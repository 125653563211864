import type { FC, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useNavigate, useParams } from 'react-router';

import classNames from 'classnames';
import { Filter } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import type { EmitUpdate } from '../../hooks/useSocket';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { ICollaboratorSharedState } from '../../pages/DiagramsPage';
import type { Dispatch } from '../../store/store';
import ElementRegisterFilter from './ElementRegisterFilter/ElementRegisterFilter';
import ElementRegisterTable from './ElementRegisterTable/ElementRegisterTable';
import styles from './ThreatRegister.module.css';
import { ThreatRegisterDetail } from './ThreatRegisterDetail/ThreatRegisterDetail';
import ThreatRegisterFilter from './ThreatRegisterFilter/ThreatRegisterFilter';
import { ThreatRegisterHeader } from './ThreatRegisterHeader/ThreatRegisterHeader';
import { ThreatRegisterTable } from './ThreatRegisterTable/ThreatRegisterTable';

interface IProps {
  collaborators?: ICollaboratorSharedState[];
  emitUpdate: EmitUpdate;
}

export const ThreatRegister: FC<IProps> = ({ collaborators, emitUpdate }) => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const params = useParams();

  const { id, diagramId } = params;
  const { visible, drawnWidth, forceRemoveThreatId, forceUpdateComponentId, visualRemoveComponentId } =
    useTypedSelector((state) => state.drawn);
  const threatModelId = useTypedSelector((state) => state.threatModel?.currentThreatModel?.id);
  const { threatRegisterList, threatRegisterListQuery } = useTypedSelector((state) => state.threats);
  const { groupedThreatRegisterList, groupedThreatRegisterListQuery } = useTypedSelector((state) => state.threats);
  const width = visible ? `calc(100vw - ${drawnWidth + 65 || 0}px)` : '100%';
  const { currentThreatModel, liveUpdateData } = useTypedSelector((state) => state.threatModel);

  const [isFilterActive, setIsFilterActive] = useState(false);

  useEffect(() => {
    if (liveUpdateData && currentThreatModel && liveUpdateData.includes(currentThreatModel.id)) {
      dispatch.threats.getGroupedThreatRegisterList({
        ...groupedThreatRegisterListQuery,
        threatModelId,
      });
      dispatch.threats.getThreatRegisterList({
        ...threatRegisterListQuery,
        threatModelId,
      });
    }
  }, [liveUpdateData]);

  // triggered when thread was deleted
  useEffect(() => {
    if (params.threatsId === forceRemoveThreatId) {
      dispatch.threats.getGroupedThreatRegisterList({
        ...groupedThreatRegisterListQuery,
        threatModelId,
      });
    }

    dispatch.drawn.setForceRemoveThreatId(null);
  }, [forceRemoveThreatId]);

  // triggered when component was updated
  useEffect(() => {
    if (forceUpdateComponentId && groupedThreatRegisterList?.some((i) => i?.component?.id === forceUpdateComponentId)) {
      dispatch.threats.getGroupedThreatRegisterList({
        ...groupedThreatRegisterListQuery,
        page: 0,
        threatModelId,
      });
    }

    dispatch.drawn.setForceUpdateComponentId(null);
  }, [forceUpdateComponentId]);

  // triggered when component was deleted
  // bug - when component deleting threats and mitigation not delete with it
  // but - in search mitigation in result shows already added to current threat
  useEffect(() => {
    if (visualRemoveComponentId && threatRegisterList?.some((i) => i?.component?.id === visualRemoveComponentId)) {
      dispatch.threats.getGroupedThreatRegisterList({
        ...groupedThreatRegisterListQuery,
        page: 0,
        threatModelId,
      });
    }

    dispatch.drawn.setVisualRemoveComponentId(null);
  }, [visualRemoveComponentId]);

  const handleFilterClick = (event: SyntheticEvent) => {
    setIsFilterActive((prevState) => !prevState);
  };

  // const handleNavLinkClick = (route: string) => {
  //   navigate(`${window.location.href}/${route}`);
  // };

  return (
    <div>
      <ThreatRegisterHeader collaborators={collaborators} />
      <div className={styles.wrap}>
        <div className={styles.content} style={{ width }}>
          <div className={styles.threatRegisterTitle}>
            <div
              onClick={handleFilterClick}
              className={classNames(styles.filterIconWrapper, {
                [styles.filterIconWrapperActive]: isFilterActive,
              })}
            >
              <Filter size={24} />
            </div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  as={Link}
                  className={styles.tabLink}
                  active={!window.location.pathname.includes('element')}
                  onClick={() => navigate(`/collections/${id}/d/${diagramId}/threats-register`)}
                >
                  Threats
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  as={Link}
                  className={styles.tabLink}
                  active={window.location.pathname.includes('element')}
                  onClick={() => {
                    navigate(`/collections/${id}/d/${diagramId}/threats-register/element`);
                    dispatch.drawn.setVisible(false);
                    setIsFilterActive(false);
                  }}
                >
                  Elements
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <Routes>
            <Route
              path="/*"
              element={
                <div className="d-flex gap-4">
                  <ThreatRegisterFilter isFilterActive={isFilterActive} />
                  <ThreatRegisterTable emitUpdate={emitUpdate} />
                  <Routes>
                    <Route
                      path="/:threatsId/*"
                      element={<ThreatRegisterDetail isFilterActive={isFilterActive} emitUpdate={emitUpdate} />}
                    />
                  </Routes>
                </div>
              }
            />
            <Route
              path="/element/*"
              element={
                <div className="d-flex gap-4">
                  <ElementRegisterFilter isFilterActive={isFilterActive} />
                  <ElementRegisterTable emitUpdate={emitUpdate} />
                  <Routes>
                    <Route
                      path="/:threatsId/*"
                      element={
                        <ThreatRegisterDetail
                          isFilterActive={isFilterActive}
                          emitUpdate={emitUpdate}
                          isElementRegister
                        />
                      }
                    />
                  </Routes>
                </div>
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};
