import { useFormik } from 'formik';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { Col, Spinner } from 'reactstrap';
import * as Yup from 'yup';

import { SKELETON_COLORS } from '../../global/constants';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';
import UiButton from '../common/UIButton/UiButton';
import UiInput from '../common/UiInput/UiInput';
import styles from '../Project/ProjectCreateOrUpdate/ProjectCreateOrUpdate.module.css';

export const ProfileInformationForm = () => {
  const dispatch = useDispatch<Dispatch>();
  const { current: user } = useTypedSelector((state) => state.user);
  const [loading, setLoading] = useState('');

  const informationFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading('info');
      await dispatch.user.updateUserInfo(values);
      setLoading('');
    },
  });

  return (
    <>
      <div className={styles.profileTitle}>Profile information</div>
      <Col sm={6} className="fs-12">
        {user?.id && (
          <UiInput
            name="firstName"
            label="First name"
            type="text"
            placeholder="First name"
            value={informationFormik.values.firstName}
            onChange={informationFormik.handleChange}
            onBlur={informationFormik.handleBlur}
            errorText={informationFormik.errors.firstName}
            showError={informationFormik.touched.firstName && informationFormik.errors.firstName}
          />
        )}
        {!user?.id && (
          <div>
            <Skeleton
              style={{ lineHeight: '11px' }}
              width={63}
              height={17}
              baseColor={SKELETON_COLORS.BASE}
              highlightColor={SKELETON_COLORS.HIGHLIGHT}
            />
            <Skeleton
              style={{ lineHeight: '11px' }}
              width={226}
              height={28}
              baseColor={SKELETON_COLORS.BASE}
              highlightColor={SKELETON_COLORS.HIGHLIGHT}
            />
          </div>
        )}
      </Col>
      <Col sm={6} className="fs-12">
        {user?.id && (
          <UiInput
            name="lastName"
            label="Last name"
            type="text"
            placeholder="Last name"
            value={informationFormik.values.lastName}
            onChange={informationFormik.handleChange}
            onBlur={informationFormik.handleBlur}
            errorText={informationFormik.errors.lastName}
            showError={informationFormik.touched.lastName && informationFormik.errors.lastName}
          />
        )}
        {!user?.id && (
          <div>
            <Skeleton
              style={{ lineHeight: '11px' }}
              width={63}
              height={17}
              baseColor={SKELETON_COLORS.BASE}
              highlightColor={SKELETON_COLORS.HIGHLIGHT}
            />
            <Skeleton
              style={{ lineHeight: '11px' }}
              width={226}
              height={28}
              baseColor={SKELETON_COLORS.BASE}
              highlightColor={SKELETON_COLORS.HIGHLIGHT}
            />
          </div>
        )}{' '}
      </Col>
      <Col className="d-flex justify-content-end mt-1">
        <UiButton className={styles.updateButton} onClick={() => informationFormik.submitForm()}>
          {loading === 'info' ? <Spinner color="light" size="sm" /> : 'Save'}
        </UiButton>
      </Col>
    </>
  );
};
