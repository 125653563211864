/* eslint-disable @typescript-eslint/no-use-before-define */
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type {
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  ReactFlowInstance,
} from 'reactflow';
import ReactFlow, { Background, ConnectionMode, Panel, SelectionMode } from 'reactflow';
import 'reactflow/dist/style.css';

import type { Connection } from '@reactflow/core/dist/esm/types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import type { YMap } from 'yjs/dist/src/internals';
import { NODES, NODES_LABELS } from '../../global/constants';
import DataStoreNode from './nodes/DataStoreNode';
import ExternalEntityNode from './nodes/ExternalEntityNode';
import ProcessNode from './nodes/ProcessNode';
import StickerNode from './nodes/StickerNode';
import TrustBoundaryNode from './nodes/TrustBoundaryNode';

import {
  canAddNodeToTarget,
  DIAGRAM_ZOOM_VALUES,
  filterEdges,
  filterNodes,
  getDefaultNodeSize,
  getViewportFromLocalStorage,
  setMarkerForEdges,
  setSelectedFalse,
  setTransitionToUserCursors,
} from '../../helpers/diagram';
import { getHelperLines } from '../../helpers/getHelperLines';
import { useCopyPaste } from '../../hooks/useCopyPaste';
import type { EmitUpdate } from '../../hooks/useSocket';
import { UpdateTypes, useSocket } from '../../hooks/useSocket';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { ICollaboratorSharedState } from '../../pages/DiagramsPage';
import type { Dispatch } from '../../store/store';
import CursorUser from './CursorUser/CursorUser';
import DiagramDrawn from './DiagramDrown';
import DiagramHeader from './DiagramHeader/DiagramHeader';
import DiagramTools from './DiagramTools';
import ExportPdf from './DiagramTools/ExportPDF';
import { ToggleDrawerButton } from './DiagramTools/ToggleDrawerButton';
import ZoomButtons from './DiagramTools/ZoomButtons';
import CustomEdge from './edges/CustomEdge';
import { EditableEdge } from './edges/EditableEdge/EditableEdge';
import SmoothStepEdge from './edges/SmoothstepEdge';
import HelperLines from './HelperLines/HelperLines';
import DefaultNode from './nodes/DefaultNode';
import { TemplatesModal } from './TemplatesModal/TemplatesModal';

let timeout: NodeJS.Timeout;

const nodeTypes = {
  externalEntityNode: ExternalEntityNode,
  datastoreNode: DataStoreNode,
  processNode: ProcessNode,
  trustBoundaryNode: TrustBoundaryNode,
  stickerNode: StickerNode,
  defaultNode: DefaultNode,
};

const edgeTypes = {
  default: CustomEdge,
  smoothstep: SmoothStepEdge,
  editable: EditableEdge,
};

const getId = () => `${window.crypto.randomUUID()}`;

type DiagramProps = {
  nodes?: Node[];
  edges?: Edge[];
  isSidebar?: boolean;
  isLocked?: boolean;
  borderPosition?: 'top' | 'left' | undefined;
  heightVh?: number;
  collaborators?: ICollaboratorSharedState[];
  nodesSharedState?: YMap<Node>;
  edgesSharedState?: YMap<Edge>;
  handleNodeChange?: OnNodesChange;
  handleEdgeChange?: OnEdgesChange;
  onConnect?: OnConnect;
  onNodeDelete?: (newNodes: Node[]) => void;
  emitUpdate?: EmitUpdate;
};

const Diagram: FC<DiagramProps> = ({
  nodes = [],
  edges = [],
  isLocked = false,
  borderPosition = undefined,
  collaborators,
  nodesSharedState,
  edgesSharedState,
  handleNodeChange,
  handleEdgeChange,
  onConnect,
  emitUpdate,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const { emitGlobalUpdate } = useSocket();
  const { id, diagramId } = useParams();
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState({} as ReactFlowInstance);
  const flowRef = useRef<HTMLDivElement>(null);

  const { currentThreatModel } = useTypedSelector((state) => state.threatModel);
  const { activeTemplateId } = useTypedSelector((state) => state.representation);

  const defaultViewport = useTypedSelector((state) => state.app?.viewport);
  const { selectedNode, isAllowedToDeleteByBackspace, defaultEdgeStyle, undoManager } = useTypedSelector(
    (state) => state.diagram,
  );
  const { current } = useTypedSelector((state) => state.user);
  const { emitComponentVisualRemove } = useTypedSelector((state) => state.drawn);
  const { current: currentProject } = useTypedSelector((state) => state.project);

  const copyPasteHandlers = useCopyPaste({
    nodesSharedState,
    edgesSharedState,
    dispatch,
  });
  const [helperLineHorizontal, setHelperLineHorizontal] = useState<number | undefined>(undefined);
  const [helperLineVertical, setHelperLineVertical] = useState<number | undefined>(undefined);

  useEffect(() => {
    return () => {
      dispatch.diagram.setCollaborators([]);
      dispatch.representation.setActiveTemplateId(null);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMarkerForEdges(reactFlowWrapper);
      clearTimeout(timeout);
    }, 200);
  }, [reactFlowInstance?.viewportInitialized]);

  useEffect(() => {
    const reactFlowPan: any = document.querySelector('.react-flow__pane');

    if (reactFlowPan) {
      if (selectedNode) {
        reactFlowPan.style.cursor = 'crosshair';
        // document.getElementsByClassName("react-flow__node")
      } else {
        reactFlowPan.style.removeProperty('cursor');
      }
    }
  }, [selectedNode]);

  useEffect(() => {
    if (reactFlowWrapper) {
      const panElement = reactFlowWrapper.current?.querySelector('.react-flow__pane');
      const nowIsDragging = panElement && panElement?.classList?.contains('dragging');
      setTransitionToUserCursors(!nowIsDragging);
    }
  }, [collaborators]);

  const handleEmitRegisterUpdate = () => {
    if (currentThreatModel?.id && emitUpdate) {
      emitUpdate(UpdateTypes.ELEMENT_REGISTER, currentThreatModel.id, true);
    }
  };

  const handleEmitAfterModelUpdate = () => {
    if (currentThreatModel) {
      emitGlobalUpdate({ threatModels: [currentThreatModel.id] });
    }
  };

  const onNodesChange = (changes: NodeChange[]) => {
    // reset the helper lines (clear existing lines, if any)
    setHelperLineHorizontal(undefined);
    setHelperLineVertical(undefined);

    // this will be true if it's a single node being dragged
    // inside we calculate the helper lines and snap position for the position where the node is being moved to
    if (changes.length === 1 && changes[0].type === 'position' && changes[0].dragging && changes[0].position) {
      const helperLines = getHelperLines(changes[0], nodes);

      // if we have a helper line, we snap the node to the helper line position
      // this is being done by manipulating the node position inside the change object
      changes[0].position.x = helperLines.snapPosition.x ?? changes[0].position.x;
      changes[0].position.y = helperLines.snapPosition.y ?? changes[0].position.y;

      // if helper lines are returned, we set them so that they can be displayed
      setHelperLineHorizontal(helperLines.horizontal);
      setHelperLineVertical(helperLines.vertical);
    }

    // const isNodeRemoving = (changes: NodeChange[]) => {
    //   let isRemoving = false;
    //   changes.map((change) => {
    //     if (change.type === 'remove') {
    //       isRemoving = true;
    //     }
    //   });
    //
    //   return isRemoving;
    // };

    const selectedIds: string[] = [];
    changes.forEach((obj) => {
      if (obj.type === 'select' && obj.selected) {
        selectedIds.push(obj.id);
      }
    });
    nodes?.forEach((node) => {
      const selectedNode = selectedIds.includes(node.id) && node.data;

      if (!selectedNode || !current?.id) return;

      if (typeof node.data.selectedBy === 'string') {
        node.data.selectedBy =
          node.data.selectedBy === current.id ? [node.data.selectedBy] : [node.data.selectedBy, current.id];
      } else if (
        node.data.selectedBy &&
        Array.isArray(node.data.selectedBy) &&
        !node.data.selectedBy.includes(current.id)
      ) {
        node.data.selectedBy = [...node.data.selectedBy, current.id];
      }

      nodesSharedState?.set(node.id, node);
    });

    if (!undoManager?.canRedo()) handleNodeChange?.(changes);
  };

  const onEdgesChange = (changes: EdgeChange[]) => {
    const selectedIds: string[] = [];
    changes.forEach((obj) => {
      if (obj.type === 'select' && obj.selected) {
        selectedIds.push(obj.id);
      }
    });
    edges?.forEach((edge) => {
      const selectedEdge = selectedIds.includes(edge.id) && edge.data;

      if (!selectedEdge || !current?.id) return;

      if (typeof edge.data.selectedBy === 'string') {
        edge.data.selectedBy =
          edge.data.selectedBy === current.id ? [edge.data.selectedBy] : [edge.data.selectedBy, current.id];
      } else if (
        edge.data.selectedBy &&
        Array.isArray(edge.data.selectedBy) &&
        !edge.data.selectedBy.includes(current.id)
      ) {
        edge.data.selectedBy = [...edge.data.selectedBy, current.id];
      }

      edgesSharedState?.set(edge.id, edge);
    });
    handleEdgeChange?.(changes);
  };

  const onEdgeUpdate = (oldEdge: Edge, newConnection: Connection) => {
    if (newConnection.source === newConnection.target) return;

    const updatedEdges = edges.map((ied) => {
      if (ied.id === oldEdge.id)
        return {
          ...ied,
          source: newConnection.source,
          target: newConnection.target,
          sourceHandle: newConnection.sourceHandle?.replace('target', 'source'),
          targetHandle: newConnection.targetHandle?.replace('source', 'target'),
        };

      return ied;
    });

    if (edgesSharedState) {
      const changedEdge: any = updatedEdges.find((edge) => edge.id === oldEdge.id);

      if (changedEdge) edgesSharedState.set(changedEdge.id, changedEdge);
    }
  };

  const handleNodeDelete = useCallback(
    (deleted: Node[]) => {
      deleted.forEach((deletedNode) => {
        emitComponentVisualRemove && emitComponentVisualRemove(deletedNode.id);
      });
      dispatch.drawn.removeComponents(deleted.map((i) => i.id));
      navigate(`/collections/${id}/d/${diagramId}`);

      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(() => {
        dispatch.threatModel.updateCurrentThreatModel();
        handleEmitAfterModelUpdate();
        handleEmitRegisterUpdate();
      }, 3000);
    },
    [nodes, edges],
  );

  const handleEdgeDelete = useCallback(
    (deleted: Edge[]) => {
      deleted.forEach((deletedEdge) => {
        emitComponentVisualRemove && emitComponentVisualRemove(deletedEdge.id);
      });
      dispatch.drawn.removeComponents(deleted.map((i) => i.id));
      navigate(`/collections/${id}/d/${diagramId}`);

      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(() => {
        dispatch.threatModel.updateCurrentThreatModel();
        handleEmitAfterModelUpdate();
        handleEmitRegisterUpdate();
      }, 3000);
    },
    [nodes, edges],
  );

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const focusOnLastAddedSticker = () => {
    const textArea = document?.querySelector('.react-flow__nodes')?.lastChild?.lastChild?.firstChild;

    if (textArea) {
      // @ts-ignore
      textArea.focus();
    }
  };

  const onDrop = useCallback(
    (event: {
      preventDefault: () => void;
      dataTransfer: { getData: (arg0: string) => any };
      clientX: number;
      clientY: number;
    }) => {
      event.preventDefault();
      deselectNodes();
      deselectEdges();

      const type = event.dataTransfer.getData('application/reactflow');
      const label = event.dataTransfer.getData('label');

      if (typeof type === 'undefined' || !type) {
        return;
      }

      dispatch.diagram.setSelectedNode(null);
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode: Node = {
        id: getId(),
        type,
        position,
        selected: false,
        style: getDefaultNodeSize(type),
        data: {
          label,
          user: current?.id ? current : {},
          representation: diagramId,
          fontSize: 12,
          textStyle: {
            isBold: false,
            isItalic: false,
            isUnderline: false,
            isLineThrough: false,
          },
          selectedBy: [],
        },
        zIndex: getZIndex(type),
      };

      if (!currentProject?.id) {
        dispatch.project.setCurrent({ id });
      }

      dispatch.representation
        .addNewNodeEffect({
          node: newNode,
          diagramId,
        })
        .then(() => {
          nodesSharedState?.set(newNode.id, newNode);
          const createNodeTimeout = setTimeout(() => {
            clearTimeout(createNodeTimeout);
            nodesSharedState?.set(newNode.id, {
              ...newNode,
              selected: true,
              data: {
                ...newNode.data,
                selectedBy: current?.id ? [current.id] : [],
              },
            });
          }, 300);
        });

      if (type === NODES.STICKER_NODE) {
        focusOnLastAddedSticker();
      }

      handleEmitRegisterUpdate();
    },
    [reactFlowInstance],
  );

  const getZIndex = (nodeType: string) => {
    switch (nodeType) {
      case NODES.TRUST_BOUNDARY_NODE:
        return 0;
      case NODES.STICKER_NODE:
        return 3;
      default:
        return 2;
    }
  };

  const borderClass = classNames({
    'border-top': borderPosition === 'top',
  });

  const handleNodeClick = async (e: any, node: Node) => {
    dispatch.diagram.setIsAllowedToDeleteByBackspace(true);

    if (selectedNode) return;

    const isSticker = node.type === NODES.STICKER_NODE;

    if (isSticker) {
      navigate(`/collections/${id}/d/${diagramId}`);
      dispatch.diagram.setIsAllowedToDeleteByBackspace(node?.data?.user?.id === current?.id);
      dispatch.drawn.setVisible(false);

      return;
    }

    deselectEdges();
    deselectNodes(node?.id);
    const exist = await dispatch.drawn.getComponent({ id: node.id });

    if (exist && id && diagramId) {
      navigate(`/collections/${id}/d/${diagramId}/c/${node.id}`);
    }
  };

  const handleEdgeClick = async (e: any, node: any) => {
    if (selectedNode) return;

    deselectNodes();
    deselectEdges(node?.id);

    if (node?.id) {
      const exist = await dispatch.drawn.getComponent({ id: node.id });

      if (exist && id && diagramId) {
        navigate(`/collections/${id}/d/${diagramId}/c/${node.id}`);
      }
    }
  };

  const handleConnect = useCallback(
    (edge: any) => {
      if (isLocked) return;

      deselectNodes();
      deselectEdges();

      if (edge.source === edge.target) return;

      const component = {
        ...edge,
        id: getId(),
        selected: false,
        data: {
          representation: diagramId,
          label: 'Dataflow',
          selectedBy: [],
          points: [],
          algorithm: 'linear',
          ...defaultEdgeStyle.data,
        },
        markerEnd: defaultEdgeStyle.markerEnd,
        type: defaultEdgeStyle.type,
      };
      onConnect && onConnect(component);
      const createNodeTimeout = setTimeout(() => {
        clearTimeout(createNodeTimeout);
        setMarkerForEdges(reactFlowWrapper);
        edgesSharedState?.set(component.id, {
          ...component,
          selected: true,
          data: {
            ...component.data,
            selectedBy: current?.id ? [current.id] : [],
          },
        });
      }, 300);
      dispatch.drawn.saveComponent(component);
    },
    [reactFlowInstance, defaultEdgeStyle, isLocked],
  );

  const handleOnInit = (instance: ReactFlowInstance) => {
    if (instance?.fitView) instance.fitView();

    if (instance?.zoomTo) {
      const viewportFromLocalStorage = getViewportFromLocalStorage();
      instance.zoomTo(viewportFromLocalStorage.zoom);
    }

    setReactFlowInstance(instance);
  };

  const deselectNodes = (currentNodeId?: string) => {
    Array.from(nodesSharedState?.values() || []).forEach((node: Node) => {
      if (currentNodeId && node.id === currentNodeId) return;

      if (Array.isArray(node.data.selectedBy) && node.data?.selectedBy?.length) {
        if (node.data.selectedBy.includes(current?.id)) {
          node.data.selectedBy = node.data.selectedBy.filter((id: string) => id !== current?.id);

          if (node.selected) node.selected = false;

          nodesSharedState?.set(node.id, node);
        }
      }
    });
  };
  const deselectEdges = (currentEdgeId?: string) => {
    Array.from(edgesSharedState?.values() || []).forEach((edge: Edge) => {
      if (currentEdgeId && edge.id === currentEdgeId) return;

      if (Array.isArray(edge.data.selectedBy) && edge.data?.selectedBy?.length) {
        if (edge.data.selectedBy.includes(current?.id)) {
          edge.data.selectedBy = edge.data.selectedBy.filter((id: string) => id !== current?.id);

          if (edge.selected) edge.selected = false;

          edgesSharedState?.set(edge.id, edge);
        }
      }
    });
  };

  const handleCreateDiagramFromTemplate = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const panElement = document.querySelector('.react-flow__pane');
    panElement?.classList?.add('waitTemplateCursor');
    dispatch.representation.setActiveTemplateId(null);
    const position = reactFlowInstance?.screenToFlowPosition({ x: event.clientX, y: event.clientY });

    await dispatch.representation.createFromTemplate({
      representationId: diagramId,
      templateId: activeTemplateId,
      position: structuredClone(position),
    });
    panElement?.classList?.remove('waitTemplateCursor');
  };

  const handleClick = (event: any) => {
    if (event.target.className === 'react-flow__pane' && activeTemplateId?.length) {
      handleCreateDiagramFromTemplate(event);

      return;
    }

    if (event.target.className === 'react-flow__pane') {
      deselectNodes();
      deselectEdges();
      navigate(`/collections/${id}/d/${diagramId}`);
    }

    dispatch.diagram.setEditedComponentId('');
    const canAdd = canAddNodeToTarget(event);

    if (!selectedNode?.length || !canAdd) return;

    event.preventDefault();
    event.stopPropagation();
    dispatch.diagram.setSelectedNode(null);
    deselectNodes();
    deselectEdges();
    const position = reactFlowInstance?.screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });
    const newNode: Node = {
      id: getId(),
      type: selectedNode,
      position,
      selected: false,
      style: getDefaultNodeSize(selectedNode),
      data: {
        label: NODES_LABELS[selectedNode],
        user: current,
        representation: diagramId,
        selectedBy: [],
      },
      zIndex: getZIndex(selectedNode),
    };
    dispatch.representation
      .addNewNodeEffect({
        node: newNode,
        diagramId,
      })
      .then(() => {
        nodesSharedState?.set(newNode.id, newNode);
        const createNodeTimeout = setTimeout(() => {
          clearTimeout(createNodeTimeout);
          nodesSharedState?.set(newNode.id, {
            ...newNode,
            selected: true,
            data: {
              ...newNode.data,
              selectedBy: current?.id ? [current.id] : [],
            },
          });
        }, 300);
      });

    if (!currentProject?.id) {
      dispatch.project.setCurrent({ id });
    }

    if (selectedNode === 'stickerNode') {
      focusOnLastAddedSticker();
    }

    if (currentThreatModel?.id) {
      handleEmitRegisterUpdate();
    }
  };

  const handleDragStart = useCallback((event: any, currentNode: Node) => {
    const nodeId = currentNode.id;

    if (nodeId) {
      deselectNodes(nodeId);
      deselectEdges(nodeId);
    }

    if (event.detail === 2 || event.target.tagName === 'TEXTAREA') return;

    const nodes = document.querySelectorAll<HTMLElement>('.react-flow__node');
    const edges = document.querySelectorAll<HTMLElement>('.react-flow__edge');
    const handlers = document.querySelectorAll<HTMLElement>('#nodeHandler');

    if (!nodes || !edges || !handlers) {
      return;
    }

    for (const node of Array.from(nodes)) {
      node.classList.add('unhoverable');
    }

    for (const edge of Array.from(edges)) {
      edge.classList.add('unhoverable');
    }

    for (const handle of Array.from(handlers)) {
      handle.style.display = 'none';
    }
  }, []);

  const handleDragEnd = (event: any) => {
    if (event.detail === 2 || event.target.tagName === 'TEXTAREA') return;

    const nodes = document.querySelectorAll<HTMLElement>('.react-flow__node');
    const edges = document.querySelectorAll<HTMLElement>('.react-flow__edge');
    const handlers = document.querySelectorAll<HTMLElement>('#nodeHandler');

    for (const node of Array.from(nodes)) {
      node.classList.remove('unhoverable');
    }

    for (const edge of Array.from(edges)) {
      edge.classList.remove('unhoverable');
    }

    for (const handle of Array.from(handlers)) {
      handle.style.display = 'unset';
    }
  };

  const handleSelectionEnd = () => {
    const deselectForCollaborators = (nd: any, isEdge: boolean) => {
      if (!nd.selected && nd.data?.selectedBy?.includes(current?.id)) {
        nd.data.selectedBy = nd.data.selectedBy.filter((i: string) => i !== current?.id);

        if (isEdge) edgesSharedState?.set(nd.id, nd);
        else nodesSharedState?.set(nd.id, nd);
      }

      if (
        !isEdge &&
        nd?.selected &&
        nd.type === NODES.STICKER_NODE &&
        nd?.data?.user?.id !== current?.id &&
        isAllowedToDeleteByBackspace
      ) {
        dispatch.diagram.setIsAllowedToDeleteByBackspace(false);
      }
    };
    nodes.forEach((n) => deselectForCollaborators(n, false));
    edges.forEach((e) => deselectForCollaborators(e, true));
  };

  return (
    <div className={`dndflow ${borderClass}`}>
      <DiagramHeader collaborators={collaborators} />
      <DiagramDrawn emitUpdate={emitUpdate} />
      <TemplatesModal isCanvasEmpty={!nodes?.length} coords={copyPasteHandlers.coords} />
      <div className="container-fluid">
        <div className="reactflow-wrapper row" ref={reactFlowWrapper}>
          <ReactFlow
            connectionRadius={45}
            connectionMode={ConnectionMode.Loose}
            nodes={!isLocked ? filterNodes(nodes) : setSelectedFalse(filterNodes(nodes))}
            edges={!isLocked ? filterEdges(edges) : setSelectedFalse(filterEdges(edges))}
            selectionMode={SelectionMode.Full}
            ref={flowRef}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={handleConnect}
            onInit={handleOnInit}
            onDrop={onDrop}
            defaultViewport={defaultViewport}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onDragOver={onDragOver}
            onNodeDragStart={handleDragStart}
            onNodeDragStop={handleDragEnd}
            onNodesDelete={handleNodeDelete}
            onEdgesDelete={handleEdgeDelete}
            onNodeClick={handleNodeClick}
            selectionKeyCode="Shift"
            edgesUpdatable={!isLocked}
            edgesFocusable={!isLocked}
            nodesDraggable={!isLocked}
            nodesConnectable={!isLocked}
            nodesFocusable={!isLocked}
            elementsSelectable={!isLocked}
            proOptions={{ hideAttribution: true }}
            onEdgeClick={handleEdgeClick}
            onEdgeUpdate={onEdgeUpdate}
            defaultMarkerColor="#6F767E"
            panOnScroll
            onClickCapture={handleClick}
            minZoom={DIAGRAM_ZOOM_VALUES.MIN}
            maxZoom={DIAGRAM_ZOOM_VALUES.MAX}
            onMoveStart={() => setTransitionToUserCursors(false)}
            onMoveEnd={() => setTransitionToUserCursors(true)}
            onSelectionEnd={handleSelectionEnd}
            deleteKeyCode={[isAllowedToDeleteByBackspace ? 'Backspace' : '', 'Delete']}
          >
            <HelperLines horizontal={helperLineHorizontal} vertical={helperLineVertical} />
            <Background gap={12} color="white" />
            {!isLocked && (
              <Panel position="bottom-left">
                <DiagramTools copyPasteHandlers={copyPasteHandlers} />
              </Panel>
            )}
            <Panel position="bottom-right" style={{ zIndex: '100001' }}>
              <ZoomButtons />
            </Panel>

            <Panel position="top-left">
              <ExportPdf />
            </Panel>

            <Panel position="top-right">
              <ToggleDrawerButton />
            </Panel>
            <Panel
              style={{ top: '5px', left: '50px', zIndex: 100000 }}
              position="top-left"
              className="position-absolute"
            >
              {!!collaborators?.length &&
                collaborators
                  .filter((user) => user?.first_name !== current?.first_name && user?.last_name !== current?.last_name)
                  .map((user, index) => {
                    if (!Object.keys(user).length) {
                      return null;
                    }

                    return <CursorUser {...user} key={user.id + index} />;
                  })}
            </Panel>
          </ReactFlow>
        </div>
      </div>
    </div>
  );
};

export default Diagram;
