import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import { CheckLg, PlusLg } from 'react-bootstrap-icons';
import type { NodeData } from '../../../global/types';
import {
  getColorDependsOnBackground,
  putColorToLocalStorage,
  replaceColorFromLocalStorage,
} from '../../../helpers/colors';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import UiRangeInput from '../../common/UiRangeInput/UiRangeInput';
import styles from './NodeBorderPicker.module.css';

const OPTIONS = [1, 2, 3, 4, 5];

let timer: any = null;

type NodeBorderPickerProps = {
  itemsVisible: boolean;
  setItemsVisible: (value: boolean) => void;
  node: NodeData;
  colors: string[];
};

const NodeBorderPicker: FC<NodeBorderPickerProps> = ({ itemsVisible, setItemsVisible, node, colors }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const { nodesSharedState } = useTypedSelector((state) => state.diagram);
  const [borderWidth, setBorderWidth] = useState<number>(node?.borderWidth || 3);
  const [borderColor, setBorderColor] = useState<string>(node?.borderColor || 'black');

  const handleSelect = (newValue: any, isBorder = false) => {
    if (newValue) {
      if (!colors.map((c) => c.substring(0, 7)).includes(newValue) && !isBorder) putColorToLocalStorage(newValue);

      const inNode = nodesSharedState?.get(node.id);

      if (inNode) {
        if (isBorder) {
          setBorderWidth(newValue);
          inNode.data = {
            ...inNode.data,
            borderWidth: newValue,
          };
        } else {
          setBorderColor(newValue);
          inNode.data = {
            ...inNode.data,
            borderColor: newValue,
          };
        }

        nodesSharedState && nodesSharedState.set(inNode.id, inNode);
      }
    }
  };

  const changeLastColor = (newColor: string) => {
    const lastColor = colors.at(-1);

    if (lastColor && !colors.map((color) => color.substring(0, 7)).includes(newColor.substring(0, 7)))
      replaceColorFromLocalStorage(lastColor, newColor);

    const inNode = nodesSharedState?.get(node.id);

    if (inNode) {
      inNode.data = {
        ...inNode.data,
        borderColor: newColor,
      };
      nodesSharedState && nodesSharedState.set(inNode.id, inNode);
    }
  };

  const handleColorChange = (e: any) => {
    e.persist();
    e.stopPropagation();
    e.preventDefault();
    setBorderColor(e.target.value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      changeLastColor(e.target.value);
    }, 500);
  };

  const handleWidthClick = (ev: any, value: number) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      handleSelect(value, true);
    }, 500);
  };

  const handleMouseEnter = (e: any) => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleShowColorPicker = () => {
    document.getElementById(`node-color-input-${node.id}`)?.click();
    handleSelect('#ffffff');
  };

  return (
    <div
      className={classNames(
        {
          [styles.dropdownActive]: itemsVisible,
        },
        styles.dropdown,
        'd-flex align-items-center justify-content-between rounded p-1 px-2 gap-3 cursor-pointer position-relative',
      )}
    >
      {showTooltip && !itemsVisible && <div className="main-tooltip">Border Picker</div>}
      <div
        onClick={() => setItemsVisible(!itemsVisible)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
          {borderColor === '#ffffff' ? (
            <>
              <circle cx="12.5" cy="12" r="12" fill="none" stroke="#B4B4BC" strokeWidth="1" />
              <circle cx="12.5" cy="12" r={12 - borderWidth} fill="none" stroke="#B4B4BC" strokeWidth="1" />
            </>
          ) : (
            <circle cx="12.5" cy="12" r="11" fill="white" stroke={borderColor} strokeWidth={borderWidth} />
          )}
        </svg>
      </div>
      {itemsVisible && (
        <div className={classNames(styles.dropdownItems)} style={{ top: colors.length <= 7 ? '-84px' : '-115px' }}>
          <UiRangeInput
            options={OPTIONS}
            value={borderWidth}
            onOptionClick={handleWidthClick}
            label="Thickness"
            valueSign=""
            isThickness
          />
          <div className={styles.dropdownItemWrapper}>
            {colors?.map((c) => (
              <div
                id={c}
                key={c}
                onClick={(ev: any) => {
                  if (c !== node?.borderColor) {
                    handleSelect(c);
                  } else {
                    ev.preventDefault();
                    ev.stopPropagation();
                  }
                }}
                className={classNames(styles.dropdownItem, 'rounded-circle')}
                style={{
                  background: c,
                  cursor: c === node?.borderColor ? 'default' : 'pointer',
                }}
              >
                {c === node?.borderColor && (
                  <div className={styles.selectedMark}>
                    <CheckLg size={16} color={getColorDependsOnBackground(c.substring(0, 7))} />
                  </div>
                )}
              </div>
            ))}
            {colors.length <= 15 && (
              <div className={styles.plusIcon} onClick={handleShowColorPicker}>
                <PlusLg className="cursor-pointer" />
              </div>
            )}
          </div>
        </div>
      )}
      <input
        id={`node-color-input-${node.id}`}
        className="position-absolute opacity-0"
        type="color"
        style={{ pointerEvents: 'none' }}
        onChange={handleColorChange}
        defaultValue="#ffffff"
      />
    </div>
  );
};

export default NodeBorderPicker;
