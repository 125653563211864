import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import ProjectList from '../../components/Project/ProjectList';
import SettingsPage from '../AdminPage/SettingsPage';
import UsersPage from '../AdminPage/UsersPage';
import TeamsPage from '../TeamsPage';

const HomePage: FC = () => (
  <Routes>
    <Route path="/" element={<ProjectList />} />
    <Route path="/teams" element={<TeamsPage />} />
    <Route path="/users" element={<UsersPage />} />
    <Route path="/settings" element={<SettingsPage />} />
  </Routes>
);

export default HomePage;
