import classNames from 'classnames';
import type { FC } from 'react';
import { Fragment, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import { DEFAULT_FETCH_LIMIT, SKELETON_COLORS } from '../../global/constants';
import type { Project } from '../../global/types';
import { useSocket } from '../../hooks/useSocket';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';
import UiButton from '../common/UIButton/UiButton';
import ArchiveDeleteModal from './archive/ArchiveDeleteModal';
import { ProjectLine } from './ProjectLine';
import styles from './ProjectListTable.module.css';

type ProjectListTableProps = {
  projects: Project[] | null;
  parentProjectId?: string;
  nestedLevel?: number;
};

const STEP_FOR_NESTED_PROJECTS = 20;

const ProjectListTable: FC<ProjectListTableProps> = ({ projects = null, parentProjectId, nestedLevel = 0 }) => {
  const dispatch = useDispatch<Dispatch>();
  const query = useTypedSelector((state) => state.project.query);

  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const [openedNestedProjects, setOpenedNestedProjects] = useState<string[]>([]);

  const [selectedProject, setSelectedProject] = useState<string>('');

  const [loading, setLoading] = useState(false);

  const canFetchMore = query.page * query.limit < query.count;

  const { emitGlobalUpdate } = useSocket();

  const handleEmitAfterProjectUpdate = (isArchive = false) => {
    if (selectedProject && typeof selectedProject === 'string') {
      const archiveData = isArchive ? [selectedProject] : undefined;
      emitGlobalUpdate({
        projects: [selectedProject],
        archives: archiveData,
      });
    }
  };

  const handleArchive = async () => {
    await dispatch.project.archiveProject(selectedProject);
    handleEmitAfterProjectUpdate(true);
    setShowArchiveModal(false);
    setSelectedProject('');
  };

  const handleOpenedNestedProjects = (project: Project, isAdd = false) => {
    setOpenedNestedProjects((projectsId) => {
      if (isAdd) {
        return [...projectsId, project.id];
      }

      return projectsId.filter((projectId) => projectId !== project.id);
    });

    dispatch.project.getNestedProjects({ limit: query.limit, page: 0, parentId: project.id });
  };

  const fetchMoreData = async () => {
    const nextPage = query.page + 1;

    if (nextPage * query.limit < query.count) {
      setLoading(true);
      await dispatch.project.getProjectsByQuery({
        ...query,
        page: nextPage,
      });
      setLoading(false);
    }
  };

  const canFetchMoreNestedProjects = ({ query }: Project) => {
    if (!query) return false;

    return (query.page + 1) * query.limit < query.count;
  };

  const fetchMoreNestedProjects = async ({ query, id }: Project) => {
    setLoading(true);

    await dispatch.project.getNestedProjectsByQuery({
      count: query?.count || 0,
      page: (query?.page || 0) + 1,
      limit: query?.limit || DEFAULT_FETCH_LIMIT,
      parentProjectId: id,
    });
    setLoading(false);
  };

  return (
    <div className={!parentProjectId ? styles.content : ''} id="scrollableTargetDiv">
      <InfiniteScroll
        scrollableTarget="scrollableTargetDiv"
        next={fetchMoreData}
        hasMore={canFetchMore && !parentProjectId}
        loader={
          loading ? (
            <div className="w-100 m-auto text-center">
              <Spinner color="dark" />
            </div>
          ) : null
        }
        dataLength={projects?.length || 0}
      >
        <div data-testid="collections-container">
          {!parentProjectId && (
            <div className={styles.contentHead}>
              {!projects ? (
                <div className={classNames(styles.skeleton, styles.headRow)}>
                  <Skeleton
                    height={40}
                    width="100%"
                    containerClassName={styles.skeleton}
                    count={1}
                    borderRadius={10}
                    baseColor={SKELETON_COLORS.BASE}
                    highlightColor={SKELETON_COLORS.HIGHLIGHT}
                  />
                </div>
              ) : (
                <div className={styles.headRow}>
                  <div className={styles.menuColumn} />
                  <div className={classNames(styles.headColumn, styles.collectionNameColumn)}>Collection Name</div>
                  <div className={classNames(styles.centered, styles.headColumn, styles.modelsAmountColumn)}>
                    Models amount
                  </div>
                  <div className={classNames(styles.ownerColumn, styles.headColumn)}>Owner</div>
                </div>
              )}
            </div>
          )}

          {projects?.map((project) => {
            const isNestedOpen = openedNestedProjects.includes(project.id);

            return (
              <Fragment key={project.id}>
                <ProjectLine
                  indentation={nestedLevel * STEP_FOR_NESTED_PROJECTS}
                  project={project}
                  setSelectedProject={setSelectedProject}
                  setShowArchiveModal={setShowArchiveModal}
                  isNestedProjectsOpen={isNestedOpen}
                  onOpenNestedProjects={() => handleOpenedNestedProjects(project, true)}
                  onCloseNestedProjects={() => handleOpenedNestedProjects(project)}
                />

                {isNestedOpen && !!project.sub_projects_amount && (
                  <div data-testid="sub-collections-container" style={{ background: '#f8fafd' }}>
                    <ProjectListTable
                      nestedLevel={nestedLevel + 1}
                      projects={project?.nestedProjects || null}
                      parentProjectId={project.id}
                    />
                    {canFetchMoreNestedProjects(project) && (
                      <div className="d-flex justify-content-center">
                        <UiButton onClick={() => fetchMoreNestedProjects(project)}>Upload more collection</UiButton>
                      </div>
                    )}
                  </div>
                )}
              </Fragment>
            );
          })}
          {!projects && (
            <div className={styles.skeleton}>
              <Skeleton
                height={40}
                width="100%"
                containerClassName={styles.skeleton}
                count={4}
                borderRadius={10}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
            </div>
          )}
        </div>
      </InfiniteScroll>
      {showArchiveModal && (
        <ArchiveDeleteModal
          onArchive={handleArchive}
          onCancel={() => setShowArchiveModal(false)}
          title={projects?.find((p) => p.id === selectedProject)?.title || ''}
        />
      )}
    </div>
  );
};

export default ProjectListTable;
