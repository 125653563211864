import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { XLg } from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Spinner } from 'reactstrap';
import * as Yup from 'yup';
import { SKELETON_COLORS } from '../../../global/constants';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { getValidationForCodex } from '../../../store/constants/custom-codex-constants';
import { THREAT_PRIORITY } from '../../../store/constants/drawn-constants';

import type { Dispatch } from '../../../store/store';
import UiModal from '../../common/Modal/UiModal';
import UiButton from '../../common/UIButton/UiButton';
import UiInput from '../../common/UiInput/UiInput';
import ThreatDropdown from '../../Diagram/ThreatDropdown';
import styles from './CustomCodexTable.module.css';
import { ResourcesForm } from './ResourcesForm';
import { ThreatItemsForm } from './ThreatItemsForm';

export const CreateThreatForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState<string>('');
  const [deleteModal, setDeleteModla] = useState(false);
  const { threats } = useTypedSelector((state) => state.customCodex);
  const [currentThreat, setCurrentThreat] = useState<any>(null);

  const isCreating = params?.threatId === 'new';

  const getCurrentThreat = async () => {
    const found = threats?.find((a) => a.id === params?.threatId);

    if (found) {
      setCurrentThreat(null);
      const res = await dispatch.customCodex.getCustomThreatById(found.id);
      setCurrentThreat(res);
    }
  };

  useEffect(() => {
    if (!isCreating) {
      getCurrentThreat();
    } else {
      setCurrentThreat(null);
    }
  }, [params]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: currentThreat || {
      title: '',
      description: '',
      priority: '',
      mitigations: [],
      resources: [],
      caused_by: [],
      neutralized_by: [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Required').min(1).max(64),
      description: Yup.string().required('Required').min(1).max(500),
      priority: Yup.mixed<THREAT_PRIORITY>().oneOf(Object.values(THREAT_PRIORITY)).nullable(),
      mitigations: getValidationForCodex({ title: Yup.string(), id: Yup.string() }),
      resources: getValidationForCodex({ title: Yup.string(), url: Yup.string() }),
      caused_by: getValidationForCodex({ title: Yup.string(), id: Yup.string() }),
      neutralized_by: getValidationForCodex({ title: Yup.string(), id: Yup.string() }),
    }),
    onSubmit: async (values) => {
      setLoading('save');
      let isOk;

      const preparedAttribute: Record<string, any> = {
        title: values.title,
        description: values.description,
        priority: values.priority || THREAT_PRIORITY.LOW,
      };

      if (values.mitigations?.length) {
        preparedAttribute.mitigationsIds = values.mitigations.map((m: any) => m.id);
      }

      if (values.resources?.length) {
        preparedAttribute.resources = values.resources;
      }

      if (values.caused_by?.length) {
        preparedAttribute.causedByIds = values.caused_by.map((m: any) => m.id);
      }

      if (values.neutralized_by?.length) {
        preparedAttribute.neutralizedByIds = values.neutralized_by.map((m: any) => m.id);
      }

      if (isCreating) {
        isOk = await dispatch.customCodex.createThreat(preparedAttribute);
      } else {
        isOk = await dispatch.customCodex.updateThreat({
          ...preparedAttribute,
          id: currentThreat.id,
        });
        await getCurrentThreat();
      }

      setLoading('');

      if (isOk) {
        navigate(-1);
        dispatch.drawn.setVisible(false);
        setCurrentThreat(null);
        formik.resetForm();
      }
    },
  });

  const handleDelete = async () => {
    setLoading('delete');
    navigate('/admin/codex/threats');
    await dispatch.customCodex.deleteThreat(currentThreat.id);
    setLoading('');
    setDeleteModla(false);
    setCurrentThreat(null);
    dispatch.drawn.setVisible(false);
  };

  const showForm = currentThreat || isCreating;

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5>{isCreating ? 'Add' : 'Edit'} Threat</h5>
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate('/admin/codex/threats');
            dispatch.drawn.setVisible(false);
          }}
        >
          <XLg size={20} />
        </div>
      </div>

      {showForm && (
        <UiInput
          name="title"
          label="Name *"
          placeholder="Threat name"
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.errors?.title?.toString()}
          showError={!!formik.touched?.title && !!formik.errors?.title}
        />
      )}

      <div className="mt-3" />

      {showForm && (
        <UiInput
          name="description"
          label="Description *"
          type="textarea"
          textAreaRows={4}
          placeholder="Threat description"
          value={formik.values?.description || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.errors.description?.toString()}
          showError={!!formik.touched.description && !!formik.errors.description}
        />
      )}

      <div className="mt-3" />

      {showForm && (
        <>
          <div className={styles.label}>Priority</div>
          <ThreatDropdown
            value={formik.values?.priority}
            options={Object.values(THREAT_PRIORITY)}
            defaultValue={THREAT_PRIORITY.LOW}
            onChange={(priority: THREAT_PRIORITY) => formik.setFieldValue('priority', priority)}
          />
        </>
      )}

      {showForm && (
        <>
          <hr />
          <ThreatItemsForm
            type="attributes"
            title="caused by"
            items={formik.values.caused_by || []}
            setItems={(values) => {
              formik.setFieldValue('caused_by', values);
            }}
            excludeItems={formik.values.neutralized_by?.map((t: any) => t.id) || []}
          />
        </>
      )}

      {showForm && (
        <>
          <hr />
          <ThreatItemsForm
            type="attributes"
            title="neutralized by"
            items={formik.values.neutralized_by || []}
            setItems={(values) => {
              formik.setFieldValue('neutralized_by', values);
            }}
            excludeItems={formik.values.caused_by?.map((t: any) => t.id) || []}
          />
        </>
      )}

      {showForm && (
        <>
          <hr />
          <ThreatItemsForm
            type="mitigations"
            title="mitigations"
            items={formik.values.mitigations || []}
            setItems={(values) => {
              formik.setFieldValue('mitigations', values);
            }}
          />
        </>
      )}

      {showForm && (
        <>
          <hr />
          <ResourcesForm
            resources={formik.values?.resources}
            setResources={(values) => {
              formik.setFieldValue('resources', values);
            }}
          />
        </>
      )}

      {showForm && (
        <>
          <hr />
          <div className="d-flex justify-content-end">
            <UiButton
              onClick={() => setDeleteModla(true)}
              className="mt-3 me-3"
              type="transparent"
              style={{ width: '100px' }}
              hidden={isCreating}
            >
              Delete
            </UiButton>
            <UiButton
              onClick={() => {
                navigate('/admin/codex/threats');
                dispatch.drawn.setVisible(false);
              }}
              className="mt-3 me-3"
              type="transparent"
              style={{ width: '100px' }}
              hidden={!isCreating}
            >
              Cancel
            </UiButton>
            <UiButton disabled={!!loading} onClick={formik.submitForm} className="mt-3" style={{ width: '100px' }}>
              {loading === 'save' ? <Spinner color="light" size="sm" /> : 'Save'}
            </UiButton>
          </div>
        </>
      )}

      {deleteModal && (
        <UiModal title="Delete" onClose={() => setDeleteModla(false)}>
          {`Are you sure you want to delete the "${currentThreat.title}" threat?`}
          <div className="d-flex justify-content-end">
            <UiButton onClick={handleDelete} className="mt-3" type="danger" style={{ width: '100px' }}>
              {loading === 'delete' ? <Spinner color="danger" size="sm" /> : 'Delete'}
            </UiButton>
          </div>
        </UiModal>
      )}

      {!currentThreat && !isCreating && (
        <Skeleton
          style={{ lineHeight: '25px', margin: '10px 0' }}
          width="100%"
          height={40}
          count={4}
          borderRadius={10}
          baseColor={SKELETON_COLORS.BASE}
          highlightColor={SKELETON_COLORS.HIGHLIGHT}
        />
      )}
    </>
  );
};
