import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HIDE_ONBOARDING_AFTER_PAYMENT, SING_UP_PAGE_PAYMENT_URL_PARAMS } from '../global/constants';
import type { Dispatch } from '../store/store';
import { useTypedSelector } from './useTypeSelector';

export function useInitChargebee() {
  const dispatch = useDispatch<Dispatch>();
  const { current } = useTypedSelector((state) => state.user);
  const companyName = useTypedSelector((state) => state.customer.companyName);
  const [chargebeeLoaded, setChargebeeLoaded] = useState(false);

  useEffect(() => {
    if (!companyName) {
      dispatch.customer.getCustomer({});
    }
  }, [companyName]);

  const initChargebee = useCallback(() => {
    const { Chargebee } = window as any;

    if (!Chargebee) {
      console.error('Chargebee is not loaded!');

      return;
    }

    Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_SITE_NAME,
      publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY,
    });

    setChargebeeLoaded(true);
  }, []);

  const reregisterChargebee = useCallback(() => {
    const { Chargebee } = window as any;

    if (!Chargebee) {
      console.error('Chargebee is not loaded!');

      return;
    }

    Chargebee.registerAgain();
  }, []);

  const initCart = useCallback(() => {
    const { Chargebee } = window as any;

    if (!Chargebee) {
      console.error('Chargebee is not loaded!');

      return;
    }

    const cbInstance = Chargebee.getInstance();
    const cart = cbInstance.getCart();

    cart.setCustomer({
      email: current?.email,
      company: companyName,
    });
  }, []);

  const loadScript = useCallback(() => {
    const script = document.createElement('script');

    if (!process.env.REACT_APP_CHARGEBEE_SCRIPT_SRC) {
      console.error("Couldn't load Chargebee. Script src is missing!");

      return;
    }

    script.src = process.env.REACT_APP_CHARGEBEE_SCRIPT_SRC;
    script.async = true;
    document.head.appendChild(script);

    return script;
  }, []);

  useEffect(() => {
    if (!current?.email) return;

    const { Chargebee } = window as any;

    if (Chargebee) {
      Chargebee.registerAgain();
      initCart(); // reinitialize cart with new user data
    } else {
      const script = loadScript();

      if (!script) return;

      script.onload = () => {
        initChargebee();
        initCart();
        localStorage.removeItem(SING_UP_PAGE_PAYMENT_URL_PARAMS);
        localStorage.setItem(HIDE_ONBOARDING_AFTER_PAYMENT, JSON.stringify(true));
      };
    }
  }, [current?.email, companyName]);

  return { chargebeeLoaded, reregisterChargebee, initCart };
}
