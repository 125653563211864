import type { FC } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';

import { useFormik } from 'formik';
import { ArrowLeft, Broadcast, Layers } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Col, Form, FormGroup, Spinner } from 'reactstrap';
import * as Yup from 'yup';
import deviciLogo from '../../assets/images/devici-logo.png';
import UiModal from '../../components/common/Modal/UiModal';
import UiButton from '../../components/common/UIButton/UiButton';
import UiInput from '../../components/common/UiInput/UiInput';
import { ProfileAvatarForm } from '../../components/Profile/ProfileAvatarForm';
import { ProfileEmailForm } from '../../components/Profile/ProfileEmailForm';
import { ProfileInformationForm } from '../../components/Profile/ProfileInformationForm';
import styles from '../../components/Project/ProjectCreateOrUpdate/ProjectCreateOrUpdate.module.css';
import { copyQrCodeToNavigator } from '../../helpers/user';
import { HOME_ROUTE } from '../../store/constants/route-constants';
import type { Dispatch, RootState } from '../../store/store';

type ProfileProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const ProfilePage: FC<ProfileProps> = ({ user, changePassword, getReconnectMfaCode, verifyReconnectMfaCode }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState('');

  const [showMFAModal, setShowMFAModal] = useState(false);
  const [mfaSetupData, setMfaSetupData] = useState<{
    qrImage: string;
    code: string;
  } | null>(null);

  const codeFormik = useFormik({
    enableReinitialize: true,
    initialValues: { code: '' },
    validationSchema: Yup.object({
      code: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      verifyReconnectMfaCode({ code: values.code, email: user?.email });
      setShowMFAModal(false);
      values.code = '';
    },
  });

  const passwordFormik = useFormik({
    enableReinitialize: true,
    initialValues: { currentPassword: '', newPassword: '' },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required(),
      newPassword: Yup.string()
        .min(8, 'Minimum 8 characters required')
        .max(99, 'Maximum 99 characters required')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading('password');
      const result = await changePassword({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      });

      if (result) passwordFormik.resetForm();

      setLoading('');
    },
  });

  const handleBack = () => {
    navigate(HOME_ROUTE);
  };

  const handleOpenMfaModal = async () => {
    const mfaData = await getReconnectMfaCode();
    setMfaSetupData(mfaData);
  };

  const handleCopyQrCode = async (text: string) => {
    const result = await copyQrCodeToNavigator(text);

    if (result) toast.success('Copied to clipboard!');
    else toast.warning('Could not copy!');
  };

  return (
    <div>
      <Form className="px-2 py-2">
        <FormGroup row>
          <div className="d-flex">
            <div onClick={handleBack} className={styles.backButton}>
              <div>
                <ArrowLeft fontSize={24} className={`${styles.backIcon}`} />
              </div>
              <span className={styles.back}>Back to Collections</span>
            </div>
          </div>
        </FormGroup>

        <FormGroup row style={{ width: '500px' }} className="m-auto d-flex">
          <div className={styles.profileMainTitle}>My Profile</div>
          <ProfileAvatarForm />
          <ProfileInformationForm />
          <ProfileEmailForm />

          <div className={styles.profileTitle}>Change Password</div>
          <Col sm={6} className="fs-12 ">
            <UiInput
              name="currentPassword"
              label="Current Password"
              type="password"
              placeholder="Current Password"
              value={passwordFormik.values.currentPassword}
              onChange={passwordFormik.handleChange}
              onBlur={passwordFormik.handleBlur}
              errorText={passwordFormik.errors.currentPassword}
              showError={passwordFormik.touched.currentPassword && passwordFormik.errors.currentPassword}
            />
          </Col>
          <Col sm={6} className="fs-12 ">
            <UiInput
              name="newPassword"
              label="New Password"
              type="password"
              placeholder="New Password"
              value={passwordFormik.values.newPassword}
              onChange={passwordFormik.handleChange}
              onBlur={passwordFormik.handleBlur}
              errorText={passwordFormik.errors.newPassword}
              showError={passwordFormik.touched.newPassword && passwordFormik.errors.newPassword}
            />
          </Col>
          <Col className="d-flex justify-content-end mt-1">
            <UiButton className={styles.updateButton} onClick={() => passwordFormik.submitForm()}>
              {loading === 'password' ? <Spinner color="light" size="sm" /> : 'Save'}
            </UiButton>
          </Col>
          <div className={styles.profileTitle}>Reconnect MFA</div>
          <Col className="d-flex justify-content-start mt-1">
            <UiButton
              onClick={() => {
                setShowMFAModal(true);
                handleOpenMfaModal();
              }}
            >
              {loading === 'reconnect-mfa' ? (
                <Spinner color="light" size="sm" />
              ) : (
                <div style={{ fontSize: '16px' }}>
                  <Broadcast /> Reconnect MFA
                </div>
              )}
            </UiButton>
          </Col>
        </FormGroup>
      </Form>
      {showMFAModal && (
        <UiModal
          title="Reconnect MFA"
          onClose={() => {
            setShowMFAModal(false);
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img width={119} height={35} src={deviciLogo} alt="Devici logo" />
            </div>
            <div>
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                }}
              >
                Scan the QR code with the authenticator app of your choice.
              </div>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <img width={240} src={mfaSetupData?.qrImage} />
                <UiButton type="transparent" onClick={() => handleCopyQrCode(mfaSetupData?.code || '')}>
                  Copy key <Layers />
                </UiButton>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 20,
                  paddingLeft: 15,
                  width: 235,
                }}
              >
                <div
                  style={{
                    height: 225,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <UiInput
                    label="Enter code from the app"
                    placeholder="Enter code"
                    name="code"
                    value={codeFormik.values.code}
                    onChange={codeFormik.handleChange}
                    onBlur={codeFormik.handleBlur}
                  />
                </div>
                <UiButton
                  onClick={() => {
                    codeFormik.handleSubmit();
                  }}
                >
                  Continue
                </UiButton>
              </div>
            </div>
          </div>
        </UiModal>
      )}
    </div>
  );
};

const mapState = (state: RootState) => ({
  user: state.user?.current,
});

const mapDispatch = (dispatch: Dispatch) => ({
  changePassword: dispatch.user.changePassword,
  getReconnectMfaCode: dispatch.auth.getReconnectMfaCode,
  verifyReconnectMfaCode: dispatch.auth.verifyReconnectMfaCode,
});

export default connect(mapState, mapDispatch)(ProfilePage);
