import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import styles from '../../components/common/Auth/styles/index.module.css';

import deviciLogo from '../../assets/images/devici-logo.png';
import Placeholder from '../../components/common/Placeholder';
import PrivacyAndTerms from '../../components/common/PrivacyAndTerms';
import CodeForm from '../../components/Forms/CodeForm';
import ResendCode from '../../components/Forms/CodeForm/ResendCode';
import EmailForm from '../../components/Forms/EmailForm';
import PasswordForm from '../../components/Forms/PasswordForm';
import { AUTH_FLOW } from '../../global/auth';
import { HOME_ROUTE, SIGN_IN_ROUTE, SIGN_UP_ROUTE } from '../../store/constants/route-constants';
import type { Dispatch, RootState } from '../../store/store';

type ForgotPasswordProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const ForgotPasswordPage: FC<ForgotPasswordProps> = ({
  authFlow,
  isAuthenticated,
  forgotPassword,
  confirmForgotPassword,
  resetAuthFlow,
  isAuthLoading,
}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    return () => {
      resetAuthFlow();
      document.onkeyup = null;
    };
  }, []);

  const handleNavigate = (url: string) => {
    navigate(url);
  };

  const isConfirmCodeFlow = authFlow === AUTH_FLOW.CONFIRM_FORGOT_PASSWORD;
  const isConfirmCodeCompleted = authFlow === AUTH_FLOW.CONFIRM_FORGOT_COMPLETED;

  const getMainTitle = (isConfirmCodeFlow: boolean) => {
    if (isConfirmCodeFlow) {
      return 'Code successfully sent to email';
    }

    if (isConfirmCodeCompleted) {
      return '';
    }

    return 'Forgot password?';
  };

  if (isAuthenticated) {
    return <Navigate to={HOME_ROUTE} />;
  }

  document.onkeyup = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && isConfirmCodeCompleted && email.length && password.length) {
      handleNavigate(SIGN_IN_ROUTE);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <img src={deviciLogo} alt="Devici logo" />
          </div>
          <div className={styles.title}>{getMainTitle(isConfirmCodeFlow)}</div>
        </div>

        {!isConfirmCodeCompleted && isConfirmCodeFlow && (
          <>
            <PasswordForm title="Enter new password" email={email} isFirstPassword={false} setPassword={setPassword} />
            <CodeForm
              email={email}
              password={password}
              title="Enter code"
              handleSubmit={confirmForgotPassword}
              isLoading={isAuthLoading}
            />
          </>
        )}
        {!isConfirmCodeCompleted && !isConfirmCodeFlow && (
          <>
            <EmailForm setEmail={setEmail} handleSubmit={forgotPassword} isLoading={isAuthLoading} />
            <div className={styles.footer}>
              <div
                className={styles.footerLink}
                onClick={() => {
                  handleNavigate(SIGN_IN_ROUTE);
                }}
              >
                Return to login page
              </div>{' '}
              <span className={styles.footerLinkDivider} />
              <div className={styles.footerLink} onClick={() => handleNavigate(SIGN_UP_ROUTE)}>
                Create an account
              </div>
            </div>
          </>
        )}
        {isConfirmCodeCompleted ? (
          <Placeholder
            title="You have been successfully changed password!"
            message="Now you are able to sign in."
            btnTitle="Sign in"
            onClick={() => handleNavigate(SIGN_IN_ROUTE)}
          />
        ) : (
          !isConfirmCodeCompleted &&
          isConfirmCodeFlow &&
          email && <ResendCode email={email} handleResendCode={forgotPassword} />
        )}
        <PrivacyAndTerms />
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  authFlow: state.auth?.authFlow,
  isAuthenticated: state.auth?.isAuthenticated,
  isAuthLoading: state.auth.isAuthLoading,
});

const mapDispatch = (dispatch: Dispatch) => ({
  resetAuthFlow: dispatch.auth.resetAuthFlow,
  forgotPassword: dispatch.auth.forgotPassword,
  confirmForgotPassword: dispatch.auth.confirmForgotPassword,
});

export default connect(mapState, mapDispatch)(ForgotPasswordPage);
