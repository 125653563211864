import thinkImage from '../../../assets/images/think-image.png';
import styles from './ResizeNotification.module.css';

const ResizeNotification = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        For the best experience, please use Devici on a full-size screen, such as a laptop or desktop. The application
        does not currently support mobile or tablet screen resolutions.
      </div>
      <div className={styles.imageContainer}>
        <img src={thinkImage} alt="" />
      </div>
    </div>
  );
};

export default ResizeNotification;
