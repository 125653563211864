import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import type { ThreatModel } from '../../global/types';
import type { Dispatch } from '../../store/store';
import DateInput from '../common/DateInput/DateInput';
import ProjectAvatar from '../common/ProjectAvatar/ProjectAvatar';
import UiPriority from '../common/UiPriority/UiPriority';
import UserAvatar from '../common/UserAvatar/UserAvatar';
import CardSettings from './CardSettings';
import PriorityModal from './PriorityModal';
import styles from './RepresentationCard.module.css';
import UsersDropdown from './UsersDropdown';

type RepresentationCardProps = {
  threatModel: ThreatModel | any;
  projectId: string | number | undefined;
  title: string;
  hasInfo?: boolean;
  active?: boolean;
  index: number;
  handleEmitAfterUpdate: (isArchive?: boolean) => void;
  onTransferClick?: (project: any, selectedForTransferModel: any) => void;
  query: any;
};

const KanbanEl = document.getElementById('root') || document.body;

const RepresentationCard: FC<RepresentationCardProps> = ({
  threatModel,
  projectId,
  title,
  hasInfo = true,
  active = false,
  index,
  handleEmitAfterUpdate,
  onTransferClick,
  query,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const [date, setDate] = useState(threatModel?.due_to_date ? new Date(threatModel.due_to_date) : '');

  useEffect(() => {
    setDate(threatModel?.due_to_date ? new Date(threatModel.due_to_date) : '');
  }, [threatModel]);

  const [isButton, setIsButton] = useState(false);
  const [isSettings, setIsSettings] = useState({
    visible: false,
    pageX: 0,
    pageY: 0,
  });
  const [isPriority, setIsPriority] = useState(false);
  const [usersPopupVisible, setUsersPopupVisible] = useState(false);

  const handlePriorityChange = (newPriority: any) => {
    setIsPriority(false);
    threatModel.priority = newPriority;
    dispatch.threatModel.updateThreatModel(threatModel);
    handleEmitAfterUpdate && handleEmitAfterUpdate();
  };

  const handleMouseOver = () => {
    setIsButton(true);
  };

  const handleMouseLeave = () => {
    setIsButton(false);
    setIsSettings({ visible: false, pageX: 0, pageY: 0 });
    setIsPriority(false);
    setUsersPopupVisible(false);
  };

  const handleSettingsButtonClick = (event: any) => {
    event.stopPropagation();
    setIsSettings({ visible: true, pageX: event.pageX, pageY: event.pageY });
  };

  const handleCardClick = (event: any) => {
    event.stopPropagation();
    dispatch.app.setSidebarVisible(false);

    if (!threatModel?.representations?.length) {
      toast.warning('Representation not found!');

      return;
    }

    dispatch.threatModel.setCurrentThreatModel(threatModel);

    if (!threatModel?.representations[0]?.id) {
      toast.error('Diagram not found!');

      return;
    }

    if (!projectId) {
      if (!threatModel?.project?.id) {
        toast.error('Parent collection not found!');

        return;
      }

      navigate(`/collections/${threatModel?.project?.id}/d/${threatModel?.representations[0]?.id}`);
    } else {
      navigate(`/collections/${projectId}/d/${threatModel?.representations[0]?.id}`);
    }
  };

  const handleDateChange = (newValue: any) => {
    setDate(newValue);
    threatModel.due_to_date = newValue;
    dispatch.threatModel.updateThreatModel(threatModel);
    handleEmitAfterUpdate && handleEmitAfterUpdate();
  };

  const handleUpdateThreatModelColumn = () => {
    dispatch.threatModel.getThreatModelsByQuery({
      ...query,
      projectId,
      page: 0,
      fetch: true,
    });
    handleEmitAfterUpdate && handleEmitAfterUpdate();
  };

  return (
    <Draggable draggableId={threatModel.id} index={index}>
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onClick={handleCardClick}
            className={classNames(styles.card, {
              [styles.active]: active,
            })}
          >
            {!projectId && (
              <div className={styles.header}>
                <div className={styles.projectSection}>
                  <ProjectAvatar
                    title={threatModel?.project?.title || ''}
                    color={threatModel?.project?.color || '#C4C4C4'}
                  />
                  <div title={threatModel?.project?.title} className={styles.projectTitle}>
                    {threatModel?.project?.title}
                  </div>
                </div>
                {isButton && !projectId && (
                  <div onClick={handleSettingsButtonClick} className={styles.projectDetailButton}>
                    <ThreeDotsVertical size={20} className={styles.headerButton} />
                  </div>
                )}
              </div>
            )}
            <div className={styles.header}>
              <div className={styles.title}>
                <a className="mb-0 text-decoration-none cursor-pointer text-black">{title}</a>
              </div>
              {isButton && projectId && (
                <div onClick={handleSettingsButtonClick} className={styles.projectDetailButton}>
                  <ThreeDotsVertical size={20} className={styles.headerButton} />
                </div>
              )}
            </div>
            <div className={styles.body}>
              <div className={styles.description}>{threatModel.description}</div>
              <div className={styles.priority}>
                <div>
                  <UiPriority
                    type={threatModel.priority}
                    onClick={(event: any) => {
                      event.stopPropagation();
                      setIsPriority(!isPriority);
                    }}
                  />
                </div>
                {isPriority && <PriorityModal onClick={handlePriorityChange} currentPriority={threatModel.priority} />}
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.footerLeft}>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setUsersPopupVisible(true);
                  }}
                >
                  <UserAvatar user={threatModel.owner} showPopUpOnHover={false} className="me-1" />
                  {usersPopupVisible && (
                    <UsersDropdown
                      hide={() => {
                        setUsersPopupVisible(false);
                        handleUpdateThreatModelColumn();
                      }}
                      threatModelId={threatModel.id}
                    />
                  )}
                </div>
                {hasInfo && <DateInput value={date} onChange={handleDateChange} placeholder="Due date" />}
                {!hasInfo && <DateInput />}
              </div>
            </div>
            {isSettings.visible &&
              createPortal(
                <CardSettings
                  currentItem={threatModel}
                  position={isSettings}
                  handleEmitAfterUpdate={handleEmitAfterUpdate}
                  onTransferClick={onTransferClick}
                  setIsSettings={setIsSettings}
                />,
                KanbanEl,
              )}
          </div>
        );
      }}
    </Draggable>
  );
};

export default RepresentationCard;
