import classNames from 'classnames';
import type { FC } from 'react';
import { Check2All, Clipboard2X, ClockHistory, XLg } from 'react-bootstrap-icons';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';

import { MITIGATION_STATUS, MITIGATION_STATUS_TEXT } from '../../../store/constants/drawn-constants';
import type { IMitigation, IThreat } from '../../../store/models/drawn';
import type { Dispatch } from '../../../store/store';
import styles from './ThreatRegisterTable.module.css';

type ThreatRegisterTableMitigationDropdownProps = {
  threat: IThreat;
  mitigation: IMitigation;
  setVisible: (val: any) => void;
  handleEmitAction: () => void;
};

const statuses = {
  [MITIGATION_STATUS.DONE]: MITIGATION_STATUS_TEXT.COMPLETE,
  [MITIGATION_STATUS.WILL]: MITIGATION_STATUS_TEXT.QUEUED_FOR_ACTION,
  [MITIGATION_STATUS.NEVER]: MITIGATION_STATUS_TEXT.NOT_APPLICABLE,
};

export const ThreatRegisterTableMitigationDropdown: FC<ThreatRegisterTableMitigationDropdownProps> = ({
  setVisible,
  threat,
  mitigation,
  handleEmitAction,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const handleClose = () => {
    setVisible({
      threatId: '',
      id: '',
    });
  };

  const handleMitigationStatusChange = async (newStatus: MITIGATION_STATUS | null) => {
    const data = {
      status: newStatus,
    };
    setVisible(false);
    dispatch.threats.updateThreatFromThreatRegisterList({
      id: threat.id,
      mitigation: threat?.mitigations?.map((m) => {
        if (m.id === mitigation.id) {
          mitigation.status = newStatus;
        }

        return m;
      }),
    });
    dispatch.drawn.setThreat({
      ...threat,
      mitigation: threat?.mitigations?.map((m) => {
        if (m.id === mitigation.id) {
          mitigation.status = newStatus;
        }

        return m;
      }),
    });
    await dispatch.drawn.updateMitigation({
      id: mitigation.id,
      threatsId: threat.id,
      mitigation: data,
      isThreatRegister: true,
    });
    await dispatch.threatModel.updateCurrentThreatModel();
    handleEmitAction();
  };

  return (
    <OutsideClickHandler onOutsideClick={handleClose}>
      <ul
        className="list-group list-group-horizontal shadow bg-white gap-1 align-items-center p-1"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {Object.values(MITIGATION_STATUS)
          .filter((s: any) => s !== mitigation?.status)
          .map((status) => (
            <li
              key={status}
              className={classNames(
                styles.hasTooltip,
                styles.mitigationTooltip,
                'list-group-item position-relative d-flex align-items-center justify-content-center',
                {
                  [styles.mitigationStatusDone]: status === MITIGATION_STATUS.DONE,
                  [styles.mitigationStatusWill]: status === MITIGATION_STATUS.WILL,
                  [styles.mitigationStatusNever]: status === MITIGATION_STATUS.NEVER,
                  [styles.mitigationStatusDefault]: !status?.length,
                },
              )}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleMitigationStatusChange(status);
              }}
              title={statuses[status] || MITIGATION_STATUS_TEXT.UNCLASSIFIED}
              data-tooltip={statuses[status] || MITIGATION_STATUS_TEXT.UNCLASSIFIED}
            >
              {status === MITIGATION_STATUS.DONE && <Check2All color="#36A159" fontSize={15} />}
              {status === MITIGATION_STATUS.WILL && <ClockHistory color="#ebac0acc" fontSize={15} />}
              {status === MITIGATION_STATUS.NEVER && <Clipboard2X color="#e83c2db3" fontSize={15} />}
            </li>
          ))}
        <li
          className={classNames(
            styles.hasTooltip,
            styles.mitigationTooltip,
            styles.mitigationStatusDefault,
            'list-group-item position-relative d-flex align-items-center justify-content-center',
          )}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleMitigationStatusChange(null);
          }}
          title={MITIGATION_STATUS_TEXT.UNCLASSIFIED}
          data-tooltip={MITIGATION_STATUS_TEXT.UNCLASSIFIED}
        >
          <XLg color="#5a5a5eb3" fontSize={15} />
        </li>
      </ul>
    </OutsideClickHandler>
  );
};
