import type { FC } from 'react';
import { useState } from 'react';
import { PlusLg, XLg } from 'react-bootstrap-icons';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import UiButton from '../../common/UIButton/UiButton';
import UiInput from '../../common/UiInput/UiInput';
import { AliasItem } from './AliasItem';
import styles from './CustomCodexTable.module.css';

let timer: any = null;

type ThreatMitigationsFormProps = {
  items: any[];
  title: string;
  setItems: (ids: string[]) => void;
  type: 'mitigations' | 'attributes';
  excludeItems?: string[];
};

export const ThreatItemsForm: FC<ThreatMitigationsFormProps> = ({ title, items, type, setItems, excludeItems }) => {
  const { mitigations: foundMitigations, attributes: foundAttributes } = useTypedSelector((state) => state.customCodex);
  const dispatch = useDispatch<Dispatch>();
  const [value, setValue] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const foundItems = type === 'mitigations' ? foundMitigations : foundAttributes;

  const clearFoundItems = () => {
    if (type === 'attributes') dispatch.customCodex.setAttributes(null);

    if (type === 'mitigations') dispatch.customCodex.setMitigations(null);
  };

  const searchItems = async (value: string) => {
    setLoading(true);

    if (type === 'mitigations') await dispatch.customCodex.searchMitigations(value);

    if (type === 'attributes') await dispatch.customCodex.searchAttribute(value);

    setLoading(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);

    if (!value.length) clearFoundItems();

    if (value.length > 1) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        searchItems(value);
      }, 500);
    }
  };

  const textTitle = title === 'mitigations' ? 'mitigation' : 'attribute';
  const preparedItems = foundItems?.filter(
    (fm) => !items.some((m) => m.id === fm.id) && !excludeItems?.includes(fm.id),
  );

  return (
    <div className="mt-2 fs-12">
      <span className="fw-bold text-capitalize">{title}:</span>
      <div className="my-1 d-flex gap-1 flex-wrap">
        {items?.map((item) => (
          <AliasItem
            key={item.id}
            title={item.title}
            onDelete={() => {
              setItems(items.filter((m: any) => m.id !== item.id));
            }}
            isCurrent={false}
          />
        ))}
        {!items.length && `No ${textTitle}s`}
      </div>

      {showForm && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setValue('');
            setShowForm(false);
            clearFoundItems();
          }}
        >
          <div className={styles.mitigationsWrap}>
            <UiInput
              name={title}
              value={value}
              onBlur={() => {}}
              onChange={handleChange}
              placeholder={`Enter ${textTitle} name`}
              autoFocus
            />
            {Boolean(value.length) && (
              <>
                <XLg
                  size={16}
                  className={styles.mitigationSearchDelete}
                  onClick={() => {
                    setValue('');
                    setShowForm(false);
                    clearFoundItems();
                  }}
                />
                <div className={styles.foundMitigaitons}>
                  {preparedItems?.map((mitigation) => (
                    <AliasItem
                      key={mitigation.id}
                      title={mitigation.title}
                      onClick={() => setItems([...items, mitigation])}
                      isCurrent={false}
                    />
                  ))}
                  {!preparedItems?.length && (
                    <span className="text-center w-100">{loading ? <Spinner size="sm" /> : 'Not found'}</span>
                  )}
                </div>
              </>
            )}
          </div>
        </OutsideClickHandler>
      )}

      {!showForm && (
        <UiButton
          className="ps-0"
          type="transparent"
          style={{ height: 30 }}
          onClick={() => {
            setShowForm(true);
          }}
        >
          <PlusLg className="cursor-pointer me-1" /> <span className="fs-12">Add {textTitle}</span>
        </UiButton>
      )}
    </div>
  );
};
