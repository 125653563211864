import classNames from 'classnames';
import type { FC, SyntheticEvent } from 'react';
import { useState } from 'react';
import { TypeBold } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';

import type { NodeData } from '../../../global/types';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from './NodeStylePicker.module.css';

type NodeStylePickerProps = {
  node: NodeData;
  isEdge?: boolean;
};

const NodeStylePicker: FC<NodeStylePickerProps> = ({ node, isEdge }) => {
  const dispatch = useDispatch<Dispatch>();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { nodesSharedState, edgesSharedState, defaultEdgeStyle } = useTypedSelector((state) => state.diagram);

  const handleSetBoldFontWeight = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (isEdge) {
      const inEdge = edgesSharedState?.get(node.id);

      if (inEdge) {
        inEdge.data = {
          ...inEdge.data,
          textStyle: {
            isBold: !inEdge.data?.textStyle?.isBold,
          },
        };
        dispatch.diagram.setDefaultEdgeStyle({
          ...defaultEdgeStyle,
          data: {
            ...defaultEdgeStyle.data,
            textStyle: inEdge.data.textStyle,
          },
        });
        edgesSharedState?.set(inEdge.id, inEdge);
      }
    } else {
      const inNode = nodesSharedState?.get(node.id);

      if (inNode) {
        inNode.data = {
          ...inNode.data,
          textStyle: {
            isBold: !inNode.data?.textStyle?.isBold,
          },
        };
        nodesSharedState?.set(inNode.id, inNode);
      }
    }
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div onClick={(e) => e.stopPropagation()} className={styles.wrapper}>
      <div
        className={classNames(styles.icon, {
          [styles.active]: node?.textStyle?.isBold,
        })}
        onClick={handleSetBoldFontWeight}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {showTooltip && <div className="main-tooltip">Font Weight</div>}
        <TypeBold size={24} color="black" />
      </div>
    </div>
  );
};

export default NodeStylePicker;
