export enum THREAT_STATUS {
  OPEN = 'open',
  COMPLETE = 'complete',
  NEED_INVESTIGATION = 'needs investigation',
  REVIEWING = 'reviewing',
  NOT_APPLICABLE = 'not applicable',
}

export enum THREAT_MODEL_STATUS {
  REPRESENTATION = 'Representation',
  THREATS_AND_MITIGATIONS = 'Threats & Mitigations',
  RETROSPECTIVE = 'Retrospective',
  DONE = 'Done',
}

export enum THREAT_PRIORITY {
  CRITICAL = 'critical',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum ELEMENT_TYPE {
  EXTERNAL_ENTITY_NODE = 'externalEntityNode',
  PROCESS_NODE = 'processNode',
  DATA_STORE = 'datastoreNode',
  DATA_FLOW = 'dataflow',
}

export enum MITIGATION_STATUS {
  DONE = 'done',
  WILL = 'will',
  NEVER = 'never',
}

export enum MITIGATION_STATUS_TEXT {
  COMPLETE = 'Complete',
  QUEUED_FOR_ACTION = 'Queued for Action',
  NOT_APPLICABLE = 'Not Applicable',
  UNCLASSIFIED = 'Unclassified',
}

export enum DRAWN_WIDTH {
  MIN = 315,
  MAX = 850,
}
