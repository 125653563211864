import { useCallback, useEffect, useRef } from 'react';
import type { Edge, Node, XYPosition } from 'reactflow';
import { getConnectedEdges, useReactFlow, useStore } from 'reactflow';
import { useSocket } from './useSocket';
import { useTypedSelector } from './useTypeSelector';

export function useCopyPaste<NodeData, EdgeData>({ nodesSharedState, edgesSharedState, dispatch }: any) {
  const mousePosRef = useRef<XYPosition>({ x: 0, y: 0 });
  const rfDomNode = useStore((state) => state.domNode);
  const { emitGlobalUpdate } = useSocket();

  const { currentThreatModel } = useTypedSelector((state) => state.threatModel);
  const representationId = useTypedSelector((state) => state.representation.data?.id);
  const { getNodes, setNodes, getEdges, setEdges, screenToFlowPosition } = useReactFlow<NodeData, EdgeData>();
  const {
    bufferedEdges,
    bufferedNodes,
    copyPasteType: type,
    copyPasteRepresentationId,
  } = useTypedSelector((state) => state.diagram);
  const currentUserId = useTypedSelector((state) => state.user.current?.id);

  const handleEmitAfterModelUpdate = () => {
    if (currentThreatModel) {
      emitGlobalUpdate({ threatModels: [currentThreatModel.id] });
    }
  };

  useEffect(() => {
    if (rfDomNode) {
      const events = ['cut', 'copy', 'paste'];
      const preventDefault = (e: any) => {
        if (e?.target?.dataset?.id !== 'custom-diagram-input') {
          e.preventDefault();
        }
      };
      const onMouseMove = (event: MouseEvent) => {
        const bounds = rfDomNode.getBoundingClientRect();
        mousePosRef.current = {
          x: event.clientX - (bounds?.left ?? 0),
          y: event.clientY - (bounds?.top ?? 0),
        };
      };
      for (const event of events) {
        rfDomNode.addEventListener(event, preventDefault);
      }
      rfDomNode.addEventListener('mousemove', onMouseMove);

      return () => {
        for (const event of events) {
          rfDomNode.removeEventListener(event, preventDefault);
        }
        rfDomNode.removeEventListener('mousemove', onMouseMove);
      };
    }
  }, [rfDomNode]);

  const copy = useCallback(() => {
    const selectedNodes = getNodes().filter((node) => node.selected);
    const selectedEdges = getConnectedEdges(selectedNodes, getEdges()).filter((edge) => {
      const isExternalSource = selectedNodes.every((n) => n.id !== edge.source);
      const isExternalTarget = selectedNodes.every((n) => n.id !== edge.target);

      return !(isExternalSource || isExternalTarget);
    });

    dispatch.diagram.setBufferedNodes(selectedNodes);
    dispatch.diagram.setBufferedEdges(selectedEdges);
    dispatch.diagram.setCopyPasteType('copy');
    dispatch.diagram.setCopyPasteRepresentationId(representationId);
  }, [getNodes, getEdges]);

  const cut = useCallback(() => {
    const selectedNodes = getNodes().filter((node) => node.selected);
    const selectedEdges = getConnectedEdges(selectedNodes, getEdges()).filter((edge) => {
      const isExternalSource = selectedNodes.every((n) => n.id !== edge.source);
      const isExternalTarget = selectedNodes.every((n) => n.id !== edge.target);

      return !(isExternalSource || isExternalTarget);
    });

    dispatch.diagram.setBufferedNodes(selectedNodes);
    dispatch.diagram.setBufferedEdges(selectedEdges);

    selectedNodes.forEach((n) => nodesSharedState?.delete(n.id));
    selectedEdges.forEach((e) => edgesSharedState?.delete(e.id));

    // A cut action needs to remove the copied nodes and edges from the graph.
    setNodes((nodes) => nodes.filter((node) => !node.selected));
    setEdges((edges) => edges.filter((edge) => !selectedEdges.includes(edge)));
    dispatch.diagram.setCopyPasteType('cut');
    dispatch.diagram.setCopyPasteRepresentationId(representationId);
  }, [getNodes, setNodes, getEdges, setEdges]);

  const paste = useCallback(
    (
      { x: pasteX, y: pasteY } = screenToFlowPosition({
        x: mousePosRef.current.x,
        y: mousePosRef.current.y,
      }),
    ) => {
      setNodes((nodes) =>
        nodes.map((node) => {
          node.selected = false;
          nodesSharedState?.set(node.id, { ...node, selected: false });

          return node;
        }),
      );
      setEdges((edges) =>
        edges.map((edge) => {
          edge.selected = false;
          edgesSharedState?.set(edge.id, { ...edge, selected: false });

          return edge;
        }),
      );
      const minX = Math.min(...bufferedNodes.map((s: any) => s.position.x));
      const minY = Math.min(...bufferedNodes.map((s: any) => s.position.y));

      const getId = () => `${window.crypto.randomUUID()}`;
      const newOldNodeIdPairs: any = {};
      const newOldEdgeIdPairs: any = {};

      const newNodes: Node<NodeData>[] = bufferedNodes.map((node: any) => {
        const id = type === 'cut' ? node.id : getId();
        const x = pasteX + (node.position.x - minX);
        const y = pasteY + (node.position.y - minY);
        newOldNodeIdPairs[node.id] = id;

        const newNode = structuredClone({
          ...node,
          id,
          position: { x, y },
          selected: false,
          data: {
            ...node.data,
            representation: representationId,
            selectedBy: node.data?.selectedBy?.filter((i: string) => i !== currentUserId) || [],
          },
        });
        nodesSharedState?.set(newNode.id, newNode);

        return newNode;
      });

      const newEdges: Edge<EdgeData>[] = bufferedEdges.map((edge: any) => {
        const id = type === 'cut' ? edge.id : getId();
        const source = newOldNodeIdPairs[edge.source];
        const target = newOldNodeIdPairs[edge.target];
        newOldEdgeIdPairs[edge.id] = id;

        const newEdge = structuredClone({
          ...edge,
          id,
          source,
          target,
          selected: false,
          data: {
            ...edge.data,
            representation: representationId,
            selectedBy: edge.data?.selectedBy?.filter((i: string) => i !== currentUserId) || [],
          },
        });
        edgesSharedState?.set(newEdge.id, newEdge);

        return newEdge;
      });

      if (type === 'copy') {
        dispatch.drawn.makeManyComponentCopy({
          data: {
            ...newOldNodeIdPairs,
            ...newOldEdgeIdPairs,
          },
          representationId,
        });
        setTimeout(async () => {
          await dispatch.threatModel.updateCurrentThreatModel();
          await handleEmitAfterModelUpdate();
        }, 3000);
      }

      if (type === 'cut') {
        if (copyPasteRepresentationId !== representationId) {
          const components = [...bufferedNodes.map((n) => n.id), ...bufferedEdges.map((e) => e.id)];

          if (components.length) {
            dispatch.drawn.updateManyComponents({
              representationId,
              components,
            });
          }
        }

        dispatch.diagram.setCopyPasteType('copy');
      }

      setNodes((nodes) => [...nodes.map((node) => ({ ...node, selected: false })), ...newNodes]);
      setEdges((edges) => [...edges.map((edge) => ({ ...edge, selected: false })), ...newEdges]);
    },
    [bufferedNodes, bufferedEdges, screenToFlowPosition, setNodes, setEdges, type],
  );

  return { cut, copy, paste, bufferedNodes, bufferedEdges, coords: mousePosRef.current };
}

export default useCopyPaste;
