import classNames from 'classnames';
import { useState, type FC } from 'react';
import { ArrowLeft, BoxArrowDown, Clipboard2Check } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import OutsideClickHandler from 'react-outside-click-handler';
import { useSearchParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { TIER } from '../../../global/constants';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { ICollaboratorSharedState } from '../../../pages/DiagramsPage';
import { PROJECTS_ROUTE } from '../../../store/constants/route-constants';
import {
  THREATS_REGISTER_SELECTED_COMPONENT_ID,
  THREAT_REPORT_TYPE,
} from '../../../store/constants/threat-register-constants';
import type { Dispatch } from '../../../store/store';
import UserAvatar from '../../common/UserAvatar/UserAvatar';
import { DiagramPercentage } from '../../Diagram/DiagramTools/DiagramPercentage';
import { DiagramTitle } from '../../Diagram/DiagramTools/DiagramTitle';
import styles from './ThreatRegisterHeader.module.css';

interface IProps {
  collaborators?: ICollaboratorSharedState[];
}

export const ThreatRegisterHeader: FC<IProps> = ({ collaborators }) => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const params = useParams();
  const current = useTypedSelector((state) => state.user?.current);
  const { visible, drawnWidth } = useTypedSelector((state) => state.drawn);
  const { currentThreatModel } = useTypedSelector((state) => state.threatModel);
  const customerLimits = useTypedSelector((state) => state.tiers.customerLimits);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState<THREAT_REPORT_TYPE | ''>('');
  const [_, setSearchParams] = useSearchParams();

  const handleBack = () => {
    dispatch.threats.setThreatRegisterSelectedThreat(null);
    localStorage.removeItem(THREATS_REGISTER_SELECTED_COMPONENT_ID);
    navigate(`${PROJECTS_ROUTE}/${params.id}/d/${params.diagramId}`);
  };

  const handleDownloadCsvReport = async (type: THREAT_REPORT_TYPE) => {
    const isFree = customerLimits?.tier === TIER.FREE;

    if (isFree) {
      setSearchParams({ show_plans: 'true' });

      return;
    }

    setLoading(type);
    await dispatch.threats.exportCsvThreatRegister({
      threatModelId: currentThreatModel?.id,
      type,
    });
    setLoading('');
  };

  const renderUsers = () => {
    const data =
      collaborators?.filter(
        (user) => user?.first_name !== current?.first_name && user?.last_name !== current?.last_name,
      ) || [];

    return data.map((user, index) => (
      <div className={styles.user} key={index}>
        <UserAvatar user={user} />
      </div>
    ));
  };

  return (
    <div style={{ marginRight: visible ? drawnWidth + 10 : 10 }} className={styles.headWrap}>
      <div className="d-flex gap-2 position-relative">
        <div className={classNames('rounded-1 shadow', styles.toolItem)}>
          <div className={styles.toolItemIcon} onClick={handleBack}>
            <ArrowLeft size={18} color="#7A94B0" />
          </div>
        </div>
        <DiagramTitle showCanvases={false} />
        <DiagramPercentage isThreatRegister />
        <div className={classNames('block rounded-1 shadow bg-white user-select-none', styles.toolItem)}>
          <div
            data-tooltip="Export"
            className={classNames(styles.toolItemIcon, styles.hasTooltip, {
              [styles.disableTooltip]: dropdownVisible,
            })}
            onClick={() => setDropdownVisible(!dropdownVisible)}
            id="dropdown-trigger"
          >
            <BoxArrowDown size={20} id="dropdown-trigger" />
          </div>
        </div>
        {dropdownVisible && (
          <div className={classNames(styles.exportModal, 'shadow rounded-1')}>
            <OutsideClickHandler
              onOutsideClick={(e: any) => {
                if (e.target.id !== 'dropdown-trigger') {
                  setDropdownVisible(false);
                }
              }}
            >
              <>
                <div onClick={() => handleDownloadCsvReport(THREAT_REPORT_TYPE.THREAT)}>
                  {loading === THREAT_REPORT_TYPE.THREAT ? (
                    <Spinner size="sm" className={styles.spinner} />
                  ) : (
                    <p>Export Threats Register</p>
                  )}
                </div>
                <div onClick={() => handleDownloadCsvReport(THREAT_REPORT_TYPE.ELEMENT)}>
                  {loading === THREAT_REPORT_TYPE.ELEMENT ? (
                    <Spinner size="sm" className={styles.spinner} />
                  ) : (
                    <p>Export Elements Register</p>
                  )}
                </div>
              </>
            </OutsideClickHandler>
          </div>
        )}
      </div>
      <div className="d-flex gap-2 align-items-center">
        {renderUsers()}
        <div className={classNames('block rounded-1 shadow bg-white', styles.topRightToolItem)}>
          <div
            className={classNames(styles.toolItemIcon, {
              [styles.activeTool]: true,
            })}
            onClick={handleBack}
          >
            <Clipboard2Check className="d-block" fontSize={20} color={visible ? '#FFFFFF' : '#7A94B0'} />
          </div>
        </div>
      </div>
    </div>
  );
};
