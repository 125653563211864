import { useRef, type FC, type PropsWithChildren, type ReactNode } from 'react';
import ReactDOM from 'react-dom';

import { XSquareFill } from 'react-bootstrap-icons';
import { Modal } from 'reactstrap';
import useResizeObserver from '../../../hooks/useResizeObserver';
import classes from './UiModal.module.css';

type ModalOverlyProps = { width?: number; onClick: any; title: ReactNode; isCloseButton?: boolean } & PropsWithChildren;

const ModalOverlay: FC<ModalOverlyProps> = ({ children, width = 475, onClick, title, isCloseButton = true }) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleResize = (element: Element) => {
    const { width } = element.getBoundingClientRect();

    if (ref.current) ref.current.style.marginLeft = `${width / 2}px`;
  };

  useResizeObserver(document.getElementById('sidebar-element'), handleResize);

  return (
    <Modal
      contentClassName={classes.modalContent}
      innerRef={ref}
      backdrop
      toggle={onClick}
      centered
      style={{ width }}
      isOpen
    >
      <div className={classes.modalHeader}>
        <div className={classes.modalTitle}>{title}</div>
        {isCloseButton && (
          <div onClick={onClick} className={classes.closeButton}>
            <XSquareFill size={30} color="#0F0F10" />
          </div>
        )}
      </div>
      <div className={classes.modalBody}>{children}</div>
    </Modal>
  );
};

const portalElement = document.getElementById('overlays');

export type UiModalProps = PropsWithChildren & {
  onClose?: any;
  width?: number;
  title: ReactNode;
  isCloseButton?: boolean;
};

const UiModal: FC<UiModalProps> = ({ children, onClose, width, title, isCloseButton }) => {
  return ReactDOM.createPortal(
    <ModalOverlay title={title} onClick={onClose} width={width} isCloseButton={isCloseButton}>
      {children}
    </ModalOverlay>,
    portalElement as HTMLElement,
  );
};

export default UiModal;
