import type { FC } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import styles from './ThreatRegisterTable.module.css';

type ThreatRegisterTableSortColumnProps = {
  column: string;
  onSort?: (column: string) => void;
};

export enum ORDER_TYPE {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const ThreatRegisterTableSortColumn: FC<ThreatRegisterTableSortColumnProps> = ({ column, onSort }) => {
  const handleSort = () => {
    if (onSort) {
      onSort(column);
    }
  };

  return (
    <div className={styles.tableSort} onClick={handleSort}>
      <CaretUpFill className={styles.tableSortIconUp} />
      <CaretDownFill className={styles.tableSortIconDown} />
    </div>
  );
};
