import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ProjectSubHeader from '../../components/Project/ProjectSubHeader';
import RightBar from '../../components/RightBar/RightBar';
import ThreatModelsKanbanMode from '../../components/ThreatModels/ThreatModelsKanbanMode';
import ThreatModelsListMode from '../../components/ThreatModels/ThreatModelsListMode';
import type { Dispatch } from '../../store/store';

const MyModelsPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const [isListMode, setIsListMode] = useState<boolean>(false);

  useEffect(() => {
    dispatch.project.getProjects(false);

    return () => {
      dispatch.threatModel.setThreatModels(null);
    };
  }, []);

  const [selectedProject, setSelectedProject] = useState<any>();
  const [selectedForTransferModel, setSelectedForTransferModel] = useState<any>();
  const [selectedModelProject, setSelectedModelProject] = useState<any>();

  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const [isTransferring, setIsTransferring] = useState(false);

  const handleNavItemClick = () => {
    setIsListMode(!isListMode);

    if (!isListMode) {
      navigate(`list`, { replace: true });
    } else {
      navigate(`../my-models/`, { replace: true });
    }
  };

  const handleOpenRightSidebar = async () => {
    await setShowRightSidebar(true);
    setIsTransferring(false);
    setSelectedProject(undefined);
  };

  const handlerTransferClick = async (selectedProjectForTransfer: any, selectedForTransferModel: any) => {
    setIsTransferring(true);
    setShowRightSidebar(true);
    setSelectedForTransferModel(selectedForTransferModel);
    setSelectedModelProject(selectedProjectForTransfer);
    setSelectedProject(undefined);
  };

  return (
    <div>
      <div style={{ padding: '10px', position: 'relative', overflow: 'hidden' }}>
        <ProjectSubHeader
          onNavItemClick={handleNavItemClick}
          isListMode={isListMode}
          onThreatModelCreate={handleOpenRightSidebar}
          isMyModel
        />

        <Routes>
          <Route path="/" element={<ThreatModelsKanbanMode onTransferClick={handlerTransferClick} />} />
          <Route path="/list" element={<ThreatModelsListMode isGroupedByProject />} />
        </Routes>

        <RightBar
          visible={showRightSidebar}
          setVisible={setShowRightSidebar}
          isTransferring={isTransferring}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          selectedForTransferModel={selectedForTransferModel}
          isMyModel
          selectedModelProject={selectedModelProject}
        />
      </div>
    </div>
  );
};

export default MyModelsPage;
