import type { FC } from 'react';
import { useState } from 'react';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { SKELETON_COLORS } from '../../global/constants';
import type { Project, Running } from '../../global/types';
import { HOME_ROUTE } from '../../store/constants/route-constants';
import type { Dispatch } from '../../store/store';
import ProjectAvatar from '../common/ProjectAvatar/ProjectAvatar';
import RunningDropDown from '../ThreatModels/RunningDropDown/RunningDropDown';
import ArchiveDeleteModal from './archive/ArchiveDeleteModal';
import styles from './ProjectHeader.module.css';
import ProjectSettingsModal from './ProjectSettingsModal';

type ProjectHeaderProps = {
  project: Project;
  currentRunning?: Running;
  onRunningChange?: (running: Running) => void;
  isRepositoryMode?: boolean;
};

const ProjectHeader: FC<ProjectHeaderProps> = ({
  project,
  onRunningChange,
  currentRunning,
  isRepositoryMode = false,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const [showSettings, setShowSettings] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const handleSettingsArchive = () => {
    setShowArchiveModal(true);
  };

  const handleArchive = async () => {
    await dispatch.project.archiveProject(project.id);
    await setShowArchiveModal(false);
    navigate(HOME_ROUTE);
  };

  const handleSettingsClick = () => {
    setShowSettings(!showSettings);
  };

  const handleRunningChange = (chosenRunning: Running) => {
    if (onRunningChange) {
      onRunningChange(chosenRunning);
    }
  };

  return (
    <>
      <div className={styles.nav} data-testid="collection-page-header">
        {project?.title && <ProjectAvatar title={project?.title} color={project?.color || '#C4C4C4'} noHover />}
        {!project?.title && (
          <Skeleton
            style={{ lineHeight: '18px' }}
            width={40}
            height={38}
            baseColor={SKELETON_COLORS.BASE}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
          />
        )}
        {project?.title && <div className={styles.title}>{project?.title}</div>}
        {!project?.title && (
          <Skeleton
            style={{ lineHeight: '18px' }}
            width={150}
            height={38}
            baseColor={SKELETON_COLORS.BASE}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
          />
        )}
        {project?.title && (
          <div className={styles.settingsIcon} onClick={handleSettingsClick} data-testid="collection-settings-button">
            <ThreeDotsVertical color="#000" size={16} />
          </div>
        )}
        {showSettings && (
          <div className={styles.settingsModal}>
            <ProjectSettingsModal
              project={project}
              setVisible={setShowSettings}
              onArchiveClick={() => handleSettingsArchive()}
              showOTMImport
            />
          </div>
        )}
        {isRepositoryMode && (
          <div className={styles.runningDropDown}>
            <RunningDropDown onChange={handleRunningChange} options={project.runnings} value={currentRunning} />
          </div>
        )}
      </div>
      {showArchiveModal && (
        <ArchiveDeleteModal
          onArchive={handleArchive}
          onCancel={() => setShowArchiveModal(false)}
          title={project?.title || ''}
        />
      )}
    </>
  );
};

export default ProjectHeader;
