import type { FC } from 'react';
import { cloneElement, useEffect, useState } from 'react';
import { Star } from 'react-bootstrap-icons';
import { useSearchParams } from 'react-router-dom';
import { TIER } from '../../global/constants';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import ChoosePricingModal from '../ChoosePricingModal/ChoosePricingModal';
import UiButton from '../common/UIButton/UiButton';

type Props = {
  trigger?: React.ReactNode;
};

/**
 * @param trigger - Custom trigger element that will open ChoosePricingModal
 * @returns UpgradePlan button that opens ChoosePlanModal or nothing if user is subscribed to business plan
 * @see ChoosePricingModal
 */
const UpgradePlan: FC<Props> = ({ trigger }) => {
  const { customerLimits } = useTypedSelector((state) => state.tiers);
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const showPlans = searchParams.get('show_plans') === 'true';
    setIsPricingModalOpen(showPlans);
  }, [searchParams]);

  const toggle = () => {
    const showPlans = searchParams.get('show_plans') === 'true';

    if (showPlans) {
      setSearchParams({});
    } else {
      setSearchParams({ show_plans: 'true' });
    }
  };

  if (!customerLimits) {
    return null;
  }

  if (customerLimits?.tier !== TIER.FREE) {
    return null;
  }

  if (customerLimits?.isBetaUser) {
    return null;
  }

  return (
    <>
      {trigger ? (
        cloneElement(trigger as React.ReactElement, { onClick: toggle })
      ) : (
        <UiButton type="accent" onClick={toggle}>
          <Star />
          <span style={{ marginLeft: '8px' }}>Upgrade Plan</span>
        </UiButton>
      )}
      {isPricingModalOpen && <ChoosePricingModal onClose={toggle} />}
    </>
  );
};

export default UpgradePlan;
