import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import ProjectDetail from '../../components/Project/ProjectDetail';
import ProjectHeader from '../../components/Project/ProjectHeader';
import ProjectSubHeader from '../../components/Project/ProjectSubHeader';
import { PROJECT_TYPE } from '../../global/types';
import { useSocket } from '../../hooks/useSocket';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';

const ProjectDetailPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { id: projectId } = params;
  const [isListMode, setIsListMode] = useState<boolean>(params['*']?.includes('list') || false);
  const dispatch = useDispatch<Dispatch>();
  const { emitGlobalUpdate } = useSocket();
  const { current, liveUpdateData: projectLiveUpdateData } = useTypedSelector((state) => state.project);

  const [currentRunning, setCurrentRunning] = useState(current?.runnings?.[0] || undefined);
  const [isRepositoryMode, setIsRepositoryMode] = useState(false);

  useEffect(() => {
    if (current?.type === PROJECT_TYPE.REPOSITORY) {
      setCurrentRunning(current?.runnings?.[0] || undefined);
    }

    setIsRepositoryMode(current?.type === PROJECT_TYPE.REPOSITORY && current?.runnings?.length > 0);
  }, [current]);

  useEffect(() => {
    if (projectId) {
      dispatch.project.getProjectById(projectId);
    }

    return () => {
      dispatch.project.setCurrent(null);
      dispatch.threatModel.setThreatModels(null);
    };
  }, [projectId]);

  useEffect(() => {
    if (projectId && projectLiveUpdateData && projectLiveUpdateData.includes(projectId)) {
      dispatch.project.getProjectById(projectId);
      dispatch.project.setLiveUpdateData(null);
    }
  }, [projectLiveUpdateData]);

  const handleNavItemClick = () => {
    setIsListMode(!isListMode);

    if (!isListMode) {
      navigate(`../collections/${projectId}/list`, { replace: true });
    } else {
      navigate(`../collections/${projectId}`, { replace: true });
    }
  };

  const handleEmitAfterProjectUpdate = () => {
    if (projectId && typeof projectId === 'string') {
      emitGlobalUpdate({
        projects: [projectId],
      });
    }
  };

  const handleThreatModelCreate = async () => {
    const createdThreatModel = await dispatch.threatModel.createThreatModel({
      projectId,
      title: 'Draft threat model',
    });

    if (createdThreatModel?.representationId && current?.id) {
      dispatch.drawn.setIsFirstOpen(true);
      dispatch.app.setSidebarVisible(false);
      handleEmitAfterProjectUpdate();
      navigate(`/collections/${current.id}/d/${createdThreatModel.representationId}`);
    }
  };

  return (
    current && (
      <div style={{ padding: '10px', position: 'relative', overflow: 'hidden' }}>
        <ProjectHeader
          project={current}
          onRunningChange={setCurrentRunning}
          currentRunning={currentRunning}
          isRepositoryMode={isRepositoryMode}
        />
        <ProjectSubHeader
          onNavItemClick={handleNavItemClick}
          isListMode={isListMode}
          onThreatModelCreate={handleThreatModelCreate}
          isRepositoryMode={isRepositoryMode}
        />
        <ProjectDetail projectId={projectId} project={current} currentRunning={currentRunning} />
      </div>
    )
  );
};

export default ProjectDetailPage;
