import { memo, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import type { NodeProps, ResizeDragEvent, ResizeParamsWithDirection } from 'reactflow';
import { NodeResizer, NodeToolbar, Position } from 'reactflow';
import { SKELETON_COLORS } from '../../../global/constants';
import { getColorDependsOnBackground } from '../../../helpers/colors';
import { isReadMode } from '../../../helpers/isReadMode';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import User from '../../User/User';
import DiagramStickerPopupTools from '../DiagramStickerPopupTools';

let timer: any = null;

// eslint-disable-next-line react/display-name
export default memo(({ id, data, selected, type }: NodeProps) => {
  const [deletedUserData, setDeletedUserData] = useState<{
    first_name: string;
    last_name: string;
  } | null>(null);

  timer = setTimeout(() => {
    setDeletedUserData({
      first_name: 'Deleted',
      last_name: 'Account',
    });
  }, 1500);
  const currentUser = useTypedSelector((state) => state.user.current);
  const user = data?.user?.id === currentUser?.id ? currentUser : data?.user;
  const textRef = useRef<any>();
  const [attributesPopup, setAttributesPopup] = useState<any>({
    visible: false,
    componentId: id,
  });
  const { nodesSharedState } = useTypedSelector((state) => state.diagram);
  const interactionMode = useTypedSelector((state) => state.threatModel.interactionMode);
  const [inputValue, setInputValue] = useState(data?.label || '');
  const dispatch = useDispatch<Dispatch>();
  const isCurrentUserStickerCreator = currentUser?.id === data?.user?.id || !user;
  const { selectedNode } = useTypedSelector((state) => state.diagram);

  useEffect(() => {
    if (data?.label) setInputValue(data.label);
  }, [data.label]);

  const handleResize = (event: ResizeDragEvent, params: ResizeParamsWithDirection) => {
    textRef.current.style.height = `${params.height - 53}px`;
  };

  const onKeyUpHandler = (e: any) => {
    const target = e.target as HTMLTextAreaElement;
    textRef.current.parentNode.parentNode.style.height = `${target.scrollHeight + 53}px`;
    textRef.current.style.height = `${target.scrollHeight}px`;
  };

  const onChangeHandler = function (e: any) {
    if (isReadMode(interactionMode)) return;

    onKeyUpHandler(e);
    setInputValue(e.target.value);

    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch.representation.updateSticker({
        id,
        description: e.target.value,
      });
      const node = nodesSharedState?.get(id);

      if (node?.id === id) {
        node.data = {
          ...node.data,
          label: e.target.value,
        };

        if (textRef.current?.style?.height) {
          const newHeight = +(textRef.current?.style?.height || '').replace('px', '') + 53;
          node.height = newHeight;

          if (node.style) node.style.height = newHeight;
        }

        nodesSharedState && nodesSharedState.set(node.id, node);
      }
    }, 500);
  };

  const showPopup = () => {
    setAttributesPopup({ ...attributesPopup, componentId: id, visible: true });
  };

  return (
    <>
      {isCurrentUserStickerCreator && (
        <>
          <NodeResizer
            onResize={handleResize}
            nodeId={data.id}
            minHeight={90}
            minWidth={150}
            color="rgba(52, 168, 83, 0.05)"
          />
          <NodeToolbar
            isVisible={selected && !attributesPopup.visible && data?.selectedBy?.includes(currentUser?.id)}
            position={Position.Top}
          >
            <DiagramStickerPopupTools
              setAttributesPopup={showPopup}
              node={{
                id,
                type,
                color: data.color,
                fontSize: data?.fontSize,
                autoFontSize: data?.autoFontSize,
                textStyle: data?.textStyle,
                fontColor: data?.fontColor,
              }}
            />
          </NodeToolbar>
        </>
      )}
      <div
        style={{
          cursor: selectedNode?.length ? 'crosshair' : 'unset',
          minHeight: '90px',
          minWidth: '170px',
          height: 'inherit',
          width: 'inherit',
          background: '#F5FAF6',
          backgroundColor: data?.color || '#F5FAF6',
          boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.25)',
          padding: '5px',
          zIndex: 1000,
          containerType: 'inline-size',
        }}
      >
        <textarea
          className="diagram_sticker_input bg-transparent w-100 m-0 border-0 nodrag fs-12"
          disabled={!isCurrentUserStickerCreator}
          onChange={onChangeHandler}
          value={inputValue}
          ref={textRef}
          onFocus={(e: any) => {
            dispatch.diagram.setCanCopy(false);
            e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
          }}
          onBlur={() => dispatch.diagram.setCanCopy(true)}
          style={{
            color: data?.fontColor || getColorDependsOnBackground(data?.color || '#F5FAF6'),
            height: 'calc(100% - 43px)',
            fontSize: data?.autoFontSize ? '7cqw' : `${data?.fontSize || 12}px`,
            fontWeight: `${data?.textStyle?.isBold ? '600' : '400'}`,
          }}
          data-isnode={1}
          data-id="custom-diagram-input"
        />
        {user && (
          <div
            data-isnode={1}
            style={{
              width: 'auto',
              scale: '0.8',
              margin: '-10px 0px 0px -15px',
            }}
          >
            <User user={user} noHover />
          </div>
        )}
        {!user && !deletedUserData && (
          <Skeleton
            style={{ lineHeight: '25px' }}
            width="100%"
            height={35}
            baseColor={SKELETON_COLORS.BASE}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
          />
        )}
        {!user && deletedUserData && (
          <div
            data-isnode={1}
            style={{
              width: '180px',
              scale: '0.8',
              margin: '-10px 0px 0px -10px',
            }}
          >
            <User user={deletedUserData} noHover />
          </div>
        )}
      </div>
    </>
  );
});
