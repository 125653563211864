import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { CheckLg, CircleFill, PlusLg } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import type { Edge } from 'reactflow';
import {
  EDGE_DEFAULT_COLOR,
  getColorDependsOnBackground,
  getOpacity,
  getOpacityPercent,
  putColorToLocalStorage,
} from '../../../../../helpers/colors';
import { setColorToMarkers } from '../../../../../helpers/diagram';
import { useTypedSelector } from '../../../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../../../store/store';
import UiRangeInput from '../../../../common/UiRangeInput/UiRangeInput';
import styles from './EdgeColorPicker.module.css';

const OPTIONS = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

type EdgeColorPickerProps = {
  itemsVisible: boolean;
  setItemsVisible: (value: boolean) => void;
  edge: any;
  colors: string[];
};

let timer: any = null;

const EdgeColorPicker: FC<EdgeColorPickerProps> = ({ itemsVisible, setItemsVisible, edge, colors }) => {
  const dispatch = useDispatch<Dispatch>();
  const { edgesSharedState, defaultEdgeStyle } = useTypedSelector((state) => state.diagram);
  const [colorValue, setColorValue] = useState<string>(edge?.color || EDGE_DEFAULT_COLOR);
  const [showPickerTooltip, setShowPickerTooltip] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<{
    show: boolean;
    value: string | null;
  }>({
    show: false,
    value: null,
  });
  const [opacity, setOpacity] = useState<string>('CC');
  const [opacityValue, setOpacityValue] = useState<number>(getOpacityPercent(edge?.color?.substring(7, 9)));
  const [edges, setEdges] = useState<Edge[] | null>();

  useEffect(() => {
    if (edgesSharedState) {
      setEdges(Array.from(edgesSharedState.values()));
    }
  }, [edgesSharedState]);

  const handleSelect = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.target.id.length < 8) {
      e.target.id += opacity;
    }

    setColorValue(e.target.id);
    setColorToMarkers(e.target.id);

    if (e.target.id) {
      if (
        !colors
          .map((color) => {
            return color.substring(0, 7);
          })
          .includes(e.target.id.substring(0, 7))
      ) {
        putColorToLocalStorage(e.target.id);
      }

      edges?.forEach((inEdge) => {
        if (inEdge.id === edge.id) {
          inEdge.data = {
            ...inEdge.data,
            color: e.target.id,
          };
          inEdge.markerEnd = {
            // @ts-ignore
            type: inEdge.markerEnd?.type || inEdge.data.markerType,
            color: e.target.id.substring(0, 7),
          };
          dispatch.diagram.setDefaultEdgeStyle({
            ...defaultEdgeStyle,
            data: {
              ...defaultEdgeStyle.data,
              color: e.target.id,
            },
            markerEnd: inEdge.markerEnd,
          });
          edgesSharedState && edgesSharedState.set(inEdge.id, inEdge);
        }
      });
    }

    if (e.target.tagName === 'INPUT') e.target.id = 'node-color-input';
  };

  const handleOpacityChange = (e: any) => {
    e.persist();
    const opacity = getOpacity(e.target.value);
    setOpacity(opacity);
    setOpacityValue(e.target.value);

    clearTimeout(timer);
    timer = setTimeout(() => {
      e.target.id = colorValue.substring(0, 7) + opacity;
      handleSelect(e);
    }, 500);
  };

  const handleColorChange = (e: any) => {
    e.persist();
    clearTimeout(timer);
    timer = setTimeout(() => {
      e.target.id = e.target.value + opacity;
      handleSelect(e);
    }, 500);
  };

  const handleOptionClick = (ev: any, value: number) => {
    ev.target.value = value;
    handleOpacityChange(ev);
  };

  const handleColorMouseEnter = (color: string) => {
    setShowTooltip({
      show: true,
      value: color,
    });
  };

  const handleColorMouseLeave = (color: string) => {
    setShowTooltip({
      show: false,
      value: null,
    });
  };

  const handleMouseEnter = () => {
    setShowPickerTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowPickerTooltip(false);
  };

  return (
    <div
      className={classNames(
        {
          [styles.dropdownActive]: itemsVisible,
        },
        styles.dropdown,
        'd-flex align-items-center justify-content-between rounded p-1 gap-3 cursor-pointer position-relative',
      )}
    >
      {showPickerTooltip && !itemsVisible && <div className="main-tooltip">Color picker</div>}
      <CircleFill
        onClick={() => setItemsVisible(!itemsVisible)}
        className={styles.dropdownCircleIcon}
        fontSize={25}
        color={colorValue}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      {itemsVisible && (
        <div className={classNames(styles.dropdownItems)} style={{ top: colors.length <= 7 ? '-84px' : '-115px' }}>
          <UiRangeInput options={OPTIONS} value={opacityValue} onOptionClick={handleOptionClick} />
          <div className={styles.dropdownItemWrapper}>
            {colors?.map((c) => (
              <div
                id={c}
                key={c}
                onClick={(e) => {
                  if (c.substring(0, 7) !== edge?.color?.substring(0, 7)) handleSelect(e);
                  else if (!edge?.color || edge?.color?.length <= 2) handleSelect(e);
                  else {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                className={classNames(styles.dropdownItem, 'rounded-circle')}
                style={{
                  background: c,
                  cursor: c.substring(0, 7) === edge?.color?.substring(0, 7) ? 'default' : 'pointer',
                }}
                onMouseEnter={() => handleColorMouseEnter(c)}
                onMouseLeave={() => handleColorMouseLeave(c)}
              >
                {c.substring(0, 7) === edge?.color?.substring(0, 7) && (
                  <div className={styles.selectedMark}>
                    <CheckLg size={16} color={getColorDependsOnBackground(c.substring(0, 7))} />
                  </div>
                )}
                {showTooltip.show && showTooltip.value === c && <div className={styles.tooltip}>{c}</div>}
              </div>
            ))}
            {colors.length <= 15 && (
              <div
                className={styles.plusIcon}
                onClick={() => {
                  document.getElementById(`edge-color-input-${edge.id}`)?.click();
                }}
              >
                <PlusLg size={16} color="black" className="cursor-pointer" />
              </div>
            )}
          </div>
        </div>
      )}
      <input
        id={`edge-color-input-${edge.id}`}
        className="position-absolute opacity-0"
        type="color"
        style={{ pointerEvents: 'none' }}
        onChange={handleColorChange}
      />
    </div>
  );
};

export default EdgeColorPicker;
