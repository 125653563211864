import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';

import classNames from 'classnames';
import { Layers } from 'react-bootstrap-icons';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import deviciLogo from '../../assets/images/devici-logo.png';
import styles from '../../components/common/Auth/styles/index.module.css';
import PrivacyAndTerms from '../../components/common/PrivacyAndTerms';
import UiButton from '../../components/common/UIButton/UiButton';
import CodeForm from '../../components/Forms/CodeForm';
import ResendCode from '../../components/Forms/CodeForm/ResendCode';
import EmailForm from '../../components/Forms/EmailForm';
import PasswordForm from '../../components/Forms/PasswordForm';
import { AUTH_FLOW } from '../../global/auth';
import { SING_UP_PAGE_PAYMENT_URL_PARAMS } from '../../global/constants';
import { copyQrCodeToNavigator } from '../../helpers/user';
import { SIGN_IN_ROUTE } from '../../store/constants/route-constants';
import type { Dispatch, RootState } from '../../store/store';

interface IMfaSetupData {
  code: string;
  email: string;
  session: string;
  password: string;
  secondCode: string;
}

type SignUpProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const SignUpPage: FC<SignUpProps> = ({
  authFlow,
  isSignedUp,
  isConfirmed,
  mfaSetupData,
  mfaCheckData,
  isAuthLoading,
  isEmailChecked,
  isAuthenticated,
  signUp,
  confirm,
  checkEmail,
  resendCode,
  checkMfaCode,
  verifyMfaToken,
  resetSignUpState,
  isExtendedSessionDuration,
}) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState(params.get('email_address') || '');
  const [password, setPassword] = useState('');
  const [secondCode, setSecondCode] = useState('');
  const [windowWidth, setWindowWidth] = useState(0);

  const data = {
    // _gl: params.get("_gl"),
    // plan: params.get('plan'),
    plan: 'yearly',
    users: params.get('users'),
    buying: params.get('buying'),
  };

  if (data.plan?.length && data.buying?.length && data.users?.length) {
    localStorage.setItem(SING_UP_PAGE_PAYMENT_URL_PARAMS, JSON.stringify(data));
  }

  useEffect(() => {
    const handleResize = (e: any) => {
      setWindowWidth(e.target.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      resetSignUpState();
      window.removeEventListener('resize', handleResize);
      setWindowWidth(0);
    };
  }, []);

  useEffect(() => {
    if (authFlow === AUTH_FLOW.MFA_SETUP_COMPLETED && mfaCheckData) {
      checkMfaCode({ code: secondCode, email, session: mfaCheckData.session });
    }
  }, [authFlow]);

  const handleNavigate = (path: string) => {
    return navigate(path);
  };

  const getMainTitle = (isSignedUp: boolean, isConfirmed: boolean) => {
    if (isSignedUp && !isConfirmed) {
      return 'Code successfully sent to email';
    }

    if (isSignedUp && isConfirmed) {
      return '';
    }

    return (
      <div>
        <b>Welcome to Faster, Secure Threat Modeling with Devici</b>
        <br />
        <div className="mt-3 fw-normal fs-12">
          Instantly access powerful threat modeling <br /> tools—free, no credit card required.
        </div>
      </div>
    );
  };

  const handleConfirm = async (data: any) => {
    const { email, code, password } = data;
    await confirm({ email, code, password });
  };

  const isMfaSetupData = (data: any): data is IMfaSetupData => {
    return typeof data === 'object' && 'code' in data && 'email' in data && 'session' in data && 'password' in data;
  };

  const handleMfaSetup = async <T,>(data: T) => {
    if (isMfaSetupData(data)) {
      const { email, code, password, session, secondCode } = data;
      setSecondCode(secondCode);
      await verifyMfaToken({ session, code, email, password });
    }
  };

  const handleCopyQrCode = async (text: string) => {
    const result = await copyQrCodeToNavigator(text);

    if (result) toast.success('Copied to clipboard!');
    else toast.warning('Could not copy!');
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  const isMfaSetup = authFlow === AUTH_FLOW.MFA_SETUP || authFlow === AUTH_FLOW.MFA_SETUP_COMPLETED;

  return (
    <div className={classNames(styles.container, styles.signUpBackground)}>
      <div className={styles.main}>
        {!!windowWidth && windowWidth < 600 && (
          <div className={styles.displaySizeWarning}>
            For optimal performance and a seamless experience, we recommend using our platform on a desktop rather than
            a mobile device.
          </div>
        )}
        <div className={styles.header}>
          <div className={styles.logo}>
            <img src={deviciLogo} alt="Devici logo" />
          </div>
          <div className={styles.title}>{getMainTitle(isSignedUp, isConfirmed)}</div>
        </div>
        {!isSignedUp && !isConfirmed && !isEmailChecked && (
          <EmailForm
            email={email}
            setEmail={setEmail}
            handleSubmit={checkEmail}
            isLoading={isAuthLoading}
            isSignUpForm
          />
        )}
        {!isSignedUp && !isConfirmed && isEmailChecked && email && (
          <PasswordForm
            email={email}
            title="Password"
            handleSubmit={signUp}
            isFirstPassword
            isLoading={isAuthLoading}
            setPassword={setPassword}
            needCheckboxAuthSession={isExtendedSessionDuration}
          />
        )}
        {isSignedUp && !isMfaSetup && email && (
          <>
            <CodeForm
              email={email}
              title="Enter code"
              password={password}
              isLoading={isAuthLoading}
              handleSubmit={handleConfirm}
            />
            <ResendCode email={email} handleResendCode={resendCode} />
          </>
        )}
        {isMfaSetup && email && (
          <>
            <span className="lh-1 fw-bold mt-2 text-start w-100">MFA Set Up</span>
            <span className={styles.mfaSetupText}>
              For security reasons, we require two <b>different</b> codes from an authenticator app.
            </span>
            <span className={styles.mfaSetupText}>
              First, scan the QR code with an authenticator app. Then, enter the first code in the first box below.
              Then, please <b>wait</b> for a new code to generate and enter it into the second box.
            </span>
            <div className={styles.mfaSetupWrap}>
              <div>
                <img src={mfaSetupData?.qrImage || ''} />
                <UiButton type="transparent" onClick={() => handleCopyQrCode(mfaSetupData?.code || '')}>
                  Copy key <Layers />
                </UiButton>
              </div>
              <span className={styles.verticalLine} />
              <div className="mt-3">
                <CodeForm
                  isDoubled
                  email={email}
                  title="Enter code from the app"
                  password={password}
                  isLoading={isAuthLoading}
                  secondTitle="Enter second code from app"
                  handleSubmit={handleMfaSetup}
                  session={mfaSetupData?.session}
                />
              </div>
            </div>
          </>
        )}
        <div className={styles.footer}>
          <div className={styles.footerLink} onClick={() => handleNavigate(SIGN_IN_ROUTE)}>
            Have a Devici Account already? Log in here.
          </div>
        </div>
        <PrivacyAndTerms />
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  authFlow: state.auth?.authFlow,
  isSignedUp: state.auth?.isSignedUp,
  isConfirmed: state.auth?.isConfirmed,
  mfaSetupData: state.auth.mfaSetupData,
  mfaCheckData: state.auth.mfaCheckData,
  isAuthLoading: state.auth.isAuthLoading,
  isEmailChecked: state.auth?.isEmailChecked,
  isAuthenticated: state.auth?.isAuthenticated,
  isExtendedSessionDuration: state.auth?.sessionDuration > 1,
});

const mapDispatch = (dispatch: Dispatch) => ({
  signUp: dispatch.auth.signUp,
  confirm: dispatch.auth.confirm,
  checkEmail: dispatch.auth.checkEmail,
  checkMfaCode: dispatch.auth.checkMfaCode,
  verifyMfaToken: dispatch.auth.verifyMfaToken,
  resetSignUpState: dispatch.auth.resetSignUpState,
  resendCode: dispatch.auth.resendSignUpConfirmationCode,
});

export default connect(mapState, mapDispatch)(SignUpPage);
