import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CloseButton, FormGroup, Input, Modal, ModalBody, Spinner, Table } from 'reactstrap';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import { ROLE } from '../../store/models/user';
import type { Dispatch } from '../../store/store';

export const ChooseUsersModal = () => {
  const dispatch = useDispatch<Dispatch>();
  const { pricingPageModalVisible, users, current } = useTypedSelector((state) => state.user);
  const onClose = () => {
    dispatch.user.setPricingPageModalVisible(false);
  };
  const [loading, setLoading] = useState('');

  const handleToggleUserEnabled = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { id, checked } = event.target;
    const selectedUser = users?.find((u) => u.id === id);

    if (selectedUser?.role === ROLE.ADMIN) return;

    setLoading(id);

    if (checked) await dispatch.user.enableUser(id);
    else await dispatch.user.disableUser(id);

    await dispatch.user.getUsers({});
    setLoading('');
  };

  return (
    <Modal size="lg" centered zIndex={12000} toggle={onClose} isOpen={pricingPageModalVisible}>
      <ModalBody>
        <div className="d-flex">
          <CloseButton className="ms-auto" onClick={onClose} />
        </div>
        <p className="text-center mt-1">
          You can&apos;t choose fewer users than are in your account. <br /> Please update the number of users you are
          purchasing or deactivate some here.
        </p>

        <Table bordered size="sm">
          <thead>
            <tr>
              <th style={{ width: '45%' }}>User</th>
              <th style={{ width: '45%' }}>Email</th>
              <th style={{ width: '10%' }}>Enabled</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user) => (
              <tr key={user.id}>
                <td>
                  {user.first_name} {user.last_name} {user.role === ROLE.ADMIN ? '(admin)' : ''}
                </td>
                <td>{user.email}</td>
                <td className="d-flex justify-content-center align-items-center">
                  <FormGroup switch className="p-0">
                    {loading === user.id ? (
                      <Spinner size="sm" color="primary" className="text-center" />
                    ) : (
                      <Input
                        className="cursor-pointer m-0"
                        type="switch"
                        id={user.id}
                        disabled={current?.id === user.id}
                        checked={user.isEnabled}
                        onChange={handleToggleUserEnabled}
                      />
                    )}
                  </FormGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
};
