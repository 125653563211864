import classNames from 'classnames';
import type { FC } from 'react';
import styles from './UiInput.module.css';

type UiInputProps = {
  type?: 'text' | 'textarea' | 'password' | 'email';
  name: string;
  label?: string;
  placeholder?: string;
  showError?: boolean | string;
  errorText?: string;
  textAreaRows?: number;
  value: string;
  onChange: any;
  onBlur: any;
  onFocus?: any;
  isNodeInput?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  textAreaHeight?: number;
  color?: string;
  disableRows?: boolean;
};

const UiInput: FC<UiInputProps> = ({
  type = 'text',
  name,
  label,
  placeholder = '',
  showError = false,
  errorText = '',
  textAreaRows = 2,
  value,
  onChange,
  onBlur,
  isNodeInput = false,
  disabled = false,
  autoFocus = false,
  textAreaHeight = false,
  onFocus,
  color = '#000000',
  disableRows = false,
}) => {
  const labelClass = classNames({
    [`${styles.errorLabel}`]: showError,
  });

  const inputClass = classNames({
    [`${styles.errorInput}`]: showError,
    [`${styles.nodeInput}`]: isNodeInput,
  });

  return (
    <div className={styles.wrapper}>
      {label && (
        <label htmlFor={`${label}-${placeholder}`} className={`${styles.label} ${labelClass}`}>
          {label}
        </label>
      )}
      {type !== 'textarea' && (
        <input
          style={{ color }}
          name={name}
          id={name}
          className={`${styles.input} ${inputClass}`}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          autoFocus={autoFocus}
          onFocus={onFocus}
          autoComplete={type === 'password' ? 'new-password username' : undefined}
        />
      )}
      {type === 'textarea' && (
        <textarea
          style={{
            color,
            height: textAreaHeight ? `${textAreaHeight}px` : 'auto',
          }}
          name={name}
          id={name}
          className={`${styles.input} ${inputClass}`}
          placeholder={placeholder}
          rows={disableRows ? undefined : textAreaRows}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          autoFocus={autoFocus}
          onFocus={onFocus}
          onCut={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      )}
      {showError && <div className={styles.error}>{errorText}</div>}
    </div>
  );
};

export default UiInput;
