import type { ChangeEvent, FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import TextareaAutosize from 'react-textarea-autosize';
import type { Node } from 'reactflow';
import { NODES } from '../../../../global/constants';
import { getTextareaHeight } from '../../../../helpers/diagram';
import { useTypedSelector } from '../../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../../store/store';
import styles from './NodeInput.module.css';

type NodeInputProps = {
  id: any;
  value: string;
  fontSize?: number;
  onBlur: any;
  inputColor?: string;
  autoFontSize?: boolean;
  fontBold?: boolean;
};

let timer: any = null;

const NodeInput: FC<NodeInputProps> = ({
  id,
  value,
  fontSize,
  onBlur,
  inputColor = '#000000',
  autoFontSize = false,
  fontBold = false,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { diagramId } = useParams();
  const { nodesSharedState } = useTypedSelector((state) => state.diagram);
  const nodeInputRef = useRef<any>(null);
  const component = useTypedSelector((state) => state.drawn?.component);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nodes, setNodes] = useState<Node[] | null>();
  const [inputValue, setValue] = useState('');

  useEffect(() => {
    if (nodesSharedState) {
      setNodes(Array.from(nodesSharedState.values()));
    }
  }, [nodesSharedState]);

  useEffect(() => {
    if (id && !component?.id) dispatch.drawn.getComponent({ id });

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [id]);

  useEffect(() => {
    setValue(component?.id === id ? component?.title : value);
  }, []);

  const onTitleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue(event.target.value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (event.target.value.length > 1 && component?.id) {
        dispatch.drawn.setComponent({
          ...component,
          title: event.target.value,
        });
        dispatch.drawn.updateComponent({
          id: component?.id,
          description: component?.description,
          representationId: diagramId,
          title: event.target.value,
        });
        const node = nodesSharedState?.get(component?.id);

        if (node) {
          node.data = {
            ...node.data,
            label: event.target.value,
          };
          node.style = {
            ...node.style,
            height: getTextareaHeight(event.target.scrollHeight, node?.style?.height, node.type === NODES.PROCESS_NODE),
          };
          nodesSharedState && nodesSharedState.set(node.id, node);
        }
      }
    }, 300);
  };

  return (
    <TextareaAutosize
      ref={nodeInputRef}
      className={styles.nodeInput}
      onChange={onTitleChange}
      value={inputValue}
      onFocus={(e) => {
        dispatch.diagram.setCanCopy(false);
        e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
      }}
      onBlur={() => dispatch.diagram.setCanCopy(true)}
      style={{
        color: inputColor,
        fontSize: autoFontSize ? '16cqw' : `${fontSize || 12}px`,
        fontWeight: `${fontBold ? '600' : '400'}`,
      }}
      maxLength={60}
      minLength={1}
      autoFocus
      onMouseDownCapture={(e) => {
        e.stopPropagation();
      }}
      data-id="custom-diagram-input"
    />
  );
};

export default NodeInput;
