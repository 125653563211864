import { LexoRank } from 'lexorank';
import type { RefObject, SyntheticEvent } from 'react';
import type { Edge, Node } from 'reactflow';
import { MarkerType } from 'reactflow';
import type { YMap } from 'yjs/dist/src/internals';
import * as Yup from 'yup';
import { NODES } from '../global/constants';
import type { ThreatModel } from '../global/types';
import { DRAWN_WIDTH } from '../store/constants/drawn-constants';
import { EDGE_STYLES } from './colors';

export enum DIAGRAM_ZOOM_VALUES {
  MAX = 1.3,
  MIN = 0.4,
  STEP = 25,
  MAX_PERCENT = 150,
  MIN_PERCENT = 0,
}

export enum ATTRIBUTES_SEARCH_TYPE {
  CODEX = 'Keyword',
  LEO = 'Smart',
  MITIGATIN = 'Mitigating',
}

export interface DEFAULT_EDGE_STYLE {
  type: EDGE_STYLES;
  markerEnd: {
    type: MarkerType.ArrowClosed;
    color: string;
  };
  data: {
    color: string;
    fontColor: string;
    fontSize: number;
    textStyle: {
      isBold: boolean;
    };
    markerType: 'start' | 'end' | 'both';
    borderWidth: string;
  };
}

export const convertZoomToPercent = (value: number): number => {
  const { MAX, MIN, MAX_PERCENT } = DIAGRAM_ZOOM_VALUES;
  const result = ((value - MIN) / (MAX - MIN)) * MAX_PERCENT;

  return Math.round(result);
};

export const convertPercentToZoom = (percent: number): number => {
  const { MAX, MIN, MAX_PERCENT } = DIAGRAM_ZOOM_VALUES;
  const clampedPercent = Math.max(0, Math.min(MAX_PERCENT, percent));
  const result = (clampedPercent / MAX_PERCENT) * (MAX - MIN) + MIN;

  return Math.round(result * 100) / 100;
};

export const getNextZoomValue = (zoomLevel: number, next = false) => {
  const { MIN, MAX, STEP, MIN_PERCENT, MAX_PERCENT } = DIAGRAM_ZOOM_VALUES;
  const percentage = convertZoomToPercent(zoomLevel);
  const remainder = percentage % STEP;
  let newValue = MIN_PERCENT;

  if (next) {
    if (zoomLevel > MAX) return;

    newValue = !remainder ? percentage + STEP : percentage + (STEP - remainder);

    if (newValue > MAX_PERCENT) newValue = MAX_PERCENT;
  } else {
    if (zoomLevel < MIN) return;

    newValue = !remainder ? percentage - STEP : percentage - remainder;

    if (newValue < MIN_PERCENT) newValue = MIN_PERCENT;
  }

  return convertPercentToZoom(newValue);
};

export const setDrawnIsOpenToLocalStorage = (value: boolean) => {
  localStorage.setItem('drawn_is_open', String(value));
};

export const getDrawnIsOpenFromLocalStorage = () => {
  const value = localStorage.getItem('drawn_is_open');

  return value === 'true';
};

export const setDrawnWidthFromLocalStorage = (value: number) => {
  localStorage.setItem('drawn_width', String(value));
};

export const getDrawnWidthFromLocalStorage = () => {
  const value = localStorage.getItem('drawn_width');

  return value ? Number(value) : DRAWN_WIDTH.MIN;
};

export const setDefaultEdgeStyle = (value: any) => {
  localStorage.setItem('default_edge_style', JSON.stringify(value));
};

export const getDefaultEdgeStyle = () => {
  const value = localStorage.getItem('default_edge_style');

  return value
    ? JSON.parse(value)
    : {
        type: EDGE_STYLES.BEZIER,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: '#6F767E',
        },
        data: {
          color: '#6F767E',
          fontColor: '#ffffff',
          fontSize: 12,
          textStyle: {
            isBold: false,
          },
          markerType: 'end',
        },
      };
};

export const getZIndex = (nodeType: string) => {
  switch (nodeType) {
    case NODES.TRUST_BOUNDARY_NODE:
      return 0;
    case NODES.STICKER_NODE:
      return 3;
    default:
      return 2;
  }
};

export const setViewportToLocalStorage = (value: any) => {
  localStorage.setItem('app_viewport', JSON.stringify(value));
};

export const getViewportFromLocalStorage = () => {
  const value = localStorage.getItem('app_viewport');

  return value ? JSON.parse(value) : { x: 0, y: 0, zoom: 0.5 };
};

export const setAppLeftSidebarVisible = (value: boolean) => {
  localStorage.setItem('app_left_sidebar_visible', String(value));
};

export const getAppLeftSidebarVisible = () => {
  const value = localStorage.getItem('app_left_sidebar_visible');

  return value === 'true';
};

export const reactFlowDataChanged = (oldState: any, newState: any) => {
  if (oldState.nodes.length !== newState.nodes.length) return true;

  if (oldState.edges.length !== newState.edges.length) return true;

  let isChanged = false;

  for (let i = 0; i < newState.nodes.length; i += 1) {
    if (isChanged) return true;

    const currentNewObject = newState.nodes[i];
    const currentOldObject = oldState.nodes.find((on: any) => on.id === currentNewObject.id);

    if (currentNewObject && currentOldObject) {
      Object.keys(currentNewObject)?.forEach((key) => {
        if (isChanged) return true;

        const currentNewField = currentNewObject[key];
        const currentOldField = currentOldObject[key];

        if (!currentNewField || !currentOldField) return false;

        if (typeof currentNewField !== 'object' && key !== 'selected') {
          isChanged = currentNewField !== currentOldField;
        } else if (key === 'data') {
          isChanged = !![
            currentNewField.label !== currentOldField.label,
            currentNewField.color !== currentOldField.color,
            currentNewField.borderColor !== currentOldField.borderColor,
            currentNewField.borderWidth !== currentOldField.borderWidth,
          ].filter(Boolean).length;
        } else if (key === 'position' || key === 'positionAbsolute') {
          isChanged = !![currentNewField.x !== currentOldField.x, currentNewField.y !== currentOldField.y].filter(
            Boolean,
          ).length;
        }
      });
    }
  }

  for (let i = 0; i < newState.edges.length; i += 1) {
    if (isChanged) return true;

    const currentNewObject = newState.edges[i];
    const currentOldObject = oldState.edges.find((oe: any) => oe.id === currentNewObject.id);

    if (currentNewObject && currentOldObject) {
      Object.keys(currentNewObject)?.forEach((key) => {
        if (isChanged) return true;

        const currentNewField = currentNewObject[key];
        const currentOldField = currentOldObject[key];

        if (!currentNewField || !currentOldField) return false;

        if (typeof currentNewField !== 'object' && key !== 'selected') {
          isChanged = currentNewField !== currentOldField;
        } else if (key === 'data') {
          isChanged = !![
            currentNewField.label !== currentOldField.label,
            currentNewField.color !== currentOldField.color,
            currentNewField.borderColor !== currentOldField.borderColor,
            currentNewField.borderWidth !== currentOldField.borderWidth,
            currentNewField.markerType !== currentOldField.markerType,
          ].filter(Boolean).length;
        } else if (key === 'markEnd') {
          isChanged = currentNewField.type !== currentOldField.type;
        }
      });
    }
  }

  return isChanged;
};

export const isRectangleInside = (rect1: any, rect2: any) => {
  const x1 = rect1.x;
  const y1 = rect1.y;
  const width1 = rect1.width;
  const height1 = rect1.height;
  const x2 = rect2.x;
  const y2 = rect2.y;
  const width2 = rect2.width;
  const height2 = rect2.height;
  let isInside = x1 >= x2 && y1 >= y2;
  isInside = isInside && x1 + width1 <= x2 + width2 && y1 + height1 <= y2 + height2;
  const isPartiallyInside = !(x1 + width1 <= x2 || x1 >= x2 + width2 || y1 + height1 <= y2 || y1 >= y2 + height2);

  return { isInside, isPartiallyInside };
};

export const getDefaultNodeSize = (node: string) => {
  switch (node) {
    case NODES.TRUST_BOUNDARY_NODE:
      return { width: 200, height: 200 };
    case NODES.PROCESS_NODE:
      return { width: 100, height: 100 };
    case NODES.DATA_STORE_NODE:
      return { width: 100, height: 50 };
    case NODES.EXTERNAL_ENTITY_NODE:
      return { width: 150, height: 50 };
    default:
      return {};
  }
};

export const canAddNodeToTarget = (event: any) => {
  const conditions = [
    event.target.classList.contains('react-flow__pane'),
    event.target.classList.contains('react-flow__node'),
    event.target.classList.contains('react-flow__edge'),
    event.target.classList.contains('react-flow__edge-interaction'),
    !!event.target.dataset?.isnode,
    !!event.target.dataset?.isedge,
  ].filter(Boolean);

  return !!conditions.length;
};

export const getTextareaHeight = (inputHeight: number, oldHeight: any, isProcess?: boolean) => {
  const oldValue = oldHeight ? Number(oldHeight) : 50;
  const offsetHeight = isProcess ? 90 : 50;
  const addHeight = isProcess ? 20 : 10;

  if (inputHeight < offsetHeight || oldHeight > 148) return oldValue;

  return inputHeight + addHeight;
};

export const setSelectedFalse = <T extends Node | Edge>(nodes: T[]): T[] => {
  return nodes.map((node) => ({
    ...node,
    selected: false,
  })) as T[];
};

export const filterNodes = (nodes: Node[]): Node[] => {
  const types = Object.values(NODES);

  return nodes.filter((n) => {
    // eslint-disable-next-line no-prototype-builtins
    if (n?.hasOwnProperty('extent')) delete n.extent;

    // eslint-disable-next-line no-prototype-builtins
    if (n?.hasOwnProperty('parentNode')) delete n.parentNode;

    return n.type && types.includes(n.type);
  });
};

export const filterEdges = (edges: Edge[]): Edge[] => {
  return edges.map((e) => {
    if (e?.zIndex) delete e?.zIndex;

    return e;
  });
};

export const setTransitionToUserCursors = (turnOn: boolean) => {
  const userCursorsElements = document.querySelectorAll('#user-cursor');
  userCursorsElements?.forEach((el: any) => {
    el.style.transition = turnOn ? 'all 0.3s ease 0s' : 'unset';
  });
};

export const getLinearInterpolation = (x: number, y1: number, y2: number) => {
  const x1 = DIAGRAM_ZOOM_VALUES.MAX;
  const x2 = DIAGRAM_ZOOM_VALUES.MIN;
  const y = y1 + (x - x1) * ((y2 - y1) / (x2 - x1));

  return y;
};

export const getNodeIdByClickEvent = (e: SyntheticEvent | any) => {
  const schema = Yup.object().shape({
    uuid: Yup.string().uuid(),
  });
  const node = e.target as HTMLDivElement;
  const nodeId =
    node.dataset?.id ||
    node.parentElement?.dataset?.id ||
    node.parentElement?.parentElement?.dataset?.id ||
    node.parentElement?.parentElement?.parentElement?.dataset?.id;
  const isUuid = schema.isValidSync({ uuid: nodeId });

  if (isUuid) return nodeId;
};

export const getNodeCoords = (cssTransform: string) => {
  const regex = /[-+]?\d+(\.\d+)?/g;
  const matches = cssTransform.match(regex);

  return { x: +(matches?.[0] || 0), y: +(matches?.[1] || 0) };
};

export const setMarkerForEdges = (ref: RefObject<HTMLDivElement> | null) => {
  if (!ref?.current) return;

  const exist = ref.current.querySelector('marker.react-flow__arrowhead#selectedEdge');

  if (exist) return;

  const marker = ref.current.querySelector('marker.react-flow__arrowhead');

  if (marker) {
    marker.insertAdjacentHTML(
      'afterend',
      `<marker class="react-flow__arrowhead" id="selectedEdgeTriangle" markerWidth="15.5" markerHeight="15.5" viewBox="-10 -10 20 20" markerUnits="strokeWidth" orient="auto-start-reverse" refX="0" refY="0">
        <polyline stroke-linecap="round" stroke-linejoin="round" points="-5,-4 0,0 -5,4 -5,-4" class="selectedEdge" ></polyline>
      </marker>
      <marker id="selectedEdgeSquare" viewBox="0 0 24 24" refX="12" refY="12" markerWidth="6" markerHeight="6" orient="auto">
        <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.68,4.68), scale(0.61)" >
          <rect x="-2.4" y="-2.4" width="24" height="24" rx="0" fill="#ffffff" strokewidth="0" ></rect>
        </g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <path class="selectedEdge" style="stroke-width: 4px!important;" d="M17,2H7A5,5,0,0,0,2,7V17a5,5,0,0,0,5,5H17a5,5,0,0,0,5-5V7A5,5,0,0,0,17,2Zm3,15a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V7A3,3,0,0,1,7,4H17a3,3,0,0,1,3,3Z"></path>
      </marker>
      `,
    );
  }
};

export const setColorToMarkers = (color: string) => {
  const polyline: SVGPolylineElement | null = document.querySelector(
    'marker#selectedEdgeTriangle > polyline.selectedEdge',
  );
  const path: SVGPathElement | null = document.querySelector('marker#selectedEdgeSquare > path.selectedEdge');

  if (polyline) {
    polyline.setAttribute('stroke', `${color || '#6f767e'}`);
  }

  if (path) {
    path.setAttribute('stroke', `${color || '#6f767e'}`);
  }
};

export function sortByLexoRank(a: any, b: any, isProject: boolean): number {
  if (isProject) {
    if (!a.rank && b.rank) return -1;

    if (a.rank && !b.rank) return 1;

    if (!a.rank || !b.rank) return 0;

    return a.rank.localeCompare(b.rank);
  }

  if (!a.my_models_rank && b.my_models_rank) return -1;

  if (a.my_models_rank && !b.my_models_rank) return 1;

  if (!a.my_models_rank || !b.my_models_rank) return 0;

  return a.my_models_rank.localeCompare(b.my_models_rank);
}

interface ISortablePayload<TEntity> {
  prev?: TEntity;
  entity: TEntity;
  next?: TEntity;
}

const createSortablePayloadForOneColumn = (
  items: ThreatModel[],
  sourceIndex: number,
  destinationIndex: number,
): ISortablePayload<ThreatModel> => {
  let input: ISortablePayload<ThreatModel>;
  const entity = items[sourceIndex];

  if (destinationIndex === 0) {
    const nextEntity = items[destinationIndex];
    input = { prev: undefined, entity, next: nextEntity };
  } else if (destinationIndex === items.length - 1) {
    const prevEntity = items[destinationIndex];
    input = { prev: prevEntity, entity, next: undefined };
  } else {
    const prevEntity = items[destinationIndex];
    const offset = sourceIndex > destinationIndex ? -1 : 1;
    const nextEntity = items[destinationIndex + offset];
    input = { prev: prevEntity, entity, next: nextEntity };
  }

  return input;
};

const getBetweenRank = ({ prev, entity, next }: ISortablePayload<any>, isProject: boolean): string => {
  let newLexoRank: LexoRank;
  const field = isProject ? 'rank' : 'my_models_rank';

  if (!prev && !!next) {
    newLexoRank = LexoRank.parse(next[field]).genPrev();
  } else if (!next && !!prev) {
    newLexoRank = LexoRank.parse(prev[field]).genNext();
  } else if (!!prev && !!next) {
    newLexoRank = LexoRank.parse(next[field]).between(LexoRank.parse(prev[field]));
  } else {
    newLexoRank = LexoRank.parse(entity[field]).genNext();
  }

  return newLexoRank.toString();
};

const createSortablePayloadForTwoColumn = (
  startItems: ThreatModel[],
  endItems: ThreatModel[],
  source: number,
  destination: number,
): ISortablePayload<ThreatModel> => {
  const entity = startItems[source];
  const input: ISortablePayload<ThreatModel> = {
    prev: endItems[destination - 1],
    entity,
    next: endItems[destination],
  };

  return input;
};

export const getRankForOneColumn = (items: ThreatModel[], source: number, destination: number, isProject: boolean) => {
  const sortablePayload = createSortablePayloadForOneColumn(items, source, destination);

  return getBetweenRank(sortablePayload, isProject);
};

export const getRankForTwoColumn = (
  start: ThreatModel[],
  end: ThreatModel[],
  source: number,
  destination: number,
  isProject: boolean,
) => {
  const sortablePayload = createSortablePayloadForTwoColumn(start, end, source, destination);

  return getBetweenRank(sortablePayload, isProject);
};

interface IGetSelectedComponentsArgs {
  nodesSharedState: YMap<Node> | null;
  edgesSharedState: YMap<Edge> | null;
  currentUserId: string;
}

export const getSelectedComponentsAmount = ({
  nodesSharedState,
  edgesSharedState,
  currentUserId,
}: IGetSelectedComponentsArgs): number => {
  const selectedAmount = [
    ...Array.from(nodesSharedState?.values() || []),
    ...Array.from(edgesSharedState?.values() || []),
  ].filter((i) => {
    const isSelected = i.selected;
    const isSelectedByCurrentUser = i.data?.selectedBy?.includes(currentUserId);

    return isSelectedByCurrentUser && isSelected;
  }).length;

  return selectedAmount;
};

const getDifference = (defaultPosition: number, templatePosition: number) => {
  return defaultPosition - templatePosition;
};

const getPositionByDiff = (diff: number, templatePosition: number) => {
  return templatePosition + diff;
};

const getLeftmostBottomCoords = (nodes: Node[]) => {
  return nodes.reduce(
    (acc, curr) => {
      const currPosY = curr.position.y + +(curr.height || curr.style?.height || 0);

      if (curr.position.x < acc.x) acc.x = curr.position.x;

      if (currPosY > acc.y) acc.y = currPosY;

      return acc;
    },
    {
      x: Math.min(...nodes.map((node) => node.position.x)),
      y: Math.max(...nodes.map((node) => node.position.y + +(node.height || 0))),
    },
  );
};

export const setNodesPosition = (templateData: Node[], position: { x: number; y: number }) => {
  const nodes: Node[] = [];
  const leftmostBottomNodeCoords = getLeftmostBottomCoords(templateData);
  const diffX = getDifference(position.x, leftmostBottomNodeCoords.x);
  const diffY = getDifference(position.y, leftmostBottomNodeCoords.y);

  for (let i = 0; i < templateData.length; i += 1) {
    const currentNode = structuredClone(templateData[i]);

    if (currentNode?.position) {
      const xPos = getPositionByDiff(diffX, currentNode.position.x);
      const yPos = getPositionByDiff(diffY, currentNode.position.y);
      currentNode.position.x = xPos;
      currentNode.position.y = yPos;
    }

    if (currentNode?.positionAbsolute) {
      const xPosAbsolute = getPositionByDiff(diffX, currentNode.positionAbsolute.x);
      const yPosAbsolute = getPositionByDiff(diffY, currentNode.positionAbsolute.y);
      currentNode.positionAbsolute.x = xPosAbsolute;
      currentNode.positionAbsolute.y = yPosAbsolute;
    }

    nodes.push(currentNode);
  }

  return nodes;
};

export const formatDateForThreatModelHistory = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  return new Date(date).toLocaleDateString('en-US', options);
};
