import type { XYPosition } from 'reactflow';
import { getSmoothStepPath } from 'reactflow';
import { Algorithm } from '../constants';

import type { ControlPointData } from '../ControlPoint';

export const isControlPoint = (point: ControlPointData | XYPosition): point is ControlPointData => 'id' in point;

interface GetSmoothStepPathParams {
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
}

export const getLabelCoords = (
  { sourceX, sourceY, targetX, targetY }: GetSmoothStepPathParams,
  points: ControlPointData[],
  isSelected?: boolean,
  algorithm?: Algorithm,
) => {
  let labelX = 0;
  let labelY = 0;

  if (!points.length) {
    const coords = getSmoothStepPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
    });
    labelX = coords[1];
    labelY = coords[2];
  } else if (points.length === 1) {
    labelX = points[0].x;
    labelY = points[0].y;
  } else if (points.length % 2 === 0) {
    const middle = points.length / 2;
    const coords = getSmoothStepPath({
      sourceX: points[middle - 1].x,
      sourceY: points[middle - 1].y,
      targetX: points[middle].x,
      targetY: points[middle].y,
    });
    labelX = coords[1];
    labelY = coords[2];
  } else {
    const middlePoint = Math.floor((points.length || 0) / 2);
    labelX = points[middlePoint]?.x || 0;
    labelY = points[middlePoint]?.y || 0;
  }

  let subValue = 0;

  if (isSelected) {
    subValue = algorithm === Algorithm.Linear ? 20 : 30;
  }

  return {
    labelX,
    labelY: labelY - subValue,
  };
};
