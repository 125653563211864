import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ArchiveList from '../../components/Project/archive/ArchiveList';
import { checkIdsMatchingForLiveUpdate } from '../../helpers/checkIdsForLiveUpdate';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';

const ArchivePage = () => {
  const { archives, archiveLiveUpdateData, liveUpdateData } = useTypedSelector((state) => state.project);
  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    dispatch.project.getArchives();
  }, []);

  useEffect(() => {
    if (
      archiveLiveUpdateData
      // && checkIdsMatchingForLiveUpdate(archiveLiveUpdateData, archives)
    ) {
      dispatch.project.getArchives();
      dispatch.project.setArchiveLiveUpdateData(null);
    }
  }, [archiveLiveUpdateData]);

  useEffect(() => {
    if (liveUpdateData && checkIdsMatchingForLiveUpdate(liveUpdateData, archives)) {
      dispatch.project.getArchives();
      dispatch.project.setLiveUpdateData(null);
    }
  }, [liveUpdateData]);

  return <ArchiveList archives={archives} />;
};

export default ArchivePage;
