import { useEffect, useState } from 'react';
import { getLinearInterpolation } from '../helpers/diagram';
import { THREATS_REGISTER_ROUTE } from '../store/constants/route-constants';

export function useMouseCoordinates() {
  let counter = 0;

  const [mouseCoordinates, setMouseCoordinates] = useState({
    x: 0,
    y: 0,
  });

  const mouseMoveHandler = (event: MouseEventInit) => {
    if (window.location.pathname.includes(THREATS_REGISTER_ROUTE)) return;

    counter += 1;
    const regex = /[-+]?\d+(\.\d+)?/g;

    const viewport: any = document.querySelector('.react-flow__viewport');
    const numbers = viewport?.style?.transform?.match(regex) || [0, 0, 0];

    const x = Number.parseFloat(numbers[0]);
    const y = Number.parseFloat(numbers[1]);
    const zoom = Number.parseFloat(numbers[2]);

    const panElement = document?.querySelector('.react-flow__pane');
    const nowIsDragging = panElement && panElement?.classList?.contains('dragging');

    if (counter % 15 === 0 && !nowIsDragging) {
      setMouseCoordinates({
        x: (x - (event.clientX || 0)) / zoom + getLinearInterpolation(zoom, 135, 235),
        y: (y - (event.clientY || 0)) / zoom + 27,
      });
      counter = 0;
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', mouseMoveHandler, true);

    return () => {
      window.removeEventListener('mousemove', mouseMoveHandler, true);
    };
  }, []);

  return [mouseCoordinates];
}
