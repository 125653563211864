import { useCallback, useEffect, useRef, useState } from 'react';
import { BaseEdge, EdgeLabelRenderer, useReactFlow, type EdgeProps, type XYPosition } from 'reactflow';

import classNames from 'classnames';
import { EDGE_STYLES } from '../../../../helpers/colors';
import { setColorToMarkers } from '../../../../helpers/diagram';
import { useTypedSelector } from '../../../../hooks/useTypeSelector';
import DiagramNodeAttribute from '../../NodeAttributePopup';
import styles from '../CustomEdge.module.css';
import EdgeInput from '../EdgeInput';
import EdgePopUpTools from '../EdgePopUpTools/EdgePopUpTools';
import type { Algorithm } from './constants';
import { ControlPoint, type ControlPointData } from './ControlPoint';
import { getControlPoints, getPath } from './path';
import { getLabelCoords } from './path/utils';

const useIdsForInactiveControlPoints = (points: ControlPointData[]) => {
  const prevIds = useRef<string[]>([]);
  let newPoints: ControlPointData[] = [];

  if (prevIds.current.length === points.length) {
    newPoints = points.map((point, i) => (point.active ? point : { ...point, id: prevIds.current[i] }));
  } else {
    newPoints = points.map((prevPoint, i) => {
      const id = window.crypto.randomUUID();
      prevIds.current[i] = id;

      return prevPoint.active ? points[i] : { ...points[i], id };
    });
  }

  return newPoints;
};

export type EditableEdgeData = {
  algorithm?: Algorithm;
  points: ControlPointData[];
  color?: string;
  markerType?: string;
  borderWidth?: string;
  fontSize?: number;
  autoFontSize?: boolean;
  textStyle?: {
    isBold?: boolean;
  };
  fontColor?: string;
  selectedBy?: string[];
  hiddenLabel?: boolean;
  label: string;
  selected: boolean;
};

export function EditableEdge({
  id,
  selected,
  source,
  sourceX,
  sourceY,
  sourcePosition,
  target,
  targetX,
  targetY,
  targetPosition,
  markerEnd,
  markerStart,
  style,
  data,
  ...delegated
}: EdgeProps<EditableEdgeData>) {
  const { nodesSharedState, edgesSharedState } = useTypedSelector((state) => state.diagram);
  const currentUserId = useTypedSelector((state) => state.user?.current?.id) || '';
  const collaborators = useTypedSelector((state) => state.diagram.collaborators);
  const { selectedNode, attributes, mitigatingAttributes } = useTypedSelector((state) => state.diagram);
  const { setEdges } = useReactFlow();
  const [attributesPopup, setAttributesPopup] = useState<any>({
    visible: false,
    componentId: null,
  });

  const sourceOrigin = { x: sourceX, y: sourceY } as XYPosition;
  const targetOrigin = { x: targetX, y: targetY } as XYPosition;

  const hasCollaborators =
    collaborators?.length &&
    !!collaborators.filter((id) => {
      return data?.selectedBy?.includes(id);
    })?.length;

  const sourceNode = nodesSharedState?.get(source);
  const targetNode = nodesSharedState?.get(target);
  const shouldShowPoints = selected || sourceNode?.selected || targetNode?.selected;

  useEffect(() => {
    if (selected) {
      const edgeElement = document.querySelector(`g[data-testid='rf__edge-${id}']`);
      edgeElement?.parentElement?.insertAdjacentElement('beforeend', edgeElement);
      setColorToMarkers(data?.color || '');
    }
  }, [selected]);

  useEffect(() => {
    return () => {
      setAttributesPopup({ visible: false, componentId: null });
    };
  }, [selected]);

  useEffect(() => {
    if (attributes?.length || (mitigatingAttributes && Object.keys(mitigatingAttributes)?.length)) {
      setAttributesPopup({
        ...attributesPopup,
        visible: false,
      });
    }
  }, [attributes?.length || mitigatingAttributes]);

  const setControlPoints = useCallback(
    (update: (points: ControlPointData[]) => ControlPointData[]) => {
      const currentEdge = edgesSharedState?.get(id);

      if (currentEdge && currentEdge.type === EDGE_STYLES.EDITABLE) {
        const points = currentEdge?.data?.points ?? [];
        const data = { ...currentEdge.data, points: update(points) };
        edgesSharedState?.set(id, {
          ...currentEdge,
          data,
        });
      }
    },
    [id, setEdges],
  );

  const points = data?.points ? data.points : [];
  const pathPoints = [sourceOrigin, ...points, targetOrigin];
  const controlPoints = getControlPoints(pathPoints, data?.algorithm, {
    fromSide: sourcePosition,
    toSide: targetPosition,
  });
  const path = getPath(pathPoints, data?.algorithm, {
    fromSide: sourcePosition,
    toSide: targetPosition,
  });

  const getMarkerEnd = (slt: boolean) => {
    if (data?.markerType) {
      if (data.markerType === 'end' || data.markerType === 'both') {
        return slt ? 'url(#selectedEdgeTriangle)' : markerEnd;
      }

      return slt ? 'url(#selectedEdgeSquare)' : undefined;
    }

    return slt ? 'url(#selectedEdgeTriangle)' : markerEnd;
  };

  const getMarkerStart = (slt: boolean) => {
    if (data?.markerType) {
      if (data.markerType === 'start' || data.markerType === 'both') {
        return slt ? 'url(#selectedEdgeTriangle)' : markerEnd;
      }

      return slt ? 'url(#selectedEdgeSquare)' : undefined;
    }

    return slt ? 'url(#selectedEdgeSquare)' : undefined;
  };

  const controlPointsWithIds = useIdsForInactiveControlPoints(controlPoints);
  const isSelected = selected && !attributesPopup.visible && data?.selectedBy?.includes(currentUserId);

  const { labelX, labelY } = getLabelCoords(
    {
      sourceX,
      sourceY,
      targetX,
      targetY,
    },
    data?.points ? data.points : [],
    isSelected,
    data?.algorithm,
  );

  return (
    <>
      <BaseEdge
        id={id}
        path={path}
        {...delegated}
        markerEnd={getMarkerEnd(!!isSelected)}
        markerStart={getMarkerStart(!!isSelected)}
        style={{
          strokeWidth: `${data?.borderWidth || 2}px`,
          stroke: data?.color || 'var(--dark-gray)',
        }}
      />

      {shouldShowPoints &&
        controlPointsWithIds.map((point, index) => (
          <ControlPoint key={point.id} index={index} setControlPoints={setControlPoints} color="#000" {...point} />
        ))}

      <EdgeLabelRenderer>
        <div
          data-isedge={1}
          data-edgeid={id}
          data-edgex={labelX}
          data-edgey={labelY}
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            cursor: selectedNode?.length ? 'crosshair' : 'pointer',
            backgroundColor: data?.color ? data.color.substring(0, 7) : '',
            zIndex: isSelected ? 100 : 2,
            padding: data?.hiddenLabel ? 0 : '3px',
          }}
          className={classNames('nodrag nopan fs-12 position-absolute text-center', styles.edgeComponent, {
            [styles.hasCollaborators]: hasCollaborators,
          })}
        >
          {data?.hiddenLabel ? null : (
            <EdgeInput
              id={id}
              value={data?.label || ''}
              color={data?.color}
              selected={!!selected}
              fontSize={data?.fontSize || 12}
              autoFontSize={data?.autoFontSize}
              fontBold={data?.textStyle?.isBold}
              fontColor={data?.fontColor}
            />
          )}
          {isSelected && (
            <EdgePopUpTools
              edge={{
                id,
                color: data?.color,
                markerType: data?.markerType,
                borderWidth: data?.borderWidth,
                fontSize: data?.fontSize,
                autoFontSize: data?.autoFontSize,
                textStyle: data?.textStyle,
                fontColor: data?.fontColor || '#ffffff',
                hiddenLabel: data?.hiddenLabel,
              }}
              setAttributesPopup={() => {
                setAttributesPopup({
                  componentId: id,
                  visible: true,
                  id,
                });
              }}
            />
          )}
          {selected && attributesPopup.visible && (
            <div className={styles.attributesPopupWrap}>
              <DiagramNodeAttribute data={attributesPopup} setData={setAttributesPopup} />
            </div>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
