import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import ProjectHeader from '../../components/Project/ProjectHeader';
import RunningTableList from '../../components/Running/RunningTableList/RunningTableList';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';

const POLLING_INTERVAL = 30000;
const RunningListPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const params = useParams();
  const { id: projectId } = params;
  const { current } = useTypedSelector((state) => state.project);
  const { runningListQuery } = useTypedSelector((state) => state.running);

  useEffect(() => {
    if (projectId) {
      dispatch.project.getProjectById(projectId);
      dispatch.running.getRunningListByProjectId({ projectId, ...runningListQuery });
    }

    return () => {
      dispatch.project.setCurrent(null);
      dispatch.threatModel.setThreatModels(null);
    };
  }, [projectId]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch.running.getRunningListByProjectId({ projectId, ...runningListQuery });
    }, POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  return (
    current && (
      <div style={{ padding: '10px', position: 'relative' }}>
        <ProjectHeader project={current} />
        <RunningTableList />
      </div>
    )
  );
};

export default RunningListPage;
