import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import {
  cleanUserTokensFromLocalStorage,
  getAccessToken,
  getRefreshToken,
  getSessionToken,
  setUserTokensToLocalStorage,
} from '../../helpers/user';
import { REFRESH_TOKEN_URL } from '../constants/api-constants';
import { SIGN_IN_ROUTE } from '../constants/route-constants';

const baseURL = process.env.REACT_APP_API_URL;

const http = axios.create({ baseURL });

http.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const accessToken = getAccessToken();
    const sessionToken = getSessionToken();

    if (config.headers === undefined) {
      config.headers = {};
    }

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers.Session = `Bearer ${sessionToken}`;
    }

    return config;
  },
  (error) => error,
);

let refresh = false;

http.interceptors.response.use(
  (resp) => resp,
  async (error) => {
    if (error?.response?.status === 401 && !refresh) {
      try {
        const refreshToken = getRefreshToken();

        if (!refreshToken) {
          return (window.location.href = SIGN_IN_ROUTE);
        }

        const response = await axios.post(`${baseURL}/${REFRESH_TOKEN_URL}`, {
          refreshToken,
        });

        refresh = true;
        const { access_token, session_token } = response.data;
        setUserTokensToLocalStorage(access_token, refreshToken, session_token);
        error.config.headers = {
          ...error.config.headers,
          Authorization: `Bearer ${access_token}`,
          Session: `Bearer ${session_token}`,
        };

        if (response.data.access_token?.length) refresh = false;

        return await http.request(error.config);
      } catch (er) {
        cleanUserTokensFromLocalStorage();

        // eslint-disable-next-line no-return-assign
        return (window.location.href = SIGN_IN_ROUTE);
      }
    }

    refresh = false;

    return error;
  },
);

export default http;
