import styles from './index.module.css';

const Index = () => {
  return (
    <>
      <div className={styles.breakLine} />

      <div className={styles.links}>
        <a
          className={styles.linkItem}
          href="https://devici.com/terms-policies/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy policy
        </a>
        <div className={styles.breakLineVertical} />
        <a
          className={styles.linkItem}
          href="https://devici.com/terms-policies/terms-of-use"
          target="_blank"
          rel="noreferrer"
        >
          Terms of service
        </a>
      </div>
    </>
  );
};

export default Index;
