import classNames from 'classnames';
import type { FC } from 'react';
import { Route, Routes } from 'react-router';
import { ReactFlowProvider } from 'reactflow';

import type { EmitUpdate } from '../../../hooks/useSocket';
import AddMitigationForm from '../../Diagram/DiagramDrown/AddMitigationForm';
import Checklist from '../../Diagram/DiagramDrown/CheckList/Checklist';
import MitigationExplorer from '../../Diagram/DiagramDrown/MitigationExplorer';
import { ThreatRegisterMap } from '../ThreatRegisterMap/ThreatRegisterMap';
import styles from './ThreatRegisterDrawer.module.css';

type ThreatRegisterDrawerProps = {
  emitUpdate: EmitUpdate;
};

export const ThreatRegisterDrawer: FC<ThreatRegisterDrawerProps> = ({ emitUpdate }) => {
  return (
    <div className={classNames(styles.sidebarContainer, 'bg-white noScale h-100')} id="threats-register-drawn-element">
      <Routes>
        <Route
          path="/"
          element={
            <ReactFlowProvider>
              <ThreatRegisterMap />
            </ReactFlowProvider>
          }
        />
        <Route path="/checklist" element={<Checklist emitUpdate={emitUpdate} />} />
        <Route path="/explorer" element={<MitigationExplorer emitUpdate={emitUpdate} autoHeight />} />
        <Route path="/m/:mitigationId" element={<AddMitigationForm emitUpdate={emitUpdate} />} />
        <Route path="element/:threatsId/checklist" element={<Checklist emitUpdate={emitUpdate} />} />
        <Route path="element/:threatsId/explorer" element={<MitigationExplorer emitUpdate={emitUpdate} />} />
        <Route path="element/:threatsId/m/:mitigationId" element={<AddMitigationForm emitUpdate={emitUpdate} />} />
      </Routes>
    </div>
  );
};
