import type { FC } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';

import type { Dispatch, RootState } from '../../../../store/store';
import styles from '../styles/index.module.css';

type CheckBoxForAuthSessionProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const CheckBoxForAuthSession: FC<CheckBoxForAuthSessionProps> = ({
  initialIsLongTermSession,
  setIsLongTermAuthSession,
  sessionDuration,
}) => {
  const [isLongTermSession, setIsLongTermSession] = useState(initialIsLongTermSession);

  const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isLongTerm = e.target.checked;

    setIsLongTermSession(isLongTerm);
    setIsLongTermAuthSession(isLongTerm);
  };

  return (
    <div className={styles.authSessionCheckBox}>
      <div className="d-flex gap-2 align-items-center mt-1 m-auto">
        <input
          id="checkbox"
          checked={isLongTermSession}
          type="checkbox"
          onChange={onChangeCheckbox}
          className="form-check-input m-0"
        />
        <label htmlFor="checkbox" className="m-0 cursor-pointer text-black">
          Remember me for the next {sessionDuration} days
        </label>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  initialIsLongTermSession: state.auth.isLongTermSession,
  sessionDuration: state.auth.sessionDuration,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setIsLongTermAuthSession: dispatch.auth.setIsLongTermSession,
});

export default connect(mapState, mapDispatch)(CheckBoxForAuthSession);
