import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';
import UiButton from '../common/UIButton/UiButton';
import styles from './ProjectList.module.css';
import ProjectListTable from './ProjectListTable';

const ProjectList = () => {
  const { projects, liveUpdateData, query } = useTypedSelector((state) => state.project);
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch.project.getProjects(false);

    return () => {
      dispatch.project.setQuery({
        ...query,
        page: 0,
        limit: 25,
      });
    };
  }, []);

  useEffect(() => {
    if (liveUpdateData) {
      dispatch.project.getProjects(false);
      dispatch.project.setLiveUpdateData(null);
    }
  }, [liveUpdateData]);

  return (
    <div className={styles.content} data-testid="project-list-content">
      <div className={styles.header}>
        <Link to="/collections/create">
          <UiButton>New Collection</UiButton>
        </Link>
      </div>
      <ProjectListTable projects={projects} />
    </div>
  );
};

export default ProjectList;
