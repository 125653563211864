import type { FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './ReSettings.module.css';

type ReSettingsProps = {
  setVisible: any;
  onRestoreClick: any;
  onDeleteClick: any;
  top?: number;
  left?: number;
};

const ReSettings: FC<ReSettingsProps> = ({ setVisible, onRestoreClick, onDeleteClick, top, left }) => {
  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(null)}>
      <div
        className={styles.modal}
        style={{
          top: top || '0px',
          left: left || '0px',
        }}
      >
        <span className={styles.triangleTop} />
        <div className={styles.listItem} onClick={onRestoreClick}>
          Restore
        </div>
        <div className={styles.listItem} onClick={onDeleteClick}>
          Delete
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default ReSettings;
