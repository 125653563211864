import { createModel } from '@rematch/core';
import { toast } from 'react-toastify';
import http from '../http/http-common';
import type { RootModel } from './index';

import type { TIER } from '../../global/constants';
import { TIERS_CUSTOMER_LIMITS } from '../constants/api-constants';

export type CustomerLimits = {
  isBetaUser: boolean;
  billingPeriod?: string;
  tier: (typeof TIER)[keyof typeof TIER];
  seats: {
    limit: number;
    available: number;
    isSeatsLimitExceeded: boolean;
  };
  threatModels?: {
    limit: number;
    available: number;
    representationsPerModel: number;
  };
  codeGenius?: {
    limit: number;
    available: number;
  };
} | null;

type TiersState = {
  customerLimits: CustomerLimits;
};

export const tiers = createModel<RootModel>()({
  state: {
    customerLimits: null,
  } as TiersState,
  reducers: {
    setCustomerLimits(state, customerLimits: CustomerLimits) {
      return {
        ...state,
        customerLimits,
      };
    },
    resetTiersState(state) {
      return {
        ...state,
        customerLimits: null,
      };
    },
  },
  effects: (dispatch) => {
    return {
      async getCustomerLimits() {
        try {
          const response = await http(`${TIERS_CUSTOMER_LIMITS}`);

          if (response.status === 200) {
            dispatch.tiers.setCustomerLimits({
              ...response.data,
              customerLimits: response.data,
            });

            return response.data;
          }
        } catch (error: any) {
          toast.warning(error?.message || '');
        }
      },
    };
  },
});
