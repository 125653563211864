import { useEffect } from 'react';

type ResizeObserverCallback = (rect: Element) => void;

const useResizeObserver = (element: Element | null, callback: ResizeObserverCallback) => {
  useEffect(() => {
    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        callback(entry.target);
      }
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
    };
  }, [element, callback]);
};

export default useResizeObserver;
