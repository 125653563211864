import { createModel } from '@rematch/core';
import { toast } from 'react-toastify';
import type { RootModel } from '.';
import type { SessionList } from '../../global/types';
import { CUSTOMER_URL } from '../constants/api-constants';
import http from '../http/http-common';

type CustomerState = {
  companyName: string | null;
  logo: string | null;
  isMFARequired: boolean;
  isLoading: 'get-mfa' | 'update-mfa' | null;
  sessionList: SessionList[];
  activeSession: number;
  isSessionLoading: boolean;
  isSamlConfigured: boolean | null;
};

export const customer = createModel<RootModel>()({
  state: {
    companyName: null,
    logo: null,
    isMFARequired: false,
    isSessionLoading: false,
    isSamlConfigured: null,
  } as CustomerState,
  reducers: {
    setCompanyName(state, companyName) {
      return { ...state, companyName };
    },
    setLogo(state, logo) {
      return { ...state, logo };
    },
    setIsMFARequired(state, isMFARequired) {
      return { ...state, isMFARequired };
    },
    setIsLoading(state, isLoading: CustomerState['isLoading'] = null) {
      return { ...state, isLoading };
    },
    setSessionList(state, sessionList) {
      return { ...state, sessionList };
    },
    setActiveSession(state, activeSession) {
      return { ...state, activeSession };
    },
    setSessionLoading(state, isSessionLoading: boolean) {
      return { ...state, isSessionLoading };
    },
    setIsSamlConfigured(state, isSamlConfigured) {
      return { ...state, isSamlConfigured };
    },
  },
  effects: (dispatch) => {
    return {
      async getCustomer({ needAvatar }) {
        const response = await http(`${CUSTOMER_URL}${needAvatar ? '?needAvatar=true' : ''}`);

        if (response.status === 200) {
          if (response?.data?.name) {
            dispatch.customer.setCompanyName(response.data.name);
          }

          if (response?.data?.logo) {
            dispatch.customer.setLogo(response.data.logo);
          }
        }
      },
      async updateCompany(company) {
        try {
          const response = await http.patch(`${CUSTOMER_URL}`, company);

          if (response.status === 200) {
            toast.success('Organization name updated!');
            dispatch.customer.setCompanyName(response?.data?.name);
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },
      async uploadCompanyLogo(file) {
        try {
          const formData = new FormData();
          formData.append('file', file);
          const response: any = await http.post(`${CUSTOMER_URL}/upload-logo`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (response.status === 201) {
            toast.success('Organization logo saved!');
            await dispatch.customer.getCustomer({ needAvatar: true });
          }

          if (response?.response?.status === 400) {
            toast.error(response?.response?.data?.message.toString() || 'Organization logo not uploaded!');
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },
      async removeCompanyLogo() {
        try {
          const response = await http.delete(`${CUSTOMER_URL}/logo`);

          if (response.status === 200) {
            toast.success('Organization logo deleted!');
            dispatch.customer.setLogo(null);
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async updateMFA(isMFARequired: boolean) {
        try {
          dispatch.customer.setIsLoading('update-mfa');
          const response = await http.patch(`${CUSTOMER_URL}/mfa`, { isMFARequired });

          if (response.status === 200) {
            dispatch.customer.setIsMFARequired(response.data.isMFARequired);
          }
        } catch (error: any) {
          if (error instanceof Error) {
            toast.warning(error.message);
          }

          toast.warning(String(error));
        } finally {
          dispatch.customer.setIsLoading();
        }
      },

      async getMFA() {
        try {
          dispatch.customer.setIsLoading('get-mfa');
          const response = await http.get(`${CUSTOMER_URL}/mfa`);

          if (response.status === 200) {
            dispatch.customer.setIsMFARequired(response.data.isMFARequired);
          }
        } catch (error: any) {
          if (error instanceof Error) {
            toast.warning(error.message);
          }

          toast.warning(String(error));
        } finally {
          dispatch.customer.setIsLoading();
        }
      },

      async updateSessionDuration(sessionDuration: number) {
        try {
          dispatch.customer.setSessionLoading(true);
          const response = await http.patch(`${CUSTOMER_URL}/session-duration`, { sessionDuration });

          if (response.status === 200) {
            dispatch.customer.setActiveSession(response.data.sessionDuration);
          }
        } catch (error) {
          if (error instanceof Error) {
            toast.warning(error.message);
          } else {
            toast.warning(String(error));
          }
        } finally {
          dispatch.customer.setSessionLoading(false);
        }
      },

      async getSessionList() {
        try {
          dispatch.customer.setSessionLoading(true);

          const response = await http.get(`${CUSTOMER_URL}/session-list`);

          if (response.status === 200) {
            dispatch.customer.setSessionList(response.data.sessionList);
            dispatch.customer.setActiveSession(response.data.activeSession);
          }
        } catch (error) {
          if (error instanceof Error) {
            toast.warning(error.message);
          } else {
            toast.warning(String(error));
          }
        } finally {
          dispatch.customer.setSessionLoading(false);
        }
      },
    };
  },
});
