import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Spinner } from 'reactstrap';

import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';
import DiagramsPage from '../DiagramsPage';

export const ThreatModelPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const { data: representation } = useTypedSelector((state) => state.representation);
  const { diagramId } = useParams();

  useEffect(() => {
    if (diagramId && diagramId !== representation?.id) {
      dispatch.representation.setData(null);
      dispatch.representation.getRepresentation(diagramId);
      dispatch.diagram.setUndoManager(null);
    }
  }, [diagramId]);

  if (representation?.id) return <DiagramsPage diagramId={representation?.id} />;

  return (
    <div className="h-100 d-flex justify-content-center align-items-center">
      <Spinner color="dark" />
    </div>
  );
};

export default ThreatModelPage;
