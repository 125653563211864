import { useFormik } from 'formik';
import type { FC } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';

type teamModalProps = {
  onToggle: () => void;
  onCreate: (title: string) => void;
  toggle: boolean;
};

const TeamModal: FC<teamModalProps> = ({ toggle, onToggle, onCreate }) => {
  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().min(6, 'Minimum 6 characters required').required('Required'),
    }),
    onSubmit: (values) => {
      onCreate(values.title);
      formik.values.title = '';
    },
  });

  return (
    <Modal isOpen={toggle} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>Create new Team</ModalHeader>
      <ModalBody>
        <Form id="team-form" onSubmit={formik.handleSubmit}>
          <FormGroup row>
            <Label for="team-title-modal" sm={1}>
              <strong>Title</strong>
            </Label>
            <Col sm={11}>
              <Input
                id="team-title-modal"
                name="title"
                placeholder="Enter team title ..."
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.title && formik.errors.title ? (
                <p className="text-danger">{formik.errors.title}</p>
              ) : null}
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button form="team-form" color="success" type="submit" disabled={!formik.isValid}>
          Create
        </Button>{' '}
        <Button color="secondary" onClick={onToggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TeamModal;
