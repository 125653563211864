import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import { ROLE } from '../../store/models/user';
import NotFoundPage from '../NotFoundPage';
import CustomCodexPage from './CustomCodexPage';
import CustomCodexAttributesPage from './CustomCodexPage/AttributesPage';
import CustomCodexMitigationsPage from './CustomCodexPage/MitigationsPage';
import CustomCodexThreatsPage from './CustomCodexPage/ThreatsPage';
import SecurityPage from './SecurityPage';
import SettingsPage from './SettingsPage';
import UsersPage from './UsersPage';
import UserAddPage from './UsersPage/UserAddPage';

const AdminPage: FC = () => {
  const { current } = useTypedSelector((state) => state.user);

  if (current && current?.role !== ROLE.ADMIN) return <NotFoundPage />;

  return (
    <div style={{ padding: 0 }}>
      <Routes>
        <Route path="/" element={<SettingsPage />} />
        <Route
          path={'/users/*'}
          element={
            <div>
              <Routes>
                <Route path="/" element={<UsersPage />} />
                <Route path="/add" element={<UserAddPage />} />
              </Routes>
            </div>
          }
        />
        <Route path="/security" element={<SecurityPage />} />
        <Route path="/codex/" element={<CustomCodexPage />} />
        <Route path="/codex/attributes/*" element={<CustomCodexAttributesPage />} />
        <Route path="/codex/threats/*" element={<CustomCodexThreatsPage />} />
        <Route path="/codex/mitigations/*" element={<CustomCodexMitigationsPage />} />
        <Route path={'/*'} element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default AdminPage;
