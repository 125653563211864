import classNames from 'classnames';
import type { FC } from 'react';
import { Envelope } from 'react-bootstrap-icons';
import { stringToBackgroundColor, stringToCommonColor } from '../../../helpers/colors';
import styles from './UserPopUp.module.css';

type UserPopUpProps = {
  img?: string;
  fullName?: string;
  email?: string;
  shortName?: string;
};

const UserPopUp: FC<UserPopUpProps> = ({ fullName = 'laurent', img, email = 'example@email.com', shortName = '' }) => {
  return (
    <div className={styles.popUp}>
      <span className={styles.triangle} />
      <div className={styles.popUpHeader} onClick={(e) => e.stopPropagation()}>
        {img ? (
          <img className={classNames(styles.userAvatar)} alt={fullName} src={img} />
        ) : (
          <span
            style={{
              background: stringToBackgroundColor(fullName),
              color: stringToCommonColor(fullName),
            }}
            className={styles.userAvatarText}
          >
            {shortName}
          </span>
        )}
      </div>
      <div className={styles.popUpBody} onClick={(e) => e.stopPropagation()}>
        <div className={styles.fullName}>{fullName}</div>
        <div className={styles.email}>
          <Envelope size={20} />
          <p title={email}>{email}</p>
        </div>
      </div>
      {/* <div className={styles.popUpFooter}> */}
      {/*  <button onClick={handleProfileClick} className={styles.openProfileBtn}> */}
      {/*    Open profile */}
      {/*  </button> */}
      {/* </div> */}
    </div>
  );
};

export default UserPopUp;
