import { createModel } from '@rematch/core';
import { toast } from 'react-toastify';

import { AUTH_FLOW } from '../../global/auth';
import history from '../../helpers/history';
import {
  cleanUserTokensFromLocalStorage,
  getAccessToken,
  getIsAuthenticated,
  getRefreshToken,
  removeCodeChallengeFromLocalStorage,
  removeIsLongTermSessionFromLocalStorage,
  setCodeChallengeToLocalStorage,
  setUserTokensToLocalStorage,
} from '../../helpers/user';
import {
  CHECK_AUTH_FLOW,
  CHECK_EMAIL,
  CHECK_MFA_CODE,
  CONFIRMATION_URL,
  CONFIRM_FORGOT_PASSWORD,
  EXCHANGE_CODE_URL,
  FORGOT_PASSWORD,
  HUBSPOT,
  RECONNECT_MFA_CODE,
  REDEEM_INVITATION_CODE,
  REFRESH_TOKEN_URL,
  RESEND_CONFIRMATION_URL,
  SET_PASSWORD,
  SIGN_IN_URL,
  SIGN_OUT_URL,
  SIGN_UP_URL,
  VERIFY_MFA_TOKEN,
  VERIFY_RECONNECT_MFA_CODE,
} from '../constants/api-constants';
import { SIGN_IN_ROUTE } from '../constants/route-constants';
import { STRIPE_INACTIVE_STATUS } from '../constants/stripe-constants';
import http from '../http/http-common';
import type { RootModel } from './index';

interface IMfaCheckData {
  session: string;
}

interface IMfaSetupData extends IMfaCheckData {
  code: string;
  qrImage: string;
}

type AuthState = {
  authFlow?: AUTH_FLOW;
  sessionDuration: number;
  isAuthenticated: boolean;
  isEmailChecked: boolean;
  isSignedUp: boolean;
  isConfirmed: boolean;
  accessToken: '';
  refreshToken: '';
  samlRedirectUrl: string | null;
  paymentMethods: Array<{ id: string; card: { brand: string; last4: string } }>;
  subscription: { nickname: string; status: string };
  id?: '';
  username?: '';
  avatar?: null;
  created_at?: '';
  customerStripeId?: null;
  email?: '';
  roles?: '';
  isCLIAuth?: boolean;
  isAuthLoading: boolean;
  mfaSetupData: IMfaSetupData | null;
  mfaCheckData: IMfaCheckData | null;
  isLongTermSession: boolean;
  isMFARequired: boolean;
  isHideOnboarding: boolean;
};

export const auth = createModel<RootModel>()({
  state: {
    isEmailChecked: false,
    isAuthenticated: getIsAuthenticated(),
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
    isSignedUp: false,
    isConfirmed: false,
    paymentMethods: [],
    subscription: { nickname: '', status: STRIPE_INACTIVE_STATUS },
    samlRedirectUrl: null,
    isCLIAuth: false,
    isAuthLoading: false,
    mfaSetupData: null,
    mfaCheckData: null,
    sessionDuration: 1,
    isLongTermSession: false,
    isMFARequired: true,
    isHideOnboarding: false,
  } as AuthState,
  reducers: {
    setIsAuthenticated(state, { isAuthenticated }) {
      return {
        ...state,
        isAuthenticated,
      };
    },

    setIsEmailChecked(state, data) {
      return {
        ...state,
        isEmailChecked: data,
      };
    },

    setIsSignedUp(state, isSuccessful) {
      return {
        ...state,
        isSignedUp: isSuccessful,
      };
    },

    setIsConfirmed(state, isSuccessful) {
      return {
        ...state,
        isConfirmed: isSuccessful,
      };
    },

    resetSignUpState(state) {
      return {
        ...state,
        isConfirmed: false,
        isSignedUp: false,
        isEmailChecked: false,
      };
    },

    setAuthFlow(state, { authFlow, sessionDuration = 1 }) {
      return {
        ...state,
        authFlow,
        sessionDuration,
      };
    },

    resetAuthFlow(state) {
      return {
        ...state,
        authFlow: undefined,
      };
    },

    setSamlRedirectUrl(state, samlRedirectUrl) {
      return {
        ...state,
        samlRedirectUrl,
      };
    },

    setTokens(state, { accessToken, refreshToken, sessionToken }) {
      return {
        ...state,
        accessToken,
        refreshToken,
        sessionToken,
      };
    },

    setPaymentMethods(state, paymentMethods) {
      return {
        ...state,
        paymentMethods,
      };
    },

    setSubscription(state, subscription) {
      return {
        ...state,
        subscription,
      };
    },

    setUser(state, user) {
      return {
        ...state,
        ...user,
      };
    },

    setIsCLIAuth(state, isCLIAuth) {
      return {
        ...state,
        isCLIAuth,
      };
    },

    setIsAuthLoading(state, isAuthLoading) {
      return {
        ...state,
        isAuthLoading,
      };
    },

    setMfaSetupData(state, mfaSetupData) {
      return {
        ...state,
        mfaSetupData,
      };
    },

    setMfaCheckData(state, mfaCheckData) {
      return {
        ...state,
        mfaCheckData,
      };
    },
    resetAuthState(state) {
      return {
        ...state,
        email: '',
        isSignedUp: false,
        isConfirmed: false,
        mfaCheckData: null,
        mfaSetupData: null,
        authFlow: undefined,
        isAuthLoading: false,
        isEmailChecked: false,
        isLongTermSession: false,
      };
    },
    setIsLongTermSession(state, isLongTermSession) {
      return {
        ...state,
        isLongTermSession,
      };
    },
    setIsMFARequired(state, isMFARequired: boolean) {
      return {
        ...state,
        isMFARequired,
      };
    },
    setIsHideOnboarding(state, isHideOnboarding) {
      return {
        ...state,
        isHideOnboarding,
      };
    },
  },

  effects: (dispatch) => ({
    async checkEmail({ email }) {
      try {
        dispatch.auth.setIsAuthLoading(true);
        const result = await http(`${CHECK_EMAIL}?email=${email}`);
        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;

        if (result.request?.status === 200) {
          dispatch.auth.setIsEmailChecked(true);

          if (result.data.authFlow === AUTH_FLOW.UNCONFIRMED) {
            dispatch.auth.setIsSignedUp(true);
            dispatch.auth.setIsConfirmed(false);
          }
        } else {
          toast.error(response?.message?.toString());
        }

        dispatch.auth.setIsAuthLoading(false);
      } catch (error) {
        console.error(error);
      }
    },

    async signUp({ email, password }) {
      try {
        dispatch.auth.setIsAuthLoading(true);
        const [title] = (email as string).split('@');
        const lastName = `${title[0].toUpperCase()}`;
        const firstName = `${lastName}${title.slice(1)}`;
        const data = {
          email,
          password,
          lastName,
          firstName,
          name: `${firstName} company`,
        };
        const result = await http.post(SIGN_UP_URL, data);
        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;

        if (result.status === 201) {
          toast.success(response?.message);
          dispatch.auth.setIsSignedUp(true);
        } else {
          toast.error(response?.message?.toString());
        }

        dispatch.auth.setIsAuthLoading(false);
      } catch (error) {
        console.error(error);
      }
    },

    async resendSignUpConfirmationCode({ email }) {
      try {
        dispatch.auth.setIsAuthLoading(true);
        const result = await http.post(RESEND_CONFIRMATION_URL, { email });
        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;

        if (result.status === 201) {
          toast.success(response?.message);
        } else {
          toast.error(response?.message?.toString());
        }

        dispatch.auth.setIsAuthLoading(false);
      } catch (error) {
        console.error(error);
      }
    },

    async confirm({ code, email, password }) {
      try {
        dispatch.auth.setIsAuthLoading(true);
        const result = await http.post(CONFIRMATION_URL, { code, email });
        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;

        if (result.status === 201) {
          await this.signIn({ email, password });
          dispatch.auth.setIsConfirmed(true);
        } else {
          dispatch.auth.setIsAuthLoading(false);
          toast.error(response?.message?.toString());
        }
      } catch (error) {
        console.error(error);
      }
    },

    async checkAuthFlow({ email }) {
      try {
        dispatch.auth.setIsAuthLoading(true);
        const result = await http.post(CHECK_AUTH_FLOW, {
          email,
        });

        if (result.status === 201) {
          if (result.data?.authFlow === AUTH_FLOW.SAML) {
            const { authFlow, authData, isMFARequired } = result.data;
            dispatch.auth.setAuthFlow(authFlow);
            setCodeChallengeToLocalStorage(authData.codeChallenge);
            dispatch.auth.setIsMFARequired(isMFARequired);

            dispatch.auth.setSamlRedirectUrl(authData.redirectUrl);

            return;
          }

          dispatch.auth.setAuthFlow(result.data);
          dispatch.auth.setIsMFARequired(result.data.isMFARequired);
        } else {
          const responseText = result.request?.responseText;

          if (responseText?.length) {
            const response = JSON.parse(responseText);
            toast.error(response?.message);
          }
        }

        dispatch.auth.setIsAuthLoading(false);
      } catch (error) {
        console.error(error);
      }
    },

    async redeemInvitationCode({ email, code }) {
      try {
        const result = await http.post(REDEEM_INVITATION_CODE, {
          email,
          code,
        });

        if (result.status === 201) {
          dispatch.auth.setAuthFlow(result.data);
        } else {
          const responseText = result.request?.responseText;

          if (responseText?.length) {
            const response = JSON.parse(responseText);
            toast.error(response?.message);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async setPermanentPassword({ email, password }) {
      try {
        const result = await http.post(SET_PASSWORD, { email, password });

        if (result.status === 201) {
          await this.signIn({
            email,
            password,
          });
        } else {
          const responseText = result.request?.responseText;

          if (responseText?.length) {
            const response = JSON.parse(responseText);
            toast.error(response?.message);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async forgotPassword({ email }) {
      try {
        dispatch.auth.setIsAuthLoading(true);
        const result = await http.post(FORGOT_PASSWORD, { email });

        if (result.status === 201) {
          toast.success('Success! Please check email');
          dispatch.auth.setAuthFlow({
            authFlow: AUTH_FLOW.CONFIRM_FORGOT_PASSWORD,
          });
        } else {
          const responseText = result.request?.responseText;

          if (responseText?.length) {
            const response = JSON.parse(responseText);
            toast.error(response?.message);
          }
        }

        dispatch.auth.setIsAuthLoading(false);
      } catch (error) {
        console.error(error);
      }
    },

    async confirmForgotPassword({ email, password, code }) {
      try {
        dispatch.auth.setIsAuthLoading(true);
        const result = await http.post(CONFIRM_FORGOT_PASSWORD, {
          email,
          password,
          code,
        });

        if (result.status === 201) {
          toast.success('Success!');
          dispatch.auth.setAuthFlow({
            authFlow: AUTH_FLOW.CONFIRM_FORGOT_COMPLETED,
          });
        } else {
          const responseText = result.request?.responseText;

          if (responseText?.length) {
            const response = JSON.parse(responseText);
            toast.error(response?.message);
          }
        }

        dispatch.auth.setIsAuthLoading(false);
      } catch (error: any) {
        console.error(error);
      }
    },

    async signIn({ email, password, needChangeFlow = true }, state) {
      try {
        dispatch.auth.setIsAuthLoading(true);
        const result = await http.post(SIGN_IN_URL, {
          email,
          password,
          isLongTermSession: state.auth.isLongTermSession,
        });

        if (result.status === 201) {
          dispatch.auth.setIsAuthLoading(false);
          const { access_token, refresh_token, session_token, authFlow } = result.data;

          if (authFlow === AUTH_FLOW.MFA_SETUP) {
            const { authFlow, ...mfaSetupData } = result.data;
            dispatch.auth.setAuthFlow({ authFlow });
            dispatch.auth.setMfaSetupData(mfaSetupData);
          }

          if (authFlow === AUTH_FLOW.MFA_CHECK) {
            const { authFlow, ...mfaCheckData } = result.data;

            if (needChangeFlow) {
              dispatch.auth.setAuthFlow({ authFlow });
            }

            dispatch.auth.setMfaCheckData(mfaCheckData);
          }

          if (authFlow === AUTH_FLOW.UNCONFIRMED) {
            const { authFlow } = result.data;
            dispatch.auth.setAuthFlow({ authFlow });
          }

          if (access_token && refresh_token && session_token) {
            dispatch.auth.setIsAuthenticated({ isAuthenticated: true });
            dispatch.auth.setTokens({
              accessToken: access_token,
              refreshToken: refresh_token,
              sessionToken: session_token,
            });

            setUserTokensToLocalStorage(access_token, refresh_token, session_token);
          }
        } else {
          const responseText = result.request?.responseText;

          if (responseText?.length) {
            const response = JSON.parse(responseText);
            toast.error(response?.message);
          }

          dispatch.auth.setIsAuthLoading(false);
        }

        dispatch.auth.setIsAuthLoading(false);
      } catch (error) {
        console.error(error);
      }
    },

    async verifyMfaToken({ session, code, email, password }) {
      try {
        dispatch.auth.setIsAuthLoading(true);
        const result = await http.post(VERIFY_MFA_TOKEN, {
          session,
          email,
          code,
        });

        if (result.status === 201) {
          await this.signIn({ email, password, needChangeFlow: false });
          dispatch.auth.setAuthFlow({
            authFlow: AUTH_FLOW.MFA_SETUP_COMPLETED,
          });
        } else {
          const responseText = result.request?.responseText;

          if (responseText?.length) {
            const response = JSON.parse(responseText);
            toast.error(response?.message);
          }

          dispatch.auth.setIsAuthLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async checkMfaCode({ session, code, email }, state) {
      try {
        dispatch.auth.setIsAuthLoading(true);
        const { isLongTermSession } = state.auth;
        const result = await http.post(CHECK_MFA_CODE, {
          session,
          email,
          code,
          isLongTermSession,
        });

        if (result.status === 201) {
          const { access_token, refresh_token, session_token } = result.data;
          dispatch.auth.setIsAuthenticated({ isAuthenticated: true });
          dispatch.auth.setTokens({
            accessToken: access_token,
            refreshToken: refresh_token,
            sessionToken: session_token,
          });
          setUserTokensToLocalStorage(access_token, refresh_token, session_token);

          dispatch.auth.resetAuthState();
        } else {
          const responseText = result.request?.responseText;

          if (responseText?.length) {
            const response = JSON.parse(responseText);
            toast.error(response?.message);
          }

          dispatch.auth.setIsAuthLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getReconnectMfaCode() {
      try {
        dispatch.auth.setIsAuthLoading(true);
        const result = await http.get(RECONNECT_MFA_CODE);

        return result.data;
      } catch (error) {
        console.error(error);
      }
    },

    async verifyReconnectMfaCode({ code, email }) {
      try {
        dispatch.auth.setIsAuthLoading(true);
        const result = await http.post(VERIFY_RECONNECT_MFA_CODE, {
          code,
          email,
        });

        if (result.status === 201) {
          toast.success('MFA is successfully reconnected!');
        } else {
          const responseText = result.request?.responseText;

          if (responseText?.length) {
            const response = JSON.parse(responseText);
            toast.error(response?.message);
          }
        }

        dispatch.auth.setIsAuthLoading(false);
      } catch (error) {
        console.error(error);
      }
    },

    async exchangeCodeForTokens(data: { code: string; codeChallenge: string }) {
      try {
        const result = await http.post(EXCHANGE_CODE_URL, data);

        if (result.status === 201) {
          const { access_token, refresh_token, session_token, authFlow } = result.data;

          if (authFlow) {
            dispatch.auth.setAuthFlow({ authFlow });

            return;
          }

          if (access_token && refresh_token && session_token) {
            dispatch.auth.setTokens({
              accessToken: access_token,
              refreshToken: refresh_token,
              sessionToken: session_token,
            });
            setUserTokensToLocalStorage(access_token, refresh_token, session_token);
            dispatch.auth.setIsAuthenticated({ isAuthenticated: true });
          }

          removeCodeChallengeFromLocalStorage();
        } else {
          const responseText = result.request?.responseText;

          if (responseText?.length) {
            const response = JSON.parse(responseText);
            toast.error(response?.message);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async signOut() {
      try {
        const result = await http(`${SIGN_OUT_URL}?refreshToken=${getRefreshToken()}`);

        if (result.status === 200) {
          this.logOutUser();
        }
      } catch (error) {
        console.error(error);
      }
    },

    async refresh() {
      try {
        const refresh_token = getRefreshToken();

        if (!refresh_token) return;

        const result = await http.post(REFRESH_TOKEN_URL, {
          refreshToken: getRefreshToken(),
        });

        if (result.status === 201) {
          const { access_token, session_token } = result.data;
          dispatch.auth.setTokens({
            accessToken: access_token,
            refreshToken: refresh_token,
            sessionToken: session_token,
          });
          setUserTokensToLocalStorage(access_token, refresh_token, session_token);
        }
      } catch (error) {
        console.error(error);
      }
    },

    logOutUser() {
      dispatch.auth.resetAuthState();
      dispatch.user.resetUserState();
      dispatch.tiers.resetTiersState();
      dispatch.project.resetProjectsState();
      dispatch.threatModel.resetThreatModelsState();
      dispatch.representation.resetRepresentationState();

      removeIsLongTermSessionFromLocalStorage();
      cleanUserTokensFromLocalStorage();
      history.push(SIGN_IN_ROUTE);
    },

    async hubspot() {
      try {
        await http.post(`${HUBSPOT}`);
      } catch (error) {
        console.error(error);
      }
    },
  }),
});
