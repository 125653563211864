import type { FC } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import styles from './ElementRegisterTable.module.css';

type ElementRegisterSortColumnProps = {
  column: string;
  onSort?: (column: string) => void;
};

export const ElementRegisterSortColumn: FC<ElementRegisterSortColumnProps> = ({ column, onSort }) => {
  const handleSort = () => {
    if (onSort) {
      onSort(column);
    }
  };

  return (
    <div onClick={handleSort}>
      <CaretUpFill className={styles.tableSortIconUp} />
      <CaretDownFill className={styles.tableSortIconDown} />
    </div>
  );
};
