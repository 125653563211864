import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { XLg } from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Spinner } from 'reactstrap';
import * as Yup from 'yup';
import { SKELETON_COLORS } from '../../../global/constants';
import { useTypedSelector } from '../../../hooks/useTypeSelector';

import type { Dispatch } from '../../../store/store';
import UiModal from '../../common/Modal/UiModal';
import UiButton from '../../common/UIButton/UiButton';
import UiInput from '../../common/UiInput/UiInput';
import { ResourcesForm } from './ResourcesForm';

export const CreateMitigationForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState<string>('');
  const [deleteModal, setDeleteModla] = useState(false);
  const { mitigations } = useTypedSelector((state) => state.customCodex);
  const [currentMitigation, setCurrentMitigation] = useState<any>(null);
  const isCreating = params?.mitigationId === 'new';

  const getCurrentMitigation = async () => {
    const found = mitigations?.find((a) => a.id === params?.mitigationId);

    if (found) {
      setCurrentMitigation(null);
      const res = await dispatch.customCodex.getCustomMitigationById(found.id);
      setCurrentMitigation(res);
    }
  };

  useEffect(() => {
    if (!isCreating) {
      getCurrentMitigation();
    } else {
      setCurrentMitigation(null);
    }
  }, [params]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: currentMitigation || {
      title: '',
      example: '',
      question: '',
      resources: [],
      definition: '',
      explanation: '',
      consideration: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Required').min(1).max(64),
      definition: Yup.string().required('Required').min(1).max(500),
      example: Yup.string().min(1).max(500).nullable(),
      question: Yup.string().min(1).max(500).nullable(),
      explanation: Yup.string().min(1).max(500).nullable(),
      consideration: Yup.string().min(1).max(500).nullable(),
      resources: Yup.array(
        Yup.object()
          .shape({
            title: Yup.string(),
            url: Yup.string(),
          })
          .nullable()
          .optional(),
      )
        .nullable()
        .optional(),
    }),
    onSubmit: async (values) => {
      setLoading('save');
      let isOk;

      const preparedMitigation: any = {
        title: values.title,
        definition: values.definition,
      };

      if (values.example) preparedMitigation.example = values.example;

      if (values.question) preparedMitigation.question = values.question;

      if (values.explanation) preparedMitigation.explanation = values.explanation;

      if (values.consideration) preparedMitigation.consideration = values.consideration;

      if (values.resources?.length) preparedMitigation.resources = values.resources;

      if (isCreating) {
        isOk = await dispatch.customCodex.createMitigation(preparedMitigation);
      } else {
        isOk = await dispatch.customCodex.updateMitigation({
          ...preparedMitigation,
          id: currentMitigation.id,
        });
      }

      setLoading('');

      if (isOk) {
        navigate(-1);
        dispatch.drawn.setVisible(false);
        setCurrentMitigation(null);
        formik.resetForm();
      }
    },
  });

  const handleDelete = async () => {
    setLoading('delete');
    navigate('/admin/codex/mitigations');
    await dispatch.customCodex.deleteMitigation(currentMitigation.id);
    setLoading('');
    setDeleteModla(false);
    setCurrentMitigation(null);
    dispatch.drawn.setVisible(false);
  };

  const showForm = currentMitigation || isCreating;

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5>{isCreating ? 'Add' : 'Edit'} Mitigation</h5>
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate('/admin/codex/mitigations');
            dispatch.drawn.setVisible(false);
          }}
        >
          <XLg size={20} />
        </div>
      </div>

      {showForm && (
        <UiInput
          name="title"
          label="Name *"
          placeholder="Mitigation name"
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.errors?.title?.toString()}
          showError={!!formik.touched?.title && !!formik.errors?.title}
        />
      )}

      <div className="mt-3" />

      {showForm && (
        <UiInput
          name="definition"
          label="Definition *"
          type="textarea"
          textAreaRows={2}
          placeholder="Not specified"
          value={formik.values?.definition || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.errors.definition?.toString()}
          showError={!!formik.touched.definition && !!formik.errors.definition}
        />
      )}

      <div className="mt-3" />

      {showForm && (
        <UiInput
          name="question"
          label="How it works"
          type="textarea"
          textAreaRows={2}
          placeholder="Not specified"
          value={formik.values?.question || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.errors.question?.toString()}
          showError={!!formik.touched.question && !!formik.errors.question}
        />
      )}

      <div className="mt-3" />

      {showForm && (
        <UiInput
          name="consideration"
          label="Consideration"
          type="textarea"
          textAreaRows={2}
          placeholder="Not specified"
          value={formik.values?.consideration || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.errors.consideration?.toString()}
          showError={!!formik.touched.consideration && !!formik.errors.consideration}
        />
      )}

      <div className="mt-3" />

      {showForm && (
        <UiInput
          name="example"
          label="Example"
          type="textarea"
          textAreaRows={2}
          placeholder="Not specified"
          value={formik.values?.example || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.errors.example?.toString()}
          showError={!!formik.touched.example && !!formik.errors.example}
        />
      )}

      <div className="mt-3" />

      {showForm && (
        <UiInput
          name="explanation"
          label="Explanation"
          type="textarea"
          textAreaRows={2}
          placeholder="Not specified"
          value={formik.values?.explanation || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.errors.explanation?.toString()}
          showError={!!formik.touched.explanation && !!formik.errors.explanation}
        />
      )}

      <hr />
      {showForm && (
        <ResourcesForm
          resources={formik.values?.resources}
          setResources={(values) => {
            formik.setFieldValue('resources', values);
          }}
        />
      )}

      <hr />
      {showForm && (
        <div className="d-flex justify-content-end">
          <UiButton
            onClick={() => setDeleteModla(true)}
            className="mt-3 me-3"
            type="transparent"
            style={{ width: '100px' }}
            hidden={isCreating}
          >
            Delete
          </UiButton>
          <UiButton
            onClick={() => {
              navigate('/admin/codex/mitigations');
              dispatch.drawn.setVisible(false);
            }}
            className="mt-3 me-3"
            type="transparent"
            style={{ width: '100px' }}
            hidden={!isCreating}
          >
            Cancel
          </UiButton>
          <UiButton disabled={!!loading} onClick={formik.submitForm} className="mt-3" style={{ width: '100px' }}>
            {loading === 'save' ? <Spinner color="light" size="sm" /> : 'Save'}
          </UiButton>
        </div>
      )}

      {deleteModal && (
        <UiModal title="Delete" onClose={() => setDeleteModla(false)}>
          {`Are you sure you want to delete the "${currentMitigation.title}" mitigation?`}
          <div className="d-flex justify-content-end">
            <UiButton onClick={handleDelete} className="mt-3" type="danger" style={{ width: '100px' }}>
              {loading === 'delete' ? <Spinner color="danger" size="sm" /> : 'Delete'}
            </UiButton>
          </div>
        </UiModal>
      )}

      {!currentMitigation && !isCreating && (
        <Skeleton
          style={{ lineHeight: '25px', margin: '10px 0' }}
          width="100%"
          height={40}
          count={7}
          borderRadius={10}
          baseColor={SKELETON_COLORS.BASE}
          highlightColor={SKELETON_COLORS.HIGHLIGHT}
        />
      )}
    </>
  );
};
