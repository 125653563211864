import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';
import TeamModal from './TeamModal';

const TeamList: FC = () => {
  const [toggle, setToggle] = useState(false);

  const dispatch = useDispatch<Dispatch>();
  const { teams } = useTypedSelector((state) => state.team);

  useEffect(() => {
    dispatch.team.getTeams();
  }, []);

  const handleTeamModalToggle = () => {
    setToggle(!toggle);
  };

  const handleCreate = (title: string) => {
    dispatch.team.createTeam(title);
    setToggle(false);
  };

  return (
    <div className="container-fluid py-3">
      <h3>Teams</h3>
      <ul className="list-group">
        {teams.map((team) => (
          <Link
            to={`/teams/${team.id}`}
            key={team.id}
            className="list-group-item d-flex justify-content-between align-items-center cursor-pointer"
          >
            {team.title}
          </Link>
        ))}
        <Button onClick={handleTeamModalToggle} color="success" className="mt-2">
          Create Team
        </Button>
      </ul>
      <TeamModal onToggle={handleTeamModalToggle} onCreate={handleCreate} toggle={toggle} />
    </div>
  );
};

export default TeamList;
