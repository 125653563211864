import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { ArrowLeftShort } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import * as Yup from 'yup';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { USERS_ROUTE } from '../../../store/constants/route-constants';
import type { Dispatch } from '../../../store/store';
import UiButton from '../../common/UIButton/UiButton';
import UiInput from '../../common/UiInput/UiInput';
import ThreatDropdown from '../../Diagram/ThreatDropdown';
import styles from './UsersAdd.module.css';

const UsersAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState(false);
  const { customerLimits } = useTypedSelector((state) => state.tiers);

  const handleBackClick = () => {
    navigate(-1);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: 'user',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email format').required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const [title] = (values.email as string).split('@');
      const lastName = `${title[0].toUpperCase()}`;
      const firstName = `${lastName}${title.slice(1)}`;
      const status = await dispatch.user.inviteUser({
        firstName,
        lastName,
        email: values.email,
        role: values.role,
      });

      if (status === 201) {
        await dispatch.user.getUsers({});
        await dispatch.tiers.getCustomerLimits();
        navigate(-1);
      }

      setLoading(false);
    },
  });

  const handleCreate = async () => {
    if (loading) return;

    await formik.submitForm();
  };

  useEffect(() => {
    if (!customerLimits?.isBetaUser) {
      if (customerLimits && customerLimits.seats.available === 0) {
        toast.error('You have reached the maximum number of users. Please add more seats to continue.');
        navigate(USERS_ROUTE);
      }
    }
  }, []);

  if (!customerLimits?.isBetaUser) {
    if (customerLimits && customerLimits.seats.available === 0) {
      return null;
    }
  }

  return (
    <div className="content">
      <div className={styles.header}>
        <div onClick={handleBackClick} className={styles.back}>
          <div style={{ lineHeight: '37px' }}>
            <ArrowLeftShort size={24} />
          </div>
          Back
        </div>
        <div className={styles.headerRight}>
          <UiButton onClick={handleBackClick} type="transparent">
            Cancel
          </UiButton>
          <UiButton onClick={handleCreate}>{loading ? <Spinner color="light" size="sm" /> : 'Create'}</UiButton>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.bodyContent}>
          <div className={styles.bodyHeader}>Add user</div>
          <div>
            <UiInput
              name="email"
              label="Email"
              type="email"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorText={formik.errors.email}
              showError={formik.touched.email && formik.errors.email}
            />
            <div className="mt-3" style={{ fontSize: '12px', fontWeight: 600 }}>
              Role
            </div>
            <ThreatDropdown
              value={formik.values.role}
              options={['admin', 'user']}
              onChange={(newRole: string) => {
                formik.setFieldValue('role', newRole);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersAdd;
