import classNames from 'classnames';
import type { SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BoxArrowDown, FiletypeJson } from 'react-bootstrap-icons';
import { Spinner } from 'reactstrap';
import PDFIcon from '../../../assets/icons/PDFIcon.svg';
import { isFreePlan } from '../../../helpers/isFreePlan';
import { isReadMode } from '../../../helpers/isReadMode';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import { DiagramPercentage } from './DiagramPercentage';
import { DiagramTitle } from './DiagramTitle';
import styles from './index.module.css';

const SHOW_OTM_IMPORT_EXPORT = process.env?.REACT_APP_SHOW_OTM_IMPORT_EXPORT === 'true';

const ExportPdf = () => {
  const dispatch = useDispatch<Dispatch>();
  const [otmIsLoading, setOtmIsLoading] = useState(false);
  const [exportDropdownVisible, setExportDropdownVisible] = useState(false);
  const { currentThreatModel, liveUpdateData, interactionMode } = useTypedSelector((state) => state.threatModel);
  const customerLimits = useTypedSelector((state) => state.tiers.customerLimits);
  const [pdfIsLoading, setPdfIsLoading] = useState(false);

  const disallowOTM = otmIsLoading || isFreePlan(customerLimits);
  const disallowPDF = pdfIsLoading || isFreePlan(customerLimits);

  useEffect(() => {
    if (liveUpdateData && currentThreatModel && liveUpdateData.includes(currentThreatModel.id)) {
      dispatch.threatModel.getThreatModel(currentThreatModel.id);
      dispatch.threatModel.setLiveUpdateData(null);
    }
  }, [liveUpdateData]);

  const handleExport = async (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (disallowPDF) return;

    setPdfIsLoading(true);

    const result = await dispatch.threatModel.getPDFReportData('');

    if (result?.length) {
      const a = document.createElement('a');
      a.href = result;
      a.download = `${currentThreatModel?.title}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }

    setPdfIsLoading(false);
  };

  const handleOTMExport = async () => {
    if (disallowOTM) return;

    setOtmIsLoading(true);
    const data = await dispatch.threatModel.getOTMReportData({});

    if (data) {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
      const link = document.createElement('a');
      link.href = jsonString;
      link.download = `${currentThreatModel?.title}_otm_report.json`;

      link.click();
    }

    setOtmIsLoading(false);
  };

  return (
    <div className="d-flex gap-2">
      <DiagramPercentage />
      <DiagramTitle isReadMode={isReadMode(interactionMode)} />
      <div
        style={{
          flexDirection: 'row',
        }}
        className={classNames('block rounded-1 shadow bg-white user-select-none', styles.toolItem)}
      >
        <div
          className={classNames(styles.hasTooltip)}
          data-tooltip="Export"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setExportDropdownVisible(!exportDropdownVisible);
          }}
          id="exportDropdown"
        >
          <BoxArrowDown size={20} />
        </div>
      </div>
      {exportDropdownVisible && (
        <div
          className={classNames(styles.deleteRepresentationModal, 'shadow rounded-1')}
          style={{ top: 'calc(100% + 5px)', width: '45px' }}
        >
          <div className="d-flex flex-column justify-content-center">
            <div className={classNames(styles.toolItem, 'p-0')}>
              <div
                className={classNames(styles.hasTooltip, {
                  [styles.hasTooltipDisable]: disallowPDF,
                })}
                data-tooltip="Export to PDF"
                onClick={handleExport}
                id="exportDropdown"
              >
                {pdfIsLoading ? <Spinner size="sm" className={styles.spinner} /> : <img src={PDFIcon} alt="PDFIcon" />}
              </div>
            </div>

            {SHOW_OTM_IMPORT_EXPORT && (
              <div className={classNames(styles.toolItem, 'p-0')}>
                <div
                  className={classNames(styles.hasTooltip, {
                    [styles.hasTooltipDisable]: disallowOTM,
                  })}
                  data-tooltip="Export OTM"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleOTMExport();
                  }}
                  id="exportDropdown"
                >
                  {otmIsLoading ? (
                    <Spinner size="sm" className={styles.spinner} />
                  ) : (
                    <FiletypeJson size={20} color="#7a94b0" />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExportPdf;
