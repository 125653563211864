import logo from '../../assets/images/devici-logo-light.svg';
import maintenanceModePageImage from '../../assets/images/maintenance-mode-page.jpg';
import styles from './index.module.css';

const MaintenanceModePage = () => {
  return (
    <div className={styles.maintenanceModePage}>
      <div>
        <img src={logo} alt="logo" />
      </div>
      <img src={maintenanceModePageImage} alt="maintenance mode" />
      <h1>Under construction. Please try later...</h1>
    </div>
  );
};

export default MaintenanceModePage;
