import classNames from 'classnames';
import type { FC } from 'react';
import { Trash3 } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../../../store/store';
import styles from './EdgeContextMenu.module.css';

type EdgeContextMenuProps = {
  setVisible: (value: boolean) => void;
  edge: any;
};

const EdgeContextMenu: FC<EdgeContextMenuProps> = ({ setVisible, edge }) => {
  const dispatch = useDispatch<Dispatch>();
  const { edgesSharedState } = useTypedSelector((state) => state.diagram);
  const emitComponentVisualRemove = useTypedSelector((state) => state.drawn.emitComponentVisualRemove);

  const handleRename = () => {
    dispatch.diagram.setEditedComponentId(edge.id);
    setVisible(false);
  };

  const handleDelete = async () => {
    edgesSharedState && edgesSharedState.delete(edge.id);
    emitComponentVisualRemove && emitComponentVisualRemove(edge.id);
  };

  return (
    <ul
      className={classNames(
        'fs-12 position-absolute start-100 top-100 bg-white p-2 rounded-2 shadow list-unstyled user-select-none',
        styles.edgeContextMenu,
      )}
    >
      <li onClick={handleRename} className="">
        Rename
      </li>
      <hr className="m-0" />
      <li onClick={handleDelete} className="text-danger d-flex align-items-center gap-2">
        <Trash3 size={16} type="danger" /> Delete
      </li>
    </ul>
  );
};

export default EdgeContextMenu;
