import classNames from 'classnames';
import type { FC, SyntheticEvent } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Spinner } from 'reactstrap';
import type { Project } from '../../global/types';
import { COLLECTION_COLORS } from '../../helpers/colors';
import { useSocket } from '../../hooks/useSocket';
import type { Dispatch } from '../../store/store';
import styles from './ProjectColorPicker.module.css';

type ProjectColorPickerProps = {
  project: Project;
  closeColorPicker: () => void;
  isListItem: boolean;
  modalOffsetHeight: number;
};

const ProjectColorPicker: FC<ProjectColorPickerProps> = ({
  project,
  isListItem,
  modalOffsetHeight,
  closeColorPicker,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const [loadingColorItem, setLoading] = useState('');
  const { emitGlobalUpdate } = useSocket();

  const handleEmitAfterProjectUpdate = () => {
    if (project?.id && typeof project.id === 'string') {
      emitGlobalUpdate({
        projects: [project.id],
      });
    }
  };

  const handleSelectColor = async (event: SyntheticEvent, color: string) => {
    if (project.color === color) return;

    setLoading(color);
    event.stopPropagation();
    await dispatch.project.updateProject({
      id: project.id,
      title: project.title,
      description: project.description,
      color,
    });
    setLoading('');
    handleEmitAfterProjectUpdate();
    dispatch.project.setProjectColor({
      id: project.id,
      color,
    });
  };

  const getModalPosition = () => {
    const position = {
      top: '85px',
      left: '190px',
    };

    if (isListItem) {
      position.top = '65px';
      position.left = '250px';

      if (modalOffsetHeight) position.top = `-${modalOffsetHeight - 20}px`;

      position.left = '215px';
    }

    return position;
  };

  return (
    <div
      data-testid="collection-color-picker"
      style={getModalPosition()}
      onClick={(e) => e.stopPropagation()}
      className={classNames(
        styles.modal,
        'position-absolute d-flex align-items-center justify-content-between flex-wrap',
      )}
    >
      {COLLECTION_COLORS.map((color) => (
        <div
          key={color}
          className={classNames(styles.colorItem, {
            [styles.colorItemActive]: color === project.color,
          })}
          style={{ background: color }}
          onClick={(e) => handleSelectColor(e, color)}
        >
          {loadingColorItem === color && <Spinner color="light" style={{ height: '14px', width: '14px' }} />}
        </div>
      ))}
    </div>
  );
};

export default ProjectColorPicker;
