export const HOME_ROUTE = '/';

export const SIGN_IN_ROUTE = '/sign-in';

export const SIGN_UP_ROUTE = '/sign-up';

export const CONFIRMATION_ROUTE = '/confirmation';

export const RESET_PASSWORD_ROUTE = '/reset-password';

export const FORGOT_PASSWORD_ROUTE = '/forgot-password';

export const BILLING_ROUTE = '/billing';

export const PROFILE_ROUTE = '/profile';

export const PROFILE_ROUTE_UPDATE_USERNAME = '/profile/update-username';

export const PROFILE_ROUTE_UPDATE_USER_AVATAR = '/profile/update-auth-avatar';

export const CONTACT_US_ROUTE = '/contact-us';

export const PLANS_ROUTE = '/plans';

export const PROJECTS_ROUTE = '/collections';

export const PROJECTS_ARCHIVE_ROUTE = '/archives';

export const PROJECTS_CREATE_ROUTE = '/collections/create';

export const PROJECTS_UPDATE_ROUTE = '/collections/update/:projectId';

export const PROJECT_DETAIL_ROUTE = '/collections/:id/*';

export const PROJECT_RUNNINGS_LIST_ROUTE = '/collections/:id/runnings';

export const PROJECT_DIAGRAMS_ROUTE = '/collections/:id/d/:diagramId/*';

export const PROJECT_THREATS_ROUTE = '/collections/:id/threats/:threatId';

export const PROJECT_MITIGATIONS_ROUTE = '/collections/:id/mitigations/:mitigationId';

export const PROJECT_RETROSPECTIVES_ROUTE = '/collections/:id/retrospectives/:retrospectiveId';

export const TEAMS_ROUTE = '/teams';

export const TEAM_DETAIL_ROUTE = '/teams/:id';

export const USERS_ROUTE = '/users';

export const USER_ADD_ROUTE = '/users/add';

export const SETTINGS_ROUTE = '/settings';

export const DEBUG_PAGE_ROUTE = '/debug-page';

export const UI_KIT_PAGE_ROUTE = '/ui-kit-page';

// Diagram drawer routes
export const DRAWER_THREAT_MODEL_TRANSFER = 'transfer';

export const DRAWER_COMPONENTS_ROUTE = 'c/:componentId';

export const DRAWER_ATTRIBUTES_ROUTE = 'c/:componentId/a';

export const DRAWER_ATTRIBUTE_DETAIL_ROUTE = 'c/:componentId/a/:attributeId';

export const DRAWER_MITIGATIONS_ROUTE = 'c/:componentId/ut/m';

export const DRAWER_THREATS_ROUTE = 'c/:componentId/t';

export const DRAWER_THREATS_DETAIL_ROUTE = 'c/:componentId/t/:threatsId';

export const DRAWER_THREATS_DETAIL_MITIGATION_ROUTE = 'c/:componentId/t/:threatsId/m';

export const DRAWER_THREATS_DETAIL_CHECKLIST_ROUTE = 'c/:componentId/t/:threatsId/c';

export const DRAWER_USER_THREATS_ROUTE = 'c/:componentId/ut';

export const DRAWER_ADD_MITIGATION_ROUTE = 'c/:componentId/t/:threatsId/m/:mitigationId';

export const CLI_AUTH_ROUTE = '/cli-auth';

export const CLI_THREAT_MODEL = '/cli_threat_models/:id';

export const MY_THREAT_MODELS_ROUTE = '/my-models/*';

export const THREATS_REGISTER_ROUTE = '/threats-register';

export const CODE_GENIUS_ROUTE = '/code-genius';

export const ADMIN_ROUTE = '/admin';

export const ADMIN_USERS_ROUTE = '/admin/users';

export const ADMIN_SECURITY_ROUTE = '/admin/security';

export const ADMIN_CODEX_ROUTE = '/admin/codex';

export const ADMIN_RESOURCES_ROUTE = '/admin/resources';
