import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';
import type { NodeProps } from 'reactflow';
import { NodeResizer, NodeToolbar, Position } from 'reactflow';
import { getColorDependsOnBackground } from '../../../helpers/colors';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import styles from '../diagram.module.css';
import DiagramPopupTools from '../DiagramPopupTools';
import DiagramNodeAttribute from '../NodeAttributePopup';
import NodeHandlers from './NodeHandlers';
import NodeInput from './NodeInput/NodeInput';

// eslint-disable-next-line react/display-name
export default memo(({ data, selected, id, type }: NodeProps) => {
  const {
    selectedNode,
    nodesSharedState,
    collaborators,
    attributes,
    mitigatingAttributes,
    editedComponentId = id,
  } = useTypedSelector((state) => state.diagram);
  const [attributesPopup, setAttributesPopup] = useState<any>({
    visible: false,
    componentId: id,
  });
  const [isInputActive, setIsInputActive] = useState(false);
  const [showHandlers, setShowHandlers] = useState(false);
  const node = nodesSharedState?.get(id);
  const hasCollaborators =
    collaborators?.length &&
    !!collaborators?.filter((id) => {
      return data?.selectedBy?.includes(id);
    })?.length;

  useEffect(() => {
    if (!selected) setIsInputActive(false);

    return () => {
      setAttributesPopup({ visible: false });
    };
  }, [selected]);

  useEffect(() => {
    if (editedComponentId?.length && id === editedComponentId) setIsInputActive(true);
  }, [editedComponentId]);

  useEffect(() => {
    if (attributes?.length || (mitigatingAttributes && Object.keys(mitigatingAttributes)?.length)) {
      setAttributesPopup({
        ...attributesPopup,
        visible: false,
      });
    }
  }, [attributes?.length || mitigatingAttributes]);

  const handleMouseEnter = () => {
    setShowHandlers(true);
  };

  const handleMouseLeave = () => {
    setShowHandlers(false);
  };

  const handleLabelDoubleClick = () => {
    setIsInputActive(true);
  };

  return (
    <>
      <NodeResizer
        nodeId={data.id}
        minHeight={50}
        minWidth={150}
        isVisible={selected}
        handleStyle={{
          width: '10px',
          height: '10px',
          borderRadius: '2px',
        }}
      />
      <NodeToolbar isVisible={selected && !attributesPopup.visible} position={Position.Top}>
        <DiagramPopupTools
          setAttributesPopup={() => {
            setAttributesPopup({
              componentId: id,
              visible: true,
            });
          }}
          node={{
            id,
            type,
            color: data.color,
            borderColor: data?.borderColor,
            borderWidth: data?.borderWidth,
            fontSize: data?.fontSize,
            autoFontSize: data?.autoFontSize,
            textStyle: data?.textStyle,
            fontColor: data?.fontColor,
          }}
        />
      </NodeToolbar>
      <NodeToolbar isVisible={attributesPopup.visible} position={Position.Bottom}>
        <DiagramNodeAttribute data={attributesPopup} setData={setAttributesPopup} />
      </NodeToolbar>
      <div
        className={classNames(styles.diagramNode, {
          [styles.hasCollaborators]: hasCollaborators,
        })}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          cursor: selectedNode?.length ? 'crosshair' : 'unset',
          minHeight: '50px',
          minWidth: '150px',
          height: node?.height || 50,
          width: node?.width || 150,
          backgroundColor: '#555',
          background: data?.color || '#ffffff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `solid ${data?.borderWidth || 3}px ${data?.borderColor || 'black'}`,
          overflow: 'hidden',
        }}
        data-isnode={1}
        onDoubleClick={handleLabelDoubleClick}
      >
        <div
          data-isnode={1}
          style={{
            textAlign: 'center',
            width: '90%',
            margin: 'auto',
            fontSize: '12px',
            fontWeight: '400',
            containerType: 'inline-size',
          }}
        >
          {isInputActive && (
            <NodeInput
              id={id}
              value={data.label}
              onBlur={() => {
                setIsInputActive(false);
              }}
              inputColor={data?.fontColor || getColorDependsOnBackground(data?.color || '#FFFFFF')}
              fontSize={data?.fontSize || 12}
              autoFontSize={data?.autoFontSize}
              fontBold={data?.textStyle?.isBold}
            />
          )}
          {!isInputActive && (
            <span
              data-isnode={1}
              style={{
                color: data?.fontColor || getColorDependsOnBackground(data?.color || '#ffffff'),
                fontSize: data?.autoFontSize ? '16cqw' : `${data?.fontSize || 12}px`,
                fontWeight: `${data?.textStyle?.isBold ? '600' : '400'}`,
                textDecoration: `${
                  data?.textStyle?.isUnderline ? 'underline' : data?.textStyle?.isLineThrough ? 'line-through' : 'none'
                }`,
                fontStyle: `${data?.isItalic ? 'italic' : 'normal'}`,
              }}
            >
              {data.label}
            </span>
          )}
        </div>
        <NodeHandlers isVisible={showHandlers} />
      </div>
    </>
  );
});
