export const passwordLengthLimits = {
  min: 8,
  max: 99,
};

export const enum AUTH_FLOW {
  SAML = 'SAML',
  BASIC = 'BASIC',
  REJECTED = 'REJECTED',
  MFA_SETUP = 'MFA_SETUP',
  MFA_CHECK = 'MFA_CHECK',
  UNCONFIRMED = 'UNCONFIRMED',
  FIRST_SIGN_IN = 'FIRST_SIGN_IN',
  MFA_SETUP_COMPLETED = 'MFA_SETUP_COMPLETED',
  WITH_INVITATION_CODE = 'WITH_INVITATION_CODE',
  CONFIRM_FORGOT_PASSWORD = 'CONFIRM_FORGOT_PASSWORD',
  CONFIRM_FORGOT_COMPLETED = 'CONFIRM_FORGOT_COMPLETED',
}
