import classNames from 'classnames';
import type { FC } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import { CalendarEvent, ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import Calendar from 'react-calendar';
import { createPortal } from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { formatDate } from '../../../helpers/calendar';
import './Calendar.css';
import CalendarLabel from './CalendarLabel';
import styles from './DateInput.module.css';

type DateInputProp = {
  value?: Date | string;
  onChange?: (date: any) => void;
  placeholder?: string;
  noHover?: boolean;
};

const KanbanEl = document.getElementById('root') || document.body;

const DateInput: FC<DateInputProp> = ({ value, onChange, placeholder, noHover = false }) => {
  const [modal, setModal] = useState({
    visible: false,
    pageX: 0,
    pageY: 0,
  });
  const calendarRef = useRef<any>(null);
  const [isRight, setIsRight] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  useLayoutEffect(() => {
    if (calendarRef?.current) {
      const popupRect = calendarRef.current.getBoundingClientRect();

      if (popupRect.right > window.innerWidth) setIsRight(true);

      if (popupRect.top + 290 > window.innerHeight) setIsBottom(true);
    }

    return () => {
      setIsRight(false);
      setIsBottom(false);
    };
  }, [modal.visible]);

  const onChangeDate = (newValue: any) => {
    onChange && onChange(newValue);
    setModal({ visible: false, pageX: 0, pageY: 0 });
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
  };

  const handleOpen = (event: any, value: boolean) => {
    event.preventDefault();

    if (!noHover) {
      if (value) {
        const rects = event.target.getBoundingClientRect();
        setModal({ visible: true, pageX: rects.x, pageY: rects.y + 30 });
      } else setModal({ visible: false, pageX: 0, pageY: 0 });
    }
  };

  return (
    <div
      className={classNames(styles.dateInput, {
        [styles.isOpen]: modal.visible,
      })}
      style={{
        cursor: noHover ? 'default' : 'pointer',
        pointerEvents: noHover ? 'none' : 'unset',
      }}
      onClick={handleClick}
    >
      <div className={styles.icon} onClick={(e) => handleOpen(e, true)}>
        <CalendarEvent size={16} />
      </div>
      {placeholder && !value ? (
        <span onClick={(e) => handleOpen(e, true)} className="fs-12 text-secondary pe-1">
          {placeholder}
        </span>
      ) : null}
      {value && (
        <span onClick={(e) => handleOpen(e, true)} className={styles.value}>
          {formatDate(value)}
        </span>
      )}
      {modal.visible &&
        createPortal(
          <OutsideClickHandler onOutsideClick={(e) => handleOpen(e, false)}>
            <div
              ref={calendarRef}
              style={{
                position: 'absolute',
                zIndex: 100,
                left: isRight ? 'unset' : modal.pageX,
                top: isBottom ? 'unset' : modal.pageY,
                bottom: isBottom ? 10 : 'unset',
                right: isRight ? 10 : 'unset',
              }}
              className={classNames('bg-white', {
                [styles.calendarOverlayRight]: isRight,
                [styles.calendarOverlayBottom]: isBottom,
              })}
            >
              <Calendar
                value={value}
                onChange={onChangeDate}
                next2Label={null}
                nextLabel={<ChevronRight size={16} />}
                prevLabel={<ChevronLeft size={16} />}
                prev2Label={null}
                view="month"
                navigationLabel={(value) => <CalendarLabel {...value} onChange={onChange} />}
              />
            </div>
          </OutsideClickHandler>,
          KanbanEl,
        )}
    </div>
  );
};

export default DateInput;
