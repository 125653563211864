import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.min.css';

import type { FC } from 'react';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import LogRocket from 'logrocket';
import { useDispatch } from 'react-redux';
import { ScrollToTop } from './components/common/ScrollToTop/ScrollToTop';
import Toast from './components/common/Toast/Toast';
import Sidebar from './components/Sidebar/SidebarContainer';
import ProtectedRoute from './helpers/ProtectedRoute';
import { useHubspot } from './hooks/useHubspot';
import type { IGlobalLiveUpdateData } from './hooks/useSocket';
import { UpdateTypes, useSocket } from './hooks/useSocket';
import { useTypedSelector } from './hooks/useTypeSelector';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import { CLIAuthPage, NotFoundPage, SignInPage, SignUpPage } from './pages/index';
import MaintenanceModePage from './pages/MaintenanceModePage';
import routes from './routes';
import { CLI_AUTH_ROUTE, FORGOT_PASSWORD_ROUTE, SIGN_IN_ROUTE, SIGN_UP_ROUTE } from './store/constants/route-constants';
import type { Dispatch } from './store/store';

const isMaintenanceMode = JSON.parse(process.env.REACT_APP_IS_MAINTENANCE_MODE || '');

const App: FC = () => {
  const { current } = useTypedSelector((state) => state.user);
  const { socket } = useSocket();
  const dispatch = useDispatch<Dispatch>();

  useHubspot(20 * 60 * 1000);

  socket?.on(UpdateTypes.GLOBAL, (data: IGlobalLiveUpdateData) => {
    if (data.projects && data.projects.length) {
      dispatch.project.setLiveUpdateData(data.projects);
    }

    if (data.threatModels && data.threatModels.length) {
      dispatch.threatModel.setLiveUpdateData(data.threatModels);
    }

    if (data.archives && data.archives.length) {
      dispatch.project.setArchiveLiveUpdateData(data.archives);
    }
  });

  const handleWheel = (event: any) => {
    if (event.target.classList.contains('noScale')) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  if (current?.id) {
    LogRocket.identify(current.id, {
      name: `${current?.first_name} ${current?.last_name}`,
      email: current?.email,
      // Add your own custom user variables here, ie:
      // subscriptionType: 'pro'
    });
  }

  useEffect(() => {
    document.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return isMaintenanceMode ? (
    <MaintenanceModePage />
  ) : (
    <div className="bodyMain">
      <ScrollToTop />
      <Sidebar />
      <div className="contentMain" id="content-element">
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.id}
              path={route.path}
              element={<ProtectedRoute isPublic={!!route?.public}>{route.element}</ProtectedRoute>}
            />
          ))}
          <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPasswordPage />} />
          <Route path={SIGN_UP_ROUTE} element={<SignUpPage />} />
          <Route path={SIGN_IN_ROUTE} element={<SignInPage />} />
          <Route path={CLI_AUTH_ROUTE} element={<CLIAuthPage />} />
          <Route path={'/*'} element={<NotFoundPage />} />
        </Routes>
      </div>
      <Toast autoClose={8000} position="bottom-left" />
    </div>
  );
};

export default App;
