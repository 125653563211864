import classNames from 'classnames';
import { useFormik } from 'formik';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { ArrowLeft, Check2All, Clipboard2X, ClockHistory, DashLg, Plus, XLg } from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import * as Yup from 'yup';
import { SKELETON_COLORS } from '../../../global/constants';
import type { EmitUpdate } from '../../../hooks/useSocket';
import { UpdateTypes } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { MITIGATION_STATUS, MITIGATION_STATUS_TEXT } from '../../../store/constants/drawn-constants';
import { PROJECTS_ROUTE, THREATS_REGISTER_ROUTE } from '../../../store/constants/route-constants';
import type { IMitigation } from '../../../store/models/drawn';
import { initialMitigation } from '../../../store/models/drawn';
import type { Dispatch } from '../../../store/store';
import UiButton from '../../common/UIButton/UiButton';
import UiInput from '../../common/UiInput/UiInput';
import styles from './index.module.css';

type AddMitigationFormProps = {
  emitUpdate?: EmitUpdate;
};

const AddMitigationForm: FC<AddMitigationFormProps> = ({ emitUpdate }) => {
  const params = useParams();
  const { mitigationId, threatsId } = useParams();
  const isNew = mitigationId === 'new';
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { pathname } = useLocation();
  const nodeTitle = useTypedSelector(({ drawn }) => drawn?.component?.title) || '';
  const { newMitigation, threat } = useTypedSelector((state) => state.drawn);
  const [currentMitigation, setCurrentMitigation] = useState<any>({});
  const [showFullInfo, setShowFullInfo] = useState(isNew);
  const [isSaving, setIsSaving] = useState(false);
  const { forceUpdateMitigationId, forceUpdateThreatId } = useTypedSelector((state) => state.drawn);
  const currentUserId = useTypedSelector((state) => state.user?.current?.id);
  const [isOwner, setIsOwner] = useState(false);

  const handleEmitAfterUpdate = () => {
    if (mitigationId && emitUpdate) {
      emitUpdate(UpdateTypes.MITIGATION, mitigationId, true);

      if (threatsId) {
        emitUpdate(UpdateTypes.THREAT, threatsId, true);
      }
    }
  };

  const getMitigation = async () => {
    if (!mitigationId || isNew) return;

    const result = await dispatch.mitigations.getMitigationById(mitigationId);

    if (result?.created_by?.id) setIsOwner(result.created_by.id === currentUserId);

    setCurrentMitigation(result);
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (mitigationId && forceUpdateMitigationId && mitigationId === forceUpdateMitigationId) {
      const currentMitigation = threat.mitigations?.find((i) => i.id === forceUpdateMitigationId);

      if (currentMitigation?.id) getMitigation();
      else {
        toast.error('Mitigation deleted!');
        handleBack();
        dispatch.drawn.setForceUpdateMitigationId(null);
      }

      dispatch.drawn.setForceUpdateMitigationId(null);
    }
  }, [forceUpdateMitigationId]);

  useEffect(() => {
    if (threatsId && forceUpdateThreatId && threatsId === forceUpdateThreatId) {
      dispatch.drawn.getThreat(threatsId);
      dispatch.drawn.setForceUpdateThreatId(null);
    }
  }, [forceUpdateThreatId]);

  useEffect(() => {
    if (!currentMitigation?.id && !isNew) {
      getMitigation();
    }

    return () => {
      dispatch.drawn.clearMitigation();
      dispatch.drawn.clearNewMitigation();
      setCurrentMitigation({});
    };
  }, []);

  useEffect(() => {
    if (mitigationId === 'new') {
      setShowFullInfo(true);
      setCurrentMitigation({
        ...initialMitigation,
        title: newMitigation?.title,
      });
    } else if (currentMitigation?.id) getMitigation();
  }, [mitigationId]);

  const handleCreateMitigation = async (data: any) => {
    const result = await dispatch.drawn.addMitigation({
      threatsId,
      mitigation: data,
    });

    if (threatsId && pathname.includes(`${THREATS_REGISTER_ROUTE}/`)) dispatch.drawn.getThreat(threatsId);

    return result;
  };

  const handleUpdateMitigation = async (data: any) => {
    const updatedData = await dispatch.drawn.updateMitigation({
      id: mitigationId,
      threatsId,
      mitigation: data,
    });
    handleEmitAfterUpdate();

    return updatedData;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: currentMitigation?.title || newMitigation?.title || '',
      definition: currentMitigation?.definition || '',
      howItWorks: currentMitigation?.explanation || '',
      consideration: currentMitigation?.consideration || '',
      example: currentMitigation?.example || '',
      status: currentMitigation?.status || '',
      is_custom: currentMitigation?.is_custom || isNew,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Required'),
      definition: Yup.string().required('Required'),
      howItWorks: Yup.string(),
      consideration: Yup.string(),
      example: Yup.string(),
    }),
    onSubmit: async (values) => {
      setIsSaving(true);
      const newMitigationObject: IMitigation = {
        title: values.title,
        status: values.status || null,
        consideration: values.consideration,
        definition: values.definition,
        example: values.example,
        explanation: values.howItWorks,
      };
      const result = isNew
        ? await handleCreateMitigation(newMitigationObject)
        : await handleUpdateMitigation(newMitigationObject);

      setIsSaving(false);

      if (result) {
        handleEmitAfterUpdate();

        if (pathname.includes(`${THREATS_REGISTER_ROUTE}/element`)) {
          navigate(
            `${PROJECTS_ROUTE}/${params.id}/d/${params.diagramId}${THREATS_REGISTER_ROUTE}/element/${threat.id}`,
          );
        } else if (pathname.includes(THREATS_REGISTER_ROUTE)) {
          navigate(`${PROJECTS_ROUTE}/${params.id}/d/${params.diagramId}${THREATS_REGISTER_ROUTE}/${threat.id}`);
        } else {
          handleBack();
        }

        await dispatch.threatModel.updateCurrentThreatModel();

        handleEmitAfterUpdate();
      }
    },
  });
  const inputDisabled = !formik.values.is_custom || (!isOwner && !isNew);
  const showButtons = (showFullInfo && currentMitigation?.is_custom && isOwner) || isNew;

  const handleChangeStatus = async (status: string | null) => {
    await handleUpdateMitigation({ status });
    await dispatch.threatModel.updateCurrentThreatModel();
    await formik.setFieldValue('status', status);

    if (pathname.includes(THREATS_REGISTER_ROUTE)) {
      const updatedMitigations =
        threat?.mitigations
          ?.map((m: any) => {
            if (m.id === mitigationId) {
              m.status = status;
            }

            return m;
          })
          .sort((a, b) => a.title.localeCompare(b.title)) || [];
      dispatch.threats.updateThreatFromThreatRegisterList({
        id: threatsId,
        mitigations: [...updatedMitigations],
      });
    }
  };

  const renderTitle = () => {
    if (pathname.includes(`${THREATS_REGISTER_ROUTE}/`)) return 'Back';

    return `Back to ${nodeTitle || ''}`;
  };

  return (
    <div style={{ margin: '10px' }}>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="cursor-pointer" onClick={handleBack}>
          <ArrowLeft fontSize={16} className="me-2 text-secondary" />
          <span className="fs-12 fw-bold text-secondary">{renderTitle()}</span>
        </div>
      </div>

      <div className={styles.addMitigationWrap}>
        {!isNew &&
          (formik.values.title ? (
            <div
              className={classNames(styles.mitigationTitle, {
                [styles.mitigationStatusDoneActive]: formik.values.status === MITIGATION_STATUS.DONE,
                [styles.mitigationStatusWillActive]: formik.values.status === MITIGATION_STATUS.WILL,
                [styles.mitigationStatusNeverActive]: formik.values.status === MITIGATION_STATUS.NEVER,
                [styles.mitigationStatusDefaultActive]: !formik.values.status?.length,
              })}
            >
              {formik.values.status === MITIGATION_STATUS.DONE && <Check2All fontSize={14} />}
              {formik.values.status === MITIGATION_STATUS.WILL && <ClockHistory fontSize={14} />}
              {formik.values.status === MITIGATION_STATUS.NEVER && <Clipboard2X fontSize={14} />} {formik.values.title}
            </div>
          ) : (
            <Skeleton
              style={{ lineHeight: '25px' }}
              width={125}
              height={28}
              baseColor={SKELETON_COLORS.BASE}
              highlightColor={SKELETON_COLORS.HIGHLIGHT}
            />
          ))}
        {!isNew && (
          <div className={classNames(styles.mitigationStatus, 'd-flex align-items-center gap-2')}>
            <h4>Mitigation Status</h4>
            <div className="d-flex align-items-center justify-content-between gap-3">
              <div
                className={classNames(styles.mitigationStatusDone, styles.tooltipElement, {
                  [styles.mitigationStatusDoneActive]: formik.values.status === MITIGATION_STATUS.DONE,
                })}
                onClick={() => handleChangeStatus(MITIGATION_STATUS.DONE)}
                data-tooltip={MITIGATION_STATUS_TEXT.COMPLETE}
              >
                <Check2All fontSize={26} />
              </div>
              <div
                className={classNames(styles.mitigationStatusWill, styles.tooltipElement, {
                  [styles.mitigationStatusWillActive]: formik.values.status === MITIGATION_STATUS.WILL,
                })}
                onClick={() => handleChangeStatus(MITIGATION_STATUS.WILL)}
                data-tooltip={MITIGATION_STATUS_TEXT.QUEUED_FOR_ACTION}
              >
                <ClockHistory fontSize={26} />
              </div>
              <div
                className={classNames(styles.mitigationStatusNever, styles.tooltipElement, {
                  [styles.mitigationStatusNeverActive]: formik.values.status === MITIGATION_STATUS.NEVER,
                })}
                onClick={() => handleChangeStatus(MITIGATION_STATUS.NEVER)}
                data-tooltip={MITIGATION_STATUS_TEXT.NOT_APPLICABLE}
              >
                <Clipboard2X fontSize={26} />
              </div>
              <div
                className={classNames(
                  styles.mitigationStatusDefault,
                  styles.tooltipElement,
                  styles.lastTooltipElement,
                  {
                    [styles.mitigationStatusDefaultActive]: !formik.values.status?.length,
                  },
                )}
                onClick={() => handleChangeStatus(null)}
                data-tooltip={MITIGATION_STATUS_TEXT.UNCLASSIFIED}
              >
                <XLg fontSize={26} />
              </div>
            </div>
          </div>
        )}
        {!isNew && (
          <div className={classNames(styles.mitigationFullInfo, 'd-flex align-items-center justify-content-between')}>
            <h4>Show full information</h4>
            {showFullInfo ? (
              <DashLg fontSize={26} color="black" onClick={() => setShowFullInfo(false)} />
            ) : (
              <Plus fontSize={26} color="black" onClick={() => setShowFullInfo(true)} />
            )}
          </div>
        )}

        {showFullInfo && (
          <>
            <UiInput
              name="title"
              label="Mitigation name *"
              placeholder="Mitigation name"
              showError={!formik.values.title?.length}
              value={formik.values.title}
              onChange={formik.handleChange}
              onFocus={() => dispatch.diagram.setCanCopy(false)}
              onBlur={(e: any) => {
                formik.handleBlur(e);
                dispatch.diagram.setCanCopy(true);
              }}
              disabled={inputDisabled}
            />
            <UiInput
              name="definition"
              type="textarea"
              textAreaRows={7}
              label="Definition *"
              placeholder="Not specified"
              showError={!formik.values.definition?.length}
              value={formik.values.definition}
              onChange={formik.handleChange}
              onFocus={() => dispatch.diagram.setCanCopy(false)}
              onBlur={(e: any) => {
                formik.handleBlur(e);
                dispatch.diagram.setCanCopy(true);
              }}
              disabled={inputDisabled}
            />
            <UiInput
              name="howItWorks"
              type="textarea"
              label="How it works"
              placeholder="Not specified"
              showError={!!formik.errors.howItWorks}
              value={formik.values.howItWorks}
              onChange={formik.handleChange}
              onFocus={() => dispatch.diagram.setCanCopy(false)}
              onBlur={(e: any) => {
                formik.handleBlur(e);
                dispatch.diagram.setCanCopy(true);
              }}
              disabled={inputDisabled}
            />
            <UiInput
              name="consideration"
              type="textarea"
              label="Considerations"
              placeholder="Not specified"
              showError={!!formik.errors.consideration}
              value={formik.values.consideration}
              onChange={formik.handleChange}
              onFocus={() => dispatch.diagram.setCanCopy(false)}
              onBlur={(e: any) => {
                formik.handleBlur(e);
                dispatch.diagram.setCanCopy(true);
              }}
              disabled={inputDisabled}
            />
            <UiInput
              name="example"
              type="textarea"
              label="Example"
              placeholder="Not specified"
              showError={!!formik.errors.example}
              value={formik.values.example}
              onChange={formik.handleChange}
              onFocus={() => dispatch.diagram.setCanCopy(false)}
              onBlur={(e: any) => {
                formik.handleBlur(e);
                dispatch.diagram.setCanCopy(true);
              }}
              disabled={inputDisabled}
            />
          </>
        )}
      </div>

      {showButtons && (
        <div className="mt-3 text-end">
          <UiButton type="transparent" onClick={handleBack}>
            Cancel
          </UiButton>
          <UiButton disabled={!!Object.keys(formik?.errors || {})?.length} onClick={() => formik.handleSubmit()}>
            {isSaving ? <Spinner size="sm" className={styles.spinner} /> : <div>Save</div>}
          </UiButton>
        </div>
      )}
    </div>
  );
};

export default AddMitigationForm;
