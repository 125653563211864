import classNames from 'classnames';
import { memo, useState } from 'react';
import { ArrowClockwise, CalendarEvent, ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Popover } from 'reactstrap';
import { formatDateForThreatModelHistory } from '../../../helpers/diagram';
import { type EmitUpdate } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { PROJECTS_ROUTE } from '../../../store/constants/route-constants';
import type { Dispatch } from '../../../store/store';
import { CollapseSection } from '../../CollapseSection';
import UiButton from '../../common/UIButton/UiButton';
import InfinityScroll from '../../LazyLoader';
import styles from './styles.module.css';

type ThreatModelHistoryProps = {
  emitUpdate: EmitUpdate;
};

const ThreatModelHistory = ({ emitUpdate }: ThreatModelHistoryProps) => {
  const dispatch = useDispatch<Dispatch>();
  const params = useParams();

  const navigate = useNavigate();
  const isThreatModeHistoryLoading = useTypedSelector((state) => state.threatModel.isThreatModeHistoryLoading);
  const threatModelHistory = useTypedSelector((state) => state.threatModel?.threatModelHistory);
  const isRestoreVersion = useTypedSelector((state) => state.threatModel?.isRestoreVersion);
  const rootThreatModel = useTypedSelector((state) => state.threatModel.rootThreatModel);
  const currentThreatModelId = useTypedSelector((state) => state.threatModel.currentThreatModel?.id);
  const currentRepresentationIndex = useTypedSelector((state) => {
    const index =
      state.threatModel.currentThreatModel?.representations.findIndex(({ id }) => params.diagramId === id) || 0;

    return index === -1 ? 0 : index;
  });
  const [openPopover, setOpenPopover] = useState<string>('');

  const handleHistoryChange = async (id: string) => {
    navigate(`${PROJECTS_ROUTE}/${params.id}/d/${id}`);
  };

  const handleRestoreThreatModel = async (id: string) => {
    dispatch.threatModel.restoreThreatModelToPreviousVersion({ id, navigate, emitUpdate });
  };

  const handlePopover = (id: string) => {
    setOpenPopover((prev) => {
      if (prev === id) return '';

      return id;
    });
  };

  return (
    <CollapseSection
      onOpen={() => dispatch.threatModel.getThreatModelHistory(rootThreatModel?.id || '')}
      title={<span className={styles.threatHistoryTitle}>Threat Model History</span>}
      closeIcon={<ChevronUp size={20} />}
      openIcon={<ChevronDown size={20} />}
      defaultOpen={false}
    >
      <div className={styles.threatHistoryContainer}>
        <InfinityScroll
          canLoad={threatModelHistory?.hasNext && !isThreatModeHistoryLoading}
          onNext={() => dispatch.threatModel.loadMoreThreatMode(rootThreatModel?.id || '')}
        >
          <div
            className={classNames([styles.threatHistoryContent], {
              [styles.currentThreatModel]: rootThreatModel?.id === currentThreatModelId,
            })}
            onClick={() => {
              if (!rootThreatModel) return;

              handleHistoryChange(
                rootThreatModel?.representations[currentRepresentationIndex]?.id ||
                  rootThreatModel?.representations[0]?.id,
              );
            }}
          >
            <div>
              <div className={styles.threatTitle}>Current</div>
              <div className={styles.threatDate}>
                <CalendarEvent size={14} /> {formatDateForThreatModelHistory(new Date())}
              </div>
            </div>
          </div>
          {threatModelHistory?.historyPoints?.map((item) => (
            <div
              key={item.id}
              className={classNames([styles.threatHistoryContent], {
                [styles.currentThreatModel]: item.id === currentThreatModelId,
              })}
              onClick={() =>
                handleHistoryChange(item.representations[currentRepresentationIndex]?.id || item.representations[0]?.id)
              }
            >
              <div>
                <div className={styles.threatTitle}>{item.title}</div>
                <div className={styles.threatDate}>
                  <CalendarEvent size={14} /> {formatDateForThreatModelHistory(item.created_at)}
                </div>
              </div>

              <ArrowClockwise
                size={20}
                id={`popover-${item.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handlePopover(item.id);
                }}
              />
              <Popover
                onClick={(e) => e.stopPropagation()}
                flip
                target={`popover-${item.id}`}
                isOpen={openPopover === item.id}
                toggle={() => {
                  handlePopover(item.id);
                }}
                innerClassName={styles.popover}
              >
                <div>
                  Selecting <b>Restore</b> will make this the <b>Current</b> active version of the model, moving the
                  existing one to a historical version.
                </div>
                <UiButton disabled={isRestoreVersion} onClick={() => handleRestoreThreatModel(item.id)}>
                  Restore Now
                </UiButton>
              </Popover>
            </div>
          ))}
        </InfinityScroll>
      </div>
    </CollapseSection>
  );
};

export default memo(ThreatModelHistory);
