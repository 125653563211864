import { WebsocketProvider } from 'y-websocket';
import type { Doc } from 'yjs';

const WS_SERVER_URL = process.env.REACT_APP_WS_SERVER_URL;

export function getWebsocketProvider(diagramId: string, doc: Doc, headerValue: string) {
  if (!WS_SERVER_URL) {
    throw new Error('Websocket provider not configured');
  }

  if (!headerValue) return null;

  return new WebsocketProvider(WS_SERVER_URL, diagramId, doc, {
    params: { tenant: headerValue },
  });
}
