import { createModel } from '@rematch/core';
import { toast } from 'react-toastify';
import type { Node } from 'reactflow';
import type { Comment } from '../../global/types';
import {
  getDrawnIsOpenFromLocalStorage,
  getDrawnWidthFromLocalStorage,
  setDrawnIsOpenToLocalStorage,
  setDrawnWidthFromLocalStorage,
} from '../../helpers/diagram';
import history from '../../helpers/history';
import type { EmitUpdate } from '../../hooks/useSocket';
import {
  COMMENTS,
  COMPONENTS,
  COMPONENTS_MAKE_COPY,
  COMPONENTS_MAKE_COPY_MANY,
  DELETE_THREAT_FROM_COMPONENTS,
  MITIGATIONS,
  THREATS,
} from '../constants/api-constants';
import type { MITIGATION_STATUS } from '../constants/drawn-constants';
import http from '../http/http-common';
import type { AttributeTypes } from './attributes';
import type { RootModel } from './index';

export type IMitigation = {
  id?: string;
  status: MITIGATION_STATUS | null;
  title: string;
  definition?: string;
  consideration?: string;
  example?: string;
  explanation?: string;
  question?: string;
};

type IComponent = {
  id: string;
  title: string;
  description: string;
  representation?: any;
  attributes: any[];
  threats: any[];
  resource_url?: string;
};

export type IThreat = {
  id: string;
  title: string;
  description: string;
  category?: string;
  is_custom: boolean;
  mitigations: IMitigation[] | null;
  priority: string;
  status: string;
  history_records?: any;
  comments: Comment[];
  neutralized_by?: {
    id: string;
    title: string;
  }[];
  caused_by?: {
    id: string;
    title: string;
  }[];
  component?: IComponent;
};

export type IAttribute = {
  id: string;
  title: string;
  description: string;
  is_custom: boolean;
  type: AttributeTypes | null;
};

export interface IMitigatingAttribute extends IAttribute {
  shouldNeutralize: string[];
}

type DrawnState = {
  visible: boolean;
  drawnWidth: number;
  component: IComponent;
  threat: IThreat;
  mitigation: IMitigation;
  newMitigation: IMitigation | null;
  stickersVisible: boolean;
  isCommentInputActive: boolean;
  commentData: {
    count: number;
    items: Comment[];
  };
  commentLimit: number;
  currentAttribute: IAttribute;
  isFirstOpen: boolean;
  forceUpdateComponentId: string | null;
  visualRemoveComponentId: string | null;
  forceUpdateThreatId: string | null;
  forceRemoveThreatId: string | null;
  forceUpdateMitigationId: string | null;
  forceUpdateForCommentsByThreatId: string | null;
  emitUpdate?: EmitUpdate;
  emitComponentVisualRemove?: (componentId: string) => void;
  wsRemoveAttributeId: string | null;
};

export const initialComponent = {
  id: '',
  title: '',
  description: '',
  representation: '',
  attributes: [],
  threats: [],
};

const initialThreat = {
  id: '',
  title: '',
  description: '',
  category: '',
  is_custom: true,
  mitigations: [],
  priority: '',
  status: '',
  comments: [],
};

export const initialMitigation = {
  status: null,
  title: '',
  definition: '',
  consideration: '',
  example: '',
  explanation: '',
};

const initialAttribute = {
  id: '',
  title: '',
  description: '',
  is_custom: false,
  type: null,
};

export const drawn = createModel<RootModel>()({
  state: {
    visible: getDrawnIsOpenFromLocalStorage(),
    drawnWidth: getDrawnWidthFromLocalStorage(),
    component: initialComponent,
    threat: initialThreat,
    mitigation: initialMitigation,
    newMitigation: initialMitigation,
    stickersVisible: true,
    comments: [],
    commentData: {
      count: 0,
      items: [],
    },
    isCommentInputActive: false,
    commentLimit: 5,
    currentAttribute: initialAttribute,
    isFirstOpen: false,
    forceUpdateComponentId: null,
    forceUpdateThreatId: null,
    forceRemoveThreatId: null,
    forceUpdateMitigationId: null,
    forceUpdateForCommentsByThreatId: null,
    visualRemoveComponentId: null,
    wsRemoveAttributeId: null,
  } as DrawnState,
  reducers: {
    setIsCommentInputActive(state, isCommentInputActive) {
      return { ...state, isCommentInputActive };
    },
    setIsFirstOpen(state, isFirstOpen) {
      return {
        ...state,
        isFirstOpen,
      };
    },
    setVisible(state, visible) {
      setDrawnIsOpenToLocalStorage(visible);

      return { ...state, visible };
    },
    setComponent(state, component) {
      return { ...state, component };
    },
    setThreat(state, threat) {
      return { ...state, threat };
    },
    setCommentData(state, commentData) {
      return { ...state, commentData };
    },
    clearCommentData(state) {
      const commentData = {
        items: [],
        count: 0,
      };

      return { ...state, commentData, commentLimit: 5 };
    },
    setCommentLimit(state, limit: number) {
      return {
        ...state,
        commentLimit: limit,
      };
    },
    setMitigation(state, mitigation) {
      return { ...state, mitigation };
    },
    setNewMitigation(state, newMitigation) {
      return { ...state, newMitigation };
    },
    clearPrevTreatState(state) {
      return { ...state, threat: initialThreat };
    },
    clearMitigation(state) {
      return { ...state, mitigation: initialMitigation };
    },
    clearNewMitigation(state) {
      return { ...state, newMitigation: initialMitigation };
    },
    setDrawnWidth(state, drawnWidth) {
      setDrawnWidthFromLocalStorage(drawnWidth);

      return { ...state, drawnWidth };
    },
    setStickersVisible(state, stickersVisible) {
      return { ...state, stickersVisible };
    },
    removeMitigation(state, mitigationId) {
      const newThreat = state.threat;
      newThreat.mitigations = state.threat?.mitigations?.filter((m) => m.id !== mitigationId) || [];

      return {
        ...state,
        threat: newThreat,
      };
    },
    setCurrentAttribute(state, currentAttribute) {
      return { ...state, currentAttribute };
    },
    setForceUpdateComponentId(state, forceUpdateComponentId) {
      return { ...state, forceUpdateComponentId };
    },
    setForceUpdateThreatId(state, forceUpdateThreatId) {
      return { ...state, forceUpdateThreatId };
    },
    setForceRemoveThreatId(state, forceRemoveThreatId) {
      return { ...state, forceRemoveThreatId };
    },
    setForceUpdateForCommentsByThreatId(state, forceUpdateForCommentsByThreatId) {
      return { ...state, forceUpdateForCommentsByThreatId };
    },
    setForceUpdateMitigationId(state, forceUpdateMitigationId) {
      return { ...state, forceUpdateMitigationId };
    },
    setVisualRemoveComponentId(state, visualRemoveComponentId) {
      return { ...state, visualRemoveComponentId };
    },
    setEmitUpdate(state, emitUpdate) {
      return { ...state, emitUpdate };
    },
    setEmitComponentVisualRemove(state, emitComponentVisualRemove) {
      return { ...state, emitComponentVisualRemove };
    },
    clearCurrentAttribute(state) {
      return { ...state, currentAttribute: initialAttribute };
    },
    setWsRemoveAttributeId(state, wsRemoveAttributeId) {
      return { ...state, wsRemoveAttributeId };
    },
  },
  effects: (dispatch) => {
    return {
      async saveComponent(node: Node, state) {
        try {
          if (node?.id && node?.data?.representation) {
            const result = await http.put(COMPONENTS, {
              id: node.id,
              representationId: node.data.representation,
              title: node.data?.label || ' ',
              description: '',
            });
            const pathname = window.location.pathname.split('/');
            const project = pathname?.[2];
            const diagram = pathname?.[4];

            if (result.status === 200 && project && diagram) {
              dispatch.threatModel.setCanPdfExport(true);
              history.push(`/collections/${project}/d/${diagram}/c/${node.id}`);
            }
          }
        } catch (error) {
          console.error(error);
        }
      },
      async makeComponentCopy({ idToCopyFrom, idToApply }) {
        try {
          if (idToCopyFrom && idToApply) {
            await http.post(COMPONENTS_MAKE_COPY, {
              idToCopyFrom,
              idToApply,
            });
          }
        } catch (error) {
          console.error(error);
        }
      },
      async makeManyComponentCopy({ data, representationId }) {
        try {
          if (Object.keys(data || {}).length) await http.post(`${COMPONENTS_MAKE_COPY_MANY}/${representationId}`, data);
        } catch (error) {
          console.error(error);
        }
      },
      async updateComponent(component: any) {
        try {
          if (component?.id) await http.put(COMPONENTS, component);
        } catch (error) {
          console.error(error);
        }
      },
      async getComponent({ id, setNull = true }) {
        try {
          if (setNull) {
            dispatch.drawn.setComponent(null);
          }

          const result = await http(`${COMPONENTS}/${id}`);

          if (result.status === 200 && result?.data?.id) {
            if (result?.data?.isRecovered) {
              await dispatch.threatModel.updateCurrentThreatModel();
            }

            dispatch.drawn.setComponent(result.data);
            dispatch.drawn.getAttributesAndThreats(id);

            return true;
          }
        } catch (error: any) {
          console.error(error.message);
        }
      },
      async addAttribute(attrData: any) {
        try {
          if (attrData?.componentId) {
            const result = await http.post(`${COMPONENTS}/add-attribute`, attrData);

            if (result.status === 201) {
              dispatch.drawn.getAttributesAndThreats(attrData?.componentId);
            } else {
              const responseText = result.request?.responseText;
              const response = responseText?.length ? JSON.parse(responseText) : null;
              toast.warning(response?.message?.toString());
            }
          }
        } catch (error) {
          console.error(error);
        }
      },
      async addCustomAttribute(attr: any) {
        try {
          if (attr?.componentId) {
            const result = await http.post(`${COMPONENTS}/add-custom-attribute`, attr);

            if (result.status === 201) {
              dispatch.drawn.getAttributesAndThreats(attr.componentId);
            }
          }
        } catch (error) {
          console.error(error);
        }
      },
      async syncThreats(componentId: string) {
        try {
          const result = await http.post(`${COMPONENTS}/sync-threats`, {
            componentId,
          });

          if (result.status === 201) {
            dispatch.drawn.setComponent(result.data);
          }
        } catch (error) {
          console.error(error);
        }
      },
      async removeAttribute(attr, state) {
        try {
          if (attr.componentId) {
            const result = await http.delete(`${COMPONENTS}/${attr.componentId}/${attr.attributeId}`);

            if (result.status === 200) {
              dispatch.drawn.setComponent({
                ...state.drawn.component,
                attributes: state.drawn.component.attributes.filter((i) => i.id !== attr.attributeId),
              });
              dispatch.drawn.syncThreats(attr.componentId);
            }
          }
        } catch (error) {
          console.error(error);
        }
      },
      async addUserGeneratedThreat(threat: any, state) {
        try {
          if (threat.priority.length && threat.componentId.length) {
            const result = await http.post(THREATS, threat);

            if (result.status === 201) {
              if (state.drawn?.component?.id)
                await dispatch.drawn.getComponent({
                  id: state.drawn.component.id,
                });

              return result.status === 201;
            }
          }
        } catch (error) {
          console.error(error);
        }
      },
      async getThreat(id: string, state) {
        try {
          const result = await http(`${THREATS}/${id}`);

          if (result.status === 200) {
            dispatch.drawn.setThreat(result.data);
            dispatch.threats.updateThreatFromThreatRegisterList(result.data);
            dispatch.threats.updateSubThreatFromElementRegisterList(result.data);

            if (state.drawn?.component?.threats?.length) {
              dispatch.drawn.setComponent({
                ...state.drawn.component,
                threats: state.drawn.component.threats.map((th) => {
                  if (th.id === id) return result.data;

                  return th;
                }),
              });
            }

            return result.data;
          }
        } catch (error) {
          console.error(error);
        }
      },
      async getCommentsByThreatId({ threatId, limit }) {
        try {
          const result = await http(`${COMMENTS}/${threatId}?page=0&limit=${limit}`);

          if (result.status === 200) {
            dispatch.drawn.setCommentData(result.data);
            dispatch.drawn.setCommentLimit(limit);
          }
        } catch (error) {
          console.error(error);
        }
      },
      async updateThreat(threat: any) {
        try {
          if (threat?.id) {
            const result = await http.patch(`${THREATS}/${threat.id}`, threat);

            if (result.status === 200) dispatch.drawn.getThreat(threat.id);
          }
        } catch (error) {
          console.error(error);
        }
      },
      async addMitigation({ threatsId, mitigation }) {
        try {
          if (threatsId) {
            const result = await http.post(`${MITIGATIONS}/${threatsId}`, mitigation);

            if (result.status === 201) {
              dispatch.drawn.setMitigation(initialMitigation);

              return true;
            }
          }
        } catch (error) {
          console.error(error);
        }
      },
      async getMitigation(id) {
        const result = await http(`${MITIGATIONS}/${id}`);

        if (result.status === 200) dispatch.drawn.setMitigation(result.data);
      },
      async updateMitigation({ id, mitigation, threatsId, isThreatRegister = false }) {
        try {
          const result = await http.patch(`${MITIGATIONS}/${id}`, mitigation);

          if (result.status === 200) {
            if (!isThreatRegister) dispatch.drawn.getThreat(threatsId);

            return true;
          }
        } catch (error) {
          console.error(error);
        }
      },
      async deleteMitigation(mitigation) {
        try {
          const result = await http.delete(`${MITIGATIONS}/${mitigation.id}`);
          const responseText = result.request?.responseText;

          if (responseText?.length) {
            const response = JSON.parse(responseText);
            toast.error(response?.message);
          }

          if (result.status === 200) {
            dispatch.drawn.removeMitigation(mitigation.id);

            return true;
          }
        } catch (error) {
          console.error(error);
        }
      },
      async addComment({ threatId, commentText, limit }) {
        try {
          if (threatId) {
            const result = await http.post(`${COMMENTS}/${threatId}`, {
              text: commentText,
            });

            if (result.status === 201) {
              dispatch.drawn.getCommentsByThreatId({ threatId, limit });

              return true;
            }
          }
        } catch (error) {
          console.error(error);
        }
      },
      async removeThreatFromComponent(id, state) {
        try {
          const componentId = state.drawn?.component.id;

          if (!componentId) return;

          const result = await http.delete(`${DELETE_THREAT_FROM_COMPONENTS}/${id}/`);
          const responseText = result.request?.responseText;

          if (responseText?.length) {
            const response = JSON.parse(responseText);
            toast.error(response?.message);
          }

          if (result.status === 200) {
            dispatch.drawn.setComponent({
              ...state.drawn.component,
              threats: state.drawn.component.threats.filter((i) => i.id !== id),
            });
            toast.success('Threat deleted from component!');

            return true;
          }
        } catch (error) {
          console.error(error);
        }
      },
      async getAttributesAndThreats(id, state) {
        try {
          const attributes = await dispatch.attributes.getComponentAttributes(id);
          const threats = await dispatch.threats.getComponentThreats(id);
          dispatch.drawn.setComponent({
            ...state.drawn.component,
            attributes,
            threats,
          });
        } catch (error) {
          console.error(error);
        }
      },
      toggleStickers(_, state) {
        const nodes = Array.from(state.diagram.nodesSharedState?.values() || []);
        const stickerIds = nodes.filter((n) => n.type === 'stickerNode').map((i) => i.id);
        stickerIds.forEach((stickerId) => {
          const stickerNode = document.querySelector<HTMLElement>(`div[data-id='${stickerId}']`);

          if (stickerNode) stickerNode.style.display = state.drawn.stickersVisible ? 'unset' : 'none';
        });
      },
      async updateManyComponents({ representationId, components }) {
        try {
          const response = await http.patch(`${COMPONENTS}/move`, {
            representationId,
            components,
          });

          if (response.status !== 200) {
            toast.warning('Operation failed, please revert your changes on previous canvas!');
          }
        } catch (error) {
          console.error(error);
        }
      },

      async removeComponents(components) {
        try {
          await http.delete(`${COMPONENTS}/many`, {
            data: {
              components,
            },
          });
        } catch (error) {
          console.error(error);
        }
      },
    };
  },
});
