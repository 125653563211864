import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Plus, X, XLg } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import Skeleton from 'react-loading-skeleton';
import { Spinner } from 'reactstrap';
import { MIN_SEARCH_LENGTH, SKELETON_COLORS } from '../../../global/constants';
import { ATTRIBUTES_SEARCH_TYPE } from '../../../helpers/diagram';
import { useSelectedEntityType } from '../../../hooks/useSelectedEntityType';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import { AISearchInput } from '../AISearchInput/AISearchInput';
import { AttributeSearchTypeButtons } from '../AISearchInput/AttributeSearchTypeButtons';
import styles from './index.module.css';
import { MitigationAttributesForm } from './MitigatingAttributes';

type DrawnAttributesProps = {
  showSearch: boolean;
  setShowSearch: (val: boolean) => void;
  onAttributeAdd: () => void;
  onAttributeRemove: (attributeId: string) => void;
};
enum TooltipPosition {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

let timer: any = null;
const SHOW_LEO = process.env?.REACT_APP_SHOW_LEO === 'true';

const DrawnAttributes: FC<DrawnAttributesProps> = ({
  showSearch,
  setShowSearch,
  onAttributeAdd,
  onAttributeRemove,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const { componentId } = useParams();
  const { component, drawnWidth } = useTypedSelector((state) => state.drawn);
  const { attributes, popupAttributes, popupMitigatingAttributes, mitigatingAttributes } = useTypedSelector(
    (state) => state.diagram,
  );
  const isSearchingAttributes = useTypedSelector((state) => state.diagram?.isSearchingAttributes);
  const [searchValue, setSearchValue] = useState('');
  const [selectedAttributeIndex, setSelectedAttributeIndex] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const selectedEntityType = useSelectedEntityType();
  const plusRef = useRef<any>(null);
  const [tooltipPoss, setTooltipPoss] = useState<TooltipPosition>(TooltipPosition.CENTER);
  const [searchType, setSearchType] = useState<ATTRIBUTES_SEARCH_TYPE>(ATTRIBUTES_SEARCH_TYPE.CODEX);
  const foundAttributes =
    attributes?.filter((aa) => !component?.attributes?.map((ca) => ca?.title)?.includes(aa.title)) || [];

  const setAttributesTooltipSide = () => {
    if (!plusRef?.current) return;

    const { left, right } = plusRef.current.getBoundingClientRect();

    if (right + 35 > window.innerWidth) setTooltipPoss(TooltipPosition.RIGHT);
    else {
      const drawerLeft = document.getElementById('drawn-element')?.getBoundingClientRect()?.left;

      if (drawerLeft && left - 35 < drawerLeft) {
        setTooltipPoss(TooltipPosition.LEFT);
      } else setTooltipPoss(TooltipPosition.CENTER);
    }
  };

  useLayoutEffect(() => {
    setAttributesTooltipSide();
  }, [drawnWidth, component]);

  useEffect(() => {
    if (popupAttributes?.length) {
      setSearchValue('');
      setShowSearch(false);
      dispatch.diagram.setAttributes([]);
    }
  }, [popupAttributes?.length]);

  useEffect(() => {
    if (popupMitigatingAttributes && Object.keys(popupMitigatingAttributes)?.length) {
      setSearchValue('');
      setShowSearch(false);
      dispatch.diagram.setMitigatingAttributes(null);
    }
  }, [popupMitigatingAttributes]);

  useEffect(() => {
    if (searchValue.length) setSearchValue('');

    if (attributes.length) {
      dispatch.diagram.setAttributes([]);
      dispatch.diagram.setMitigatingAttributes(null);
      dispatch.diagram.setPopupMitigatingAttributes(null);
    }
  }, [componentId]);

  useEffect(() => {
    setSearchValue('');
    dispatch.diagram.setAttributes([]);
    dispatch.diagram.setMitigatingAttributes(null);
    dispatch.diagram.setPopupMitigatingAttributes(null);
  }, [searchType]);

  const handleChange = (e: any) => {
    setSearchValue(e.target.value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      const { value } = e.target;

      if (value?.length && value.length >= MIN_SEARCH_LENGTH)
        dispatch.diagram.getAttributes({ search: value, selectedEntityType });
      else dispatch.diagram.setAttributes([]);
    }, 500);
  };

  const handleAddAttribute = (attr: any) => {
    setDisabled(true);

    if (!disabled) {
      dispatch.drawn
        .addAttribute({
          componentId: component.id,
          id: attr.id,
          isCustom: attr.is_custom,
        })
        .then(() => {
          setDisabled(false);
        });
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (!componentId) return;

        dispatch.drawn.syncThreats(componentId).then(() => {
          onAttributeAdd();
        });
      }, 2000);
    }
  };

  const handleRemoveAttribute = (attribute: any) => {
    dispatch.drawn.removeAttribute({
      attributeId: attribute.id,
      componentId: component.id,
    });
    onAttributeRemove(attribute.id);

    if (mitigatingAttributes) {
      dispatch.diagram.getMitigatingAttributes({ componentId: component.id, isPopup: false, selectedEntityType });
    }
  };

  const handleAddCustomAttribute = () => {
    if (!searchValue.length) return;

    dispatch.drawn.addCustomAttribute({
      componentId: component.id,
      title: searchValue,
    });
    setSearchValue('');
    onAttributeAdd();
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      let newIndex = event.shiftKey ? selectedAttributeIndex - 1 : selectedAttributeIndex + 1;

      if (newIndex < 0) newIndex = foundAttributes.length - 1;

      if (newIndex >= foundAttributes.length) newIndex = 0;

      setSelectedAttributeIndex(newIndex);
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      const selectedAttr = foundAttributes?.[selectedAttributeIndex];

      if (selectedAttr?.id) {
        handleAddAttribute(selectedAttr);
        setSearchValue('');
        dispatch.diagram.setAttributes([]);
        setSelectedAttributeIndex(0);
      }
    }
  };

  const renderAttributes = (attr: any) => {
    if (attr)
      return (
        <div
          key={attr.id}
          className={classNames('rounded d-flex justify-content-between gap-2 align-items-center cursor-pointer', {
            [styles.attribute]: !attr.is_custom,
            [styles.customAttribute]: attr.is_custom,
          })}
          onClick={() => {
            navigate(`a/${attr.id}`);
          }}
        >
          {attr.title}
          <XLg
            className="cursor-pointer"
            fontSize={12}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleRemoveAttribute(attr);
            }}
          />
        </div>
      );

    return null;
  };

  const renderFoundAttributes = (forAiSearch = false) => {
    const canAddCustom =
      searchValue.length > 2 &&
      !component?.attributes?.map((a) => a.title.toLocaleLowerCase())?.includes(searchValue.toLocaleLowerCase()) &&
      !isSearchingAttributes &&
      !forAiSearch;

    if (foundAttributes?.length)
      return (
        <div className={styles.attributesWrap}>
          {foundAttributes.map((attr, idx) => (
            <div
              key={attr.id}
              className={classNames('fs-12', styles.attributeItem, {
                [styles.selectedAttribute]: selectedAttributeIndex === idx,
                [styles.customAttribute]: attr?.is_custom,
              })}
              onClick={() => handleAddAttribute(attr)}
            >
              {attr.title}
            </div>
          ))}
        </div>
      );

    if (canAddCustom)
      return (
        <div className={styles.attributesWrap}>
          <div
            className={classNames('fs-12', styles.attributeItem, styles.customAttributeItem)}
            onClick={handleAddCustomAttribute}
          >
            <Plus fontSize={16} color="#0062D8" />
            {`Add “${searchValue}” as a new attribute`}
          </div>
          <div className={styles.noDataText}>No matches found</div>
        </div>
      );

    return null;
  };

  return (
    <>
      {component?.attributes && component?.attributes?.map(renderAttributes)}
      {!component?.attributes && (
        <>
          <Skeleton
            style={{ lineHeight: '25px' }}
            width={100}
            height={28}
            baseColor={SKELETON_COLORS.BASE}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
          />
          <Skeleton
            style={{ lineHeight: '25px' }}
            width={100}
            height={28}
            baseColor={SKELETON_COLORS.BASE}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
          />
        </>
      )}
      {showSearch ? (
        <>
          {SHOW_LEO && (
            <div className="w-100">
              <AttributeSearchTypeButtons
                searchType={searchType}
                setSearchType={setSearchType}
                showCloseBtn
                clearSearchValue={() => setSearchValue('')}
                hideSearch={() => setShowSearch(false)}
              />
            </div>
          )}
          {searchType === ATTRIBUTES_SEARCH_TYPE.CODEX && (
            <div className={styles.inputWrap} onKeyDown={handleKeyDown}>
              {!isSearchingAttributes && (
                <X
                  className={styles.inputWrapClearButton}
                  onClick={() => {
                    setShowSearch(false);
                    setSearchValue('');
                    dispatch.diagram.setAttributes([]);
                  }}
                />
              )}
              {isSearchingAttributes && (
                <div style={{ top: '-3px' }} className={styles.inputWrapClearButton}>
                  <Spinner color="dark" size="sm" />
                </div>
              )}
              <input
                type="text"
                value={searchValue}
                className="form-control shadow-none mb-1 pe-4"
                placeholder="Enter attributes"
                onChange={handleChange}
                autoFocus
                onFocus={() => dispatch.diagram.setCanCopy(false)}
                onBlur={() => dispatch.diagram.setCanCopy(true)}
              />
              {renderFoundAttributes()}
            </div>
          )}
          {searchType === ATTRIBUTES_SEARCH_TYPE.MITIGATIN && componentId && (
            <MitigationAttributesForm
              isPopup={false}
              componentId={componentId}
              onAttributeAdd={onAttributeAdd}
              mitigatingAttributes={mitigatingAttributes}
            />
          )}
          {searchType === ATTRIBUTES_SEARCH_TYPE.LEO && (
            <div className="w-100">
              <AISearchInput search={searchValue} setSearch={setSearchValue} forDrawer />
              {renderFoundAttributes(true)}
            </div>
          )}
        </>
      ) : (
        <div
          ref={plusRef}
          className={classNames(styles.addAttributeIcon, {
            [styles.isLeftSide]: tooltipPoss === 'left',
            [styles.isRightSide]: tooltipPoss === 'right',
          })}
          onClick={() => setShowSearch(true)}
          data-tooltip="Add attributes"
          data-tooltip-z-index={43}
        >
          <Plus fontSize={16} color="#4285F4" />
        </div>
      )}
    </>
  );
};

export default DrawnAttributes;
