import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Search } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import { MIN_SEARCH_LENGTH } from '../../../global/constants';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from './UiSearch.module.css';

type SearchAttributeProps = {
  placeholder?: string;
  onOptionSelect: any;
  isMitigation?: boolean;
  isThreat?: boolean;
};

let timer: any = null;

const SearchUI: FC<SearchAttributeProps> = ({
  placeholder = 'Search',
  onOptionSelect,
  isMitigation = false,
  isThreat = false,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const component = useTypedSelector((state) => state.drawn?.component);

  const { mitigations, threats } = useTypedSelector((state) => state.diagram);
  const { isSearchingMitigations, isSearchingThreats } = useTypedSelector((state) => state.diagram);

  const [value, setValue] = useState('');
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [dropDownVisible, setDropDownVisible] = useState(true);

  useEffect(() => {
    return () => {
      dispatch.diagram.setThreats([]);
      dispatch.diagram.setMitigations([]);
      window.onkeydown = null;
    };
  }, []);

  const handleChange = (e: any) => {
    setValue(e.target.value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      const searchValue = e.target.value;

      if (searchValue?.length && searchValue.length >= MIN_SEARCH_LENGTH) {
        setDropDownVisible(true);

        if (isMitigation) {
          dispatch.diagram.getMitigations({
            search: searchValue,
          });
        }

        if (isThreat) {
          dispatch.diagram.getThreats({
            search: searchValue,
          });
        }
      } else {
        if (isMitigation) {
          dispatch.diagram.setMitigations([]);
        }

        if (isThreat) {
          dispatch.diagram.setThreats([]);
        }
      }
    }, 500);
  };

  window.onkeydown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      const itemsCount = isMitigation ? mitigations.length : threats.length;
      let newIndex = event.shiftKey ? selectedItemIndex - 1 : selectedItemIndex + 1;

      if (newIndex < 0) newIndex = itemsCount - 1;

      if (newIndex >= itemsCount) newIndex = 0;

      setSelectedItemIndex(newIndex);
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      const selectedItem = isMitigation ? mitigations?.[selectedItemIndex] : threats?.[selectedItemIndex];

      if (selectedItem?.id) {
        onOptionSelect(selectedItem);
        setValue('');
        isMitigation ? dispatch.diagram.setMitigations([]) : dispatch.diagram.setThreats([]);
        setSelectedItemIndex(0);
      }
    }
  };

  const renderOptions = () => {
    const dataList = isMitigation ? mitigations : threats;
    const filteredOptions = dataList?.filter(
      (option: any) => !component?.attributes?.map((ca) => ca.title).includes(option?.title),
    );

    if (dataList?.length && dropDownVisible && filteredOptions.length && value.length)
      return (
        <div className={classNames('d-flex flex-wrap gap-1 p-1', styles.attributesWrap)}>
          {filteredOptions?.map((option: any, idx: number) => (
            <div
              // hidden={component?.attributes
              //   ?.map((attr) => attr.title)
              //   .includes(attr)}
              key={option?.id}
              className={classNames('fs-12', styles.attributeItem, {
                [styles.selectedAttribute]: selectedItemIndex === idx,
              })}
              onClick={() => {
                onOptionSelect(option);
                setValue('');
                setDropDownVisible(false);
              }}
            >
              {option?.title}
            </div>
          ))}
        </div>
      );
  };

  return (
    <div className={classNames('w-100 position-relative', styles.inputWrap)}>
      <div>
        <Search fontSize={16} color="#6F767E" />
      </div>
      <input
        value={value}
        className={styles.input}
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        autoFocus
        onFocus={() => dispatch.diagram.setCanCopy(false)}
        onBlur={() => dispatch.diagram.setCanCopy(true)}
      />
      {((isSearchingMitigations && isMitigation) || (isSearchingThreats && isThreat)) && (
        <div style={{ paddingTop: '2px' }}>
          <Spinner color="dark" size="sm" />
        </div>
      )}
      {renderOptions()}
    </div>
  );
};

export default SearchUI;
