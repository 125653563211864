import { createModel } from '@rematch/core';

import { setChosenPlan } from '../../helpers/plan';
import type { RootModel } from './index';

type PlanState = {
  plans: [];
  chosenPlan: {
    id: string;
    nickname?: string;
    amount: number;
  };
};

export const plan = createModel<RootModel>()({
  state: {
    plans: [],
    chosenPlan: {},
  } as PlanState,
  reducers: {
    setPlans(state, plans) {
      return {
        ...state,
        plans,
      } as PlanState;
    },
    setChosenPlan(state, planId: string) {
      const chosenPlan = state.plans.find(({ id }) => id === planId);
      setChosenPlan(chosenPlan || {});

      return {
        ...state,
        chosenPlan,
      } as unknown as PlanState;
    },
  },
  effects: (dispatch) => {
    return {
      async getPlans() {},

      async choosePlan(planId: string) {
        dispatch.plan.setChosenPlan(planId);
      },
    };
  },
});
