import classNames from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { Col, Spinner } from 'reactstrap';
import * as Yup from 'yup';

import { SKELETON_COLORS } from '../../global/constants';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import { ROLE } from '../../store/models/user';
import type { Dispatch } from '../../store/store';
import UiButton from '../common/UIButton/UiButton';
import UiInput from '../common/UiInput/UiInput';
import styles from '../Project/ProjectCreateOrUpdate/ProjectCreateOrUpdate.module.css';

export const ProfileCompanyForm = () => {
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const { current } = useTypedSelector((state) => state.user);
  const { companyName } = useTypedSelector((state) => state.customer);

  useEffect(() => {
    if (companyName) setIsFetched(true);
  }, [companyName]);

  const companyFormik = useFormik({
    enableReinitialize: true,
    initialValues: { name: companyName || '' },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(6, 'Organization name must be at least 6 characters')
        .max(140, 'Organization name must be at most 140 characters')
        .required('Organization name is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      await dispatch.customer.updateCompany({
        name: values.name,
      });
      setLoading(false);
    },
  });
  const showError = !!(companyFormik.touched.name && companyFormik.errors.name);
  const isAdmin = current?.role === ROLE.ADMIN;
  const disableSubmit = !isAdmin || showError || !companyFormik.values.name.length;

  return (
    <div className="border-bottom p-3 user-select-none">
      <div
        className={classNames(styles.companyNameInputLabel, {
          'text-danger': showError,
        })}
      >
        * Organization Name
      </div>
      <div className="mt-2">
        <Col className="fs-12">
          {isFetched ? (
            <UiInput
              name="name"
              type="text"
              placeholder="Enter organization name"
              value={companyFormik.values.name}
              onChange={companyFormik.handleChange}
              onBlur={companyFormik.handleBlur}
              errorText={companyFormik.errors.name}
              showError={showError}
              disabled={!isAdmin}
            />
          ) : (
            <div>
              <Skeleton
                style={{ lineHeight: '11px' }}
                width={33}
                height={17}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
              <Skeleton
                style={{ lineHeight: '11px' }}
                width={476}
                height={27}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
            </div>
          )}
          <Col className="d-flex justify-content-end mt-1">
            <UiButton
              className={styles.updateButton}
              onClick={() => {
                if (companyFormik.values.name.length) companyFormik.submitForm();
              }}
              disabled={disableSubmit}
            >
              {loading ? <Spinner color="light" size="sm" /> : 'Save'}
            </UiButton>
          </Col>
        </Col>
      </div>
    </div>
  );
};
