import classNames from 'classnames';
import type { DragEvent, FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Diagram3Fill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import CloseEyesIcon from '../../../assets/icons/CloseEyesIcon.svg';
import CloseEyesIconWhite from '../../../assets/icons/CloseEyesIconWhite.svg';
import DataStoreIcon from '../../../assets/icons/DataStoreIcon.svg';
import ExternalEntityIcon from '../../../assets/icons/ExternalEntityIcon.svg';
import ProcessIcon from '../../../assets/icons/ProcessIcon.svg';
import StickerIcon from '../../../assets/icons/StickerIcon.svg';
import TrustBoundaryIcon from '../../../assets/icons/TrustBoundaryIcon.svg';
import VectorIcon from '../../../assets/icons/VectorIcon.svg';
import VectorIconWhite from '../../../assets/icons/VectorIconWhite.svg';
import { NODES } from '../../../global/constants';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from './index.module.css';
import UndoRedo from './UndoRedo';

type DiagramToolsProps = {
  copyPasteHandlers: any;
};

const DiagramTools: FC<DiagramToolsProps> = ({ copyPasteHandlers }) => {
  const dispatch = useDispatch<Dispatch>();
  const { stickersVisible } = useTypedSelector((state) => state.drawn);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const { selectedNode } = useTypedSelector((state) => state.diagram);
  const { templatesModalVisibility, activeTemplateId } = useTypedSelector((state) => state.representation);
  const { nodesSharedState, edgesSharedState, isAllowedToDeleteByBackspace } = useTypedSelector(
    (state) => state.diagram,
  );
  const selectionRef = useRef<HTMLDivElement>(null);

  const handleKeyUp = (event: any) => {
    if (!isAllowedToDeleteByBackspace && event.code === 'Backspace') {
      dispatch.representation.removeSelectedNodes({});
    }

    if (isSelectionMode) setIsSelectionMode(false);
  };

  useEffect(() => {
    dispatch.drawn.toggleStickers({});
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [stickersVisible]);

  const deselectComponents = () => {
    Array.from(nodesSharedState?.values() || [])?.forEach((node) => {
      if (node.selected) {
        node.selected = false;
        nodesSharedState?.set(node.id, node);
      }
    });
    Array.from(edgesSharedState?.values() || [])?.forEach((edge) => {
      if (edge.selected) {
        edge.selected = false;
        edgesSharedState?.set(edge.id, edge);
      }
    });
  };

  const onDragStart = (event: DragEvent<HTMLDivElement>, nodeType: string, label: string) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.setData('label', label);
    event.dataTransfer.effectAllowed = 'move';
    deselectComponents();
  };

  const toggleStickers = () => {
    deselectComponents();
    dispatch.drawn.setStickersVisible(!stickersVisible);
  };

  const handleCursorClick = () => {
    if (activeTemplateId?.length) dispatch.representation.setActiveTemplateId(null);

    if (selectedNode) dispatch.diagram.setSelectedNode(null);

    if (selectionRef.current) {
      const eventOption = { key: 'Shift', shiftKey: true, bubbles: true };
      selectionRef.current.dispatchEvent(
        isSelectionMode ? new KeyboardEvent('keyup', eventOption) : new KeyboardEvent('keydown', eventOption),
      );
      setIsSelectionMode(!isSelectionMode);
    }
  };

  const handleToolSelect = (node: string) => {
    if (activeTemplateId?.length) {
      dispatch.representation.setActiveTemplateId(null);
    }

    if (isSelectionMode) handleCursorClick();

    dispatch.diagram.setSelectedNode(node === selectedNode ? null : node);
  };

  return (
    <div
      className={classNames(
        'tools d-inline-flex flex-column align-items-center gap-2 z-1 user-select-none noScale',
        styles.toolsWrap,
      )}
    >
      <div className={classNames('block rounded-1 shadow bg-white noScale', styles.toolItem)}>
        <div
          ref={selectionRef}
          className={classNames(styles.toolItemWrap, styles.hasTooltip, {
            [styles.activeTool]: isSelectionMode,
          })}
          onClick={handleCursorClick}
          data-tooltip={isSelectionMode ? 'Scroll' : 'Selection mode'}
        >
          {isSelectionMode && <img src={VectorIconWhite} alt="VectorIconWhite" className="noScale" />}
          {!isSelectionMode && <img src={VectorIcon} alt="VectorIcon" className="noScale" />}
        </div>
        <div
          className={classNames(styles.hasTooltip, {
            [styles.activeTool]: templatesModalVisibility || activeTemplateId?.length,
          })}
          data-tooltip="Templates"
          onClick={() => {
            if (isSelectionMode) handleCursorClick();

            dispatch.representation.setTemplatesModalVisibility(true);
            dispatch.diagram.setSelectedNode(null);
          }}
        >
          <div>
            <Diagram3Fill size={18} className="noScale" />
          </div>
        </div>
      </div>

      <div className={classNames('block rounded-1 shadow bg-white noScale', styles.toolItem)}>
        <div
          className={classNames(styles.hasTooltip, {
            [styles.activeTool]: selectedNode === NODES.PROCESS_NODE,
          })}
          data-tooltip="Process"
          onClick={() => handleToolSelect(NODES.PROCESS_NODE)}
        >
          <div onDragStart={(event) => onDragStart(event, NODES.PROCESS_NODE, 'Process')} draggable>
            <img src={ProcessIcon} alt="ProcessIcon" className="noScale" />
          </div>
        </div>
        <div
          className={classNames(styles.hasTooltip, {
            [styles.activeTool]: selectedNode === NODES.DATA_STORE_NODE,
          })}
          data-tooltip="Data Store"
          onClick={() => handleToolSelect(NODES.DATA_STORE_NODE)}
        >
          <div onDragStart={(event) => onDragStart(event, NODES.DATA_STORE_NODE, 'Datastore')} draggable>
            <img src={DataStoreIcon} alt="DataStoreIcon" className="noScale" />
          </div>
        </div>
        <div
          className={classNames(styles.hasTooltip, {
            [styles.activeTool]: selectedNode === NODES.EXTERNAL_ENTITY_NODE,
          })}
          data-tooltip="External Entity"
          onClick={() => handleToolSelect(NODES.EXTERNAL_ENTITY_NODE)}
        >
          <div onDragStart={(event) => onDragStart(event, NODES.EXTERNAL_ENTITY_NODE, 'External Entity')} draggable>
            <img src={ExternalEntityIcon} alt="ExternalEntityIcon" className="noScale" />
          </div>
        </div>
        <div
          className={classNames(styles.hasTooltip, {
            [styles.activeTool]: selectedNode === NODES.TRUST_BOUNDARY_NODE,
          })}
          data-tooltip="Trust Boundary"
          onClick={() => handleToolSelect(NODES.TRUST_BOUNDARY_NODE)}
        >
          <div onDragStart={(event) => onDragStart(event, NODES.TRUST_BOUNDARY_NODE, 'Trust Boundary')} draggable>
            <img src={TrustBoundaryIcon} alt="TrustBoundaryIcon" className="noScale" />
          </div>
        </div>
      </div>

      <div className={classNames('block rounded-1 shadow bg-white', styles.toolItem)}>
        <div
          className={classNames(styles.hasTooltip, {
            [styles.activeTool]: selectedNode === NODES.STICKER_NODE,
          })}
          data-tooltip="Sticky note"
          onClick={() => handleToolSelect(NODES.STICKER_NODE)}
        >
          <div onDragStart={(event) => onDragStart(event, NODES.STICKER_NODE, '')} draggable>
            <img src={StickerIcon} alt="StickerIcon" className="noScale" />
          </div>
        </div>
        <div
          className={classNames(styles.hasTooltip, {
            [styles.activeTool]: !stickersVisible,
          })}
          data-tooltip={`${stickersVisible ? 'Hide' : 'Show'} stickers`}
          onClick={toggleStickers}
        >
          {stickersVisible ? (
            <img src={CloseEyesIcon} alt="CloseEyesIcon" className="noScale" />
          ) : (
            <img src={CloseEyesIconWhite} alt="CloseEyesIconWhite" className="noScale" />
          )}
        </div>
      </div>

      <UndoRedo setIsSelectionMode={setIsSelectionMode} copyPasteHandlers={copyPasteHandlers} />
    </div>
  );
};

export default DiagramTools;
