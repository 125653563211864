import React from 'react';

import {
  BillingPage,
  CliThreatModelPage,
  DebugPage,
  HomePage,
  ProfilePage,
  UpdateUserAvatarPage,
  UpdateUsernamePage,
} from './pages';
import AdminPage from './pages/AdminPage';
import SettingsPage from './pages/AdminPage/SettingsPage';
import UsersPage from './pages/AdminPage/UsersPage';
import UserAddPage from './pages/AdminPage/UsersPage/UserAddPage';
import ArchivePage from './pages/ArchivePage/ArchivePage';
import CheckoutSuccessPage from './pages/CheckoutSuccessPage';
import CodeGeniusPage from './pages/CodeGeniusPage/CodeGeniusPagePage';
import ConfirmationPage from './pages/ConfirmationPage';
import ContactUsPage from './pages/CountactUs';
import MitigationsPage from './pages/MitigationsPage';
import MyModelsPage from './pages/MyModelsPage/MyModelsPage';
import PlansPage from './pages/PlansPage';
import ProjectCreateOrUpdatePage from './pages/ProjectsCreateOrUpdatePage/ProjectCreateOrUpdatePage';
import ProjectDetailPage from './pages/ProjectsPage/ProjectDetailPage';
import RetrospectivesPage from './pages/RetrospectivesPage';
import RunningListPage from './pages/RunningsListPage/RunningListPage';
import TeamsPage from './pages/TeamsPage';
import TeamDetailPage from './pages/TeamsPage/TeamDetailPage';
import { ThreatModelPage } from './pages/ThreatModelPage';
import UiKitPage from './pages/UiKitPage';
import {
  ADMIN_ROUTE,
  BILLING_ROUTE,
  CLI_THREAT_MODEL,
  CODE_GENIUS_ROUTE,
  CONFIRMATION_ROUTE,
  CONTACT_US_ROUTE,
  DEBUG_PAGE_ROUTE,
  MY_THREAT_MODELS_ROUTE,
  PLANS_ROUTE,
  PROFILE_ROUTE,
  PROFILE_ROUTE_UPDATE_USERNAME,
  PROFILE_ROUTE_UPDATE_USER_AVATAR,
  PROJECTS_ARCHIVE_ROUTE,
  PROJECTS_CREATE_ROUTE,
  PROJECTS_UPDATE_ROUTE,
  PROJECT_DETAIL_ROUTE,
  PROJECT_DIAGRAMS_ROUTE,
  PROJECT_MITIGATIONS_ROUTE,
  PROJECT_RETROSPECTIVES_ROUTE,
  PROJECT_RUNNINGS_LIST_ROUTE,
  SETTINGS_ROUTE,
  TEAMS_ROUTE,
  TEAM_DETAIL_ROUTE,
  UI_KIT_PAGE_ROUTE,
  USERS_ROUTE,
  USER_ADD_ROUTE,
} from './store/constants/route-constants';

export interface Page {
  id: number;
  path: string;
  title: string;
  element: React.ReactElement;
  public?: boolean;
}

const routes: Page[] = [
  {
    id: 1,
    path: '/',
    title: 'HomePage',
    element: <HomePage />,
  },
  {
    id: 5,
    path: BILLING_ROUTE,
    title: 'BillingPage',
    element: <BillingPage />,
  },
  {
    id: 6,
    path: PROFILE_ROUTE,
    title: 'ProfilePage',
    element: <ProfilePage />,
  },
  {
    id: 7,
    path: PROFILE_ROUTE_UPDATE_USERNAME,
    title: 'UpdateUsernamePage',
    element: <UpdateUsernamePage />,
  },
  {
    id: 8,
    path: PROFILE_ROUTE_UPDATE_USER_AVATAR,
    title: 'UpdateUserAvatarPage',
    element: <UpdateUserAvatarPage />,
  },
  {
    id: 10,
    path: CONTACT_US_ROUTE,
    title: 'ContactUsPage',
    element: <ContactUsPage />,
  },
  {
    id: 11,
    path: PLANS_ROUTE,
    title: 'PlansPage',
    element: <PlansPage />,
  },
  {
    id: 12,
    path: CONFIRMATION_ROUTE,
    title: 'ConfirmationPage',
    element: <ConfirmationPage />,
  },
  {
    id: 14,
    path: PROJECT_DETAIL_ROUTE,
    title: 'ProjectsDetailPage',
    element: <ProjectDetailPage />,
  },
  {
    id: 15,
    path: PROJECT_DIAGRAMS_ROUTE,
    title: 'ThreatModelPage',
    element: <ThreatModelPage />,
  },
  {
    id: 17,
    path: PROJECT_MITIGATIONS_ROUTE,
    title: 'ThreatsPage',
    element: <MitigationsPage />,
  },
  {
    id: 18,
    path: PROJECT_RETROSPECTIVES_ROUTE,
    title: 'ThreatsPage',
    element: <RetrospectivesPage />,
  },
  {
    id: 19,
    path: TEAM_DETAIL_ROUTE,
    title: 'TeamDetailPage',
    element: <TeamDetailPage />,
  },
  {
    id: 20,
    path: USER_ADD_ROUTE,
    title: 'UserAddPage',
    element: <UserAddPage />,
  },
  {
    id: 21,
    path: DEBUG_PAGE_ROUTE,
    title: 'DebugPage',
    element: <DebugPage />,
    public: true,
  },
  {
    id: 22,
    path: TEAMS_ROUTE,
    title: 'TeamsPage',
    element: <TeamsPage />,
  },
  {
    id: 23,
    path: USERS_ROUTE,
    title: 'UsersPage',
    element: <UsersPage />,
  },
  {
    id: 24,
    path: SETTINGS_ROUTE,
    title: 'SettingsPage',
    element: <SettingsPage />,
  },
  {
    id: 25,
    path: UI_KIT_PAGE_ROUTE,
    title: 'UiKitPage',
    element: <UiKitPage />,
    public: true,
  },
  {
    id: 26,
    path: PROJECTS_CREATE_ROUTE,
    title: 'ProjectsCreateOrUpdatePage',
    element: <ProjectCreateOrUpdatePage />,
    public: true,
  },
  {
    id: 27,
    path: PROJECTS_UPDATE_ROUTE,
    title: 'ProjectsCreateOrUpdatePage',
    element: <ProjectCreateOrUpdatePage />,
    public: true,
  },
  {
    id: 28,
    path: PROJECTS_ARCHIVE_ROUTE,
    title: 'TeamsPage',
    element: <ArchivePage />,
  },
  {
    id: 29,
    path: MY_THREAT_MODELS_ROUTE,
    title: 'MyModelsPage',
    element: <MyModelsPage />,
  },
  {
    id: 30,
    path: CLI_THREAT_MODEL,
    title: 'CliThreatModelPage',
    element: <CliThreatModelPage />,
  },
  {
    id: 31,
    path: '/checkout-success',
    title: 'Checkout Success',
    element: <CheckoutSuccessPage />,
  },
  {
    id: 32,
    path: CODE_GENIUS_ROUTE,
    title: 'Code Genuis',
    element: <CodeGeniusPage />,
  },
  {
    id: 33,
    path: PROJECT_RUNNINGS_LIST_ROUTE,
    title: 'Project Runnings',
    element: <RunningListPage />,
  },
  {
    id: 34,
    path: `${ADMIN_ROUTE}/*`,
    title: 'Admin',
    element: <AdminPage />,
  },
];

export default routes;
