import type { ChangeEvent, FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import TextareaAutosize from 'react-textarea-autosize';
import { getColorDependsOnBackground } from '../../../helpers/colors';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from './CustomEdge.module.css';

type EdgeInputProps = {
  id: string;
  selected: boolean;
  value: string;
  color?: string;
  autoFontSize?: boolean;
  fontBold?: boolean;
  fontSize?: number;
  fontColor?: string;
};
let timer: any = null;

const EdgeInput: FC<EdgeInputProps> = ({ id, selected, value, color, autoFontSize, fontBold, fontSize, fontColor }) => {
  const dispatch = useDispatch<Dispatch>();
  const { diagramId } = useParams();
  const { edgesSharedState } = useTypedSelector((state) => state.diagram);
  const component = useTypedSelector((state) => state.drawn?.component);
  const editedComponentId = useTypedSelector((state) => state.diagram?.editedComponentId) || '';
  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setValue] = useState(value);
  const span = useRef<HTMLSpanElement | null>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setValue(component?.id === id ? component?.title : value);
  }, []);

  useEffect(() => {
    if (span?.current?.offsetWidth) setWidth(span.current.offsetWidth + 10);
  }, [inputValue]);

  useEffect(() => {
    if (!selected) setIsEdit(false);

    return () => {
      setValue(value);
    };
  }, [selected]);

  useEffect(() => {
    if (id === editedComponentId) setIsEdit(true);
  }, [editedComponentId]);

  const onChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);

    if (!event.target.value.length) return;

    clearTimeout(timer);
    timer = setTimeout(() => {
      if (event.target.value.length > 1 && component?.id) {
        dispatch.drawn.setComponent({
          ...component,
          title: event.target.value,
        });
        dispatch.drawn.updateComponent({
          id: component.id,
          description: component?.description,
          representationId: diagramId,
          title: event.target.value,
        });
        const currentEdge = edgesSharedState?.get(component.id);

        if (currentEdge) {
          currentEdge.data = {
            ...currentEdge.data,
            label: event.target.value,
          };
          edgesSharedState && edgesSharedState.set(id, currentEdge);
        }
      }
    }, 300);
  };

  if (isEdit && selected)
    return (
      <>
        <span
          className={styles.hideSpan}
          ref={span}
          style={{
            fontSize: autoFontSize ? '16cqw' : `${fontSize || 12}px`,
            fontWeight: `${fontBold ? '600' : '400'}`,
          }}
        >
          {inputValue}
        </span>
        <TextareaAutosize
          className={styles.edgeInput}
          onChange={onChangeHandler}
          value={inputValue}
          onFocus={(e: any) => {
            dispatch.diagram.setCanCopy(false);
            e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);

            if (span?.current?.offsetWidth) setWidth(span.current.offsetWidth + 10);
          }}
          onBlur={() => dispatch.diagram.setCanCopy(true)}
          rows={1}
          maxLength={64}
          minLength={1}
          required
          autoFocus
          style={{
            color: fontColor || (color ? getColorDependsOnBackground(color) : 'white'),
            fontSize: autoFontSize ? '16cqw' : `${fontSize || 12}px`,
            fontWeight: `${fontBold ? '600' : '400'}`,
            width: `${width}px`,
            maxWidth: fontSize && fontSize > 18 ? '400px' : '150px',
          }}
          data-id="custom-diagram-input"
        />
      </>
    );

  return (
    <div
      data-isedge={1}
      className={styles.edgeLabel}
      onDoubleClick={() => {
        setIsEdit(true);
      }}
      style={{
        color: fontColor || (color ? getColorDependsOnBackground(color) : 'white'),
        fontSize: autoFontSize ? '16cqw' : `${fontSize || 12}px`,
        fontWeight: `${fontBold ? '600' : '400'}`,
        maxWidth: fontSize && fontSize > 18 ? '400px' : '150px',
      }}
    >
      {value}
    </div>
  );
};

export default EdgeInput;
