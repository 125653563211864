import type { PropsWithChildren, ReactNode } from 'react';
import { useState } from 'react';
import { PlusSquareFill } from 'react-bootstrap-icons';
import { Collapse } from 'reactstrap';
import DashSquareFill from '../../assets/icons/DashSquareFill.svg';

type CollapseSectionType = PropsWithChildren & {
  title: ReactNode;
  defaultOpen?: boolean;
  closeIcon?: ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  openIcon?: ReactNode;
};

export const CollapseSection = ({
  children,
  title,
  defaultOpen = true,
  closeIcon = <img src={DashSquareFill} />,
  openIcon = <PlusSquareFill size={24} color="#0062D8" />,
  onOpen = () => {},
  onClose = () => {},
}: CollapseSectionType): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  const handleOpen = () => {
    setIsOpen(true);
    onOpen();
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <div>
      <div className="d-flex gap-3 justify-content-between">
        <div>{title}</div>
        <div role="button">
          {isOpen ? <div onClick={handleClose}>{closeIcon}</div> : <div onClick={handleOpen}>{openIcon}</div>}
        </div>
      </div>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
};
