import { useEffect, useState } from 'react';
import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';
import { getAccessToken, getSessionToken } from '../helpers/user';
import { useTypedSelector } from './useTypeSelector';

const socketUrl = process.env.REACT_APP_WS_API_URL;

const DELAY = 750;

export enum RoomTypes {
  DIAGRAM = 'diagram',
}

export enum ErrorEvents {
  ERROR = 'error',
  CONNECTION_ERROR = 'connection_error',
}

export enum UpdateTypes {
  GLOBAL = 'global_update',

  // related to the diagram:
  THREAT = 'threat_update',
  THREAT_REMOVE = 'threat_remove',
  COMPONENT = 'component_update',
  REGISTER = 'register_update',
  ELEMENT_REGISTER = 'element_register_update',
  ATTRIBUTE = 'attribute_update',
  ATTRIBUTE_REMOVE = 'attribute_remove',
  MITIGATION = 'mitigation_update',
  THREAT_COMMENT = 'threat_comment_update',
  COMPONENT_REMOVE = 'visual_component_remove',
  THREAT_MODEL_REVERTED = 'threat_model_reverted',
}

export interface IGlobalLiveUpdateData {
  projects?: string[];
  threatModels?: string[];
  archives?: string[];
}

export type EmitUpdate = (
  updateType: UpdateTypes,
  entityId: string | Record<string, string>,
  needDelay?: boolean,
  room?: RoomTypes,
) => void;

export type GlobalEmitUpdate = (data: IGlobalLiveUpdateData) => void;

type Rooms = Record<RoomTypes, string | undefined>;

let socket: Socket | null = null;

export function useSocket() {
  const { isAuthenticated } = useTypedSelector((state) => state.auth);
  const [rooms, setRooms] = useState<Rooms>({
    [RoomTypes.DIAGRAM]: '',
  });

  if (!socketUrl) {
    throw new Error('Ws api url not provided');
  }

  const connect = () => {
    if (!socket) {
      socket = io(socketUrl, {
        extraHeaders: {
          Authorization: `Bearer ${getAccessToken()}`,
          Session: `Bearer ${getSessionToken()}`,
        },
        reconnectionAttempts: 10,
      });

      return socket;
    }

    return socket;
  };

  useEffect(() => {
    if (isAuthenticated) {
      connect();
    }
  }, [isAuthenticated]);

  const joinRoom = (roomType: RoomTypes, roomId: string) => {
    const socket = connect();
    socket.emit('join_room', {
      type: roomType,
      room: roomId,
      auth: {
        authorization: `Bearer ${getAccessToken()}`,
        session: `Bearer ${getSessionToken()}`,
      },
    });
    setRooms((prev) => {
      prev[roomType] = roomId;

      return prev;
    });

    return socket;
  };

  const leaveRoom = (roomType: RoomTypes, roomId: string) => {
    socket?.emit('leave_room', { type: roomType, room: roomId });
    setRooms((prev) => {
      if (prev) {
        delete prev[roomType];
      }

      return prev;
    });
  };

  const emitUpdate: EmitUpdate = (updateType, entityId, needDelay = false, room = RoomTypes.DIAGRAM) => {
    if (socket) {
      const roomId = rooms[room];

      if (roomId) {
        const dataToSend = {
          id: typeof entityId === 'object' ? JSON.stringify(entityId) : entityId,
          room: roomId,
          type: room,
        };
        setTimeout(() => socket?.emit(updateType, dataToSend), needDelay ? DELAY : 0);
      }
    }
  };

  const emitGlobalUpdate: GlobalEmitUpdate = (dataToSend: IGlobalLiveUpdateData, needDelay = true) => {
    if (socket && Object.keys(dataToSend).length) {
      setTimeout(() => socket?.emit(UpdateTypes.GLOBAL, dataToSend), needDelay ? DELAY : 0);
    }
  };

  const disconnect = () => {
    socket?.disconnect();
    socket = null;
  };

  return {
    socket,
    joinRoom,
    leaveRoom,
    emitUpdate,
    disconnect,
    emitGlobalUpdate,
  };
}
