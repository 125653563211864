import classNames from 'classnames';
import type { ChangeEvent, FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { CodeSlash, XLg } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import ReactTextareaAutosize from 'react-textarea-autosize';
import type { EmitUpdate } from '../../../hooks/useSocket';
import { UpdateTypes } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { PROJECTS_ROUTE, THREATS_REGISTER_ROUTE } from '../../../store/constants/route-constants';
import { THREATS_REGISTER_SELECTED_CLASS } from '../../../store/constants/threat-register-constants';
import type { IThreat } from '../../../store/models/drawn';
import { initialComponent } from '../../../store/models/drawn';
import type { Dispatch } from '../../../store/store';
import CommentInput from '../../Comments/CommentInput/CommentInput';
import CommentList from '../../Comments/CommentList/CommentList';
import MitigationItem from '../../Diagram/MitigationItem';
import { ThreatHistory } from '../../Diagram/ThreatHistory/ThreatHistory';
import { MitigationSearch } from '../../Mitigations/MitigationSearch/MitigationSearch';
import { DetailResize } from '../DetailResize';
import { ThreatRegisterDrawer } from '../ThreatRegisterDrawer/ThreatRegisterDrawer';
import styles from './ThreatRegisterDetail.module.css';

let timer: any = null;

type ThreatRegisterProps = {
  emitUpdate: EmitUpdate;
  isElementRegister?: boolean;
  isFilterActive?: boolean;
};

export const ThreatRegisterDetail: FC<ThreatRegisterProps> = ({
  emitUpdate,
  isElementRegister = false,
  isFilterActive = false,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const {
    commentData,
    commentLimit,
    threat,
    forceUpdateThreatId,
    forceUpdateForCommentsByThreatId,
    forceRemoveThreatId,
    visualRemoveComponentId,
    forceUpdateMitigationId,
  } = useTypedSelector((state) => state.drawn);
  const [wereCommentsUpdated, setWereCommentsUpdated] = useState(false);
  const scrollPoint = useRef(null);
  const navigationUrl = isElementRegister
    ? `${PROJECTS_ROUTE}/${params.id}/d/${params.diagramId}${THREATS_REGISTER_ROUTE}/element`
    : `${PROJECTS_ROUTE}/${params.id}/d/${params.diagramId}${THREATS_REGISTER_ROUTE}`;
  const threatModelId = useTypedSelector((state) => state.threatModel?.currentThreatModel?.id);
  const { threatRegisterListQuery, groupedThreatRegisterListQuery } = useTypedSelector((state) => state.threats);

  useEffect(() => {
    if (params.threatsId?.length) {
      dispatch.drawn.getThreat(params.threatsId);
      dispatch.drawn.getCommentsByThreatId({
        threatId: params.threatsId,
        limit: commentLimit,
      });
    }

    return () => {
      dispatch.drawn.clearPrevTreatState();
      dispatch.diagram.setMitigations(null);
    };
  }, [params.threatsId]);

  // triggered when comment's was updated
  useEffect(() => {
    if (wereCommentsUpdated) {
      setWereCommentsUpdated(false);
    }
  }, [wereCommentsUpdated]);

  // triggered when threat was updated
  useEffect(() => {
    if (params.threatsId && forceUpdateThreatId && params.threatsId === forceUpdateThreatId) {
      dispatch.drawn.getThreat(params.threatsId).then((res) => {
        dispatch.threats.setThreatRegisterSelectedThreat({
          title: res.title,
          description: res.description,
        });
      });
    }

    dispatch.drawn.setForceUpdateThreatId(null);
  }, [forceUpdateThreatId]);

  // triggered when threat was deleted
  useEffect(() => {
    if (params.threatsId === forceRemoveThreatId) {
      toast.error('Threat deleted!');
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleClose();
    }

    dispatch.drawn.setForceRemoveThreatId(null);
  }, [forceRemoveThreatId]);

  // triggered when component was deleted
  useEffect(() => {
    if (threat && threat?.component?.id === visualRemoveComponentId) {
      toast.error('Component with this threat was deleted!');
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleClose();
    }

    dispatch.drawn.setVisualRemoveComponentId(null);
  }, [visualRemoveComponentId]);

  // triggered when comments was updated
  useEffect(() => {
    if (params.threatsId && forceUpdateForCommentsByThreatId && params.threatsId === forceUpdateForCommentsByThreatId) {
      dispatch.drawn.getCommentsByThreatId({
        threatId: params.threatsId,
        limit: commentLimit + 1,
      });
      setWereCommentsUpdated(true);
    }

    dispatch.drawn.setForceUpdateForCommentsByThreatId(null);
  }, [forceUpdateForCommentsByThreatId]);

  // triggered when mitigation was updated
  useEffect(() => {
    if (threat.id === params.threatsId && threat?.mitigations?.some((i) => i.id === forceUpdateMitigationId)) {
      dispatch.drawn.getThreat(params.threatsId);
    }

    dispatch.drawn.setForceUpdateMitigationId(null);
  }, [forceUpdateMitigationId]);

  const handleEmitAfterUpdate = (needComponentUpdate = true) => {
    if (params.threatsId && emitUpdate) {
      emitUpdate(UpdateTypes.THREAT, params.threatsId, true);

      if (threat?.component?.id && needComponentUpdate) {
        emitUpdate(UpdateTypes.COMPONENT, threat.component.id, true);
      }
    }
  };

  const fetchData = ({ title, description }: Partial<IThreat>) => {
    if (!threatModelId) return;

    if (title && description) {
      dispatch.threats.setThreatRegisterSelectedThreat({
        title,
        description,
      });
      dispatch.threats.getGroupedThreatRegisterList({
        ...groupedThreatRegisterListQuery,
        threatModelId,
      });
      dispatch.threats.getThreatRegisterList({
        ...threatRegisterListQuery,
        threatModelId,
        threatTitle: title,
        threatDescription: description,
      });
      dispatch.threats.setThreatRegisterSelectedThreat({
        title,
        description,
      });
    }
  };

  const executeScroll = () => {
    setTimeout(() => {
      if (scrollPoint?.current) {
        // @ts-ignore
        scrollPoint?.current?.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
        });
      }
    }, 777);
  };

  const handleMoreCommentClick = (increaseBy: number) => {
    dispatch.drawn.getCommentsByThreatId({
      threatId: params.threatsId,
      limit: commentLimit + increaseBy,
    });
  };

  const deselectElements = () => {
    document.querySelectorAll(`.${THREATS_REGISTER_SELECTED_CLASS}`).forEach((el) => {
      if (el.classList.contains(THREATS_REGISTER_SELECTED_CLASS)) {
        el.classList.remove(THREATS_REGISTER_SELECTED_CLASS);
      }
    });
  };

  const handleClose = () => {
    deselectElements();
    dispatch.drawn.setComponent(initialComponent);
    navigate(navigationUrl);
  };

  const handleChangeThreat = async (value: string, field: string) => {
    dispatch.drawn.updateThreat({
      id: threat.id,
      [field]: value,
    });
    handleEmitAfterUpdate();
    await dispatch.threatModel.updateCurrentThreatModel();
    fetchData({
      title: threat.title,
      description: threat.description,
      [field]: value,
    });
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>, field: string) => {
    dispatch.drawn.setThreat({
      ...threat,
      [field]: event.target.value,
    });
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (event.target.value.length || field === 'description') handleChangeThreat(event.target.value, field);
    }, 1200);
  };

  const handleRemoveMitigation = (mitigationId: string) => {
    const updatedMitigations = threat?.mitigations?.filter((i: any) => i.id !== mitigationId);
    dispatch.threats.updateThreatFromThreatRegisterList({
      id: threat.id,
      mitigations: updatedMitigations,
    });
    dispatch.threatModel.updateCurrentThreatModel();
    navigate(`${navigationUrl}/${threat.id}`);
  };

  return (
    <div className={styles.wrap} style={{ left: isFilterActive ? '185px' : 0 }}>
      <DetailResize loading={!threat?.id}>
        <div className={styles.leftSide}>
          <div className="mt-2 mb-2 px-2 d-flex justify-content-between gap-3 position-relative">
            {threat.id && (
              <>
                <ReactTextareaAutosize
                  id="Title"
                  placeholder="Enter title"
                  onFocus={() => dispatch.diagram.setCanCopy(false)}
                  onBlur={() => dispatch.diagram.setCanCopy(true)}
                  onChange={(e: any) => onChange(e, 'title')}
                  value={threat.title}
                  className={classNames(styles.rightbarTextAreaTitle, {
                    [styles.errorInput]: !threat.title?.length && threat.id,
                  })}
                />
                {!threat.title?.length && threat.id && <div className={styles.titleErrorText}>Required</div>}
              </>
            )}
            <div className="d-flex gap-3">
              {threat?.component?.resource_url && (
                <a
                  className={styles.showInCode}
                  target="_blank"
                  rel="noreferrer"
                  href={threat?.component?.resource_url}
                >
                  <CodeSlash size={18} className="me-1" /> Show in Code
                </a>
              )}
              <div className={styles.closeIcon} onClick={handleClose}>
                <XLg size={16} color="#0062D8" />
              </div>
            </div>
          </div>

          <div className="d-flex px-2">
            <ReactTextareaAutosize
              id="Description"
              placeholder="Enter description"
              onFocus={() => dispatch.diagram.setCanCopy(false)}
              onBlur={() => dispatch.diagram.setCanCopy(true)}
              onChange={(e: any) => onChange(e, 'description')}
              value={threat.description}
              className={styles.rightbarTextAreaDescription}
            />
          </div>
          {!!threat.caused_by?.length && (
            <div className={classNames('d-flex flex-wrap mt-2 px-2 gap-1', styles.causedBy)}>
              <span className={styles.causedByTitle}>Caused by:</span>
              {threat.caused_by?.map((att) => (
                <div key={att.id} className={styles.causedByAttributes}>
                  {att.title}
                </div>
              ))}
            </div>
          )}
          <div className="d-flex mt-3 px-2">
            <span className={styles.causedByTitle}>Mitigations:</span>
            <div style={{ width: 'calc(100% - 100px)' }}>
              <button type="button" className={styles.mitigationBtn} onClick={() => navigate('explorer')}>
                Explorer
              </button>
              {!!threat?.mitigations?.length && (
                <button type="button" className={styles.mitigationBtn} onClick={() => navigate('checklist')}>
                  Checklist
                </button>
              )}
              <div className="d-flex flex-wrap align-items-center gap-1 mt-2 mb-2">
                {threat?.mitigations?.map((mitigation) => (
                  <MitigationItem
                    key={mitigation.id}
                    data={mitigation}
                    onRemove={(e, isOk) => {
                      if (mitigation.id && isOk) handleRemoveMitigation(mitigation.id);

                      handleEmitAfterUpdate(e);
                      emitUpdate && mitigation.id && emitUpdate(UpdateTypes.MITIGATION, mitigation.id, true);
                    }}
                  />
                ))}
                {threat.component?.id && (
                  <MitigationSearch
                    componentId={threat.component?.id}
                    isThreatRegister
                    handleEmit={() => {
                      handleEmitAfterUpdate(true);
                      emitUpdate && threat.id && emitUpdate(UpdateTypes.THREAT, threat.id, true);
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="px-2">
            <ThreatHistory threat={threat} />
          </div>
          {!!commentData.items.length && (
            <div className="mt-2">
              <CommentList commentData={commentData} onMoreCommentsClick={handleMoreCommentClick} />
              <span ref={scrollPoint} />
            </div>
          )}
        </div>
        <div id="threat-register-rightside" className={styles.rightSide}>
          <ThreatRegisterDrawer emitUpdate={emitUpdate} />
        </div>
      </DetailResize>
      <CommentInput
        threat={threat}
        emitUpdate={() => {}}
        onScroll={executeScroll}
        needScroll={wereCommentsUpdated}
        hideAvatar
        size={{
          minHeight: 55,
          maxHeight: 120,
          paddingLeft: 0,
        }}
      />
    </div>
  );
};
