import classNames from 'classnames';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { ChevronRight } from 'react-bootstrap-icons';
import { Link, useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import { ADMIN_ROUTE } from '../../store/constants/route-constants';
import ProjectAvatar from '../common/ProjectAvatar/ProjectAvatar';
import styles from './Sidebar.module.css';

type MenuItemProps = {
  item: any;
  onClick: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const MenuItem: FC<MenuItemProps> = ({ item, onClick, ...props }) => {
  const showSidebar = useTypedSelector((state) => state.app?.sidebarVisible);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentProjectId = useTypedSelector((state) => state.project?.current?.id);
  const { projects } = useTypedSelector((state) => state.project);
  const location = useLocation();

  useEffect(() => {
    if (!showSidebar) setOpen(false);
  }, [showSidebar]);

  const isLocationActive = (route: string) => {
    if (location.pathname.includes(ADMIN_ROUTE)) {
      return location.pathname === route;
    }

    if (route === '/' && location?.pathname.startsWith('/collections')) {
      return route === '/' || location.pathname.startsWith('/collections');
    }

    return location.pathname.substring(0, 3) === route?.substring(0, 3);
  };

  return (
    <>
      <div
        onClick={() => {
          onClick();

          if (showSidebar) setOpen(!open);
        }}
        className={classNames(styles.item, {
          [styles.activeItem]: isLocationActive(item.route),
          [styles.hoverItem]: !showSidebar,
        })}
        data-tooltip={item.title}
        data-testid={`sidebar-item-${item.title.toLowerCase()}`}
        {...props}
      >
        {item.icon}
        <div
          style={{
            opacity: showSidebar ? 1 : 0,
            transition: 'opacity 0.3s cubic-bezier(0.4, 0, 1, 1) 0s',
          }}
        >
          {item.title}
        </div>
        {item?.children ? (
          <div
            className={styles.itemToggleIcon}
            style={{
              opacity: showSidebar ? 1 : 0,
              transition: !showSidebar ? 'opacity 0.3s cubic-bezier(0, 0, 0, 1.17) 0s' : 'opacity 0.3s ease-out 0s',
            }}
          >
            {!loading && (
              <ChevronRight
                onClick={async (e) => {
                  e.stopPropagation();

                  if (!showSidebar) {
                    onClick();

                    return;
                  }

                  if (!open && !projects && item.fetchChildren) {
                    setLoading(true);
                    await item.fetchChildren(false);
                    setLoading(false);
                  }

                  setOpen(!open);
                }}
                size={16}
                className={classNames({ [styles.itemToggleIconOpen]: open })}
              />
            )}
            {loading && <Spinner color="light" size="sm" />}
          </div>
        ) : null}
      </div>
      {open
        ? item?.children?.map((ch: any, idx: number) => (
            <Link
              key={ch.id}
              className={classNames(styles.itemToggleChild, {
                [styles.itemToggleChildActive]: currentProjectId === ch.id,
                [styles.itemToggleChildLast]: item.children.length - 1 === idx,
                [styles.itemToggleChildFirst]: idx === 0,
              })}
              to={item?.forAdmin ? `${ch.id}` : `/collections/${ch.id}`}
            >
              {!item?.forAdmin && <ProjectAvatar title={ch.title} color={ch?.color || '#C4C4C4'} noHover size={10} />}
              <div className={styles.itemToggleChildLabel}>{ch.title}</div>
            </Link>
          ))
        : null}
    </>
  );
};

export default MenuItem;
