import classNames from 'classnames';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import logo from '../../assets/images/devici-logo-light.svg';
import deviciLogo from '../../assets/images/devici-logo.png';
import PrivacyAndTerms from '../../components/common/PrivacyAndTerms';
import UiButton from '../../components/common/UIButton/UiButton';
import { getIsAuthenticated } from '../../helpers/user';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import { SIGN_IN_ROUTE } from '../../store/constants/route-constants';
import type { Dispatch } from '../../store/store';
import styles from './index.module.css';

const CLIAuthPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const isCLIAuth = useTypedSelector((state) => state.auth?.isCLIAuth);
  const isLoggedIn = getIsAuthenticated();

  useEffect(() => {
    if (isLoggedIn && token?.length) dispatch.user.registerCLIToken(token);
  }, []);

  const handleLogin = async () => {
    if (!token?.length) {
      toast.warning('CLI token not found!');

      return;
    }

    dispatch.auth.setIsCLIAuth(true);
    navigate(SIGN_IN_ROUTE);
  };

  return (
    <div className={classNames('d-flex align-items-center justify-content-center h-100', styles.wrapper)}>
      <div
        className={classNames(
          styles.header,
          'position-absolute start-0 end-0 top-0 w-100 d-flex align-items-center justify-content-start',
        )}
      >
        <Link to="/">
          <div>
            <img src={logo} alt="logo" />
          </div>
        </Link>
      </div>
      <div className={classNames('bg-white text-center', styles.card)}>
        <div className={styles.logo}>
          <img src={deviciLogo} alt="Devici logo" />
        </div>
        {isCLIAuth ? (
          <h5>
            You can close this page and return to CLI.
            <br /> It should now be logged in.
          </h5>
        ) : (
          <>
            <h5>Log in to the Devici CLI</h5>
            <UiButton disabled={!token?.length} onClick={handleLogin}>
              Log In
            </UiButton>
          </>
        )}
        <PrivacyAndTerms />
      </div>
    </div>
  );
};

export default CLIAuthPage;
