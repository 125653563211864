import classNames from 'classnames';
import type { FC } from 'react';
import RandomAvatar from '../RandomAvatar/RandomAvatar';
import styles from './ProjectAvatar.module.css';

type ProjectAvatarProps = {
  img?: string;
  title: string;
  active?: boolean;
  noHover?: boolean;
  size?: number;
  color?: string;
};
const ProjectAvatar: FC<ProjectAvatarProps> = ({ img, title, active = false, noHover = false, size = 40, color }) => {
  if (color)
    return (
      <div
        data-testid="collection-avatar"
        className={styles.projectAvatarWrap}
        style={{
          background: color,
          width: size,
          height: size,
          borderRadius: '8px',
        }}
      />
    );

  return (
    <div
      className={styles.projectAvatarWrap}
      style={{ cursor: noHover ? 'unset' : 'pointer' }}
      data-testid="collection-avatar"
    >
      {img ? (
        <img
          className={classNames(styles.projectAvatar, {
            [styles.active]: active,
            [styles.noHover]: noHover,
          })}
          src={img}
          alt={title}
        />
      ) : (
        <span
          className={classNames(styles.projectAvatar, {
            [styles.active]: active,
            [styles.noHover]: noHover,
          })}
        >
          <RandomAvatar username={title} saturation="90" width={size.toString()} height={size.toString()} />
        </span>
      )}
    </div>
  );
};

export default ProjectAvatar;
