import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import { Check2All, Clipboard2X, ClockHistory, XLg } from 'react-bootstrap-icons';
import { Spinner } from 'reactstrap';
import { MITIGATION_STATUSES } from '../../../global/constants';
import type { IMitigationStatus } from '../../../global/types';
import { MITIGATION_STATUS_TEXT } from '../../../store/constants/drawn-constants';
import styles from './UiCheck.module.css';

type UiCheckProps = {
  status: IMitigationStatus;
  isActive: boolean;
  onClick?: any;
  isLoading?: boolean;
};

const UiCheck: FC<UiCheckProps> = ({ status, isActive, onClick, isLoading = false }) => {
  const [popUpVisible, setPopUpVisible] = useState(false);

  const handleMouseEnter = () => {
    setPopUpVisible(true);
  };

  const handleMouseLeave = () => {
    setPopUpVisible(false);
  };

  return (
    <div className={styles.container}>
      {isLoading && (
        <div className={styles.itemWrapper}>
          {' '}
          <Spinner size="sm" className={styles.spinner} />{' '}
        </div>
      )}
      {!isLoading && (
        <>
          {status === MITIGATION_STATUSES.DONE && (
            <div className={styles.itemWrapper}>
              <div
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter}
                onClick={onClick}
                className={classNames(styles.item, styles.itemDone, {
                  [styles.itemDoneActive]: isActive,
                })}
              >
                <Check2All color="#34A853" fontSize={15} />
                {popUpVisible && (
                  <div className={styles.popUp}>
                    <span className={styles.triangle} />
                    {MITIGATION_STATUS_TEXT.COMPLETE}
                  </div>
                )}
              </div>
            </div>
          )}
          {status === MITIGATION_STATUSES.IN_FUTURE && (
            <div className={styles.itemWrapper}>
              <div
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter}
                onClick={onClick}
                className={classNames(styles.item, styles.itemWill, {
                  [styles.itemWillActive]: isActive,
                })}
              >
                <ClockHistory color="#FAAD14" fontSize={15} />
                {popUpVisible && (
                  <div className={styles.popUp}>
                    <span className={styles.triangle} />
                    {MITIGATION_STATUS_TEXT.QUEUED_FOR_ACTION}
                  </div>
                )}
              </div>
            </div>
          )}
          {status === MITIGATION_STATUSES.NEVER && (
            <div className={styles.itemWrapper}>
              <div
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter}
                onClick={onClick}
                className={classNames(styles.item, styles.itemNever, {
                  [styles.itemNeverActive]: isActive,
                })}
              >
                <Clipboard2X color="#EA4335" fontSize={15} />
                {popUpVisible && (
                  <div className={styles.popUp}>
                    <span className={styles.triangle} />
                    {MITIGATION_STATUS_TEXT.NOT_APPLICABLE}
                  </div>
                )}
              </div>
            </div>
          )}
          {status === null && (
            <div className={styles.itemWrapper}>
              <div
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter}
                onClick={onClick}
                className={classNames(styles.item, styles.itemDefault, {
                  [styles.itemDefaultActive]: isActive,
                })}
              >
                <XLg color="#EA4335" fontSize={15} />
                {popUpVisible && (
                  <div className={styles.popUp}>
                    <span className={styles.triangle} />
                    {MITIGATION_STATUS_TEXT.UNCLASSIFIED}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UiCheck;
