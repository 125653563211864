import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import { XLg } from 'react-bootstrap-icons';
import BigCheckIcon from '../../assets/icons/BigCheckIcon.svg';
import { SKELETON_COLORS } from '../../global/constants';
import type { User } from '../../global/types';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';
import UiButton from '../common/UIButton/UiButton';
import UsersDropdown from '../ThreatModels/UsersDropdown';
import UserComponent from '../User/User';
import styles from './UserRightBar.module.css';

type UserRightBarProps = {
  visible: boolean;
  setVisible: (isVisible: boolean) => void;
};

const UserRightBar: FC<UserRightBarProps> = ({ visible = false, setVisible }) => {
  const dispatch = useDispatch<Dispatch>();
  const deletingUser = useTypedSelector((state) => state.user.removedUserInfo);
  const users = useTypedSelector((state) => state.user.users);
  const [usersPopupVisible, setUsersPopupVisible] = useState(false);
  const [newUser, setNewUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const cantDelete = !deletingUser?.id || !newUser?.id || newUser.id === deletingUser.id;

  useEffect(() => {
    if (!newUser && deletingUser?.id) {
      const defaultUser = users?.filter((i) => i.id !== deletingUser.id)?.at(0);

      if (defaultUser) setNewUser(defaultUser);
    }
  }, [deletingUser]);

  const onCancel = () => {
    setVisible(false);
    const timeout = setTimeout(() => {
      setNewUser(null);
      setStep(1);
      dispatch.user.setRemovedUserInfo({});
      clearTimeout(timeout);
    }, 400);
  };

  const handleDeleteUser = async () => {
    if (cantDelete) return;

    setLoading(true);
    const result = await dispatch.user.removeUser({
      deletingUser: deletingUser.id,
      newUserId: newUser.id,
    });
    setLoading(false);

    if (result === 200) {
      setStep(0);
      await dispatch.user.getUsers({});
      await dispatch.tiers.getCustomerLimits();
    }
  };

  const title = `${deletingUser?.first_name} ${deletingUser?.last_name} has ${
    deletingUser?.projectsCount || 0
  } collections and ${deletingUser?.threatModelsCount || 0} threat models.`;

  const renderContent = () => {
    switch (step) {
      case 1: {
        const stepOneText = `Please reassign these assets to another user before deleting ${deletingUser?.first_name} ${deletingUser?.last_name}`;

        return (
          <>
            {deletingUser?.id ? (
              <>
                <h4 className={styles.title}>{title}</h4>
                <div className={styles.text}>{stepOneText}</div>
              </>
            ) : (
              <Skeleton
                style={{ lineHeight: '25px' }}
                width={379}
                height={62}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
            )}

            <div
              className={classNames(
                'd-flex align-items-center justify-content-between position-relative cursor-pointer my-3 mb-4',
                styles.userAvatar,
              )}
              onClick={(e) => setUsersPopupVisible(true)}
              onMouseLeave={(e) => setUsersPopupVisible(false)}
            >
              {newUser ? (
                <UserComponent noHover user={newUser} showPopUpOnHover={false} />
              ) : (
                <Skeleton
                  width={380}
                  height={45}
                  baseColor={SKELETON_COLORS.BASE}
                  highlightColor={SKELETON_COLORS.HIGHLIGHT}
                />
              )}

              {usersPopupVisible && (
                <UsersDropdown
                  hide={() => {
                    setUsersPopupVisible(false);
                  }}
                  onSelect={(userData) => {
                    setNewUser(userData);
                    setUsersPopupVisible(false);
                  }}
                  exclude={deletingUser.id}
                />
              )}
            </div>
            <div className="d-flex justify-content-end">
              {newUser ? (
                <UiButton onClick={() => setStep(2)}>Go to the next step</UiButton>
              ) : (
                <Skeleton
                  width={380}
                  height={40}
                  baseColor={SKELETON_COLORS.BASE}
                  highlightColor={SKELETON_COLORS.HIGHLIGHT}
                />
              )}
            </div>
          </>
        );
      }
      case 2: {
        const stepTwoText = `Once a user is deleted from the system, they cannot be restored. Are you sure you want to delete the user ${deletingUser?.first_name} ${deletingUser?.last_name}?`;

        return (
          <>
            <h4 className={styles.title}>Warning! This action is permanent.</h4>
            <div className={styles.text}>{stepTwoText}</div>

            <div className="d-flex justify-content-between mt-4">
              <UiButton onClick={() => setStep(1)} type="transparent">
                Back
              </UiButton>
              <UiButton onClick={handleDeleteUser} disabled={cantDelete}>
                {loading ? <Spinner color="light" size="sm" /> : 'Delete User'}
              </UiButton>
            </div>
          </>
        );
      }

      case 0: {
        const stepThreeText = `User ${deletingUser?.first_name} ${deletingUser?.last_name} has been successfully deleted.`;

        return (
          <div className="text-center m-auto mt-5">
            <img src={BigCheckIcon} />
            <h4 className={styles.title2}>{stepThreeText}</h4>
          </div>
        );
      }

      default:
        return null;
    }
  };

  return (
    <div
      className={styles.userRightBarWrap}
      style={{
        right: visible ? 0 : -420,
      }}
    >
      <OutsideClickHandler onOutsideClick={onCancel} disabled={!visible}>
        <div className={styles.userRightBar}>
          <div className={classNames('text-end', styles.closeBtn)} onClick={onCancel}>
            <XLg size={34} />
          </div>
          {!!step && (
            <div className={styles.stepsWrap}>
              {[1, 2].map((st) => (
                <div
                  key={st}
                  className={classNames('user-select-none', {
                    [styles.activeStep]: step === st,
                    [styles.prevStep]: step === 2 && st !== step,
                  })}
                  onClick={() => {
                    if (step === 2 && st !== step) {
                      setStep(1);
                    }
                  }}
                >
                  {st}
                </div>
              ))}
              <span />
            </div>
          )}
          {renderContent()}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default UserRightBar;
