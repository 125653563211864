import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Spinner } from 'reactstrap';
import { SKELETON_COLORS } from '../../../global/constants';

import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { DEFAULT_CUSTOM_CODEX_PAGINATION_PARAMS } from '../../../store/constants/custom-codex-constants';
import type { Dispatch } from '../../../store/store';
import UiButton from '../../common/UIButton/UiButton';
import UiPriority from '../../common/UiPriority/UiPriority';
import styles from './CustomCodexTable.module.css';
import { SearchInput } from './SearchInput';
import { ThreatsPriorityFilter } from './ThreatsPriorityFilter';
import { UploadCsvModal } from './UploadCsvModal';

export const CustomCodexThreatsTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { threats, query } = useTypedSelector((state) => state.customCodex);
  const { drawnWidth, visible } = useTypedSelector((state) => state.drawn);
  const [showFileModal, setShowFileModal] = useState(false);
  const [loading, setLoading] = useState('');
  const width = visible ? `calc(100vw - ${drawnWidth + 65 || 0}px)` : '100%';
  const canFetchMore = query.page * query.limit < query.count;

  useEffect(() => {
    if (!threats) {
      dispatch.customCodex.getCustomThreats(DEFAULT_CUSTOM_CODEX_PAGINATION_PARAMS);
    }

    if (visible) dispatch.drawn.setVisible(false);

    return () => {
      dispatch.customCodex.setThreats(null);
    };
  }, []);

  const fetchMoreData = async () => {
    const nextPage = query.page + 1;

    if (nextPage * query.limit < query.count) {
      setLoading('scroll');
      await dispatch.customCodex.getCustomThreats({
        ...query,
        page: nextPage,
      });
      setLoading('');
    }
  };

  const sortByName = async (sort: string) => {
    setLoading(sort);
    await dispatch.customCodex.getCustomThreats({
      ...query,
      sort,
      order: query.order === 'ASC' ? 'DESC' : 'ASC',
      page: 0,
    });
    setLoading('');
  };

  const renderThreats = (threat: any) => {
    return (
      <tr
        key={threat.id}
        onClick={() => {
          dispatch.drawn.setVisible(true);
          navigate(threat.id);
        }}
        className="cursor-pointer"
      >
        <td className={styles.descriptionCol}>{threat.title}</td>
        <td
          className={classNames(styles.descriptionCol, {
            [styles.hasTooltip]: threat?.description?.length > 100,
          })}
          data-tooltip={threat.description}
        >
          {threat.description}
        </td>
        <td className="p-0">
          <div className={styles.priorityCel}>
            <UiPriority type={threat.priority} size="smallItem" />
          </div>
        </td>
        <td className="text-center">{moment(threat.created_at).format('MM.DD.YYYY')}</td>
      </tr>
    );
  };

  return (
    <div id="scrollableTargetTable" className={styles.tableWrap} style={{ width }}>
      <h3 className={styles.header}>Codex: Threats</h3>
      <div className="d-flex justify-content-between align-items-center" style={{ zIndex: 2 }}>
        <div className="d-flex gap-3 align-items-center fs-12 position-relative  mb-2">
          <UiButton
            onClick={() => {
              navigate('new');
              dispatch.drawn.setVisible(true);
            }}
          >
            Add Threat
          </UiButton>
          <SearchInput type="threats" />
          <ThreatsPriorityFilter />
        </div>
        {/* // TODO: need uncoment when upload by csv will be done
        <div>
          <UiButton onClick={() => setShowFileModal(true)} type="transparent" className="text-black">
            <Upload size={16} className="me-2" /> Import File
          </UiButton>
        </div> */}
      </div>

      <div className={styles.tableScroll} id="scrollableTargetDiv">
        {threats === null && (
          <Skeleton
            style={{ lineHeight: '25px', marginBottom: '5px' }}
            width="100%"
            height={30}
            count={15}
            borderRadius={10}
            baseColor={SKELETON_COLORS.BASE}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
          />
        )}
        {!!threats && (
          <InfiniteScroll
            scrollableTarget="scrollableTargetDiv"
            next={fetchMoreData}
            hasMore={canFetchMore}
            loader={
              loading === 'scroll' ? (
                <div className="w-100 m-auto text-center">
                  <Spinner color="dark" />
                </div>
              ) : null
            }
            dataLength={threats?.length || 0}
          >
            <table className={styles.table}>
              <thead className="position-sticky top-0 z-1">
                <tr>
                  <th style={{ width: '25%' }} className={styles.tableHead}>
                    Name
                    {loading === 'title' ? (
                      <div className={styles.spinner}>
                        <Spinner size="sm" />
                      </div>
                    ) : (
                      <div className={styles.sortIconsWrap} onClick={() => sortByName('title')}>
                        <CaretUpFill className={styles.tableSortIconUp} />
                        <CaretDownFill className={styles.tableSortIconDown} />
                      </div>
                    )}
                  </th>
                  <th style={{ width: '50%' }} className={styles.tableHead}>
                    Description
                  </th>
                  <th style={{ width: '10%' }} className={styles.tableHead}>
                    Priority
                    {loading === 'priority' ? (
                      <div className={styles.spinner}>
                        <Spinner size="sm" />
                      </div>
                    ) : (
                      <div className={styles.sortIconsWrap} onClick={() => sortByName('priority')}>
                        <CaretUpFill className={styles.tableSortIconUp} />
                        <CaretDownFill className={styles.tableSortIconDown} />
                      </div>
                    )}
                  </th>
                  <th style={{ width: '15%' }} className={styles.tableHead}>
                    Created At
                    {loading === 'created_at' ? (
                      <div className={styles.spinner}>
                        <Spinner size="sm" />
                      </div>
                    ) : (
                      <div className={styles.sortIconsWrap} onClick={() => sortByName('created_at')}>
                        <CaretUpFill className={styles.tableSortIconUp} />
                        <CaretDownFill className={styles.tableSortIconDown} />
                      </div>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody id="scrollableTargetTable">
                {threats?.length ? (
                  threats?.map(renderThreats)
                ) : (
                  <tr className="cursor-default">
                    <td className="text-center" colSpan={6}>
                      No threats
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        )}
      </div>
      {showFileModal && <UploadCsvModal onClose={() => setShowFileModal(false)} />}
    </div>
  );
};
