import type { Params } from '@sentry/react/types/types';

export const toPascalCase = (value: string) => {
  return value.replaceAll('_', ' ').replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
};

export const openFullscreen = (elem: any) => {
  try {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }

    const sidebar = document.getElementById('sidebar-element');

    if (sidebar) sidebar.style.display = 'none';

    const content = document.getElementById('content-element');

    if (content) content.style.marginLeft = '0';
  } catch (e: any) {
    console.error(e.message);
  }
};

export const closeFullscreen = (document: any) => {
  try {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }

    const sidebar = document.getElementById('sidebar-element');

    if (sidebar) sidebar.style.display = 'block';
  } catch (error: any) {
    console.error(error.message);
  }
};

const calculateLevenshtein = (a: string, b: string): number => {
  return Math.abs(a.length - b.length);
};

export const sortAttributesBySearch = (attributes: any[], search: string) => {
  return (
    attributes?.sort((a: any, b: any) => {
      const distanceA = calculateLevenshtein(a.title, search);
      const distanceB = calculateLevenshtein(b.title, search);

      return distanceA - distanceB;
    }) || []
  );
};

export const getTableHeight = (params: Readonly<Params<string>>, height: number) => {
  if (!params?.['*']?.length) return 'calc(100vh - 125px)';

  const newHeight = document.body.offsetHeight - height - 195;

  return `${newHeight}px`;
};

export function toggleElementInArray<T>(array: T[], element: T): T[] {
  const index = array.indexOf(element);

  if (index !== -1) {
    // Element exists, remove it
    return [...array.slice(0, index), ...array.slice(index + 1)];
  }

  // Element doesn't exist, insert it
  return [...array, element];
}

export function getReadableFileSizeString(fileSizeInBytes: number) {
  let i = -1;
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes /= 1024;
    i += 1;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export const getFileFromMetadataXml = (xml?: string) => {
  if (!xml?.length) return null;

  const blob = new Blob([xml], { type: '.xml' });

  return new File([blob], 'metadata.xml');
};

export const getEncryptionCertificateFile = (hashString: string) => {
  if (!hashString?.length) return null;

  const certificateContent = `-----BEGIN CERTIFICATE-----
${hashString}
-----END CERTIFICATE-----`;
  const blob = new Blob([certificateContent], { type: '.crt' });

  return new File([blob], 'encryption-certificate.crt');
};
