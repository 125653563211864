import { useEffect, useState } from 'react';
import type { Edge, Node } from 'reactflow';
import { useTypedSelector } from './useTypeSelector';

export function useSelectedEntityType() {
  const componentId = useTypedSelector((state) => state.drawn?.component?.id);
  const [selectedEntityType, setSelectedEntityType] = useState('');
  const { nodesSharedState, edgesSharedState } = useTypedSelector((state) => state.diagram);
  const [nodes, setNodes] = useState<Node[] | null>();
  const [edges, setEdges] = useState<Edge[] | null>();

  useEffect(() => {
    if (nodesSharedState) {
      setNodes(Array.from(nodesSharedState.values()));
    }
  }, [nodesSharedState]);

  useEffect(() => {
    if (edgesSharedState) {
      setEdges(Array.from(edgesSharedState.values()));
    }
  }, [edgesSharedState]);

  useEffect(() => {
    if (nodes) {
      const selectedEntity = nodes.find((node) => node.id === componentId);

      if (selectedEntity && selectedEntity.type) {
        setSelectedEntityType(selectedEntity.type);
      }
    }
  }, [nodes, componentId]);

  useEffect(() => {
    if (edges) {
      const selectedEntity = edges.find((edge) => edge.id === componentId);

      if (selectedEntity && selectedEntity.type) {
        setSelectedEntityType(selectedEntity.type);
      }
    }
  }, [edges, componentId]);

  return selectedEntityType;
}
