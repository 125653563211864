import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import { Clipboard2Check } from 'react-bootstrap-icons';

import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { THREATS_REGISTER_ROUTE } from '../../../store/constants/route-constants';
import type { Dispatch } from '../../../store/store';
import styles from './index.module.css';

type DiagramPercentageProps = {
  isThreatRegister?: boolean;
};

export const DiagramPercentage: FC<DiagramPercentageProps> = ({ isThreatRegister = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { currentThreatModel } = useTypedSelector((state) => state.threatModel);
  const [showStatistics, setShowStatistics] = useState(false);

  const handleOpenStatistics = () => {
    setShowStatistics(!showStatistics);
  };

  if (currentThreatModel?.percentage !== undefined)
    return (
      <div className={classNames('block rounded-1 shadow bg-white user-select-none', styles.statisticsWrapper)}>
        <div className={styles.statistics} onClick={handleOpenStatistics}>
          <div className={styles.hasTooltip}>
            <span>{currentThreatModel.percentage}%</span>
          </div>
          {showStatistics && (
            <div className={classNames(styles.statItemWrapper, 'rounded shadow p-1')}>
              <div
                className={classNames(styles.statThreat, styles.statItem)}
                data-tooltip={`${currentThreatModel?.threatAmount} threats`}
                onClick={() => {
                  if (!isThreatRegister) {
                    navigate(THREATS_REGISTER_ROUTE.replace('/', ''));
                    dispatch.drawn.setVisible(false);
                  }
                }}
              >
                <Clipboard2Check color="#E83C2D" size={16} /> {currentThreatModel?.threatAmount}
              </div>
              <div
                className={classNames(styles.statMitigation, styles.statItem)}
                data-tooltip={`${currentThreatModel?.mitigationAmount} mitigations`}
              >
                <Clipboard2Check color="#36A159" size={16} /> {currentThreatModel?.mitigationAmount}
              </div>
            </div>
          )}
        </div>
      </div>
    );

  return (
    <div className={classNames('block rounded-1 shadow bg-white user-select-none', styles.statisticsWrapper)}>
      <div className={styles.statistics}>
        <Skeleton width={30} />
      </div>
    </div>
  );
};
