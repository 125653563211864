import classNames from 'classnames';
import type { FC, SyntheticEvent } from 'react';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { initialComponent } from '../../../store/models/drawn';
import type { Dispatch } from '../../../store/store';
import { DeleteRepresentationModal } from './DeleteRepresentationModal';
import styles from './index.module.css';
import { RepresentationDropdown } from './RepresentationDropdown';

const SHOW_MULTIPLE_CANVASES = process.env?.REACT_APP_SHOW_MULTIPLE_CANVASES === 'true';

interface DiagramTitleProps {
  showCanvases?: boolean;
  isReadMode?: boolean;
}

export const DiagramTitle: FC<DiagramTitleProps> = ({ showCanvases = true, isReadMode = false }) => {
  const dispatch = useDispatch<Dispatch>();
  const params = useParams();
  const navigate = useNavigate();
  const componentId = useTypedSelector((state) => state.drawn?.component?.id);
  const { currentThreatModel } = useTypedSelector((state) => state.threatModel);
  const { nodesSharedState, edgesSharedState } = useTypedSelector((state) => state.diagram);
  const currentRepresentation = useTypedSelector((state) => state.representation.data);
  const [showRepresentationDropdown, setShowRepresentationDropdown] = useState(false);
  const [deleteRepresentationId, setDeleteRepresentationId] = useState('');

  const handleOpenDrawer = () => {
    const { id, diagramId } = params;

    if (componentId) {
      const currentNode = nodesSharedState?.get(componentId);

      if (currentNode?.id)
        nodesSharedState?.set(currentNode.id, {
          ...currentNode,
          selected: false,
        });

      const currentEdge = edgesSharedState?.get(componentId);

      if (currentEdge?.id)
        edgesSharedState?.set(currentEdge.id, {
          ...currentEdge,
          selected: false,
        });

      dispatch.drawn.setComponent(initialComponent);
    }

    if (id && diagramId) {
      dispatch.drawn.setVisible(true);
      navigate(`/collections/${id}/d/${diagramId}`);
    }
  };

  const handleToggleDropdown = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setShowRepresentationDropdown(!showRepresentationDropdown);
  };

  const currentRepresentationTitle =
    currentRepresentation?.title ||
    `Canvas ${(currentThreatModel?.representations?.findIndex((i) => i.id === params.diagramId) || 0) + 1}`;

  return (
    <div
      className={classNames(
        'block rounded-1 shadow bg-white user-select-none position-relative',
        styles.threatModelTitle,
      )}
    >
      <div className={classNames(styles.hasTooltip, styles.title)} onClick={handleOpenDrawer}>
        {currentThreatModel?.title ? currentThreatModel.title : <Skeleton width={100} />}
      </div>

      {SHOW_MULTIPLE_CANVASES && showCanvases && (
        <>
          <div className={styles.breakLine} />
          <div id="representationDropdown" className={classNames('d-flex gap-2 align-items-center p-0')}>
            <div
              id="representationDropdown"
              className={classNames(styles.representationTitle, 'p-2')}
              onClick={handleToggleDropdown}
              title={currentRepresentationTitle}
            >
              {currentRepresentationTitle}
            </div>
          </div>
          {showRepresentationDropdown && (
            <RepresentationDropdown
              isReadMode={isReadMode}
              currentThreatModel={currentThreatModel}
              currentRepresentation={currentRepresentation}
              deleteRepresentationId={deleteRepresentationId}
              setDeleteRepresentationId={setDeleteRepresentationId}
              setShowRepresentationDropdown={setShowRepresentationDropdown}
            />
          )}
          {!!deleteRepresentationId.length && (
            <DeleteRepresentationModal
              currentThreatModel={currentThreatModel}
              deleteRepresentationId={deleteRepresentationId}
              currentRepresentationId={currentRepresentation?.id}
              setDeleteRepresentationId={setDeleteRepresentationId}
            />
          )}
        </>
      )}
    </div>
  );
};
