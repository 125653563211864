import classNames from 'classnames';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { FiletypeCsv } from 'react-bootstrap-icons';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import UploadCsvFile from '../../../assets/icons/UploadCsvFile.svg';
import { getReadableFileSizeString } from '../../../helpers/app';
import type { Dispatch } from '../../../store/store';
import UiModal from '../Modal/UiModal';
import UiButton from '../UIButton/UiButton';
import styles from './ManageSamlForm.module.css';

type ImportUsersFormProps = {
  onClose: () => void;
};

export const ImportUsersForm: FC<ImportUsersFormProps> = ({ onClose }) => {
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles.at(0);

    if (file) setFile(file);
  }, []);
  const { getRootProps, getInputProps, inputRef, open, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    accept: { 'text/csv': ['.csv'] },
  });

  const handleImport = async () => {
    setLoading(true);
    await dispatch.user.uploadUsersCsv(file);
    setLoading(false);
    onClose();
  };

  const downloadSampleFileToImportUsers = async () => {
    const result = await dispatch.user.downloadSampleFileToImportUsers();

    if (result.length) {
      const url = window.URL.createObjectURL(new Blob([result]));
      const a = document.createElement('a');
      a.href = url;
      a.download = 'saml-sso-import-users-sample.csv';
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  return (
    <UiModal title="Import file" onClose={onClose}>
      <div
        className={classNames(styles.uploadWrap, {
          [styles.isDragActive]: isDragActive || file,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {file && (
          <div>
            <div className="d-flex">
              <FiletypeCsv color="#2360C5" size={40} />
              <div className="ms-1 mt-1">
                <div className={styles.uploadFileName}>{file.name}</div>
                <div className={styles.uplaodFileSize}>{getReadableFileSizeString(file.size)}</div>
              </div>
            </div>

            <div
              className={styles.deleteFileButton}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setFile(null);

                if (inputRef?.current?.value) {
                  inputRef.current.value = '';
                }
              }}
            >
              Delete
            </div>
          </div>
        )}
        {!file && (
          <div className="text-center">
            <img className="mb-3" src={UploadCsvFile} alt="" />
            <div className={styles.uploadWrapText}>
              Drop file here or <span onClick={() => open()}>Choose file</span>
            </div>
            <div className={styles.uploadWrapSubText}>CSV Format</div>
          </div>
        )}
      </div>

      <div className="d-flex justify-content-between">
        <UiButton onClick={downloadSampleFileToImportUsers} className="mt-3 me-3" type="transparent">
          Download sample CSV
        </UiButton>

        <div>
          <UiButton onClick={onClose} className="mt-3 me-3" type="transparent" style={{ width: '100px' }}>
            Cancel
          </UiButton>
          <UiButton onClick={handleImport} className="mt-3" style={{ width: '100px' }} disabled={!file}>
            {loading ? <Spinner color="light" size="sm" /> : 'Import'}
          </UiButton>
        </div>
      </div>
    </UiModal>
  );
};
