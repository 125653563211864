import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserList from '../../../components/Users/UserList';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';

const UsersPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const { isAuthenticated } = useTypedSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    let role: any;
    const fetchCurrentUser = async () => {
      const data = await dispatch.user.getCurrentUser();
      role = data?.role;
    };
    fetchCurrentUser().then(() => {
      if (isAuthenticated && role !== 'admin') {
        navigate('/');
      }
    });
  }, []);

  return <UserList />;
};

export default UsersPage;
