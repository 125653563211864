import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { THREAT_PRIORITY } from '../../../store/constants/drawn-constants';
import type { Dispatch } from '../../../store/store';
import UiDropdown from '../../common/UiDropDown/UiDropDown';
import styles from './CustomCodexTable.module.css';

const options = [
  { id: 'All Priorities', title: 'All Priorities' },
  ...Object.values(THREAT_PRIORITY).map((p) => ({ id: p, title: p })),
];

export const ThreatsPriorityFilter = () => {
  const { query } = useTypedSelector((state) => state.customCodex);
  const dispatch = useDispatch<Dispatch>();
  const [value, setValue] = useState<any>(options[0]);

  const handleChange = async (newValue: Record<string, string>) => {
    setValue(newValue);
    dispatch.customCodex.setThreats(null);

    if (newValue === options[0]) {
      await dispatch.customCodex.getCustomThreats({
        ...query,
        priority: null,
      });

      return;
    }

    await dispatch.customCodex.getCustomThreats({
      ...query,
      priority: newValue.title,
    });
  };

  return (
    <div className={styles.priorityFilter}>
      <div className={styles.priorityFilterLabel}>Priority</div>
      <UiDropdown onChange={handleChange} options={options} value={value} />
    </div>
  );
};
