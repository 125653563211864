import { useState } from 'react';
import DateInput from '../../components/common/DateInput/DateInput';
import UiFilter from '../../components/common/Filter/UiFilter';
import ThreatIndicator from '../../components/common/ThreatIndicator/ThreatIndicator';
import UiButton from '../../components/common/UIButton/UiButton';
import UiIconButton from '../../components/common/UIIconButton/UiIconButton';
import UiPriority from '../../components/common/UiPriority/UiPriority';
import UserAvatar from '../../components/common/UserAvatar/UserAvatar';
import User from '../../components/User/User';
import { useTypedSelector } from '../../hooks/useTypeSelector';

const UiKitPage = () => {
  const { current } = useTypedSelector((state) => state.user);

  const [date, setDate] = useState(new Date());

  return (
    <div className="container">
      <div className="row my-3 d-flex justify-content-evenly">
        <UiButton>Create new</UiButton>
        <UiButton disabled>Create new</UiButton>
        <UiButton type="default">Create new</UiButton>
        <UiButton type="default" disabled>
          Create new
        </UiButton>
        <UiButton type="transparent">Create new</UiButton>
        <UiButton type="transparent" disabled>
          Create new
        </UiButton>
      </div>
      <div className="col my-3 d-flex justify-content-evenly">
        <UiFilter type="icon-only" active />
        <UiFilter type="icon-only" />
        <UiFilter type="icon-right" active />
        <UiFilter type="icon-right" />
        <UiFilter type="icon-left" active />
        <UiFilter type="icon-left" />
        <UiFilter type="icon-none" active />
        <UiFilter type="icon-none" />
      </div>
      <div className="col my-3 d-flex justify-content-evenly">
        <UiIconButton type="dots" active />
        <UiIconButton type="dots" />
        <UiIconButton type="list" active />
        <UiIconButton type="list" />
        <UiIconButton type="grid" active />
        <UiIconButton type="grid" />
      </div>
      <div className="col my-3 d-flex justify-content-evenly">
        <UiPriority type="high" />
        <UiPriority type="low" />
        <UiPriority type="medium" />
        <UiPriority type="critical" />
      </div>
      <div className="row my-3 d-flex border-top pt-3 align-items-center gap-3">
        <ThreatIndicator leftValue={9} rightValue={2} widthBackground />
        <ThreatIndicator leftValue={9} rightValue={2} widthBackground={false} />
      </div>
      <div className="row my-3 d-flex border-top pt-3 align-items-center gap-3">
        <DateInput />
        <DateInput value={date} onChange={setDate} />
      </div>
      <div className="row my-3 d-flex border-top pt-3 align-items-center gap-3">
        <User user={current} />
        <User user={current} clearIcon />
        <User user={current} active />
        <User user={current} small />
        <User user={current} small clearIcon />
        <User user={current} small active />
      </div>
      <div className="row my-3 d-flex border-top pt-3 align-items-center gap-3">
        <UserAvatar user={current} />
        <UserAvatar user={current} active />
      </div>
    </div>
  );
};

export default UiKitPage;
