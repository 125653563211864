import type { PaginationParamsInterface } from '../../global/types';
import type { ELEMENT_TYPE, THREAT_PRIORITY, THREAT_STATUS } from './drawn-constants';

export interface ThreatRegisterFilterParams extends PaginationParamsInterface {
  statuses: THREAT_STATUS[];
  priorities: THREAT_PRIORITY[];
  elementTypes: ELEMENT_TYPE[];
  canvases: string[];
}

export const DEFAULT_THREATS_REGISTER_PAGINATION_PARAMS: ThreatRegisterFilterParams = {
  limit: 30,
  page: 0,
  count: 0,
  order: 'ASC',
  sort: 'title',
  statuses: [],
  priorities: [],
  elementTypes: [],
  canvases: [],
};

export const DEFAULT_GROUP_THREATS_REGISTER_PAGINATION_PARAMS: ThreatRegisterFilterParams = {
  limit: 30,
  page: 0,
  count: 0,
  order: 'ASC',
  sort: 'title',
  statuses: [],
  priorities: [],
  elementTypes: [],
  canvases: [],
};

export enum THREATS_REGISTER_DETAIL_SIZE {
  MIN = 335,
  MAX = 500,
}

export const THREATS_REGISTER_SELECTED_CLASS = 'threatRegisterSelectedNode';

export const THREATS_REGISTER_SELECTED_THREAT = 'THREATS_REGISTER_SELECTED_THREAT';

export const THREATS_REGISTER_SELECTED_COMPONENT_ID = 'THREATS_REGISTER_SELECTED_COMPONENT_ID';

export enum THREAT_REPORT_TYPE {
  ELEMENT = 'element-register-report',
  THREAT = 'threat-register-report',
}
