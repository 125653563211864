import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import deviciLogo from '../../assets/images/devici-logo.png';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';
import UiButton from '../common/UIButton/UiButton';
import UiInput from '../common/UiInput/UiInput';
import styles from './style.module.css';

type OnboardingModalProps = {
  closeModal: () => Promise<void>;
};

const OnboardingModal: FC<OnboardingModalProps> = ({ closeModal }) => {
  const dispatch = useDispatch<Dispatch>();
  const [step, setSteps] = useState(0);
  const [checkboxOne, setCheckboxOne] = useState(false);
  const [checkboxTwo, setCheckboxTwo] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useTypedSelector((state) => state.user.current);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: user?.email || '',
      organization: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string().email().required('Required'),
      organization: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      await dispatch.user.sendOnboardingData(values);
    },
  });

  const handleCloseModal = async () => {
    setLoading(true);
    await formik.submitForm();
    await closeModal();
    setLoading(false);
  };

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <div className="w-100" style={{ height: '240px' }}>
            <div className="text-center fw-bold" style={{ marginBottom: '20px' }}>
              The Legal Stuff
            </div>
            <p>
              First things first, please review and agree to our terms by ticking the box below to accept our Privacy
              Policy, Terms of Use, and End User License Agreement (EULA).
            </p>
            <div className="d-flex gap-2 m-auto mt-4 mb-2 fs-14">
              <input
                checked={checkboxTwo}
                type="checkbox"
                className="cursor-pointer"
                onChange={(e) => setCheckboxTwo(e.target.checked)}
                id="first"
              />
              <label htmlFor="first" className="m-0 cursor-pointer user-select-none" style={{ lineHeight: '1.4' }}>
                Checking this box means that I agree to Devici&apos;s{' '}
                <a href="https://devici.com/terms-policies/privacy-policy" target="_blank" rel="noreferrer">
                  Privacy Policy{' '}
                </a>
                and{' '}
                <a href="https://devici.com/terms-policies/terms-of-use" target="_blank" rel="noreferrer">
                  Terms of Use.{' '}
                </a>
                I will receive updates about products and services and can unsubscribe anytime.
              </label>
            </div>
            <div className="d-flex gap-2 m-auto fs-14">
              <input
                checked={checkboxOne}
                className="cursor-pointer"
                type="checkbox"
                onChange={(e) => setCheckboxOne(e.target.checked)}
                id="second"
              />
              <label className="m-0 cursor-pointer" htmlFor="second">
                I accept the{' '}
                <a
                  href="https://devici.com/terms-policies/devici-end-user-license-agreement"
                  target="_blank"
                  rel="noreferrer"
                >
                  End User License Agreement (EULA)
                </a>
              </label>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="w-100">
            <div className="fw-bold text-center mb-3">Tell us a little about yourself</div>
            <div className="d-flex gap-3 mb-3">
              <UiInput
                label="*First Name"
                placeholder="First Name"
                name="firstName"
                value={formik.values.firstName}
                type="text"
                onChange={formik.handleChange}
                onFocus={() => dispatch.diagram.setCanCopy(false)}
                onBlur={(e: any) => {
                  formik.handleBlur(e);
                  dispatch.diagram.setCanCopy(true);
                }}
                errorText={formik.errors.firstName}
                showError={formik.touched.firstName && formik.errors.firstName}
              />
              <UiInput
                label="*Last Name"
                placeholder="Last Name"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onFocus={() => dispatch.diagram.setCanCopy(false)}
                onBlur={(e: any) => {
                  formik.handleBlur(e);
                  dispatch.diagram.setCanCopy(true);
                }}
                errorText={formik.errors.lastName}
                showError={formik.touched.lastName && formik.errors.lastName}
                type="text"
              />
            </div>
            <div className="d-flex gap-3 mb-3">
              <UiInput
                label="*Email"
                placeholder="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onFocus={() => dispatch.diagram.setCanCopy(false)}
                onBlur={(e: any) => {
                  formik.handleBlur(e);
                  dispatch.diagram.setCanCopy(true);
                }}
                errorText={formik.errors.email}
                showError={formik.touched.email && formik.errors.email}
                type="email"
                disabled
              />
              <UiInput
                label="*Organization"
                placeholder="Organization"
                name="organization"
                value={formik.values.organization}
                onChange={formik.handleChange}
                onFocus={() => dispatch.diagram.setCanCopy(false)}
                onBlur={(e: any) => {
                  formik.handleBlur(e);
                  dispatch.diagram.setCanCopy(true);
                }}
                errorText={formik.errors.organization}
                showError={formik.touched.organization && formik.errors.organization}
                type="text"
              />
            </div>
          </div>
        );
      default:
        return (
          <>
            <p>
              <b>Welcome to Devici!</b>
            </p>
            <p>We’re excited you are here. Ready to advance your threat modeling journey?</p>
          </>
        );
    }
  };

  const renderFooter = () => {
    const handleClickNextStep = () => {
      setSteps(step + 1);
    };
    const handleClickBackStep = () => {
      setSteps(step - 1);
    };
    switch (step) {
      case 1:
        return (
          <>
            <div />
            <UiButton onClick={handleClickNextStep} disabled={!checkboxOne || !checkboxTwo}>
              Next
            </UiButton>
          </>
        );
      case 2: {
        const canMoveNext =
          checkboxOne &&
          checkboxTwo &&
          formik.values.firstName?.length &&
          formik.values.lastName?.length &&
          formik.values.email?.length &&
          formik.values.organization?.length;

        return (
          <>
            <UiButton onClick={handleClickBackStep} type="transparent">
              Back
            </UiButton>
            <UiButton onClick={handleCloseModal} disabled={!canMoveNext} style={{ width: 200 }}>
              {loading ? <Spinner size="sm" /> : 'Start Threat Modeling'}
            </UiButton>
          </>
        );
      }
      default:
        return (
          <>
            <div />
            <UiButton onClick={handleClickNextStep}>Let&apos;s Go!</UiButton>
          </>
        );
    }
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <div className={styles.head}>
          <img src={deviciLogo} alt="logo" />
          {!!step && (
            <div className={styles.stepsWrap}>
              {[1, 2].map((st) => (
                <div
                  key={st}
                  className={classNames('user-select-none', {
                    [styles.activeStep]: step === st,
                  })}
                >
                  {st}
                </div>
              ))}
              <span />
            </div>
          )}
        </div>
        <div className={styles.body}>{renderContent()}</div>
        <div className={styles.footer}>{renderFooter()}</div>
      </div>
    </div>
  );
};

export default OnboardingModal;
