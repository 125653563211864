import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import { useTypedSelector } from '../../hooks/useTypeSelector';
import { DEFAULT_USER_PAGINATION_PARAMS } from '../../store/constants/user-constants';
import type { Dispatch } from '../../store/store';
import UiButton from '../common/UIButton/UiButton';
import UiInput from '../common/UiInput/UiInput';
import User from '../User/User';
import styles from './UsersDropdown.module.css';

let timeout: any = null;
type UsersDropdownProps = {
  hide: () => void;
  threatModelId?: string;
  onSelect?: (value: any) => any;
  exclude?: string;
};

const UsersDropdown: FC<UsersDropdownProps> = ({ hide, threatModelId, onSelect, exclude }) => {
  const dispatch = useDispatch<Dispatch>();
  const { dropdownUsers: users, query } = useTypedSelector((state) => state.user) || [];
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const canFetchMore = (query.page + 1) * query.limit < query.count;

  useEffect(() => {
    if (!users?.length) {
      setLoading(true);
      dispatch.user.getUsers({ forDropdown: true, needAvatar: true }).then(() => {
        setLoading(false);
      });
    }

    return () => {
      if (timeout) clearTimeout(timeout);

      dispatch.user.setDropdownUsers([]);
    };
  }, []);

  const handleSelect = (userId: string) => {
    if (threatModelId?.length) {
      dispatch.threatModel
        .updateThreatModelOwner({
          id: threatModelId,
          ownerId: userId,
        })
        .then(() => {
          hide();
        });
    } else if (onSelect) {
      onSelect(users.find((i) => i.id === userId));
    }
  };

  const handleChange = (event: any) => {
    setSearchValue(event.target.value);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setLoading(true);
      dispatch.user.setQuery(DEFAULT_USER_PAGINATION_PARAMS);
      dispatch.user
        .searchUsers({
          field: 'first_name',
          value: event.target.value,
          forDropdown: true,
        })
        .then(() => setLoading(false));
    }, 400);
  };

  const fetchMoreData = async () => {
    const nextPage = query.page + 1;

    if (nextPage * query.limit < query.count) {
      setLoading(true);
      await dispatch.user.getUsersByQuery({
        ...query,
        page: nextPage,
        forDropdown: true,
        needAvatar: true,
      });
      setLoading(false);
    }
  };

  const prepareData = () => {
    if (exclude?.length) return users?.filter((i) => i.id !== exclude) || [];

    return users || [];
  };

  return (
    <div className={styles.dropdown}>
      <div className="mb-1">
        <UiInput
          placeholder="search"
          name="search"
          value={searchValue}
          onChange={handleChange}
          onBlur={() => dispatch.diagram.setCanCopy(true)}
          onFocus={() => dispatch.diagram.setCanCopy(false)}
        />
      </div>
      {prepareData().map((user) => (
        <div
          key={user.id}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSelect(user.id);
          }}
        >
          <User user={user} />
        </div>
      ))}
      {loading && (
        <div className="text-center mt-2">
          <Spinner size="sm" />
        </div>
      )}
      {!loading && canFetchMore && (
        <div className={styles.loadMoreButton}>
          <UiButton type="transparent" onClick={fetchMoreData}>
            Load more
          </UiButton>
        </div>
      )}
      {!!searchValue?.length && !users.length && !loading && (
        <div className="fs-12 m-auto w-100 text-center pt-3 mb-2">Not found</div>
      )}
    </div>
  );
};

export default UsersDropdown;
