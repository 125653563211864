import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Trash3 } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import type { Node } from 'reactflow';
import type { NodeData } from '../../../global/types';
import { getId } from '../../../helpers/user';
import { UpdateTypes } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { initialComponent } from '../../../store/models/drawn';
import type { Dispatch } from '../../../store/store';
import styles from './index.module.css';

let timeout: NodeJS.Timeout;

type PopupToolsContextMenuProps = {
  setVisible: (val: any) => void;
  node: NodeData;
  isSticker?: boolean;
};

const PopupToolsContextMenu: FC<PopupToolsContextMenuProps> = ({ node, setVisible, isSticker = false }) => {
  const dispatch = useDispatch<Dispatch>();
  const params = useParams();
  const navigate = useNavigate();
  const { nodesSharedState } = useTypedSelector((state) => state.diagram);
  const { emitUpdate } = useTypedSelector((state) => state.drawn);
  const emitComponentVisualRemove = useTypedSelector((state) => state.drawn.emitComponentVisualRemove);
  const { diagramId } = params;
  const [nodes, setNodes] = useState<Node[] | null>();

  useEffect(() => {
    if (nodesSharedState) {
      setNodes(Array.from(nodesSharedState.values()));
    }
  }, [nodesSharedState]);

  const handleRename = () => {
    dispatch.diagram.setEditedComponentId(node.id);
    setVisible(false);
  };

  const handleCopy = () => {
    const nodeId = getId();
    dispatch.representation.cloneNodeEffect({ node, nodeId, diagramId });
    setTimeout(() => {
      dispatch.threatModel.updateCurrentThreatModel();
    }, 3000);
    emitUpdate && emitUpdate(UpdateTypes.COMPONENT, nodeId, true);
  };

  const handleDelete = async () => {
    nodes?.forEach((nd) => {
      if (nd?.parentNode === node.id) delete nd.parentNode;
    });
    nodesSharedState && nodesSharedState.delete(node.id);
    dispatch.drawn.removeComponents([node.id]);
    dispatch.drawn.setComponent(initialComponent);
    navigate(`/collections/${params.id}/d/${params.diagramId}`);

    if (timeout) clearTimeout(timeout);

    setTimeout(async () => {
      await dispatch.threatModel.updateCurrentThreatModel();
    }, 3000);
    emitComponentVisualRemove && emitComponentVisualRemove(node.id);
  };

  return (
    <ul
      className={classNames(
        'fs-12 position-absolute start-100 top-100 bg-white p-2 rounded-2 shadow list-unstyled user-select-none',
        styles.popupToolsContextMenu,
      )}
    >
      {!isSticker && (
        <li onClick={handleRename} className="">
          Rename
        </li>
      )}
      <li onClick={handleCopy}>Duplicate</li>
      <hr className="m-0" />
      <li onClick={handleDelete} className="text-danger d-flex align-items-center gap-2">
        <Trash3 size={16} type="danger" /> Delete
      </li>
    </ul>
  );
};

export default PopupToolsContextMenu;
