import { useReactFlow, useViewport } from 'reactflow';
import { getLinearInterpolation } from '../../../helpers/diagram';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { ICollaboratorSharedState } from '../../../pages/DiagramsPage';
import UserAvatar from '../../common/UserAvatar/UserAvatar';
import styles from './DiagramHeader.module.css';

interface IProps {
  collaborators?: ICollaboratorSharedState[];
}

const DiagramHeader = ({ collaborators }: IProps) => {
  const { setViewport } = useReactFlow();
  const { zoom } = useViewport();

  const currentUserId = useTypedSelector((state) => state.user?.current?.id);
  const { visible, drawnWidth } = useTypedSelector((state) => state.drawn);

  const handleMoveToUser = (user: any) => {
    if (user.id === currentUserId) return;

    const reactFlowBounds = document.querySelector('.react-flow__viewport')?.getBoundingClientRect();
    setViewport({
      x: user.mouse_coordinates.x * zoom + (reactFlowBounds?.width || 0) / 2 + getLinearInterpolation(zoom, 50, 400),
      y: user.mouse_coordinates.y * zoom + (reactFlowBounds?.height || 0) / 2 + getLinearInterpolation(zoom, 1, 200),
      zoom,
    });
  };

  return (
    <div className="d-flex column justify-content-between">
      <div
        className={styles.usersWrapper}
        style={{
          marginRight: visible ? `${drawnWidth + 110}px` : '115px',
        }}
      >
        <div className={styles.users}>
          {!!collaborators?.length &&
            collaborators
              .filter(
                (data) => data && typeof data === 'object' && Object.keys(data).length && currentUserId !== data?.id,
              )
              .map((user, index) => (
                <div style={{ marginLeft: '-5px' }} key={user.first_name + user.last_name + index}>
                  <UserAvatar user={user} onClick={() => handleMoveToUser(user)} />
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default DiagramHeader;
