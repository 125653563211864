import type { FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';
import { capitalizeFirstLetter } from '../../../helpers/user';

import type { EmitUpdate } from '../../../hooks/useSocket';
import { UpdateTypes } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { THREAT_STATUS } from '../../../store/constants/drawn-constants';
import type { IThreat } from '../../../store/models/drawn';
import type { Dispatch } from '../../../store/store';

type ThreatRegisterTableStatusDropdownProps = {
  threat: IThreat;
  setVisible: (val: boolean) => void;
  handleEmitAction: () => void;
  type: 'threat' | 'element';
  styles?: React.CSSProperties;
  isElementRegister?: boolean;
  emitUpdate: EmitUpdate;
};

export const ThreatRegisterTableStatusDropdown: FC<ThreatRegisterTableStatusDropdownProps> = ({
  setVisible,
  threat,
  handleEmitAction,
  type,
  styles,
  isElementRegister = false,
  emitUpdate,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { groupedThreatRegisterListQuery, elementRegisterListQuery } = useTypedSelector((state) => state.threats);

  const handleEmitAfterUpdate = (threatId: string) => {
    emitUpdate(UpdateTypes.THREAT, threatId, true);
  };

  const handleStatusChange = async (newStatus: THREAT_STATUS) => {
    const data = {
      id: threat.id,
      status: newStatus,
    };
    setVisible(false);
    const res = await dispatch.threats.updateThreat({
      threat: data,
      registerType: type,
    });
    await dispatch.threatModel.updateCurrentThreatModel();
    handleEmitAction();
    handleEmitAfterUpdate(threat.id);

    if (res && res.status === 200) {
      if (isElementRegister) {
        dispatch.threats.getElementRegisterList(elementRegisterListQuery);
      } else {
        dispatch.threats.getGroupedThreatRegisterList(groupedThreatRegisterListQuery);
      }
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <ul className="list-group shadow" style={styles || {}}>
        {Object.values(THREAT_STATUS)
          .filter((s) => s !== threat.status)
          .map((s) => (
            <li
              className="list-group-item"
              key={s}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleStatusChange(s);
              }}
            >
              {capitalizeFirstLetter(s)}
            </li>
          ))}
      </ul>
    </OutsideClickHandler>
  );
};
