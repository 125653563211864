import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Col } from 'reactstrap';

import Skeleton from 'react-loading-skeleton';
import { SKELETON_COLORS } from '../../global/constants';
import { isValidFileUploaded } from '../../helpers/user';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';
import UserAvatar from '../common/UserAvatar/UserAvatar';
import styles from '../Project/ProjectCreateOrUpdate/ProjectCreateOrUpdate.module.css';

export const ProfileAvatarForm = () => {
  const dispatch = useDispatch<Dispatch>();
  const { current: user } = useTypedSelector((state) => state.user);
  const [loading, setLoading] = useState('');
  const avatarUploadRef = useRef<any>(null);

  const handleUploadUserAvatar = () => {
    avatarUploadRef?.current?.click();
  };
  const handleChangeUserAvatar = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading('avatar');

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const result = isValidFileUploaded(file);

      if (result.valid) {
        const img = URL.createObjectURL(file);
        dispatch.user.setCurrent({
          ...user,
          avatar: img,
        });
        await dispatch.user.uploadUserAvatar(file);
      } else {
        toast.warning(result.text);
      }
    }

    setLoading('');
  };

  return (
    <Col sm={12} className="fs-16 d-flex gap-2">
      <input ref={avatarUploadRef} hidden type="file" onChange={handleChangeUserAvatar} />
      {user?.id && (
        <UserAvatar user={user} diameter={50} onClick={handleUploadUserAvatar} loading={loading === 'avatar'} />
      )}
      {!user?.id && (
        <Skeleton
          style={{ lineHeight: '22px' }}
          width={48}
          height={48}
          borderRadius={50}
          baseColor={SKELETON_COLORS.BASE}
          highlightColor={SKELETON_COLORS.HIGHLIGHT}
        />
      )}
      {user?.id && (
        <div
          style={{
            lineHeight: '50px',
            fontSize: '16px',
            fontWeight: '500',
          }}
        >
          <div className={styles.userFullName}>
            {user?.first_name} {user?.last_name}
          </div>
          <div className={styles.userUploadPhotoLabel} onClick={handleUploadUserAvatar}>
            Upload new photo
          </div>
        </div>
      )}
      {!user?.id && (
        <Skeleton
          style={{ lineHeight: '11px' }}
          width={250}
          height={38}
          baseColor={SKELETON_COLORS.BASE}
          highlightColor={SKELETON_COLORS.HIGHLIGHT}
        />
      )}
    </Col>
  );
};
