import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { Spinner } from 'reactstrap';
import { stringToBackgroundColor, stringToCommonColor } from '../../../helpers/colors';
import UserPopUp from '../UserPopUp/UserPopUp';
import styles from './UserAvatar.module.css';

const KanbanEl = document.getElementById('root') || document.body;

type UserAvatarProp = {
  active?: boolean;
  showPopUpOnHover?: boolean;
  className?: string;
  user?: any;
  noHover?: boolean;
  diameter?: number;
  onClick?: () => void;
  noBorder?: boolean;
  loading?: boolean;
};
const UserAvatar: FC<UserAvatarProp> = ({
  active,
  showPopUpOnHover = false,
  className = '',
  user,
  noHover = true,
  diameter = 35,
  onClick = () => {},
  noBorder = false,
  loading = false,
}) => {
  const [isUserPopUp, setIsUserPopUp] = useState(false);
  const [modal, setModal] = useState({
    visible: false,
    pageX: 0,
    pageY: 0,
  });

  const handleMouseEnter = (event: any) => {
    setIsUserPopUp(true);
    const rects = event.target.getBoundingClientRect();
    setModal({ visible: true, pageX: rects.x, pageY: rects.y });
  };

  const handleMouseLeave = () => {
    setIsUserPopUp(false);
    setModal({ visible: false, pageX: 0, pageY: 0 });
  };

  return (
    <div
      className={classNames(className, styles.userAvatarWrap)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      title={`${user?.first_name} ${user?.last_name}`}
      onClick={onClick}
    >
      {loading ? (
        <div className={styles.userAvatar} style={{ width: `${diameter}px`, height: `${diameter}px` }}>
          <Spinner />
        </div>
      ) : user?.avatarUrl?.length ? (
        <img
          style={{ width: `${diameter}px`, height: `${diameter}px` }}
          className={classNames(styles.userAvatar, {
            [styles.active]: active,
            [styles.noBorder]: noBorder,
          })}
          src={user?.avatarUrl}
          alt={`${user?.first_name} ${user?.last_name}`}
        />
      ) : (
        <span
          style={{
            width: `${diameter}px`,
            height: `${diameter}px`,
            background: stringToBackgroundColor(`${user?.first_name} ${user?.last_name}`),
            color: stringToCommonColor(`${user?.first_name} ${user?.last_name}`),
          }}
          className={classNames(styles.userAvatar, {
            [styles.noHover]: noHover,
            [styles.active]: active,
            [styles.noBorder]: noBorder,
          })}
        >
          {user?.first_name?.[0]}
          {user?.last_name?.[0]}
        </span>
      )}
      {isUserPopUp &&
        showPopUpOnHover &&
        createPortal(
          <div
            style={{
              position: 'absolute',
              zIndex: 100,
              left: modal.pageX,
              top: modal.pageY,
            }}
          >
            <UserPopUp
              fullName={`${user?.first_name} ${user?.last_name}`}
              shortName={`${user?.first_name?.[0]}${user?.last_name?.[0]}`}
              img={user?.avatar}
              email={user?.email}
            />
          </div>,
          KanbanEl,
        )}
    </div>
  );
};

export default UserAvatar;
