import classNames from 'classnames';
import type { FC, SyntheticEvent } from 'react';
import { useState } from 'react';
import { Check2, ExclamationTriangle, Trash3 } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { THREAT_STATUS } from '../../../store/constants/drawn-constants';

import type { Dispatch } from '../../../store/store';
import styles from './index.module.css';

type DrawnThreatItemProps = {
  threat: any;
  highlighted?: boolean;
  onThreatRemove: (isOk?: boolean) => void;
};

const DrawnThreatItem: FC<DrawnThreatItemProps> = ({ threat, onThreatRemove, highlighted }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<Dispatch>();

  const handleRemoveThreat = async (event: SyntheticEvent) => {
    setLoading(true);
    event.preventDefault();
    event.stopPropagation();
    const isOk = await dispatch.drawn.removeThreatFromComponent(threat.id);
    setLoading(false);
    onThreatRemove(isOk);
  };

  return (
    <Link
      to={`t/${threat.id}`}
      className={classNames(
        'd-flex align-items-center justify-content-between gap-3 mb-1 text-decoration-none text-secondary',
        styles.threatsItem,
        { [styles.highlighted]: highlighted },
      )}
    >
      <li>{threat.title || threat.description}</li>
      {!!threat.neutralized_by.length && <div className={styles.threatNeutralized}>N</div>}

      {threat?.status === THREAT_STATUS.COMPLETE ? (
        <div className={styles.threatIconCheck}>
          <Check2 fontSize={15} color="#34A853" />
        </div>
      ) : (
        <div className={styles.threatIconExclamation}>
          <ExclamationTriangle fontSize={15} color="#6F767E" />
        </div>
      )}

      <div className={styles.threatIconRemove} onClick={handleRemoveThreat} title="Delete">
        {loading ? <Spinner size="sm" /> : <Trash3 fontSize={15} color="#ff6464" />}
      </div>
    </Link>
  );
};

export default DrawnThreatItem;
