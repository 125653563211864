import type { FC } from 'react';
import { Filter } from 'react-bootstrap-icons';
import styles from './UiFilter.module.css';

const ACTIVE_COLOR = '#4285F4';

type UIFilterProps = {
  label?: string;
  active?: boolean;
  type?: 'icon-left' | 'icon-right' | 'icon-none' | 'icon-only';
  onClick?: () => void;
};

const UiFilter: FC<UIFilterProps> = ({ label = 'Filters', active = false, type = 'icon-left', onClick }) => {
  return (
    <button className={`${styles.filterDefault} ${active ? styles.active : ''}`} onClick={onClick}>
      {type === 'icon-left' && (
        <Filter size={24} color={`${active ? ACTIVE_COLOR : ''}`} className={styles.filterIconLeft} />
      )}
      {type !== 'icon-only' && label}
      {type === 'icon-right' && (
        <Filter size={24} color={`${active ? ACTIVE_COLOR : ''}`} className={styles.filterIconRight} />
      )}
      {type === 'icon-only' && <Filter size={24} color={`${active ? ACTIVE_COLOR : ''}`} />}
    </button>
  );
};

export default UiFilter;
