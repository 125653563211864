import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';

import { useNavigate, useParams } from 'react-router';
import type { EmitUpdate } from '../../../hooks/useSocket';
import { UpdateTypes } from '../../../hooks/useSocket';
import { PROJECTS_ROUTE, THREATS_REGISTER_ROUTE } from '../../../store/constants/route-constants';
import type { IMitigation, IThreat } from '../../../store/models/drawn';
import type { Dispatch } from '../../../store/store';
import MitigationItem from '../../Diagram/MitigationItem';
import { ThreatRegisterMitigationSearch } from '../ThreatRegisterMitigationSearch/ThreatRegisterMitigationSearch';
import styles from './ThreatRegisterTable.module.css';
import { ThreatRegisterTableMitigationDropdown } from './ThreatRegisterTableMitigationDropdown';

type ThreatRegisterTableMitigationProps = {
  threat: IThreat;
  handleEmitAction: () => void;
  isElementRegister?: boolean;
  emitUpdate: EmitUpdate;
  isReadMode?: boolean;
};

export const ThreatRegisterTableMitigation: FC<ThreatRegisterTableMitigationProps> = ({
  threat,
  handleEmitAction,
  isElementRegister = false,
  emitUpdate,
  isReadMode = false,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch<Dispatch>();
  const [isExpand, setIsExpand] = useState(true);
  const [mitigationPopup, setMitigationPopup] = useState<any>({
    threatId: '',
    id: '',
  });

  useEffect(() => {
    if (threat.mitigations && threat.mitigations?.length > 5) setIsExpand(false);
    else setIsExpand(true);
  }, []);

  const handleEmitAfterUpdate = (threatId: string, mitigationId?: string) => {
    emitUpdate(UpdateTypes.THREAT, threatId, true);

    if (mitigationId) {
      emitUpdate(UpdateTypes.MITIGATION, mitigationId, true);
    }
  };

  const handleRemoveMitigation = (threat: IThreat, mitigationId: string) => {
    if (isReadMode) return;

    const updatedMitigations =
      threat.mitigations?.filter((i: any) => i.id !== mitigationId).sort((a, b) => a.title.localeCompare(b.title)) ||
      [];

    if (isElementRegister) {
      dispatch.threats.updateSubThreatFromElementRegisterList({
        id: threat.id,
        mitigations: updatedMitigations,
      });
    } else {
      dispatch.threats.updateThreatFromThreatRegisterList({
        id: threat.id,
        mitigations: updatedMitigations,
      });
    }

    dispatch.threatModel.updateCurrentThreatModel();
    handleEmitAction();
    handleEmitAfterUpdate(threat.id, mitigationId);

    if (isElementRegister) {
      navigate(`${PROJECTS_ROUTE}/${params.id}/d/${params.diagramId}${THREATS_REGISTER_ROUTE}/element/${threat.id}`);
    } else {
      navigate(`${PROJECTS_ROUTE}/${params.id}/d/${params.diagramId}${THREATS_REGISTER_ROUTE}/${threat.id}`);
    }
  };

  const mitigations = isExpand ? threat.mitigations : threat?.mitigations?.filter((_, idx) => idx <= 4);

  return (
    <div
      className="d-flex gap-1 flex-wrap py-1 align-items-center position-relative"
      style={{ height: mitigations?.length ? 'auto' : 'inherit' }}
    >
      {mitigations?.map((m: IMitigation) => (
        <div key={m.id}>
          <MitigationItem
            data={m}
            isReadMode={isReadMode}
            onRemove={(needComponentUpdate, isOk) => {
              if (isReadMode) return;

              if (m.id && isOk) handleRemoveMitigation(threat, m.id);
            }}
            disableOnclick
            onClick={(e) => {
              if (isReadMode) return;

              e.preventDefault();
              e.stopPropagation();
              setMitigationPopup({
                threatId: threat.id,
                id: m.id,
              });
            }}
          />
          {mitigationPopup.id === m.id && mitigationPopup.threatId === threat.id && (
            <div className={styles.statusCel}>
              <ThreatRegisterTableMitigationDropdown
                threat={threat}
                mitigation={m}
                setVisible={setMitigationPopup}
                handleEmitAction={() => {
                  if (isReadMode) return;

                  handleEmitAction();
                  handleEmitAfterUpdate(threat.id, m.id);
                }}
              />
            </div>
          )}
        </div>
      ))}
      {!isExpand && (
        <div
          onClick={(e) => {
            if (isReadMode) return;

            e.preventDefault();
            e.stopPropagation();
            setIsExpand(true);
          }}
          className={styles.mitigationButton}
        >
          <CaretDownFill color="#828285" />
        </div>
      )}
      {isExpand && mitigations && mitigations?.length > 5 ? (
        <div
          onClick={(e) => {
            if (isReadMode) return;

            e.preventDefault();
            e.stopPropagation();
            setIsExpand(false);
          }}
          className={styles.mitigationButton}
        >
          <CaretUpFill color="#828285" />
        </div>
      ) : null}
      {threat.component && !isReadMode && (
        <ThreatRegisterMitigationSearch
          width={300}
          hideIcon={!mitigations?.length}
          threat={threat}
          isThreatRegister
          isElementRegister={isElementRegister}
          handleEmitAfterUpdate={handleEmitAfterUpdate}
        />
      )}
    </div>
  );
};
