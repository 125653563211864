export const NODES = {
  TRUST_BOUNDARY_NODE: 'trustBoundaryNode',
  EXTERNAL_ENTITY_NODE: 'externalEntityNode',
  PROCESS_NODE: 'processNode',
  DATA_STORE_NODE: 'datastoreNode',
  STICKER_NODE: 'stickerNode',
};

export const EDGES = {
  CONNECTION: 'default',
};

export const NODES_LABELS = {
  [NODES.TRUST_BOUNDARY_NODE]: 'Trust Boundary',
  [NODES.EXTERNAL_ENTITY_NODE]: 'External Entity',
  [NODES.PROCESS_NODE]: 'Process',
  [NODES.DATA_STORE_NODE]: 'Datastore',
  [NODES.STICKER_NODE]: '',
  [EDGES.CONNECTION]: 'Dataflow',
};

export const THREAT_PAGE_TABS = {
  THREAT_KANBAN_MODE: 1,
  THREAT_LIST_MODE: 2,
};

export const HOME_PAGE_TABS = {
  PROJECTS: 1,
  TEAMS: 2,
  USERS: 3,
  SETTINGS: 4,
};

export const COLUMN_NAMES = {
  REPRESENTATION: 'Representation',
  THREATS_AND_MITIGATIONS: 'Threats & Mitigations',
  RETROSPECTIVE: 'Retrospective',
  DONE: 'Done',
  ARCHIVE: 'Archive',
};

export enum COLUMN_TITLES {
  REPRESENTATION = 'REPRESENTATION',
  THREATS_AND_MITIGATIONS = 'THREATS_AND_MITIGATIONS',
  RETROSPECTIVE = 'RETROSPECTIVE',
  DONE = 'DONE',
  ARCHIVE = 'ARCHIVE',
}

export const MITIGATION_STATUSES = {
  DONE: 'done',
  IN_FUTURE: 'will',
  NEVER: 'never',
};

export const SKELETON_COLORS = {
  BASE: '#efefef',
  HIGHLIGHT: '#F9F9F9',
  DARK_BASE: '#6F767EFF',
  DARK_HIGHLIGHT: '#757c83',
};

export const MIN_SEARCH_LENGTH = 2;

export const ENVIRONMENT = {
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
  STAGING: 'staging',
};

export const DEFAULT_THREAT_MODEL_URL_QUERY = {
  count: 0,
  limit: 20,
  page: 0,
  data: [],
  fetched: false,
  originOrder: [],
  originMyModelsOrder: [],
};

export const SUBSCRIPTION_PLAN_PERIOD = {
  MONTHLY: 'monthly',
  ANNUALLY: 'annually',
};

export const CHARGEBEE_PLAN_IDS = {
  MONTHLY: process.env.REACT_APP_CHARGEBEE_MONTHLY_PLAN_ID,
  ANNUALLY: process.env.REACT_APP_CHARGEBEE_ANNUALLY_PLAN_ID,
};

export enum RepresentationFormat {
  DIAGRAM = 'diagram',
  CODE = 'code',
  TEXT = 'text',
}

export const TIER = {
  FREE: 'free',
  BUSINESS: 'business',
  ENTERPRISE: 'enterprise',
} as const;

export const PAYMENT_TESTERS_EMAILS = process.env.REACT_APP_PAYMENT_TESTERS_EMAILS?.split(',') || [];

export const CONTACT_SALES_URL = 'https://hubs.ly/Q02sqmH70';

export const SING_UP_PAGE_PAYMENT_URL_PARAMS = 'sign_up_page_payment_url_params';

export const HIDE_ONBOARDING_AFTER_PAYMENT = 'hide_onboarding_after_payment';

export const DEFAULT_FETCH_LIMIT = 25;
