import { createModel } from '@rematch/core';
import type { Edge, Node } from 'reactflow';

import { toast } from 'react-toastify';
import type { UndoManager, YMap } from 'yjs/dist/src/internals';
import type { NODES } from '../../global/constants';
import { sortAttributesBySearch } from '../../helpers/app';
import type { DEFAULT_EDGE_STYLE } from '../../helpers/diagram';
import { getDefaultEdgeStyle, setDefaultEdgeStyle } from '../../helpers/diagram';
import { ATTRIBUTES, COMPONENTS, MITIGATIONS, THREATS } from '../constants/api-constants';
import http from '../http/http-common';
import type { RootModel } from './index';

import { EDGE_STYLES } from '../../helpers/colors';
import type { IMitigatingAttribute } from './drawn';

type DiagramState = {
  attributes: any[];
  popupAttributes: any[];
  mitigatingAttributes: Record<string, IMitigatingAttribute[]> | null;
  popupMitigatingAttributes: Record<string, IMitigatingAttribute[]> | null;
  highlightedThreats: string[];
  mitigations: any;
  nodesSharedState: YMap<Node> | null;
  edgesSharedState: YMap<Edge> | null;
  threats: any;
  isSearchingMitigations: boolean;
  isSearchingThreats: boolean;
  isAllowedToDeleteByBackspace: boolean;
  editedComponentId: string;
  selectedNode: (typeof NODES)[keyof typeof NODES] | null;
  isSearchingAttributes: boolean;
  forceUpdateAttributeId: string | null;
  copiedNodeIds: string[];
  canCopy: boolean;
  collaborators: string[];
  threatRegisterDetailHeight: number;
  defaultEdgeStyle: DEFAULT_EDGE_STYLE;
  bufferedNodes: Node[];
  bufferedEdges: Edge[];
  copyPasteType: string;
  copyPasteRepresentationId: string;
  undoManager: UndoManager | null;
};

export const diagram = createModel<RootModel>()({
  state: {
    attributes: [],
    popupAttributes: [],
    mitigatingAttributes: null,
    popupMitigatingAttributes: null,
    highlightedThreats: [],
    mitigations: null,
    selectedNode: null,
    nodesSharedState: null,
    edgesSharedState: null,
    threats: null,
    isSearchingAttributes: false,
    isSearchingMitigations: false,
    isSearchingThreats: false,
    isAllowedToDeleteByBackspace: true,
    editedComponentId: '',
    forceUpdateAttributeId: null,
    copiedNodeIds: [],
    canCopy: true,
    collaborators: [],
    threatRegisterDetailHeight: 0,
    defaultEdgeStyle: getDefaultEdgeStyle(),
    bufferedNodes: [],
    bufferedEdges: [],
    copyPasteType: '',
    copyPasteRepresentationId: '',
    undoManager: null,
  } as DiagramState,
  reducers: {
    setAttributes(state, attributes) {
      return { ...state, attributes };
    },
    setPopupAttributes(state, popupAttributes) {
      return { ...state, popupAttributes };
    },
    clearAttributes(state) {
      return {
        ...state,
        attributes: [],
        popupAttributes: [],
      };
    },
    setMitigatingAttributes(state, mitigatingAttributes) {
      return { ...state, mitigatingAttributes };
    },
    setPopupMitigatingAttributes(state, popupMitigatingAttributes) {
      return { ...state, popupMitigatingAttributes };
    },
    setHighlightedThreats(state, highlightedThreats) {
      return { ...state, highlightedThreats };
    },
    setSelectedNode(state, selectedNode) {
      return {
        ...state,
        selectedNode,
      };
    },
    setNodesSharedState(state, nodesSharedState) {
      return {
        ...state,
        nodesSharedState,
      };
    },
    setEdgesSharedState(state, edgesSharedState) {
      return {
        ...state,
        edgesSharedState,
      };
    },
    clearSharedState(state) {
      return {
        ...state,
        edgesSharedState: null,
        nodesSharedState: null,
        doc: null,
      };
    },
    deleteFromNodesSharedState(state, nodeId) {
      state.nodesSharedState?.delete(nodeId);

      return { ...state };
    },
    deleteFromEdgesSharedState(state, edgeId) {
      state.edgesSharedState?.delete(edgeId);

      return { ...state };
    },
    setMitigations(state, mitigations) {
      return { ...state, mitigations };
    },
    setThreats(state, threats) {
      return { ...state, threats };
    },
    setIsSearchingAttributes(state, isSearchingAttributes) {
      return { ...state, isSearchingAttributes };
    },
    setIsSearchingThreats(state, isSearchingThreats) {
      return { ...state, isSearchingThreats };
    },
    setIsSearchingMitigations(state, isSearchingMitigations) {
      return { ...state, isSearchingMitigations };
    },
    setEditedComponentId(state, editedComponentId) {
      return { ...state, editedComponentId };
    },
    setForceUpdateAttributeId(state, forceUpdateAttributeId) {
      return { ...state, forceUpdateAttributeId };
    },
    setCopiedNodeId(state, copiedNodeId) {
      if (Array.isArray(copiedNodeId)) return { ...state, copiedNodeIds: copiedNodeId };

      return {
        ...state,
        copiedNodeIds: [copiedNodeId],
      };
    },
    setCanCopy(state, canCopy) {
      return { ...state, canCopy };
    },
    setCollaborators(state, collaborators) {
      return { ...state, collaborators };
    },
    setIsAllowedToDeleteByBackspace(state, value) {
      return { ...state, isAllowedToDeleteByBackspace: value };
    },
    setThreatRegisterDetailHeight(state, threatRegisterDetailHeight) {
      return { ...state, threatRegisterDetailHeight };
    },
    setDefaultEdgeStyle(state, defaultEdgeStyle) {
      setDefaultEdgeStyle(defaultEdgeStyle);

      return { ...state, defaultEdgeStyle };
    },
    setBufferedNodes(state, bufferedNodes) {
      return { ...state, bufferedNodes };
    },
    setBufferedEdges(state, bufferedEdges) {
      return { ...state, bufferedEdges };
    },
    setCopyPasteType(state, copyPasteType) {
      return { ...state, copyPasteType };
    },
    setCopyPasteRepresentationId(state, copyPasteRepresentationId) {
      return { ...state, copyPasteRepresentationId };
    },
    setUndoManager(state, undoManager) {
      return { ...state, undoManager };
    },
  },
  effects: (dispatch) => {
    return {
      async getAttributes({ search, selectedEntityType, isPopup }, state) {
        dispatch.diagram.setIsSearchingAttributes(true);
        const representationId = state.representation.data?.id;

        if (selectedEntityType === EDGE_STYLES.SMOOTHSTEP || selectedEntityType === EDGE_STYLES.EDITABLE) {
          selectedEntityType = EDGE_STYLES.BEZIER;
        }

        const result = await http.get(
          `${ATTRIBUTES}?text=${search}&entity_type=${selectedEntityType}&representationId=${representationId}`,
        );

        if (result.status === 200) {
          const sortedData = sortAttributesBySearch(result?.data, search);

          if (isPopup) dispatch.diagram.setPopupAttributes(sortedData);
          else dispatch.diagram.setAttributes(sortedData);
        }

        dispatch.diagram.setIsSearchingAttributes(false);
      },
      async getAttributesFromLeo({ text, forDrawer }) {
        dispatch.diagram.setIsSearchingAttributes(true);
        const response = await http.post(`${ATTRIBUTES}/ask-leo`, {
          text,
        });

        if (response.status === 201) {
          if (response?.data) {
            if (forDrawer) dispatch.diagram.setAttributes(response.data);
            else dispatch.diagram.setPopupAttributes(response.data);
          }
        }

        dispatch.diagram.setIsSearchingAttributes(false);
      },
      async getAttributeByTitle(title: string) {
        const response = await http(`${ATTRIBUTES}/one-for-explorer?title=${title}`);

        return response.data;
      },
      async getMitigations({ search }) {
        dispatch.diagram.setIsSearchingMitigations(true);
        const result = await http.get(`${MITIGATIONS}?text=${search}&forExplorer=true`);

        if (result.status === 200) {
          dispatch.diagram.setMitigations(result.data || []);
        }

        dispatch.diagram.setIsSearchingMitigations(false);
      },
      async getAttributeById(id: string) {
        try {
          const response = await http(`${ATTRIBUTES}/${id}`);

          if (response.status === 200 && response.data) {
            dispatch.drawn.setCurrentAttribute(response.data);
          }
        } catch (e: any) {
          toast.warning(e.message);
        }
      },
      async updateAttributeById(_, state) {
        try {
          if (!state.drawn.currentAttribute?.id) return;

          const response = await http.patch(`${ATTRIBUTES}/${state.drawn.currentAttribute.id}`, {
            title: state.drawn.currentAttribute.title,
            description: state.drawn.currentAttribute.description,
            type: state.drawn.currentAttribute.type,
          });

          if (response.status === 200) {
            toast.success('Attribute updated!');

            if (state.drawn?.component?.id)
              await dispatch.drawn.getComponent({
                id: state.drawn.component.id,
              });
          } else {
            const responseText = response.request?.responseText;
            const res = responseText?.length ? JSON.parse(responseText) : null;

            return res?.message?.toString();
          }
        } catch (e: any) {
          toast.warning(e.message);
        }
      },
      async getThreats({ search }) {
        dispatch.diagram.setIsSearchingThreats(true);
        const result = await http.get(`${THREATS}/?text=${search}`);

        if (result.status === 200) {
          dispatch.diagram.setThreats(result.data || []);
        }

        dispatch.diagram.setIsSearchingThreats(false);
      },
      async getMitigatingAttributes({ componentId, selectedEntityType, isPopup }) {
        try {
          if (selectedEntityType === EDGE_STYLES.SMOOTHSTEP || selectedEntityType === EDGE_STYLES.EDITABLE) {
            selectedEntityType = EDGE_STYLES.BEZIER;
          }

          dispatch.diagram.setIsSearchingAttributes(true);
          const result = await http(
            `${COMPONENTS}/neutralize-attributes/${componentId}?entity_type=${selectedEntityType}`,
          );

          if (result.status === 200) {
            dispatch.diagram.setIsSearchingAttributes(false);

            if (isPopup) {
              dispatch.diagram.setPopupMitigatingAttributes(result.data || {});

              return;
            }

            dispatch.diagram.setMitigatingAttributes(result.data || {});
          }
        } catch (e: any) {
          toast.warning(e.message);
        }
      },
    };
  },
});
