import classNames from 'classnames';
import type { FC } from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Progress, Spinner } from 'reactstrap';

import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SKELETON_COLORS } from '../../../global/constants';
import { getTableHeight } from '../../../helpers/app';
import { isReadMode } from '../../../helpers/isReadMode';
import { getThreatRegisterSelectedThreat } from '../../../helpers/threatRegister';
import { capitalizeFirstLetter } from '../../../helpers/user';
import type { EmitUpdate } from '../../../hooks/useSocket';
import { useSocket } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { THREAT_PRIORITY, THREAT_STATUS } from '../../../store/constants/drawn-constants';
import { PROJECTS_ROUTE, THREATS_REGISTER_ROUTE } from '../../../store/constants/route-constants';
import {
  DEFAULT_GROUP_THREATS_REGISTER_PAGINATION_PARAMS,
  DEFAULT_THREATS_REGISTER_PAGINATION_PARAMS,
} from '../../../store/constants/threat-register-constants';
import type { IThreat } from '../../../store/models/drawn';
import type { Dispatch } from '../../../store/store';
import UiModal from '../../common/Modal/UiModal';
import UiButton from '../../common/UIButton/UiButton';
import UiPriority from '../../common/UiPriority/UiPriority';
import ThreatDropdown from '../../Diagram/ThreatDropdown';
import styles from './ThreatRegisterTable.module.css';
import { ThreatRegisterTableMitigation } from './ThreatRegisterTableMitigation';
import { ThreatRegisterTablePriorityDropdown } from './ThreatRegisterTablePriorityDropdown';
import { ORDER_TYPE, ThreatRegisterTableSortColumn } from './ThreatRegisterTableSortColumn';
import { ThreatRegisterTableStatusDropdown } from './ThreatRegisterTableStatusDropdown';

interface IApiAccessModal {
  visible: boolean;
  isStatus?: boolean;
  isPriority?: boolean;
  selectedTitle?: string;
}

type ThreatRegisterTableProps = {
  emitUpdate: EmitUpdate;
};

export const ThreatRegisterTable: FC<ThreatRegisterTableProps> = ({ emitUpdate }) => {
  const navigate = useNavigate();
  const params = useParams();
  const threatModelId = useTypedSelector((state) => state.threatModel?.currentThreatModel?.id);
  const dispatch = useDispatch<Dispatch>();
  const { threatRegisterSelectedThreat, threatRegisterList, threatRegisterListQuery } = useTypedSelector(
    (state) => state.threats,
  );
  const { groupedThreatRegisterList, groupedThreatRegisterListQuery } = useTypedSelector((state) => state.threats);
  const interactionMode = useTypedSelector((state) => state.threatModel.interactionMode);
  const { threatRegisterDetailHeight } = useTypedSelector((state) => state.diagram);
  const tableRef = useRef<any>('threatRegisterTableRef');
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isPriority, setIsPriority] = useState<boolean | string>(false);
  const [isStatus, setIsStatus] = useState<boolean | string>(false);
  const [modalStatus, setModalStatus] = useState<THREAT_STATUS | undefined>(undefined);
  const [modalPriority, setModalPriority] = useState<THREAT_PRIORITY | undefined>(undefined);
  // const canFetchMore = threatRegisterListQuery.page * threatRegisterListQuery.limit < threatRegisterListQuery.count;
  const canFetchMoreGroup =
    groupedThreatRegisterListQuery.page * groupedThreatRegisterListQuery.limit < groupedThreatRegisterListQuery.count;
  const [modal, setModal] = useState<IApiAccessModal>({
    visible: false,
    selectedTitle: '',
  });
  const { emitGlobalUpdate } = useSocket();
  const { currentThreatModel, registerLiveUpdateData } = useTypedSelector((state) => state.threatModel);
  const { forceUpdateThreatId } = useTypedSelector((state) => state.drawn);

  const fetchData = () => {
    if (!threatModelId) return;

    dispatch.threats.getGroupedThreatRegisterList({
      ...groupedThreatRegisterListQuery,
      threatModelId,
    });
    dispatch.threats.getThreatRegisterList({
      ...threatRegisterListQuery,
      threatModelId,
    });
  };

  useEffect(() => {
    if (tableRef?.current?.style && threatRegisterDetailHeight) {
      tableRef.current.style.height = getTableHeight(params, threatRegisterDetailHeight);
    }

    if (threatModelId) {
      dispatch.threats.getGroupedThreatRegisterList({
        ...groupedThreatRegisterListQuery,
        threatModelId,
      });
    }

    return () => {
      dispatch.threats.setGroupedThreatRegisterQuery(DEFAULT_GROUP_THREATS_REGISTER_PAGINATION_PARAMS);
      dispatch.threats.setQuery(DEFAULT_THREATS_REGISTER_PAGINATION_PARAMS);
      dispatch.threats.setGroupedThreatRegisterList(null);
      dispatch.threats.setThreatRegisterList(null);
    };
  }, [threatModelId]);

  useEffect(() => {
    if (registerLiveUpdateData && currentThreatModel && registerLiveUpdateData === currentThreatModel.id) {
      fetchData();
    }

    dispatch.threatModel.setRegisterLiveUpdateData(null);
  }, [registerLiveUpdateData]);

  useEffect(() => {
    if (threatModelId && threatRegisterSelectedThreat) {
      const localStorageSelectedThreatTitle = threatRegisterSelectedThreat?.title;
      const localStorageSelectedThreatDescription = threatRegisterSelectedThreat?.description;

      if (localStorageSelectedThreatTitle) {
        dispatch.threats.getGroupedThreatRegisterList({
          ...groupedThreatRegisterListQuery,
          threatModelId,
        });
        dispatch.threats.getThreatRegisterList({
          ...threatRegisterListQuery,
          threatModelId,
          threatTitle: localStorageSelectedThreatTitle,
          threatDescription: localStorageSelectedThreatDescription,
          page: 0,
          sort: 'created_at',
          order: threatRegisterListQuery.order === ORDER_TYPE.ASC ? ORDER_TYPE.DESC : ORDER_TYPE.ASC,
        });
      }
    }
  }, [forceUpdateThreatId, threatRegisterSelectedThreat]);

  useEffect(() => {
    if (!threatModelId) return;

    const localStorageThreat = getThreatRegisterSelectedThreat();
    const localStorageSelectedThreatTitle = localStorageThreat?.title;
    const localStorageSelectedThreatDescription = localStorageThreat?.description;

    if (localStorageSelectedThreatDescription && localStorageSelectedThreatTitle) {
      dispatch.threats.setThreatRegisterSelectedThreat({
        title: localStorageSelectedThreatTitle,
        description: localStorageSelectedThreatDescription,
      });
      dispatch.threats.getThreatRegisterList({
        ...threatRegisterListQuery,
        threatModelId,
        threatTitle: localStorageSelectedThreatTitle,
        threatDescription: localStorageSelectedThreatDescription,
        page: 0,
        sort: 'created_at',
        order: threatRegisterListQuery.order === ORDER_TYPE.ASC ? ORDER_TYPE.DESC : ORDER_TYPE.ASC,
      });
    }
  }, [threatModelId]);

  useEffect(() => {
    if (tableRef?.current?.style) {
      tableRef.current.style.height = getTableHeight(params, threatRegisterDetailHeight);
    }
  }, [threatRegisterDetailHeight]);

  const handleEmitAfterModelUpdate = () => {
    if (currentThreatModel) {
      emitGlobalUpdate({ threatModels: [currentThreatModel.id] });
    }
  };

  const handleClickRow = (threat: any) => {
    if (isReadMode(interactionMode)) return;

    navigate(`${PROJECTS_ROUTE}/${params.id}/d/${params.diagramId}${THREATS_REGISTER_ROUTE}/${threat.id}`);
    dispatch.drawn.getThreat(threat.id);
  };

  const handleOpenRowClick = async (groupedThreat: IThreat) => {
    dispatch.threats.setThreatRegisterList(null);

    if (
      groupedThreat.title === threatRegisterSelectedThreat?.title &&
      groupedThreat.description === threatRegisterSelectedThreat?.description
    ) {
      dispatch.threats.setThreatRegisterSelectedThreat(null);
      navigate(`${PROJECTS_ROUTE}/${params.id}/d/${params.diagramId}${THREATS_REGISTER_ROUTE}`);
    } else {
      dispatch.threats.setThreatRegisterSelectedThreat({
        title: groupedThreat.title,
        description: groupedThreat.description,
      });
    }
  };

  const handleTableSort = (column: string) => {
    dispatch.threats.getGroupedThreatRegisterList({
      ...groupedThreatRegisterListQuery,
      page: 0,
      threatModelId,
      sort: column,
      order: groupedThreatRegisterListQuery.order === ORDER_TYPE.ASC ? ORDER_TYPE.DESC : ORDER_TYPE.ASC,
    });
  };

  const handleSubTableSort = (column: string) => {
    dispatch.threats.getThreatRegisterList({
      ...threatRegisterListQuery,
      page: 0,
      threatModelId,
      sort: column,
      order: threatRegisterListQuery.order === ORDER_TYPE.ASC ? ORDER_TYPE.DESC : ORDER_TYPE.ASC,
    });
  };

  const handleChangeModalStatus = (status: THREAT_STATUS) => {
    if (isReadMode(interactionMode)) return;

    setModalStatus(status);
  };

  const handleChangeModalPriority = (priority: THREAT_PRIORITY) => {
    if (isReadMode(interactionMode)) return;

    setModalPriority(priority);
  };

  const handleCloseModal = () => {
    setModal({
      visible: false,
      isPriority: false,
      isStatus: false,
      selectedTitle: '',
    });
    setModalPriority(undefined);
    setModalStatus(undefined);
  };

  const onLoadMoreClick = async () => {
    const nextPage = threatRegisterListQuery.page + 1;

    if (nextPage * threatRegisterListQuery.limit < threatRegisterListQuery.count) {
      setLoadingMore(true);
      await dispatch.threats.getThreatRegisterList({
        ...threatRegisterListQuery,
        page: nextPage,
      });
      setLoadingMore(false);
    }
  };

  const handleUpdateMany = async () => {
    let status;

    if (modal.visible) {
      if (modal.isPriority) {
        status = await dispatch.threats.updateManyThreatsByTitleOrComponentId({
          titleOrId: {
            title: modal.selectedTitle,
          },
          threatModelId: currentThreatModel?.id,
          updateFields: {
            priority: modalPriority,
          },
        });
      }

      if (modal.isStatus) {
        status = await dispatch.threats.updateManyThreatsByTitleOrComponentId({
          titleOrId: {
            title: modal.selectedTitle,
          },
          threatModelId: currentThreatModel?.id,
          updateFields: {
            status: modalStatus,
          },
        });
      }
    }

    if (status && status === 200) {
      setModal({
        visible: false,
        isPriority: false,
        isStatus: false,
        selectedTitle: '',
      });
      await dispatch.threats.getGroupedThreatRegisterList({
        ...groupedThreatRegisterListQuery,
        threatModelId,
      });
      await dispatch.threats.getThreatRegisterList({
        ...threatRegisterListQuery,
        threatModelId,
      });
      handleEmitAfterModelUpdate();
      toast.success('Threats update successfully!');
    }
  };

  const fetchMoreData = async () => {
    const nextPage = groupedThreatRegisterListQuery.page + 1;

    if (nextPage * groupedThreatRegisterListQuery.limit < groupedThreatRegisterListQuery.count) {
      setLoading(true);
      await dispatch.threats.getGroupedThreatRegisterList({
        ...groupedThreatRegisterListQuery,
        page: nextPage,
      });
      setLoading(false);
    }
  };

  const onHoverDescription = (event: any) => {
    const rect = event.target.getBoundingClientRect();
    const bottomMargin = params?.['*']?.length ? threatRegisterDetailHeight || 335 : 0;
    const top = rect.top + 150 + bottomMargin;

    if (top > document.body.clientHeight) {
      event.target.classList.add(styles.bottomTooltip);
    }
  };

  if (groupedThreatRegisterList === null) {
    return (
      <div className={styles.tableContent}>
        <Skeleton
          style={{ lineHeight: '25px', marginBottom: '5px' }}
          width="100%"
          height={30}
          count={15}
          borderRadius={10}
          baseColor={SKELETON_COLORS.BASE}
          highlightColor={SKELETON_COLORS.HIGHLIGHT}
        />
      </div>
    );
  }

  if (Array.isArray(groupedThreatRegisterList) && !groupedThreatRegisterList.length)
    return <div className="w-100 fs-12 text-center">No Threats</div>;

  return (
    <div className={styles.tableContent}>
      <InfiniteScroll
        scrollableTarget="scrollableTargetTable"
        next={fetchMoreData}
        hasMore={canFetchMoreGroup}
        loader={
          loading ? (
            <div className="w-100 m-auto text-center">
              <Progress
                animated
                color="secondary"
                value="100"
                style={{
                  height: '7px',
                  width: '100px',
                  margin: 'auto',
                }}
              />
            </div>
          ) : null
        }
        dataLength={groupedThreatRegisterList?.length || 0}
      >
        <div
          id="scrollableTargetTable"
          ref={tableRef}
          style={{
            height: getTableHeight(params, 335),
          }}
          className={styles.tableWrap}
        >
          <table className={styles.table}>
            <thead className="position-sticky top-0 z-3">
              <tr>
                <th style={{ width: '3%' }} className={styles.tableHead}>
                  Title
                  <ThreatRegisterTableSortColumn column="title" onSort={handleTableSort} />
                </th>
                <th style={{ width: '10%' }} className={styles.tableHead}>
                  Description
                </th>
                <th style={{ width: '10%' }} className={styles.tableHead}>
                  Status
                </th>
                <th style={{ width: '10%', minWidth: '90px' }} className={styles.tableHead}>
                  Priority
                </th>
                <th style={{ width: '10%', minWidth: '90px', paddingRight: '22px' }} className={styles.tableHead}>
                  Total Elements Impacted
                  <ThreatRegisterTableSortColumn column="total_amount" onSort={handleTableSort} />
                </th>
              </tr>
            </thead>
            <tbody id="scrollableTargetTable">
              {groupedThreatRegisterList?.map((groupedThreat: any, index) => (
                <Fragment key={`${groupedThreat.id}+${index}`}>
                  <tr
                    onClick={() => handleOpenRowClick(groupedThreat)}
                    className={classNames({
                      [styles.activeRow]: (params?.['*'] || '').includes(groupedThreat.id),
                    })}
                  >
                    <td className={styles.titleTd}>
                      <span className={styles.title}>{groupedThreat.title}</span>
                      {threatRegisterSelectedThreat?.title === groupedThreat.title &&
                        threatRegisterSelectedThreat?.description === groupedThreat.description && (
                          <CaretUpFill className={styles.tableIconUp} />
                        )}
                      {(threatRegisterSelectedThreat?.title !== groupedThreat.title ||
                        threatRegisterSelectedThreat?.description !== groupedThreat.description) && (
                        <CaretDownFill className={styles.tableIconDown} />
                      )}
                    </td>
                    <td
                      onMouseEnter={onHoverDescription}
                      onMouseLeave={(e: any) => {
                        e.target.classList.remove(styles.bottomTooltip);
                      }}
                      className={classNames(styles.hasTooltip, styles.descriptionColumn, 'position-relative', {
                        [styles.disableTooltip]: !groupedThreat?.description?.length,
                      })}
                      data-tooltip={groupedThreat.description}
                    >
                      {groupedThreat.description}
                    </td>
                    <td
                      onClick={(e) => {
                        if (isReadMode(interactionMode)) return;

                        e.preventDefault();
                        e.stopPropagation();
                        setModal({ visible: true, selectedTitle: groupedThreat.title, isStatus: true });
                      }}
                    >
                      <div className={styles.statusCel}>
                        {groupedThreat.statuses
                          .split(',')
                          .map((status: THREAT_STATUS) => capitalizeFirstLetter(status.trim()))
                          .join(', ')}
                      </div>
                    </td>
                    <td
                      onClick={(event: any) => {
                        if (isReadMode(interactionMode)) return;

                        event.preventDefault();
                        event.stopPropagation();
                        setModal({ visible: true, selectedTitle: groupedThreat.title, isPriority: true });
                      }}
                    >
                      <div className={styles.priorityCel}>
                        {groupedThreat.priorities
                          .replaceAll(' ', '')
                          .split(',')
                          ?.map((p: THREAT_PRIORITY) => (
                            <UiPriority type={p} size="smallItemNoAbsolute" key={p} />
                          ))}
                      </div>
                    </td>
                    <td>{groupedThreat?.total_amount}</td>
                  </tr>
                  {threatRegisterSelectedThreat?.title === groupedThreat.title &&
                    threatRegisterSelectedThreat?.description === groupedThreat?.description &&
                    groupedThreat.total_amount > 0 && (
                      <tr>
                        <td style={{ padding: 0 }} colSpan={5}>
                          <table className={styles.subTable}>
                            <thead className="position-sticky top-1 z-1">
                              <tr>
                                <th style={{ width: '15%' }} className={styles.tableHead}>
                                  Impacted Element
                                </th>
                                <th style={{ width: '10%', paddingRight: '21px' }} className={styles.tableHead}>
                                  Threat Status
                                  <ThreatRegisterTableSortColumn column="status" onSort={handleSubTableSort} />{' '}
                                </th>
                                <th style={{ width: '10%', minWidth: '90px' }} className={styles.tableHead}>
                                  Priority
                                  <ThreatRegisterTableSortColumn column="priority" onSort={handleSubTableSort} />{' '}
                                </th>
                                <th style={{ width: '40%' }} className={styles.tableHead}>
                                  Mitigation
                                </th>
                              </tr>
                            </thead>
                            <tbody id="scrollableTargetTable">
                              {threatRegisterList === null && (
                                <tr>
                                  <td colSpan={5} style={{ padding: '10px 2px' }}>
                                    <Skeleton
                                      style={{ lineHeight: '25px', marginBottom: '5px' }}
                                      width="100%"
                                      height={30}
                                      count={3}
                                      borderRadius={10}
                                      baseColor={SKELETON_COLORS.BASE}
                                      highlightColor={SKELETON_COLORS.HIGHLIGHT}
                                    />
                                  </td>
                                </tr>
                              )}
                              {threatRegisterList?.map((threat: any, index) => (
                                <tr
                                  key={`${threat.id}+${index}`}
                                  onClick={() => handleClickRow(threat)}
                                  className={classNames({
                                    [styles.activeRow]: (params?.['*'] || '').includes(threat.id),
                                  })}
                                >
                                  <td>{threat?.component?.title}</td>
                                  <td
                                    onClick={(e) => {
                                      if (isReadMode(interactionMode)) return;

                                      e.preventDefault();
                                      e.stopPropagation();
                                      setIsStatus(threat.id);
                                    }}
                                  >
                                    <div className={styles.statusCel}>
                                      {capitalizeFirstLetter(threat.status)}
                                      {isStatus === threat.id && (
                                        <ThreatRegisterTableStatusDropdown
                                          emitUpdate={emitUpdate}
                                          threat={threat}
                                          setVisible={setIsStatus}
                                          handleEmitAction={handleEmitAfterModelUpdate}
                                          type="threat"
                                          styles={{
                                            position: 'absolute',
                                            top: '80%',
                                            zIndex: 1,
                                          }}
                                        />
                                      )}
                                    </div>
                                  </td>
                                  <td
                                    onClick={(event: any) => {
                                      if (isReadMode(interactionMode)) return;

                                      event.stopPropagation();
                                      setIsPriority(threat.id);
                                    }}
                                  >
                                    <div style={{ top: '4px' }} className={styles.priorityCel}>
                                      <UiPriority type={threat.priority} size="smallItem" />
                                      {isPriority === threat.id && (
                                        <ThreatRegisterTablePriorityDropdown
                                          threat={threat}
                                          setVisible={setIsPriority}
                                          handleEmitAction={handleEmitAfterModelUpdate}
                                          type="threat"
                                          emitUpdate={emitUpdate}
                                        />
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <ThreatRegisterTableMitigation
                                      isReadMode={isReadMode(interactionMode)}
                                      threat={threat}
                                      handleEmitAction={handleEmitAfterModelUpdate}
                                      emitUpdate={emitUpdate}
                                    />
                                  </td>
                                </tr>
                              ))}
                              {threatRegisterList && threatRegisterListQuery.count > threatRegisterList.length && (
                                <tr onClick={onLoadMoreClick}>
                                  <td style={{ textAlign: 'center' }} colSpan={5}>
                                    {!loadingMore && 'Load more'}
                                    {loadingMore && (
                                      <div className="w-100 m-auto text-center">
                                        <Spinner color="dark" size="sm" />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
      {modal.visible && (
        <UiModal
          title={modal.isStatus ? 'Update status' : modal.isPriority ? 'Update priority' : ''}
          onClose={handleCloseModal}
        >
          {modal.isStatus && (
            <div className="d-flex flex-column gap-4">
              <div>
                Are you sure you want to update status for all the{' '}
                <span className="fw-bold">&quot;{modal.selectedTitle}&quot;</span> threats
              </div>
              <div>
                <ThreatDropdown
                  label="Status"
                  defaultValue="Choose status"
                  value={modalStatus}
                  options={Object.values(THREAT_STATUS)}
                  onChange={(status: THREAT_STATUS) => {
                    handleChangeModalStatus(status);
                    // handleEmitAfterUpdate();
                  }}
                />
              </div>
              <div className="d-flex justify-content-end gap-2">
                <UiButton type="transparent" onClick={handleCloseModal}>
                  Cancel
                </UiButton>
                <UiButton disabled={modalStatus === undefined} onClick={handleUpdateMany}>
                  Update
                </UiButton>
              </div>
            </div>
          )}
          {modal.isPriority && (
            <div className="d-flex flex-column gap-4">
              <div>
                Are you sure you want to update priority for all the{' '}
                <span className="fw-bold">&quot;{modal.selectedTitle}&quot;</span> threats
              </div>
              <div>
                <ThreatDropdown
                  label="Priority"
                  defaultValue="Choose priority"
                  value={modalPriority}
                  options={Object.values(THREAT_PRIORITY)}
                  onChange={(priority: THREAT_PRIORITY) => {
                    handleChangeModalPriority(priority);
                    // handleEmitAfterUpdate();
                  }}
                />
              </div>
              <div className="d-flex justify-content-end gap-2">
                <UiButton type="transparent" onClick={handleCloseModal}>
                  Cancel
                </UiButton>
                <UiButton disabled={modalPriority === undefined} onClick={handleUpdateMany}>
                  Update
                </UiButton>
              </div>
            </div>
          )}
        </UiModal>
      )}
    </div>
  );
};
