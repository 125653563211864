import classNames from 'classnames';
import type { FC, SyntheticEvent } from 'react';
import { Check2All, Clipboard2X, ClockHistory, X } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { MITIGATION_STATUS } from '../../../store/constants/drawn-constants';
import type { IMitigation } from '../../../store/models/drawn';
import type { Dispatch } from '../../../store/store';
import styles from './index.module.css';

type MitigationItemProps = {
  data: IMitigation;
  onRemove: (needComponentUpdate: boolean, isOk?: boolean) => void;
  onClick?: (e: SyntheticEvent) => void;
  disableOnclick?: boolean;
  isReadMode?: boolean;
};

const MitigationItem: FC<MitigationItemProps> = ({
  data,
  onRemove,
  onClick,
  disableOnclick = false,
  isReadMode = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const handleRemove = async (event: any) => {
    if (isReadMode) return;

    event.stopPropagation();
    const isOk = await dispatch.drawn.deleteMitigation(data);
    onRemove(false, isOk);
  };

  return (
    <div
      className={classNames(
        'p-1 rounded d-flex justify-content-between gap-1 align-items-center fs-12 w-auto',
        styles.mitigation,
        styles[data?.status || ''],
      )}
      onClick={(e) => {
        onClick && onClick(e);

        if (disableOnclick) return;

        navigate(`m/${data.id}`);
      }}
    >
      {data.status === MITIGATION_STATUS.DONE && <Check2All fontSize={15} />}
      {data.status === MITIGATION_STATUS.WILL && <ClockHistory fontSize={15} />}
      {data.status === MITIGATION_STATUS.NEVER && <Clipboard2X fontSize={15} color="#EA4335" />}
      {data.title}
      <X className="cursor-pointer" color="#6F767E" fontSize={18} onClick={handleRemove} />
    </div>
  );
};

export default MitigationItem;
