import classNames from 'classnames';
import { useFormik } from 'formik';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { Plus, X } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Input, Label, Spinner } from 'reactstrap';
import * as Yup from 'yup';

import CopyIcon from '../../../assets/icons/CopyIcon.svg';
import type { SAML_CONFIG } from '../../../global/types';
import { getEncryptionCertificateFile, getFileFromMetadataXml } from '../../../helpers/app';
import { copyQrCodeToNavigator } from '../../../helpers/user';
import type { Dispatch } from '../../../store/store';
import { CollapseSection } from '../../CollapseSection';
import UiModal from '../Modal/UiModal';
import UiButton from '../UIButton/UiButton';
import { EncryptedResponseField } from './EncryptedResponseField';
import styles from './ManageSamlForm.module.css';
import { MetadataField } from './MetadataField';

type SamlFormProps = {
  onClose: (needUpdateConfig?: boolean) => void;
  setSamlConfig: (config: SAML_CONFIG | null) => void;
  isConfigured: boolean | null;
  samlConfig: SAML_CONFIG;
};

const validationSchema = Yup.object().shape({
  idpSignOut: Yup.string().optional(),
  email: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  identifiers: Yup.array().min(1).required('Required'),
  metadataFile: Yup.mixed().test('oneOfRequired', 'XML file is required', function (value) {
    return value || this.parent.metadataUrl;
  }),
  metadataUrl: Yup.string()
    .url('Must be a valid URL')
    .test('oneOfRequired', 'Metadata Url is required', function (value) {
      return value || this.parent.metadataFile;
    }),
});

export const SamlForm: FC<SamlFormProps> = ({ onClose, samlConfig, isConfigured, setSamlConfig }) => {
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState('');
  const touchedFields = useRef<Record<string, boolean>>({});

  const { values, handleSubmit, setFieldValue, handleChange, resetForm, isValid, dirty, errors, validateField } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        metadataUrl: samlConfig.metadataUrl || '',
        identifiers: samlConfig.identifiers || [],
        identifier: '',
        email: samlConfig.attributeMapping?.email || '',
        lastName: samlConfig.attributeMapping?.lastName || '',
        firstName: samlConfig.attributeMapping?.firstName || '',
        idpSignOut: samlConfig.idpSignOut || false,
        metadataFile: samlConfig.metadataFile,
        encryptedResponses: samlConfig.encryptedResponses || false,
      },
      validationSchema,
      onSubmit: async (values) => {
        const { email, firstName, identifiers, lastName, metadataUrl, idpSignOut, metadataFile, encryptedResponses } =
          values;
        setLoading('save');

        const config = await dispatch.user.saveSamlConfig({
          data: {
            metadataUrl,
            identifiers,
            attributeMapping: { email, lastName, firstName },
            idpSignOut,
            metadataFile,
            encryptedResponses,
          },
          isConfigured,
        });

        if (config) {
          dispatch.customer.setIsSamlConfigured(true);
          resetForm();
          setSamlConfig({
            acsUrl: config.acsUrl,
            entityId: config.entityId,
            logoutUrl: config.logoutUrl,
            identifiers: config?.identifiers || [],
            metadataUrl: config?.metadataUrl,
            idpSignOut: config?.idpSignOut,
            encryptedResponses: config?.encryptedResponses,
            attributeMapping: config?.attributeMapping,
            metadataFile: getFileFromMetadataXml(config?.metadataFile),
            activeEncryptionCertificate: getEncryptionCertificateFile(config?.activeEncryptionCertificate),
          });
        }

        setLoading('');
      },
    });

  const handleCopy = async (text: string) => {
    const result = await copyQrCodeToNavigator(text);

    if (result) toast.success('Copied to clipboard!');
    else toast.warning('Could not copy!');
  };

  const handleFieldChange = (fieldName: keyof typeof values) => (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);

    touchedFields.current[fieldName] = true;
  };

  const handleClearIdentifire = () => {
    setFieldValue('identifier', '');
  };

  const handleCloseModal = () => {
    onClose();
    resetForm();
  };

  const isError = (fieldName: keyof typeof values) => {
    return Boolean(errors[fieldName] && touchedFields.current[fieldName]);
  };

  return (
    <UiModal title="Manage SAML SSO" onClose={handleCloseModal}>
      <form onSubmit={handleSubmit}>
        <CollapseSection title="Pass the Entity ID and ACS Url by copying them" defaultOpen>
          <div className="d-flex gap-3 mt-3">
            <div
              className={styles.copyButton}
              onClick={() => {
                if (samlConfig.entityId) handleCopy(samlConfig.entityId);
              }}
            >
              <span className="fw-bold me-2">Entity ID:</span>
              <img src={CopyIcon} alt="copy-icon" />
            </div>
            <div
              className={styles.copyButton}
              onClick={() => {
                if (samlConfig.acsUrl) handleCopy(samlConfig.acsUrl);
              }}
            >
              <span className="fw-bold me-2">ACS Url:</span>
              <img src={CopyIcon} alt="copy-icon" />
            </div>
          </div>

          <div className="d-flex gap-3 mt-3">
            <div
              className={classNames(styles.copyButton, 'bg-white ps-0 justify-content-start')}
              style={{ paddingRight: 32 }}
            >
              <div>
                <Input
                  checked={values.idpSignOut}
                  onChange={(e) => {
                    setFieldValue('idpSignOut', e.target.checked);
                  }}
                  className="me-2"
                  id="logutUrl"
                  type="checkbox"
                  title="Use logout url"
                />
                <Label className="mb-0 user-select-none cursor-pointer" htmlFor="logutUrl">
                  Use Logout flow
                </Label>
              </div>
            </div>
            {values.idpSignOut ? (
              <div
                className={styles.copyButton}
                onClick={() => {
                  if (samlConfig.logoutUrl) handleCopy(samlConfig.logoutUrl);
                }}
              >
                <span className="fw-bold me-2">Logout Url:</span>
                <img src={CopyIcon} alt="copy-icon" />
              </div>
            ) : (
              <div className={styles.copyButton} style={{ visibility: 'hidden' }} />
            )}
          </div>
        </CollapseSection>

        <hr />
        <CollapseSection title="Enter the URL link where you want to drag data" defaultOpen>
          <MetadataField
            isError={isError}
            metadataFile={values.metadataFile}
            metadataFileError={errors.metadataFile}
            setFieldValue={setFieldValue}
            metadataUrl={values.metadataUrl}
            metadataUrlError={errors.metadataUrl}
            touchField={(field: string) => {
              touchedFields.current[field] = true;
            }}
          />
        </CollapseSection>
        <hr />
        <CollapseSection title="Fill in the matching fields" defaultOpen>
          <div className="d-flex flex-column mt-3 mb-3 gap-2">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <label
                className={`${isError('firstName') ? styles.errorLabel : ''} ${styles.importantField} fs-14`}
                htmlFor="firstName"
              >
                First Name:
              </label>
              <input
                id="firstName"
                className={`${isError('firstName') ? styles.fieldError : ''} form-control fs-14`}
                type="text"
                placeholder="Enter First Name"
                name="firstName"
                value={values.firstName}
                onChange={handleFieldChange('firstName')}
                style={{ maxWidth: 275 }}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center gap-2">
              <label
                className={`${isError('lastName') ? styles.errorLabel : ''} ${styles.importantField} fs-14`}
                htmlFor="lastName"
              >
                Last Name:
              </label>
              <input
                id="lastName"
                className={`${isError('lastName') ? styles.fieldError : ''} form-control fs-14`}
                type="text"
                placeholder="Enter Last Name"
                name="lastName"
                value={values.lastName}
                onChange={handleFieldChange('lastName')}
                style={{ maxWidth: 275 }}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center gap-2">
              <label
                className={`${isError('email') ? styles.errorLabel : ''} ${styles.importantField} fs-14`}
                htmlFor="email"
              >
                Email:
              </label>
              <input
                id="email"
                className={`${isError('email') ? styles.fieldError : ''} form-control fs-14`}
                type="text"
                placeholder="Enter Email"
                name="email"
                value={values.email}
                onChange={handleFieldChange('email')}
                style={{ maxWidth: 275 }}
              />
            </div>
          </div>
        </CollapseSection>
        <hr />
        <CollapseSection title="Enter identifiers" defaultOpen>
          <div className="mt-3 mb-3">
            <div className={classNames('d-flex gap-2 mb-2 flex-wrap', styles.identifireWrap)}>
              {values.identifiers.map((idn, idx) => (
                <div key={idn + idx} className={styles.identifire}>
                  {idn}
                  <span
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setFieldValue('identifiers', [...values.identifiers.filter((i) => i !== idn)]);
                    }}
                  >
                    <X size={16} />
                  </span>
                </div>
              ))}
            </div>
            <div className="d-flex gap-2 position-relative">
              <input
                value={values.identifier}
                className={`${isError('identifiers') ? styles.fieldError : ''} ${styles.identifireField} form-control`}
                type="text"
                id="identifiers"
                placeholder="company-domain.com"
                name="identifier"
                onChange={handleFieldChange('identifiers')}
              />
              {values.identifier && (
                <div className={styles.identifireActions}>
                  <X size={16} onClick={handleClearIdentifire} className="cursor-pointer" />
                  <div
                    className={styles.addIdentifireButton}
                    onClick={() => {
                      setFieldValue('identifiers', [...values.identifiers, values.identifier]).then(() => {
                        validateField('identifiers');
                      });

                      touchedFields.current.identifiers = true;
                      handleClearIdentifire();
                    }}
                  >
                    <Plus size={16} color="#0062D8" />
                    <div className="color">Add</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </CollapseSection>
        <hr />
        <CollapseSection title="Advanced Settings" defaultOpen={false}>
          <EncryptedResponseField
            encryptedResponses={values.encryptedResponses}
            setFieldValue={setFieldValue}
            certificate={samlConfig.activeEncryptionCertificate}
          />
        </CollapseSection>
        <hr />

        <div className="d-flex justify-content-end gap-2">
          <UiButton type="transparent" onClick={handleCloseModal}>
            Cancel
          </UiButton>
          <UiButton disabled={!(dirty && isValid)} htmlType="submit" style={{ width: '100px' }}>
            {loading === 'save' ? <Spinner color="light" size="sm" /> : <div>Save</div>}
          </UiButton>
        </div>
      </form>
    </UiModal>
  );
};
