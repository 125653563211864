import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { BoundingBox, Circle, Square } from 'react-bootstrap-icons';
import CircleSquareIcon from '../../../assets/icons/CircleSquareIcon.svg';
import { NODES } from '../../../global/constants';
import { getDefaultNodeSize } from '../../../helpers/diagram';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import styles from './index.module.css';

type NodeTypeDropdownProps = {
  itemsVisible: boolean;
  setItemsVisible: (value: boolean) => void;
  id?: string;
  type?: string;
};

const NodeTypeDropdown: FC<NodeTypeDropdownProps> = ({ itemsVisible, setItemsVisible, id, type }) => {
  const { nodesSharedState } = useTypedSelector((state) => state.diagram);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  useEffect(() => {
    const element: any = document.querySelector(`[data-id="${id}"]`);

    if (element) element.style.height = 'auto';

    if (element) element.style.width = 'auto';
  }, [id]);

  const handleSelect = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.target.id && id) {
      const node = nodesSharedState?.get(id);

      if (node) {
        const nodeSize = getDefaultNodeSize(e.target.id);
        node.type = e.target.id;
        node.width = nodeSize.width;
        node.height = nodeSize.height;

        if (node.style) {
          node.style.height = Number(nodeSize.height);
          node.style.width = Number(nodeSize.width);
        }

        nodesSharedState && nodesSharedState.set(node.id, node);
      }
    }

    setItemsVisible(false);
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      onClick={() => setItemsVisible(!itemsVisible)}
      className={classNames(
        {
          [styles.activeDropDown]: itemsVisible,
        },
        styles.dropdown,
        'd-flex align-items-center justify-content-between rounded p-1 px-2 cursor-pointer position-relative',
      )}
    >
      {showTooltip && !itemsVisible && <div className="main-tooltip">Change Element</div>}
      <div
        className={classNames(styles.input, 'd-flex position-relative align-items-center')}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img src={CircleSquareIcon} alt="CircleSquareIcon" />
      </div>
      {itemsVisible && (
        <div className={classNames(styles.dropdownItems, 'd-flex align-items-center position-absolute rounded')}>
          {type !== NODES.PROCESS_NODE && (
            <div className={classNames(styles.item)}>
              <Circle fontSize={16} onClick={handleSelect} id={NODES.PROCESS_NODE} />
            </div>
          )}
          {/* {type !== "datastoreNode" && <Diamond className="cursor-pointer" fontSize={16} onClick={handleSelect} id="datastoreNode"/>} */}
          {type !== NODES.DATA_STORE_NODE && (
            <div className={classNames(styles.item)}>
              <div
                className={classNames(styles.dataStoreIcon, 'cursor-pointer m-1')}
                onClick={handleSelect}
                id={NODES.DATA_STORE_NODE}
              />
            </div>
          )}
          {type !== NODES.EXTERNAL_ENTITY_NODE && (
            <div className={classNames(styles.item)}>
              <Square fontSize={16} onClick={handleSelect} id={NODES.EXTERNAL_ENTITY_NODE} />
            </div>
          )}
          {type !== NODES.TRUST_BOUNDARY_NODE && type === NODES.STICKER_NODE && (
            <div className={classNames(styles.item)}>
              <BoundingBox
                className={classNames(styles.item)}
                fontSize={16}
                onClick={handleSelect}
                id={NODES.TRUST_BOUNDARY_NODE}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NodeTypeDropdown;
