import moment from 'moment';
import React, { memo, useEffect } from 'react';
import type { Id } from 'react-toastify';
import { toast } from 'react-toastify';
import UiModal from '../../../../components/common/Modal/UiModal';
import UiButton from '../../../../components/common/UIButton/UiButton';
import { useTimer } from '../../../../hooks/useTimer';

type OutdatedVersionModalProps = {
  closeModal: () => void;
  handleMoveToNewVersion: () => void;
  isOpen: boolean;
  author: string;
  date: Date;
};

const OutdatedVersionModal = ({
  handleMoveToNewVersion,
  closeModal,
  isOpen,
  author,
  date,
}: OutdatedVersionModalProps): JSX.Element | null => {
  const toastId = React.useRef<null | Id>(null);

  const formattedSecondsToMinutes = (seconds: number) => {
    return moment.utc(seconds * 1000).format('mm:ss');
  };

  const handleExpire = () => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
    }

    handleMoveToNewVersion();
  };

  const { seconds, onStart } = useTimer({
    duration: 180,
    isStart: false,
    onExpire: handleExpire,
    onChange: (seconds) => {
      if (toastId.current) {
        toast.update(toastId.current, {
          render: `Redirecting in ${formattedSecondsToMinutes(seconds)} seconds.`,
        });
      }
    },
  });

  const handleCompleteWork = () => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
    }

    toastId.current = toast.warn(`Redirecting in ${formattedSecondsToMinutes(seconds)} seconds.`, {
      autoClose: false,
    });

    closeModal();
  };

  useEffect(() => {
    if (isOpen) {
      onStart();
    }
  }, [isOpen, onStart]);

  return isOpen ? (
    <UiModal isCloseButton={false} title="ATTENTION">
      <p>
        {author} has reverted to an earlier version of this threat model, making the current version obsolete. Changes
        after {moment(date).format('YYYY-MM-DD HH:mm:ss')} are not in the updated model but are saved historically.
      </p>
      <b>Please take one of these actions</b>
      <ul style={{ paddingLeft: 18 }}>
        <li>
          <b>OK</b>: Go to the updated current model.
        </li>
        <li>
          <b>Complete Work</b>: Finish working in this model, before it's saved as a historical version.
        </li>
      </ul>
      <p />
      <p>
        If you don't take any action, you will be automatically moved to the updated model in{' '}
        {formattedSecondsToMinutes(seconds)} seconds.
      </p>
      <div className="d-flex gap-2 justify-content-end">
        <UiButton onClick={handleCompleteWork}>Complete Work</UiButton>
        <UiButton onClick={handleMoveToNewVersion}>OK</UiButton>
      </div>
    </UiModal>
  ) : null;
};

export default memo(OutdatedVersionModal);
