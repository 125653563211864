import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { CheckCircleFill, DashCircleFill, Star, ThreeDotsVertical } from 'react-bootstrap-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { SKELETON_COLORS, TIER } from '../../global/constants';
import { capitalizeFirstLetter } from '../../helpers/user';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import { TIERS_PORTAL_LINK } from '../../store/constants/api-constants';
import http from '../../store/http/http-common';
import type { Dispatch } from '../../store/store';
import UiButton from '../common/UIButton/UiButton';
import UiPriority from '../common/UiPriority/UiPriority';
import UpgradePlan from '../UpgradePlan/UpgradePlan';
import UserRightBar from '../UserRightBar/UserRightBar';
import styles from './UserList.module.css';
import { UserRoleDropdown } from './UserRoleDropdown/UserRoleDropdown';
import UserSettingsModal from './UserSettingsModal/UserSettingsModal';

const UserList: FC = () => {
  const navigate = useNavigate();

  const [showSettings, setShowSettings] = useState<{
    visible: boolean;
    id: string;
  } | null>(null);
  const [userRightBarVisible, setUserRightBarVisible] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState<string>('');

  const dispatch = useDispatch<Dispatch>();
  const { users, current, query } = useTypedSelector((state) => state.user);
  const { customerLimits } = useTypedSelector((state) => state.tiers);
  const [loading, setLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState('');
  const [isPortalLoading, setIsPortalLoading] = useState(false);
  const canFetchMore = query.page * query.limit < query.count;

  useEffect(() => {
    dispatch.user.getUsers({ needAvatar: false });
  }, []);

  const handleSettingsClick = (userId: string) => {
    setShowSettings({ id: userId, visible: true });
  };

  const getStatus = (status: string | undefined) => {
    if (status === 'confirmed') {
      return <UiPriority label="Confirmed" width={95} type="low" />;
    }

    if (status === 'invited') {
      return <UiPriority label="Invited" width={70} type="medium" />;
    }

    if (status === 'n/a') {
      return <UiPriority label="N/A" width={70} type="medium" />;
    }

    if (status === 'external_idp') {
      return <UiPriority label="External IDP" width={100} type="medium" />;
    }

    return <UiPriority label="N/A" width={70} type="high" />;
  };

  const fetchMoreData = async () => {
    const nextPage = query.page + 1;

    if (nextPage * query.limit < query.count) {
      setLoading(true);
      await dispatch.user.getUsersByQuery({
        ...query,
        page: nextPage,
        needAvatar: false,
      });
      setLoading(false);
    }
  };

  const handleOpenPortalPage = async () => {
    setIsPortalLoading(true);
    try {
      const response = await http.get(TIERS_PORTAL_LINK);
      setIsPortalLoading(false);
      window.open(response.data.redirectUrl, '_blank');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classNames('content', styles.wrap)}>
      <div className={styles.header}>Users</div>
      <div className={styles.subHeader}>
        <UiButton onClick={() => navigate('/admin/users/add')}>Add User</UiButton>
        {customerLimits && !customerLimits.isBetaUser && (
          <div className="d-flex align-items-center">
            <div className="me-3">
              You are working in the {customerLimits.tier} version.{' '}
              <span
                className={classNames({
                  'text-danger': customerLimits.seats?.available === 0,
                })}
              >
                Number of available users {customerLimits.seats?.available}/{customerLimits.seats?.limit}
              </span>
            </div>
            {customerLimits.tier === TIER.FREE ? (
              <UpgradePlan />
            ) : (
              <UiButton onClick={handleOpenPortalPage}>
                {isPortalLoading ? <Spinner size="sm" /> : <Star />}
                <span style={{ marginLeft: '8px' }} />
                Add More Seats
              </UiButton>
            )}
          </div>
        )}
      </div>
      {users && (
        <div className={styles.tableWrap} id="scrollableTargetDiv">
          <InfiniteScroll
            scrollableTarget="scrollableTargetDiv"
            next={fetchMoreData}
            hasMore={canFetchMore}
            loader={
              loading ? (
                <div className="w-100 m-auto text-center">
                  <Spinner color="dark" />
                </div>
              ) : null
            }
            dataLength={users?.length || 0}
          >
            <table className={styles.table}>
              <thead className={styles.thead}>
                <tr>
                  <th className={styles.indexField}>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Role</th>
                  <th style={{ width: '75px' }}>Enabled</th>
                  <th className={styles.settingsTd} />
                </tr>
              </thead>
              <tbody>
                {users?.map((user, index) => (
                  <tr key={index}>
                    <td className={classNames('text-center position-relative', styles.indexField)}>{index + 1}</td>
                    <td>
                      {user.first_name} {user.last_name}
                    </td>
                    <td>{user.email}</td>
                    <td>{getStatus(user.status)}</td>
                    <td
                      className={classNames(styles.roleCel, {
                        [styles.noPointer]: current?.id === user.id,
                      })}
                      onClick={() => {
                        if (current?.id === user.id) return;

                        setCurrentUserRole(user.id);
                      }}
                    >
                      {roleLoading === user.id ? <Spinner size="sm" /> : capitalizeFirstLetter(user.role)}
                      {currentUserRole === user.id && (
                        <UserRoleDropdown user={user} setVisible={setCurrentUserRole} setLoading={setRoleLoading} />
                      )}
                    </td>
                    <td className="text-center">
                      {user.isEnabled ? (
                        <CheckCircleFill size={22} color="#36a159" />
                      ) : (
                        <DashCircleFill size={22} color="#eb6f0a" />
                      )}
                    </td>
                    <td
                      className={classNames(styles.settingsTd, {
                        [styles.noPointer]: current?.id === user.id,
                      })}
                      onClick={() => {
                        if (current?.id === user.id) return;

                        handleSettingsClick(user.id);
                      }}
                    >
                      {current?.id !== user.id && (
                        <span onClick={() => {}}>
                          <ThreeDotsVertical size={16} />
                        </span>
                      )}
                      {showSettings && showSettings.visible && showSettings.id === user.id && (
                        <div className="position-absolute">
                          <UserSettingsModal
                            setVisible={setShowSettings}
                            user={user}
                            onDelete={() => {
                              setUserRightBarVisible(true);
                              dispatch.user.getRemovedUserInfo(user.id);
                            }}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      )}
      {!users && (
        <div>
          <Skeleton
            height={40}
            width="100%"
            count={4}
            borderRadius={10}
            baseColor={SKELETON_COLORS.BASE}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
          />
        </div>
      )}
      <UserRightBar visible={userRightBarVisible} setVisible={setUserRightBarVisible} />
    </div>
  );
};

export default UserList;
