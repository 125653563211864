import classNames from 'classnames';
import type { FC, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import { ArrowsAngleContract, ArrowsAngleExpand, Dash, Map, Plus } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useReactFlow, useStore } from 'reactflow';
import CrosshairIcon from '../../../assets/icons/CrosshairIcon.svg';
import { closeFullscreen, openFullscreen } from '../../../helpers/app';
import { convertZoomToPercent, getNextZoomValue } from '../../../helpers/diagram';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import { CustomMinMap } from './CustomMinMap';
import styles from './index.module.css';

type ZoomButtonsProps = {
  noMargin?: boolean;
  isThreatsRegister?: boolean;
};

const ZoomButtons: FC<ZoomButtonsProps> = ({ noMargin = false, isThreatsRegister = false }: any) => {
  const dispatch = useDispatch<Dispatch>();
  const { visible, drawnWidth } = useTypedSelector((state) => state.drawn);
  const fullscreenMode = useTypedSelector((state) => state.app.fullscreenMode);
  const zoomLevel = useStore((store) => store.transform[2]) || 0;
  const { getViewport, fitView, zoomTo } = useReactFlow();
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    if (!isThreatsRegister) {
      dispatch.app.setViewport(getViewport());
    }
  }, [zoomLevel]);

  const onZoomOut = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const value = getNextZoomValue(zoomLevel, false);

    if (value) zoomTo(value);
  };

  const onZoomIn = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const value = getNextZoomValue(zoomLevel, true);

    if (value) zoomTo(value);
  };

  const onFitView = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    fitView();
  };

  const onToggleFullScreen = () => {
    if (!fullscreenMode) {
      openFullscreen(document.documentElement);
    } else {
      closeFullscreen(document);
      dispatch.app.setSidebarVisible(false);
    }

    dispatch.app.setFullScreenMode(!fullscreenMode);
  };

  const onToggleMap = () => {
    setShowMap(!showMap);
  };

  return (
    <div
      className={classNames('d-flex rounded-1 shadow bg-white align-items-center z-1 noScale', styles.toolVerticalItem)}
      style={
        noMargin
          ? {}
          : {
              marginRight: visible ? `${drawnWidth}px` : '70px',
              marginBottom: '5px',
            }
      }
    >
      <div className={styles.toolItem}>
        <div
          className={classNames(styles.hasTooltip, styles.bottomTooltip, 'p-0', {
            [styles.activeItem]: showMap,
          })}
          data-tooltip="Pin map"
        >
          <Map
            className={classNames(styles.zoomButton, 'noScale')}
            fontSize={16}
            color="#6F767E"
            onClick={onToggleMap}
          />
        </div>
      </div>
      <div className={styles.toolItem}>
        <div
          className={classNames(styles.hasTooltip, styles.bottomTooltip, 'p-0', {
            [styles.activeItem]: fullscreenMode,
          })}
          data-tooltip={fullscreenMode ? 'Minimize' : 'Fullscreen'}
        >
          {fullscreenMode ? (
            <ArrowsAngleContract
              className={classNames(styles.zoomButton, 'noScale')}
              fontSize={16}
              color="#6F767E"
              onClick={onToggleFullScreen}
            />
          ) : (
            <ArrowsAngleExpand
              className={classNames(styles.zoomButton, 'noScale')}
              fontSize={16}
              color="#6F767E"
              onClick={onToggleFullScreen}
            />
          )}
        </div>
      </div>
      <div className={styles.toolItem}>
        <div className={classNames(styles.hasTooltip, styles.bottomTooltip, 'p-0')} data-tooltip="Fit to screen">
          <img
            style={{ padding: '9px' }}
            src={CrosshairIcon}
            alt="CrosshairIcon"
            className="noScale"
            onClick={onFitView}
          />
        </div>
      </div>
      <div className={styles.toolItem}>
        <div className={classNames(styles.hasTooltip, styles.bottomTooltip, 'p-0')} data-tooltip="Zoom out">
          <Dash
            className={classNames(styles.zoomButton, 'noScale')}
            fontSize={16}
            color="#6F767E"
            onClick={onZoomOut}
          />
        </div>
      </div>
      <span className="fs-12 user-select-none text-center" style={{ color: '#6F767E' }}>
        {convertZoomToPercent(zoomLevel) || 1}%
      </span>
      <div className={styles.toolItem}>
        <div className={classNames(styles.hasTooltip, styles.bottomTooltip, 'p-0')} data-tooltip="Zoom in">
          <Plus className={classNames(styles.zoomButton, 'noScale')} fontSize={16} color="#6F767E" onClick={onZoomIn} />
        </div>
      </div>
      {showMap && <CustomMinMap />}
    </div>
  );
};

export default ZoomButtons;
