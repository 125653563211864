import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import { BarChartLine, Box2, CheckCircle, Diagram2, Gear, People, Shield, Star } from 'react-bootstrap-icons';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import {
  ADMIN_CODEX_ROUTE,
  ADMIN_ROUTE,
  ADMIN_SECURITY_ROUTE,
  ADMIN_USERS_ROUTE,
} from '../../store/constants/route-constants';
import type { Dispatch } from '../../store/store';
import MenuItem from './MenuItem';
import styles from './Sidebar.module.css';

import sidebarLogoIcon from '../../assets/icons/SidebarLogoIcon.svg';
import sidebarToggleIcon from '../../assets/icons/SidebarToggleIcon.svg';
import deviciLogo from '../../assets/images/devici-logo-light.svg';
import OnboardingModal from '../Onboarding';
import UpgradePlan from '../UpgradePlan/UpgradePlan';

import { TIER } from '../../global/constants';
import type { SIDEBAR_MENU_ITEMS } from '../../global/types';
import { ROLE } from '../../store/models/user';
import { ProductsFruitsComponent } from './ProductFruitsComponent';
import { ProfileItem } from './ProfileItem';

const Sidebar: FC<PropsWithChildren> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const [sidebarClosable, setSidebarClosable] = useState(false);
  const showSidebar = useTypedSelector((state) => state.app?.sidebarVisible);
  const isHideOnboarding = useTypedSelector((state) => state.auth?.isHideOnboarding);
  const projects = useTypedSelector((state) => state.project?.projects) || [];
  const { current } = useTypedSelector((state) => state.user);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const customerLimits = useTypedSelector((state) => state.tiers.customerLimits);

  const menuItems = [
    [
      {
        title: 'My Models',
        route: '/my-models',
        icon: <CheckCircle className={styles.icon} />,
      },
      {
        title: 'Collections',
        route: '/',
        icon: <Diagram2 className={styles.icon} />,
        children: projects,
        fetchChildren: dispatch.project.getProjects,
      },
      {
        title: 'Archive',
        route: '/archives',
        icon: <Box2 className={styles.icon} />,
      },
    ],
  ];

  const adminMenuItems: SIDEBAR_MENU_ITEMS = [
    [
      {
        title: 'Settings',
        route: ADMIN_ROUTE,
        icon: <Gear className={styles.icon} />,
        forAdmin: true,
      },
      {
        title: 'Users',
        route: ADMIN_USERS_ROUTE,
        icon: <People className={styles.icon} />,
        forAdmin: true,
      },
      {
        title: 'Security',
        route: ADMIN_SECURITY_ROUTE,
        icon: <Shield className={styles.icon} />,
        forAdmin: true,
      },
    ],
  ];

  if (customerLimits?.tier !== TIER.FREE) {
    adminMenuItems[0].push({
      title: 'Custom Codex',
      route: ADMIN_CODEX_ROUTE,
      icon: <BarChartLine style={{ zIndex: 1 }} className={styles.icon} />,
      forAdmin: true,
      children: [
        {
          id: `${ADMIN_CODEX_ROUTE}/attributes`,
          title: 'Attributes',
        },
        {
          id: `${ADMIN_CODEX_ROUTE}/threats`,
          title: 'Threats',
        },
        {
          id: `${ADMIN_CODEX_ROUTE}/mitigations`,
          title: 'Mitigations',
        },
      ],
    });
  }

  useEffect(() => {
    if (isHideOnboarding) {
      setShowOnboardingModal(false);

      return;
    }

    if (current && current.isOnboardingCompleted === false) {
      setShowOnboardingModal(true);
    }

    if (current?.isOnboardingCompleted) setShowOnboardingModal(false);
  }, [current?.isOnboardingCompleted, isHideOnboarding]);

  const handleMenuItemClick = (route: string | null) => {
    if (route !== null) {
      navigate(route);
    }
  };

  const handleDrawerClick = () => {
    if (!showSidebar) {
      setSidebarClosable(false);
    } else {
      setSidebarClosable(true);
    }

    dispatch.app.setSidebarVisible(!showSidebar);
  };

  const handleCloseModal = async () => {
    await dispatch.user.getCurrentUser();
    const threatModel = await dispatch.threatModel.createDefaultThreatModel();
    const projectId = threatModel?.project?.id;
    const diagramId = threatModel?.representationId;

    if (projectId && diagramId) {
      setShowOnboardingModal(false);
      navigate(`/collections/${projectId}/d/${diagramId}`);
    }
  };

  if (showOnboardingModal) {
    return createPortal(<OnboardingModal closeModal={handleCloseModal} />, document.body);
  }

  return (
    <div
      className={classNames(`${styles.sidebar}`, {
        [styles.sidebarShow]: showSidebar,
        [styles.sidebarHide]: sidebarClosable,
      })}
      id="sidebar-element"
    >
      <div className={styles.iconWrapper}>
        <img src={showSidebar ? sidebarToggleIcon : sidebarLogoIcon} onClick={handleDrawerClick} alt="" />
        <div hidden={!showSidebar}>
          <Link to="/">
            <img src={deviciLogo} alt="Devici logo" className={classNames(styles.sidebarLogo, 'noScale')} />
          </Link>
        </div>
      </div>
      <div className={styles.menu}>
        <div style={{ opacity: showSidebar ? 1 : 0 }} className={styles.menuGroupTitle}>
          Menu
        </div>
        {menuItems.map((group, index) => (
          <div key={index} className={styles.itemGroup}>
            {group.map((item, index) => (
              <MenuItem item={item} key={index} onClick={() => handleMenuItemClick(item.route)} />
            ))}
          </div>
        ))}
        {current?.role === ROLE.ADMIN && (
          <>
            <div style={{ opacity: showSidebar ? 1 : 0 }} className={styles.menuGroupTitle}>
              Managment
            </div>
            {adminMenuItems.map((group, index) => (
              <div key={index} className={styles.itemGroup}>
                {group.map((item, index) => (
                  <MenuItem item={item} key={index} onClick={() => handleMenuItemClick(item.route)} />
                ))}
              </div>
            ))}
          </>
        )}
        <UpgradePlan
          trigger={
            <div className="mt-auto mb-3">
              <MenuItem
                style={{
                  backgroundColor: 'var(--accent)',
                  color: 'var(--main-dark)',
                }}
                onClick={() => {}}
                item={{
                  title: 'Upgrade Plan',
                  route: '/upgrade',
                  icon: (
                    <Star
                      className={styles.icon}
                      style={{
                        backgroundColor: 'var(--accent)',
                        color: 'var(--main-dark)',
                      }}
                    />
                  ),
                }}
              />
            </div>
          }
        />
      </div>
      <div className={styles.userInfoWrap}>
        <ProfileItem />
      </div>
      {current?.email && <ProductsFruitsComponent user={current} />}
    </div>
  );
};

export default Sidebar;
