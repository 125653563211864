import classNames from 'classnames';
import type { FC } from 'react';
import React, { useState } from 'react';
import { ChevronDown, ChevronUp, ThreeDotsVertical } from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { SKELETON_COLORS } from '../../../global/constants';
import type { Project } from '../../../global/types';
import { useSocket } from '../../../hooks/useSocket';
import type { Dispatch } from '../../../store/store';
import DateInput from '../../common/DateInput/DateInput';
import UiModal from '../../common/Modal/UiModal';
import ProjectAvatar from '../../common/ProjectAvatar/ProjectAvatar';
import ReSettings from '../../common/ReSettings/ReSettings';
import UiButton from '../../common/UIButton/UiButton';
import UiPriority from '../../common/UiPriority/UiPriority';
import User from '../../User/User';
import styles from './ArchiveList.module.css';

interface IApiAccessModal {
  visible: boolean;
  isCollection?: boolean;
  isDelete?: boolean;
  isRestore?: boolean;
  selectedTitle?: string;
  selectedThreatModelId?: string;
  selectedProjectId?: string;
}

type ArchiveListProps = {
  archives: Project[] | null;
};

const ArchiveList: FC<ArchiveListProps> = ({ archives }) => {
  const dispatch = useDispatch<Dispatch>();
  const { emitGlobalUpdate } = useSocket();

  const [showSettings, setShowSettings] = useState<{
    visible: boolean;
    id: string;
  } | null>(null);

  const [modal, setModal] = useState<IApiAccessModal>({
    visible: false,
  });

  const [showThreatSettings, setShowThreatSettings] = useState<string | null>(null);

  const [openedProjectId, setOpenedProjectId] = useState('');
  const defaultModal = {
    visible: false,
    isCollection: false,
    isDelete: false,
    isRestore: false,
    selectedTitle: '',
    selectedProjectId: '',
    selectedThreatModelId: '',
  };
  const handleEmitAfterArchiveUpdate = (projectId?: string | undefined) => {
    const id = showSettings?.id || projectId;

    if (id) {
      emitGlobalUpdate({
        projects: [id],
        archives: [id],
      });
    }
  };

  const handleEmitForThreatModelAfterArchiveUpdate = (threatModelId?: string | undefined) => {
    if (threatModelId) {
      emitGlobalUpdate({
        threatModels: [threatModelId],
      });
    }
  };

  const handleSettingsClick = (project: Project) => {
    setShowSettings({ id: project.id, visible: true });
  };

  const handleDropDownLick = (project: Project, isClosing = false) => {
    if (isClosing) {
      setOpenedProjectId('');
    } else {
      setOpenedProjectId(project.id);
    }
  };

  const handleThreatSettingsClick = (threatModelId: string) => {
    setShowThreatSettings(threatModelId);
  };

  const handleRestoreCollectionClick = async () => {
    await dispatch.project.unArchiveProject(modal.selectedProjectId);
    handleEmitAfterArchiveUpdate();
    setShowSettings(null);
    setModal(defaultModal);
  };

  const handleRemoveCollectionClick = async () => {
    await dispatch.project.deleteProject(modal.selectedProjectId);
    handleEmitAfterArchiveUpdate();
    setShowSettings(null);
    setModal(defaultModal);
  };

  const handleThreatModelRestoreClick = async () => {
    if (modal.selectedThreatModelId) {
      await dispatch.threatModel.unArchive(modal.selectedThreatModelId);
      handleEmitAfterArchiveUpdate(modal.selectedProjectId);
      setShowThreatSettings(null);
      setModal(defaultModal);
    }
  };

  const handleRemoveThreatModelClick = async () => {
    if (modal.selectedThreatModelId) {
      await dispatch.threatModel.remove(modal.selectedThreatModelId);
      handleEmitAfterArchiveUpdate(modal.selectedProjectId);
      setShowThreatSettings(null);
      setModal(defaultModal);
    }
  };

  const handleCloseModal = () => {
    setModal(defaultModal);
  };

  return (
    <div className={styles.content}>
      <div className={styles.miniHeader}>Archive Collections</div>
      <div className={styles.content} id="scrollableTargetDiv">
        <table>
          <thead>
            {!archives ? (
              <tr className={styles.skeleton}>
                <th>
                  <Skeleton
                    height={40}
                    containerClassName={styles.skeleton}
                    count={1}
                    borderRadius={10}
                    baseColor={SKELETON_COLORS.BASE}
                    highlightColor={SKELETON_COLORS.HIGHLIGHT}
                  />
                </th>
              </tr>
            ) : (
              <tr>
                <th style={{ width: 86 }} />
                <th>Collection Name</th>
                {/* <th>Status</th> */}
                {/* <th>Team</th> */}
                <th className={styles.centered}>Modeling</th>
                <th style={{ marginLeft: '20px' }}>Owner</th>
              </tr>
            )}
          </thead>
          <tbody className={styles.tbody}>
            {archives?.map((project, index) => (
              <React.Fragment key={project.id}>
                <tr key={index} className={styles.mainTr} data-testid={`archive-item-${project.title}`}>
                  <td className={styles.settings}>
                    {project.id !== openedProjectId && (
                      <ChevronDown size={16} onClick={() => handleDropDownLick(project)} />
                    )}
                    {project.id === openedProjectId && (
                      <ChevronUp size={16} onClick={() => handleDropDownLick(project, true)} />
                    )}
                    {project.archived_at !== null && (
                      <ThreeDotsVertical onClick={() => handleSettingsClick(project)} size={16} />
                    )}
                    {showSettings != null && showSettings.visible && showSettings.id === project.id && (
                      <div className={styles.settingsModal}>
                        <ReSettings
                          setVisible={setShowSettings}
                          onRestoreClick={() => {
                            setModal({
                              visible: true,
                              isCollection: true,
                              isRestore: true,
                              isDelete: false,
                              selectedTitle: project.title,
                              selectedProjectId: project.id,
                            });
                            handleEmitAfterArchiveUpdate(project.id);
                          }}
                          onDeleteClick={() => {
                            setModal({
                              visible: true,
                              isCollection: true,
                              isRestore: false,
                              isDelete: true,
                              selectedTitle: project.title,
                              selectedProjectId: project.id,
                            });
                          }}
                          top={23}
                          left={-38}
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    <div className={styles.title}>
                      <div className={styles.titleAvatar}>
                        <ProjectAvatar title={project.title} color={project.color || '#C4C4C4'} noHover />
                      </div>
                      <div className={styles.titleContent}>{project.title}</div>
                    </div>
                  </td>
                  <td className={styles.centered}>{project?.threat_models?.length || 0}</td>
                  <td>
                    {project.created_by ? `${project.created_by.first_name} ${project.created_by.last_name}` : '-'}
                  </td>
                </tr>
                {project?.threat_models?.length > 0 && project.id === openedProjectId && (
                  <tr key={project.id} className={styles.subTr}>
                    <td colSpan={4}>
                      <table className={styles.subTable}>
                        <thead>
                          <tr className={styles.subTrThead}>
                            <th />
                            <th>Modelling name</th>
                            <th>Due date</th>
                            <th>Priority</th>
                            <th>Status</th>
                            <th>Owner</th>
                          </tr>
                        </thead>
                        <tbody>
                          {project.threat_models.map((tModel) => (
                            <tr key={tModel.id}>
                              <td
                                style={{ padding: '2px' }}
                                className={styles.threatModelSettings}
                                onClick={() => handleThreatSettingsClick(tModel.id)}
                              >
                                <ThreeDotsVertical size={16} />
                                {showThreatSettings === tModel.id && (
                                  <div className={styles.settingsModal}>
                                    <ReSettings
                                      setVisible={setShowThreatSettings}
                                      onRestoreClick={() => {
                                        setModal({
                                          visible: true,
                                          isRestore: true,
                                          isDelete: false,
                                          selectedTitle: tModel.title,
                                          selectedThreatModelId: tModel.id,
                                          selectedProjectId: project.id,
                                        });
                                        handleEmitForThreatModelAfterArchiveUpdate(tModel.id);
                                        handleEmitAfterArchiveUpdate(project.id);
                                      }}
                                      onDeleteClick={() => {
                                        setModal({
                                          visible: true,
                                          isRestore: false,
                                          isDelete: true,
                                          selectedTitle: tModel.title,
                                          selectedThreatModelId: tModel.id,
                                          selectedProjectId: project.id,
                                        });
                                      }}
                                      top={5}
                                      left={-10}
                                    />
                                  </div>
                                )}
                              </td>
                              <td>{tModel.title}</td>
                              <td>
                                <div className={classNames('position-relative', styles.dateField)}>
                                  <DateInput
                                    value={tModel.due_to_date ? new Date(tModel.due_to_date) : ''}
                                    placeholder="Due date"
                                    noHover
                                    // onChange={(newValue) =>
                                    //   handleDateChange({
                                    //     ...item2,
                                    //     due_to_date: newValue,
                                    //   })
                                    // }
                                  />
                                </div>
                              </td>
                              <td>
                                <UiPriority noPointer type={tModel.priority} />
                              </td>
                              <td>{tModel.status}</td>
                              <td>
                                {tModel.owner ? <User user={tModel.owner} small showPopUpOnHover noHover /> : '-'}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
            {!archives && (
              <tr className={styles.skeleton}>
                <td>
                  <Skeleton
                    height={40}
                    containerClassName={styles.skeleton}
                    count={4}
                    borderRadius={10}
                    baseColor={SKELETON_COLORS.BASE}
                    highlightColor={SKELETON_COLORS.HIGHLIGHT}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {modal.visible && (
        <UiModal
          title={`${modal.isRestore ? 'Restore' : 'Delete'} ${modal.isCollection ? 'collection' : 'threat model'}
        ${modal.isRestore ? '' : 'permanently'}`}
          onClose={handleCloseModal}
        >
          <div className="d-flex flex-column gap-4">
            <div>
              {!modal.isRestore ? (
                <div>You cannot delete this collection until all sub-collections are deleted!</div>
              ) : null}
              Are you sure you want to <span>{modal.isRestore ? 'restore' : 'permanently delete'}</span> the{' '}
              <span className="fw-bold">&quot;{modal.selectedTitle}&quot;</span>{' '}
              {modal.isCollection ? 'collection' : 'threat model'}?
            </div>
            <div className="d-flex justify-content-end gap-2">
              <UiButton type="transparent" onClick={handleCloseModal}>
                Cancel
              </UiButton>
              {modal.isRestore && !modal.isCollection && (
                <UiButton onClick={handleThreatModelRestoreClick}>Restore</UiButton>
              )}
              {modal.isDelete && !modal.isCollection && (
                <UiButton onClick={handleRemoveThreatModelClick}>Delete</UiButton>
              )}
              {modal.isRestore && modal.isCollection && (
                <UiButton onClick={handleRestoreCollectionClick}>Restore</UiButton>
              )}
              {modal.isDelete && modal.isCollection && (
                <UiButton onClick={handleRemoveCollectionClick}>Delete</UiButton>
              )}
            </div>
          </div>
        </UiModal>
      )}
    </div>
  );
};

export default ArchiveList;
