import { createModel } from '@rematch/core';
import { toast } from 'react-toastify';
import type { CustomCodexData, PaginationParams } from '../../global/types';
import {
  DEFAULT_CUSTOM_CODEX_PAGINATION_PARAMS,
  DEFAULT_CUSTOM_CODEX_STATS,
} from '../constants/custom-codex-constants';
import http from '../http/http-common';
import type { RootModel } from './index';

type CustomCodexState = {
  attributes: any[] | null;
  mitigations: any[] | null;
  threats: any[] | null;
  query: PaginationParams;
  stats: CustomCodexData;
  isLoadingCustomCodexStats: boolean;
};

export const customCodex = createModel<RootModel>()({
  state: {
    attributes: null,
    threats: null,
    mitigations: null,
    query: DEFAULT_CUSTOM_CODEX_PAGINATION_PARAMS,
    stats: DEFAULT_CUSTOM_CODEX_STATS,
    isLoadingCodexStats: false,
  } as unknown as CustomCodexState,
  reducers: {
    setAttributes(state, attributes) {
      return {
        ...state,
        attributes,
      };
    },
    setMitigations(state, mitigations) {
      return {
        ...state,
        mitigations,
      };
    },
    setThreats(state, threats) {
      return {
        ...state,
        threats,
      };
    },
    setQuery(state, query) {
      return { ...state, query };
    },
    setStats(state, stats: CustomCodexData) {
      return { ...state, stats };
    },
    setIsLoadingCustomCodexStats(state, isLoadingCustomCodexStats: boolean) {
      return { ...state, isLoadingCustomCodexStats };
    },
    resestQuery(state) {
      return {
        ...state,
        query: DEFAULT_CUSTOM_CODEX_PAGINATION_PARAMS,
      };
    },
  },
  effects: (dispatch) => ({
    // Attributes
    async getCustomAttributes(query, state) {
      try {
        const { limit, page, sort, order, title } = query;
        const nextPage = limit * page;
        const params = new URLSearchParams({ limit, page: nextPage.toString(), sort, order });

        if (title) {
          params.append('title', title);
        }

        const result = await http(`/codex/attributes/?${params.toString()}`);

        if (result.status === 200 && result.data?.items) {
          const preparedData = !page
            ? result.data.items
            : [...(state.customCodex?.attributes || []), ...result.data.items];
          dispatch.customCodex.setAttributes(preparedData);
        }

        dispatch.customCodex.setQuery({
          ...query,
          count: result?.data?.count || 0,
        });
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async getCustomAttributeById(id) {
      try {
        const result = await http.get(`/codex/attributes/${id}`);

        if (result.status === 200) {
          return result.data;
        }

        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;
        toast.warning(response?.message?.toString());
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async createAttribute(attribute, state) {
      try {
        const result = await http.post('/codex/attributes', attribute);

        if (result.status === 201) {
          dispatch.customCodex.getCustomAttributes({
            ...state.customCodex.query,
            page: 0,
          });
          toast.success('Attribute created!');

          return result.status === 201;
        }

        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;
        toast.warning(response?.message?.toString());
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async updateAttribute(attribute, state) {
      try {
        const result = await http.put(`/codex/attributes/${attribute.id}`, attribute);

        if (result.status === 200) {
          dispatch.customCodex.getCustomAttributes({
            ...state.customCodex.query,
            page: 0,
          });
          toast.success('Attribute updated!');
        } else {
          const responseText = result.request?.responseText;
          const response = responseText?.length ? JSON.parse(responseText) : null;
          toast.warning(response?.message?.toString());
        }
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async deleteAttribute(attributeId, state) {
      try {
        const result = await http.delete(`/codex/attributes/${attributeId}`);

        if (result.status === 200) {
          dispatch.customCodex.getCustomAttributes(state.customCodex.query);
          toast.success('Attribute deleted!');

          return;
        }

        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;
        toast.warning(response?.message?.toString());
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async searchAttribute(text) {
      try {
        const result = await http.get(`/codex/attributes/search?text=${text}`);

        if (result.status === 200) {
          dispatch.customCodex.setAttributes(result.data || []);

          return;
        }

        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;
        toast.warning(response?.message?.toString());
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    // Mitigations
    async getCustomMitigations(query, state) {
      try {
        const { limit, page, sort, order, title } = query;
        const nextPage = limit * page;
        const params = new URLSearchParams({ limit, page: nextPage.toString(), sort, order });

        if (title) {
          params.append('title', title);
        }

        const result = await http(`/codex/mitigations/?${params.toString()}`);

        if (result.status === 200 && result.data?.items) {
          const preparedData = !page
            ? result.data.items
            : [...(state.customCodex?.mitigations || []), ...result.data.items];
          dispatch.customCodex.setMitigations(preparedData);
        }

        dispatch.customCodex.setQuery({
          ...query,
          count: result?.data?.count || 0,
        });
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async searchMitigations(text) {
      try {
        const result = await http.get(`/codex/mitigations/search?text=${text}`);

        if (result.status === 200) {
          dispatch.customCodex.setMitigations(result.data || []);

          return;
        }

        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;
        toast.warning(response?.message?.toString());
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async getCustomMitigationById(id) {
      try {
        const result = await http.get(`/codex/mitigations/${id}`);

        if (result.status === 200) {
          return result.data;
        }

        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;
        toast.warning(response?.message?.toString());
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async createMitigation(mitigation, state) {
      try {
        const result = await http.post('/codex/mitigations', mitigation);

        if (result.status === 201) {
          dispatch.customCodex.getCustomMitigations({
            ...state.customCodex.query,
            page: 0,
          });
          toast.success('Mitigation created!');

          return result.status === 201;
        }

        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;
        toast.warning(response?.message?.toString());
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async updateMitigation(mitigation, state) {
      try {
        const result = await http.put(`/codex/mitigations/${mitigation.id}`, mitigation);

        if (result.status === 200) {
          dispatch.customCodex.getCustomMitigations({
            ...state.customCodex.query,
            page: 0,
          });
          toast.success('Mitigation updated!');
        } else {
          const responseText = result.request?.responseText;
          const response = responseText?.length ? JSON.parse(responseText) : null;
          toast.warning(response?.message?.toString());
        }
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async deleteMitigation(mitigationId, state) {
      try {
        const result = await http.delete(`/codex/mitigations/${mitigationId}`);

        if (result.status === 200) {
          dispatch.customCodex.getCustomMitigations(state.customCodex.query);
          toast.success('Mitigation deleted!');

          return;
        }

        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;
        toast.warning(response?.message?.toString());
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    // Threats
    async getCustomThreats(query, state) {
      try {
        const { limit, page, sort, order, priority, title } = query;
        const nextPage = limit * page;
        const params = new URLSearchParams({ limit, page: nextPage.toString(), sort, order });

        if (priority) {
          params.append('priority', priority);
        }

        if (title) {
          params.append('title', title);
        }

        const url = `/codex/threats/?${params.toString()}`;
        const result = await http(url);

        if (result.status === 200 && result.data?.items) {
          const preparedData = !page
            ? result.data.items
            : [...(state.customCodex?.threats || []), ...result.data.items];
          dispatch.customCodex.setThreats(preparedData);
        }

        dispatch.customCodex.setQuery({
          ...query,
          count: result?.data?.count || 0,
        });
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async getCustomThreatById(id) {
      try {
        const result = await http.get(`/codex/threats/${id}`);

        if (result.status === 200) {
          return result.data;
        }

        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;
        toast.warning(response?.message?.toString());
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async createThreat(threat, state) {
      try {
        const result = await http.post('/codex/threats', threat);

        if (result.status === 201) {
          dispatch.customCodex.getCustomThreats({
            ...state.customCodex.query,
            page: 0,
          });
          toast.success('Threat created!');

          return result.status === 201;
        }

        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;
        toast.warning(response?.message?.toString());
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async updateThreat(threat, state) {
      try {
        const result = await http.put(`/codex/threats/${threat.id}`, threat);

        if (result.status === 200) {
          dispatch.customCodex.getCustomThreats({
            ...state.customCodex.query,
            page: 0,
          });
          toast.success('Threat updated!');
        } else {
          const responseText = result.request?.responseText;
          const response = responseText?.length ? JSON.parse(responseText) : null;
          toast.warning(response?.message?.toString());
        }
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async deleteThreat(threatId, state) {
      try {
        const result = await http.delete(`/codex/threats/${threatId}`);

        if (result.status === 200) {
          dispatch.customCodex.getCustomThreats(state.customCodex.query);
          toast.success('Threat deleted!');

          return;
        }

        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;
        toast.warning(response?.message?.toString());
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async searchThreats(text) {
      try {
        const result = await http.get(`/codex/threats/search?text=${text}`);

        if (result.status === 200) {
          dispatch.customCodex.setThreats(result.data || []);

          return;
        }

        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;
        toast.warning(response?.message?.toString());
      } catch (error: any) {
        toast.warning(error.message);
      }
    },

    async getCodexStats() {
      try {
        dispatch.customCodex.setIsLoadingCustomCodexStats(true);

        const result = await http.get(`/codex/stats`);

        if (result.status === 200) {
          dispatch.customCodex.setStats(result.data);

          return;
        }

        const responseText = result.request?.responseText;
        const response = responseText?.length ? JSON.parse(responseText) : null;
        toast.warning(response?.message?.toString());
      } catch (error: any) {
        toast.warning(error.message);
      } finally {
        dispatch.customCodex.setIsLoadingCustomCodexStats(false);
      }
    },
  }),
});
