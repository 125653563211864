import { createModel } from '@rematch/core';
import { AxiosError } from 'axios';
import type { NavigateFunction } from 'react-router';
import { toast } from 'react-toastify';
import { COLUMN_NAMES, DEFAULT_THREAT_MODEL_URL_QUERY, TIER } from '../../global/constants';
import type { ThreatModel } from '../../global/types';
import { DEFAULT_THREAT_MODEL_URL, THREAT_MODEL_URL } from '../constants/api-constants';
import { HOME_ROUTE, PROJECTS_ROUTE } from '../constants/route-constants';

import type { RootModel } from '.';
import { UpdateTypes, type EmitUpdate } from '../../hooks/useSocket';
import { ThreatModelInteractionMode } from '../constants/threat-model-constants';
import http from '../http/http-common';

type RestoreThreatModelToPreviousVersion = {
  emitUpdate: EmitUpdate;
  id: string;
  navigate: NavigateFunction;
};

type ThreatModelHistory = {
  historyPoints: { id: string; title: string; created_at: Date; representations: { id: string }[] }[];
  hasNext: boolean;
};

type RootThreatModel = {
  id: string;
  representations: { id: string }[];
};

type ThreatModelState = {
  threatModels: ThreatModel[] | null;
  currentThreatModel: ThreatModel | null;
  pdfData?: any;
  canPDFExport?: boolean;
  REPRESENTATION: any;
  THREATS_AND_MITIGATIONS: any;
  RETROSPECTIVE: any;
  DONE: any;
  ARCHIVE: any;
  liveUpdateData: string[] | null;
  registerLiveUpdateData: string | null;
  elementRegisterLiveUpdateData: string | null;
  threatModelHistory: ThreatModelHistory;
  isThreatModeHistoryLoading: boolean;
  interactionMode?: ThreatModelInteractionMode;
  rootThreatModel?: RootThreatModel;
  isRestoreVersion?: boolean;
};

const initialThreatModel: ThreatModel = {
  id: '',
  title: '',
  description: '',
  status: '',
  priority: 'medium',
  representations: [],
  updated_at: new Date(),
  due_to_date: new Date(),
  owner: {
    id: '',
    email: '',
    first_name: '',
    last_name: '',
  },
  root: null,
};

export const threatModel = createModel<RootModel>()({
  state: {
    threatModels: null,
    currentThreatModel: null,
    pdfData: null,
    canPDFExport: false,
    REPRESENTATION: DEFAULT_THREAT_MODEL_URL_QUERY,
    THREATS_AND_MITIGATIONS: DEFAULT_THREAT_MODEL_URL_QUERY,
    RETROSPECTIVE: DEFAULT_THREAT_MODEL_URL_QUERY,
    DONE: DEFAULT_THREAT_MODEL_URL_QUERY,
    liveUpdateData: null,
    registerLiveUpdateData: null,
    elementRegisterLiveUpdateData: null,
  } as ThreatModelState,
  reducers: {
    seInteractionMode(state, interactionMode: ThreatModelInteractionMode) {
      return { ...state, interactionMode };
    },
    setThreatModels(state, threatModels) {
      return {
        ...state,
        threatModels,
      };
    },
    insertThreatModel(state, threatModel: ThreatModel) {
      const updatedThreatModels = [...(state.threatModels as ThreatModel[]), threatModel];

      return {
        ...state,
        threatModels: updatedThreatModels,
      };
    },
    setCurrentThreatModel(state, currentThreatModel) {
      return {
        ...state,
        currentThreatModel,
        rootThreatModel: currentThreatModel.root || {
          id: currentThreatModel.id,
          representations: currentThreatModel.representations,
        },
      };
    },
    setRootThreatModel(state, rootThreatModel) {
      return {
        ...state,
        rootThreatModel,
      };
    },
    setThreatModelStatistics(state, data) {
      if (!state.currentThreatModel) return;

      return {
        ...state,
        currentThreatModel: {
          ...state.currentThreatModel,
          percentage: data.percentage,
          threatAmount: data.threatAmount,
          mitigationAmount: data.mitigationAmount,
        },
      };
    },
    setInitialCurrentThreatModel(state) {
      return { ...state, currentThreatModel: initialThreatModel };
    },
    setPdfData(state, pdfData) {
      return { ...state, pdfData };
    },
    setCanPdfExport(state, canPDFExport) {
      return { ...state, canPDFExport };
    },
    setForceUpdateId(state, forceUpdateId) {
      return { ...state, forceUpdateId };
    },
    setField(state, data) {
      switch (data.status) {
        case COLUMN_NAMES.REPRESENTATION:
          return { ...state, REPRESENTATION: data };
        case COLUMN_NAMES.THREATS_AND_MITIGATIONS:
          return { ...state, THREATS_AND_MITIGATIONS: data };
        case COLUMN_NAMES.RETROSPECTIVE:
          return { ...state, RETROSPECTIVE: data };
        case COLUMN_NAMES.DONE:
          return { ...state, DONE: data };
        default:
          return state;
      }
    },
    setColumns(state, columns) {
      return {
        ...state,
        ...columns,
      };
    },
    setDefaultFields(state) {
      return {
        ...state,
        REPRESENTATION: DEFAULT_THREAT_MODEL_URL_QUERY,
        THREATS_AND_MITIGATIONS: DEFAULT_THREAT_MODEL_URL_QUERY,
        RETROSPECTIVE: DEFAULT_THREAT_MODEL_URL_QUERY,
        DONE: DEFAULT_THREAT_MODEL_URL_QUERY,
      };
    },
    removeThreatModel(state, tModelId: string) {
      const updatedThreatModels = state.threatModels?.filter((tM) => tM.id !== tModelId) || null;

      return {
        ...state,
        threatModels: updatedThreatModels,
      };
    },
    setLiveUpdateData(state, liveUpdateData) {
      return {
        ...state,
        liveUpdateData,
      };
    },
    setRegisterLiveUpdateData(state, registerLiveUpdateData) {
      return {
        ...state,
        registerLiveUpdateData,
      };
    },
    setElementRegisterLiveUpdateData(state, elementRegisterLiveUpdateData) {
      return {
        ...state,
        elementRegisterLiveUpdateData,
      };
    },
    resetThreatModelsState(state) {
      return {
        ...state,
        threatModels: null,
        currentThreatModel: null,
        pdfData: null,
        canPDFExport: false,
        REPRESENTATION: DEFAULT_THREAT_MODEL_URL_QUERY,
        THREATS_AND_MITIGATIONS: DEFAULT_THREAT_MODEL_URL_QUERY,
        RETROSPECTIVE: DEFAULT_THREAT_MODEL_URL_QUERY,
        DONE: DEFAULT_THREAT_MODEL_URL_QUERY,
        liveUpdateData: null,
      };
    },
    setThreatModelHistory(state, threatModelHistory: ThreatModelHistory) {
      return {
        ...state,
        threatModelHistory,
      };
    },
    setLoadedThreatModelHistory(state, threatModelHistory: ThreatModelHistory) {
      return {
        ...state,
        threatModelHistory: {
          ...threatModelHistory,
          historyPoints: [...state.threatModelHistory.historyPoints, ...threatModelHistory.historyPoints],
        },
      };
    },

    setIsThreatModeHistoryLoading(state, isThreatModeHistoryLoading: boolean) {
      return {
        ...state,
        isThreatModeHistoryLoading,
      };
    },

    setIsRestoreVersion(state, isRestoreVersion: boolean) {
      return {
        ...state,
        isRestoreVersion,
      };
    },
  },
  effects: (dispatch) => {
    return {
      async getThreatModels(projectId = '') {
        try {
          const result = await http.get(`${THREAT_MODEL_URL}/?sort=order&limit=100&projectId=${projectId || ''}`);

          if (result.status === 200) {
            dispatch.threatModel.setThreatModels(result.data.items);
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },
      async getThreatModelsByQuery(query) {
        try {
          const nextPage = query.limit * query.page;
          const sort = query?.projectId ? 'rank' : 'my_models_rank';
          const result = await http.get(
            `${THREAT_MODEL_URL}/${query?.projectId ? '' : 'general'}?sort=${sort}&limit=${
              query.limit
            }&page=${nextPage}&status=${encodeURIComponent(query.status)}&projectId=${
              query?.projectId || ''
            }&runningId=${query?.runningId || ''}`,
          );

          if (result.status === 200) {
            const preparedField = {
              ...query,
              data: !query.page ? result.data.items : [...query.data, ...result.data.items],
              count: result.data.count || 0,
              fetched: true,
              originOrder: [],
              originMyModelsOrder: [],
            };
            preparedField?.data?.forEach((i: any) => {
              if (i.rank) preparedField.originOrder.push(i.rank);

              if (i.my_models_rank) preparedField.originMyModelsOrder.push(i.my_models_rank);
            });
            dispatch.threatModel.setField(preparedField);
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },
      async getThreatModel(id: string, state) {
        try {
          const representationId = state?.representation?.data?.id || window.location.pathname.split('/').at(-1);
          const result = await http.get(`${THREAT_MODEL_URL}/${id}?representationId=${representationId}`);

          if (result instanceof AxiosError && result.response?.status === 403) {
            return (window.location.href = HOME_ROUTE);
          }

          if (result.status === 200) {
            dispatch.threatModel.setCurrentThreatModel(result.data);
            dispatch.threatModel.updateCurrentThreatModel();

            dispatch.threatModel.seInteractionMode(
              result.data.root ? ThreatModelInteractionMode.READ : ThreatModelInteractionMode.WRITE,
            );
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },
      async updateCurrentThreatModel(_: void, state) {
        try {
          if (
            state.threatModel?.currentThreatModel?.id?.length &&
            state.threatModel?.currentThreatModel?.id?.length > 0
          ) {
            const result = await http.get(`${THREAT_MODEL_URL}/statistics/${state.threatModel.currentThreatModel.id}`);

            if (result.status === 200) {
              dispatch.threatModel.setThreatModelStatistics(result.data);
            }
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },
      async createThreatModel({ projectId, title }, state) {
        const { tiers } = state;

        if (!state.tiers?.customerLimits?.isBetaUser) {
          if (tiers.customerLimits?.tier === TIER.FREE) {
            if (tiers?.customerLimits?.threatModels?.available === 0) {
              toast.error('You have reached the limit of threat models! Please upgrade your plan.');

              return;
            }
          }
        }

        try {
          const result = await http.post(`${THREAT_MODEL_URL}/`, {
            projectId,
            title,
          });

          if (result.status === 201) {
            dispatch.tiers.getCustomerLimits(); // refetch customer limits
            dispatch.threatModel.setCurrentThreatModel(result.data);

            return result.data;
          }
          // TODO: show erore message in toast
        } catch (error: any) {
          toast.warning(error);
        }
      },
      async updateThreatModel(threatModel: ThreatModel) {
        try {
          const result = await http.patch(`${THREAT_MODEL_URL}/${threatModel.id}`, {
            title: threatModel.title,
            status: threatModel.status,
            priority: threatModel.priority,
            description: threatModel.description,
            time_invested: threatModel.time_invested,
            quality_of_model: threatModel.quality_of_model,
            when_to_revisit: threatModel.when_to_revisit,
            due_to_date: threatModel.due_to_date,
            project: threatModel.project,
          });

          return result.status;
        } catch (error: any) {
          toast.warning(error);
        }
      },
      async updateThreatModelOwner(threatModel: any, state) {
        try {
          const result = await http.patch(`${THREAT_MODEL_URL}/update-owner/${threatModel.id}`, {
            ownerId: threatModel.ownerId,
          });

          if (result.status === 200) {
            if (state.threatModel?.currentThreatModel?.id) {
              dispatch.threatModel.getThreatModel(state.threatModel.currentThreatModel.id);
            }

            return result.data;
          }
        } catch (error: any) {
          toast.warning(error);
        }
      },
      async getPDFReportData(_, state) {
        try {
          const currentThreatModelId = state.threatModel?.currentThreatModel?.id;
          const representationId = state?.representation?.data?.id;

          if (!currentThreatModelId || !representationId) return;

          if (state.threatModel.pdfData?.threatModel?.id) return;

          const result: any = await http.post(
            `${THREAT_MODEL_URL}/report/${currentThreatModelId}?representationId=${representationId}`,
          );

          if (result.status === 201) {
            return result.data;
          }
        } catch (e: any) {
          toast.warning(e.message);
        }
      },
      async getOTMReportData(_, state) {
        try {
          const currentThreatModelId = state.threatModel?.currentThreatModel?.id;

          if (!currentThreatModelId) return;

          if (state.threatModel.pdfData?.threatModel?.id) return;

          const result: any = await http(`${THREAT_MODEL_URL}/otm/${currentThreatModelId}`);

          if (result.status === 200) {
            return result.data;
          }
        } catch (e: any) {
          toast.warning(e.message);
        }
      },

      async createOTMThreatModel(data) {
        try {
          const result: any = await http.post(`${THREAT_MODEL_URL}/otm`, data);

          if (result.status === 201) {
            return result.data;
          }
        } catch (e: any) {
          toast.warning(e.message);
        }
      },

      async createDefaultThreatModel() {
        try {
          const result: any = await http.post(DEFAULT_THREAT_MODEL_URL);

          if (result.status === 201) {
            dispatch.tiers.getCustomerLimits();

            return result.data;
          }
        } catch (e: any) {
          toast.warning(e.message);
        }
      },
      async remove(id: string) {
        try {
          const result = await http.delete(`${THREAT_MODEL_URL}/${id}`);

          if (result.status === 200) {
            toast.success('Threat model deleted successfully!');
            await dispatch.project.getArchives();
            dispatch.tiers.getCustomerLimits(); // refetch customer limits
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },
      async archive(id: string) {
        try {
          const result = await http.patch(`${THREAT_MODEL_URL}/archive/${id}`);

          if (result.status === 200) {
            toast.success('Threat model archived successfully!');
            dispatch.tiers.getCustomerLimits(); // refetch customer limits
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },
      async unArchive(id: string) {
        try {
          const result = await http.patch(`${THREAT_MODEL_URL}/unArchive/${id}`);

          if (result.status === 200) {
            await dispatch.project.getArchives();
            toast.success('Threat model unarchived successfully!');
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },
      async updateThreatModelOrder({ id, newStatus, newRank, projectId }) {
        try {
          if (id && newStatus && newRank) {
            await http.patch(`${THREAT_MODEL_URL}/update-order/${id}`, {
              newStatus,
              newOrder: newRank,
              projectId,
            });
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },
      forceUpdateThreatModels(projectId: string, state) {
        const { REPRESENTATION, THREATS_AND_MITIGATIONS, RETROSPECTIVE, DONE } = state.threatModel;
        dispatch.threatModel.getThreatModelsByQuery({
          ...REPRESENTATION,
          page: 0,
          projectId,
        });
        dispatch.threatModel.getThreatModelsByQuery({
          ...THREATS_AND_MITIGATIONS,
          page: 0,
          projectId,
        });
        dispatch.threatModel.getThreatModelsByQuery({
          ...RETROSPECTIVE,
          page: 0,
          projectId,
        });
        dispatch.threatModel.getThreatModelsByQuery({
          ...DONE,
          page: 0,
          projectId,
        });
      },

      async duplicate(id: string) {
        try {
          const result = await http.post(`${THREAT_MODEL_URL}/duplicate/${id}`);

          if (result.status === 201) {
            toast.success('Threat model duplicated successfully!');
          }

          return result.status;
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async getThreatModelHistory(id: string) {
        try {
          dispatch.threatModel.setIsThreatModeHistoryLoading(true);
          const result = await http.get<ThreatModelHistory>(`${THREAT_MODEL_URL}/versions/${id}`);

          if (result.status === 200) {
            dispatch.threatModel.setThreatModelHistory(result.data);
          }
        } catch (error) {
          if (error instanceof Error) {
            toast.warning(error.message);
          } else {
            toast.warning(String(error));
          }
        } finally {
          dispatch.threatModel.setIsThreatModeHistoryLoading(false);
        }
      },

      async loadMoreThreatMode(id: string, state) {
        try {
          dispatch.threatModel.setIsThreatModeHistoryLoading(true);
          const { threatModelHistory } = state.threatModel;

          const cursor = threatModelHistory?.historyPoints.at(-1)?.created_at;

          const result = await http.get<ThreatModelHistory>(
            `${THREAT_MODEL_URL}/versions/${id}?limit=10&${cursor ? `cursor=${cursor}` : ''}`,
          );

          if (result.status === 200) {
            dispatch.threatModel.setLoadedThreatModelHistory(result.data);
          }
        } catch (error) {
          if (error instanceof Error) {
            toast.warning(error.message);
          } else {
            toast.warning(String(error));
          }
        } finally {
          dispatch.threatModel.setIsThreatModeHistoryLoading(false);
        }
      },

      async restoreThreatModelToPreviousVersion(
        { id, navigate, emitUpdate }: RestoreThreatModelToPreviousVersion,
        state,
      ) {
        try {
          const user = state.user.current;

          dispatch.threatModel.setIsRestoreVersion(true);
          const result = await http.post<ThreatModel>(`${THREAT_MODEL_URL}/restore-version/${id}`);

          if (result.status === 201) {
            dispatch.threatModel.setCurrentThreatModel(result.data);
            const representationId = result.data.representations[0]?.id;

            emitUpdate(UpdateTypes.THREAT_MODEL_REVERTED, {
              representationId,
              date: new Date().toISOString(),
              author: `${user?.first_name} ${user?.last_name}`,
            });

            navigate(`${PROJECTS_ROUTE}/${result.data.project?.id}/d/${representationId}`);
          }
        } catch (error) {
          if (error instanceof Error) {
            toast.warning(error.message);
          } else {
            toast.warning(String(error));
          }
        } finally {
          dispatch.threatModel.setIsRestoreVersion(false);
        }
      },

      async getRootThreatModel(id: string) {
        try {
          const result = await http.get(`${THREAT_MODEL_URL}/${id}`);

          if (result instanceof AxiosError && result.response?.status === 403) {
            return (window.location.href = HOME_ROUTE);
          }

          if (result.status === 200) {
            dispatch.threatModel.setRootThreatModel({
              id: result.data.id,
              representations: result.data.representations,
            });
          }
        } catch (error) {
          if (error instanceof Error) {
            toast.warning(error.message);
          } else {
            toast.warning(String(error));
          }
        }
      },
    };
  },
});
