import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { XLg } from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Spinner } from 'reactstrap';
import * as Yup from 'yup';
import { SKELETON_COLORS } from '../../../global/constants';
import { useTypedSelector } from '../../../hooks/useTypeSelector';

import type { Dispatch } from '../../../store/store';
import UiModal from '../../common/Modal/UiModal';
import UiButton from '../../common/UIButton/UiButton';
import UiInput from '../../common/UiInput/UiInput';
import { AliasesForm } from './AliasesForm';
import { ResourcesForm } from './ResourcesForm';

export const CreateAttributeForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState<string>('');
  const [deleteModal, setDeleteModla] = useState(false);
  const { attributes } = useTypedSelector((state) => state.customCodex);
  const [currentAttribute, setCurrentAttribute] = useState<any>(null);

  const isCreating = params?.attributeId === 'new';

  const getCurrentAttribute = async () => {
    const found = attributes?.find((a) => a.id === params?.attributeId);

    if (found) {
      setCurrentAttribute(null);
      const res = await dispatch.customCodex.getCustomAttributeById(found.id);
      setCurrentAttribute(res);
    }
  };

  useEffect(() => {
    if (!isCreating) {
      getCurrentAttribute();
    } else {
      setCurrentAttribute(null);
    }
  }, [params]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: currentAttribute || {
      title: '',
      description: '',
      aliases: [],
      resources: [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Required').min(1).max(64),
      description: Yup.string().required('Required').min(1).max(500),
      aliases: Yup.array().of(Yup.string()),
      resources: Yup.array(
        Yup.object()
          .shape({
            title: Yup.string(),
            url: Yup.string(),
          })
          .nullable()
          .optional(),
      )
        .nullable()
        .optional(),
    }),
    onSubmit: async (values) => {
      setLoading('save');
      let isOk;

      const preparedAttribute: any = {
        title: values.title,
        description: values.description,
      };

      if (values.aliases?.length) {
        preparedAttribute.aliases = values.aliases;
      }

      if (values.resources?.length) {
        preparedAttribute.resources = values.resources;
      }

      if (isCreating) {
        isOk = await dispatch.customCodex.createAttribute(preparedAttribute);
      } else {
        isOk = await dispatch.customCodex.updateAttribute({
          ...preparedAttribute,
          id: currentAttribute.id,
        });
        await getCurrentAttribute();
      }

      setLoading('');

      if (isOk) {
        navigate(-1);
        dispatch.drawn.setVisible(false);
        setCurrentAttribute(null);
        formik.resetForm();
      }
    },
  });

  const handleDelete = async () => {
    setLoading('delete');
    navigate('/admin/codex/attributes');
    await dispatch.customCodex.deleteAttribute(currentAttribute.id);
    setLoading('');
    setDeleteModla(false);
    setCurrentAttribute(null);
    dispatch.drawn.setVisible(false);
  };

  const showForm = currentAttribute || isCreating;

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5>{isCreating ? 'Add' : 'Edit'} Attribute</h5>
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate('/admin/codex/attributes');
            dispatch.drawn.setVisible(false);
          }}
        >
          <XLg size={20} />
        </div>
      </div>

      {showForm && (
        <UiInput
          name="title"
          label="Name *"
          placeholder="Attribute name"
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.errors?.title?.toString()}
          showError={!!formik.touched?.title && !!formik.errors?.title}
        />
      )}

      <div className="mt-3" />

      {showForm && (
        <UiInput
          name="description"
          label="Description *"
          type="textarea"
          textAreaRows={4}
          placeholder="Attribute description"
          value={formik.values?.description || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.errors.description?.toString()}
          showError={!!formik.touched.description && !!formik.errors.description}
        />
      )}

      <hr />
      {showForm && (
        <AliasesForm
          aliases={formik.values.aliases}
          currentTitle={formik.values.title}
          setAliases={(values) => {
            formik.setFieldValue('aliases', values);
          }}
        />
      )}

      <hr />
      {showForm && (
        <ResourcesForm
          resources={formik.values?.resources}
          setResources={(values) => {
            formik.setFieldValue('resources', values);
          }}
        />
      )}

      <hr />
      {showForm && (
        <div className="d-flex justify-content-end">
          <UiButton
            onClick={() => setDeleteModla(true)}
            className="mt-3 me-3"
            type="transparent"
            style={{ width: '100px' }}
            hidden={isCreating}
          >
            Delete
          </UiButton>
          <UiButton
            onClick={() => {
              navigate('/admin/codex/attributes');
              dispatch.drawn.setVisible(false);
            }}
            className="mt-3 me-3"
            type="transparent"
            style={{ width: '100px' }}
            hidden={!isCreating}
          >
            Cancel
          </UiButton>
          <UiButton disabled={!!loading} onClick={formik.submitForm} className="mt-3" style={{ width: '100px' }}>
            {loading === 'save' ? <Spinner color="light" size="sm" /> : 'Save'}
          </UiButton>
        </div>
      )}

      {deleteModal && (
        <UiModal title="Delete" onClose={() => setDeleteModla(false)}>
          {`Are you sure you want to delete the "${currentAttribute.title}" attribute?`}
          <div className="d-flex justify-content-end">
            <UiButton onClick={handleDelete} className="mt-3" type="danger" style={{ width: '100px' }}>
              {loading === 'delete' ? <Spinner color="danger" size="sm" /> : 'Delete'}
            </UiButton>
          </div>
        </UiModal>
      )}

      {!currentAttribute && !isCreating && (
        <Skeleton
          style={{ lineHeight: '25px', margin: '10px 0' }}
          width="100%"
          height={40}
          count={4}
          borderRadius={10}
          baseColor={SKELETON_COLORS.BASE}
          highlightColor={SKELETON_COLORS.HIGHLIGHT}
        />
      )}
    </>
  );
};
