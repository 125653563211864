import type { FC } from 'react';
import UiModal from '../../common/Modal/UiModal';
import UiButton from '../../common/UIButton/UiButton';
import styles from './ArchiveDeleteModal.module.css';

type ArchiveDeleteModalProps = {
  onCancel: any;
  onArchive: any;
  title: string;
};

const ArchiveDeleteModal: FC<ArchiveDeleteModalProps> = ({ onCancel, onArchive, title }) => {
  return (
    <UiModal title="Archive" onClose={onCancel}>
      <div data-testid="archive-collection-modal">
        <div className={styles.header}>
          <div>Are you sure you want to move the collection &quot;{title || 'collection'}&quot; to the archive?</div>
          <div>Once archived, it will no longer be manageable.</div>
        </div>
        <div className={styles.body}>Move collection to archive?</div>
        <div className={styles.footer}>
          <UiButton onClick={onCancel} type="transparent">
            Cancel
          </UiButton>
          <UiButton onClick={onArchive}>Archive</UiButton>
        </div>
      </div>
    </UiModal>
  );
};

export default ArchiveDeleteModal;
