import type { FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';

import type { ThreatModelPriorityType } from '../../../global/types';
import type { EmitUpdate } from '../../../hooks/useSocket';
import { UpdateTypes } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { IThreat } from '../../../store/models/drawn';
import type { Dispatch } from '../../../store/store';
import PriorityModal from '../../ThreatModels/PriorityModal';
import styles from './ThreatRegisterTable.module.css';

type ThreatRegisterTablePriorityDropdownProps = {
  threat: IThreat;
  setVisible: (val: boolean) => void;
  handleEmitAction: () => void;
  type: 'threat' | 'element';
  isElementRegister?: boolean;
  emitUpdate: EmitUpdate;
};

export const ThreatRegisterTablePriorityDropdown: FC<ThreatRegisterTablePriorityDropdownProps> = ({
  threat,
  setVisible,
  handleEmitAction,
  type,
  isElementRegister = false,
  emitUpdate,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { groupedThreatRegisterListQuery, elementRegisterListQuery } = useTypedSelector((state) => state.threats);
  const handleEmitAfterUpdate = (threatId: string) => {
    emitUpdate(UpdateTypes.THREAT, threatId, true);
  };

  const handlePriorityChange = async (updatedThreat: IThreat) => {
    const data = {
      id: updatedThreat.id,
      priority: updatedThreat.priority,
    };
    setVisible(false);
    const res = await dispatch.threats.updateThreat({
      threat: data,
      registerType: type,
    });
    handleEmitAction();
    handleEmitAfterUpdate(threat.id);

    if (res && res.status === 200) {
      if (isElementRegister) {
        dispatch.threats.getElementRegisterList(elementRegisterListQuery);
      } else {
        dispatch.threats.getGroupedThreatRegisterList(groupedThreatRegisterListQuery);
      }
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={styles.priorityModel}>
        <PriorityModal
          onClick={(newPriority) => {
            handlePriorityChange({
              ...threat,
              priority: newPriority,
            });
          }}
          currentPriority={threat.priority as ThreatModelPriorityType}
        />
      </div>
    </OutsideClickHandler>
  );
};
