import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import OutsideClickHandler from 'react-outside-click-handler';
import { useNavigate } from 'react-router-dom';
import type { Running } from '../../../global/types';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import styles from './RunningDropDown.module.css';

type RunningDropDownProps = {
  value?: Running;
  label?: string;
  options: Running[];
  onChange?: any;
  disabled?: boolean;
};

const RunningDropDown: FC<RunningDropDownProps> = ({ label, options, value, onChange, disabled = false }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { current } = useTypedSelector((state) => state.project);

  const renderItem = () => {
    if (value && options?.includes(value)) {
      return <div>{value.branch}</div>;
    }

    return value?.branch || '-';
  };

  const handleShowAllClick = () => {
    if (current?.id) {
      navigate(`../collections/${current.id}/runnings`, { replace: true });
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div
        className={classNames(
          'w d-flex align-items-center gap-4 position-relative user-select-none',
          styles.threatsDetailDropdown,
        )}
      >
        {label && <span className="w-25 fw-bold">{label}</span>}
        <div
          className={classNames('w-100 text-capitalize', styles.threatsDetailDropdownInput, { [styles.active]: open })}
          onClick={() => !disabled && setOpen(!open)}
        >
          {renderItem()}
          {open ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
          {open && (
            <div className={classNames('position-absolute', styles.threatsDetailDropdownOptions)}>
              {open &&
                options
                  .filter((running) => running.id !== value?.id)
                  .map((o: Running, idx: number) => (
                    <div key={o.id + idx} className="text-capitalize" onClick={() => onChange && onChange(o)}>
                      {`#${o.serial_id} (${o.branch})`}
                    </div>
                  ))}
              <div key="show-all" className="text-capitalize text-primary" onClick={handleShowAllClick}>
                Show all
              </div>
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default RunningDropDown;
