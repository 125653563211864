import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { SIGN_IN_ROUTE } from '../store/constants/route-constants';
import type { RootState } from '../store/store';
import { getIsAuthenticated } from './user';

const ProtectedRoute = ({ children, isAuthenticated, isPublic }: any) => {
  const location = useLocation();

  if (isPublic) return children;

  if (isAuthenticated || getIsAuthenticated()) return children;

  return <Navigate to={SIGN_IN_ROUTE} state={{ from: location }} replace />;
};

const mapState = (state: RootState) => ({
  isAuthenticated: state.auth?.isAuthenticated,
});

export default connect(mapState)(ProtectedRoute);
