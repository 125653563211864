import { CustomCodexAttributesTable } from '../../../components/AdminPage/CustomCodexTable/CustomCodexAttributesTable';
import { CustomCodexDrawer } from '../../../components/AdminPage/CustomCodexTable/CustomCodexDrawer';

const CustomCodexAttributesPage = () => {
  return (
    <div style={{ padding: 10 }} className="d-flex w-100">
      <CustomCodexAttributesTable />
      <CustomCodexDrawer type="attributes" />
    </div>
  );
};

export default CustomCodexAttributesPage;
