import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { SKELETON_COLORS } from '../../../../global/constants';
import type { EmitUpdate } from '../../../../hooks/useSocket';
import { UpdateTypes } from '../../../../hooks/useSocket';
import { useTypedSelector } from '../../../../hooks/useTypeSelector';
import { THREATS_REGISTER_ROUTE } from '../../../../store/constants/route-constants';
import type { Dispatch } from '../../../../store/store';
import UiCheckList from '../../../common/UiCheck/UiCheckList';
import styles from './Checklist.module.css';

type ChecklistProps = {
  emitUpdate?: EmitUpdate;
};

const Checklist: FC<ChecklistProps> = ({ emitUpdate }) => {
  const { threatsId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { pathname } = useLocation();
  const nodeTitle = useTypedSelector(({ drawn }) => drawn?.component?.title) || '';
  const { threat, forceUpdateThreatId, forceUpdateMitigationId } = useTypedSelector((state) => state.drawn);

  useEffect(() => {
    if (threatsId) {
      dispatch.drawn.getThreat(threatsId);
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (threatsId && forceUpdateThreatId && threatsId === forceUpdateThreatId) {
      dispatch.drawn.getThreat(threatsId);
      dispatch.drawn.setForceUpdateThreatId(false);
    }
  }, [forceUpdateThreatId]);

  useEffect(() => {
    if (threat?.id && !threat.mitigations) {
      dispatch.mitigations.getMitigationsForThreat(threatsId);
    }
  }, [threat]);

  useEffect(() => {
    if (
      threat?.id &&
      threat.mitigations &&
      forceUpdateMitigationId &&
      threat.mitigations.map((m) => m.id).includes(forceUpdateMitigationId)
    ) {
      dispatch.mitigations.getMitigationsForThreat(threatsId);
    }
  }, [forceUpdateMitigationId]);

  const handleEmitAfterUpdate = (mitigationId: string) => {
    if (threatsId && emitUpdate) {
      emitUpdate(UpdateTypes.THREAT, threatsId, true);
    }

    if (mitigationId && emitUpdate) {
      emitUpdate(UpdateTypes.MITIGATION, mitigationId, true);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleStatusClick = async (mitigationId: string, status: string | null) => {
    setIsLoading(true);
    await dispatch.drawn.updateMitigation({
      id: mitigationId,
      threatsId,
      mitigation: { status },
    });
    await dispatch.threatModel.updateCurrentThreatModel();
    setIsLoading(false);
    handleEmitAfterUpdate(mitigationId);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const renderTitle = () => {
    if (pathname.includes(`${THREATS_REGISTER_ROUTE}/`)) return 'Back';

    return `Back to ${nodeTitle || ''}`;
  };

  return (
    <div style={{ margin: '10px' }}>
      <div className={styles.wrapper}>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="cursor-pointer" onClick={handleBack}>
            <ArrowLeft fontSize={16} className="me-2 text-secondary" />
            <span className="fs-12 fw-bold text-secondary">{renderTitle()}</span>
          </div>
        </div>
        <div className={styles.description}>{threat?.description}</div>
        <div className={styles.questionList}>
          {threat?.mitigations &&
            threat?.mitigations?.map(
              (mitigation) =>
                mitigation?.question && (
                  <div key={mitigation.id} className={styles.listItem}>
                    <div className={styles.listItemDescription}>{mitigation.question}</div>
                    <UiCheckList onClick={handleStatusClick} mitigation={mitigation} isLoading={isLoading} />
                  </div>
                ),
            )}
          {threat?.id?.length < 1 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                width: '100%',
              }}
            >
              <Skeleton
                style={{ lineHeight: '25px' }}
                width="100%"
                borderRadius={4}
                height={53}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
              <Skeleton
                style={{ lineHeight: '25px' }}
                width="100%"
                borderRadius={4}
                height={53}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
              <Skeleton
                style={{ lineHeight: '25px' }}
                width="100%"
                borderRadius={4}
                height={53}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Checklist;
