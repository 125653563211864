import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Spinner } from 'reactstrap';
import { SKELETON_COLORS } from '../../../global/constants';

import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { DEFAULT_CUSTOM_CODEX_PAGINATION_PARAMS } from '../../../store/constants/custom-codex-constants';
import type { Dispatch } from '../../../store/store';
import UiButton from '../../common/UIButton/UiButton';
import styles from './CustomCodexTable.module.css';
import { SearchInput } from './SearchInput';
import { UploadCsvModal } from './UploadCsvModal';

export const CustomCodexMitigationsTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { mitigations, query } = useTypedSelector((state) => state.customCodex);
  const { drawnWidth, visible } = useTypedSelector((state) => state.drawn);
  const [showFileModal, setShowFileModal] = useState(false);
  const [loading, setLoading] = useState('');
  const width = visible ? `calc(100vw - ${drawnWidth + 65 || 0}px)` : '100%';
  const canFetchMore = query.page * query.limit < query.count;

  useEffect(() => {
    if (!mitigations) {
      dispatch.customCodex.getCustomMitigations(DEFAULT_CUSTOM_CODEX_PAGINATION_PARAMS);
    }

    if (visible) dispatch.drawn.setVisible(false);

    return () => {
      dispatch.customCodex.setMitigations(null);
    };
  }, []);

  const fetchMoreData = async () => {
    const nextPage = query.page + 1;

    if (nextPage * query.limit < query.count) {
      setLoading('scroll');
      await dispatch.customCodex.getCustomMitigations({
        ...query,
        page: nextPage,
      });
      setLoading('');
    }
  };

  const sortByName = async (sort: string) => {
    setLoading(sort);
    await dispatch.customCodex.getCustomMitigations({
      ...query,
      sort,
      order: query.order === 'ASC' ? 'DESC' : 'ASC',
      page: 0,
    });
    setLoading('');
  };

  const renderMitigations = (mitigation: any) => {
    return (
      <tr
        key={mitigation.id}
        onClick={() => {
          dispatch.drawn.setVisible(true);
          navigate(mitigation.id);
        }}
        className="cursor-pointer"
      >
        <td className={styles.descriptionCol}>{mitigation.title}</td>
        <td
          className={classNames(styles.descriptionCol, {
            [styles.hasTooltip]: mitigation?.definition?.length > 100,
          })}
          data-tooltip={mitigation.definition}
        >
          {mitigation.definition}
        </td>
        <td className="text-center">{moment(mitigation.created_at).format('MM.DD.YYYY')}</td>
      </tr>
    );
  };

  return (
    <div id="scrollableTargetTable" className={styles.tableWrap} style={{ width }}>
      <h3 className={styles.header}>Codex: Mitigations</h3>
      <div className="d-flex justify-content-between align-items-center" style={{ zIndex: 2 }}>
        <div className="d-flex gap-3 align-items-center fs-12 position-relative  mb-3">
          <UiButton
            onClick={() => {
              navigate('new');
              dispatch.drawn.setVisible(true);
            }}
          >
            Add Mitigation
          </UiButton>
          <SearchInput type="mitigations" />
        </div>
        {/* // TODO: need uncoment when upload by csv will be done
        <div>
          <UiButton onClick={() => setShowFileModal(true)} type="transparent" className="text-black">
            <Upload size={16} className="me-2" /> Import File
          </UiButton>
        </div> */}
      </div>

      <div className={styles.tableScroll} id="scrollableTargetDiv">
        {mitigations === null && (
          <Skeleton
            style={{ lineHeight: '25px', marginBottom: '5px' }}
            width="100%"
            height={30}
            count={15}
            borderRadius={10}
            baseColor={SKELETON_COLORS.BASE}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
          />
        )}
        {!!mitigations && (
          <InfiniteScroll
            scrollableTarget="scrollableTargetDiv"
            next={fetchMoreData}
            hasMore={canFetchMore}
            loader={
              loading === 'scroll' ? (
                <div className="w-100 m-auto text-center">
                  <Spinner color="dark" />
                </div>
              ) : null
            }
            dataLength={mitigations?.length || 0}
          >
            <table className={styles.table}>
              <thead className="position-sticky top-0 z-1">
                <tr>
                  <th style={{ width: '30%' }} className={styles.tableHead}>
                    Name
                    {loading === 'title' ? (
                      <div className={styles.spinner}>
                        <Spinner size="sm" />
                      </div>
                    ) : (
                      <div className={styles.sortIconsWrap} onClick={() => sortByName('title')}>
                        <CaretUpFill className={styles.tableSortIconUp} />
                        <CaretDownFill className={styles.tableSortIconDown} />
                      </div>
                    )}
                  </th>
                  <th style={{ width: '50%' }} className={styles.tableHead}>
                    Definition
                  </th>
                  <th style={{ width: '20%' }} className={styles.tableHead}>
                    Created At
                    {loading === 'created_at' ? (
                      <div className={styles.spinner}>
                        <Spinner size="sm" />
                      </div>
                    ) : (
                      <div className={styles.sortIconsWrap} onClick={() => sortByName('created_at')}>
                        <CaretUpFill className={styles.tableSortIconUp} />
                        <CaretDownFill className={styles.tableSortIconDown} />
                      </div>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody id="scrollableTargetTable">
                {mitigations?.length ? (
                  mitigations?.map(renderMitigations)
                ) : (
                  <tr className="cursor-default">
                    <td className="text-center" colSpan={6}>
                      No mitigations
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        )}
      </div>
      {showFileModal && <UploadCsvModal onClose={() => setShowFileModal(false)} />}
    </div>
  );
};
