import classNames from 'classnames';
import moment from 'moment';
import type { FC, SyntheticEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { CheckLg, ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { getMonthsArray } from '../../../helpers/calendar';
import styles from './DateInput.module.css';

const CalendarLabel: FC<any> = (value) => {
  const listRef = useRef<any>(null);
  const [showModal, setShowModal] = useState(false);
  const months = getMonthsArray(value.date);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = months.findIndex((m) => moment(m).isSame(value.date)) * 38;
    }
  }, [showModal]);

  const handleClick = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setShowModal(!showModal);
  };

  return (
    <div onClick={handleClick} className={styles.calendarLabel}>
      {value.label} {showModal ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      {showModal && (
        <div className={styles.calendarLabelModal} ref={listRef}>
          {months.map((date, idx: number) => {
            const isSame = moment(date).isSame(value.date);

            return (
              <div
                key={date.toString()}
                className={classNames({
                  [styles.calendarLabelModalActive]: isSame,
                })}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  value.onChange(date);
                  setShowModal(false);
                }}
              >
                <CheckLg style={{ opacity: isSame ? 1 : 0 }} size={16} /> {moment(date).format('MMMM YYYY')}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CalendarLabel;
