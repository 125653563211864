import classNames from 'classnames';
import type { FC } from 'react';
import { useCallback, useDeferredValue, useEffect } from 'react';
import { useParams } from 'react-router';
import { UndoManager, YMapEvent } from 'yjs';

import { useDispatch } from 'react-redux';
import type { Edge, Node } from 'reactflow';
import type { YMap } from 'yjs/dist/src/internals';
import LeftIcon from '../../../assets/icons/LeftIcon.svg';
import RightIcon from '../../../assets/icons/RightIcon.svg';
import { NODES } from '../../../global/constants';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from './index.module.css';

type UndoRepoProps = {
  setIsSelectionMode: (val: boolean) => void;
  copyPasteHandlers: any;
};

type ChangedParentTypes = Map<YMap<Node> | YMap<Edge>, YMapEvent<Node | Edge>[]>;

const UndoRedo: FC<UndoRepoProps> = ({ setIsSelectionMode, copyPasteHandlers }) => {
  const { nodesSharedState, edgesSharedState, canCopy, undoManager } = useTypedSelector((state) => state.diagram);
  const prevDiagramState = useDeferredValue([
    ...Array.from(nodesSharedState?.values() || []),
    ...Array.from(edgesSharedState?.values() || []),
  ]);
  const dispatch = useDispatch<Dispatch>();
  const params = useParams();
  const { diagramId } = params;
  const canUndo = undoManager?.canUndo();
  const canRedo = undoManager?.canRedo();

  useEffect(() => {
    if (!undoManager && nodesSharedState && edgesSharedState) {
      const undoManager = new UndoManager([nodesSharedState, edgesSharedState], {
        ignoreRemoteMapChanges: false,
      });

      dispatch.diagram.setUndoManager(undoManager);
    }
  }, [nodesSharedState, edgesSharedState, undoManager, dispatch.diagram]);

  const handleBack = useCallback(() => {
    if (undoManager) undoManager.undo();

    setIsSelectionMode(false);
  }, [setIsSelectionMode, undoManager]);

  const handleForward = useCallback(() => {
    if (undoManager) undoManager.redo();

    setIsSelectionMode(false);
  }, [setIsSelectionMode, undoManager]);

  useEffect(() => {
    const handleKeyDown = async (e: KeyboardEvent) => {
      if (e.ctrlKey || e.metaKey) {
        if (e.key === 'z' && e.shiftKey) {
          e.preventDefault();
          handleForward();

          return;
        }

        if (e.key === 'z') {
          e.preventDefault();
          handleBack();

          return;
        }

        if (e.key === 'c' && diagramId && canCopy) {
          copyPasteHandlers?.copy();
          setIsSelectionMode(false);
        }

        if (e.key === 'x' && diagramId && canCopy) {
          copyPasteHandlers?.cut();
          setIsSelectionMode(false);
        }

        if (e.key === 'v' && diagramId && canCopy) {
          copyPasteHandlers?.paste();
          setIsSelectionMode(false);
        }
      }

      if (e.key === 'Escape') {
        setIsSelectionMode(false);
        dispatch.representation.setActiveTemplateId(null);
        dispatch.representation.setTemplatesModalVisibility(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [canCopy, copyPasteHandlers, diagramId, dispatch.representation, handleBack, handleForward, setIsSelectionMode]);

  const getKeysChanged = (changedParentTypes: ChangedParentTypes, diagramState: (Node | Edge)[]) => {
    const keysChanged: string[] = [];

    changedParentTypes.forEach((events) => {
      events.forEach((event) => {
        if (event instanceof YMapEvent) {
          keysChanged.push(
            ...Array.from(event.keysChanged).filter((key) => !diagramState.some((item) => item.id === key)),
          );
        }
      });
    });

    return keysChanged;
  };

  const filterStickers = (keysChanged: string[], diagramState: (Node | Edge)[]) => {
    return keysChanged.filter((key) => {
      return diagramState.some(({ type, id }) => type !== NODES.STICKER_NODE && id === key);
    });
  };

  const onStackItemPopped = useCallback(
    (event: { changedParentTypes: ChangedParentTypes }) => {
      const currentDiagramState = [
        ...Array.from(nodesSharedState?.values() || []),
        ...Array.from(edgesSharedState?.values() || []),
      ];

      const deletedComponents = filterStickers(
        getKeysChanged(event.changedParentTypes, currentDiagramState),
        prevDiagramState,
      );

      const revertedComponents = filterStickers(
        getKeysChanged(event.changedParentTypes, prevDiagramState),
        currentDiagramState,
      );

      if (deletedComponents.length) {
        dispatch.drawn.removeComponents(deletedComponents);
      }

      if (revertedComponents.length) {
        Promise.all(
          revertedComponents.map((id) => {
            return dispatch.drawn.getComponent({ id });
          }),
        );
      }
    },
    [dispatch.drawn, edgesSharedState, nodesSharedState, prevDiagramState],
  );

  useEffect(() => {
    if (undoManager && nodesSharedState && edgesSharedState) {
      undoManager.on('stack-item-popped', onStackItemPopped);

      return () => {
        undoManager.off('stack-item-popped', onStackItemPopped);
      };
    }
  }, [undoManager, nodesSharedState, edgesSharedState, dispatch.drawn, onStackItemPopped]);

  return (
    <div className={classNames('block rounded-1 shadow bg-white', styles.toolItem)}>
      <div
        className={classNames(styles.hasTooltip, {
          [styles.hasTooltipDisable]: !canUndo,
        })}
        data-tooltip="Undo Ctrl+Z"
        onClick={handleBack}
      >
        <img src={LeftIcon} alt="LeftIcon" className="noScale" />
      </div>
      <div
        className={classNames(styles.hasTooltip, {
          [styles.hasTooltipDisable]: !canRedo,
        })}
        data-tooltip="Redo Ctrl+Shift+Z"
        onClick={handleForward}
      >
        <img src={RightIcon} alt="RightIcon" className="noScale" />
      </div>
    </div>
  );
};

export default UndoRedo;
