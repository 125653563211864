import classNames from 'classnames';
import type { FC } from 'react';
import styles from './ThreatIndicator.module.css';

type ThreatIndicatorProp = {
  leftValue: number;
  rightValue: number;
  widthBackground?: boolean;
};

const ThreatIndicator: FC<ThreatIndicatorProp> = ({ rightValue, leftValue, widthBackground }) => {
  if (widthBackground)
    return (
      <div className={styles.wrap}>
        <div className={styles.left}>{leftValue}</div>
        <span className={styles.center} />
        <div className={styles.right}>{rightValue}</div>
      </div>
    );

  return (
    <div className={classNames(styles.wrap, styles.wrapWithoutBack)}>
      <div className={styles.leftWitoutBack}>{leftValue}</div>
      <span className={styles.center} />
      <div className={styles.rightWithoutBack}>{rightValue}</div>
    </div>
  );
};

export default ThreatIndicator;
