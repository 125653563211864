import { minidenticon } from 'minidenticons';
import type { FC } from 'react';
import { useMemo } from 'react';

type RandomAvatarProps = {
  username?: string;
  saturation?: string;
  lightness?: string;
  width?: string;
  height?: string;
};

const RandomAvatar: FC<RandomAvatarProps> = ({
  username = 'laurent',
  saturation = '90',
  lightness = '40',
  ...props
}) => {
  const svgURI = useMemo(
    () => `data:image/svg+xml;utf8,${encodeURIComponent(minidenticon(username, saturation, lightness))}`,
    [username, saturation, lightness],
  );

  return (
    <span>
      <img src={svgURI} alt={username} {...props} />
    </span>
  );
};

export default RandomAvatar;
