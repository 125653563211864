import type { FC } from 'react';
import { useRef, useState } from 'react';
import { PlusLg } from 'react-bootstrap-icons';
import OutsideClickHandler from 'react-outside-click-handler';
import UiButton from '../../common/UIButton/UiButton';

import { AliasItem } from './AliasItem';

type AliasesFormProps = {
  aliases: string[];
  currentTitle: string;
  setAliases: (values: string[]) => void;
};

export const AliasesForm: FC<AliasesFormProps> = ({ aliases, currentTitle, setAliases }) => {
  const aliasesRef = useRef<any>('AliasesRef');
  const [showForm, setShowForm] = useState(false);

  const handleAdd = () => {
    const newAlias = aliasesRef.current.value as string;

    if (newAlias.length && !aliases?.includes(newAlias)) {
      setAliases([...aliases, newAlias]);
      aliasesRef.current.value = '';
    }
  };

  const handleRemove = (deletedAlias: string) => {
    setAliases([...(aliases?.filter((i: string) => i !== deletedAlias) || [])]);
  };

  return (
    <div className="mt-2 fs-12">
      <span className="fw-bold">Aliases:</span>
      <div className="my-1 d-flex gap-2 flex-wrap">
        {aliases?.length
          ? aliases.map((a: string) => (
              <AliasItem key={a} title={a} onDelete={handleRemove} isCurrent={currentTitle === a} />
            ))
          : 'No aliases'}
      </div>
      {showForm && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setShowForm(false);
            aliasesRef.current.value = '';
          }}
        >
          <div className="d-flex align-items-center mt-3">
            <input
              ref={aliasesRef}
              className="p-1"
              placeholder="Enter alias"
              style={{ borderRadius: 4, border: '1px solid #87878d' }}
            />
            <UiButton type="transparent" style={{ height: 30 }} onClick={handleAdd}>
              <PlusLg className="cursor-pointer me-1" size={14} /> <span className="fs-12">Add</span>
            </UiButton>
          </div>
        </OutsideClickHandler>
      )}

      {!showForm && (
        <UiButton type="transparent" className="ps-0" style={{ height: 30 }} onClick={() => setShowForm(true)}>
          <PlusLg className="cursor-pointer me-1" size={14} /> <span className="fs-12">Add alias</span>
        </UiButton>
      )}
    </div>
  );
};
