import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UsersAdd from '../../../components/Users/UsersAdd/UsersAdd';
import { useTypedSelector } from '../../../hooks/useTypeSelector';

const UserAddPage = () => {
  const { current } = useTypedSelector((state) => state.user);
  const { isAuthenticated } = useTypedSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && current?.role !== 'admin') {
      navigate('/');
    }
  }, []);

  return <UsersAdd />;
};

export default UserAddPage;
