import classNames from 'classnames';
import type { FC } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useParams } from 'react-router';

import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { DRAWN_WIDTH } from '../../../store/constants/drawn-constants';
import type { Dispatch } from '../../../store/store';
import { CreateAttributeForm } from './CreateAttributeForm';
import { CreateMitigationForm } from './CreateMitigationForm';
import { CreateThreatForm } from './CreateThreatForm';
import styles from './CustomCodexTable.module.css';

let isResizing: any = null;

type CustomCodexDrawerProps = {
  type: 'attributes' | 'threats' | 'mitigations';
};

export const CustomCodexDrawer: FC<CustomCodexDrawerProps> = ({ type }) => {
  const params = useParams();
  const dispatch = useDispatch<Dispatch>();
  const sidebarPanel = useRef<any>('CustomCodexSidebarPanel');
  const { visible, drawnWidth } = useTypedSelector((state) => state.drawn);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const cbHandleMouseMove = useCallback(handleMousemove, []);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const cbHandleMouseUp = useCallback(handleMouseup, []);

  useEffect(() => {
    if (!params?.['*']?.length) {
      dispatch.drawn.setVisible(false);

      if (visible) dispatch.customCodex.resestQuery();
    }
  }, [params]);

  useEffect(() => {
    if (sidebarPanel.current) {
      if (visible) sidebarPanel.current.style.display = 'block';
      else sidebarPanel.current.style.display = 'none';

      sidebarPanel.current.style.width = `${drawnWidth}px`;
    }
  }, [visible]);

  function handleMousedown(e: any) {
    e.stopPropagation();
    e.preventDefault();
    sidebarPanel.current.style.transition = 'unset';
    // we will only add listeners when needed, and remove them afterward
    document.addEventListener('mousemove', cbHandleMouseMove);
    document.addEventListener('mouseup', cbHandleMouseUp);
    isResizing = true;
  }

  function handleMousemove(e: any) {
    if (!isResizing) return;

    const offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);

    if (DRAWN_WIDTH.MAX > offsetRight && offsetRight > 420) {
      sidebarPanel.current.style.width = `${offsetRight}px`;
      dispatch.drawn.setDrawnWidth(offsetRight);
    }
  }

  function handleMouseup() {
    if (!isResizing) return;

    sidebarPanel.current.style.transition = 'all .3s';
    isResizing = false;
    document.removeEventListener('mousemove', cbHandleMouseMove);
    document.removeEventListener('mouseup', cbHandleMouseUp);
  }

  return (
    <div
      className={classNames(styles.sidebarContainer, 'bg-white noScale')}
      ref={sidebarPanel}
      id="custom-codex-drawn-element"
    >
      <div className={classNames(styles.sidebarDrawer, 'position-absolute ')} onMouseDown={handleMousedown} />
      <Routes>{type === 'attributes' ? <Route path="/:attributeId" element={<CreateAttributeForm />} /> : null}</Routes>
      <Routes>{type === 'threats' ? <Route path="/:threatId" element={<CreateThreatForm />} /> : null}</Routes>
      <Routes>
        {type === 'mitigations' ? <Route path="/:mitigationId" element={<CreateMitigationForm />} /> : null}
      </Routes>
    </div>
  );
};
