import classNames from 'classnames';
import type { FC } from 'react';
import { XLg } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';

import { ATTRIBUTES_SEARCH_TYPE } from '../../../helpers/diagram';
import type { Dispatch } from '../../../store/store';
import styles from './AISearchInput.module.css';

type AttributeSearchTypeButtonsProps = {
  searchType: ATTRIBUTES_SEARCH_TYPE;
  setSearchType: (type: ATTRIBUTES_SEARCH_TYPE) => void;
  showCloseBtn: boolean;
  clearSearchValue?: () => void;
  hideSearch?: () => void;
};

export const AttributeSearchTypeButtons: FC<AttributeSearchTypeButtonsProps> = ({
  searchType,
  setSearchType,
  showCloseBtn,
  clearSearchValue,
  hideSearch,
}) => {
  const dispatch = useDispatch<Dispatch>();

  return (
    <div className="fs-6 fw-bold mb-2 noScale d-flex align-items-center justify-content-between">
      <div className={styles.searchTypeButtons}>
        {Object.values(ATTRIBUTES_SEARCH_TYPE).map((type) => (
          <button
            key={type}
            type="button"
            className={classNames({
              [styles.searchTypeButtonActive]: searchType === type,
            })}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSearchType(type);
            }}
          >
            {type}
          </button>
        ))}
      </div>
      {showCloseBtn && (
        <div
          className="cursor-pointer"
          onClick={() => {
            if (hideSearch) hideSearch();

            setSearchType(ATTRIBUTES_SEARCH_TYPE.CODEX);

            if (clearSearchValue) clearSearchValue();

            dispatch.diagram.setAttributes([]);
            dispatch.diagram.setMitigatingAttributes(null);
            dispatch.diagram.setPopupMitigatingAttributes(null);
          }}
        >
          <XLg size={14} color="#0062D8" />
        </div>
      )}
    </div>
  );
};
