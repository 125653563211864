import type { FC, ReactElement } from 'react';
import styles from './AdminDropdown.module.css';

type AdminDropdownProps = {
  title: string;
  description: string;
  content: ReactElement;
  hide?: boolean;
};

export const AdminDropdown: FC<AdminDropdownProps> = ({ title, description, content, hide = false }) => {
  if (hide) return null;

  return (
    <div className="border-bottom p-3 user-select-none">
      <div className={styles.title}>{title}</div>
      <div className="d-flex justify-content-between">
        <div>{description}</div>
      </div>
      <div className="mt-2">{content}</div>
    </div>
  );
};
