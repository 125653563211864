import type { FC } from 'react';
import type { Comment } from '../../../global/types';
import CommentItem from '../CommentItem/CommentItem';
import styles from './CommentList.module.css';

type CommentListProps = {
  commentData: {
    items: Comment[];
    count: number;
  };
  onMoreCommentsClick: (increaseBy: number) => void;
};

const CommentList: FC<CommentListProps> = ({ commentData, onMoreCommentsClick }) => {
  const handleLoadMoreClick = () => {
    onMoreCommentsClick(5);
  };

  return (
    <div className={styles.list}>
      {commentData.items.length < commentData.count && (
        <p onClick={handleLoadMoreClick} className={styles.moreComments}>
          5 more comments
        </p>
      )}
      {commentData.items.map((comment) => (
        <CommentItem key={comment.id} comment={comment} />
      ))}
    </div>
  );
};

export default CommentList;
