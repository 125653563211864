import type { FC } from 'react';
import { useState } from 'react';
import { XLg } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from './CustomCodexTable.module.css';

let timer: any = null;

type SearchInputProps = {
  type: 'attributes' | 'mitigations' | 'threats';
};

export const SearchInput: FC<SearchInputProps> = ({ type }) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<Dispatch>();
  const { query } = useTypedSelector((state) => state.customCodex);
  const { drawnWidth } = useTypedSelector((state) => state.drawn);

  const clearData = () => {
    if (type === 'attributes') dispatch.customCodex.setAttributes(null);

    if (type === 'threats') dispatch.customCodex.setThreats(null);

    if (type === 'mitigations') dispatch.customCodex.setMitigations(null);
  };

  const getData = () => {
    if (type === 'attributes') {
      dispatch.customCodex.getCustomAttributes({
        ...query,
        title: null,
        page: 0,
      });
    }

    if (type === 'threats') {
      dispatch.customCodex.getCustomThreats({
        ...query,
        title: null,
        page: 0,
      });
    }

    if (type === 'mitigations') {
      dispatch.customCodex.getCustomMitigations({
        ...query,
        title: null,
        page: 0,
      });
    }
  };

  const searchItems = async (value: string) => {
    setLoading(true);

    if (type === 'attributes') {
      await dispatch.customCodex.getCustomAttributes({
        ...query,
        title: value,
      });
    }

    if (type === 'threats') {
      await dispatch.customCodex.getCustomThreats({
        ...query,
        title: value,
      });
    }

    if (type === 'mitigations') {
      await dispatch.customCodex.getCustomMitigations({
        ...query,
        title: value,
      });
    }

    if (drawnWidth) dispatch.drawn.setVisible(false);

    setLoading(false);
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value);

    if (!value.length) {
      clearData();
      getData();
    }

    if (value.length > 1) {
      clearData();
      clearTimeout(timer);
      timer = setTimeout(() => {
        searchItems(value.trim());
      }, 500);
    }
  };

  return (
    <div className="position-relative">
      <input className={styles.searchInput} value={search} placeholder={`Search ${type}`} onChange={onSearchChange} />
      {!!search.length && (
        <div
          className={styles.searchInputClear}
          onClick={() => {
            setSearch('');
            getData();
          }}
        >
          {loading ? <Spinner size="sm" style={{ marginTop: 1 }} /> : <XLg size={16} />}
        </div>
      )}
    </div>
  );
};
