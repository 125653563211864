import classNames from 'classnames';
import { useFormik } from 'formik';
import type { FC, MouseEvent, SyntheticEvent } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import { ChevronRight, Trash3 } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { COLUMN_NAMES, DEFAULT_THREAT_MODEL_URL_QUERY } from '../../global/constants';
import type { ThreatModel } from '../../global/types';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import { THREAT_MODEL_STATUS } from '../../store/constants/drawn-constants';
import { DEFAULT_USER_PAGINATION_PARAMS } from '../../store/constants/user-constants';
import type { Dispatch } from '../../store/store';
import UiModal from '../common/Modal/UiModal';
import UiButton from '../common/UIButton/UiButton';
import UiInput from '../common/UiInput/UiInput';
import styles from './CardSettings.module.css';

type ModalType = {
  visible: boolean;
};

type CardSettingsProps = {
  currentItem: ThreatModel;
  position: {
    pageX: number;
    pageY: number;
  };
  handleEmitAfterUpdate: any;
  onTransferClick?: (project: any, selectedForTransferModel: any) => void;
  setIsSettings: (value: any) => void;
  onArchiveClick?: () => void;
  zIndex?: number;
};

const CardSettings: FC<CardSettingsProps> = ({
  currentItem,
  position,
  handleEmitAfterUpdate,
  onTransferClick,
  setIsSettings,
  onArchiveClick,
  zIndex,
}) => {
  const [isRight, setIsRight] = useState(false);
  const [isBottom, setIsBottom] = useState(false);
  const [showRightSettings, setShowRightSettings] = useState(false);
  const popupRef = useRef<any>(null);
  const dispatch = useDispatch<Dispatch>();
  const params = useParams();
  const { id: projectId } = params;
  const { current } = useTypedSelector((state) => state.project);
  const { REPRESENTATION, THREATS_AND_MITIGATIONS, RETROSPECTIVE, DONE } = useTypedSelector(
    (state) => state.threatModel,
  );
  const [showModal, setShowModal] = useState<ModalType | null>({ visible: false });

  useLayoutEffect(() => {
    if (popupRef.current) {
      const popupRect = popupRef.current.getBoundingClientRect();

      if (popupRect.left + popupRect.width > window.innerWidth) setIsRight(true);

      if (popupRect.top + 260 > window.innerHeight) setIsBottom(true);
    }

    if (!current && projectId) {
      dispatch.project.getProjectById(projectId);
    }

    return () => {
      setIsRight(false);
      setIsBottom(false);
    };
  }, []);

  const threatsStatusActions: { [key: string]: any } = {
    [THREAT_MODEL_STATUS.REPRESENTATION]: REPRESENTATION,
    [THREAT_MODEL_STATUS.THREATS_AND_MITIGATIONS]: THREATS_AND_MITIGATIONS,
    [THREAT_MODEL_STATUS.RETROSPECTIVE]: RETROSPECTIVE,
    [THREAT_MODEL_STATUS.DONE]: DONE,
  };

  let moveToOptions = [
    COLUMN_NAMES.REPRESENTATION,
    COLUMN_NAMES.THREATS_AND_MITIGATIONS,
    COLUMN_NAMES.RETROSPECTIVE,
    COLUMN_NAMES.DONE,
  ];

  moveToOptions = moveToOptions.filter((option) => option !== currentItem.status);

  const updateColumnByCurrentThreatModelStatus = (threatModel: ThreatModel) => {
    const action = threatsStatusActions[threatModel.status];

    if (action) {
      dispatch.threatModel.getThreatModelsByQuery(action);
    }
  };

  const updateTitleFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: currentItem?.title || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().max(100, 'Max length is 100 characters').min(1).required('Required'),
    }),
    onSubmit: async (values) => {
      currentItem.title = values.title;
      const resStatus = await dispatch.threatModel.updateThreatModel(currentItem);

      if (resStatus === 200) {
        updateColumnByCurrentThreatModelStatus(currentItem);
        setShowModal({ visible: false });
        setIsSettings({ visible: false, pageX: 0, pageY: 0 });
      } else {
        toast.error('Error while update threat model!');
      }
    },
  });

  const handleStatusClick = async (event: SyntheticEvent, option: string) => {
    event?.preventDefault();
    event?.stopPropagation();
    const currentStatus = currentItem.status;
    currentItem.status = option;
    setShowRightSettings(false);
    setIsSettings({ visible: false, pageX: 0, pageY: 0 });
    await dispatch.threatModel.updateThreatModel(currentItem);
    dispatch.threatModel.getThreatModelsByQuery({
      ...DEFAULT_THREAT_MODEL_URL_QUERY,
      status: currentStatus,
      projectId: current?.id,
      fetch: true,
    });
    await dispatch.threatModel.getThreatModelsByQuery({
      ...DEFAULT_THREAT_MODEL_URL_QUERY,
      status: option,
      projectId: current?.id,
      fetch: true,
    });
    handleEmitAfterUpdate && handleEmitAfterUpdate();
  };

  const handleMoveToClick = (event: any) => {
    event.stopPropagation();
    setShowRightSettings(true);
  };

  const handleMoveToMouseLeave = () => {
    setShowRightSettings(false);
  };

  const handleTransferClick = async (event: any) => {
    event.stopPropagation();

    if (onTransferClick && (currentItem?.project || current)) {
      await onTransferClick(currentItem?.project || current, currentItem);
    }
  };

  const handleDuplicateClick = async (event: any) => {
    event.stopPropagation();
    const status = await dispatch.threatModel.duplicate(currentItem.id);

    if (status === 201) {
      dispatch.threatModel.getThreatModelsByQuery(REPRESENTATION);
      dispatch.threatModel.getThreatModelsByQuery(THREATS_AND_MITIGATIONS);
      dispatch.threatModel.getThreatModelsByQuery(RETROSPECTIVE);
      dispatch.threatModel.getThreatModelsByQuery(DONE);
    }
  };

  const handleArchiveClick = async (event: any) => {
    event.stopPropagation();
    await dispatch.threatModel.archive(currentItem.id);
    await dispatch.threatModel.getThreatModelsByQuery({
      ...DEFAULT_USER_PAGINATION_PARAMS,
      projectId: current?.id || projectId,
      status: currentItem.status,
      page: 0,
    });
    handleEmitAfterUpdate && handleEmitAfterUpdate(true, currentItem.id);
    onArchiveClick && onArchiveClick();
  };

  const handleModalClose = () => {
    setShowModal({
      visible: false,
    });
  };

  const handleRenameClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setShowModal({
      visible: true,
    });
  };

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <div
        className={classNames(styles.settings, {
          [styles.settingsOverlayRight]: isRight,
          [styles.settingsOverlayBottom]: isBottom,
        })}
        ref={popupRef}
        style={{
          zIndex: zIndex || 'var(--modal-z-index)',
          left: position.pageX,
          top: position.pageY,
        }}
      >
        <div className={styles.settingsLeft}>
          <div className={styles.listItem} onClick={handleMoveToClick}>
            <div>Move to</div>
            <div className={styles.rightChevron}>
              <ChevronRight size={16} />
            </div>
          </div>
          <div className={styles.listItem} onClick={handleRenameClick}>
            <div>Rename model</div>
          </div>
          <div className={styles.listItem} onClick={handleTransferClick}>
            <div>Transfer model</div>
          </div>
          <div className={styles.listItem} onClick={handleDuplicateClick}>
            <div>Duplicate model</div>
          </div>
          <div onClick={handleArchiveClick} className={`${styles.archiveItem} ${styles.red}`}>
            <div className={styles.listItemIcon}>
              <Trash3 size={16} />
            </div>
            Archive
          </div>
          {showRightSettings && (
            <div className={styles.settingsRight} onMouseLeave={handleMoveToMouseLeave}>
              {moveToOptions.map((option) => (
                <div
                  key={option}
                  className={styles.listItem}
                  onClick={(event: any) => handleStatusClick(event, option)}
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {showModal?.visible && (
        <UiModal title="Rename Threat model" onClose={handleModalClose}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <div>
              <UiInput
                name="title"
                value={updateTitleFormik.values.title}
                onChange={updateTitleFormik.handleChange}
                onBlur={updateTitleFormik.handleBlur}
              />
            </div>

            <div style={{ display: 'flex', justifyContent: 'end', gap: '10px' }}>
              <UiButton type="transparent" onClick={handleModalClose}>
                Cancel
              </UiButton>
              <UiButton onClick={() => updateTitleFormik.submitForm()}>Update</UiButton>
            </div>
          </div>
        </UiModal>
      )}
    </div>
  );
};

export default CardSettings;
