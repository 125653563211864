import { THREATS_REGISTER_SELECTED_THREAT } from '../store/constants/threat-register-constants';
import type { IThreat } from '../store/models/drawn';

export const setThreatRegisterSelectedThreat = (value: Partial<IThreat>) => {
  localStorage.setItem(THREATS_REGISTER_SELECTED_THREAT, JSON.stringify(value));
};

export const getThreatRegisterSelectedThreat = () => {
  return JSON.parse(localStorage.getItem(THREATS_REGISTER_SELECTED_THREAT) || '{}');
};
