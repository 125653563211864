import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import { toggleElementInArray } from '../../../helpers/app';
import { capitalizeFirstLetter } from '../../../helpers/user';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { THREAT_PRIORITY, THREAT_STATUS } from '../../../store/constants/drawn-constants';
import type { Dispatch } from '../../../store/store';
import styles from '../ThreatRegisterFilter/ThreatRegisterFilter.module.css';

const enum FILTERS {
  STATUSES = 'statuses',
  PRIORITIES = 'priorities',
  CANVASES = 'canvases',
}

interface ElementRegisterFilterProps {
  isFilterActive: boolean;
}
const ElementRegisterFilter: FC<ElementRegisterFilterProps> = ({ isFilterActive }) => {
  const dispatch = useDispatch<Dispatch>();
  const { subThreatRegisterListQuery, elementRegisterListQuery } = useTypedSelector((state) => state.threats);
  const { currentThreatModel } = useTypedSelector((state) => state.threatModel);

  const handleCheckBoxChange = (filterName: string, value: string) => {
    let newValues: string[] = [];

    if (filterName === FILTERS.STATUSES) {
      newValues = toggleElementInArray(elementRegisterListQuery.statuses, value);
    }

    if (filterName === FILTERS.PRIORITIES) {
      newValues = toggleElementInArray(elementRegisterListQuery.priorities, value);
    }

    if (filterName === FILTERS.CANVASES) {
      newValues = toggleElementInArray(elementRegisterListQuery.canvases, value);
    }

    const threatModelId = currentThreatModel?.id;

    dispatch.threats.getElementRegisterList({
      ...elementRegisterListQuery,
      threatModelId,
      [filterName]: newValues,
      page: 0,
    });
    dispatch.threats.getThreatsByComponentId({
      ...subThreatRegisterListQuery,
      [filterName]: newValues,
      page: 0,
    });
  };

  return (
    <div
      style={{
        marginLeft: isFilterActive ? -15 : '-200px',
      }}
      className={styles.content}
    >
      <div className={styles.menuDiv}>
        <div className={styles.header}>Status</div>
        <div className={styles.itemList}>
          {Object.values(THREAT_STATUS).map((status) => (
            <div key={status} className={styles.item}>
              <div className={styles.checkbox}>
                <input
                  id={status}
                  checked={elementRegisterListQuery?.statuses?.includes(status)}
                  onChange={(event) => handleCheckBoxChange(FILTERS.STATUSES, status)}
                  type="checkbox"
                />
              </div>
              <label htmlFor={status} className="cursor-pointer">
                {capitalizeFirstLetter(status)}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.menuDiv}>
        <div className={styles.header}>Priority</div>
        <div className={styles.itemList}>
          {Object.values(THREAT_PRIORITY).map((priority) => (
            <div key={priority} className={styles.item}>
              <div className={styles.checkbox}>
                <input
                  id={priority}
                  checked={elementRegisterListQuery?.priorities?.includes(priority)}
                  onChange={(event) => handleCheckBoxChange(FILTERS.PRIORITIES, priority)}
                  type="checkbox"
                />
              </div>
              <label className="cursor-pointer" htmlFor={priority}>
                {capitalizeFirstLetter(priority)}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.menuDiv}>
        <div className={styles.header}>Canvases</div>
        <div className={styles.itemList}>
          {currentThreatModel?.representations?.map((rep, idx) => (
            <div key={`${rep.id}-${idx}`} className={styles.item}>
              <div className={styles.checkbox}>
                <input
                  id={rep.title || `Canvas ${idx + 1}`}
                  checked={elementRegisterListQuery?.canvases?.includes(rep.id)}
                  onChange={(event) => handleCheckBoxChange(FILTERS.CANVASES, rep.id)}
                  type="checkbox"
                />
              </div>
              <label className="cursor-pointer" htmlFor={rep.title || `Canvas ${idx + 1}`}>
                {capitalizeFirstLetter(rep.title || `Canvas ${idx + 1}`)}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ElementRegisterFilter;
