import classNames from 'classnames';
import type { FC } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { ArrowLeft, ChevronRight } from 'react-bootstrap-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Spinner } from 'reactstrap';
import { useSelectedEntityType } from '../../../hooks/useSelectedEntityType';
import type { EmitUpdate } from '../../../hooks/useSocket';
import { UpdateTypes } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { AttributeTypes } from '../../../store/models/attributes';
import type { Dispatch } from '../../../store/store';
import SearchAttribute from '../../common/SearchAttribute/SearchAttribute';
import UiButton from '../../common/UIButton/UiButton';
import styles from './index.module.css';

const attributesTypesSet = {
  [AttributeTypes.DATA]: `${AttributeTypes.DATA[0].toUpperCase()}${AttributeTypes.DATA.slice(1)}`,
  [AttributeTypes.FUNCTIONAL]: `${AttributeTypes.FUNCTIONAL[0].toUpperCase()}${AttributeTypes.FUNCTIONAL.slice(1)}`,
};

type AttributesExplorerProps = {
  emitUpdate?: EmitUpdate;
};

const AttributesExplorer: FC<AttributesExplorerProps> = ({ emitUpdate }) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const attributes = useTypedSelector((state) => state.attributes);
  const { limit, page, count } = useTypedSelector((state) => state.attributes);
  const currentThreatModel = useTypedSelector((state) => state.threatModel.currentThreatModel);

  const canFetchMore = page * limit < count;

  const [selectedType, setSelectedType] = useState<any>(null);
  const [selectedAttribute, setSelectedAttribute] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const selectedEntityType = useSelectedEntityType();
  const isAlreadyExistAttribute = useTypedSelector((state) => {
    return !!state.drawn.component?.attributes?.find((attr) => attr.title === selectedAttribute?.title);
  });
  const [canAdd, setCanAdd] = useState(true);

  useEffect(() => {
    return () => {
      dispatch.diagram.clearAttributes();
      dispatch.attributes.setLimit(10);
      dispatch.attributes.clearAttributes();
    };
  }, []);

  const nodeTitle = useTypedSelector(({ drawn }) => drawn?.component?.title) || '';

  const { diagramId, componentId } = params;

  const handleEmitAfterUpdate = () => {
    if (diagramId && emitUpdate) {
      emitUpdate(UpdateTypes.COMPONENT, componentId!, true);
    }

    if (currentThreatModel?.id && emitUpdate) {
      emitUpdate(UpdateTypes.REGISTER, currentThreatModel.id, true);
      emitUpdate(UpdateTypes.ELEMENT_REGISTER, currentThreatModel.id, true);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSearchSelect = async (attributeTitle: string) => {
    const foundAttr = await dispatch.diagram.getAttributeByTitle(attributeTitle);
    dispatch.diagram.clearAttributes();
    setSelectedAttribute(foundAttr);
  };

  const handleAddAttributeClick = async () => {
    if (canAdd) {
      setCanAdd(false);
      await dispatch.drawn.addAttribute({
        componentId,
        title: selectedAttribute.title,
      });

      if (componentId) {
        await dispatch.drawn.syncThreats(componentId);
        handleEmitAfterUpdate();
        await dispatch.threatModel.updateCurrentThreatModel();
      }

      setSelectedAttribute(null);
      setCanAdd(true);
    }
  };

  const fetchMoreData = async () => {
    const nextPage = page + 1;

    if (nextPage * limit < count) {
      setLoading(true);
      await dispatch.attributes.getAttributes({
        type: selectedType.toLowerCase(),
        selectedEntityType,
        page: nextPage,
        limit: 10,
      });
      setLoading(false);
    }
  };

  return (
    <div style={{ margin: '10px' }}>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="cursor-pointer" onClick={handleBack}>
          <ArrowLeft fontSize={16} className="me-2 text-secondary" />
          <span className="fs-12 fw-bold text-secondary">Back to {nodeTitle || ''}</span>
        </div>
      </div>
      <div style={{ position: 'relative', height: '30px', marginBottom: '6px' }}>
        <SearchAttribute placeholder="Search Attribute" onAttributeSelect={handleSearchSelect} />
      </div>

      <ul className={classNames('border rounded list-unstyled', styles.threatsList)} id="scrollableTargetDiv123">
        {Object.values(attributesTypesSet).map((type: string) => (
          <Fragment key={type}>
            <li
              className={classNames(styles.threatsListItem, {
                [styles.threatsListItemActive]: selectedType === type,
              })}
              onClick={() => {
                setSelectedType(type !== selectedType ? type : null);
                dispatch.attributes.setLimit(10);
                dispatch.attributes.getAttributes({
                  type: type.toLowerCase() as AttributeTypes,
                  selectedEntityType,
                  page: 0,
                  limit: 10,
                });
              }}
            >
              {type}
              {selectedType === type ? <ChevronRight fontSize={16} /> : <ChevronRight fontSize={16} />}
            </li>
            {selectedType === type && attributes[type.toLowerCase() as AttributeTypes]?.data && (
              <InfiniteScroll
                scrollableTarget="scrollableTargetDiv123"
                dataLength={attributes[type.toLowerCase() as AttributeTypes]?.data?.length}
                next={fetchMoreData}
                hasMore={canFetchMore}
                loader={
                  loading ? (
                    <li className={styles.threatsListSubItemLoader}>
                      <Spinner color="dark" size="sm" />
                    </li>
                  ) : null
                }
              >
                {attributes[type.toLowerCase() as AttributeTypes]?.data.map((attribute: any) => {
                  return (
                    <li
                      className={classNames(styles.threatsListSubItem, {
                        [styles.threatsListSubItemActive]: selectedAttribute?.title === attribute.title,
                      })}
                      key={attribute.title}
                      onClick={() => setSelectedAttribute(attribute)}
                    >
                      {attribute.title}
                    </li>
                  );
                })}
              </InfiniteScroll>
            )}
            {selectedType === type && !attributes[type.toLowerCase() as AttributeTypes]?.data && (
              <li className={styles.threatsListSubItemLoader}>
                <Spinner color="dark" size="sm" />
              </li>
            )}
          </Fragment>
        ))}
      </ul>
      {selectedAttribute?.title && (
        <div className={styles.threatsDetail}>
          <h6>{selectedAttribute.title}</h6>
          <p>{selectedAttribute.description}</p>
          <div className="w-100 text-center mb-2">
            {isAlreadyExistAttribute ? (
              <div className="rounded border border-1 border-success text-success fs-12 p-1 w-75 m-auto">
                This attribute already exists in element
              </div>
            ) : (
              <UiButton disabled={!selectedAttribute || !canAdd} onClick={handleAddAttributeClick}>
                Add attribute to element
              </UiButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AttributesExplorer;
