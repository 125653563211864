import type { FC } from 'react';
import { List, ListGroupItem } from 'reactstrap';
import Diagram from '../Diagram';

type MitigationDetailProp = {
  threats: any[];
  mitigations: any[];
  nodes: any[];
  edges: any[];
};

const MitigationDetail: FC<MitigationDetailProp> = ({ threats, mitigations, nodes, edges }) => {
  return (
    <div className="vh-92">
      <div className="container-fluid d-flex h-50">
        <div className="col-md-6">
          <List>
            {threats.map((threat) => (
              <ListGroupItem key={threat.id} className="my-2">
                {threat.title}
              </ListGroupItem>
            ))}
          </List>
        </div>
        <span className="border" />
        <div className="col-md-6">
          <List>
            {mitigations.map((mitigation) => (
              <ListGroupItem key={mitigation.id} className="my-2">
                {mitigation.title}
              </ListGroupItem>
            ))}
          </List>
        </div>
      </div>
      <div className="h-50">
        <Diagram nodes={nodes} edges={edges} isSidebar={false} isLocked borderPosition="top" heightVh={47} />
      </div>
    </div>
  );
};

export default MitigationDetail;
