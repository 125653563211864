import 'reactflow/dist/style.css';
import './index.css';

import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import App from './App';
import { ENVIRONMENT } from './global/constants';
import history from './helpers/history';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';

if (process.env.REACT_APP_LOG_ROCKET) {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET || '', {
    network: { isEnabled: false },
    shouldDebugLog: false,
  });
  setupLogRocketReact(LogRocket);
}

const sentryEnv = process.env.REACT_APP_SENTRY_ENV;
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

if (sentryEnv && sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    environment: sentryEnv,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
    enabled: sentryEnv !== ENVIRONMENT.DEVELOPMENT,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // @ts-expect-error
  <HistoryRouter history={history}>
    <Provider store={store}>
      <App />
    </Provider>
  </HistoryRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
