import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { CheckLg, CircleFill, PlusLg } from 'react-bootstrap-icons';
import { NODES } from '../../../global/constants';
import {
  getColorDependsOnBackground,
  getOpacity,
  getOpacityPercent,
  putColorToLocalStorage,
  replaceColorFromLocalStorage,
} from '../../../helpers/colors';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import UiRangeInput from '../../common/UiRangeInput/UiRangeInput';
import styles from './index.module.css';

const OPTIONS = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

type NodeColorPickerProps = {
  itemsVisible: boolean;
  setItemsVisible: (value: boolean) => void;
  node: any;
  colors: string[];
};

let timer: any = null;

const NodeColorPicker: FC<NodeColorPickerProps> = ({ itemsVisible, setItemsVisible, node, colors }) => {
  const { nodesSharedState } = useTypedSelector((state) => state.diagram);

  const [colorValue, setColorValue] = useState<string>(node?.color || '#FFFFFF');

  const [showPickerTooltip, setShowPickerTooltip] = useState<boolean>(false);

  const [showTooltip, setShowTooltip] = useState<{
    show: boolean;
    value: string | null;
  }>({
    show: false,
    value: null,
  });

  const [opacity, setOpacity] = useState<string>('CC');
  const [opacityValue, setOpacityValue] = useState<number>(getOpacityPercent(node?.color?.substring(7, 9)));

  useEffect(() => {
    if (node?.type === NODES.TRUST_BOUNDARY_NODE) {
      setOpacity('4D');
      setOpacityValue(40);
    }
  }, []);

  const handleSelect = (defaultColor: string) => {
    if (defaultColor) {
      defaultColor += opacity;

      if (!colors.map((c) => c.substring(0, 7)).includes(defaultColor.substring(0, 7)))
        putColorToLocalStorage(defaultColor);

      const inNode = nodesSharedState?.get(node.id);

      if (inNode?.id) {
        setColorValue(defaultColor);
        inNode.data = {
          ...inNode.data,
          color: defaultColor,
        };
        nodesSharedState && nodesSharedState.set(inNode.id, structuredClone(inNode));
      }
    }
  };

  const changeLastColor = (newColor: string) => {
    const lastColor = colors.at(-1);

    if (lastColor && !colors.map((color) => color.substring(0, 7)).includes(newColor.substring(0, 7)))
      replaceColorFromLocalStorage(lastColor, newColor);

    const inNode = nodesSharedState?.get(node.id);

    if (inNode?.id) {
      inNode.data = {
        ...inNode.data,
        color: newColor,
      };
      nodesSharedState && nodesSharedState.set(inNode.id, structuredClone(inNode));
    }
  };

  const handleOpacityChange = (e: any) => {
    e.persist();
    const opacity = getOpacity(e.target.value);
    setOpacity(opacity);
    setOpacityValue(e.target.value);

    clearTimeout(timer);
    timer = setTimeout(() => {
      e.target.id = colorValue.substring(0, 7) + opacity;
      changeLastColor(e.target.id);
    }, 500);
  };

  const handleColorChange = (e: any) => {
    e.persist();
    e.stopPropagation();
    e.preventDefault();
    setColorValue(e.target.value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      changeLastColor(e.target.value);
    }, 500);
  };

  const handleOptionClick = (ev: any, value: number) => {
    ev.target.value = value;
    handleOpacityChange(ev);
  };

  const handleColorMouseEnter = (color: string) => {
    setShowTooltip({
      show: true,
      value: color,
    });
  };

  const handleColorMouseLeave = (color: string) => {
    setShowTooltip({
      show: false,
      value: null,
    });
  };

  const handleMouseEnter = () => {
    setShowPickerTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowPickerTooltip(false);
  };

  const handleShowColorPicker = () => {
    document.getElementById(`node-border-color-input-${node.id}`)?.click();
    handleSelect('#ffffff');
  };

  return (
    <div
      className={classNames(
        {
          [styles.dropdownActive]: itemsVisible,
        },
        styles.dropdown,
        'd-flex align-items-center justify-content-between rounded p-1 px-2 gap-3 cursor-pointer position-relative',
      )}
    >
      {showPickerTooltip && !itemsVisible && <div className="main-tooltip">Color Picker</div>}
      <CircleFill
        onClick={() => setItemsVisible(!itemsVisible)}
        className={styles.dropdownCircleIcon}
        fontSize={25}
        color={colorValue}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      {itemsVisible && (
        <div className={classNames(styles.dropdownItems)} style={{ top: colors.length <= 7 ? '-84px' : '-115px' }}>
          <UiRangeInput options={OPTIONS} value={opacityValue} onOptionClick={handleOptionClick} />
          <div className={styles.dropdownItemWrapper}>
            {colors?.map((c) => (
              <div
                id={c}
                key={c}
                onClick={(e: any) => {
                  if (c.substring(0, 7) !== node?.color?.substring(0, 7)) handleSelect(c);
                  else {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                className={classNames(styles.dropdownItem, styles.hasTooltip, 'rounded-circle')}
                style={{
                  background: c,
                  cursor: c.substring(0, 7) === node?.color?.substring(0, 7) ? 'default' : 'pointer',
                }}
                onMouseEnter={() => handleColorMouseEnter(c)}
                onMouseLeave={() => handleColorMouseLeave(c)}
              >
                {c.substring(0, 7) === node?.color?.substring(0, 7) && (
                  <div className={styles.selectedMark}>
                    <CheckLg size={16} color={getColorDependsOnBackground(c.substring(0, 7))} />
                  </div>
                )}
                {showTooltip.show && showTooltip.value === c && <div className={styles.tooltip}>{c}</div>}
              </div>
            ))}
            {colors.length <= 15 && (
              <div className={styles.plusIcon} onClick={handleShowColorPicker}>
                <PlusLg className="cursor-pointer" />
              </div>
            )}
          </div>
        </div>
      )}
      <input
        id={`node-border-color-input-${node.id}`}
        className="position-absolute opacity-0"
        type="color"
        style={{ pointerEvents: 'none' }}
        onChange={handleColorChange}
        defaultValue="#ffffff"
      />
    </div>
  );
};

export default NodeColorPicker;
