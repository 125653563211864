import classNames from 'classnames';
import type { FC } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { ArrowLeft, ChevronRight } from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { SKELETON_COLORS } from '../../../global/constants';
import { toPascalCase } from '../../../helpers/app';
import type { EmitUpdate } from '../../../hooks/useSocket';
import { UpdateTypes } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import UiButton from '../../common/UIButton/UiButton';
import UiSearch from '../../common/UiSearch/UiSearch';
import styles from './index.module.css';

type ThreatsExplorerProps = {
  emitUpdate?: EmitUpdate;
};

const ThreatsExplorer: FC<ThreatsExplorerProps> = ({ emitUpdate }) => {
  const params = useParams();

  const { diagramId, componentId } = params;
  const { currentThreatModel } = useTypedSelector((state) => state.threatModel);

  const handleEmitAfterAdd = () => {
    if (currentThreatModel && emitUpdate) {
      emitUpdate(UpdateTypes.REGISTER, currentThreatModel.id, true);
      emitUpdate(UpdateTypes.ELEMENT_REGISTER, currentThreatModel.id, true);
    }
  };

  const handleEmitAfterUpdate = () => {
    if (diagramId && emitUpdate) {
      emitUpdate(UpdateTypes.COMPONENT, componentId!, true);
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const nodeTitle = useTypedSelector(({ drawn }) => drawn?.component?.title) || '';
  const [selectedType, setSelectedType] = useState<any>({});
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [selectedMitigation, setSelectedMitigation] = useState<any>({});
  const [selectedChild, setSelectedChild] = useState<any>({});
  const { typeAndCategoryList } = useTypedSelector((state) => state.threats);
  const currentComponent = useTypedSelector((state) => state.drawn.component);
  const isAlreadyExistThreat = useTypedSelector((state) => {
    return !!state.drawn.component?.threats?.find((th) => th.ref_id === selectedChild?.id && !th.is_custom);
  });
  const [canAdd, setCanAdd] = useState(true);

  useEffect(() => {
    if (!currentComponent?.id && componentId) {
      dispatch.drawn.getComponent({ id: componentId });
    }
  }, [currentComponent, componentId]);

  const handleBack = () => {
    if (selectedMitigation?.id) setSelectedMitigation(false);
    else navigate(-1);
  };

  const handleCategoryClick = (type: string) => {
    dispatch.threats.clearSelectedCategory();
    setSelectedChild(null);
    setSelectedCategory(type !== selectedCategory ? type : {});
  };

  const handleAddThreat = async () => {
    if (canAdd) {
      setCanAdd(false);
      await dispatch.threats.addThreatToComponent({
        componentId,
        threatId: selectedChild.id,
      });
      setSelectedChild(null);
      setCanAdd(true);
      handleEmitAfterUpdate();
      handleEmitAfterAdd();
      await dispatch.threatModel.updateCurrentThreatModel();
    }
  };

  const handleThreatSelect = (threat: any) => {
    setSelectedChild(threat);
  };

  useEffect(() => {
    dispatch.threats.getTypeAndCategoryList();
  }, []);

  return (
    <div style={{ margin: '10px' }}>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="cursor-pointer" onClick={handleBack}>
          <ArrowLeft fontSize={16} className="me-2 text-secondary" />
          <span className="fs-12 fw-bold text-secondary">Back to {nodeTitle || ''}</span>
        </div>
      </div>

      <div
        style={{
          position: 'relative',
          height: '30px',
          marginBottom: '6px',
        }}
      >
        <UiSearch placeholder="Search Threat" onOptionSelect={handleThreatSelect} isThreat />
      </div>

      {selectedMitigation?.id ? (
        <div>
          <p className="fw-bold mb-2">{selectedMitigation.title}</p>
          {selectedMitigation.body.map((i: any) => (
            <Fragment key={i[0]}>
              <p className="fs-12">{i[0]}</p>
              <p className="fs-12 text-secondary">{i[1]}</p>
            </Fragment>
          ))}
        </div>
      ) : (
        <div className={styles.content}>
          {/* <div */}
          {/*  className={classNames( */}
          {/*    "d-flex align-items-center border px-2 rounded py-1 gap-2 mb-1", */}
          {/*    styles.threatsSearch */}
          {/*  )} */}
          {/* > */}
          {/*  <Search fontSize={16} color="#6F767E" /> */}
          {/*  <input */}
          {/*    type="text" */}
          {/*    className="form-control border-0 shadow-none p-0" */}
          {/*    placeholder="Search threats" */}
          {/*    autoFocus */}
          {/*  /> */}
          {/* </div> */}

          {typeAndCategoryList && (
            <ul className={classNames('border rounded list-unstyled', styles.threatsList)}>
              {typeAndCategoryList.map((item) => (
                <Fragment key={item.title}>
                  <li
                    key={item.id}
                    className={classNames(styles.threatsListItem, {
                      [styles.threatsListItemActive]: selectedType === item.title,
                    })}
                    onClick={() => {
                      setSelectedType(item.title !== selectedType ? item.title : {});
                      setSelectedChild(null);
                    }}
                  >
                    {item.title}
                    <ChevronRight fontSize={16} />
                  </li>
                  {selectedType === item.title
                    ? item.categories.map((category: any, index: any) => (
                        <Fragment key={index}>
                          <li
                            key={item.id}
                            className={classNames(styles.threatsListSubItem, {
                              [styles.threatsListSubItemActive]: selectedCategory === category.title,
                            })}
                            onClick={() => handleCategoryClick(category.title)}
                          >
                            {toPascalCase(category.title)}
                            <ChevronRight fontSize={16} />
                          </li>
                          {selectedCategory === category.title
                            ? category?.threats?.map((threatItem: any) => (
                                <li
                                  key={threatItem.description}
                                  className={styles.threat__item}
                                  onClick={() => setSelectedChild(threatItem)}
                                  title={threatItem.description}
                                >
                                  {threatItem.title}
                                </li>
                              ))
                            : null}
                        </Fragment>
                      ))
                    : null}
                </Fragment>
              ))}
            </ul>
          )}
          {!typeAndCategoryList && (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <Skeleton
                style={{ lineHeight: '25px' }}
                width="100%"
                borderRadius={4}
                height={30}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
              <Skeleton
                style={{ lineHeight: '25px' }}
                width="100%"
                borderRadius={4}
                height={30}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
            </div>
          )}

          {selectedChild?.id && (
            <div className={styles.threatsDetail}>
              <h6>{selectedChild.title}</h6>
              <p>{selectedChild.description}</p>
              {selectedChild?.info && (
                <div className={styles.threatsDetailLink}>
                  {selectedChild?.info?.map((link: any) => (
                    <a key={link.url} href={link.url} target="_blank" rel="noreferrer">
                      {link.title}
                    </a>
                  ))}
                </div>
              )}
              <div className="w-100 text-center mb-2">
                {isAlreadyExistThreat ? (
                  <div className="rounded border border-1 border-success text-success fs-12 p-1 w-75 m-auto">
                    This threat already exists in element
                  </div>
                ) : (
                  <UiButton onClick={handleAddThreat} disabled={!canAdd}>
                    Add threat to element
                  </UiButton>
                )}
              </div>
              {/* <div className="d-flex align-items-center flex-wrap gap-2"> */}
              {/*  <span className="fs-12 fw-bold">Mitigation</span> */}
              {/*  {selectedChild.mitigations.map((mit: any) => ( */}
              {/*    <div */}
              {/*      key={mit.id} */}
              {/*      className={styles.mitigation} */}
              {/*      onClick={() => setSelectedMitigation(mit)} */}
              {/*    > */}
              {/*      {mit.title} */}
              {/*    </div> */}
              {/*  ))} */}
              {/* </div> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ThreatsExplorer;
