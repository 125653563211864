/* eslint-disable @typescript-eslint/no-use-before-define */
import type { FC } from 'react';
import { Check } from 'react-bootstrap-icons';
import { CONTACT_SALES_URL } from '../../global/constants';
import { SUBSCRIPTION_PLANS } from './ChoosePricingModal';
import styles from './PricingMatrixTable.module.css';

const PricingMatrixTable: FC = () => {
  const rowTitles = ['Core Features', 'Administration & Security', 'Programmatic Threat Model Management'];

  return (
    <div>
      <h3 className="text-center fw-light mt-5 mb-2">Compare Devici Threat Modeling platform pricing</h3>
      <table className={styles.Table}>
        <thead>
          <tr>
            <th style={{ width: '25%' }}>Feature</th>
            {Object.entries(SUBSCRIPTION_PLANS).map(([key, plan]) => (
              <th key={key} style={{ width: '25%' }}>
                {plan.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {FULL_FEATURES.map((feature, index) => (
            <tr key={index} className={rowTitles.includes(feature.title) ? styles.titleRow : ''}>
              {Object.entries(feature).map(([key, value]) => {
                return (
                  <td key={key}>
                    {key === 'title' ? (
                      <span>{value}</span>
                    ) : value === false ? (
                      '-'
                    ) : value === true ? (
                      <Check className="text-success fs-3" />
                    ) : value === 'Contact Sales for Per User Pricing' ? (
                      <a
                        className="link-secondary text-black"
                        style={{ textDecoration: 'underline' }}
                        href={CONTACT_SALES_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {value}
                      </a>
                    ) : (
                      value
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PricingMatrixTable;

export const FULL_FEATURES = [
  {
    title: 'Core Features',
    free: '',
    business: '',
    enterprise: '',
  },
  {
    title: 'Smart Threat Models',
    free: 3,
    business: 'Always Unlimited',
    enterprise: 'Always Unlimited',
  },
  {
    title: 'Users',
    free: 10,
    business: 'Priced per user',
    enterprise: 'Unlimited',
  },
  {
    title: 'Code Genius',
    free: '5/month',
    business: 'Unlimited',
    enterprise: 'Unlimited',
  },
  {
    title: 'AI-Infused Threat Models',
    free: true,
    business: true,
    enterprise: true,
  },
  {
    title: 'Devici Codex of Threats & Mitigations',
    free: true,
    business: true,
    enterprise: true,
  },
  {
    title: 'Threat Model Health Score',
    free: true,
    business: true,
    enterprise: true,
  },
  {
    title: 'Collaboration',
    free: true,
    business: true,
    enterprise: true,
  },
  {
    title: 'Threat Register',
    free: true,
    business: true,
    enterprise: true,
  },
  {
    title: 'Threat Model History & Versioning',
    free: true,
    business: true,
    enterprise: true,
  },
  {
    title: 'Programmatic Threat Model Management',
    free: '',
    business: '',
    enterprise: '',
  },
  {
    title: 'Custom Codex',
    free: false,
    business: true,
    enterprise: true,
  },
  {
    title: 'Default Threat Model Templates',
    free: false,
    business: true,
    enterprise: true,
  },
  {
    title: 'Workflows',
    free: false,
    business: true,
    enterprise: true,
  },
  {
    title: 'Multi-Layer Threat Model Canvases',
    free: false,
    business: true,
    enterprise: true,
  },
  {
    title: 'API',
    free: false,
    business: true,
    enterprise: true,
  },
  {
    title: 'Threat Model Repository',
    free: false,
    business: true,
    enterprise: true,
  },
  {
    title: 'Export (PDF/JSON) & Import (JSON)',
    free: false,
    business: true,
    enterprise: true,
  },
  {
    title: 'Onboarding Services',
    free: false,
    business: false,
    enterprise: true,
  },
  {
    title: 'On-Premise or Hybrid Solutions',
    free: false,
    business: 'Add On',
    enterprise: 'Add On',
  },
  {
    title: 'Administration & Security',
    free: '',
    business: '',
    enterprise: '',
  },
  {
    title: 'MFA',
    free: true,
    business: true,
    enterprise: true,
  },
  {
    title: 'User Role & Management',
    free: true,
    business: true,
    enterprise: true,
  },
  {
    title: 'SSO/SAML',
    free: false,
    business: true,
    enterprise: true,
  },
] as const;
