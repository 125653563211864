import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import type { Edge } from 'reactflow';
import { useTypedSelector } from '../../../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../../../store/store';
import UiRangeInput from '../../../../common/UiRangeInput/UiRangeInput';
import styles from './EdgeConnectionPicker.module.css';

const OPTIONS = [1, 2, 3, 4, 5];

type MarkerType = 'start' | 'end' | 'both';

type EdgeConnectionPickerProps = {
  itemsVisible: boolean;
  setItemsVisible: (value: boolean) => void;
  edge: any;
};

let timer: any = null;

const edgeIcons: any = {
  start: (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="8" viewBox="0 0 20 8" fill="none">
      <path d="M6.5 5.5L6.5 4L6.5 2.5L3 4L6.5 5.5Z" fill="black" />
      <path d="M19 4L6.5 4M6.5 4L6.5 5.5L3 4L6.5 2.5L6.5 4Z" stroke="black" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),
  end: (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="8" viewBox="0 0 20 8" fill="none">
      <path d="M13.5 2.5V4V5.5L17 4L13.5 2.5Z" fill="black" />
      <path d="M1 4H13.5M13.5 4V2.5L17 4L13.5 5.5V4Z" stroke="black" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),
  both: (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="8" viewBox="0 0 22 8" fill="none">
      <path d="M15.5 4V2.5L19 4L15.5 5.5V4H6.5V5.5L3 4L6.5 2.5V4H15.5Z" fill="black" />
      <path
        d="M3 4H6.5M3 4L6.5 2.5V4M3 4L6.5 5.5V4M15.5 4V2.5L19 4L15.5 5.5V4ZM15.5 4H6.5"
        stroke="black"
        strokeWidth="2"
        style={{ transform: 'translateX(2.5px)' }}
      />
    </svg>
  ),
};

const EdgeConnectionPicker: FC<EdgeConnectionPickerProps> = ({ itemsVisible, setItemsVisible, edge }) => {
  const dispatch = useDispatch<Dispatch>();
  const { edgesSharedState, defaultEdgeStyle } = useTypedSelector((state) => state.diagram);
  const [borderWidth, setBorderWidth] = useState<number>(edge?.borderWidth || 2);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [arrowType, setArrowType] = useState<MarkerType>(edge?.markerType || 'end');
  const [edges, setEdges] = useState<Edge[] | null>();

  useEffect(() => {
    if (edgesSharedState) {
      setEdges(Array.from(edgesSharedState.values()));
    }
  }, [edgesSharedState]);

  const handleSelect = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.target.id) {
      setBorderWidth(e.target.id);
      edges?.forEach((inEdge) => {
        if (inEdge.id === edge.id) {
          inEdge.data = {
            ...inEdge.data,
            borderWidth: e.target.id,
          };
          dispatch.diagram.setDefaultEdgeStyle({
            ...defaultEdgeStyle,
            data: {
              ...defaultEdgeStyle.data,
              borderWidth: e.target.id,
            },
          });
          edgesSharedState && edgesSharedState.set(inEdge.id, inEdge);
        }
      });
    }
  };

  const handleWidthClick = (ev: any, value: number) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      ev.target.id = value;
      handleSelect(ev);
    }, 500);
  };

  const handleArrowClick = (type: MarkerType) => {
    setArrowType(type);
    edges?.forEach((inEdge) => {
      if (inEdge.id === edge.id) {
        inEdge.data = {
          ...inEdge.data,
          markerType: type,
        };
        dispatch.diagram.setDefaultEdgeStyle({
          ...defaultEdgeStyle,
          data: {
            ...defaultEdgeStyle.data,
            markerType: type,
          },
        });
        edgesSharedState && edgesSharedState.set(inEdge.id, inEdge);
      }
    });
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className={classNames(
        {
          [styles.activeDropDown]: itemsVisible,
        },
        styles.dropdown,
        'd-flex align-items-center justify-content-between rounded p-1 cursor-pointer position-relative',
      )}
    >
      {showTooltip && !itemsVisible && <div className="main-tooltip">Connection Picker</div>}
      <div
        onClick={() => setItemsVisible(!itemsVisible)}
        className={classNames(styles.input, 'd-flex position-relative align-items-center')}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {edge.markerType ? edgeIcons[edge.markerType] : edgeIcons.end}
      </div>
      {itemsVisible && (
        <div className={classNames(styles.dropdownItems, 'rounded')}>
          <div className={styles.arrows}>
            <svg
              onClick={() => handleArrowClick('end')}
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="13"
              viewBox="0 0 40 13"
              fill="none"
            >
              <path
                d="M40 6.5L30 0.726497V12.2735L40 6.5ZM0 7.5H31V5.5H0V7.5Z"
                fill={arrowType === 'end' ? '#0062D8' : 'black'}
              />
            </svg>

            <svg
              onClick={() => handleArrowClick('start')}
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="13"
              viewBox="0 0 40 13"
              fill="none"
            >
              <path
                d="M0 6.5L10 12.2735V0.726497L0 6.5ZM40 5.5L9 5.5V7.5L40 7.5V5.5Z"
                fill={arrowType === 'start' ? '#0062D8' : 'black'}
              />
            </svg>

            <svg
              onClick={() => handleArrowClick('both')}
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="13"
              viewBox="0 0 50 13"
              fill="none"
            >
              <path
                d="M0 6.5L10 12.2735V0.726497L0 6.5ZM50 6.5L40 0.726497V12.2735L50 6.5ZM9 7.5H41V5.5H9V7.5Z"
                fill={arrowType === 'both' ? '#0062D8' : 'black'}
              />
            </svg>
          </div>
          <UiRangeInput
            options={OPTIONS}
            value={borderWidth}
            onOptionClick={handleWidthClick}
            label="Thickness"
            valueSign=""
            isThickness
          />
        </div>
      )}
    </div>
  );
};

export default EdgeConnectionPicker;
