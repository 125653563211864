import type { FC, SyntheticEvent } from 'react';

import { FileEarmarkArrowDown } from 'react-bootstrap-icons';
import { Fade, Input, Label } from 'reactstrap';
import styles from './ManageSamlForm.module.css';

type EncryptedResponseFieldProps = {
  certificate: File | null;
  encryptedResponses: boolean;
  setFieldValue: (field: string, value: boolean) => void;
};

export const EncryptedResponseField: FC<EncryptedResponseFieldProps> = ({
  certificate,
  setFieldValue,
  encryptedResponses,
}) => {
  const handleDownloadCertificate = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (certificate) {
      const url = window.URL.createObjectURL(certificate);
      const link = document.createElement('a');
      link.setAttribute('download', certificate.name);
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  return (
    <>
      <div className="mt-3 d-flex gap-3 align-items-center justify-content-between">
        <div className="py-2">
          <Input
            type="checkbox"
            className="me-2"
            id="encryptedResponses"
            checked={encryptedResponses}
            title="Require encrypted SAML assertions"
            onChange={(e) => {
              setFieldValue('encryptedResponses', e.target.checked);
            }}
          />
          <Label className="mb-0 user-select-none cursor-pointer fs-14" htmlFor="encryptedResponses">
            Require encrypted SAML assertions
          </Label>
        </div>

        {certificate && encryptedResponses && (
          <div>
            <div className={styles.copyButton} onClick={handleDownloadCertificate}>
              <span className="fw-bold">Certificate</span>
              <FileEarmarkArrowDown size={20} />
            </div>
          </div>
        )}
      </div>
      {encryptedResponses && !certificate && (
        <Fade className="p-1 fs-14">
          <div className="rounded p-2 bg-light">The certificate will be available after submit</div>
        </Fade>
      )}
    </>
  );
};
