import { CustomCodexDrawer } from '../../../components/AdminPage/CustomCodexTable/CustomCodexDrawer';
import { CustomCodexMitigationsTable } from '../../../components/AdminPage/CustomCodexTable/CustomCodexMitigationsTable';

const CustomCodexMitigationsPage = () => {
  return (
    <div style={{ padding: 10 }} className="d-flex w-100">
      <CustomCodexMitigationsTable />
      <CustomCodexDrawer type="mitigations" />
    </div>
  );
};

export default CustomCodexMitigationsPage;
