import classNames from 'classnames';
import type { FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';

import type { User } from '../../../global/types';
import { capitalizeFirstLetter } from '../../../helpers/user';
import type { Dispatch } from '../../../store/store';
import styles from './UserRoleDropdown.module.css';

type UserRoleDropdownProps = {
  user: User;
  setVisible: (val: string) => void;
  setLoading: (val: string) => void;
};

export const UserRoleDropdown: FC<UserRoleDropdownProps> = ({ user, setVisible, setLoading }) => {
  const dispatch = useDispatch<Dispatch>();

  const handleStatusChange = async (newRole: string) => {
    setLoading(user.id);
    setVisible('');
    await dispatch.user.changeRole({
      id: user.id,
      role: newRole,
    });
    setLoading('');
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible('')}>
      <ul className={classNames(styles.userRole, 'list-group shadow')}>
        {Object.values(['user', 'admin'])
          .filter((s) => s !== user.role)
          .map((s) => (
            <li
              className="list-group-item"
              key={s}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleStatusChange(s);
              }}
            >
              {capitalizeFirstLetter(s)}
            </li>
          ))}
      </ul>
    </OutsideClickHandler>
  );
};
