import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import { SKELETON_COLORS } from '../../../global/constants';
import { UpdateTypes } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import UiButton from '../../common/UIButton/UiButton';
import UiInput from '../../common/UiInput/UiInput';
import styles from './index.module.css';

const AttributeEditForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState(false);
  const nodeTitle = useTypedSelector(({ drawn }) => drawn?.component?.title) || '';
  const { currentAttribute, emitUpdate, wsRemoveAttributeId } = useTypedSelector((state) => state.drawn);
  const [errorText, setErrorText] = useState('');

  const handleBack = () => {
    const { id, diagramId, componentId } = params;

    if (id && diagramId && componentId) navigate(`/collections/${id}/d/${diagramId}/c/${componentId}`);
  };

  useEffect(() => {
    if (wsRemoveAttributeId) {
      handleBack();
      toast.error('Attribute deleted!');
      dispatch.drawn.setWsRemoveAttributeId(null);
    }
  }, [wsRemoveAttributeId]);

  useEffect(() => {
    if (params?.attributeId?.length) dispatch.diagram.getAttributeById(params.attributeId);

    return () => {
      dispatch.drawn.clearCurrentAttribute();
    };
  }, [params.attributeId]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: string) => {
    const { value } = event.target;
    dispatch.drawn.setCurrentAttribute({
      ...currentAttribute,
      [field]: value,
    });
    setErrorText('');
  };

  const handleUpdateAttribute = async () => {
    setLoading(true);
    const result = await dispatch.diagram.updateAttributeById({});
    setLoading(false);

    if (typeof result === 'string') {
      setErrorText(result);
    } else {
      if (params.componentId && emitUpdate) {
        emitUpdate(UpdateTypes.COMPONENT, params.componentId, true);
      }

      handleBack();
    }
  };

  return (
    <div style={{ margin: '10px' }}>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="cursor-pointer" onClick={handleBack}>
          <ArrowLeft fontSize={16} className="me-2 text-secondary" />
          <span className="fs-12 fw-bold text-secondary">Back to {nodeTitle || ''}</span>
        </div>
      </div>
      <div className="mb-3">
        {currentAttribute?.is_custom ? (
          <>
            {(currentAttribute?.title || currentAttribute?.title === '') && (
              <UiInput
                name="title"
                label="Attribute name*"
                value={currentAttribute?.title}
                onChange={(e: any) => handleChange(e, 'title')}
                onFocus={() => dispatch.diagram.setCanCopy(false)}
                onBlur={() => dispatch.diagram.setCanCopy(true)}
                disabled={!currentAttribute?.is_custom}
                showError={(!currentAttribute?.title.length && currentAttribute.id) || !!errorText?.length}
                errorText={errorText}
              />
            )}
            {!currentAttribute?.title && currentAttribute?.title !== '' && (
              <Skeleton
                style={{ lineHeight: '25px' }}
                width="100%"
                borderRadius={4}
                height={30}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
            )}
          </>
        ) : (
          <>
            <label className={styles.attributeInputLabel}>Attribute name</label>
            {currentAttribute?.title && <div className="fs-12">{currentAttribute?.title}</div>}
            {!currentAttribute?.title && (
              <Skeleton
                style={{ lineHeight: '25px' }}
                width="100%"
                borderRadius={4}
                height={30}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
            )}
          </>
        )}
      </div>
      <div className="mb-2">
        {currentAttribute?.is_custom ? (
          <UiInput
            name="description"
            label="Description"
            value={currentAttribute?.description || ''}
            onChange={(e: any) => handleChange(e, 'description')}
            onFocus={() => dispatch.diagram.setCanCopy(false)}
            onBlur={() => dispatch.diagram.setCanCopy(true)}
            type="textarea"
            disabled={!currentAttribute?.is_custom}
          />
        ) : (
          <>
            <label className={styles.attributeInputLabel}>Description</label>
            {currentAttribute?.description && <div className="fs-12">{currentAttribute?.description}</div>}
            {!currentAttribute?.description && (
              <Skeleton
                style={{ lineHeight: '25px' }}
                width="100%"
                borderRadius={4}
                height={48}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
            )}
          </>
        )}
      </div>
      {!currentAttribute?.is_custom && (
        <div className="mb-3">
          <label className={styles.attributeInputLabel}>Type</label>
          {currentAttribute?.type && <div className="fs-12">{currentAttribute?.type}</div>}
          {!currentAttribute?.type && (
            <Skeleton
              style={{ lineHeight: '25px' }}
              width="100%"
              borderRadius={4}
              height={30}
              baseColor={SKELETON_COLORS.BASE}
              highlightColor={SKELETON_COLORS.HIGHLIGHT}
            />
          )}
        </div>
      )}
      {currentAttribute?.is_custom && (
        <div className="mb-2 d-flex align-items-center justify-content-end">
          <UiButton type="transparent" onClick={handleBack}>
            Cancel
          </UiButton>
          <UiButton disabled={!currentAttribute.title?.length} onClick={handleUpdateAttribute}>
            {loading ? <Spinner color="light" size="sm" /> : 'Save'}
          </UiButton>
        </div>
      )}
    </div>
  );
};

export default AttributeEditForm;
