import { debounce } from '@zxcvbn-ts/core';
import { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import Switch from '../../common/UISwitch';
import styles from './ManageTwoFactorAuth.module.css';

export const ManageTwoFactorAuth = () => {
  const dispatch = useDispatch<Dispatch>();
  const isMFARequired = useTypedSelector((state) => state.customer.isMFARequired);
  const isLoading = useTypedSelector((state) => state.customer.isLoading === 'get-mfa');

  const [checked, setChecked] = useState(isMFARequired);

  const updateMFA = useRef(
    debounce((isMFARequired: boolean) => {
      dispatch.customer.updateMFA(isMFARequired);
    }, 500),
  );

  const handleChange = () => {
    setChecked((prev) => {
      updateMFA.current(!prev);

      return !prev;
    });
  };

  useEffect(() => {
    setChecked(isMFARequired);
  }, [isMFARequired]);

  useEffect(() => {
    dispatch.customer.getMFA();
  }, [dispatch.customer]);

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className={styles.title}>Two-factor authentication</div>

      <div className="d-flex gap-2 align-items-center">
        <div>Required</div>
        {isLoading ? <Skeleton width={28} height={16} /> : <Switch checked={checked} onChange={handleChange} />}
      </div>
    </div>
  );
};
