import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Card, CardBody } from 'reactstrap';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';

const TeamDetailPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const { current } = useTypedSelector((state) => state.team);

  const params = useParams();
  const { id } = params;

  useEffect(() => {
    dispatch.team.getTeamById(id);
  }, []);

  return (
    <div className="container d-flex justify-content-center">
      <Card className="w-50 mt-2">
        <CardBody>
          <div className="d-flex">
            <strong>Team title: </strong>
            <p className="mx-2">{current.title}</p>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default TeamDetailPage;
