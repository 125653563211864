import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { SING_UP_PAGE_PAYMENT_URL_PARAMS } from '../../global/constants';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import {
  ADMIN_ROUTE,
  CLI_AUTH_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
} from '../../store/constants/route-constants';
import type { Dispatch } from '../../store/store';
import ChargebeeCheckout from './ChargebeeCheckout';
import Sidebar from './Sidebar';

const EMPTY_PAGES = [SIGN_UP_ROUTE, SIGN_IN_ROUTE, FORGOT_PASSWORD_ROUTE, CLI_AUTH_ROUTE];

const SidebarContainer: FC<PropsWithChildren> = () => {
  const location = useLocation();
  const dispatch = useDispatch<Dispatch>();
  const isAuthenticated = useTypedSelector((state) => state.auth?.isAuthenticated);
  const { current } = useTypedSelector((state) => state.user);
  const data = JSON.parse(localStorage.getItem(SING_UP_PAGE_PAYMENT_URL_PARAMS) || 'false');
  const { companyName } = useTypedSelector((state) => state.customer);

  useEffect(() => {
    if (isAuthenticated && !current?.id) {
      dispatch.user.getCurrentUser();
    }

    if (isAuthenticated && !companyName) {
      dispatch.customer.getCustomer({ needAvatar: location.pathname.includes(ADMIN_ROUTE) });
    }
  }, [location]);

  if (EMPTY_PAGES.includes(location.pathname)) {
    return null;
  }

  if (!isAuthenticated) {
    return null;
  }

  if (!current) {
    return null;
  }

  if (data && current?.email && companyName) {
    return <ChargebeeCheckout />;
  }

  return <Sidebar />;
};

export default SidebarContainer;
