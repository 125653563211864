import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { ImageAlt } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import { isValidFileUploaded } from '../../../helpers/user';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import UiModal from '../../common/Modal/UiModal';
import UiButton from '../../common/UIButton/UiButton';
import styles from './UploadLogoForm.module.css';

export const UploadLogoForm = () => {
  const logo = useTypedSelector((state) => state.customer.logo);
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState<string>('');
  const [currentLogo, setCurrentLogo] = useState<any>(null);
  const [modal, setModal] = useState(false);
  const avatarUploadRef = useRef<any>(null);

  useEffect(() => {
    if (!logo?.length) {
      dispatch.customer.getCustomer({ needAvatar: true });
    }
  }, []);

  useEffect(() => {
    setCurrentLogo(logo);
  }, [logo]);

  const onUpload = async (image: File) => {
    setLoading('upload');
    await dispatch.customer.uploadCompanyLogo(image);
    setLoading('');
  };

  const handleChangeLogo = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const result = isValidFileUploaded(file);

      if (result.valid) {
        const img = URL.createObjectURL(file);
        setCurrentLogo(img);
        await onUpload(file);
      } else {
        toast.warning(result.text);
      }
    }
  };

  const onRemove = async () => {
    setLoading('remove');
    await dispatch.customer.removeCompanyLogo();
    setModal(false);
    setLoading('');
  };

  return (
    <div className={classNames('d-flex align-items-center gap-2', styles.uploadFormWrap)}>
      <div
        className={classNames(styles.uploadForm, {
          [styles.border]: !currentLogo?.length,
        })}
        style={{
          backgroundImage: currentLogo ? `url(${currentLogo})` : '',
        }}
      >
        <input
          ref={avatarUploadRef}
          hidden
          type="file"
          onChange={handleChangeLogo}
          accept=".png, .jpeg, .jpg, .webp"
          alt="logo"
        />
        {!currentLogo && (
          <div className="text-center" onClick={() => avatarUploadRef?.current?.click()}>
            <ImageAlt size={30} color="#2360C5" />
          </div>
        )}
      </div>

      <UiButton style={{ width: 170 }} className="ms-2 me-1" onClick={() => avatarUploadRef?.current?.click()}>
        {loading === 'upload' ? <Spinner size="sm" /> : currentLogo ? 'Upload new logo' : 'Upload logo'}
      </UiButton>

      {currentLogo && (
        <UiButton style={{ width: 140 }} className="ms-2" onClick={() => setModal(true)} type="transparent">
          Remove logo
        </UiButton>
      )}
      <div className={styles.text}>Upload a photo size 400px x 400px. jpg, png, svg format.</div>
      {modal && (
        <UiModal title="Remove Logo" onClose={() => setModal(false)}>
          <div className={styles.modalText}>Are you sure you want to remove the logo?</div>
          <div className="d-flex justify-content-end">
            <UiButton style={{ width: 100 }} className="ms-2" onClick={() => setModal(false)} type="transparent">
              No
            </UiButton>
            <UiButton style={{ width: 100 }} className="ms-2" onClick={onRemove}>
              {loading === 'remove' ? <Spinner size="sm" /> : 'Yes'}
            </UiButton>
          </div>
        </UiModal>
      )}
    </div>
  );
};
