import moment from 'moment';
import type { FC } from 'react';
import type { Comment } from '../../../global/types';
import UserAvatar from '../../common/UserAvatar/UserAvatar';
import styles from './CommentItem.module.css';

type CommentItemProps = {
  comment: Comment;
};

const CommentItem: FC<CommentItemProps> = ({ comment }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.user}>
          <UserAvatar user={comment?.user ? comment.user : { first_name: 'Deleted', last_name: 'Account' }} />
          <div className={styles.userName}>
            {comment?.user && (
              <>
                {comment?.user?.first_name} {comment?.user?.last_name}
              </>
            )}
            {!comment?.user && <>Deleted Account</>}
          </div>
        </div>
        <div className={styles.date}>{moment(comment.created_at).fromNow(true)}</div>
      </div>
      <div className={styles.body}>{comment.text}</div>
    </div>
  );
};

export default CommentItem;
