import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Skeleton from 'react-loading-skeleton';
import { Col, Spinner } from 'reactstrap';
import { SKELETON_COLORS } from '../../global/constants';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';
import UiButton from '../common/UIButton/UiButton';
import UiInput from '../common/UiInput/UiInput';
import styles from '../Project/ProjectCreateOrUpdate/ProjectCreateOrUpdate.module.css';

export const ProfileEmailForm = () => {
  const dispatch = useDispatch<Dispatch>();
  const { current: user } = useTypedSelector((state) => state.user);

  const [loading, setLoading] = useState('');
  const [showEmailVerifyForm, setShowEmailVerifyForm] = useState(false);

  const emailFormik = useFormik({
    enableReinitialize: true,
    initialValues: { email: user?.email || '' },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email format').required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading('email');
      const isOk = await dispatch.user.changeEmail(values.email);

      if (isOk) setShowEmailVerifyForm(true);

      setLoading('');
    },
  });

  const verifyEmailFormik = useFormik({
    enableReinitialize: true,
    initialValues: { code: '', email: user?.email || '' },
    validationSchema: Yup.object({
      code: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email format').required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading('code');
      const isOk = await dispatch.user.verifyEmail({
        code: values.code,
        email: emailFormik.values.email,
      });

      if (isOk) setShowEmailVerifyForm(false);

      setLoading('');
    },
  });

  return (
    <>
      <div className={styles.profileTitle} />
      <Col sm={showEmailVerifyForm ? 6 : 12} className="fs-12">
        {user?.id && (
          <UiInput
            name="email"
            label="Email"
            type="email"
            placeholder="Email"
            value={emailFormik.values.email}
            onChange={emailFormik.handleChange}
            onBlur={emailFormik.handleBlur}
            errorText={emailFormik.errors.email}
            showError={emailFormik.touched.email && emailFormik.errors.email}
            disabled={showEmailVerifyForm}
          />
        )}
        {!user?.id && (
          <div>
            <Skeleton
              style={{ lineHeight: '11px' }}
              width={33}
              height={17}
              baseColor={SKELETON_COLORS.BASE}
              highlightColor={SKELETON_COLORS.HIGHLIGHT}
            />
            <Skeleton
              style={{ lineHeight: '11px' }}
              width={476}
              height={27}
              baseColor={SKELETON_COLORS.BASE}
              highlightColor={SKELETON_COLORS.HIGHLIGHT}
            />
          </div>
        )}
      </Col>
      {showEmailVerifyForm && (
        <Col sm={6} className="fs-12">
          <UiInput
            name="code"
            label="Verification code"
            type="text"
            placeholder="Verification code"
            value={verifyEmailFormik.values.code}
            onChange={verifyEmailFormik.handleChange}
            onBlur={verifyEmailFormik.handleBlur}
            errorText={verifyEmailFormik.errors.code}
            showError={verifyEmailFormik.touched.code && verifyEmailFormik.errors.code}
          />
        </Col>
      )}
      <Col className="d-flex justify-content-end mt-1">
        <UiButton
          className={styles.updateButton}
          onClick={() => {
            if (showEmailVerifyForm) verifyEmailFormik.submitForm();
            else emailFormik.submitForm();
          }}
          disabled={emailFormik.values.email === user?.email && !showEmailVerifyForm}
        >
          {['email', 'code'].includes(loading) ? <Spinner color="light" size="sm" /> : 'Save'}
        </UiButton>
      </Col>
    </>
  );
};
