// auth
export const SIGN_UP_URL = 'auth/sign-up';

export const CHECK_EMAIL = 'auth/check-email';

export const CONFIRMATION_URL = 'auth/email-confirmation';

export const RESEND_CONFIRMATION_URL = 'auth/resend-confirmation-code';

export const SIGN_IN_URL = 'auth/sign-in';

export const CHECK_AUTH_FLOW = 'auth/check-flow';

export const REDEEM_INVITATION_CODE = 'auth/invitation-code';

export const FORGOT_PASSWORD = 'auth/forgot-password';

export const CONFIRM_FORGOT_PASSWORD = 'auth/confirm-forgot-password';

export const SET_PASSWORD = 'auth/set-password';

export const SAML_URL = 'auth/saml';

export const EXCHANGE_CODE_URL = 'auth/code-exchange';

export const SIGN_OUT_URL = 'auth/sign-out';

export const FULL_SIGN_OUT_URL = 'auth/fullSignOut';

export const REFRESH_TOKEN_URL = 'auth/refresh';

export const VERIFY_MFA_TOKEN = 'auth/verify-mfa-token';

export const CHECK_MFA_CODE = 'auth/check-mfa-code';

export const RECONNECT_MFA_CODE = 'auth/mfa-reconnect';

export const VERIFY_RECONNECT_MFA_CODE = 'auth/verify-mfa-reconnect';

// projects
export const GET_PROJECTS = 'projects';

export const CREATE_PROJECTS = GET_PROJECTS;

export const ADD_SUB_PROJECT = '/projects/sub-project';

export const LIFT_PROJECT = '/projects/lift';

export const SEARCH_PROJECT = '/projects/search';

// threat_model
export const THREAT_MODEL_URL = 'threat-models';

// create default threat_model
export const DEFAULT_THREAT_MODEL_URL = `${THREAT_MODEL_URL}/create-default`;

// representation
export const REPRESENTATION_URL = 'representations';

// sticker
export const STICKER_URL = 'stickers';

// users
export const USER_URL = 'users';

export const USER_INVITE_URL = 'users/invite';

export const USER_RE_INVITE_URL = 'users/re-invite';

export const USER_ONBOARDING = 'onboarding';

// teams
export const TEAM_URL = 'teams';

// customers
export const CUSTOMER_URL = 'customers';

// attributes
export const ATTRIBUTES = 'attributes';

// components
export const COMPONENTS = 'components';

// runnings
export const RUNNINGS = 'runnings';

export const COMPONENTS_MAKE_COPY = 'components/deep-copy';

export const COMPONENTS_MAKE_COPY_MANY = 'components/deep-copy-many';

export const DELETE_THREAT_FROM_COMPONENTS = 'components/delete-threat';

export const THREATS = 'threats';

export const CLI_TOKENS = 'cli-tokens';

export const MITIGATIONS = 'mitigations';

export const COMMENTS = 'comments';

export const CLI_THREAT_MODELS = 'cli-threat-models';

export const TIERS_CUSTOMER_LIMITS = 'tiers/customer-limits';

export const TIERS_PORTAL_LINK = 'tiers/portal-link';

export const CUSTOM_CODEX = 'codex';

export const HUBSPOT = 'hubspot';
