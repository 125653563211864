import type { CustomerLimits } from '../store/models/tiers';

export const cleanUserTokensFromLocalStorage = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('sessionToken');
};

export const setUserTokensToLocalStorage = (accessToken: string, refreshToken: string, sessionToken: string) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('sessionToken', sessionToken);
};

export const removeIsLongTermSessionFromLocalStorage = () => {
  localStorage.removeItem('isLongTermSession');
};

export const setCodeChallengeToLocalStorage = (codeChallenge: string) => {
  localStorage.setItem('codeChallenge', codeChallenge);
};

export const removeCodeChallengeFromLocalStorage = () => {
  localStorage.removeItem('codeChallenge');
};

export const getIsAuthenticated = () => !!localStorage.getItem('accessToken');

export const getAccessToken = () => localStorage.getItem('accessToken');

export const getRefreshToken = () => localStorage.getItem('refreshToken');

export const getSessionToken = () => localStorage.getItem('sessionToken');

export const getCodeChallenge = () => localStorage.getItem('codeChallenge');

export const getIsLongTermSession = () => Boolean(JSON.parse(localStorage.getItem('isLongTermSession') || 'false'));

export const parseJwt = (token: string) => {
  try {
    if (!token?.length) return null;

    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const getId = () => `${window.crypto.randomUUID()}`;

export const copyQrCodeToNavigator = async (text: string) => {
  try {
    if (!text?.length) return false;

    await navigator.clipboard.writeText(text);

    return true;
  } catch (e) {
    return false;
  }
};

export const isValidFile = (file: File) => {
  const validExtensions = ['png', 'jpeg', 'jpg', 'webp'];
  const fileExtension = file.type.split('/')[1];

  return validExtensions.includes(fileExtension);
};

export const isValidFileUploaded = (file: File) => {
  const result = {
    valid: true,
    text: '',
  };
  const MIN_FILE_SIZE = 1;
  const MAX_FILE_SIZE = 2048;
  const fileSizeKiloBytes = file.size / 1024;

  if (!isValidFile(file)) {
    result.text = 'Incorrect file type uploaded';
    result.valid = false;

    return result;
  }

  if (fileSizeKiloBytes < MIN_FILE_SIZE) {
    result.text = 'File size is less than minimum limit (1kb)';
    result.valid = false;

    return result;
  }

  if (fileSizeKiloBytes > MAX_FILE_SIZE) {
    result.text = 'File size is greater than maximum limit (2mb)';
    result.valid = false;

    return result;
  }

  return result;
};

export const capitalizeFirstLetter = (value: string) => {
  return value?.charAt(0).toUpperCase() + value?.slice(1);
};

export const getUserAccountType = (customerLimits: CustomerLimits) => {
  if (customerLimits?.isBetaUser) {
    return 'Beta';
  }

  if (customerLimits?.tier === 'business') {
    return `Business ${customerLimits?.billingPeriod}`;
  }

  return capitalizeFirstLetter(customerLimits?.tier || '');
};
