import type { FC, PropsWithChildren } from 'react';
import { Grid, ListUl, ThreeDotsVertical } from 'react-bootstrap-icons';
import styles from './UiIconButton.module.css';

type UIIconButtonProps = PropsWithChildren & {
  disabled?: boolean;
  type?: 'list' | 'grid' | 'dots';
  active?: boolean;
  onClick?: () => void;
};

const UiIconButton: FC<UIIconButtonProps> = ({
  children,
  disabled = false,
  type = 'list',
  active = false,
  onClick,
}) => {
  return (
    <button disabled={disabled} onClick={onClick} className={`${styles.btn} ${active ? styles.active : ''}`}>
      {type === 'list' && <ListUl className={`${styles.icon} ${active ? styles.iconActive : ''}`} size={24} />}
      {type === 'grid' && <Grid className={`${styles.icon} ${active ? styles.iconActive : ''}`} size={24} />}
      {type === 'dots' && (
        <ThreeDotsVertical className={`${styles.icon} ${active ? styles.iconActive : ''}`} size={24} />
      )}
    </button>
  );
};

export default UiIconButton;
