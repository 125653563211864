import { createModel } from '@rematch/core';
import http from '../http/http-common';
import type { RootModel } from './index';

import { EDGE_STYLES } from '../../helpers/colors';
import { ATTRIBUTES } from '../constants/api-constants';

export enum AttributeTypes {
  DATA = 'data',
  FUNCTIONAL = 'functional',
}

type AttributesState = {
  [AttributeTypes.DATA]: any;
  [AttributeTypes.FUNCTIONAL]: any;
  count: number;
  limit: number;
  page: number;
};

interface IReqForAttributesExplorer {
  type: AttributeTypes;
  selectedEntityType: string;
  page: number;
  limit: number;
}

export const attributes = createModel<RootModel>()({
  state: {
    [AttributeTypes.DATA]: {
      data: null,
    },
    [AttributeTypes.FUNCTIONAL]: {
      data: null,
    },
    count: 0,
    limit: 10,
    page: 0,
  } as unknown as AttributesState,
  reducers: {
    setAttributes(state, { type, attributes, count, page, limit }) {
      if (!attributes) {
        attributes = [];
      }

      return {
        ...state,
        [type]: {
          data: page === 0 ? attributes : [...(state?.[type as AttributeTypes]?.data || []), ...attributes],
        },
        count,
        page,
        limit,
      };
    },
    setLimit(state, limit) {
      return {
        ...state,
        limit,
      };
    },
    clearAttributes(state) {
      return {
        ...state,
        [AttributeTypes.FUNCTIONAL]: {
          data: null,
          count: 0,
        },
        [AttributeTypes.DATA]: {
          data: null,
          count: 0,
        },
      };
    },
  },
  effects: (dispatch) => {
    return {
      async getAttributes(req: IReqForAttributesExplorer) {
        const nextPage = req.limit * req.page;

        if (req.selectedEntityType === EDGE_STYLES.SMOOTHSTEP || req.selectedEntityType === EDGE_STYLES.EDITABLE) {
          req.selectedEntityType = EDGE_STYLES.BEZIER;
        }

        const response = await http(
          `${ATTRIBUTES}/explorer?type=${req.type}&entity_type=${req.selectedEntityType}&limit=${req.limit}&page=${nextPage}`,
        );
        await dispatch.attributes.setAttributes({
          type: req.type,
          attributes: response?.data?.items || [],
          count: response?.data?.count,
          page: req.page,
          limit: req.limit,
        });

        if (response?.data?.items?.length < response?.data?.count) {
          await dispatch.attributes.setLimit(req.limit);
        }

        return {
          itemsLength: response?.data?.items?.length,
          count: response?.data?.count,
        };
      },
      async getComponentAttributes(componentId) {
        try {
          const response = await http(`${ATTRIBUTES}/for-component/${componentId}`);

          if (response.status === 200) {
            return response?.data || [];
          }
        } catch (error) {
          console.error(error);
        }
      },
    };
  },
});
