import classNames from 'classnames';
import { CursorFill } from 'react-bootstrap-icons';
import { useViewport } from 'reactflow';
import { getColorDependsOnBackground } from '../../../helpers/colors';
import { getLinearInterpolation } from '../../../helpers/diagram';
import type { ICollaboratorSharedState } from '../../../pages/DiagramsPage';
import styles from './CursorUser.module.css';

const CursorUser = ({ color, first_name, last_name, mouse_coordinates }: ICollaboratorSharedState) => {
  const { x, y, zoom } = useViewport();

  return (
    <div
      style={{
        position: 'absolute',
        top: y - mouse_coordinates.y * zoom - getLinearInterpolation(zoom, 1, 15),
        left: x - mouse_coordinates.x * zoom - getLinearInterpolation(zoom, 1, 40),
        transition: 'all .3s',
      }}
      className="noScale"
      id="user-cursor"
    >
      <div className={classNames(styles.main, 'noScale')}>
        <CursorFill style={{ transform: 'rotate(270deg)' }} size={16} color={color} />
        <div
          style={{
            backgroundColor: color,
            color: getColorDependsOnBackground(color),
          }}
          className={styles.nameWrapper}
        >
          <div className={classNames(styles.name, 'noScale')}>{`${first_name} ${last_name}`}</div>
        </div>
      </div>
    </div>
  );
};

export default CursorUser;
