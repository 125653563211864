import type { CSSProperties } from 'react';
import { useEffect, useRef } from 'react';
import { SPACE_FROM_BORDER } from './constants';
import styles from './UISwitch.module.css';

type Props = {
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  dotStyle?: CSSProperties;
  containerStyle?: CSSProperties;
};

const Switch = ({ checked = false, onChange = () => {}, dotStyle, containerStyle }: Props): JSX.Element => {
  const switchRef = useRef<HTMLDivElement>(null);
  const dotRef = useRef<HTMLDivElement>(null);

  const toggleSwitch = () => {
    onChange(!checked);
  };

  useEffect(() => {
    if (switchRef.current && dotRef.current) {
      const switchWidth = switchRef.current.offsetWidth;
      const dotWidth = dotRef.current.offsetWidth;
      const offsetLeft = checked ? switchWidth - dotWidth - SPACE_FROM_BORDER : SPACE_FROM_BORDER;

      dotRef.current.style.left = `${offsetLeft}px`;
    }
  }, [checked]);

  return (
    <div
      style={containerStyle}
      ref={switchRef}
      className={`${styles.switch} ${checked ? styles.switchOn : styles.switchOff}`}
      onClick={toggleSwitch}
    >
      <div style={dotStyle} ref={dotRef} className={styles.dot} />
    </div>
  );
};

export default Switch;
