import { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap';

type OptionsT = { id: string; title: string };

type AutocompleteDropdownProps = {
  options: OptionsT[];
  onItemClick?: (id: string) => void;
  onInputChange?: (title: string) => void;
  isLoading?: boolean;
  placeholder?: string;
};

const AutocompleteDropdown = ({
  options,
  onItemClick = () => {},
  onInputChange = () => {},
  isLoading,
  placeholder,
}: AutocompleteDropdownProps): JSX.Element => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
    setDropdownOpen(true);
    onInputChange(value);
  };

  const handleItemClick = (item: { id: string; title: string }) => {
    setSearchValue(item.title);
    onItemClick(item.id);
    onInputChange(item.title);
    setDropdownOpen(false);
  };

  const filterOptions = (options: OptionsT[]) => {
    return options.filter(({ title }) => title.toLowerCase().includes(searchValue.toLowerCase()));
  };

  return (
    <div>
      <Input
        type="text"
        value={searchValue}
        onChange={handleInputChange}
        onFocus={() => setDropdownOpen(true)}
        placeholder={placeholder}
      />
      <Dropdown toggle={() => {}} isOpen={dropdownOpen} direction="down">
        <DropdownToggle tag="div" data-toggle="dropdown" aria-expanded={dropdownOpen} />
        <DropdownMenu style={{ width: '100%' }}>
          {options?.length && !isLoading ? (
            filterOptions(options)?.map((item, index) => (
              <DropdownItem
                key={index}
                onClick={() => {
                  handleItemClick(item);
                  setDropdownOpen(false);
                }}
              >
                {item.title}
              </DropdownItem>
            ))
          ) : (
            <DropdownItem disabled>{isLoading ? 'Loading...' : 'No items found'}</DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default AutocompleteDropdown;
