import type { FC, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { Spinner } from 'reactstrap';

import AiSearchIcon from '../../../assets/icons/AiSearchIcon.svg';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from './AISearchInput.module.css';

type AISearchInputProps = {
  search: string;
  setSearch: (val: string) => void;
  forDrawer: boolean;
};

export const AISearchInput: FC<AISearchInputProps> = ({ search, setSearch, forDrawer }) => {
  const dispatch = useDispatch<Dispatch>();
  const { isSearchingAttributes } = useTypedSelector((state) => state.diagram);

  const handleSearchAi = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (search.length) {
      dispatch.diagram.getAttributesFromLeo({
        text: search,
        forDrawer,
      });
    }
  };

  return (
    <div
      className="d-flex align-items-center nopan position-relative mb-1"
      onKeyDown={(e) => {
        if (e.key === 'Enter') handleSearchAi(e);
      }}
    >
      {isSearchingAttributes ? (
        <div className={styles.aiSearchIcon} style={{ paddingRight: '4px' }}>
          <Spinner color="dark" size="sm" />
        </div>
      ) : (
        <img src={AiSearchIcon} alt="AiSearchIcon" className={styles.aiSearchIcon} onClick={handleSearchAi} />
      )}
      <ReactTextareaAutosize
        className={styles.searchTextarea}
        placeholder="Enter text"
        onFocus={() => dispatch.diagram.setCanCopy(false)}
        onBlur={() => dispatch.diagram.setCanCopy(true)}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        value={search}
        data-id="custom-diagram-input"
      />
    </div>
  );
};
