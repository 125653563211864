import { Col, Form, FormGroup, Input, Label } from 'reactstrap';

const RetrospectiveDetail = () => {
  return (
    <div className="container-fluid vh-50 d-flex justify-content-center mt-2 py-3 px-2">
      <Form className="w-75 d-flex justify-content-center row gap-0">
        <FormGroup row>
          <Label for="retrospective-time-invested-input" sm={3}>
            Time invested
          </Label>
          <Col sm={6}>
            <Input
              type="range"
              name="timeInvested"
              id="retrospective-time-invested-input"
              placeholder="email address"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="profile-form-default-representation" sm={3}>
            Quality of Model
          </Label>
          <Col sm={6}>
            <Input
              type="select"
              name="defaultRepresentation"
              id="profile-form-default-representation"
              placeholder="last name"
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
              <option>9</option>
              <option>10</option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="profile-form-default-representation" sm={3}>
            Quality of Model
          </Label>
          <Col sm={6}>
            <Input
              type="select"
              name="defaultRepresentation"
              id="profile-form-default-representation"
              placeholder="last name"
            >
              <option>Monthly</option>
              <option>Quarterly</option>
              <option>Yearly</option>
            </Input>
          </Col>
        </FormGroup>
      </Form>
    </div>
  );
};

export default RetrospectiveDetail;
