import { useEffect, useRef, useState } from 'react';

export type TimerProps = {
  duration: string | number;
  onExpire?: () => void;
  isStart?: boolean;
  onChange?: (seconds: number) => void;
};

export const useTimer = ({ isStart = true, onExpire = () => {}, duration = 0, onChange = () => {} }: TimerProps) => {
  const [time, setTime] = useState<number>(+duration);
  const [isRunning, setIsRunning] = useState<boolean>(isStart);
  const timer = useRef<NodeJS.Timeout | number>(0);

  const onPause = (): void => {
    setIsRunning(false);
    clearInterval(timer.current);
  };

  const onStart = (): void => {
    setIsRunning(true);
  };

  const increaseTime = (time: number): void => {
    setTime((prev) => prev + time);
  };

  useEffect(() => {
    setIsRunning(isStart);
  }, [isStart]);

  useEffect(() => {
    if (!isRunning) return () => {};

    if (!time) {
      onExpire();
      clearInterval(timer.current);
      setIsRunning(false);

      return () => {};
    }

    timer.current = setInterval(() => {
      setIsRunning(true);
      setTime((prev) => {
        const newTime = prev - 1;
        onChange(newTime);

        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer.current);
  }, [duration, isStart, isRunning, time, onExpire, onChange]);

  return { seconds: time, onPause, onStart, isRunning, increaseTime };
};
