import * as Yup from 'yup';
import type { CustomCodexData, PaginationParams } from '../../global/types';

export const DEFAULT_CUSTOM_CODEX_PAGINATION_PARAMS: PaginationParams = {
  limit: 25,
  page: 0,
  count: 0,
  order: 'ASC',
  sort: 'created_at',
};

export const DEFAULT_CUSTOM_CODEX_STATS: CustomCodexData = {
  codex: {
    threatsAmount: 0,
    mitigationsAmount: 0,
    attributesAmount: 0,
  },
  customCodex: {
    threatsAmount: 0,
    attributesAmount: 0,
    mitigationsAmount: 0,
  },
};

export const getValidationForCodex = (entries: Record<string, any>) =>
  Yup.array(Yup.object().shape(entries).nullable().optional()).nullable().optional();
