import type { Edge, Node, Viewport } from 'reactflow';

export type NodeData = {
  id: string;
  type: string;
  color: string | undefined;
  borderColor?: string | undefined;
  borderWidth?: number | undefined;
  fontSize?: number | undefined;
  fontColor?: string | undefined;
  textStyle?: {
    isBold?: boolean;
    isItalic?: boolean;
    isUnderline?: boolean;
    isLineThrough?: boolean;
  };
  autoFontSize?: boolean;
};

export type User = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  status: string;
  isEnabled: boolean;
  created_at: string;
  projectsCount?: number;
  threatModelsCount?: number;
};

export enum PROJECT_TYPE {
  DEFAULT = 'default',
  REPOSITORY = 'repository',
}

export type Running = {
  id: string;
  project: Project;
  serial_id: number;
  branch: string;
  commit_hash: string;
  commit_name: string;
  created_at: string;
};

export type Project = {
  id: string;
  title: string;
  description?: string;
  type?: PROJECT_TYPE;
  threat_models: ThreatModel[];
  runnings: Running[];
  created_by: {
    first_name: string;
    last_name: string;
  };
  sub_projects_amount: number;
  threat_models_amount: number;
  threat_amount: number;
  color?: string;
  archived_at: null | Date;
  is_default?: boolean;
  nestedProjects?: Project[];
  parent: { id: string };
  query?: {
    page: number;
    count: number;
    limit: number;
  };
};

export type Comment = {
  id: string;
  text: string;
  user: {
    first_name: string;
    last_name: string;
  };
  created_at: string;
};

export type Team = {
  id: string;
  title: string;
};

export type ThreatModel = {
  id: string;
  project?: {
    id: string;
    title: string;
    color?: string;
  };
  title: string;
  description: string;
  status: string;
  priority: ThreatModelPriorityType;
  representations: Representation[];
  updated_at: Date;
  due_to_date: Date;
  owner: {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
  };
  time_invested?: number;
  quality_of_model?: number;
  when_to_revisit?: number;
  order?: number;
  percentage?: number;
  threatAmount?: number;
  mitigationAmount?: number;
  rank?: string;
  my_models_rank?: string;
  root: null | {
    id: string;
    representations: { id: string }[];
  };
};

export type Representation = {
  id: string;
  format: string;
  data: {
    nodes: Node[];
    edges: Edge[];
    viewport: Viewport;
  };
  tenant: string;
  title?: string;
};

export const ThreatModelPriorityTypes = {
  CRITICAL: 'critical',
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};

export const ProjectStatus = {
  ON_TRACK: 'On track',
  ON_HOLD: 'On hold',
};

export type ThreatModelPriorityType = 'critical' | 'high' | 'medium' | 'low';

export type PaginationParams = {
  page: number;
  limit: number;
  count: number;
  sort?: string;
  order?: 'ASC' | 'DESC';
};

export interface PaginationParamsInterface {
  page: number;
  limit: number;
  count: number;
  sort?: string;
  order?: 'ASC' | 'DESC';
}

export const ELEMENT_TYPES = {
  EXTERNAL_ENTITY_NODE: {
    value: 'externalEntityNode',
    label: 'External Entity',
  },
  PROCESS_NODE: {
    value: 'processNode',
    label: 'Process',
  },
  DATA_STORE_NODE: {
    value: 'datastoreNode',
    label: 'Data Store',
  },
  DATA_FLOW: {
    value: 'dataflow',
    label: 'Data Flow',
  },
};

export type IMitigationStatus = 'done' | 'will' | 'never' | null;

export enum ONBOARDING_JOB_ROLES {
  SOFTWARE_DEVELOPER_OF_ENGINEER = 'Software Developer or Engineer',
  APP_SEC_LEADER = 'appSec Leader',
  C_SUITE_OR_TECHNOLOGY_LEADER = 'C-Suite or Technology Leader',
  DEV_OPS_DEV_SEC_OPS = 'DevOps/DevSecOps',
  PRODUCT_TEAM_MEMBER = 'Product Team Member',
  DEVELOPMENT_MANAGER_OR_DIRECTOR = 'Development Manager or Director',
  OTHER = 'Other',
}

export enum ONBOARDING_THREAT_MODE_USAGE_AVERAGES {
  A_0 = '0',
  A_1_2 = '1 - 2',
  A_3_5 = '3 - 5',
  A_6_10 = '6 - 10',
  A_11_15 = '11 - 15',
  A_16_20 = '16 - 20',
  A_20_PLUS = '20+',
}

export enum ONBOARDING_THREAT_MODELING_TOOLS {
  IRIUS_RISK = 'Irius Risk',
  THREAT_MODELER = 'Threat Modeler',
  FREE_OPEN_SOURCE = 'Free/Open Source (i.e. OWASP Threat Dragon, Microsoft Threat Model Tool)',
  DRAWING_TOOL = 'Drawing Tool ( i.e. Draw.io, Lucid Chart, Miro)',
  A_CUSTOM_TOOL = 'A Custom Tool ',
  NOT_USING_A_THREAT_MODELING_TOOL = 'Not Using a Threat Modeling Tool',
}

export type SAML_CONFIG = {
  acsUrl: string;
  entityId: string;
  logoutUrl: string;
  identifiers: string[];
  metadataUrl: string;
  idpSignOut: boolean;
  encryptedResponses: boolean;
  metadataFile: File | null;
  activeEncryptionCertificate: File | null;
  attributeMapping: {
    email: string;
    lastName: string;
    firstName: string;
  };
};

export type CodexStats = {
  threatsAmount: number;
  mitigationsAmount: number;
  attributesAmount: number;
};

export type CustomCodexData = {
  codex: CodexStats;
  customCodex: CodexStats;
};

export type SessionList = {
  id: number;
  duration: number;
};

export type SIDEBAR_MENU_ITEM = {
  icon: any;
  title: string;
  route: string;
  forAdmin?: boolean;
  fetchChildren?: any;
  children?: Record<string, string>[];
};

export type SIDEBAR_MENU_ITEMS = SIDEBAR_MENU_ITEM[][];
