import type { FC } from 'react';
import { useState } from 'react';
import { Spinner } from 'reactstrap';
import UploadCsvFile from '../../../assets/icons/UploadCsvFile.svg';
import UiModal from '../../common/Modal/UiModal';
import UiButton from '../../common/UIButton/UiButton';
import styles from './CustomCodexTable.module.css';

type UploadCsvModalProps = {
  onClose: () => void;
};

export const UploadCsvModal: FC<UploadCsvModalProps> = ({ onClose }) => {
  const [loading] = useState<string>('');

  return (
    <UiModal title="Import file" onClose={onClose}>
      <div className={styles.uploadWrap}>
        <div className="text-center">
          <img className="mb-3" src={UploadCsvFile} alt="" />
          <div className={styles.uploadWrapText}>
            Drop file here or <span>Choose file</span>
          </div>
          <div className={styles.uploadWrapSubText}>CSV Format</div>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <UiButton onClick={onClose} className="mt-3 me-3" type="transparent" style={{ width: '100px' }}>
          Cancel
        </UiButton>
        <UiButton onClick={() => {}} className="mt-3" style={{ width: '100px' }} disabled>
          {loading === 'save' ? <Spinner color="light" size="sm" /> : 'Import'}
        </UiButton>
      </div>
    </UiModal>
  );
};
