import type { FC } from 'react';
import { ProductFruits } from 'react-product-fruits';
import { ENVIRONMENT } from '../../global/constants';
import type { IUser } from '../../store/models/user';

const isProduction = process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.PRODUCTION;
const workspaceCode = process.env.REACT_APP_PRODUCT_FRUITS_CODE;

type ProductsFruitsComponentProps = {
  user: IUser;
};

export const ProductsFruitsComponent: FC<ProductsFruitsComponentProps> = ({ user }) => {
  if (!isProduction || !workspaceCode || !user.productFruitsHash) return null;

  const userInfo = {
    username: user.id,
    email: user.email,
    firstname: user.first_name,
    lastname: user.last_name,
    role: user.role,
    hmac: {
      hash: user.productFruitsHash,
    },
  };

  return <ProductFruits workspaceCode={workspaceCode} language="en" user={userInfo} />;
};
