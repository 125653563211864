import classNames from 'classnames';
import type { FC } from 'react';
import type { IconProps } from 'react-bootstrap-icons';
import { ExclamationTriangle, InfoCircle } from 'react-bootstrap-icons';
import type { ToastPosition } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import styles from './Toast.module.css';

interface ToastProps {
  autoClose: number;
  position: ToastPosition;
}

const Toast: FC<ToastProps> = ({ autoClose, position }) => {
  return (
    <ToastContainer
      theme="dark"
      autoClose={autoClose}
      position={position}
      hideProgressBar
      bodyClassName={(props: any) => {
        return styles[`${props.type}__toast_body`];
      }}
      toastClassName={(props: any) => {
        return classNames(styles[`${props.type}__toast`], styles.customToast);
      }}
      icon={(props: IconProps) => {
        if (props.type === 'success') {
          return (
            <div className={classNames(styles.successIcon, styles.icon)}>
              <InfoCircle /> Success
            </div>
          );
        }

        if (props.type === 'warning') {
          return (
            <div className={classNames(styles.warningIcon, styles.icon)}>
              <ExclamationTriangle /> Warning
            </div>
          );
        }

        if (props.type === 'error') {
          return (
            <div className={classNames(styles.errorIcon, styles.icon)}>
              <ExclamationTriangle /> Error
            </div>
          );
        }
      }}
    />
  );
};

export default Toast;
