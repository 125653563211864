import type { ChangeEvent, FC } from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ChevronRight, FiletypeJson, Link45deg, Pencil, Trash3 } from 'react-bootstrap-icons';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Popover } from 'reactstrap';
import type { Project } from '../../global/types';
import type { Dispatch } from '../../store/store';
import UiButton from '../common/UIButton/UiButton';
import RightBarNestedProject from '../RightBarNestedProject';
import ProjectColorPicker from './ProjectColorPicker';
import styles from './ProjectSettingsModal.module.css';

const SHOW_OTM_IMPORT_EXPORT = process.env?.REACT_APP_SHOW_OTM_IMPORT_EXPORT === 'true';

type ProjectSettingsModalProps = {
  project: Project;
  setVisible: any;
  onArchiveClick: any;
  isListItem?: any;
  showOTMImport?: boolean;
};

const ProjectSettingsModal: FC<ProjectSettingsModalProps> = ({
  project,
  setVisible,
  onArchiveClick,
  isListItem = false,
  showOTMImport = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [isCopied, setIsCopied] = useState(false);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const modalRef = useRef<any>(null);
  const [modalOffsetHeight, setModalOffsetHeight] = useState(0);
  const fileInputRef = useRef(null);

  const [isSubProjectOptions, setIsSubProjectOptions] = useState(false);
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string>();
  const [isConvert, setIsConvert] = useState<boolean>();

  const handlerNestedClick = (isConvert = false) => {
    setShowRightSidebar(true);
    setSelectedProject(undefined);
    setIsConvert(isConvert);
    setIsSubProjectOptions(false);
  };

  useEffect(() => {
    return setIsCopied(false);
  }, []);

  useLayoutEffect(() => {
    if (modalRef?.current && isListItem) {
      const modalRect = modalRef?.current?.getBoundingClientRect();

      if (modalRect.bottom > document.body.clientHeight && isListItem) setModalOffsetHeight(modalRect.height);
    }
  }, [modalRef.current]);

  const handleCopyLink = async () => {
    let textForCopy;

    if (isListItem) {
      textForCopy = `${window.location.href}collections/${project.id}`;
    } else {
      textForCopy = `${window.location.href}`;
    }

    await navigator.clipboard.writeText(textForCopy);
    setIsCopied(true);
  };

  const handleEditClick = () => {
    navigate(`/collections/update/${project.id}`);
  };

  const handleArchiveClick = () => {
    onArchiveClick(true);
  };

  const toggleColorPicker = () => {
    setColorPickerVisible(!colorPickerVisible);
  };

  const getModalPosition = () => {
    const position = {
      top: '32px',
      left: '-30px',
    };

    if (isListItem) {
      position.top = '10px';
      position.left = '15px';

      if (modalOffsetHeight) position.top = `-${modalOffsetHeight + 34}px`;
    }

    return position;
  };

  const handleOTMUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    // @ts-ignore
    fileReader.readAsText(event?.currentTarget?.files[0], 'UTF-8');
    fileReader.onload = async (e) => {
      const createdThreatModel = await dispatch.threatModel.createOTMThreatModel({
        parentProjectId: project.id,
        ...JSON.parse(e?.target?.result as string),
      });

      if (createdThreatModel?.representations && createdThreatModel?.representations[0]?.id) {
        navigate(`/collections/${project?.id}/d/${createdThreatModel?.representations[0].id}`);
      } else {
        toast.error('Wrong OTM format!');
      }
    };
  };

  const handleAddSubCollection = () => {
    dispatch.project.addSubProject({ subProjectId: selectedProject || '', parentProjectId: project.id }).then(() => {
      setShowRightSidebar(false);
    });
  };

  const handleConvertCollectionToSubCollection = () => {
    dispatch.project
      .addSubProject({ subProjectId: project.id, parentProjectId: selectedProject || '' })
      .then(() => setShowRightSidebar(false));
  };

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          if (!isSubProjectOptions && !showRightSidebar && !isSubProjectOptions) setVisible(null);
        }}
      >
        <div
          ref={modalRef}
          style={getModalPosition()}
          className={styles.modal}
          data-testid="collection-settings-popover"
        >
          <span className={modalOffsetHeight && isListItem ? styles.triangleBottom : styles.triangleTop} />
          <div className={styles.listPart}>
            <div className={styles.listItem} id="sub-collection" onClick={() => setIsSubProjectOptions(true)}>
              <div className={styles.listItemIcon}>
                <Pencil size={16} />
              </div>
              Sub collection
              <ChevronRight color="#2F2834" size={16} className="position-absolute end-0" />
            </div>
            <OutsideClickHandler onOutsideClick={() => setIsSubProjectOptions(false)}>
              <Popover popperClassName={styles.popover} flip target="sub-collection" isOpen={isSubProjectOptions}>
                <div
                  className={styles.listItem}
                  onClick={() => handlerNestedClick()}
                  data-testid="add-sub-collection-button"
                >
                  Add sub collection
                </div>
                <div
                  className={styles.listItem}
                  onClick={() => handlerNestedClick(true)}
                  data-testid="convert-to-sub-collection-button"
                >
                  Convert to sub collection
                </div>
                {project.parent && (
                  <div
                    className={styles.listItem}
                    onClick={() => dispatch.project.moveToTopLevel(project.id)}
                    data-testid="move-to-top-lvl-button"
                  >
                    Move to top level
                  </div>
                )}
              </Popover>
            </OutsideClickHandler>

            <div onClick={handleEditClick} className={styles.listItem}>
              <div className={styles.listItemIcon}>
                <Pencil size={16} />
              </div>
              Edit collection detail
            </div>
            {SHOW_OTM_IMPORT_EXPORT && showOTMImport && (
              <div
                onClick={(event) => {
                  event.stopPropagation();

                  if (fileInputRef !== null) {
                    // @ts-ignore
                    fileInputRef?.current?.click();
                  }
                }}
                className={styles.listItem}
              >
                <div className={styles.listItemIcon}>
                  <FiletypeJson size={16} />
                  <input
                    onChange={handleOTMUpload}
                    multiple={false}
                    ref={fileInputRef}
                    type="file"
                    hidden
                    accept="application/json"
                  />
                </div>
                Import OTM
              </div>
            )}
            <div className={styles.listItem} onClick={toggleColorPicker}>
              <div className={styles.listItemIcon}>
                <div className={styles.projectIconColor} style={{ background: project.color || '#C4C4C4' }} />
              </div>
              Set colors
              <ChevronRight color="#2F2834" size={16} className="position-absolute end-0" />
            </div>
          </div>
          <div className={styles.listPart}>
            <div onClick={handleCopyLink} className={styles.listItem}>
              <div className={styles.listItemIcon}>
                <Link45deg size={16} />
              </div>
              {isCopied ? 'Copied' : 'Copy collection link'}
            </div>
            {/* <div className={styles.listItem}> */}
            {/*  <div className={`${styles.listItemIcon}`}> */}
            {/*    <Subtract size={16} /> */}
            {/*  </div> */}
            {/*  Duplicate */}
            {/* </div> */}
          </div>
          <div data-testid="archive-collection" className={`${styles.listPart} ${styles.noBorder}`}>
            <div onClick={handleArchiveClick} className={`${styles.listItem} ${styles.red}`}>
              <div className={`${styles.listItemIcon}`}>
                <Trash3 size={16} />
              </div>
              Archive
            </div>
          </div>
        </div>
        {colorPickerVisible && (
          <ProjectColorPicker
            project={project}
            closeColorPicker={() => setColorPickerVisible(false)}
            isListItem={isListItem}
            modalOffsetHeight={modalOffsetHeight}
          />
        )}
      </OutsideClickHandler>
      {showRightSidebar && (
        <RightBarNestedProject
          visible={showRightSidebar}
          setVisible={setShowRightSidebar}
          setSelectedProject={setSelectedProject}
          button={
            isConvert ? (
              <UiButton disabled={!selectedProject} onClick={handleConvertCollectionToSubCollection}>
                Convert to sub collection
              </UiButton>
            ) : (
              <UiButton disabled={!selectedProject} onClick={handleAddSubCollection}>
                Add sub collection
              </UiButton>
            )
          }
        />
      )}
    </>
  );
};

export default ProjectSettingsModal;
