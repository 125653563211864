import { useFormik } from 'formik';
import type { FC } from 'react';
import * as Yup from 'yup';

import { Spinner } from 'reactstrap';
import styles from '../form.module.css';

import { FORGOT_PASSWORD_ROUTE } from '../../../store/constants/route-constants';
import UiButton from '../../common/UIButton/UiButton';
import UiInput from '../../common/UiInput/UiInput';

interface IProps {
  email: string;
  title: string;
  session?: string;
  password?: string;
  isLoading?: boolean;
  isDoubled?: boolean;
  secondTitle?: string;
  handleSubmit: <T>(data: T) => Promise<void>;
}

const CodeForm: FC<IProps> = ({ email, title, session, password, isLoading, isDoubled, secondTitle, handleSubmit }) => {
  const formik = useFormik({
    initialValues: {
      code: '',
      secondCode: '',
    },
    validationSchema: Yup.object({
      code: Yup.string().required('Required'),
      ...(isDoubled
        ? {
            secondCode: Yup.string()
              .required('Required')
              .test('code-match', "You can't use same code twice", function (value) {
                return this.parent.code !== value;
              }),
          }
        : {}),
    }),
    onSubmit: (values) => {
      handleSubmit({
        email,
        session,
        password,
        code: values.code,
        secondCode: values.secondCode,
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <div className={styles.formGroup}>
        <UiInput
          label={title}
          name="code"
          placeholder="Enter your code"
          value={formik.values.code}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showError={formik.touched.code && formik.errors.code}
          errorText={formik.errors.code}
          autoFocus={!window.location.pathname.includes(FORGOT_PASSWORD_ROUTE)}
        />
      </div>
      {isDoubled && (
        <>
          <div className={styles.middleText}>Please, wait for a new code</div>
          <div className={styles.formGroup}>
            <UiInput
              label={secondTitle}
              name="secondCode"
              value={formik.values.secondCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              showError={formik.touched.secondCode && formik.errors.secondCode}
              errorText={formik.errors.secondCode}
            />
          </div>
        </>
      )}
      <UiButton disabled={!formik.isValid} htmlType="submit" fullWidth className="fw-bold">
        {isLoading ? <Spinner color="light" size="sm" /> : 'Continue'}
      </UiButton>
    </form>
  );
};

export default CodeForm;
