/* eslint-disable @typescript-eslint/no-use-before-define */
import type { FC } from 'react';
import { useEffect } from 'react';
import { ArrowRight, Check } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import type { ModalProps } from 'reactstrap';
import { CloseButton, Modal, ModalBody } from 'reactstrap';
import { CONTACT_SALES_URL, TIER } from '../../global/constants';
import { useInitChargebee } from '../../hooks/useInitChargebee';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';
import styles from './ChoosePricingModal.module.css';
import { ChooseUsersModal } from './ChooseUsersModal';
import PricingCard from './PricingCard';
import PricingMatrixTable from './PricingMatrixTable';

const ChoosePricingModal: FC<ModalProps> = ({ onClose }) => {
  const dispatch = useDispatch<Dispatch>();
  const { users } = useTypedSelector((state) => state.user);

  useEffect(() => {
    if (!users) {
      dispatch.user.getUsers({ needAvatar: false });
    }
  }, [dispatch.user, users]);

  useInitChargebee();

  return (
    <Modal className={styles.ModalBodyWrap} isOpen size="xl" centered zIndex={11000} toggle={onClose}>
      <ModalBody className={styles.ModalBody}>
        <div className="d-flex">
          <CloseButton className="ms-auto" onClick={onClose} />
        </div>
        <h1 className="fs-2 fw-bold text-center">Say Hello to Unlimited Threat Modeling!</h1>
        <p className="fs-5 fw-bold text-center">Bye-bye per model limits!</p>
        <p className="px-5 text-center">
          Our per-user pricing model unlocks your team's full potential by promoting collaboration and innovation.
          Streamline your threat modeling process, uncover more threats, and enhance your security strategy with the
          flexibility to create as many models as you need – no limits, no extra costs.
        </p>
        <div className={styles.PricingCardsContainer}>
          {Object.entries(SUBSCRIPTION_PLANS).map(
            ([key, plan]) =>
              plan && (
                <PricingCard
                  key={key}
                  planKey={key as keyof typeof SUBSCRIPTION_PLANS}
                  subscriptionPeriod="ANNUALLY"
                  {...plan}
                />
              ),
          )}
        </div>
        <div className={styles.addsOn}>
          <div>
            <h4 className="fw-bold">Add-ons</h4>
            <div>Access a version of Devici that keeps all your critical data in-house.</div>
          </div>
          <div>
            <a rel="noreferrer" target="_blank" className="fw-bold" href={CONTACT_SALES_URL}>
              Contact sales for a quote <ArrowRight />
            </a>
          </div>
        </div>
        <PricingMatrixTable />
      </ModalBody>
      <ChooseUsersModal />
    </Modal>
  );
};

export default ChoosePricingModal;

export const SUBSCRIPTION_PLANS = {
  [TIER.FREE]: {
    title: 'Free Forever',
    description:
      'Free threat modeling for small teams, open source projects, or individuals looking to amp up their AppSec game.',
    footerText: null,
    price: null,
    customPrice: 0,
    features: [
      {
        icon: <Check size={25} />,
        text: 'Build 3 threat models',
        isBold: true,
      },
      {
        icon: <Check size={25} />,
        text: 'Team Collaboration',
        isBold: true,
      },
      {
        icon: <Check size={25} />,
        text: 'Devici Codex of Threats & Mitigations',
        isBold: false,
      },
      {
        icon: <Check size={25} />,
        text: 'Up to 10 users',
        isBold: true,
      },
      {
        icon: <Check size={25} />,
        text: 'AI-Infused Threat Knowledge',
        isBold: false,
      },
      {
        icon: <Check size={25} />,
        text: 'Real-Time Collaboration',
        isBold: false,
      },
      {
        icon: <Check size={25} />,
        text: 'Interactive List of Threats & Mitigations',
        isBold: false,
      },
      {
        icon: <Check size={25} />,
        text: 'Threat Model Health Score',
        isBold: false,
      },
    ],
    addOns: [],
    cta: 'Start Your FREE Plan',
    isPopular: false,
  },
  [TIER.BUSINESS]: {
    title: 'Growth',
    description:
      'Enhanced threat modeling for teams looking to ingrain threat modeling into their development lifecycle.',
    footerText: null,
    price: {
      MONTHLY: 20,
      ANNUALLY: 499,
    },
    features: [
      {
        icon: <Check size={25} />,
        text: 'All Free Forever features',
        isBold: false,
      },
      {
        icon: <Check size={25} />,
        text: 'Price per user',
        isBold: true,
      },
      {
        icon: <Check size={25} />,
        text: 'Programmatic Threat Model Management',
        isBold: false,
      },
      {
        icon: <Check size={25} />,
        text: 'All Free features',
        isBold: false,
      },
      {
        icon: <Check size={25} />,
        text: 'Multi-Layer Threat Model Canvases',
        isBold: false,
      },
      {
        icon: <Check size={25} />,
        text: 'SSO/SAML',
        isBold: false,
      },
      {
        icon: <Check size={25} />,
        text: 'Templates & Workflows',
        isBold: false,
      },
      {
        icon: <Check size={25} />,
        text: 'API Automation',
        isBold: false,
      },
    ],
    addOns: [
      {
        icon: <Check size={25} />,
        text: 'On-Premises Environment',
        isBold: false,
      },
    ],
    cta: 'Buy Growth',
    isPopular: true,
  },
  [TIER.ENTERPRISE]: {
    title: 'Accelerate',
    description:
      'Build unlimited threat models. Add unlimited users. This flat-rate, full-feature plan is designed for organizations actively growing a threat modeling culture.',
    price: null,
    footerText: 'Contact Sales',
    features: [
      {
        icon: <Check size={25} />,
        text: 'All Free Forever & Growth features',
        isBold: true,
      },
      {
        icon: <Check size={25} />,
        text: 'Unlimited Users',
        isBold: true,
      },
      {
        icon: <Check size={25} />,
        text: 'One Flat Rate',
        isBold: true,
      },
      {
        icon: <Check size={25} />,
        text: 'All free & growth features',
        isBold: false,
      },
    ],
    addOns: [
      {
        icon: <Check size={25} />,
        text: 'On-Premises Environment',
        isBold: false,
      },
    ],
    cta: 'Get a Quote',
    isPopular: false,
  },
};
