import type { FC } from 'react';
import { XLg } from 'react-bootstrap-icons';

type AliasItemProps = {
  title: string;
  onDelete?: (alias: string) => void;
  isCurrent: boolean;
  onClick?: () => void;
};

export const AliasItem: FC<AliasItemProps> = ({ title, onDelete, isCurrent, onClick }) => {
  return (
    <div
      className="px-2 py-1 rounded d-flex align-items-center gap-2"
      style={{ width: 'max-content', color: '#5A5A5E', background: '#E1E1EB' }}
      onClick={onClick ? () => onClick() : () => {}}
    >
      {title}
      {onDelete && !isCurrent ? <XLg className="cursor-pointer" onClick={() => onDelete(title)} /> : null}
    </div>
  );
};
