import type { FC, SyntheticEvent } from 'react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import type { EmitUpdate } from '../../../hooks/useSocket';
import { UpdateTypes } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import UserAvatar from '../../common/UserAvatar/UserAvatar';
import styles from './CommentInput.module.css';

type CommentInputProps = {
  threat: any;
  needScroll: boolean;
  onScroll: () => void;
  emitUpdate?: EmitUpdate;
  hideAvatar?: boolean;
  size?: {
    minHeight: number;
    maxHeight: number;
    paddingLeft: number;
  };
};

const CommentInput: FC<CommentInputProps> = ({
  threat,
  needScroll,
  onScroll,
  emitUpdate,
  hideAvatar = false,
  size = { minHeight: 58, maxHeight: 133, paddingLeft: 10 },
}) => {
  const [inputValue, setInputValue] = useState('');

  const dispatch = useDispatch<Dispatch>();
  const isCommentInputActive = useTypedSelector((state) => state.drawn?.isCommentInputActive);
  const { current } = useTypedSelector((state) => state.user);
  const commentLimit = useTypedSelector((state) => state.drawn?.commentLimit);

  useEffect(() => {
    return () => {
      dispatch.drawn.setIsCommentInputActive(false);
      dispatch.drawn.clearCommentData();
    };
  }, []);

  useEffect(() => {
    if (needScroll) {
      onScroll();
    }
  }, [needScroll]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputFocus = () => {
    dispatch.drawn.setIsCommentInputActive(true);
    dispatch.diagram.setCanCopy(false);
  };

  const handleEmitAfterUpdate = () => {
    if (threat?.id && emitUpdate) {
      emitUpdate(UpdateTypes.THREAT_COMMENT, threat.id, true);
    }
  };

  const handleCommentBtnClick = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch.drawn.addComment({
      threatId: threat.id,
      commentText: inputValue,
      limit: commentLimit,
    });
    setInputValue('');
    dispatch.drawn.setIsCommentInputActive(false);
    onScroll();
    handleEmitAfterUpdate();
  };

  const handleCommentInputKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (e.metaKey) {
        dispatch.drawn.addComment({
          threatId: threat.id,
          commentText: inputValue,
          limit: commentLimit,
        });
        setInputValue('');
        handleEmitAfterUpdate();
      }
    }
  };

  return (
    <div
      className={styles.container}
      style={{
        height: isCommentInputActive ? `${size.maxHeight}px` : `${size.minHeight}px`,
        paddingLeft: size.paddingLeft,
      }}
    >
      <div className={styles.userContainer}>
        {!hideAvatar && (
          <div className={styles.avatarContainer} style={{ paddingTop: isCommentInputActive ? '30px' : 0 }}>
            <UserAvatar user={current} />
          </div>
        )}
        <div className={styles.inputContainer} style={{ height: isCommentInputActive ? '100px' : '35px' }}>
          <textarea
            style={{ height: isCommentInputActive ? '43px' : '31px' }}
            value={inputValue}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={() => {
              setTimeout(() => {
                dispatch.drawn.setIsCommentInputActive(false);
              }, 300);
              dispatch.diagram.setCanCopy(true);
            }}
            id="commentInput"
            onKeyDown={handleCommentInputKeyDown}
            className={styles.input}
            placeholder="Ask a question or post an update..."
            rows={1}
          />
          <div className={styles.btnContainer} style={{ display: isCommentInputActive ? 'flex' : 'none' }}>
            <button
              type="button"
              onClick={(e) => handleCommentBtnClick(e)}
              className={styles.btn}
              disabled={inputValue.length < 2}
            >
              Comment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentInput;
