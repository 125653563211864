import classNames from 'classnames';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import notFoundImage from '../../assets/images/not-found-page.jpg';
import styles from './index.module.css';

const NotFoundPage: FC = () => (
  <main className={classNames(styles.notFoundPage, 'container text-center text-warning mt-5')}>
    <img className="w-100" src={notFoundImage} alt="not found 404" />
    <h1>Oops. The page you were looking for does’t exist.</h1>
    <p>You may have mistyped the address or the page may have moved.</p>
    <Link to="/">Take me back to the Home page</Link>
  </main>
);

export default NotFoundPage;
