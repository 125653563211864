import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { TIER } from '../../../global/constants';
import { isFreePlan } from '../../../helpers/isFreePlan';
import { copyQrCodeToNavigator } from '../../../helpers/user';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import UiModal from '../Modal/UiModal';
import UiButton from '../UIButton/UiButton';
import styles from './EnableAPIAccessForm.module.css';

interface IApiAccessModal {
  visible: boolean;
  isManage?: boolean;
  isEnable?: boolean;
  isRegenerated?: boolean;
  clientId?: string;
  clientSecret?: string;
}

export const EnableAPIAccessForm = () => {
  const dispatch = useDispatch<Dispatch>();
  const { apiAccess } = useTypedSelector((state) => state.user);
  const customerLimits = useTypedSelector((state) => state.tiers.customerLimits);
  const [loading, setLoading] = useState('');
  const [inputType, setInputType] = useState<'text' | 'password'>('password');
  const [modal, setModal] = useState<IApiAccessModal>({
    visible: false,
    clientId: '',
    clientSecret: '',
  });

  const handleManageApiAccess = () => {
    setModal({
      visible: true,
      isManage: true,
    });
  };

  const handleEnableApiAccess = async () => {
    setLoading('isEnable');
    const result = await dispatch.user.enableApiAccess();

    if (result) {
      setModal({
        visible: true,
        isEnable: true,
        clientId: result.clientId,
        clientSecret: result.clientSecret,
      });
    }

    setLoading('');
  };

  useEffect(() => {
    if (apiAccess === null && customerLimits && customerLimits?.tier !== TIER.FREE) dispatch.user.checkApiAccess();
  }, [apiAccess, customerLimits]);

  const handleClickButton = () => {
    if (apiAccess) handleManageApiAccess();
    else handleEnableApiAccess();
  };

  const handleRegenerateApiAccess = async () => {
    setLoading('isRegenerate');
    const result = await dispatch.user.regenerateApiAccess();

    if (result) {
      setModal({
        visible: true,
        isEnable: true,
        isRegenerated: true,
        clientId: result.clientId,
        clientSecret: result.clientSecret,
      });
    }

    setLoading('');
  };

  const handleDeleteApiAccess = async () => {
    setLoading('isDelete');
    await dispatch.user.deleteApiAccess();
    setModal({
      visible: false,
    });
    setLoading('');
  };

  const handleCloseModal = () => {
    if (modal.isEnable) dispatch.user.setApiAccess(true);

    setModal({
      visible: false,
    });
  };

  const handleCopy = async (text: string) => {
    const result = await copyQrCodeToNavigator(text);

    if (result) toast.success('Copied to clipboard!');
    else toast.warning('Could not copy!');
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className={styles.title}>API access</div>
      <UiButton
        disabled={!customerLimits || isFreePlan(customerLimits)}
        onClick={handleClickButton}
        style={{ width: '190px' }}
      >
        {loading === 'isEnable' ? (
          <Spinner color="light" size="sm" />
        ) : apiAccess ? (
          'Manage API access'
        ) : (
          'Enable API access'
        )}
      </UiButton>
      {modal.visible && (
        <UiModal title={apiAccess ? 'Manage Api Access' : 'Enable Api Access'} onClose={handleCloseModal}>
          {modal.isEnable && (
            <>
              <p>
                API access has been successfully {modal.isRegenerated ? 'regenerated' : 'enabled'}! Please make sure to
                securely store these credentials. You&apos;ll need them to authenticate your API requests.
              </p>

              <label className="fw-bold" htmlFor="clientId">
                Client ID
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control fs-12"
                  id="clientId"
                  defaultValue={modal.clientId}
                  readOnly
                  aria-describedby="basic-addon2"
                  style={{ fontSize: 14 }}
                />
                <div className="input-group-append">
                  <span
                    onClick={() => {
                      if (modal.clientId) handleCopy(modal.clientId);
                    }}
                    className={classNames('input-group-text', styles.inputSpan)}
                    id="basic-addon2"
                  >
                    Copy
                  </span>
                </div>
              </div>

              <label className="fw-bold" htmlFor="clientSecret">
                Client Secret
              </label>
              <div className="input-group">
                <input
                  type={inputType}
                  className="form-control fs-12"
                  id="clientSecret"
                  defaultValue={modal.clientSecret}
                  readOnly
                  aria-describedby="basic-addon1"
                  style={{ fontSize: 14 }}
                />
                <div className="input-group-append">
                  <div className={styles.inputEye}>
                    {inputType === 'text' && <Eye onClick={() => setInputType('password')} />}
                    {inputType === 'password' && <EyeSlash onClick={() => setInputType('text')} />}
                  </div>
                  <span
                    onClick={() => {
                      if (modal.clientSecret) handleCopy(modal.clientSecret);
                    }}
                    className={classNames('input-group-text', styles.inputSpan)}
                    id="basic-addon1"
                  >
                    Copy
                  </span>
                </div>
              </div>
            </>
          )}
          {modal.isManage && (
            <>
              <p>
                Regenerating API access will invalidate the current credentials, and deleting access permissions will
                revoke all current access to the system.
              </p>
              <b>Regenerate API Access</b>
              <p>Regenerating API access will create new credentials for accessing the API.</p>
              <b>Delete Access</b>
              <p className="mb-4">Deleting access will revoke all current access granted to users.</p>
              <div className="d-flex gap-2">
                <UiButton onClick={handleRegenerateApiAccess}>
                  {loading === 'isRegenerate' ? <Spinner color="light" size="sm" /> : 'Regenerate API Access'}
                </UiButton>
                <UiButton onClick={handleDeleteApiAccess}>
                  {loading === 'isDelete' ? <Spinner color="light" size="sm" /> : 'Delete Access'}
                </UiButton>
              </div>
            </>
          )}
        </UiModal>
      )}
    </div>
  );
};
