import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import { Plus, X } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Spinner } from 'reactstrap';

import OutsideClickHandler from 'react-outside-click-handler';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { THREATS_REGISTER_ROUTE } from '../../../store/constants/route-constants';
import type { Dispatch } from '../../../store/store';
import styles from './MitigationSearch.module.css';

let timer: any = null;

type IMitigationSearchProps = {
  componentId: string;
  isThreatRegister?: boolean;
  handleEmit?: () => void;
};

export const MitigationSearch: FC<IMitigationSearchProps> = ({ componentId, handleEmit, isThreatRegister = false }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const [mitigationInput, setMitigationInput] = useState(false);
  const [mitigationLoading, setMitigationLoading] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const { newMitigation } = useTypedSelector((state) => state.drawn);
  const mitigations = useTypedSelector((state) => state.diagram?.mitigations) || [];
  const { threat } = useTypedSelector((state) => state.drawn);

  const handleChangeSearchInput = (e: any) => {
    const title = e.target.value;
    dispatch.drawn.setNewMitigation({ ...newMitigation, title });
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (e.target.value.length) {
        setMitigationLoading(true);
        dispatch.mitigations.searchMitigationsByTitle(e.target.value).then(() => {
          setMitigationLoading(false);
        });
      }
    }, 500);
  };

  const handleAddMitigationToThreat = async (item: any) => {
    if (!item?.id) return;

    await dispatch.mitigations.addMitigationToThreat({
      threatId: threat.id,
      mitigationId: item.id,
      componentId,
    });
    dispatch.drawn.setThreat({
      ...threat,
      mitigations: [...(threat?.mitigations || []), item],
    });
    await dispatch.threatModel.updateCurrentThreatModel();

    if (isThreatRegister) {
      dispatch.threats.updateThreatFromThreatRegisterList({
        id: threat.id,
        mitigations: [...(threat?.mitigations || []), item],
      });
    }

    if (handleEmit) {
      handleEmit();
    }
  };

  window.onkeydown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      let newIndex = event.shiftKey ? selectedItemIndex - 1 : selectedItemIndex + 1;

      if (newIndex < 0) newIndex = mitigations.length - 1;

      if (newIndex >= mitigations.length) newIndex = 0;

      setSelectedItemIndex(newIndex);
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      const selectedItem = mitigations?.[selectedItemIndex];

      if (selectedItem?.id) {
        handleAddMitigationToThreat(selectedItem);
        dispatch.drawn.setNewMitigation({ ...newMitigation, title: '' });
        dispatch.diagram.setMitigations([]);
        setSelectedItemIndex(0);
      }
    }
  };

  const onCreateNewMitigationClick = () => {
    setMitigationInput(false);

    if (pathname.includes(`${THREATS_REGISTER_ROUTE}/`)) {
      dispatch.drawn.clearMitigation();
    }

    navigate('m/new');
  };

  if (!mitigationInput)
    return (
      <div className="">
        <button
          type="button"
          className={classNames('btn btn-outline-primary border-0', styles.addMitigationButton)}
          onClick={() => setMitigationInput(true)}
        >
          <Plus /> Add mitigation
        </button>
      </div>
    );

  return (
    <div className={classNames('w-100 position-relative', styles.inputWrap)}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setMitigationInput(false);
          dispatch.drawn.clearNewMitigation();
        }}
      >
        {mitigationLoading ? (
          <Spinner
            size="sm"
            className={classNames('position-absolute end-0 cursor-pointer', styles.inputWrapClearButton)}
          />
        ) : (
          <X
            className={classNames('position-absolute end-0 cursor-pointer', styles.inputWrapClearButton)}
            onClick={() => {
              setMitigationInput(false);
              dispatch.drawn.clearNewMitigation();
            }}
          />
        )}
        <input
          type="text"
          value={newMitigation?.title || ''}
          className="form-control shadow-none mb-1 pe-4"
          placeholder="Enter mitigations"
          onChange={handleChangeSearchInput}
          autoFocus
          onFocus={() => dispatch.diagram.setCanCopy(false)}
          onBlur={() => dispatch.diagram.setCanCopy(true)}
        />
        {!mitigations?.length && !!newMitigation?.title?.length && (
          <div className={classNames('d-flex flex-wrap gap-1 p-1', styles.attributesWrap)}>
            {!mitigations?.length && !!newMitigation?.title?.length && (
              <div>
                <div
                  key={0}
                  className={classNames('fs-12 text-primary', styles.attributeItem, styles.addItem)}
                  onClick={onCreateNewMitigationClick}
                >
                  <Plus fontSize={16} color="#4285F4" />
                  {`Add “${newMitigation.title}” as a new mitigation`}
                </div>
                <div className="fs-12 text-secondary">No matches found</div>
              </div>
            )}
          </div>
        )}
        {mitigations?.length ? (
          <div className={classNames('d-flex flex-wrap gap-1 p-1', styles.attributesWrap)}>
            {mitigations?.map((mitigation: any, idx: number) => (
              <div
                hidden={threat?.mitigations?.map((i) => i.id)?.includes(mitigation.id)}
                key={mitigation.id}
                className={classNames('fs-12', styles.attributeItem, {
                  [styles.selectedAttribute]: selectedItemIndex === idx,
                })}
                onClick={() => handleAddMitigationToThreat(mitigation)}
              >
                {mitigation.title}
              </div>
            ))}
          </div>
        ) : null}
      </OutsideClickHandler>
    </div>
  );
};
