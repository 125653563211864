import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Search } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Spinner } from 'reactstrap';
import { MIN_SEARCH_LENGTH } from '../../../global/constants';
import { useSelectedEntityType } from '../../../hooks/useSelectedEntityType';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from './SearchAttribute.module.css';

type SearchAttributeProps = {
  placeholder?: string;
  onAttributeSelect: any;
};

let timer: any = null;

const SearchAttribute: FC<SearchAttributeProps> = ({ placeholder = 'Search', onAttributeSelect }) => {
  const dispatch = useDispatch<Dispatch>();
  const { componentId } = useParams();
  const component = useTypedSelector((state) => state.drawn?.component);
  const { attributes, isSearchingAttributes } = useTypedSelector((state) => state.diagram);
  const [value, setValue] = useState('');
  const [selectedAttributeIndex, setSelectedAttributeIndex] = useState(0);
  const selectedEntityType = useSelectedEntityType();
  const [dropDownVisible, setDropDownVisible] = useState(true);

  useEffect(() => {
    return () => {
      window.onkeydown = null;
    };
  }, []);

  useEffect(() => {
    if (value.length) setValue('');

    if (attributes.length) dispatch.diagram.setAttributes([]);
  }, [componentId]);

  const handleChange = (e: any) => {
    setValue(e.target.value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      const searchValue = e.target.value;

      if (searchValue?.length && searchValue.length >= MIN_SEARCH_LENGTH) {
        setDropDownVisible(true);
        dispatch.diagram.getAttributes({ search: searchValue, selectedEntityType });
      } else {
        dispatch.diagram.setAttributes([]);
      }
    }, 500);
  };

  window.onkeydown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      let newIndex = event.shiftKey ? selectedAttributeIndex - 1 : selectedAttributeIndex + 1;

      if (newIndex < 0) newIndex = attributes.length - 1;

      if (newIndex >= attributes.length) newIndex = 0;

      setSelectedAttributeIndex(newIndex);
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      const selectedAttr = attributes?.[selectedAttributeIndex];

      if (selectedAttr?.id) {
        onAttributeSelect(selectedAttr.title);
        setValue('');
        dispatch.diagram.setAttributes([]);
        setSelectedAttributeIndex(0);
      }
    }
  };

  const renderAttributes = () => {
    const filteredAttributes = attributes
      .filter((attr) => !component?.attributes?.map((ca) => ca.title).includes(attr.title))
      .filter((at) => !at?.is_custom);

    if (attributes?.length && dropDownVisible && filteredAttributes.length)
      return (
        <div className={classNames('d-flex flex-wrap gap-1 p-1', styles.attributesWrap)}>
          {filteredAttributes?.map((attr, idx) => (
            <div
              // hidden={component?.attributes
              //   ?.map((attr) => attr.title)
              //   .includes(attr)}
              key={attr.id}
              className={classNames('fs-12', styles.attributeItem, {
                [styles.selectedAttribute]: selectedAttributeIndex === idx,
              })}
              onClick={() => {
                onAttributeSelect(attr.title);
                setValue('');
                setDropDownVisible(false);
              }}
            >
              {attr.title}
            </div>
          ))}
        </div>
      );
  };

  return (
    <div className={classNames('w-100 position-relative', styles.inputWrap)}>
      <div>
        <Search fontSize={16} color="#6F767E" />
      </div>
      <input
        value={value}
        className={styles.input}
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        autoFocus
        onFocus={() => dispatch.diagram.setCanCopy(false)}
        onBlur={() => dispatch.diagram.setCanCopy(true)}
      />
      {isSearchingAttributes && (
        <div style={{ paddingTop: '2px' }}>
          <Spinner color="dark" size="sm" />
        </div>
      )}
      {renderAttributes()}
    </div>
  );
};

export default SearchAttribute;
