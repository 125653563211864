import { useState } from 'react';
import Editor from 'react-simple-code-editor';
import Diagram from '../../components/Diagram';
import { highlightWithLineNumbers } from '../../helpers/editor';
import SourceCodeEditor from './SourceCodeEditor';
import './styles.css';

const DebugPage = () => {
  const [reactFlowCode, setReactFlowCode] = useState('');
  const [json, setJson] = useState({
    nodes: [],
    edges: [],
  });

  const submit = () => {
    const value = JSON.parse(reactFlowCode);

    if (value?.edges?.length && value?.nodes?.length) {
      setJson(value);
    }
  };

  return (
    <div className="row">
      <div className="col">
        <SourceCodeEditor setReactFlowCode={setReactFlowCode} setJson={setJson} />
        <div>
          <div className="d-flex align-middle justify-content-between p-2">
            <h6>React flow</h6>
            <button className="btn btn-primary btn-sm" onClick={submit}>
              Apply
            </button>
          </div>
          <div style={{ height: '40vh', overflow: 'auto' }}>
            <Editor
              value={reactFlowCode}
              highlight={(code) => highlightWithLineNumbers(code)}
              onValueChange={(code) => setReactFlowCode(code)}
              padding={10}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 14,
                outline: 0,
              }}
              textareaId="codeArea"
              className="code-editor"
              placeholder="Code..."
              name="hello"
            />
          </div>
        </div>
      </div>
      <div className="col">
        <Diagram edges={json.edges} nodes={json.nodes} />
      </div>
    </div>
  );
};

export default DebugPage;
