import type { FC, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { Center } from '../../components/common/Center/Center';
import UiButton from '../../components/common/UIButton/UiButton';
import { HIDE_ONBOARDING_AFTER_PAYMENT, TIER } from '../../global/constants';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import { PRODUCT_TOUR_PARAM } from '../../store/constants';
import { TIERS_PORTAL_LINK } from '../../store/constants/api-constants';
import http from '../../store/http/http-common';
import type { Dispatch } from '../../store/store';

const CheckoutSuccessPage: FC = () => {
  const { customerLimits } = useTypedSelector((state) => state.tiers);
  const currentUser = useTypedSelector((state) => state.user.current);
  const isHideOnboarding = useTypedSelector((state) => state.auth?.isHideOnboarding);
  const defaultProject = useTypedSelector((state) => state.project?.projects?.find((i) => i.is_default));
  const [isLoading, setIsLoading] = useState(true);
  const [threatModelCreatingLoading, setThreatModelCreatingLoading] = useState('');
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    const hideOnboarding = localStorage.getItem(HIDE_ONBOARDING_AFTER_PAYMENT);

    if (hideOnboarding) {
      dispatch.auth.setIsHideOnboarding(true);
      localStorage.removeItem(HIDE_ONBOARDING_AFTER_PAYMENT);
    }

    return () => {
      if (hideOnboarding) {
        dispatch.auth.setIsHideOnboarding(false);
      }
    };
  }, []);

  useEffect(() => {
    // TODO: Handle Subscribtion failed
    // TODO: Temp fix
    // keep checking for the user's tier until it's not free. Because it takes some time for backend to update the user's tier
    const interval = setInterval(() => {
      if (customerLimits?.tier !== TIER.FREE) {
        setIsLoading(false);
        clearInterval(interval);

        return;
      }

      dispatch.tiers.getCustomerLimits();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [customerLimits?.tier]);

  const handleStartThreatModeling = async (event: SyntheticEvent, isTour?: boolean) => {
    event.preventDefault();
    event.stopPropagation();

    if (threatModelCreatingLoading.length) return;

    setThreatModelCreatingLoading(isTour ? 'tour' : 'notour');
    const createdThreatModel = await dispatch.threatModel.createThreatModel({
      projectId: defaultProject?.id,
      title: 'Draft threat model',
    });

    if (createdThreatModel?.representationId && createdThreatModel?.project?.id) {
      dispatch.auth.setIsHideOnboarding(false);
      dispatch.drawn.setIsFirstOpen(true);
      dispatch.app.setSidebarVisible(false);
      navigate(
        `/collections/${createdThreatModel?.project?.id}/d/${createdThreatModel.representationId}${
          isTour ? `?${PRODUCT_TOUR_PARAM}` : ''
        }`,
      );
    }

    setThreatModelCreatingLoading('');
  };

  const handleOpenPaymentInfo = async () => {
    setIsLoading(true);
    try {
      const response = await http.get(TIERS_PORTAL_LINK);
      setIsLoading(false);
      window.open(response.data.redirectUrl, '_blank');
    } catch (err) {
      // handle error
    }
  };

  return (
    <div className="mt-5">
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="text-center">
            <h2 className="fw-bolder">Payment Successful - Welcome Aboard!</h2>
            <p>Start Building Threat Models Today!</p>
          </div>
          <div className="mt-5">
            <Center>
              <div className="shadow rounded-4 p-4 text-center border">
                <CheckCircleFill size={50} className="text-success" />
                <p className="mt-4">
                  Thank you, {currentUser?.first_name}! You&apos;re all set to explore Devici.
                  <br />
                  Here are some helpful links:
                </p>
                <ul className="list-group mb-3 text-start fs-14">
                  <a
                    target="_blank"
                    href="https://meetings.hubspot.com/brian1749/learn-about-devici-enterprise"
                    className="list-group-item link-primary"
                    rel="noreferrer"
                  >
                    Schedule a Demo & Onboarding Session
                  </a>
                  <a
                    onClick={(e) => handleStartThreatModeling(e, true)}
                    className="list-group-item link-primary cursor-pointer"
                  >
                    Take a Quick Product Tour{' '}
                    {threatModelCreatingLoading === 'tour' && <Spinner size="sm" className="ms-2" />}
                  </a>
                  <a
                    className="list-group-item link-primary"
                    href="mailto:support@devici.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact support via in-app chat or email (support@devici.com)
                  </a>
                  <a
                    className="list-group-item link-primary cursor-pointer"
                    rel="noreferrer"
                    onClick={handleOpenPaymentInfo}
                  >
                    Get payment info
                  </a>
                </ul>
                <UiButton type="primary" fullWidth onClick={(e: any) => handleStartThreatModeling(e)}>
                  {threatModelCreatingLoading === 'notour' ? (
                    <Spinner size="sm" />
                  ) : isHideOnboarding ? (
                    'Get Started in the App'
                  ) : (
                    'Start Threat Modeling'
                  )}
                </UiButton>
              </div>
            </Center>
          </div>
        </>
      )}
    </div>
  );
};

export default CheckoutSuccessPage;
