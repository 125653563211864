import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useNavigate, useParams } from 'react-router';
import type { Edge, Node } from 'reactflow';
import { ReactFlowProvider } from 'reactflow';
import { Spinner } from 'reactstrap';
import type { Socket } from 'socket.io-client';
import type { WebsocketProvider } from 'y-websocket';
import { Doc } from 'yjs';
import Diagram from '../../components/Diagram';
import ResizeNotification from '../../components/Diagram/ResizeNotification/ResizeNotification';
import { ThreatRegister } from '../../components/ThreatRegister/ThreatRegister';
import { stringToBackgroundColor } from '../../helpers/colors';
import { getWebsocketProvider } from '../../helpers/getWebsocketProvider';
import { isReadMode } from '../../helpers/isReadMode';
import useEdgesStateSynced from '../../hooks/useEdgesStateSynced';
import { useMouseCoordinates } from '../../hooks/useMouseCoordinates';
import useNodesStateSynced from '../../hooks/useNodesStateSynced';
import { RoomTypes, UpdateTypes, useSocket } from '../../hooks/useSocket';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import { PROJECTS_ROUTE, THREATS_REGISTER_ROUTE } from '../../store/constants/route-constants';
import type { Dispatch } from '../../store/store';
import OutdatedVersionModal from './components/OutdatedVersionModal';

let provider: WebsocketProvider | null;
let socket: Socket | null;
let collaboratorsTimer: any = null;

export interface ICollaboratorSharedState {
  id: string;
  first_name: string;
  last_name: string;
  color: string;
  actions?: any;
  mouse_coordinates: { x: number; y: number };
}

type NewTreatModelData = {
  representationId: string;
  date: string;
  author: string;
};

// eslint-disable-next-line no-undef
let timeOut: NodeJS.Timeout;

type DiagramsPagePops = {
  diagramId: string;
};

const DiagramsPage: FC<DiagramsPagePops> = ({ diagramId }) => {
  const [doc] = useState(new Doc());
  // TODO: need to use to show successful diagram autosaving
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isCollaborationConnectionEnabled, setIsCollaborationConnectionEnabled] = useState(false);
  const dispatch = useDispatch<Dispatch>();
  const { joinRoom, leaveRoom, emitUpdate } = useSocket();
  const [collaborators, setCollaborators] = useState<ICollaboratorSharedState[]>([]);
  const { edges, onEdgesChange, onConnect, edgesSharedState } = useEdgesStateSynced(doc);
  const { nodes, onNodesChanges, nodesSharedState } = useNodesStateSynced(doc, edgesSharedState);
  const { data: representation } = useTypedSelector((state) => state.representation);

  const { id: projectId } = useParams();
  const { current } = useTypedSelector((state) => state.user);
  const {
    liveUpdateData: threatModelsLiveUpdateData,
    rootThreatModel,
    interactionMode,
    currentThreatModel,
  } = useTypedSelector((state) => state.threatModel);
  const { sidebarVisible } = useTypedSelector((state) => state.app);
  const sideBarOffset = sidebarVisible ? 420 : 0;
  const [mouseCoordinates] = useMouseCoordinates();
  const projectExist = useTypedSelector((state) => !!state.project?.current?.id);
  const [width, setWidth] = useState(window.innerWidth);
  const [modal, setModal] = useState({ open: false, representationId: '', date: new Date(), author: '' });
  const navigate = useNavigate();
  const params = useParams();

  const handleMoveToNewVersion = useCallback(() => {
    navigate(`${PROJECTS_ROUTE}/${params.id}/d/${modal.representationId}`);
  }, [modal.representationId, navigate, params.id]);

  provider?.on('status', (data: { status: string }) =>
    setIsCollaborationConnectionEnabled(data.status === 'connected'),
  );

  provider?.awareness.on('change', () => {
    if (provider) {
      const users = Array.from(provider.awareness.getStates().values()).map((item) => ({
        ...item.collaborator,
      }));
      setCollaborators(users);
      clearTimeout(collaboratorsTimer);
      collaboratorsTimer = setTimeout(() => {
        dispatch.diagram.setCollaborators(users?.map((i) => i.id).filter((i) => i !== current?.id) || []);
      }, 300);
    }
  });

  const handleResize = () => {
    return setWidth(window.innerWidth);
  };

  const handleNewThreatModelVersion = (data: string, timeOutDelay = 2000) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }

    const { author, date, representationId }: NewTreatModelData = JSON.parse(data);

    timeOut = setTimeout(() => {
      const id = rootThreatModel?.id || '';
      dispatch.threatModel.getThreatModelHistory(id);
      dispatch.threatModel.getRootThreatModel(id);

      if (id === currentThreatModel?.id) {
        setModal({ open: true, representationId, author, date: new Date(date) });
      }
    }, timeOutDelay);
  };

  const closeModal = useCallback(() => {
    setModal((prev) => ({ ...prev, open: false }));
  }, []);

  const getCurrentThreatModel = (timeOutDelay = 2000) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }

    timeOut = setTimeout(() => {
      dispatch.threatModel.updateCurrentThreatModel();
    }, timeOutDelay);
  };

  useEffect(() => {
    if (diagramId && !representation) {
      dispatch.representation.getRepresentation(diagramId);
    }

    if (projectId && !projectExist) {
      dispatch.app.setSidebarVisible(false);
    }

    dispatch.diagram.setCopiedNodeId([]);

    dispatch.diagram.setNodesSharedState(nodesSharedState);
    dispatch.diagram.setEdgesSharedState(edgesSharedState);

    dispatch.drawn.setEmitUpdate(emitUpdate);
    dispatch.drawn.setEmitComponentVisualRemove((componentId: string) =>
      emitUpdate(UpdateTypes.COMPONENT_REMOVE, componentId, false),
    );

    window.addEventListener('resize', handleResize);

    return () => {
      dispatch.diagram.clearSharedState();
      provider?.awareness.setLocalState(null);
      provider?.disconnect();
      provider?.destroy();
      provider = null;

      if (rootThreatModel) {
        leaveRoom(RoomTypes.DIAGRAM, rootThreatModel.id);
      }

      dispatch.drawn.setEmitUpdate(undefined);
      dispatch.drawn.setEmitComponentVisualRemove(undefined);
      dispatch.representation.setData(null);
      window.removeEventListener('resize', handleResize);
      dispatch.threatModel.setInitialCurrentThreatModel();
      dispatch.threats.setThreatRegisterList(null);
      dispatch.diagram.setNodesSharedState(null);
      dispatch.diagram.setEdgesSharedState(null);
    };
  }, []);

  useEffect(() => {
    if (rootThreatModel) {
      socket = joinRoom(RoomTypes.DIAGRAM, rootThreatModel.id);
    }
  }, [currentThreatModel, joinRoom, rootThreatModel]);

  useEffect(() => {
    if (representation) {
      if (diagramId && !provider) {
        provider = getWebsocketProvider(diagramId, doc, representation.tenant);
      }

      if (representation.data?.nodes?.length && !nodes.length) {
        representation.data.nodes.forEach((node) => nodesSharedState.set(node.id, node));
      }

      if (representation.data?.edges?.length && !edges.length) {
        representation.data.edges.forEach((edge) => edgesSharedState.set(edge.id, edge));
      }
    }
  }, [representation]);

  useEffect(() => {
    if (
      threatModelsLiveUpdateData &&
      currentThreatModel &&
      threatModelsLiveUpdateData.includes(currentThreatModel.id)
    ) {
      getCurrentThreatModel(0);
    }
  }, [threatModelsLiveUpdateData]);

  useEffect(() => {
    if (!current) return;

    provider?.awareness.setLocalStateField('collaborator', {
      id: current.id,
      first_name: current.first_name,
      last_name: current.last_name,
      color: stringToBackgroundColor(`${current.first_name} ${current.last_name}`),
      mouse_coordinates: {
        x: mouseCoordinates.x + sideBarOffset,
        y: mouseCoordinates.y,
      },
    });
  }, [current, mouseCoordinates]);

  socket?.on(UpdateTypes.COMPONENT, (data): void => {
    dispatch.drawn.setForceUpdateComponentId(data);
    getCurrentThreatModel(5000);
  });

  socket?.on(UpdateTypes.COMPONENT_REMOVE, (data) => {
    dispatch.drawn.setVisualRemoveComponentId(data);
    getCurrentThreatModel(5000);
  });

  socket?.on(UpdateTypes.THREAT, (data) => {
    dispatch.drawn.setForceUpdateThreatId(data);
    getCurrentThreatModel();
  });

  socket?.on(UpdateTypes.THREAT_REMOVE, (data) => {
    dispatch.drawn.setForceRemoveThreatId(data);
    getCurrentThreatModel();
  });

  socket?.on(UpdateTypes.THREAT_COMMENT, (data) => {
    dispatch.drawn.setForceUpdateForCommentsByThreatId(data);
  });

  socket?.on(UpdateTypes.MITIGATION, (data) => {
    dispatch.drawn.setForceUpdateMitigationId(data);
    getCurrentThreatModel();
  });

  socket?.on(UpdateTypes.ATTRIBUTE, (data) => {
    dispatch.diagram.setForceUpdateAttributeId(data);
    getCurrentThreatModel();
  });

  socket?.on(UpdateTypes.ATTRIBUTE_REMOVE, (data) => {
    dispatch.drawn.setWsRemoveAttributeId(data);
    getCurrentThreatModel();
  });

  socket?.on(UpdateTypes.REGISTER, (data) => {
    dispatch.threatModel.setRegisterLiveUpdateData(data);
  });

  socket?.on(UpdateTypes.ELEMENT_REGISTER, (data) => {
    dispatch.threatModel.setElementRegisterLiveUpdateData(data);
  });

  socket?.on(UpdateTypes.THREAT_MODEL_REVERTED, (data) => {
    handleNewThreatModelVersion(data);
  });

  const prepareComponentsForDiagram = (components: Node[] | Edge[]) => {
    return components.map((component) => {
      if (
        current?.id &&
        component.selected &&
        component.data?.selectedBy &&
        !component.data.selectedBy.includes(current.id)
      ) {
        component.selected = false;
      }

      return component;
    });
  };

  return (
    <div style={{ height: '100vh', position: 'relative' }}>
      <OutdatedVersionModal
        date={modal.date}
        author={modal.author}
        isOpen={modal.open}
        closeModal={closeModal}
        handleMoveToNewVersion={handleMoveToNewVersion}
      />
      <Routes>
        <Route
          path={`${THREATS_REGISTER_ROUTE}/*`}
          element={
            representation ? (
              <ThreatRegister collaborators={collaborators} emitUpdate={emitUpdate} />
            ) : (
              <div className="h-100 d-flex justify-content-center align-items-center">
                <Spinner color="dark" />
              </div>
            )
          }
        />
        <Route
          path="/*"
          element={
            representation ? (
              <ReactFlowProvider>
                {width > 767 ? (
                  <Diagram
                    nodes={prepareComponentsForDiagram(nodes) as Node[]}
                    edges={prepareComponentsForDiagram(edges) as Edge[]}
                    handleNodeChange={onNodesChanges}
                    nodesSharedState={nodesSharedState}
                    edgesSharedState={edgesSharedState}
                    handleEdgeChange={onEdgesChange}
                    onConnect={onConnect}
                    isSidebar
                    isLocked={isReadMode(interactionMode)}
                    collaborators={collaborators}
                    emitUpdate={emitUpdate}
                  />
                ) : (
                  <ResizeNotification />
                )}
              </ReactFlowProvider>
            ) : (
              <div className="h-100 d-flex justify-content-center align-items-center">
                <Spinner color="dark" />
              </div>
            )
          }
        />
      </Routes>
    </div>
  );
};

export default DiagramsPage;
