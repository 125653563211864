export const MAIN_COLORS = ['#FFFFFF', '#FF4030', '#EB6F0A', '#FBBC05', '#36A159', '#0062D8', '#8B3BB2'];

export const MAIN_BORDER_COLORS = MAIN_COLORS.map((c) => {
  if (c === '#FFFFFF') return '#000000';

  return c;
});

export const COLLECTION_COLORS = [
  '#E83C2D',
  '#EB6F0A',
  '#FFC700',
  '#36A159',
  '#0062D8',
  '#8B3BB2',
  '#F9CECA',
  '#FADBC2',
  '#FFF1BF',
  '#CDE7D5',
  '#BFD8F5',
  '#E2CEEC',
  '#FDECEA',
  '#FDF1E7',
  '#FFF9E6',
  '#EBF6EE',
  '#E6EFFB',
  '#F3EBF7',
];

export const STICKER_COLORS = ['#F5FAF6', '#F5F9FE', '#FEF5F5', '#FEFBF3'];

export const EDGE_DEFAULT_COLOR = '#6F767E';

export enum EDGE_STYLES {
  SMOOTHSTEP = 'smoothstep',
  BEZIER = 'default',
  EDITABLE = 'editable',
  EDITABLE_BEZIER = 'editableBezier',
}

export const getUserColorsFromLocalStorage = () => {
  const colorsString = localStorage.getItem('userColors');

  return JSON.parse(colorsString || '[]');
};

export const putColorToLocalStorage = (color: string) => {
  const colors = getUserColorsFromLocalStorage();

  if (colors.length <= 8) {
    colors.push(color);
    const uniqueColors = Array.from(new Set(colors));
    localStorage.setItem('userColors', JSON.stringify(uniqueColors));
  }
};

export const replaceColorFromLocalStorage = (oldColor: string, newColor: string) => {
  const colors = getUserColorsFromLocalStorage();

  if (colors.length <= 9) {
    const oldIndex = colors.findIndex((c: string) => c === oldColor);
    colors[oldIndex] = newColor;
    localStorage.setItem('userColors', JSON.stringify([...colors]));
  }
};

export const getOpacity = (value: number) => {
  switch (true) {
    case value > 0 && value <= 10:
      return '1A';
    case value > 10 && value <= 20:
      return '33';
    case value > 20 && value <= 30:
      return '4D';
    case value > 30 && value <= 40:
      return '66';
    case value > 40 && value <= 50:
      return '80';
    case value > 50 && value <= 60:
      return '99';
    case value > 60 && value <= 70:
      return 'B3';
    case value > 70 && value <= 80:
      return 'CC';
    case value > 80 && value <= 90:
      return 'E6';
    case value > 90 && value <= 100:
      return 'FF';
    default:
      return 'CC';
  }
};

export const getOpacityPercent = (opacity: string) => {
  switch (opacity) {
    case '1A':
      return 10;
    case '33':
      return 20;
    case '4D':
      return 30;
    case '66':
      return 40;
    case '80':
      return 50;
    case '99':
      return 60;
    case 'B3':
      return 70;
    case 'CC':
      return 80;
    case 'E6':
      return 90;
    case 'FF':
      return 100;
    default:
      return 80;
  }
};

const isLightOpacity = (opacity: string) => {
  const lowOpacityList = ['1A', '33', '4D', '66'];

  return lowOpacityList.includes(opacity);
};

export const stringToBackgroundColor = (str = '') => {
  str = String(str);
  let hash = 0;
  str.split('').forEach((char) => {
    // eslint-disable-next-line no-bitwise
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = '#';
  for (let i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, '0');
  }

  return colour;
};

export const stringToCommonColor = (color: string) => {
  color = stringToBackgroundColor(color);
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substring(0, 2), 16);
  const c_g = parseInt(hex.substring(2, 2 + 2), 16);
  const c_b = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;

  return brightness > 155 ? '#000000' : '#ffffff';
};

export const isColorLight = (color: string) => {
  if (color.length > 7) {
    const opacity = color?.substring(7, 9);

    if (opacity && isLightOpacity(color.substring(7, 9))) {
      return true;
    }
  }

  const hex = color.replace('#', '');
  const colorRed = parseInt(hex.substring(0, 0 + 2), 16);
  const colorGreen = parseInt(hex.substring(2, 2 + 2), 16);
  const colorBlue = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = (colorRed * 299 + colorGreen * 587 + colorBlue * 114) / 1000;

  return brightness > 155;
};

export const getColorDependsOnBackground = (color: string) => (isColorLight(color) ? '#000000' : '#FFFFFF');

export const getRandomCollectionIconColor = () => {
  const randomIndex = Math.floor(Math.random() * COLLECTION_COLORS.length);

  return COLLECTION_COLORS[randomIndex];
};
