import classNames from 'classnames';
import type { FC, SyntheticEvent } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import type { Edge } from 'reactflow';

import { EDGE_STYLES } from '../../../../../helpers/colors';
import { useTypedSelector } from '../../../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../../../store/store';
import { Algorithm } from '../../EditableEdge/constants';
import styles from './EdgeStylePicker.module.css';

type EdgeStylePickerProps = {
  visible: boolean;
  setVisible: (value: any) => void;
  edge: Edge;
  items: EDGE_STYLES[];
};

const iconStyles: any = {
  [EDGE_STYLES.BEZIER]: (
    <svg
      style={{ transform: 'translateX(5px)' }}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
    >
      <path
        d="M1.92172 13C1.92172 13 0.182483 6.64467 3.76808 3.04074C7.35368 -0.563187 13.5 1.70354 13.5 1.70354"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  ),
  [EDGE_STYLES.SMOOTHSTEP]: (
    <svg
      style={{ transform: 'translateX(5px)' }}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path d="M1 15V1L15.0001 1.00006" stroke="#0F0F10" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  [EDGE_STYLES.EDITABLE]: (
    <svg
      style={{ transform: 'translateX(5px)' }}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="#000"
      className="bi bi-share-fill"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#000"
        d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5"
      />
    </svg>
  ),
  [EDGE_STYLES.EDITABLE_BEZIER]: (
    <svg
      style={{ transform: 'translateX(5px)' }}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="#000"
      className="bi bi-bezier2"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M1 2.5A1.5 1.5 0 0 1 2.5 1h1A1.5 1.5 0 0 1 5 2.5h4.134a1 1 0 1 1 0 1h-2.01q.269.27.484.605C8.246 5.097 8.5 6.459 8.5 8c0 1.993.257 3.092.713 3.7.356.476.895.721 1.787.784A1.5 1.5 0 0 1 12.5 11h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5H6.866a1 1 0 1 1 0-1h1.711a3 3 0 0 1-.165-.2C7.743 11.407 7.5 10.007 7.5 8c0-1.46-.246-2.597-.733-3.355-.39-.605-.952-1-1.767-1.112A1.5 1.5 0 0 1 3.5 5h-1A1.5 1.5 0 0 1 1 3.5zM2.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm10 10a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"
      />
    </svg>
  ),
};

const getEdgeIcon = (edge: Edge) => {
  if (edge?.type === EDGE_STYLES.EDITABLE) {
    if (edge.data?.algorithm === Algorithm.BezierCatmullRom) return iconStyles[EDGE_STYLES.EDITABLE_BEZIER];
  }

  return iconStyles[edge?.type || EDGE_STYLES.BEZIER];
};

export const EdgeStylePicker: FC<EdgeStylePickerProps> = ({ visible, setVisible, edge, items }) => {
  const dispatch = useDispatch<Dispatch>();
  const { edgesSharedState, defaultEdgeStyle } = useTypedSelector((state) => state.diagram);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleSelectStyle = (event: SyntheticEvent, st: EDGE_STYLES) => {
    event.preventDefault();
    event.stopPropagation();
    const foundEdge = edgesSharedState?.get(edge.id);

    if (foundEdge?.id) {
      const algorithm = st === EDGE_STYLES.EDITABLE ? Algorithm.Linear : Algorithm.BezierCatmullRom;
      edgesSharedState?.set(foundEdge.id, {
        ...foundEdge,
        type: st === EDGE_STYLES.EDITABLE_BEZIER ? EDGE_STYLES.EDITABLE : st,
        data: {
          ...foundEdge.data,
          algorithm,
        },
      });

      dispatch.diagram.setDefaultEdgeStyle({
        ...defaultEdgeStyle,
        data: {
          ...defaultEdgeStyle.data,
          algorithm,
        },
        type: st === EDGE_STYLES.EDITABLE_BEZIER ? EDGE_STYLES.EDITABLE : st,
      });
    }

    setVisible(false);
  };
  const edgeComponent: any = edgesSharedState?.get(edge.id);

  return (
    <div
      className={classNames(
        {
          [styles.activeDropDown]: visible,
        },
        styles.dropdown,
        'd-flex align-items-center justify-content-between rounded p-1 cursor-pointer position-relative z-1',
      )}
    >
      {showTooltip && !visible && <div className="main-tooltip">Type Picker</div>}
      <div
        onClick={() => setVisible(!visible)}
        className={classNames(styles.input, 'd-flex position-relative align-items-center')}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {getEdgeIcon(edgeComponent)}
      </div>
      {visible && (
        <div className={classNames(styles.dropdownItems, 'rounded d-flex')}>
          {items.map((st) => (
            <div
              key={st}
              className={classNames(styles.arrows, {
                [styles.activeItem]: (edgeComponent?.type || EDGE_STYLES.BEZIER) === st,
              })}
              onClick={(e) => handleSelectStyle(e, st)}
            >
              {iconStyles[st]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
