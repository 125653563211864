import { createModel } from '@rematch/core';
import { toast } from 'react-toastify';
import { CLI_THREAT_MODELS, REPRESENTATION_URL } from '../constants/api-constants';
import http from '../http/http-common';
import type { RootModel } from './index';

export const cliThreatModel = createModel<RootModel>()({
  state: {},
  reducers: {},
  effects: {
    async create(data) {
      try {
        const result: any = await http.post(`${CLI_THREAT_MODELS}/`, {
          projectTitle: data.projectTitle,
          projectDescription: data.projectDescription,
          projectId: data.project,
          title: data.title,
          description: data.description,
          representationId: data.representationId,
        });

        if (result.status === 201) {
          toast.success('CLI Threat Model created successfully!');

          return {
            collectionId: result?.data?.project?.id,
            representationId: result?.data?.representations?.at(0)?.id,
            status: result.status,
          };
        }

        if (result?.response?.status === 400) {
          toast.error(result?.response?.data?.message || 'Threat model not created!');
        }

        if (result?.response?.status === 502) {
          return {
            collectionId: '',
            representationId: '',
            status: result?.response?.status,
          };
        }
      } catch (error: any) {
        console.log(error);
      }
    },
    async getRepresentation(id: string) {
      try {
        const result = await http.get(`${REPRESENTATION_URL}/${id}`);

        if (result.status === 200) return result.data;
      } catch (error: any) {
        console.log(error);
      }
    },
  },
});
