import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { SKELETON_COLORS } from '../../../global/constants';
import type { SessionList } from '../../../global/types';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from '../../common/ManageSamlForm/ManageSamlForm.module.css';
import UiDropdown from '../../common/UiDropDown/UiDropDown';
import { getTitleByDuration } from './helpers';

export const ManageSessionDuration = () => {
  const dispatch = useDispatch<Dispatch>();
  const { activeSession, sessionList, isSessionLoading } = useTypedSelector((state) => ({
    activeSession: state.customer.activeSession,
    sessionList: state.customer.sessionList?.map(({ duration, id }) => ({
      id,
      title: getTitleByDuration(duration),
      duration,
    })),
    isSessionLoading: state.customer.isSessionLoading,
  }));

  const handleChange = async (value: SessionList) => {
    dispatch.customer.updateSessionDuration(value?.duration);
  };

  return (
    <div className="d-flex w-100 justify-content-between align-items-center">
      <div className={styles.title}>Session duration</div>
      <div style={{ width: 200 }}>
        {!isSessionLoading && sessionList ? (
          <UiDropdown
            disabled={isSessionLoading}
            value={sessionList.find(({ duration }) => duration === activeSession)}
            defaultValueTitle="-"
            options={sessionList}
            onChange={handleChange}
          />
        ) : (
          <Skeleton height={26} baseColor={SKELETON_COLORS.BASE} highlightColor={SKELETON_COLORS.HIGHLIGHT} />
        )}
      </div>
    </div>
  );
};
