import { ArrowClockwise } from 'react-bootstrap-icons';
import styles from '../../../common/Auth/styles/index.module.css';

interface IProps {
  email: string;
  handleResendCode: <T>(data: T) => Promise<void>;
}

const ResendCode = ({ email, handleResendCode }: IProps) => {
  return (
    <div className={[styles.footer, styles.footerResend].join(' ')}>
      <div className={styles.resendButton} onClick={() => handleResendCode({ email })}>
        Resend code <ArrowClockwise size={16} color="#7A94B0" />
      </div>
    </div>
  );
};

export default ResendCode;
