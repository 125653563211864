import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Plus, Search, XLg } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Spinner } from 'reactstrap';
import { MIN_SEARCH_LENGTH } from '../../../global/constants';
import { ATTRIBUTES_SEARCH_TYPE } from '../../../helpers/diagram';
import { useSelectedEntityType } from '../../../hooks/useSelectedEntityType';
import { UpdateTypes } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import { AISearchInput } from '../AISearchInput/AISearchInput';
import { AttributeSearchTypeButtons } from '../AISearchInput/AttributeSearchTypeButtons';
import { MitigationAttributesForm } from '../DrawnAttributes/MitigatingAttributes';
import styles from './index.module.css';

const SHOW_LEO = process.env?.REACT_APP_SHOW_LEO === 'true';

type DiagramNodeAttributeProps = {
  hidden?: boolean;
  data?: any;
  setData?: (val: any) => void;
};

let timer: any = null;

const DiagramNodeAttribute: FC<DiagramNodeAttributeProps> = ({ data, setData }) => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const selectedEntityType = useSelectedEntityType();
  const { popupAttributes, isSearchingAttributes, popupMitigatingAttributes } = useTypedSelector(
    (state) => state.diagram,
  );
  const selectedAttributes = useTypedSelector((state) => state.drawn?.component?.attributes) || [];
  const [search, setSearch] = useState('');
  const { emitUpdate } = useTypedSelector((state) => state.drawn);
  const currentThreatModel = useTypedSelector((state) => state.threatModel.currentThreatModel);
  const [selectedAttributeIndex, setSelectedAttributeIndex] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [searchType, setSearchType] = useState<ATTRIBUTES_SEARCH_TYPE>(ATTRIBUTES_SEARCH_TYPE.CODEX);
  const params = useParams();
  const { diagramId } = params;

  useEffect(() => {
    const edgeElement = document.querySelector(`div[data-edgeid='${data?.id}']`);

    if (edgeElement) edgeElement.classList.add(styles.activeEdge);

    return () => {
      if (edgeElement) edgeElement.classList.remove(styles.activeEdge);

      dispatch.diagram.setPopupAttributes([]);
    };
  }, []);

  useEffect(() => {
    setSearch('');
    dispatch.diagram.setPopupAttributes([]);
    dispatch.diagram.setMitigatingAttributes(null);
    dispatch.diagram.setPopupMitigatingAttributes(null);
  }, [searchType]);

  const handleEmitAfterUpdate = () => {
    if (diagramId && emitUpdate) {
      emitUpdate(UpdateTypes.COMPONENT, data.componentId, true);
    }

    if (currentThreatModel?.id && emitUpdate) {
      emitUpdate(UpdateTypes.REGISTER, currentThreatModel.id, true);
      emitUpdate(UpdateTypes.ELEMENT_REGISTER, currentThreatModel.id, true);
    }
  };

  const handleAttributeAdd = async () => {
    handleEmitAfterUpdate();
    await dispatch.threatModel.updateCurrentThreatModel();
  };

  const handleChange = (e: any) => {
    setSearch(e.target.value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      const { value } = e.target;

      if (value?.length && value.length >= MIN_SEARCH_LENGTH) {
        dispatch.diagram.getAttributes({
          search: value,
          selectedEntityType,
          isPopup: true,
        });
      } else {
        dispatch.diagram.setPopupAttributes([]);
      }
    }, 500);
  };

  const handleRemoveAttribute = async (attribute: any) => {
    dispatch.drawn.removeAttribute({
      attributeId: attribute.id,
      componentId: data.componentId,
    });
    navigate(`c/${data.componentId}`);
    await handleAttributeAdd();

    if (popupMitigatingAttributes) {
      dispatch.diagram.getMitigatingAttributes({ componentId: data.componentId, isPopup: true, selectedEntityType });
    }
  };

  const handleClearInput = (e: any) => {
    setSearch('');
    dispatch.diagram.setPopupAttributes([]);
  };

  const handleAddAttributeAdd = async ({ componentId, id, isCustom }: any) => {
    setDisabled(true);

    if (!disabled) {
      await dispatch.drawn.addAttribute({ componentId, id, isCustom });
      await dispatch.drawn.syncThreats(componentId);
      await handleAttributeAdd();
      setDisabled(false);
    }
  };

  const handleAddCustomAttribute = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (!search.length) return;

    dispatch.drawn.addCustomAttribute({
      componentId: data.componentId,
      title: search,
    });
    setSearch('');
    handleEmitAfterUpdate();
  };

  const foundAttributes =
    popupAttributes?.filter((aa) => !selectedAttributes?.map((ca) => ca?.title)?.includes(aa.title)) || [];

  const handleKeyDown = (event: any) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      let newIndex = event.shiftKey ? selectedAttributeIndex - 1 : selectedAttributeIndex + 1;

      if (newIndex < 0) newIndex = foundAttributes.length - 1;

      if (newIndex >= foundAttributes.length) newIndex = 0;

      setSelectedAttributeIndex(newIndex);
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      const selectedAttr = foundAttributes?.[selectedAttributeIndex];

      if (selectedAttr?.id) {
        handleAddAttributeAdd({
          componentId: data.componentId,
          id: selectedAttr.id,
          isCustom: selectedAttr.is_custom,
        });
        setSearch('');
        dispatch.diagram.setPopupAttributes([]);
        setSelectedAttributeIndex(0);
      }
    }
  };

  const renderFoundAttributes = (forAiSearch = false) => {
    const canAddCustom =
      search.length > 2 &&
      !selectedAttributes?.map((a: any) => a?.title?.toLocaleLowerCase())?.includes(search?.toLocaleLowerCase()) &&
      !isSearchingAttributes &&
      !forAiSearch;

    if (foundAttributes?.length)
      return (
        <div className={styles.attributesWrap}>
          {foundAttributes.map((attr, idx) => (
            <div
              key={attr.id}
              className={classNames('fs-12', styles.attributeItem, {
                [styles.selectedAttribute]: selectedAttributeIndex === idx,
                [styles.customAttribute]: attr?.is_custom,
              })}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleAddAttributeAdd({
                  id: attr.id,
                  componentId: data.componentId,
                  isCustom: attr.is_custom,
                });
              }}
            >
              {attr.title}
            </div>
          ))}
        </div>
      );

    if (canAddCustom)
      return (
        <div className={styles.attributesWrap}>
          <div
            className={classNames('fs-12', styles.attributeItem, styles.customAttributeItem)}
            onClick={handleAddCustomAttribute}
          >
            <Plus fontSize={16} color="#0062D8" />
            {`Add “${search}” as a new attribute`}
          </div>
          <div className={styles.noDataText}>No matches found</div>
        </div>
      );

    return null;
  };

  return (
    <div
      id="attributePopup"
      className={classNames(
        styles.nodeAttribute,
        'p-3 bg-white z-1 rounded shadow position-relative nowheel noScale nopan',
      )}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onKeyDown={handleKeyDown}
    >
      <div className="fs-6 fw-bold mb-2 noScale d-flex align-items-center justify-content-between">
        <span>Attributes</span>
        {SHOW_LEO && (
          <AttributeSearchTypeButtons searchType={searchType} setSearchType={setSearchType} showCloseBtn={false} />
        )}
      </div>

      <div className="d-flex align-items-center flex-wrap gap-1 mt-2 mb-2 fs-12">
        {selectedAttributes.map((attr) => (
          <div
            key={attr.id}
            className={classNames(
              'px-2 py-1 rounded d-flex justify-content-between gap-2 align-items-center cursor-pointer',
              {
                [styles.selectedAttributes]: !attr.is_custom,
                [styles.customAttribute]: attr.is_custom,
              },
            )}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if (data.componentId && attr.id) {
                navigate(`c/${data.componentId}/a/${attr.id}`);
              }
            }}
          >
            {attr.title}
            <XLg
              className="cursor-pointer"
              color="#6F767E"
              fontSize={14}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleRemoveAttribute(attr);
              }}
            />
          </div>
        ))}
      </div>

      {searchType === ATTRIBUTES_SEARCH_TYPE.CODEX && (
        <>
          <div className={classNames('d-flex align-items-center', styles.inputWrap)}>
            <Search fontSize={15} color="#6F767E" />
            <input
              type="text"
              className="form-control border-0 shadow-none fs-12 noScale"
              placeholder="Enter attributes"
              value={search}
              onChange={handleChange}
              autoFocus
              onFocus={() => dispatch.diagram.setCanCopy(false)}
              onBlur={() => dispatch.diagram.setCanCopy(true)}
              data-id="custom-diagram-input"
            />
            {!!search.length && !isSearchingAttributes && (
              <XLg size={15} className={classNames(styles.clearButton)} onClick={handleClearInput} />
            )}
            {isSearchingAttributes && (
              <div style={{ paddingTop: '2px', paddingRight: '10px' }}>
                <Spinner color="dark" size="sm" />
              </div>
            )}
          </div>
          {renderFoundAttributes()}
        </>
      )}

      {searchType === ATTRIBUTES_SEARCH_TYPE.LEO && (
        <>
          <AISearchInput search={search} setSearch={setSearch} forDrawer={false} />
          {renderFoundAttributes(true)}
        </>
      )}

      {searchType === ATTRIBUTES_SEARCH_TYPE.MITIGATIN && (
        <MitigationAttributesForm
          isPopup
          mitigatingAttributes={popupMitigatingAttributes}
          componentId={data.componentId}
          onAttributeAdd={handleAttributeAdd}
        />
      )}
    </div>
  );
};

export default DiagramNodeAttribute;
