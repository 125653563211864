import { createModel } from '@rematch/core';
import { toast } from 'react-toastify';
import type { PaginationParams, Running } from '../../global/types';
import { RUNNINGS } from '../constants/api-constants';
import { DEFAULT_RUNNING_PAGINATION_PARAMS } from '../constants/query-constants';
import http from '../http/http-common';
import type { RootModel } from './index';

type RunningState = {
  runningList: Running[];
  runningListQuery: PaginationParams;
};

export const running = createModel<RootModel>()({
  state: {
    runningList: [],
    runningListQuery: DEFAULT_RUNNING_PAGINATION_PARAMS,
  } as RunningState,
  reducers: {
    setRunningList(state, runningList) {
      return {
        ...state,
        runningList,
      };
    },
    setQuery(state, runningListQuery) {
      return { ...state, runningListQuery };
    },
  },
  effects: (dispatch) => {
    return {
      async getRunningListByProjectId(query) {
        try {
          const { limit, page, sort, order, projectId } = query;
          const nextPage = limit * page;
          const response = await http(
            `${RUNNINGS}/${projectId}?limit=${limit}&page=${nextPage}&sort=${sort}&order=${order}`,
          );

          if (response.data?.items) {
            dispatch.running.setRunningList(response.data.items);
          }

          dispatch.running.setQuery({
            ...query,
            count: response?.data?.count || 0,
          });
        } catch (e: any) {
          toast.warning(e?.message || '');
        }
      },
    };
  },
});
