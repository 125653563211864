import classNames from 'classnames';
import type { FC } from 'react';
import type { ThreatModelPriorityType } from '../../../global/types';
import { ThreatModelPriorityTypes } from '../../../global/types';
import styles from './UiPriority.module.css';

type UiStatusProps = {
  type?: ThreatModelPriorityType;
  onClick?: (event: any) => void;
  label?: string;
  width?: number;
  noPointer?: boolean;
  size?: 'small' | 'smallItem' | 'smallItemNoAbsolute';
};

const UiPriority: FC<UiStatusProps> = ({
  type = ThreatModelPriorityTypes.MEDIUM,
  onClick,
  label,
  width,
  noPointer = false,
  size,
}) => {
  const statusClasses = classNames({
    [`${styles.critical}`]: type === ThreatModelPriorityTypes.CRITICAL,
    [`${styles.medium}`]: type === ThreatModelPriorityTypes.MEDIUM,
    [`${styles.low}`]: type === ThreatModelPriorityTypes.LOW,
    [`${styles.high}`]: type === ThreatModelPriorityTypes.HIGH,
  });

  return (
    <div
      style={{
        width: width || '',
        cursor: noPointer ? 'unset' : 'pointer',
      }}
      onClick={onClick}
      className={classNames(statusClasses, styles.common, {
        [styles.small]: size === 'small',
        [styles.smallItem]: size === 'smallItem',
        [styles.smallItemNoAbsolute]: size === 'smallItemNoAbsolute',
      })}
    >
      <p>{label || type?.charAt(0).toUpperCase() + type?.slice(1)}</p>
    </div>
  );
};

export default UiPriority;
