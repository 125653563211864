import styles from './Space.module.css';

type Props = {
  children?: React.ReactNode;
};

/**
 * Wraps children in flexbox container with items centered alignment
 */
const Space = ({ children }: Props) => {
  return <div className={styles.Space}>{children}</div>;
};

export default Space;
