import { useFormik } from 'formik';
import type { FC } from 'react';
import { useState } from 'react';
import { ArrowLeftShort } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import * as Yup from 'yup';
import { getId } from '../../../helpers/user';
import { useSocket } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import UiButton from '../../common/UIButton/UiButton';
import UiInput from '../../common/UiInput/UiInput';
import User from '../../User/User';
import styles from './ProjectCreateOrUpdate.module.css';

type ProjectCreateOrUpdateProps = {
  mode: 'CREATE' | 'UPDATE';
};

const ProjectCreateOrUpdate: FC<ProjectCreateOrUpdateProps> = ({ mode = 'CREATE' }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [canSave, setCanSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const { emitGlobalUpdate } = useSocket();

  const handleBackClick = () => {
    navigate(-1);
  };

  const { current: currentProject } = useTypedSelector((state) => state.project);
  const user = useTypedSelector((state) => state.user?.current);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: currentProject?.title || '',
      description: currentProject?.description || '',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .min(1, 'Minimum 1 characters required')
        .max(40, 'Maximum 40 characters are allowed')
        .required('Required'),
      description: Yup.string().max(500, 'Maximum 500 characters are allowed'),
    }),
    onSubmit: async (values) => {
      const id = getId();

      if (mode === 'CREATE') {
        await dispatch.project.createProject({
          id,
          title: values.title,
          description: values.description,
        });
        emitGlobalUpdate({ projects: [id] });
        navigate(`/collections/${id}`);
      }

      if (mode === 'UPDATE' && currentProject) {
        dispatch.project.updateProject({
          id: currentProject.id,
          title: values.title,
          description: values.description,
        });
        emitGlobalUpdate({ projects: [currentProject.id] });
        navigate(`/collections/${currentProject.id}`);
      }

      formik.values.title = '';
      formik.values.description = '';
    },
  });

  const handleCreate = async () => {
    if (canSave) {
      setCanSave(false);
      setLoading(true);
      await formik.submitForm();
      setLoading(false);
    }
  };

  const userData = mode === 'CREATE' ? user : currentProject?.created_by;

  return (
    <div className={`d-flex flex-column ${styles.container}`}>
      <div className={`${styles.header}`}>{mode === 'CREATE' ? 'Create a new Collection' : 'Edit collection'}</div>
      <div className="d-flex justify-content-between">
        <div onClick={handleBackClick} className={styles.backButton}>
          <div>
            <ArrowLeftShort fontSize={24} className={`${styles.backIcon}`} />
          </div>
          <span className={styles.back}>Back</span>
        </div>
        <div className="d-flex" style={{ width: '240px' }}>
          <UiButton type="transparent" onClick={handleBackClick}>
            Cancel
          </UiButton>
          <UiButton
            disabled={!canSave || !!formik.errors.title?.length || !!formik.errors.description?.length}
            htmlType="submit"
            onClick={handleCreate}
            fullWidth
          >
            {loading ? <Spinner size="sm" className={styles.spinner} /> : mode === 'CREATE' ? 'Create' : 'Update'}
          </UiButton>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.content}>
          <div className={styles.left}>
            <div>
              <div className={styles.contentHeader}>Add name & description</div>
              <form className={styles.form}>
                <div className={styles.formGroup}>
                  <UiInput
                    name="title"
                    label="* Collection Name"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Collection Name"
                    showError={formik.errors.title}
                    errorText={formik.errors.title}
                  />
                </div>
                <div className={styles.formGroup}>
                  <UiInput
                    type="textarea"
                    textAreaRows={7}
                    name="description"
                    label="Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="This collection should include all possible solutions to the problems of our universe."
                    showError={formik.errors.description}
                    errorText={formik.errors.description}
                  />
                </div>
              </form>
            </div>
            <div className={styles.leftFooter}>
              <div>{userData?.first_name && <User user={userData} noHover />}</div>
              <div className={styles.owner}>Owner</div>
            </div>
          </div>
          {/* <div className={styles.right}> */}
          {/*  <div className={styles.contentHeader}> */}
          {/*    Add users to the Collection */}
          {/*  </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProjectCreateOrUpdate;
