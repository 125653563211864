import classNames from 'classnames';
import type { FC, SyntheticEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Download, XLg } from 'react-bootstrap-icons';
import { useDropzone } from 'react-dropzone';
import { Input, Label } from 'reactstrap';
import { getReadableFileSizeString } from '../../../helpers/app';
import styles from './ManageSamlForm.module.css';

type MetadataFieldProps = {
  metadataFile?: any;
  metadataUrl?: string;
  metadataFileError?: string;
  metadataUrlError?: string;
  touchField: (field: string) => void;
  isError: (fieldName: any) => boolean;
  setFieldValue: (field: string, value: any) => void;
};

export const MetadataField: FC<MetadataFieldProps> = ({
  isError,
  metadataFile,
  touchField,
  metadataUrl,
  metadataFileError,
  setFieldValue,
  metadataUrlError,
}) => {
  const [metadataType, setMetadataType] = useState<'url' | 'file' | ''>('');

  useEffect(() => {
    if (metadataType.length) return;

    if (metadataUrl?.length) setMetadataType('url');

    if (metadataFile) setMetadataType('file');
  }, [metadataUrl, metadataFile]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles.at(0);

    if (file) setFieldValue('metadataFile', file);
  }, []);

  const { getRootProps, getInputProps, inputRef, open, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    accept: { 'application/xml': ['.xml'] },
  });

  const handleDeleteCurrentFile = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setFieldValue('metadataFile', null);
    touchField('metadataFile');

    if (inputRef?.current?.value) {
      inputRef.current.value = '';
    }
  };

  const handleDownloadCurrentFile = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (metadataFile) {
      const url = window.URL.createObjectURL(metadataFile);
      const link = document.createElement('a');
      link.setAttribute('download', metadataFile.name);
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  return (
    <div className="mt-2">
      <div className="d-flex fs-14">
        <label className={`${styles.importantField} form-label me-2`} htmlFor="metadataUrl">
          Metadata
        </label>
        <Input
          checked={metadataType === 'url'}
          id="url"
          type="radio"
          name="metadata"
          className="me-2"
          onChange={(e) => {
            if (e.target.checked) {
              setMetadataType('url');
              setFieldValue('metadataFile', null);
            }
          }}
        />
        <Label htmlFor="url" check className="cursor-pointer user-select-none">
          Url
        </Label>
        <Input
          checked={metadataType === 'file'}
          id="file"
          type="radio"
          name="metadata"
          className="ms-3 me-2"
          onChange={(e) => {
            if (e.target.checked) {
              setMetadataType('file');
              setFieldValue('metadataUrl', '');
            }
          }}
        />
        <Label htmlFor="file" check className="cursor-pointer user-select-none">
          File
        </Label>
      </div>

      {metadataType === 'url' && (
        <>
          <input
            type="text"
            id="metadataUrl"
            name="metadataUrl"
            value={metadataUrl}
            placeholder="https://"
            className={`${isError('metadataUrl') ? styles.fieldError : ''} form-control fs-14`}
            onChange={(event) => {
              setFieldValue('metadataUrl', event.target.value);
              touchField('metadataUrl');
            }}
          />
          {isError('metadataUrl') && (
            <div className={`${styles.errorLabel} fs-10 position-absolute`}>{metadataUrlError}</div>
          )}
        </>
      )}

      {metadataType === 'file' && (
        <>
          <div
            className={classNames(styles.uploadWrap, styles.uploadWrapXml, {
              [styles.isDragActive]: isDragActive || metadataFile,
            })}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {metadataFile && (
              <div className="d-flex align-items-center justify-content-between w-100 px-2">
                <div className="d-flex align-items-center gap-2">
                  <div className={styles.uploadFileNameXml}>{metadataFile.name}</div>
                  <div className={styles.uplaodFileSize}>{getReadableFileSizeString(metadataFile.size)}</div>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <div className={styles.deleteFileButtonXml} onClick={handleDownloadCurrentFile}>
                    <Download color="green" size={14} />
                  </div>
                  <div className={styles.deleteFileButtonXml} onClick={handleDeleteCurrentFile}>
                    <XLg color="red" size={14} />
                  </div>
                </div>
              </div>
            )}
            {!metadataFile && (
              <div className="text-center fs-12">
                <div className={styles.uploadWrapTextXml}>
                  Drop file here or <span onClick={() => open()}>Choose file</span>
                </div>
              </div>
            )}
          </div>
          {isError('metadataFile') && (
            <div className={`${styles.errorLabel} fs-10 position-absolute`}>{metadataFileError}</div>
          )}
        </>
      )}
    </div>
  );
};
