import type { FC } from 'react';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import type { Edge, Node } from 'reactflow';
import { ConnectionMode, Panel, ReactFlow, useReactFlow } from 'reactflow';
import { Spinner } from 'reactstrap';

import { NODES } from '../../../global/constants';
import { DIAGRAM_ZOOM_VALUES, getNodeCoords } from '../../../helpers/diagram';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { THREATS_REGISTER_ROUTE } from '../../../store/constants/route-constants';
import { THREATS_REGISTER_SELECTED_CLASS } from '../../../store/constants/threat-register-constants';
import type { Dispatch } from '../../../store/store';
import ZoomButtons from '../../Diagram/DiagramTools/ZoomButtons';
import CustomEdge from '../../Diagram/edges/CustomEdge';
import SmoothStepEdge from '../../Diagram/edges/SmoothstepEdge';
import DataStoreNode from '../../Diagram/nodes/DataStoreNode';
import DefaultNode from '../../Diagram/nodes/DefaultNode';
import ExternalEntityNode from '../../Diagram/nodes/ExternalEntityNode';
import ProcessNode from '../../Diagram/nodes/ProcessNode';
import StickerNode from '../../Diagram/nodes/StickerNode';
import TrustBoundaryNode from '../../Diagram/nodes/TrustBoundaryNode';
import styles from './ThreatRegisterMap.module.css';

const nodeTypes = {
  externalEntityNode: ExternalEntityNode,
  datastoreNode: DataStoreNode,
  processNode: ProcessNode,
  trustBoundaryNode: TrustBoundaryNode,
  stickerNode: StickerNode,
  defaultNode: DefaultNode,
};

const edgeTypes = {
  default: CustomEdge,
  smoothstep: SmoothStepEdge,
};

export const ThreatRegisterMap: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const params = useParams();
  const { setCenter, fitView, viewportInitialized } = useReactFlow();
  const { threatRegisterRepresentation } = useTypedSelector((state) => state.representation);
  const threat = useTypedSelector((state) => state.drawn.threat);
  const drawerVisible = useTypedSelector((state) => state.drawn.visible);
  const { threatRegisterList, subThreatRegisterList } = useTypedSelector((state) => state.threats);
  const { nodesSharedState, edgesSharedState } = useTypedSelector((state) => state.diagram);
  const [loading, setLoading] = useState(false);

  const deselectElements = () => {
    document.querySelectorAll(`.${THREATS_REGISTER_SELECTED_CLASS}`).forEach((el) => {
      if (el.classList.contains(THREATS_REGISTER_SELECTED_CLASS)) {
        el.classList.remove(THREATS_REGISTER_SELECTED_CLASS);
      }
    });
  };

  const selectNode = (node: HTMLDivElement) => {
    const coords = getNodeCoords(node.style.transform);

    if (coords) {
      setCenter(coords.x + node.clientWidth / 2, coords.y + node.clientHeight / 2 + 20, {
        duration: 50,
      });
      node.classList.add(THREATS_REGISTER_SELECTED_CLASS);
    }
  };

  const selectEdge = (id: string) => {
    const edge: any = document.querySelector(`[data-edgeid="${id}"]`);

    if (edge?.dataset?.edgex && edge?.dataset?.edgey) {
      setCenter(edge.dataset.edgex, edge.dataset.edgey + 20);
      edge.firstChild.classList.add(THREATS_REGISTER_SELECTED_CLASS);
    }
  };

  useEffect(() => {
    if (!threatRegisterRepresentation && nodesSharedState && edgesSharedState) {
      dispatch.representation.setThreatRegisterRepresentation({
        threatId: '',
        data: {
          nodes: Array.from(nodesSharedState.values() || []),
          edges: Array.from(edgesSharedState.values() || []),
        },
      });
    }

    return () => {
      deselectElements();
      dispatch.representation.setThreatRegisterRepresentation(null);
    };
  }, [nodesSharedState, edgesSharedState]);

  useEffect(() => {
    if (drawerVisible) {
      const interval = setInterval(() => {
        fitView();
        clearInterval(interval);
      }, 200);
    }
  }, [drawerVisible]);

  useEffect(() => {
    const currentThreatId = params?.['*']?.split('/')?.at(-1);

    if (
      loading ||
      !drawerVisible ||
      !currentThreatId ||
      currentThreatId === 'element' ||
      threatRegisterRepresentation?.threatId === currentThreatId
    )
      return;

    if (currentThreatId) {
      setLoading(true);
      dispatch.representation.getRepresentationByThreat(currentThreatId).then(() => {
        setLoading(false);
      });
    }
  }, [params]);

  useLayoutEffect(() => {
    if (viewportInitialized) {
      const threatId = params?.threatsId;
      let componentId = '';

      if (window.location.pathname.includes(`${THREATS_REGISTER_ROUTE}/element`)) {
        componentId = subThreatRegisterList?.find((i) => i.id === threatId)?.component?.id;
      } else {
        componentId = threatRegisterList?.find((i) => i.id === threatId)?.component?.id;
      }

      if (!componentId) return;

      const timeout = setTimeout(() => {
        deselectElements();
        const node: any = document.querySelector(`[data-id="${componentId}"]`);

        if (node?.style?.transform) selectNode(node);
        else selectEdge(componentId);

        clearTimeout(timeout);
      }, 200);
    }
  }, [params, viewportInitialized]);

  useEffect(() => {
    if (!drawerVisible) return;

    if (!threat?.id?.length) {
      deselectElements();
    } else if (threat?.component?.id) {
      dispatch.drawn.getComponent({ id: threat.component.id });
    }
  }, [threat]);

  const deselectComponents = (items: any) => {
    return items
      .map((i: Node | Edge) => {
        i.selected = false;

        return i;
      })
      .filter((i: Node | Edge) => i.type !== NODES.STICKER_NODE);
  };

  return loading ? (
    <div className="d-flex align-items-center justify-content-center" style={{ height: 'calc(100vh - 70px)' }}>
      <Spinner />
    </div>
  ) : (
    <div className="container-fluid reactflow-wrapper row m-0 p-0 h-100">
      <ReactFlow
        id="threatRegisterDiagram"
        connectionRadius={45}
        connectionMode={ConnectionMode.Loose}
        nodes={deselectComponents(threatRegisterRepresentation?.data?.nodes || [])}
        edges={deselectComponents(threatRegisterRepresentation?.data?.edges || [])}
        proOptions={{ hideAttribution: true }}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        panOnScroll
        minZoom={DIAGRAM_ZOOM_VALUES.MIN}
        maxZoom={DIAGRAM_ZOOM_VALUES.MAX}
        defaultMarkerColor="#6F767E"
        elementsSelectable={false}
        edgesFocusable={false}
        nodesFocusable={false}
        nodesConnectable={false}
        nodesDraggable={false}
        className={styles.threatRegisterDiagram}
        onNodeClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onEdgeClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onPaneClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        fitView
      >
        <Panel position="bottom-right" style={{ zIndex: '100001', marginRight: '75px' }}>
          <ZoomButtons noMargin isThreatsRegister />
        </Panel>
      </ReactFlow>
    </div>
  );
};
