import { useFormik } from 'formik';
import type { Dispatch, SetStateAction } from 'react';
import * as Yup from 'yup';

import { Spinner } from 'reactstrap';
import styles from '../form.module.css';

import { passwordLengthLimits } from '../../../global/auth';
import { passwordStrengthMeter } from '../../../helpers/password-strength-meter';
import CheckBoxForAuthSession from '../../common/Auth/CheckBoxForAuthSession';
import UiButton from '../../common/UIButton/UiButton';
import UiInput from '../../common/UiInput/UiInput';

interface IProps {
  title: string;
  email: string;
  isLoading?: boolean;
  isFirstPassword: boolean;
  needCheckboxAuthSession?: boolean;
  handleSubmit?: <T>(data: T) => Promise<void>;
  setPassword?: Dispatch<SetStateAction<string>>;
}

const PasswordForm = ({
  title,
  email,
  isLoading,
  isFirstPassword,
  needCheckboxAuthSession,
  setPassword,
  handleSubmit,
}: IProps) => {
  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(passwordLengthLimits.min, `Min password length is ${passwordLengthLimits.min} characters`)
        .max(passwordLengthLimits.max, `Max password length is ${passwordLengthLimits.max} characters`)
        .test('test-password-strength', 'Password too weak', (value) => {
          if (!isFirstPassword) return true;

          return passwordStrengthMeter(value || '').score > 0;
        })
        .required('Required'),
    }),
    onSubmit: (values) => {
      if (!handleSubmit) return;

      handleSubmit({ email, password: values.password });
    },
  });

  const onChange = (e: any) => {
    if (setPassword) {
      setPassword(e.target.value);
    }

    formik.handleChange(e);
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <div className={styles.formGroup}>
        <UiInput
          label={title}
          placeholder="Enter your password"
          name="password"
          value={formik.values.password}
          onChange={onChange}
          onBlur={formik.handleBlur}
          type="password"
          errorText={formik.errors.password}
          showError={formik.touched.password && formik.errors.password}
        />
      </div>
      {needCheckboxAuthSession && <CheckBoxForAuthSession />}
      {!!handleSubmit && (
        <div>
          <UiButton htmlType="submit" disabled={!formik.isValid} fullWidth className="fw-bold">
            {isLoading ? <Spinner color="light" size="sm" /> : 'Continue'}
          </UiButton>
        </div>
      )}
    </form>
  );
};

export default PasswordForm;
