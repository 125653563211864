import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Plus, PlusLg, X } from 'react-bootstrap-icons';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Spinner } from 'reactstrap';

import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { PROJECTS_ROUTE, THREATS_REGISTER_ROUTE } from '../../../store/constants/route-constants';
import type { IThreat } from '../../../store/models/drawn';
import type { Dispatch } from '../../../store/store';
import styles from './ThreatRegisterMitigationSearch.module.css';

let timer: any = null;

type IMitigationSearchProps = {
  threat: IThreat;
  isThreatRegister?: boolean;
  isElementRegister?: boolean;
  width?: number;
  hideIcon?: boolean;
  handleEmitAfterUpdate: (threatId: string, mitigationId: string) => void;
};

export const ThreatRegisterMitigationSearch: FC<IMitigationSearchProps> = ({
  width,
  threat,
  hideIcon = false,
  isThreatRegister = false,
  isElementRegister = false,
  handleEmitAfterUpdate,
}) => {
  const params = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const [mitigationInput, setMitigationInput] = useState(false);
  const [mitigationLoading, setMitigationLoading] = useState(false);
  const { newMitigation } = useTypedSelector((state) => state.drawn);
  const mitigations = useTypedSelector((state) => state.diagram?.mitigations) || [];

  useEffect(() => {
    if (!mitigationInput) {
      dispatch.diagram.setMitigations([]);
      dispatch.drawn.setNewMitigation({ ...newMitigation, title: '' });
    }
  }, [mitigationInput]);

  const handleChangeSearchInput = (e: any) => {
    const title = e.target.value;
    dispatch.drawn.setNewMitigation({ ...newMitigation, title });
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (e.target.value.length) {
        setMitigationLoading(true);
        dispatch.mitigations.searchMitigationsByTitle(e.target.value).then(() => {
          setMitigationLoading(false);
        });
      }
    }, 500);
  };

  const handleAddMitigationToThreat = async (item: any) => {
    if (!item?.id || !threat.component) return;

    await dispatch.mitigations.addMitigationToThreat({
      threatId: threat.id,
      mitigationId: item.id,
      componentId: threat.component.id,
    });
    dispatch.drawn.getThreat(threat.id);

    handleEmitAfterUpdate(threat.id, item.id);
  };

  if (!mitigationInput)
    return (
      <div className="">
        <button
          type="button"
          className={classNames('btn btn-outline-primary border-0', styles.addMitigationButton)}
          onClick={(event: any) => {
            event.preventDefault();
            event.stopPropagation();
            setMitigationInput(true);
          }}
        >
          {hideIcon ? 'Choose mitigation...' : <PlusLg />}
        </button>
      </div>
    );

  return (
    <div className={styles.inputWrap} style={{ width: width ? `${width}px` : '100%' }}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setMitigationInput(false);
          dispatch.drawn.clearNewMitigation();
        }}
      >
        {mitigationLoading ? (
          <Spinner
            size="sm"
            className={classNames('position-absolute end-0 cursor-pointer', styles.inputWrapClearButton)}
          />
        ) : (
          <X
            className={classNames('position-absolute end-0 cursor-pointer', styles.inputWrapClearButton)}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setMitigationInput(false);
              dispatch.drawn.clearNewMitigation();
            }}
          />
        )}
        <input
          type="text"
          value={newMitigation?.title || ''}
          className="form-control shadow-none pe-4"
          placeholder="Enter mitigations"
          onChange={handleChangeSearchInput}
          autoFocus
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onFocus={() => dispatch.diagram.setCanCopy(false)}
          onBlur={() => dispatch.diagram.setCanCopy(true)}
        />
        {!mitigations?.length && !!newMitigation?.title?.length && (
          <div className={classNames('d-flex flex-wrap gap-1 p-1 bg-white', styles.attributesWrap)}>
            {!mitigations?.length && !!newMitigation?.title?.length && (
              <div>
                <div
                  key={0}
                  className={classNames('fs-12 text-primary', styles.attributeItem, styles.addItem)}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (pathname.includes(`${THREATS_REGISTER_ROUTE}/`)) {
                      dispatch.drawn.clearMitigation();
                    }

                    if (isElementRegister) {
                      navigate(
                        `${PROJECTS_ROUTE}/${params.id}/d/${params.diagramId}${THREATS_REGISTER_ROUTE}/element/${threat.id}/m/new`,
                      );
                    } else {
                      navigate(
                        `${PROJECTS_ROUTE}/${params.id}/d/${params.diagramId}${THREATS_REGISTER_ROUTE}/${threat.id}/m/new`,
                      );
                    }

                    setMitigationInput(false);
                  }}
                >
                  <Plus fontSize={16} color="#4285F4" />
                  {`Add “${newMitigation.title}” as a new mitigation`}
                </div>
                <div className="fs-12 text-secondary">No matches found</div>
              </div>
            )}
          </div>
        )}
        {mitigations?.length ? (
          <div className={classNames('d-flex flex-wrap gap-1 p-1 bg-white', styles.attributesWrap)}>
            {mitigations?.map((mitigation: any) => (
              <div
                hidden={threat?.mitigations?.map((i) => i.id)?.includes(mitigation.id)}
                key={mitigation.id}
                className={classNames('fs-12', styles.attributeItem)}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleAddMitigationToThreat(mitigation);
                  setMitigationInput(false);
                }}
              >
                {mitigation.title}
              </div>
            ))}
          </div>
        ) : null}
      </OutsideClickHandler>
    </div>
  );
};
