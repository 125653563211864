import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import OutsideClickHandler from 'react-outside-click-handler';
import { Spinner } from 'reactstrap';
import styles from './UiDropDown.module.css';

type UiDropdownProps = {
  value?: any;
  label?: string;
  options: { id: string | number; title: string }[];
  onChange?: any;
  disabled?: boolean;
  defaultValueTitle?: string;
  infinityScroll?: {
    fetchMoreData: () => void;
    canFetchMore: boolean;
    loading: boolean;
  };
};
const UiDropdown: FC<UiDropdownProps> = ({
  label,
  options,
  value,
  onChange,
  disabled = false,
  defaultValueTitle,
  infinityScroll,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div
        className={classNames(
          'd-flex align-items-center gap-2 position-relative user-select-none',
          styles.threatsDetailDropdown,
        )}
      >
        {label && <span className="w-25 fw-bold">{label}</span>}
        <div
          className={classNames('w-100 text-capitalize', styles.threatsDetailDropdownInput, { [styles.active]: open })}
          onClick={() => !disabled && setOpen(!open)}
        >
          {value?.title || defaultValueTitle || '-'}
          {open ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
          {open && (
            <div id="dropdown-wrap" className={classNames('position-absolute', styles.threatsDetailDropdownOptions)}>
              <InfiniteScroll
                scrollableTarget="dropdown-wrap"
                next={infinityScroll?.fetchMoreData ? infinityScroll.fetchMoreData : () => {}}
                hasMore={infinityScroll?.canFetchMore || false}
                loader={
                  infinityScroll?.loading ? (
                    <div className="w-100 m-auto text-center">
                      <Spinner color="dark" />
                    </div>
                  ) : null
                }
                dataLength={options?.length || 0}
              >
                {open &&
                  options
                    .filter((i) => i.id !== value?.id)
                    .map((o: any, idx: number) => (
                      <div
                        key={o + idx}
                        className={classNames('text-capitalize', styles.option)}
                        onClick={() => onChange && onChange(o)}
                        style={{
                          color: o.id === 'create_new' ? '#0062D8' : 'black',
                        }}
                      >
                        {o.title}
                      </div>
                    ))}
              </InfiniteScroll>
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default UiDropdown;
