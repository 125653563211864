import { createModel } from '@rematch/core';
import {
  getAppLeftSidebarVisible,
  getViewportFromLocalStorage,
  setAppLeftSidebarVisible,
  setViewportToLocalStorage,
} from '../../helpers/diagram';
import type { RootModel } from './index';

type AppState = {
  sidebarVisible: boolean;
  viewport: { x: number; y: number; zoom: number };
  fullscreenMode: boolean;
};

export const app = createModel<RootModel>()({
  state: {
    sidebarVisible: getAppLeftSidebarVisible(),
    viewport: getViewportFromLocalStorage(),
    fullscreenMode: false,
  } as unknown as AppState,
  reducers: {
    setSidebarVisible(state, isVisible) {
      setAppLeftSidebarVisible(isVisible);

      return {
        ...state,
        sidebarVisible: isVisible,
      };
    },
    setViewport(state, viewport) {
      setViewportToLocalStorage(viewport);

      return {
        ...state,
        viewport,
      };
    },
    setFullScreenMode(state, fullscreenMode) {
      return {
        ...state,
        fullscreenMode,
      };
    },
  },
});
