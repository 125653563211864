import classNames from 'classnames';
import type { ChangeEvent, FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { ArrowLeft, Check2, Clipboard2Check, ExclamationTriangle } from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import MitigationItem from '../MitigationItem';
import ThreatDropdown from '../ThreatDropdown';
import styles from './index.module.css';

import { SKELETON_COLORS } from '../../../global/constants';
import type { EmitUpdate } from '../../../hooks/useSocket';
import { UpdateTypes } from '../../../hooks/useSocket';
import { THREAT_PRIORITY, THREAT_STATUS } from '../../../store/constants/drawn-constants';
import CommentInput from '../../Comments/CommentInput/CommentInput';
import CommentList from '../../Comments/CommentList/CommentList';
import UiInput from '../../common/UiInput/UiInput';
import { MitigationSearch } from '../../Mitigations/MitigationSearch/MitigationSearch';
import { ThreatHistory } from '../ThreatHistory/ThreatHistory';

let timer: any = null;

type ThreatsDetailFormProps = {
  emitUpdate?: EmitUpdate;
};

const ThreatsDetailForm: FC<ThreatsDetailFormProps> = ({ emitUpdate }) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const nodeTitle = useTypedSelector(({ drawn }) => drawn?.component?.title) || '';
  const threat = useTypedSelector((state) => state.drawn?.threat);
  const currentThreatModelId = useTypedSelector((state) => state.threatModel?.currentThreatModel?.id);
  const commentData = useTypedSelector((state) => state.drawn?.commentData);
  const commentLimit = useTypedSelector((state) => state.drawn?.commentLimit);
  const isCommentInputActive = useTypedSelector((state) => state.drawn?.isCommentInputActive);
  const mitigations = useTypedSelector((state) => state.diagram?.mitigations) || [];
  const { newMitigation } = useTypedSelector((state) => state.drawn);
  const fullscreenMode = useTypedSelector((state) => state.app.fullscreenMode);
  const [wereCommentsUpdated, setWereCommentsUpdated] = useState(false);
  const [contentHeight, setContentHeight] = useState(`${window.innerHeight - 59}px`);
  const { forceUpdateThreatId, forceUpdateForCommentsByThreatId, forceRemoveThreatId } = useTypedSelector(
    (state) => state.drawn,
  );

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const { componentId, threatsId } = useParams();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!threat.mitigations) {
      dispatch.mitigations.getMitigationsForThreat(threatsId);
    }
  }, [threat.mitigations]);

  useEffect(() => {
    dispatch.drawn.clearNewMitigation();

    return () => {
      dispatch.drawn.clearCommentData();
      window.onkeydown = null;
    };
  }, []);

  useEffect(() => {
    if (wereCommentsUpdated) {
      setWereCommentsUpdated(false);
    }
  }, [wereCommentsUpdated]);

  useEffect(() => {
    if (threatsId && forceUpdateThreatId && threatsId === forceUpdateThreatId) {
      dispatch.drawn.getThreat(threatsId);
      dispatch.drawn.setForceUpdateThreatId(null);
    }
  }, [forceUpdateThreatId]);

  useEffect(() => {
    if (threatsId === forceRemoveThreatId) {
      toast.error('Threat deleted!');
      handleBack();
      dispatch.drawn.setForceRemoveThreatId(null);
    }
  }, [forceRemoveThreatId]);

  useEffect(() => {
    if (threatsId && forceUpdateForCommentsByThreatId && threatsId === forceUpdateForCommentsByThreatId) {
      dispatch.drawn.getCommentsByThreatId({
        threatId: params.threatsId,
        limit: commentLimit + 1,
      });
      dispatch.drawn.setForceUpdateForCommentsByThreatId(null);
      setWereCommentsUpdated(true);
    }
  }, [forceUpdateForCommentsByThreatId]);

  const handleEmitAfterUpdate = (needComponentUpdate = true) => {
    if (threatsId && emitUpdate) {
      emitUpdate(UpdateTypes.THREAT, threatsId, true);

      if (componentId && needComponentUpdate) {
        emitUpdate(UpdateTypes.COMPONENT, componentId, true);
      }
    }

    if (currentThreatModelId && emitUpdate) {
      emitUpdate(UpdateTypes.REGISTER, currentThreatModelId, true);
      emitUpdate(UpdateTypes.ELEMENT_REGISTER, currentThreatModelId, true);
    }
  };

  const scrollPoint = useRef(null);

  const executeScroll = () => {
    setTimeout(() => {
      if (scrollPoint?.current) {
        // @ts-ignore
        scrollPoint?.current?.scrollIntoView({
          inline: 'center',
          behavior: 'smooth',
        });
      }
    }, 777);
  };

  useEffect(() => {
    if (params.threatsId) {
      dispatch.drawn.getThreat(params.threatsId);
      dispatch.drawn.getCommentsByThreatId({
        threatId: params.threatsId,
        limit: commentLimit,
      });
    }

    return () => {
      dispatch.drawn.clearPrevTreatState();
      dispatch.diagram.setMitigations(null);
    };
  }, []);

  useEffect(() => {
    const height = isCommentInputActive ? `${window.innerHeight - 134}px` : `${window.innerHeight - 59}px`;
    setContentHeight(height);
  }, [isCommentInputActive, fullscreenMode]);

  const handleMoreCommentClick = (increaseBy: number) => {
    dispatch.drawn.getCommentsByThreatId({
      threatId: params.threatsId,
      limit: commentLimit + increaseBy,
    });
  };

  const handleChangeThreat = async (value: string, field: string) => {
    dispatch.drawn.updateThreat({
      id: threat.id,
      componentId: params.threatsId,
      [field]: value,
    });
    handleEmitAfterUpdate();
    await dispatch.threatModel.updateCurrentThreatModel();
  };

  const handleAddMitigationToThreat = async (item: any) => {
    if (!item?.id) return;

    await dispatch.mitigations.addMitigationToThreat({
      threatId: threat.id,
      mitigationId: item.id,
      componentId: params.componentId,
    });
    dispatch.drawn.setThreat({
      ...threat,
      mitigations: [...(threat?.mitigations || []), item],
    });
    handleEmitAfterUpdate();
    await dispatch.threatModel.updateCurrentThreatModel();
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>, field: string) => {
    dispatch.drawn.setThreat({
      ...threat,
      [field]: event.target.value,
    });
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (event.target.value.length || field === 'description') handleChangeThreat(event.target.value, field);
    }, 1200);
  };

  const getWindowInnerHeight = () => {
    const height = isCommentInputActive ? `${window.innerHeight - 134}px` : `${window.innerHeight - 59}px`;
    setContentHeight(height);
  };

  window.addEventListener('resize', getWindowInnerHeight);
  window.onkeydown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      let newIndex = event.shiftKey ? selectedItemIndex - 1 : selectedItemIndex + 1;

      if (newIndex < 0) newIndex = mitigations.length - 1;

      if (newIndex >= mitigations.length) newIndex = 0;

      setSelectedItemIndex(newIndex);
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      const selectedItem = mitigations?.[selectedItemIndex];

      if (selectedItem?.id) {
        handleAddMitigationToThreat(selectedItem);
        dispatch.drawn.setNewMitigation({ ...newMitigation, title: '' });
        dispatch.diagram.setMitigations([]);
        setSelectedItemIndex(0);
      }
    }
  };

  return (
    <div>
      <div
        className={styles.threatsDetailContent}
        style={{
          height: contentHeight,
        }}
      >
        <div
          style={{
            margin: '10px',
          }}
        >
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="cursor-pointer" onClick={handleBack}>
              <ArrowLeft fontSize={16} className="me-2 text-secondary" />
              <span className="fs-12 fw-bold text-secondary">Back to {nodeTitle || ''}</span>
            </div>
          </div>

          <div className={classNames('border-bottom w-auto', styles.threatsInfo)}>
            {(threat?.status === THREAT_STATUS.OPEN || threat?.status === THREAT_STATUS.NEED_INVESTIGATION) && (
              <span className={classNames(styles.threatsInfoStatusNotCompleted)}>
                <span className={styles.infoTitle}>Not completed</span>
                <ExclamationTriangle className={styles.infoIcon} fontSize={15} />
              </span>
            )}
            {threat?.status === THREAT_STATUS.COMPLETE && (
              <span className={classNames(styles.threatsInfoStatus)}>
                <span className={styles.infoTitle}>Completed</span>
                <Check2 className={styles.infoIcon} fontSize={15} />
              </span>
            )}
            {!!threat.neutralized_by?.length && (
              <span className={classNames(styles.threatsInfoStatus)}>
                <span className={styles.infoTitle}>Neutralized</span>
              </span>
            )}
            {!threat?.status && (
              <Skeleton
                style={{ lineHeight: '25px' }}
                width={129}
                height={26}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
            )}
            <div className="mb-2 w-100">
              {threat.id && (
                <UiInput
                  name="Title"
                  label="Title"
                  value={threat.title}
                  onChange={(e: any) => {
                    onChange(e, 'title');
                    handleEmitAfterUpdate();
                  }}
                  showError={!threat.title?.length && threat.id}
                  errorText="Required"
                  type="text"
                  onFocus={() => dispatch.diagram.setCanCopy(false)}
                  onBlur={() => dispatch.diagram.setCanCopy(true)}
                />
              )}
              {!threat.id && (
                <Skeleton
                  style={{ lineHeight: '25px' }}
                  width="100%"
                  height={48}
                  baseColor={SKELETON_COLORS.BASE}
                  highlightColor={SKELETON_COLORS.HIGHLIGHT}
                />
              )}
            </div>
            <div className="mb-2 w-100">
              {threat?.id && (
                <UiInput
                  name="Description"
                  label="Description"
                  value={threat.description}
                  onChange={(e: any) => {
                    onChange(e, 'description');
                    handleEmitAfterUpdate();
                  }}
                  type="textarea"
                  textAreaRows={5}
                  onFocus={() => dispatch.diagram.setCanCopy(false)}
                  onBlur={() => dispatch.diagram.setCanCopy(true)}
                />
              )}
              {!threat?.id && (
                <Skeleton
                  style={{ lineHeight: '25px' }}
                  width="100%"
                  height={48}
                  baseColor={SKELETON_COLORS.BASE}
                  highlightColor={SKELETON_COLORS.HIGHLIGHT}
                />
              )}
            </div>
          </div>

          {!!threat.neutralized_by?.length && (
            <div className={classNames('border-bottom w-auto', styles.neutralizedThreats)}>
              <div className={styles.neutralizedThreatsTitle}>Neutralized by</div>
              {threat.neutralized_by?.map((att) => (
                <div key={att.id} className={styles.neutralizedThreatAttribute}>
                  {att.title}
                </div>
              ))}
            </div>
          )}

          {!!threat.caused_by?.length && (
            <div className={classNames('border-bottom w-auto', styles.neutralizedThreats)}>
              <div className={styles.neutralizedThreatsTitle}>Caused by</div>
              {threat.caused_by?.map((att) => (
                <div key={att.id} className={styles.neutralizedThreatAttribute}>
                  {att.title}
                </div>
              ))}
            </div>
          )}

          <div className="fs-12 mt-2">
            {/* <ThreatDropdown */}
            {/*  label="Category" */}
            {/*  value={threat?.category || ""} */}
            {/*  options={[]} */}
            {/*  onChange={(category) => handleChangeThreat(category, "category")} */}
            {/* /> */}
            {threat?.status && (
              <>
                <ThreatDropdown
                  label="Status"
                  value={threat?.status}
                  options={Object.values(THREAT_STATUS)}
                  onChange={(status: THREAT_STATUS) => {
                    handleChangeThreat(status, 'status');
                    handleEmitAfterUpdate();
                  }}
                />
                <ThreatDropdown
                  label="Priority"
                  value={threat?.priority}
                  options={Object.values(THREAT_PRIORITY)}
                  onChange={(priority: THREAT_PRIORITY) => handleChangeThreat(priority, 'priority')}
                />
              </>
            )}
            {!threat?.status && (
              <>
                <Skeleton
                  style={{ lineHeight: '25px', marginBottom: '5px' }}
                  width="100%"
                  height={30}
                  baseColor={SKELETON_COLORS.BASE}
                  highlightColor={SKELETON_COLORS.HIGHLIGHT}
                />
                <Skeleton
                  style={{ lineHeight: '25px' }}
                  width="100%"
                  height={30}
                  baseColor={SKELETON_COLORS.BASE}
                  highlightColor={SKELETON_COLORS.HIGHLIGHT}
                />
              </>
            )}
          </div>

          <div
            className={classNames(
              'fs-12 d-flex align-items-center justify-content-between mt-4 mb-1',
              styles.threatsDetailMitigation,
            )}
          >
            <span className="fw-bold">Mitigations</span>
            <div className={styles.threatsDetailMitigationRight}>
              <span>
                <Link
                  to="m"
                  className={classNames(
                    'fs-12 d-flex p-1 align-items-center gap-1 cursor-pointer text-decoration-none',
                    styles.explorerButton,
                  )}
                >
                  <Clipboard2Check fontSize={16} />
                  Mitigation Explorer
                </Link>
              </span>
              {!!threat?.mitigations?.length && threat?.mitigations?.filter((m) => m.question?.length)?.length > 0 && (
                <span>
                  <Link
                    to="c"
                    className={classNames(
                      'fs-12 d-flex p-1 align-items-center gap-1 cursor-pointer text-decoration-none',
                      styles.explorerButton,
                    )}
                  >
                    <Clipboard2Check fontSize={16} />
                    Checklist
                  </Link>
                </span>
              )}
            </div>
          </div>

          <div className="d-flex flex-wrap align-items-center gap-1 mt-3 mb-2">
            {threat?.mitigations?.map((mitigation) => (
              <MitigationItem
                key={mitigation.id}
                data={mitigation}
                onRemove={(e, isOk) => {
                  if (isOk) {
                    handleEmitAfterUpdate(e);
                    emitUpdate && mitigation.id && emitUpdate(UpdateTypes.MITIGATION, mitigation.id, true);
                  }
                }}
              />
            ))}
            {!threat?.mitigations && (
              <div style={{ display: 'flex', gap: '0.25rem' }}>
                <Skeleton
                  style={{ lineHeight: '25px' }}
                  width={125}
                  height={28}
                  baseColor={SKELETON_COLORS.BASE}
                  highlightColor={SKELETON_COLORS.HIGHLIGHT}
                />
                <Skeleton
                  style={{ lineHeight: '25px' }}
                  width={125}
                  height={28}
                  baseColor={SKELETON_COLORS.BASE}
                  highlightColor={SKELETON_COLORS.HIGHLIGHT}
                />
              </div>
            )}
          </div>
          {params.componentId && <MitigationSearch componentId={params.componentId} />}
          <ThreatHistory threat={threat} />
        </div>
        {commentData.items.length > 0 && (
          <div>
            <CommentList commentData={commentData} onMoreCommentsClick={handleMoreCommentClick} />
            <span ref={scrollPoint} />
          </div>
        )}
      </div>
      <div>
        <div className={styles.commentInputDividerWrapper}>
          <div className={styles.commentInputDivider} />
        </div>
        <CommentInput
          threat={threat}
          emitUpdate={emitUpdate}
          onScroll={executeScroll}
          needScroll={wereCommentsUpdated}
        />
      </div>
    </div>
  );
};

export default ThreatsDetailForm;
