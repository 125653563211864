export const formatDate = (date: Date | string) => {
  if (typeof date === 'string') return date;

  return date.toLocaleDateString('en-US');
};

export const getMonthsArray = (today: Date) => {
  const result = [];
  for (let i = 12; i > 0; i -= 1) {
    const d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    result.push(d);
  }
  for (let i = 0; i < 12; i += 1) {
    const d = new Date(today.getFullYear(), today.getMonth() + i, 1);
    result.push(d);
  }

  return result;
};
