import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import OutsideClickHandler from 'react-outside-click-handler';
import type { ThreatModelPriorityType } from '../../../global/types';
import UiPriority from '../../common/UiPriority/UiPriority';
import styles from './index.module.css';

type ThreatDropdownProps = {
  value?: string;
  label?: string;
  options: string[];
  onChange?: any;
  disabled?: boolean;
  isPriority?: boolean;
  defaultValue?: string;
};
const ThreatDropdown: FC<ThreatDropdownProps> = ({
  label,
  options,
  value,
  onChange,
  disabled = false,
  isPriority = false,
  defaultValue,
}) => {
  const [open, setOpen] = useState(false);

  const renderItem = () => {
    if (isPriority && value && options?.includes(value)) {
      return <UiPriority noPointer size="smallItem" type={value as ThreatModelPriorityType} />;
    }

    return value || defaultValue || '-';
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div
        className={classNames(
          'd-flex align-items-center gap-2 position-relative user-select-none',
          styles.threatsDetailDropdown,
        )}
      >
        {label && <span className="w-25 fw-bold">{label}</span>}
        <div
          className={classNames('w-100 text-capitalize', styles.threatsDetailDropdownInput, { [styles.active]: open })}
          onClick={() => !disabled && setOpen(!open)}
        >
          {renderItem()}
          {open ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
          {open && (
            <div className={classNames('position-absolute', styles.threatsDetailDropdownOptions)}>
              {open &&
                options
                  .filter((i) => i !== value)
                  .map((o: any, idx: number) => (
                    <div key={o + idx} className="text-capitalize" onClick={() => onChange && onChange(o)}>
                      {isPriority ? <UiPriority noPointer size="small" type={o as ThreatModelPriorityType} /> : o}
                    </div>
                  ))}
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default ThreatDropdown;
