import { createModel } from '@rematch/core';
import { toast } from 'react-toastify';
import type { Team } from '../../global/types';
import { TEAM_URL } from '../constants/api-constants';
import http from '../http/http-common';
import type { RootModel } from './index';

type TeamState = {
  current: Team;
  teams: Team[];
};

export const team = createModel<RootModel>()({
  state: {
    current: {} as Team,
    teams: [],
  } as TeamState,
  reducers: {
    setTeams(state, teams) {
      return {
        ...state,
        teams,
      };
    },
    setCurrent(state, team) {
      return {
        ...state,
        current: team,
      };
    },
    insertTeam(state, team) {
      return {
        ...state,
        teams: [...state.teams, team],
      };
    },
  },
  effects: (dispatch) => {
    return {
      async getTeams() {
        const response = await http(TEAM_URL);
        dispatch.team.setTeams(response.data.items);
      },
      async getTeamById(id) {
        const response = await http(`${TEAM_URL}/${id}`);
        dispatch.team.setCurrent(response.data);
      },
      async createTeam(title: string) {
        try {
          const result = await http.post(`${TEAM_URL}/`, {
            title,
          });

          if (result.status === 201) {
            toast.success('Team created successfully!');
            dispatch.team.insertTeam(result.data);
          }

          return result.data;
        } catch (error: any) {
          toast.warning(error);
        }
      },
    };
  },
});
