import { createModel } from '@rematch/core';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import type { Project } from '../../global/types';
import { getRandomCollectionIconColor } from '../../helpers/colors';
import type { ProjectInfoData } from '../../helpers/projects';
import {
  filterExistProjects,
  removeNestedProjects,
  setNestedProjects,
  setNestedProjectsColor,
} from '../../helpers/projects';
import {
  ADD_SUB_PROJECT,
  CREATE_PROJECTS,
  GET_PROJECTS,
  LIFT_PROJECT,
  SEARCH_PROJECT,
} from '../constants/api-constants';
import { HOME_ROUTE } from '../constants/route-constants';
import http from '../http/http-common';
import type { RootModel } from './index';

type ProjectState = {
  current: Project | null;
  projects: Project[] | null;
  searchProjects: { id: string; title: string }[];
  archives: Project[] | null;
  query: {
    limit: number;
    page: number;
    count: number;
  };
  liveUpdateData: string[] | null;
  archiveLiveUpdateData: string[] | null;
  isLoading: 'search' | null;
};

export const project = createModel<RootModel>()({
  state: {
    current: null,
    projects: null,
    archives: null,
    query: {
      limit: 25,
      page: 0,
      count: 0,
    },
    liveUpdateData: null,
  } as ProjectState,
  reducers: {
    setProjects(state, projects) {
      return {
        ...state,
        projects,
      };
    },
    setArchives(state, archives) {
      return {
        ...state,
        archives,
      };
    },
    setCurrent(state, project) {
      return {
        ...state,
        current: project,
      };
    },
    insertProject(state, project) {
      return {
        ...state,
        projects: [...(state.projects || []), project],
      };
    },
    removeProject(state, projectId) {
      return {
        ...state,
        projects: [...(state.projects?.filter((item) => item.id !== projectId) || [])],
      };
    },
    removeArchiveProject(state, projectId) {
      return {
        ...state,
        archives: [...(state.archives?.filter((item) => item.id !== projectId) || [])],
      };
    },
    setProjectColor(state, { id, color }) {
      if (state.current?.id) state.current.color = color;

      return {
        ...state,
        projects: [...(state.projects?.map((item) => setNestedProjectsColor(id, color, item)) || [])],
      };
    },
    setProject(state, project: Project) {
      const projects = state?.projects;

      if (!projects) return state;

      return {
        ...state,
        projects: [...projects, project],
      };
    },

    setNestedProjects(state, { parentId, projectInfo }: { parentId: string; projectInfo: ProjectInfoData }) {
      const projects = state?.projects;

      if (!projects) return state;

      return {
        ...state,
        projects: projects.map((project) => setNestedProjects({ parentId, project, projectInfo })),
      };
    },
    removeNestedProjects(state, subProjectId: string) {
      const projects = state?.projects;

      if (!projects) return state;

      return {
        ...state,
        projects: projects.map((project) => removeNestedProjects(subProjectId, project)).filter(Boolean) as Project[],
      };
    },
    setQuery(state, query) {
      return { ...state, query };
    },
    setLiveUpdateData(state, liveUpdateData) {
      return { ...state, liveUpdateData };
    },
    setArchiveLiveUpdateData(state, archiveLiveUpdateData) {
      return { ...state, archiveLiveUpdateData };
    },
    resetProjectsState(state) {
      return {
        ...state,
        projects: null,
        current: null,
        archives: null,
        liveUpdateData: null,
        query: {
          limit: 25,
          page: 0,
          count: 0,
        },
      };
    },
    setSearchProject(state, projects: ProjectState['searchProjects']) {
      return { ...state, searchProjects: projects };
    },
    setLoading(state, isLoading: ProjectState['isLoading']) {
      return { ...state, isLoading };
    },
  },

  effects: (dispatch) => {
    return {
      async getProjects(_, state) {
        try {
          const { limit, page } = state.project.query;
          const response = await http(`${GET_PROJECTS}?sort=created_at&limit=${limit}&page=${page}`);

          if (response?.status === 200) {
            dispatch.project.setProjects(response.data?.items);
            dispatch.project.setQuery({
              ...state.project.query,
              count: response.data?.count || 0,
            });
          }
        } catch (e: any) {
          toast.warning(e?.message || '');
        }
      },
      async getArchives() {
        try {
          // const { limit, page } = state.project.query;
          const response = await http(`${GET_PROJECTS}/archives?sort=created_at&limit=${100}&page=${0}`);
          dispatch.project.setArchives(response.data.items);
        } catch (e: any) {
          toast.warning(e?.message || '');
        }
      },
      async getProjectsByQuery(query, state) {
        try {
          const nextPage = query.limit * query.page;
          const response = await http(`${GET_PROJECTS}?sort=created_at&limit=${query.limit}&page=${nextPage}`);

          if (response.data?.items) {
            dispatch.project.setProjects(filterExistProjects(state.project.projects || [], response.data.items));
          }

          dispatch.project.setQuery({
            ...query,
            count: response.data?.count || 0,
          });
        } catch (e: any) {
          toast.warning(e?.message || '');
        }
      },
      async createProject({ id, title, description }) {
        try {
          const result = await http.post(`${CREATE_PROJECTS}/`, {
            id,
            title,
            description,
            color: getRandomCollectionIconColor(),
          });

          if (result.status === 201) {
            toast.success('Collection created successfully!');
            dispatch.project.getProjects(false);
          }

          return result.data;
        } catch (error: any) {
          toast.warning(error);
        }
      },
      async updateProject({ id, title, description, color }) {
        try {
          const result = await http.patch(`${CREATE_PROJECTS}/${id}`, {
            title,
            description,
            color,
          });

          return result.data;
        } catch (error: any) {
          toast.warning(error?.message || '');
        }
      },
      async deleteProject(id) {
        try {
          const result: any = await http.delete(`${CREATE_PROJECTS}/${id}`);

          if (result.status === 200) {
            toast.success('Collection deleted successfully!');
            dispatch.project.removeProject(id);
            await dispatch.project.getArchives();
          }

          if (result?.response?.status === 400) {
            toast.error(result?.response?.data?.message);
          }

          return result.data;
        } catch (error: any) {
          toast.warning(error?.message || '');
        }
      },
      async archiveProject(id) {
        try {
          const result = await http.delete(`${CREATE_PROJECTS}/archive/${id}`);

          if (result.status === 200) {
            toast.success('Collection archived successfully!');
            dispatch.project.removeNestedProjects(id);
          }

          return result.data;
        } catch (error: any) {
          toast.warning(error?.message || '');
        }
      },
      async unArchiveProject(id) {
        try {
          const result = await http.post(`${CREATE_PROJECTS}/unArchive/${id}`);

          if (result.status === 201) {
            toast.success('Collection unarchived successfully!');
            dispatch.project.removeArchiveProject(id);
            dispatch.project.getProjects(false);
          }

          return result.data;
        } catch (error: any) {
          toast.warning(error);
        }
      },
      async getProjectById(id: string) {
        try {
          const result = await http(`${GET_PROJECTS}/${id}`);

          if (result instanceof AxiosError && result.response?.status === 403) {
            return (window.location.href = HOME_ROUTE);
          }

          if (result.status === 200) {
            dispatch.project.setCurrent(result.data);
          }
        } catch (e: any) {
          toast.warning(e.message);
        }
      },
      async addSubProject({ parentProjectId, subProjectId }: { parentProjectId: string; subProjectId: string }) {
        try {
          const result: any = await http.patch<Project>(`${ADD_SUB_PROJECT}`, {
            parentId: parentProjectId,
            subProjectId,
          });

          if (result.status === 200) {
            dispatch.project.removeNestedProjects(subProjectId);
            dispatch.project.setNestedProjects({
              projectInfo: { nestedProjects: [result.data] },
              parentId: parentProjectId,
            });

            toast.success('Sub collection successfully added');
          }

          if (result?.response?.status === 400) {
            toast.error(result?.response?.data?.message);
          }
        } catch (error) {
          if (error instanceof Error) {
            toast.warning(error.message);
          }

          toast.warning(String(error));
        }
      },
      async getNestedProjects({ limit, page, parentId }: { limit: number; page: number; parentId: string }) {
        try {
          const result = await http(
            `${GET_PROJECTS}?sort=created_at&limit=${limit}&page=${page}&is_nested=true&parent_id=${parentId}`,
          );

          if (result.status === 200) {
            const projectInfo: ProjectInfoData = {
              nestedProjects: result.data.items || [],
              query: {
                count: result.data.count,
                page,
                limit,
              },
            };

            dispatch.project.setNestedProjects({ projectInfo, parentId });
          }
        } catch (error) {
          if (error instanceof Error) {
            toast.warning(error.message);
          }

          toast.warning(String(error));
        }
      },

      async moveToTopLevel(projectId: string) {
        try {
          const result: any = await http.patch<Project>(`${LIFT_PROJECT}/${projectId}`);

          if (result.status === 200) {
            dispatch.project.removeNestedProjects(projectId);
            dispatch.project.setProject(result.data);
          }

          if (result?.response?.status === 400) {
            toast.error(result?.response?.data?.message);
          }
        } catch (error) {
          if (error instanceof Error) {
            toast.warning(error.message);
          }

          toast.warning(String(error));
        }
      },

      async searchProjectByTitle(title = '') {
        try {
          dispatch.project.setLoading('search');
          const result = await http(`${SEARCH_PROJECT}?title=${title}`);

          if (result.status === 200) {
            dispatch.project.setSearchProject(result.data);
          }
        } catch (error) {
          if (error instanceof Error) {
            toast.warning(error.message);
          }

          toast.warning(String(error));
        } finally {
          dispatch.project.setLoading(null);
        }
      },
      async getNestedProjectsByQuery(query) {
        try {
          const nextPage = query.page * query.limit;
          const result = await http(
            `${GET_PROJECTS}?sort=created_at&limit=${query.limit}&page=${nextPage}&is_nested=true&parent_id=${query.parentProjectId}`,
          );

          if (result.status === 200) {
            const projectInfo: ProjectInfoData = {
              nestedProjects: result.data.items || [],
              query: {
                count: result.data.count,
                page: query.page,
                limit: query.limit,
              },
            };

            dispatch.project.setNestedProjects({ projectInfo, parentId: query.parentProjectId });
          }
        } catch (error) {
          if (error instanceof Error) {
            toast.warning(error.message);
          }

          toast.warning(String(error));
        }
      },
    };
  },
});
