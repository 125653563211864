import { createModel } from '@rematch/core';
import { toast } from 'react-toastify';
import { getThreatRegisterSelectedThreat, setThreatRegisterSelectedThreat } from '../../helpers/threatRegister';
import { COMPONENTS, THREATS } from '../constants/api-constants';
import type { ThreatRegisterFilterParams } from '../constants/threat-register-constants';
import {
  DEFAULT_GROUP_THREATS_REGISTER_PAGINATION_PARAMS,
  DEFAULT_THREATS_REGISTER_PAGINATION_PARAMS,
} from '../constants/threat-register-constants';
import http from '../http/http-common';
import type { IThreat } from './drawn';
import type { RootModel } from './index';

type ThreatsStateType = {
  typeAndCategoryList: any[] | null;
  threatRegisterSelectedThreat: null | Partial<IThreat>;
  selectedCategoryData: any;
  selectedThreat: any;
  groupedThreatRegisterList: any[] | null;
  groupedThreatRegisterListQuery: ThreatRegisterFilterParams;
  threatRegisterList: any[] | null;
  threatRegisterListQuery: ThreatRegisterFilterParams;
  subThreatRegisterList: any[] | null;
  subThreatRegisterListQuery: ThreatRegisterFilterParams;
  elementRegisterList: any[] | null;
  elementRegisterListQuery: ThreatRegisterFilterParams;
};

export const threats = createModel<RootModel>()({
  state: {
    typeAndCategoryList: null,
    selectedCategoryData: null,
    threatRegisterSelectedThreat: getThreatRegisterSelectedThreat(),
    selectedThreat: null,
    groupedThreatRegisterList: null,
    groupedThreatRegisterListQuery: DEFAULT_GROUP_THREATS_REGISTER_PAGINATION_PARAMS,
    threatRegisterList: null,
    threatRegisterListQuery: DEFAULT_THREATS_REGISTER_PAGINATION_PARAMS,
    subThreatRegisterList: null,
    subThreatRegisterListQuery: DEFAULT_THREATS_REGISTER_PAGINATION_PARAMS,
    elementRegisterList: null,
    elementRegisterListQuery: DEFAULT_THREATS_REGISTER_PAGINATION_PARAMS,
  } as ThreatsStateType,
  reducers: {
    setThreatRegisterSelectedThreat(state, value) {
      setThreatRegisterSelectedThreat(value);

      return { ...state, threatRegisterSelectedThreat: value };
    },
    setTypeAndCategoryList(state, typeAndCategoryList) {
      if (!typeAndCategoryList) {
        typeAndCategoryList = [];
      }

      return {
        ...state,
        typeAndCategoryList,
      };
    },
    setSelectedCategory(state, category) {
      return {
        ...state,
        selectedCategoryData: category,
      };
    },
    clearSelectedCategory(state) {
      return {
        ...state,
        selectedCategoryData: null,
      };
    },
    setSelectedThreat(state, threat) {
      return {
        ...state,
        selectedThreat: threat,
      };
    },
    clearSelectedThreat(state) {
      return {
        ...state,
        selectedThreat: null,
      };
    },
    setGroupedThreatRegisterList(state, groupedThreatRegisterList) {
      return { ...state, groupedThreatRegisterList };
    },
    setGroupedThreatRegisterQuery(state, groupedThreatRegisterListQuery) {
      return { ...state, groupedThreatRegisterListQuery };
    },
    setThreatRegisterList(state, threatRegisterList) {
      return { ...state, threatRegisterList };
    },
    setQuery(state, threatRegisterListQuery) {
      return { ...state, threatRegisterListQuery };
    },
    setSubThreatRegisterList(state, subThreatRegisterList) {
      return { ...state, subThreatRegisterList };
    },
    setElementRegisterList(state, elementRegisterList) {
      return { ...state, elementRegisterList };
    },

    setSubThreatRegisterListQuery(state, subThreatRegisterListQuery) {
      return { ...state, subThreatRegisterListQuery };
    },
    setElementRegisterQuery(state, elementRegisterListQuery) {
      return { ...state, elementRegisterListQuery };
    },
  },
  effects: (dispatch) => {
    return {
      async getTypeAndCategoryList() {
        const response = await http(`${THREATS}/explorer`);
        await dispatch.threats.setTypeAndCategoryList(response.data);

        return response?.data?.items || [];
      },
      async getCategoryInfoByType(type: string) {
        if (!type) return;

        const response = await http(`${THREATS}/explorer?type=${type}`);
        await dispatch.threats.setSelectedCategory(response.data[0]);

        return response?.data?.items || [];
      },
      async addThreatToComponent({ componentId, threatId }) {
        const response = await http.post(`${COMPONENTS}/add-threat`, {
          componentId,
          threatId,
        });

        if (response.status === 201) {
          toast.success('Threat successfully added!');
          dispatch.drawn.getComponent({ id: componentId });
        }
      },
      async getThreatRegisterList(query, state) {
        try {
          const { limit, page, threatModelId } = query;
          const nextPage = limit * page;
          const response = await http.post(`${THREATS}/threat-register-title/${threatModelId}`, {
            ...query,
            nextPage,
          });

          if (response.status === 201 && response.data?.items) {
            const preparedData = !page
              ? response.data.items
              : [...(state.threats.threatRegisterList || []), ...response.data.items];
            dispatch.threats.setThreatRegisterList(preparedData);
          }

          dispatch.threats.setQuery({
            ...query,
            count: response?.data?.count || 0,
          });
        } catch (error: any) {
          console.error(error.message);
        }
      },
      async getGroupedThreatRegisterList(query, state) {
        try {
          const { limit, page, threatModelId } = query;
          const nextPage = limit * page;
          const response = await http.post(`${THREATS}/threat-register/${threatModelId}`, {
            ...query,
            nextPage,
          });

          if (response.status === 201 && response.data?.items) {
            const preparedData = !page
              ? response.data.items
              : [...(state.threats.groupedThreatRegisterList || []), ...response.data.items];
            dispatch.threats.setGroupedThreatRegisterList(preparedData);
          }

          dispatch.threats.setGroupedThreatRegisterQuery({
            ...query,
            count: response?.data?.count || 0,
          });
        } catch (error: any) {
          console.error(error.message);
        }
      },
      async getComponentThreats(componentId: string, state) {
        try {
          const response = await http(`${THREATS}/for-component/${componentId}`);

          if (response.status === 200) {
            return response?.data || [];
          }
        } catch (error: any) {
          console.error(error.message);
        }
      },
      async updateThreat({ threat, registerType }) {
        try {
          if (threat?.id) {
            if (registerType === 'threat') {
              dispatch.threats.updateThreatFromThreatRegisterList(threat);
            }

            if (registerType === 'element') {
              dispatch.threats.updateSubThreatFromElementRegisterList(threat);
            }

            return await http.patch(`${THREATS}/${threat.id}`, threat);
          }
        } catch (error) {
          console.error(error);
        }
      },
      async updateThreatFromThreatRegisterList(updatedThreat, state) {
        state.threats.threatRegisterList?.forEach((threat) => {
          if (threat.id === updatedThreat.id) {
            if (updatedThreat.title) threat.title = updatedThreat.title;

            if (updatedThreat.description) threat.description = updatedThreat.description;

            if (updatedThreat.status) threat.status = updatedThreat.status;

            if (updatedThreat.priority) threat.priority = updatedThreat.priority;

            if (Array.isArray(updatedThreat?.mitigations)) threat.mitigations = updatedThreat.mitigations;
          }
        });
        dispatch.threats.setThreatRegisterList(structuredClone(state.threats.threatRegisterList));
      },
      async updateSubThreatFromElementRegisterList(updatedThreat, state) {
        state.threats.subThreatRegisterList?.forEach((threat) => {
          if (threat.id === updatedThreat.id) {
            if (updatedThreat.title) threat.title = updatedThreat.title;

            if (updatedThreat.description) threat.description = updatedThreat.description;

            if (updatedThreat.status) threat.status = updatedThreat.status;

            if (updatedThreat.priority) threat.priority = updatedThreat.priority;

            if (Array.isArray(updatedThreat?.mitigations)) threat.mitigations = updatedThreat.mitigations;
          }
        });
        dispatch.threats.setSubThreatRegisterList(structuredClone(state.threats.subThreatRegisterList));
      },
      async getElementRegisterList(query, state) {
        try {
          const { limit, page, threatModelId } = query;
          const nextPage = limit * page;
          const response = await http.post(`${COMPONENTS}/register/${threatModelId}`, {
            ...query,
            nextPage,
          });

          if (response.status === 201 && response.data?.items) {
            const preparedData = !page
              ? response.data.items
              : [...(state.threats.elementRegisterList || []), ...response.data.items];

            dispatch.threats.setElementRegisterList(preparedData);
          }

          dispatch.threats.setElementRegisterQuery({
            ...query,
            count: response?.data?.count || 0,
          });
        } catch (error: any) {
          console.error(error.message);
        }
      },
      async getThreatsByComponentId(query, state) {
        try {
          const { limit, page, componentId } = query;
          const nextPage = limit * page;

          if (componentId) {
            const response = await http.post(`${THREATS}/register/${componentId}`, {
              ...query,
              nextPage,
            });

            if (response.status === 201 && response.data?.items) {
              const preparedData = !page
                ? response.data.items
                : [...(state.threats.subThreatRegisterList || []), ...response.data.items];
              dispatch.threats.setSubThreatRegisterList(preparedData);
            }

            dispatch.threats.setSubThreatRegisterListQuery({
              ...query,
              count: response?.data?.count || 0,
            });
          }
        } catch (error: any) {
          console.error(error.message);
        }
      },
      async updateManyThreatsByTitleOrComponentId({ titleOrId, threatModelId, updateFields }) {
        try {
          const response = await http.patch(`${THREATS}/many/by-title/${threatModelId}`, {
            ...titleOrId,
            ...updateFields,
          });

          if (response.status === 200) {
            return response.status;
          }

          toast(response.data);
        } catch (error) {
          console.error(error);
        }
      },
      async exportCsvThreatRegister({ threatModelId, type }) {
        try {
          const response = await http.get(`${THREATS}/${type}/${threatModelId}`);

          if (response.status === 200 && response.data.length) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = `${type}.csv`;
            document.body.appendChild(a);
            a.click();
            a.remove();

            return;
          }

          const responseText = response.request?.responseText;
          const result = responseText?.length ? JSON.parse(responseText) : null;
          toast.warning(result?.message?.toString());
        } catch (error: any) {
          toast.warning(error?.message);
        }
      },
    };
  },
});
