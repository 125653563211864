import type { FC } from 'react';
import { useState } from 'react';
import { BoxArrowUpRight, PlusLg, XLg } from 'react-bootstrap-icons';
import OutsideClickHandler from 'react-outside-click-handler';

import UiButton from '../../common/UIButton/UiButton';
import UiInput from '../../common/UiInput/UiInput';
import styles from './CustomCodexTable.module.css';

export interface AttributeResources {
  title: string;
  url: string;
}

type ResourcesFormProps = {
  resources: AttributeResources[];
  setResources: (value: AttributeResources[]) => void;
};

export const ResourcesForm: FC<ResourcesFormProps> = ({ resources = [], setResources }) => {
  const [showForm, setShowForm] = useState(false);
  const [titleError, setTitleError] = useState('');
  const [urlError, setUrlError] = useState('');
  const [resource, setResource] = useState<any>({
    url: '',
    title: '',
  });

  const handleAdd = () => {
    if (!resource.title.length) {
      setTitleError('Resource title should not be empty');

      return;
    }

    if (!resource.url.length) {
      setUrlError('Resource URL should not be empty');

      return;
    }

    const exist = resources?.some((r: AttributeResources) => r.title === resource.title);

    if (exist) {
      setTitleError('Resource title should be unique');

      return;
    }

    setResources([...(resources || []), resource]);
    setResource({ url: '', title: '' });
  };

  const handleRemove = (currentTitle: string) => {
    setResources([...(resources?.filter((r: AttributeResources) => r.title !== currentTitle) || [])]);
  };

  return (
    <div className="mt-2 fs-12">
      <span className="fw-bold">Resources:</span>
      <div className="mb-2 w-100">
        {resources?.map((resource: AttributeResources, idx: number) => (
          <div key={resource.title + idx} className={styles.linkWrap}>
            <a target="_blank" rel="noreferrer" href={resource.url} className={styles.link}>
              {resource.title}
              <BoxArrowUpRight size={12} className="ms-1" />
            </a>
            <div className="cursor-pointer" onClick={() => handleRemove(resource.title)}>
              <XLg size={14} />
            </div>
          </div>
        ))}
        {!resources?.length && <div className="user-select-none">No resources</div>}
      </div>

      {showForm && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setUrlError('');
            setTitleError('');
            setShowForm(false);
            setResource({ url: '', title: '' });
          }}
        >
          <>
            <UiInput
              name="title"
              label="Title"
              onBlur={null}
              value={resource.title}
              errorText={titleError}
              showError={!!titleError.length}
              placeholder="Enter resource title"
              onChange={(event: any) => {
                setResource({ ...resource, title: event.target.value });
              }}
              onFocus={() => {
                setTitleError('');
              }}
            />
            <UiInput
              name="url"
              label="URL"
              onBlur={null}
              value={resource.url}
              errorText={urlError}
              showError={!!urlError.length}
              placeholder="Enter resource URL"
              onChange={(event: any) => {
                setResource({ ...resource, url: event.target.value });
              }}
              onFocus={() => {
                setUrlError('');
              }}
            />
            <div className="d-flex mt-2 justify-content-center">
              <UiButton type="transparent" style={{ height: 30 }} onClick={handleAdd}>
                <PlusLg className="cursor-pointer me-1" size={14} /> <span className="fs-12">Add</span>
              </UiButton>
            </div>
          </>
        </OutsideClickHandler>
      )}

      {!showForm && (
        <UiButton
          className="ps-0"
          type="transparent"
          style={{ height: 30 }}
          onClick={() => {
            setShowForm(true);
          }}
        >
          <PlusLg className="cursor-pointer me-1" size={14} /> <span className="fs-12">Add resource</span>
        </UiButton>
      )}
    </div>
  );
};
