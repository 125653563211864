import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { ArrowLeft, Braces, Lightbulb, Person, Share } from 'react-bootstrap-icons';
import { createPortal } from 'react-dom';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { SKELETON_COLORS } from '../../global/constants';
import { useSocket } from '../../hooks/useSocket';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import { PRODUCT_TOUR_PARAM } from '../../store/constants';
import { CODE_GENIUS_ROUTE } from '../../store/constants/route-constants';
import UserAvatar from '../common/UserAvatar/UserAvatar';
import styles from './Sidebar.module.css';

export const ProfileItem = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const user = useTypedSelector((state) => state.user.current);

  const showSidebar = useTypedSelector((state) => state.app?.sidebarVisible);

  const { disconnect } = useSocket();

  const [showDropDown, setShowDropDown] = useState(false);
  const [contentElement, setContentElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const el = document.getElementById('sidebar-element');

    if (el) setContentElement(el);
  }, []);

  const handleSignOutClick = () => {
    disconnect(); // disconnect from ws live updates api
    dispatch.auth.signOut();
  };

  const handleProfileClick = (route: string) => {
    navigate(route);
  };

  if (user?.id)
    return (
      <div
        className={styles.profileItem}
        onClick={() => {
          setShowDropDown(!showDropDown);
        }}
      >
        <div className={styles.profileItemAvatar}>
          <UserAvatar noHover user={user} diameter={30} noBorder />
        </div>
        <div className={styles.profileItemName} hidden={!showSidebar}>
          <div
            style={{
              opacity: showSidebar ? 1 : 0,
              transition: 'opacity 0.3s cubic-bezier(0.4, 0, 1, 1) 0s',
            }}
          >{`${user.first_name || ''} ${user.last_name || ''}`}</div>
          <div
            style={{
              opacity: showSidebar ? 1 : 0,
            }}
            className={styles.profileItemEmail}
          >
            {user.email || ''}
          </div>
        </div>
        {showDropDown &&
          contentElement &&
          createPortal(
            <>
              <div className={styles.dropDownOverlay} onClick={() => setShowDropDown(false)} />
              <div
                className={styles.dropDownPopUp}
                style={{ left: showSidebar ? `${(contentElement?.offsetWidth || 230) + 10}px` : '75px' }}
                id="user-profile-dropdown"
              >
                <div
                  onClick={() => handleProfileClick('/profile')}
                  className={classNames(styles.dropDownItem, {
                    [styles.dropDownItemActive]: location.pathname === '/profile',
                  })}
                >
                  <Person size={24} /> My Profile
                </div>
                <a
                  href={`/?${PRODUCT_TOUR_PARAM}`}
                  style={{ color: '#D3D8E4' }}
                  className={classNames(styles.dropDownItem)}
                >
                  <Lightbulb size={24} /> Product Tour
                </a>
                <a
                  href="https://help.devici.com/knowledge/devici-product-release-notes"
                  target="_blank"
                  style={{ color: '#D3D8E4' }}
                  className={classNames(styles.dropDownItem)}
                  rel="noreferrer"
                >
                  <Share size={20} /> Product Release Notes
                </a>
                <div
                  onClick={() => handleProfileClick(CODE_GENIUS_ROUTE)}
                  className={classNames(styles.dropDownItem, {
                    [styles.dropDownItemActive]: location.pathname === CODE_GENIUS_ROUTE,
                  })}
                >
                  <Braces size={24} /> Code Genius
                </div>
                <span className={styles.breakLine} />

                <div className={styles.dropDownItemLogOut} onClick={handleSignOutClick}>
                  <ArrowLeft /> Log Out
                </div>
              </div>
            </>,
            contentElement,
          )}
      </div>
    );

  return (
    <div className={styles.skeletonWrapper}>
      <Skeleton
        width={30}
        height={30}
        borderRadius={50}
        baseColor={SKELETON_COLORS.DARK_BASE}
        highlightColor={SKELETON_COLORS.DARK_HIGHLIGHT}
      />
      {showSidebar && (
        <Skeleton
          width={115}
          height={30}
          baseColor={SKELETON_COLORS.DARK_BASE}
          highlightColor={SKELETON_COLORS.DARK_HIGHLIGHT}
        />
      )}
    </div>
  );
};
