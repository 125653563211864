import { useFormik } from 'formik';
import type { FC } from 'react';
import { useState } from 'react';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import type { EmitUpdate } from '../../../hooks/useSocket';
import { UpdateTypes } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { THREAT_PRIORITY, THREAT_STATUS } from '../../../store/constants/drawn-constants';
import type { Dispatch } from '../../../store/store';
import UiButton from '../../common/UIButton/UiButton';
import UiInput from '../../common/UiInput/UiInput';
import ThreatDropdown from '../ThreatDropdown';

type UserThreatsFormProps = {
  emitUpdate?: EmitUpdate;
};

const UserThreatsForm: FC<UserThreatsFormProps> = ({ emitUpdate }) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const nodeTitle = useTypedSelector(({ drawn }) => drawn?.component?.title) || '';
  const { currentThreatModel } = useTypedSelector((state) => state.threatModel);
  const [threat, setThreat] = useState({
    title: '',
    description: '',
    category: '',
    componentId: params.componentId,
    priority: THREAT_PRIORITY.MEDIUM,
    status: THREAT_STATUS.OPEN,
    is_custom: true,
  });
  const [canAdd, setCanAdd] = useState(true);

  const handleBack = () => {
    navigate(-1);
  };

  const formik = useFormik({
    initialValues: {
      title: threat.title,
      description: threat.description,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Required'),
      description: Yup.string(),
    }),
    onSubmit: async (values) => {
      threat.description = values.description;
      threat.title = values.title;
      const result = await dispatch.drawn.addUserGeneratedThreat(threat);

      if (result) handleBack();
    },
  });

  const { diagramId, componentId } = params;

  const handleEmitAfterAdd = () => {
    if (currentThreatModel && emitUpdate) {
      emitUpdate(UpdateTypes.REGISTER, currentThreatModel.id, true);
    }
  };

  const handleEmitAfterUpdate = () => {
    if (diagramId && emitUpdate) {
      emitUpdate(UpdateTypes.COMPONENT, componentId!, true);
    }
  };

  const handleAdd = async () => {
    if (canAdd) {
      setCanAdd(false);
      await formik.handleSubmit();
      handleEmitAfterUpdate();
      handleEmitAfterAdd();
      await dispatch.threatModel.updateCurrentThreatModel();
    }
  };

  return (
    <div style={{ margin: '10px' }}>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="cursor-pointer" onClick={handleBack}>
          <ArrowLeft fontSize={16} className="me-2 text-secondary" />
          <span className="fs-12 fw-bold text-secondary">Back to {nodeTitle || ''}</span>
        </div>
      </div>

      <form>
        <UiInput
          label="Title"
          type="text"
          name="title"
          value={formik.values.title}
          onChange={(event: any) => {
            if (event.target?.value?.length && !canAdd) setCanAdd(true);

            formik.handleChange(event);
          }}
          onFocus={() => dispatch.diagram.setCanCopy(false)}
          onBlur={(e: any) => {
            formik.handleBlur(e);
            dispatch.diagram.setCanCopy(true);
          }}
          errorText={formik.errors.title}
          showError={formik.touched.title && formik.errors.title}
          autoFocus
        />
        <UiInput
          label="Description"
          type="textarea"
          textAreaRows={6}
          name="description"
          value={formik.values.description}
          onChange={(event: any) => {
            if (event.target?.value?.length && !canAdd) setCanAdd(true);

            formik.handleChange(event);
          }}
          onFocus={() => dispatch.diagram.setCanCopy(false)}
          onBlur={(e: any) => {
            formik.handleBlur(e);
            dispatch.diagram.setCanCopy(true);
          }}
          errorText={formik.errors.description}
          showError={formik.touched.description && formik.errors.description}
        />
      </form>
      <div className="fs-12 mt-2">
        <ThreatDropdown
          label="Status"
          options={Object.values(THREAT_STATUS)}
          value={threat.status}
          onChange={(status: THREAT_STATUS) => setThreat({ ...threat, status })}
        />
        <ThreatDropdown
          label="Priority"
          options={Object.values(THREAT_PRIORITY)}
          value={threat.priority}
          onChange={(priority: THREAT_PRIORITY) => setThreat({ ...threat, priority })}
        />
      </div>

      <div className="mt-3 text-end mb-5">
        <UiButton type="transparent" onClick={handleBack}>
          Cancel
        </UiButton>
        <UiButton onClick={handleAdd} disabled={!canAdd}>
          Add threat
        </UiButton>
      </div>
    </div>
  );
};

export default UserThreatsForm;
