import classNames from 'classnames';
import type { FC, SyntheticEvent } from 'react';
import { useState } from 'react';
import { TriangleFill } from 'react-bootstrap-icons';

import { useDispatch } from 'react-redux';
import type { NodeData } from '../../../global/types';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from './NodeFontSizePicker.module.css';

const textSizeOptions = [10, 12, 14, 18, 24, 36, 48, 64, 80, 144, 288];

type NodeFontSizePickerProps = {
  node: NodeData;
  isEdge?: boolean;
  nodeFontSizeVisible: boolean;
  setNodeFontSizeVisible: (value: boolean) => void;
};

const NodeFontSizePicker: FC<NodeFontSizePickerProps> = ({
  node,
  nodeFontSizeVisible,
  setNodeFontSizeVisible,
  isEdge = false,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { nodesSharedState, edgesSharedState, defaultEdgeStyle } = useTypedSelector((state) => state.diagram);

  const handleUpdateNodeFontSize = (fontSize: number) => {
    if (isEdge) {
      const inEdge = edgesSharedState?.get(node.id);

      if (inEdge) {
        inEdge.data = {
          ...inEdge.data,
          autoFontSize: false,
          fontSize,
        };
        dispatch.diagram.setDefaultEdgeStyle({
          ...defaultEdgeStyle,
          data: {
            ...defaultEdgeStyle.data,
            autoFontSize: false,
            fontSize,
          },
        });
        edgesSharedState && edgesSharedState.set(inEdge.id, inEdge);
      }
    } else {
      const inNode = nodesSharedState?.get(node.id);

      if (inNode) {
        inNode.data = {
          ...inNode.data,
          autoFontSize: false,
          fontSize,
        };
        nodesSharedState && nodesSharedState.set(inNode.id, inNode);
      }
    }
  };

  const handleUpClick = async (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const currentFontSize = node?.fontSize || textSizeOptions.at(0);
    const fontSizeIndex = textSizeOptions.findIndex((i) => i === currentFontSize);
    const newFontSize = textSizeOptions?.[fontSizeIndex + 1] || textSizeOptions.at(-1);

    if (newFontSize) handleUpdateNodeFontSize(newFontSize);
  };

  const handleDownClick = async (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const currentFontSize = node?.fontSize || textSizeOptions.at(0);
    const fontSizeIndex = textSizeOptions.findIndex((i) => i === currentFontSize);
    const newFontSize = textSizeOptions?.[fontSizeIndex - 1] || textSizeOptions.at(0);

    if (newFontSize) handleUpdateNodeFontSize(newFontSize);
  };

  const handleSelectSize = (event: SyntheticEvent, sizeIndex: number) => {
    event.preventDefault();
    event.stopPropagation();
    handleUpdateNodeFontSize(textSizeOptions[sizeIndex]);
    setNodeFontSizeVisible(false);
  };

  const handleSelectAutoSize = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (isEdge) {
      const inEdge = edgesSharedState?.get(node.id);

      if (inEdge) {
        inEdge.data = {
          ...inEdge.data,
          autoFontSize: true,
        };
        dispatch.diagram.setDefaultEdgeStyle({
          ...defaultEdgeStyle,
          data: {
            ...defaultEdgeStyle.data,
            autoFontSize: true,
          },
        });
        edgesSharedState && edgesSharedState.set(inEdge.id, inEdge);
      }
    } else {
      const inNode = nodesSharedState?.get(node.id);

      if (inNode) {
        inNode.data = {
          ...inNode.data,
          autoFontSize: true,
        };
        nodesSharedState && nodesSharedState.set(inNode.id, inNode);
      }
    }

    setNodeFontSizeVisible(false);
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      onDoubleClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      className={classNames(styles.wrapper, 'nopan')}
      onClick={() => setNodeFontSizeVisible(!nodeFontSizeVisible)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showTooltip && !nodeFontSizeVisible && <div className="main-tooltip">Font Size</div>}
      <div className={styles.digits}>{node?.autoFontSize ? 'auto' : node?.fontSize || 12}</div>
      <div className={styles.iconWrapper}>
        <div onClick={handleUpClick} className={styles.icon}>
          <TriangleFill size={7} color="black" />
        </div>
        <div onClick={handleDownClick} className={classNames(styles.triangleDown, styles.icon)}>
          <TriangleFill size={7} color="black" />
        </div>
      </div>
      {nodeFontSizeVisible && (
        <ul className={classNames('list-group position-absolute text-center', styles.dropdown)}>
          {!isEdge && (
            <li
              className={classNames('list-group-item', {
                [styles.activeItem]: node?.autoFontSize,
              })}
              key={100}
              onClick={handleSelectAutoSize}
            >
              auto
            </li>
          )}
          {textSizeOptions.map((i, idx) => (
            <li
              className={classNames('list-group-item list-group-item-action', {
                [styles.activeItem]: node?.fontSize === i && !node?.autoFontSize,
              })}
              key={i}
              onClick={(e) => handleSelectSize(e, idx)}
            >
              {i}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NodeFontSizePicker;
