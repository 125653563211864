import type { FC } from 'react';
import type { ThreatModelPriorityType } from '../../global/types';
import { ThreatModelPriorityTypes } from '../../global/types';
import UiPriority from '../common/UiPriority/UiPriority';
import styles from './PriorityModal.module.css';

type PriorityModalProps = {
  onClick: (priority: string) => void;
  currentPriority: ThreatModelPriorityType;
};

const PriorityModal: FC<PriorityModalProps> = ({ onClick, currentPriority }) => {
  let options = [
    {
      type: ThreatModelPriorityTypes.CRITICAL,
      component: <UiPriority type="critical" />,
    },
    {
      type: ThreatModelPriorityTypes.HIGH,
      component: <UiPriority type="high" />,
    },
    {
      type: ThreatModelPriorityTypes.MEDIUM,
      component: <UiPriority type="medium" />,
    },
    {
      type: ThreatModelPriorityTypes.LOW,
      component: <UiPriority type="low" />,
    },
  ];

  options = options.filter((item) => item.type !== currentPriority);

  return (
    <div className={styles.modal}>
      {options.map((item) => (
        <div
          key={item.type}
          className={styles.item}
          onClick={(e) => {
            e.stopPropagation();
            onClick(item.type);
          }}
        >
          {item.component}
        </div>
      ))}
    </div>
  );
};

export default PriorityModal;
