import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { useFormik } from 'formik';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { getChosenPlan } from '../../helpers/plan';
import { HOME_ROUTE } from '../../store/constants/route-constants';
import type { RootState } from '../../store/store';

const BillingPage: FC = () => {
  const authState = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();
  const chosenPlan = getChosenPlan();

  // if (!authState.isAuthenticated) {
  //   return <Navigate to="/" />;
  // }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email format').required('Required'),
    }),
    onSubmit: async (values) => {
      if (!stripe || !elements) {
        return;
      }

      navigate(HOME_ROUTE);
    },
  });

  const buttonClass = classNames({
    'bg-blue-600 hover:bg-blue-600': formik.isValid,
    'bg-gray-400 ': !formik.isValid,
  });

  const onExistedCardClick = async (paymentMethodId: string, priceId: string) => {};

  const onDetachCardClick = (paymentMethodId: string) => {
    navigate(HOME_ROUTE);
  };

  return (
    <div>
      <div className="min-h-screen flex flex-col justify-center shadow-xl rounded-lg">
        <div className="max-w-md w-full mx-auto">
          <div className="text-center font-medium text-xl">Billing Details</div>
        </div>
        <div className="max-w-md w-full mx-auto mt-4 bg-white p-8 border border-gray-300">
          <div className="text-center">
            <h4>
              You have chosen <span className="text-blue-500 font-bold">{chosenPlan.nickname}</span> plan
            </h4>
          </div>
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="text-sm font-bold text-gray-600 block">
                Email
              </label>
              <input
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="email"
                className="w-full p-2 border border-gray-300 rounded mt-1"
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-red-500">{formik.errors.email}</p>
              ) : null}
            </div>
            <div>
              <label htmlFor="email" className="text-sm font-bold text-gray-600 block">
                Card details
              </label>
              <CardElement className="w-full p-2 border border-gray-300 rounded mt-1" />
            </div>
            <div>
              <button type="submit" className={`${buttonClass} w-full py-2 px-4 rounded-md text-white text-sm`}>
                Pay
              </button>
            </div>
          </form>
        </div>
        <div className="max-w-md w-full mx-auto ">
          {authState.paymentMethods?.map(({ id, card }) => (
            <div
              key={id}
              className="flex mb-0.5 border border-r-2 lg:px-4 py-1 text-center text-primary-dark bg-primary-white"
            >
              <input
                type="text"
                disabled
                className="text-left w-1/2 text-gray-600 font-bold text-center pl-1 align-baseline"
                placeholder={`${card.brand} - ${card.last4}`}
              />
              <p className="text-right w-1/2 pr-1">
                <button
                  onClick={() => onDetachCardClick(id)}
                  className="bg-blue-600 hover:bg-blue-600 py-2 px-4 rounded-md text-white text-sm mr-1"
                >
                  Detach
                </button>
                <button
                  onClick={() => onExistedCardClick(id, chosenPlan.id)}
                  className="bg-blue-600 hover:bg-blue-600 py-2 px-4 rounded-md text-white text-sm"
                >
                  Use Card
                </button>
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BillingPage;
