import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from 'reactflow';

import classNames from 'classnames';
import { setColorToMarkers } from '../../../helpers/diagram';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import DiagramNodeAttribute from '../NodeAttributePopup';
import styles from './CustomEdge.module.css';
import EdgeInput from './EdgeInput';
import EdgePopUpTools from './EdgePopUpTools/EdgePopUpTools';

const CustomEdge: FC<any> = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  data,
  id,
  selected,
}) => {
  const edgePathParams = {
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  };
  const currentUserId = useTypedSelector((state) => state.user?.current?.id) || '';
  const collaborators = useTypedSelector((state) => state.diagram.collaborators);
  const hasCollaborators =
    collaborators?.length &&
    !!collaborators.filter((id) => {
      return data?.selectedBy?.includes(id);
    })?.length;
  const [path, labelX, labelY] = getBezierPath(edgePathParams);
  const [attributesPopup, setAttributesPopup] = useState<any>({
    visible: false,
    componentId: null,
  });
  const { selectedNode, attributes, mitigatingAttributes } = useTypedSelector((state) => state.diagram);

  if (!Array.isArray(data?.selectedBy)) {
    data.selectedBy = selected ? [currentUserId] : [];
  }

  useEffect(() => {
    if (selected) {
      const edgeElement = document.querySelector(`g[data-testid='rf__edge-${id}']`);
      edgeElement?.parentElement?.insertAdjacentElement('beforeend', edgeElement);
      setColorToMarkers(data?.color);
    }
  }, [selected]);

  useEffect(() => {
    return () => {
      setAttributesPopup({ visible: false, componentId: null });
    };
  }, [selected]);

  useEffect(() => {
    if (attributes?.length || (mitigatingAttributes && Object.keys(mitigatingAttributes)?.length)) {
      setAttributesPopup({
        ...attributesPopup,
        visible: false,
      });
    }
  }, [attributes?.length || mitigatingAttributes]);

  const isSelected = selected && !attributesPopup.visible && data?.selectedBy?.includes(currentUserId);

  const getMarkerEnd = (slt: boolean) => {
    if (data?.markerType) {
      if (data.markerType === 'end' || data.markerType === 'both') {
        return slt ? 'url(#selectedEdgeTriangle)' : markerEnd;
      }

      return slt ? 'url(#selectedEdgeSquare)' : undefined;
    }

    return slt ? 'url(#selectedEdgeTriangle)' : markerEnd;
  };

  const getMarkerStart = (slt: boolean) => {
    if (data?.markerType) {
      if (data.markerType === 'start' || data.markerType === 'both') {
        return slt ? 'url(#selectedEdgeTriangle)' : markerEnd;
      }

      return slt ? 'url(#selectedEdgeSquare)' : undefined;
    }

    return slt ? 'url(#selectedEdgeSquare)' : undefined;
  };

  return (
    <>
      <BaseEdge
        style={{
          strokeWidth: `${data?.borderWidth || 2}px`,
          stroke: data?.color || 'var(--dark-gray)',
        }}
        path={path}
        markerEnd={getMarkerEnd(isSelected)}
        markerStart={getMarkerStart(isSelected)}
        interactionWidth={10}
      />

      <EdgeLabelRenderer>
        <div
          data-isedge={1}
          data-edgeid={id}
          data-edgex={labelX}
          data-edgey={labelY}
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            cursor: selectedNode?.length ? 'crosshair' : 'pointer',
            backgroundColor: data?.color ? data.color.substring(0, 7) : '',
            zIndex: isSelected ? 100 : 2,
            padding: data?.hiddenLabel ? 0 : '3px',
          }}
          className={classNames('nodrag nopan fs-12 position-absolute text-center', styles.edgeComponent, {
            [styles.hasCollaborators]: hasCollaborators,
          })}
        >
          {data?.hiddenLabel ? null : (
            <EdgeInput
              id={id}
              value={data.label}
              color={data?.color}
              selected={selected}
              fontSize={data?.fontSize || 12}
              autoFontSize={data?.autoFontSize}
              fontBold={data?.textStyle?.isBold}
              fontColor={data?.fontColor}
            />
          )}
          {isSelected && (
            <EdgePopUpTools
              edge={{
                id,
                color: data?.color,
                markerType: data?.markerType,
                borderWidth: data?.borderWidth,
                fontSize: data?.fontSize,
                autoFontSize: data?.autoFontSize,
                textStyle: data?.textStyle,
                fontColor: data?.fontColor || '#ffffff',
                hiddenLabel: data?.hiddenLabel,
              }}
              setAttributesPopup={() => {
                setAttributesPopup({
                  componentId: id,
                  visible: true,
                  id,
                });
              }}
            />
          )}
          {selected && attributesPopup.visible && (
            <div className={styles.attributesPopupWrap}>
              <DiagramNodeAttribute data={attributesPopup} setData={setAttributesPopup} />
            </div>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
