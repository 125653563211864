import classNames from 'classnames';
import moment from 'moment/moment';
import { useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Progress } from 'reactstrap';
import { SKELETON_COLORS } from '../../../global/constants';
import type { Running } from '../../../global/types';
import { getTableHeight } from '../../../helpers/app';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import RunningTableSortColumn from '../RunningTableSortColumn/RunningTableSortColumn';
import styles from './RunningTableList.module.css';

const RunningTableList = () => {
  const dispatch = useDispatch<Dispatch>();
  const params = useParams();
  const { id: projectId } = params;

  const [loading, setLoading] = useState(false);
  const { runningList, runningListQuery } = useTypedSelector((state) => state.running);
  const canFetchMore = runningListQuery.page * runningListQuery.limit < runningListQuery.count;
  const tableRef = useRef<any>('threatRegisterTableRef');

  const fetchMoreData = async () => {
    const nextPage = runningListQuery.page + 1;

    if (nextPage * runningListQuery.limit < runningListQuery.count) {
      setLoading(true);
      await dispatch.running.getRunningListByProjectId({
        ...runningListQuery,
        projectId,
        page: nextPage,
      });
      setLoading(false);
    }
  };

  if (runningList === null) {
    return (
      <Skeleton
        style={{ lineHeight: '25px', marginBottom: '5px' }}
        width="100%"
        height={30}
        count={15}
        borderRadius={10}
        baseColor={SKELETON_COLORS.BASE}
        highlightColor={SKELETON_COLORS.HIGHLIGHT}
      />
    );
  }

  return (
    <div>
      <div className={styles.tableTitle}>Runnings</div>
      <InfiniteScroll
        scrollableTarget="scrollableRunningTargetTable"
        next={fetchMoreData}
        hasMore={canFetchMore}
        loader={
          loading ? (
            <div className="w-100 m-auto text-center">
              <Progress
                animated
                color="secondary"
                value="100"
                style={{
                  height: '7px',
                  width: '100px',
                  margin: 'auto',
                }}
              />
            </div>
          ) : null
        }
        dataLength={runningList?.length || 0}
      >
        <div
          id="scrollableTargetTable"
          ref={tableRef}
          style={{
            height: getTableHeight(params, 335),
          }}
          className={styles.tableWrap}
        >
          <table className={styles.table}>
            <thead className="position-sticky top-0 z-1">
              <tr>
                <th style={{ width: '15%' }} className={styles.tableHead}>
                  Running
                  <RunningTableSortColumn column="serial_id" />
                </th>
                {/* <th style={{ width: "10%" }} className={styles.tableHead}> */}
                {/*  Status */}
                {/*  <ThreatRegisterTableSortColumn column="status" /> */}
                {/* </th> */}
                <th style={{ width: '10%', minWidth: '90px' }} className={styles.tableHead}>
                  Branch
                  <RunningTableSortColumn column="branch" />
                </th>
                <th style={{ width: '10%' }} className={styles.tableHead}>
                  Commit
                  <RunningTableSortColumn column="commit_name" />
                </th>
                <th style={{ width: '10%' }} className={styles.tableHead}>
                  Created
                  <RunningTableSortColumn column="created_at" />
                </th>
              </tr>
            </thead>
            <tbody id="scrollableTargetTable">
              {runningList?.map((running: Running) => (
                <tr
                  key={running.id}
                  onClick={() => null}
                  className={classNames({
                    [styles.activeRow]: (params?.['*'] || '').includes(running.id),
                  })}
                >
                  <td>#{running.serial_id}</td>
                  {/* <td */}
                  {/*  onClick={(e) => null } */}
                  {/* > */}
                  {/*  <div className={styles.statusCel}> */}
                  {/*    --*/}
                  {/*  </div> */}
                  {/* </td> */}
                  <td
                    onClick={(event: any) => {
                      event.stopPropagation();
                    }}
                  >
                    <div className={styles.priorityCel}>{running.branch}</div>
                  </td>
                  <td>{running.commit_name}</td>
                  <td className={styles.priorityCel}>
                    <div className={styles.descriptionColumn}>{moment(running.created_at).fromNow(true)}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default RunningTableList;
