import type { FC } from 'react';
import { useState } from 'react';
import { CreditCard } from 'react-bootstrap-icons';
import { Spinner } from 'reactstrap';
import { TIER } from '../../global/constants';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import { TIERS_PORTAL_LINK } from '../../store/constants/api-constants';
import http from '../../store/http/http-common';
import UiButton from '../common/UIButton/UiButton';
import UpgradePlan from '../UpgradePlan/UpgradePlan';

const ManageSubscriptionButton: FC = () => {
  const { customerLimits } = useTypedSelector((state) => state.tiers);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenPortalPage = async () => {
    setIsLoading(true);
    try {
      const response = await http.get(TIERS_PORTAL_LINK);
      setIsLoading(false);
      window.open(response.data.redirectUrl, '_blank');
    } catch (err) {
      // handle error
    }
  };

  if (!customerLimits) {
    return null;
  }

  if (customerLimits?.isBetaUser) {
    return null;
  }

  return (
    <div className="mt-2">
      {customerLimits?.tier === TIER.FREE ? (
        <UpgradePlan
          trigger={
            <UiButton>
              <CreditCard /> <span className="ms-2">Manage Subscription</span>
            </UiButton>
          }
        />
      ) : (
        <UiButton onClick={handleOpenPortalPage}>
          {isLoading ? <Spinner size="sm" /> : <CreditCard />}
          <span className="ms-2">Manage Subscription</span>
        </UiButton>
      )}
    </div>
  );
};

export default ManageSubscriptionButton;
