import classNames from 'classnames';
import React from 'react';
import { Clipboard2Check, LayoutSidebarInsetReverse } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { THREATS_REGISTER_ROUTE } from '../../../store/constants/route-constants';
import type { Dispatch } from '../../../store/store';
import styles from './index.module.css';

export const ToggleDrawerButton = () => {
  const navigate = useNavigate();
  const { visible, drawnWidth } = useTypedSelector((state) => state.drawn);
  const dispatch = useDispatch<Dispatch>();

  const onToggleDrawn = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch.drawn.setVisible(!visible);
  };

  return (
    <div
      className={classNames('block rounded-1 shadow bg-white', styles.topRightToolItem)}
      style={{
        marginRight: visible ? `${drawnWidth}px` : '0',
      }}
    >
      <div
        className={classNames(styles.toolItemWrap, {
          [styles.activeTool]: visible,
        })}
        onClick={onToggleDrawn}
      >
        <LayoutSidebarInsetReverse className="d-block" fontSize={20} color={visible ? '#FFFFFF' : '#7A94B0'} />
      </div>

      {/* <div
        className={classNames(styles.toolItemWrap, {
          [styles.activeTool]: false,
        })}
        onClick={() => {}}
      >
        <img
          src={threatRegister ? ThreatRegisterFullIcon : ThreatRegisterIcon}
        />
      </div> */}

      <div
        className={classNames(styles.toolItemWrap, {
          [styles.activeTool]: false,
        })}
        onClick={() => {
          navigate(THREATS_REGISTER_ROUTE.replace('/', ''));
          dispatch.drawn.setVisible(false);
        }}
      >
        <Clipboard2Check className="d-block" fontSize={20} color={visible ? '#FFFFFF' : '#7A94B0'} />
      </div>
    </div>
  );
};
