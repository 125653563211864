import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { ArrowUpRight } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import type { SUBSCRIPTION_PLAN_PERIOD } from '../../global/constants';
import { CHARGEBEE_PLAN_IDS, CONTACT_SALES_URL, TIER } from '../../global/constants';
import { useInitChargebee } from '../../hooks/useInitChargebee';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import { ROLE } from '../../store/models/user';
import type { Dispatch } from '../../store/store';
import type { SUBSCRIPTION_PLANS } from './ChoosePricingModal';
import styles from './PricingCard.module.css';
import SeatsCounter from './SeatsCounter';

type Props = {
  subscriptionPeriod: keyof typeof SUBSCRIPTION_PLAN_PERIOD;
  planKey: keyof typeof SUBSCRIPTION_PLANS;
  customPrice?: number;
} & (typeof SUBSCRIPTION_PLANS)[keyof typeof SUBSCRIPTION_PLANS];

const PricingCard: FC<Props> = ({
  title,
  planKey,
  cta,
  description,
  isPopular,
  price,
  features,
  subscriptionPeriod,
  customPrice,
  addOns,
  footerText,
}) => {
  const dispatch = useDispatch<Dispatch>();

  const customerLimits = useTypedSelector((state) => state.tiers.customerLimits);

  const { current, users } = useTypedSelector((state) => state.user);
  const [quantity, setQuantity] = useState(users?.length || 1);
  const enabledUsersCount = users?.filter((u) => u.isEnabled)?.length || 0;
  const { reregisterChargebee } = useInitChargebee();

  useEffect(() => {
    reregisterChargebee();
  }, [quantity, subscriptionPeriod]);

  useEffect(() => {
    if (users) {
      setQuantity(users.filter((i) => i.isEnabled)?.length || 1);
    }
  }, [users]);

  return (
    <div className={classNames(styles.PricingCardContainer, isPopular && styles.Popular)}>
      <div className={styles.PricingCardTop}>
        <div className={classNames(styles.PricingCardTitle, isPopular && styles.Popular)}>
          <div style={{ fontSize: '28px' }}>{title}</div>
          {isPopular && <span className={styles.PricingCardPopularBadge}>Most popular</span>}
        </div>
        {[features[0], features[1], features[2]]?.map((feature, index) => (
          <div key={index} className={`${styles.PricingCardFeatureItem} fw-bold`}>
            {feature.icon}
            {feature.text}
          </div>
        ))}
        <div className={styles.PricingCardDescription}>{description}</div>
        <div className="fs-2 fw-bolder">{footerText}</div>
        {price !== null && (
          <div className="mb-4">
            <span className="fs-1 fw-bolder">${price[subscriptionPeriod]}</span>
            <span className={styles.PricingCardPaymentTermsText}>{' per user'}</span>
            <div>
              <div className="mb-1">Users:</div>
              <div className="d-flex align-items-center">
                <SeatsCounter
                  value={quantity}
                  onChange={(val) => {
                    if (val < enabledUsersCount) dispatch.user.setPricingPageModalVisible(true);
                    else setQuantity(val);
                  }}
                  disableReduction={quantity - 1 < enabledUsersCount}
                />
                <div className="fs-5 fw-bold ms-3 text-center w-100">
                  <span>Total:</span>
                  <span className="ms-1">${price[subscriptionPeriod] * quantity}</span>
                  <span className="fs-6">
                    <br />
                    {subscriptionPeriod === 'ANNUALLY' ? ' per year' : ' per month'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {customPrice !== undefined && (
          <div className="mb-1">
            <span className="fs-1 fw-bolder">${customPrice}</span>
            <span className={styles.PricingCardPaymentTermsText}>per user/month</span>
          </div>
        )}
      </div>
      {customerLimits?.tier === planKey ? null : planKey === TIER.ENTERPRISE ? (
        current?.role === ROLE.ADMIN ? (
          <a href={CONTACT_SALES_URL} target="_blank" rel="noreferrer">
            <button type="button" className={classNames(styles.PricingCardCTAButton, isPopular && styles.Popular)}>
              {cta}
              <ArrowUpRight className="ms-2" />
            </button>
          </a>
        ) : (
          <button
            type="button"
            onClick={() => toast.warning('Only admin can buy the subscription.')}
            className={classNames(styles.PricingCardCTAButton, isPopular && styles.Popular)}
          >
            {cta}
            <ArrowUpRight className="ms-2" />
          </button>
        )
      ) : current?.role === ROLE.ADMIN ? (
        <a
          onClick={(e) => e.preventDefault()}
          data-cb-type="checkout"
          data-cb-item-0={CHARGEBEE_PLAN_IDS[subscriptionPeriod]}
          data-cb-item-0-quantity={quantity}
          key={quantity + subscriptionPeriod} // Force update
        >
          <button type="button" className={classNames(styles.PricingCardCTAButton, isPopular && styles.Popular)}>
            {cta}
            <ArrowUpRight className="ms-2" />
          </button>
        </a>
      ) : (
        <button
          type="button"
          onClick={() => toast.warning('Only admin can buy the subscription.')}
          className={classNames(styles.PricingCardCTAButton, isPopular && styles.Popular)}
        >
          {cta}
          <ArrowUpRight className="ms-2" />
        </button>
      )}

      {customerLimits?.tier === planKey && (
        <button
          disabled
          type="button"
          className={classNames(styles.PricingCardCTAButton, styles.PricingCardCTAButtonDisabled)}
        >
          Current Plan
        </button>
      )}

      <div>
        {features?.map((feature, index) =>
          index > 2 ? (
            <div key={index} className={`${styles.PricingCardFeatureItem} ${feature.isBold ? 'fw-bold' : null}`}>
              {feature.icon}
              {feature.text}
            </div>
          ) : null,
        )}
      </div>
      {!!addOns?.length && (
        <div>
          <div className="fw-bold mb-1">Add-Ons</div>
          {addOns?.map((addOn, index) => (
            <div key={index} className={`${styles.PricingCardFeatureItem} ${addOn.isBold ? 'fw-bold' : null}`}>
              {addOn.icon}
              {addOn.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PricingCard;
