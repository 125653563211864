import type { FC } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from './ElementRegisterTable.module.css';

type ElementRegisterSortSubColumnProps = {
  column: string;
  componentId: string;
};

enum ORDER_TYPE {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const ElementRegisterSortSubColumn: FC<ElementRegisterSortSubColumnProps> = ({ column, componentId }) => {
  const dispatch = useDispatch<Dispatch>();
  const { subThreatRegisterListQuery } = useTypedSelector((state) => state.threats);

  const handleSort = () => {
    dispatch.threats.getThreatsByComponentId({
      ...subThreatRegisterListQuery,
      page: 0,
      componentId,
      sort: column,
      order: subThreatRegisterListQuery.order === ORDER_TYPE.ASC ? ORDER_TYPE.DESC : ORDER_TYPE.ASC,
    });
  };

  return (
    <div onClick={handleSort}>
      <CaretUpFill className={styles.tableSortIconUp} />
      <CaretDownFill className={styles.tableSortIconDown} />
    </div>
  );
};
