import { FormGroup } from 'reactstrap';
import { AdminDropdown } from '../../../components/AdminPage/AdminDropdown/AdminDropdown';
import { UploadLogoForm } from '../../../components/AdminPage/UploadLogoForm/UploadLogoForm';
import ManageSubscriptionButton from '../../../components/ManageSubscriptionButton';
import { ProfileCompanyForm } from '../../../components/Profile/ProfileCompanyForm';
import { useTypedSelector } from '../../../hooks/useTypeSelector';

import styles from '../styles.module.css';

const SettingsPage = () => {
  const { customerLimits } = useTypedSelector((state) => state.tiers);

  return (
    <div style={{ padding: 10 }}>
      <h3 className={styles.header}>Settings</h3>
      <FormGroup row style={{ width: '600px' }} className="m-auto d-flex mt-2 pb-1 fs-14">
        <AdminDropdown title="Organization logo" description="" content={<UploadLogoForm />} />
        <ProfileCompanyForm />

        <AdminDropdown
          title="Subscription"
          description="Manage subscription plan for the organization"
          content={<ManageSubscriptionButton />}
          hide={customerLimits?.isBetaUser || !customerLimits}
        />
      </FormGroup>
    </div>
  );
};

export default SettingsPage;
