import { createModel } from '@rematch/core';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import type { Edge, Node } from 'reactflow';
import { NODES } from '../../global/constants';
import type { Representation } from '../../global/types';
import { getZIndex, setNodesPosition } from '../../helpers/diagram';
import { getId } from '../../helpers/user';
import { REPRESENTATION_URL, STICKER_URL } from '../constants/api-constants';
import { HOME_ROUTE } from '../constants/route-constants';
import http from '../http/http-common';
import type { RootModel } from './index';

type RepresentationState = {
  data: Representation | null;
  threatRegisterRepresentation: {
    threatId: string;
    data: {
      nodes: Node[];
      edges: Edge[];
    };
  } | null;
  templatesModalVisibility: boolean;
  activeTemplateId: string | null;
};

export const representation = createModel<RootModel>()({
  state: {
    data: null,
    threatRegisterRepresentation: null,
    templatesModalVisibility: false,
    activeTemplateId: null,
  } as RepresentationState,
  reducers: {
    setData(state, data) {
      return { ...state, data };
    },
    resetRepresentationState(state) {
      return { ...state, data: null };
    },
    setThreatRegisterRepresentation(state, threatRegisterRepresentation) {
      return { ...state, threatRegisterRepresentation };
    },
    setTemplatesModalVisibility(state, templatesModalVisibility) {
      return { ...state, templatesModalVisibility };
    },
    setActiveTemplateId(state, activeTemplateId) {
      if (activeTemplateId === null) {
        document.querySelector('.react-flow__pane')?.classList?.remove('templateCursor');
      }

      return { ...state, activeTemplateId };
    },
  },
  effects: (dispatch) => {
    return {
      async createRepresentation({ threatModelId, format }) {
        try {
          if (threatModelId && format) {
            const response = await http.post(REPRESENTATION_URL, {
              threatModelId,
              format,
            });

            if (response.status === 201) {
              return response.data;
            }
          }
        } catch (error: any) {
          toast.warning(error.message || '');
        }
      },
      async getRepresentationById(representationId: string) {
        try {
          const response = await http(`${REPRESENTATION_URL}/${representationId}`);

          if (response.status === 200) {
            this.setData(response.data);

            return response.status === 200;
          }
        } catch (error: any) {
          toast.warning(error.message || '');
        }
      },
      async getRepresentation(representationId: string) {
        const result = await http(`${REPRESENTATION_URL}/${representationId}`);

        if (result.data?.data?.nodes) {
          dispatch.threatModel.setCanPdfExport(!!result.data.data.nodes?.length);
        }

        if (result instanceof AxiosError && result.response?.status === 403) {
          return (window.location.href = HOME_ROUTE);
        }

        if (result.status === 200) {
          if (result.data?.threatModelId) {
            dispatch.threatModel.getThreatModel(result.data.threatModelId);
            delete result.data.threatModelId;
          }

          this.setData(result.data);
        }
      },
      async getRepresentationByThreat(threatId: string, state) {
        const result = await http(`${REPRESENTATION_URL}/by-threat/${threatId}`);

        if (result.status === 200 && result.data?.data) {
          dispatch.representation.setThreatRegisterRepresentation({
            data: result.data?.data,
            threatId,
          });
        }
      },
      async updateRepresentation({ id, title }) {
        try {
          if (id && title) {
            await http.patch(`${REPRESENTATION_URL}/update/${id}`, {
              title,
            });
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },
      async cloneNodeAndMakeComponentCopy({ node, idToCopyFrom }) {
        await dispatch.drawn.makeComponentCopy({
          idToCopyFrom,
          idToApply: node.id,
        });
      },
      async addNewNodeEffect({ node, diagramId }) {
        if (node.type === NODES.STICKER_NODE && diagramId) {
          const result = await http.put(`${STICKER_URL}`, {
            id: node.id,
            representationId: diagramId,
            description: node?.data?.stickerData?.description || '',
          });

          if (result.status === 200) dispatch.drawn.setStickersVisible(true);
        } else {
          dispatch.drawn.saveComponent(node);
        }
      },
      async cloneNodeEffect({ node, nodeId }, state) {
        const nodes = Array.from(state.diagram.nodesSharedState?.values() || []);
        const previousNode = nodes.find((item) => item.id === node.id);
        const newNode: Node = {
          id: nodeId,
          type: previousNode!.type,
          position: {
            x: previousNode!.position.x + 10,
            y: previousNode!.position.y + 10,
          },
          positionAbsolute: {
            x: (previousNode?.positionAbsolute?.x || previousNode?.position?.x || 0) + 10,
            y: (previousNode?.positionAbsolute?.y || previousNode?.position?.y || 0) + 10,
          },
          selected: true,
          data: {
            ...previousNode!.data,
            user: state.user.current,
          },
          zIndex: getZIndex(previousNode!.type!),
          width: previousNode!.width,
          height: previousNode!.height,
          parentNode: previousNode?.parentNode,
          style: previousNode?.style || {},
        };

        if (node.type === NODES.STICKER_NODE) {
          newNode.data.stickerData = { ...previousNode?.data?.stickerData };
        }

        if (previousNode) {
          state.diagram.nodesSharedState?.set(previousNode.id, {
            ...previousNode,
            selected: false,
          });
        }

        if (node.type === NODES.STICKER_NODE || node.type === NODES.TRUST_BOUNDARY_NODE) {
          state.diagram.nodesSharedState?.set(newNode.id, newNode);

          return;
        }

        state.diagram.nodesSharedState?.set(newNode.id, structuredClone(newNode));
        await this.cloneNodeAndMakeComponentCopy({
          node: newNode,
          idToCopyFrom: node.id,
        });

        return newNode.id;
      },
      async cloneEdgeEffect(edge, state) {
        try {
          const edgeId = getId();
          const newEdge = { ...edge, id: edgeId };
          state.diagram.edgesSharedState?.set(newEdge.id, newEdge);
          await dispatch.drawn.makeComponentCopy({
            idToCopyFrom: edge.id,
            idToApply: edgeId,
          });
        } catch (e: any) {
          console.error(e.message);
        }
      },
      async deleteSticker(stickerId: string) {
        await http.delete(`${STICKER_URL}/${stickerId}`);
      },
      async updateSticker(data) {
        await http.put(`${STICKER_URL}`, data);
      },
      removeSelectedNodes(_, state) {
        if (!state.diagram.canCopy) return;

        Array.from(state.diagram.nodesSharedState?.values() || []).forEach((node) => {
          if (node.selected) {
            dispatch.diagram.deleteFromNodesSharedState(node.id);
          }
        });
        Array.from(state.diagram.edgesSharedState?.values() || []).forEach((edge) => {
          if (edge.selected) {
            dispatch.diagram.deleteFromEdgesSharedState(edge.id);
          }
        });
      },
      deselectComponent(componentId, state) {
        if (!componentId) return;

        const selectedNode = state.diagram.nodesSharedState?.get(componentId);

        if (selectedNode?.selected) {
          selectedNode.selected = false;
          state.diagram.nodesSharedState?.set(componentId, selectedNode);
        }

        const selectedEdge = state.diagram.edgesSharedState?.get(componentId);

        if (selectedEdge?.selected) {
          selectedEdge.selected = false;
          state.diagram.edgesSharedState?.set(componentId, selectedEdge);
        }
      },
      async deleteRepresentation(id) {
        const response = await http.delete(`${REPRESENTATION_URL}/${id}`);

        if (response.status === 200) {
          toast.success('Deleted successfully!');

          return true;
        }
      },
      async getTemplates() {
        try {
          const response = await http(`${REPRESENTATION_URL}/templates`);

          if (response.status === 200) {
            return response.data;
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },
      async createFromTemplate({ templateId, representationId, position }, state) {
        try {
          const response = await http.post(`${REPRESENTATION_URL}/templates`, {
            templateId,
            representationId,
          });

          if (response.status === 201 && response?.data?.nodes?.length) {
            const nodes = setNodesPosition(response.data.nodes, position);
            nodes.forEach((node: Node) => {
              state.diagram?.nodesSharedState?.set(node.id, node);
            });
            response?.data?.edges?.forEach((edge: Edge) => {
              state.diagram?.edgesSharedState?.set(edge.id, edge);
            });
            const timeout = setTimeout(() => {
              dispatch.threatModel.updateCurrentThreatModel();
              clearTimeout(timeout);
            }, 3000);
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },
    };
  },
});
