import classNames from 'classnames';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ManageSessionDuration } from '../../../components/AdminPage/ManageSessionDuration/ManageSessionDuration';
import { ManageTwoFactorAuth } from '../../../components/AdminPage/ManageTwoFactorAuth/ManageTwoFactorAuth';

import { EnableAPIAccessForm } from '../../../components/common/EnableAPIAccessForm/EnableAPIAccessForm';
import { ManageSamlForm } from '../../../components/common/ManageSamlForm/ManageSamlForm';
import type { Dispatch } from '../../../store/store';
import styles from '../styles.module.css';

const SecurityPage = () => {
  const dispatch = useDispatch<Dispatch>();

  const SHOW_MANAGE_API_ACCESS = process.env?.REACT_APP_SHOW_MANAGE_API_ACCESS === 'true';

  useEffect(() => {
    dispatch.customer.getSessionList();
  }, [dispatch.customer]);

  return (
    <div style={{ padding: 10 }}>
      <h3 className={styles.header}>Security</h3>

      <div className={classNames('d-flex flex-column mx-auto pb-1 fs-14 gap-3', styles.content)}>
        <ManageSamlForm />
        <hr className="m-0" />
        <ManageTwoFactorAuth />
        <hr className="m-0" />
        <ManageSessionDuration />
        <hr className="m-0" />
        {SHOW_MANAGE_API_ACCESS && <EnableAPIAccessForm />}
        <hr className="m-0" />
      </div>
    </div>
  );
};

export default SecurityPage;
