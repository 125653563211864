import { Octokit } from 'octokit';

// export const LAMBDA_URL = "https://lhw6g52r7i.execute-api.eu-north-1.amazonaws.com/default/ruby-ast-transformer";
export const LAMBDA_URL = 'https://emaizrlycb.execute-api.eu-central-1.amazonaws.com/default/ruby-ast-transformer';

export const highlightWithLineNumbers = (codeString: string) => {
  return codeString
    .split('\n')
    .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
    .join('\n');
};

function extractRoutes(fileContent: string) {
  // eslint-disable-next-line no-useless-escape
  const routeRegex = /^(\s*[\w\.]+)\s+(.+)$/gm;
  const routes = [];
  let match;

  while ((match = routeRegex.exec(fileContent)) !== null) {
    const detail: any = { method: match[1].trim(), path: match[2].trim() };

    if (detail.path?.includes('=>')) {
      const [routeName, route] = detail.path.split('=>');
      const [className, method] = route.split('#');
      detail.routeName = routeName.trim().replaceAll('"', '');
      detail.className = className.trim().replaceAll('"', '');
      detail.methodName = method.trim().replaceAll('"', '');
    }

    routes.push(detail);
  }

  return routes;
}

export const getFileFromGitHub = async ({ owner, repo, path, token }: any) => {
  try {
    const octokit = new Octokit({ auth: token }); // auth: 'YOUR-TOKEN'
    const result: any = await octokit.rest.repos.getContent({ owner, repo, path });

    if (result.status === 200) return result.data.content;
  } catch (e: any) {
    console.error('getFileFromGitHub', e.message);
  }
};

async function getFilesList({ owner, repo, path, token }: any) {
  try {
    const octokit = new Octokit({ auth: token }); // auth: 'YOUR-TOKEN'
    const result: any = await octokit.rest.repos.getContent({ owner, repo, path });
    const isOk = result.status === 200 && result.data?.length;

    if (!isOk) return;

    const list = [];
    const files = result.data
      .map(
        (item: any) =>
          item.type === 'file' && {
            name: item.name,
            path: item.path,
          },
      )
      .filter(Boolean);

    for await (const file of files) {
      const code = await getFileFromGitHub({ owner, repo, token, path: file.path });
      list.push({
        codeBase64: code,
        name: file.name,
      });
    }

    return list;
  } catch (e: any) {
    console.error('getFilesList', e.message);
  }
}

export const getContent = async ({ userName, repo, token, filePath = 'config/routes.rb' }: any) => {
  try {
    const octokit = new Octokit({ auth: token }); // auth: 'YOUR-TOKEN'
    const result: any = await octokit.rest.repos.getContent({
      owner: userName,
      repo,
      path: filePath,
    });

    if (result?.data?.content) {
      const code = window.atob(result.data.content);
      const routes = extractRoutes(code);
      const controllers = await getFilesList({ owner: userName, repo, token, path: 'app/controllers' });
      const models = await getFilesList({ owner: userName, repo, token, path: 'app/models' });

      return { routes, controllers, models };
    }
  } catch (e: any) {
    console.error('getContent', e.message);
  }
};
