import type { FC } from 'react';
import { Handle, Position } from 'reactflow';

type NodeHandlerProps = {
  isTopTargetConnectable?: boolean;
  isBottomTargetConnectable?: boolean;
  isRightTargetConnectable?: boolean;
  isLeftTargetConnectable?: boolean;
  isTopSourceConnectable?: boolean;
  isBottomSourceConnectable?: boolean;
  isRightSourceConnectable?: boolean;
  isLeftSourceConnectable?: boolean;
  isVisible?: boolean;
};

const NodeHandlers: FC<NodeHandlerProps> = ({
  isBottomSourceConnectable = true,
  isBottomTargetConnectable = true,
  isLeftSourceConnectable = true,
  isLeftTargetConnectable = true,
  isRightSourceConnectable = true,
  isRightTargetConnectable = true,
  isTopSourceConnectable = true,
  isTopTargetConnectable = true,
  isVisible = true,
}) => {
  const handleStyle = {
    background: '#555',
    width: '10px',
    height: '10px',
  };

  return (
    <div id="nodeHandler" style={{ opacity: isVisible ? '1' : '0' }}>
      <Handle
        type="target"
        position={Position.Top}
        id="targetTop"
        style={handleStyle}
        isConnectable={isTopTargetConnectable}
      />
      <Handle
        type="target"
        position={Position.Right}
        id="targetRight"
        style={handleStyle}
        isConnectable={isRightTargetConnectable}
      />
      <Handle
        type="target"
        position={Position.Bottom}
        id="targetBottom"
        style={handleStyle}
        isConnectable={isBottomTargetConnectable}
      />
      <Handle
        type="target"
        position={Position.Left}
        id="targetLeft"
        style={handleStyle}
        isConnectable={isLeftTargetConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="sourceRight"
        style={handleStyle}
        isConnectable={isTopSourceConnectable}
      />
      <Handle
        type="source"
        position={Position.Top}
        id="sourceTop"
        style={handleStyle}
        isConnectable={isRightSourceConnectable}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="sourceBottom"
        style={handleStyle}
        isConnectable={isBottomSourceConnectable}
      />
      <Handle
        type="source"
        position={Position.Left}
        id="sourceLeft"
        style={handleStyle}
        isConnectable={isLeftSourceConnectable}
      />
    </div>
  );
};

export default NodeHandlers;
