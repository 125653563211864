import classNames from 'classnames';
import type { FC } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import type { EmitUpdate } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { DRAWN_WIDTH } from '../../../store/constants/drawn-constants';
import {
  DRAWER_ADD_MITIGATION_ROUTE,
  DRAWER_ATTRIBUTES_ROUTE,
  DRAWER_ATTRIBUTE_DETAIL_ROUTE,
  DRAWER_COMPONENTS_ROUTE,
  DRAWER_MITIGATIONS_ROUTE,
  DRAWER_THREATS_DETAIL_CHECKLIST_ROUTE,
  DRAWER_THREATS_DETAIL_MITIGATION_ROUTE,
  DRAWER_THREATS_DETAIL_ROUTE,
  DRAWER_THREATS_ROUTE,
  DRAWER_THREAT_MODEL_TRANSFER,
  DRAWER_USER_THREATS_ROUTE,
} from '../../../store/constants/route-constants';
import type { Dispatch } from '../../../store/store';
import AddMitigationForm from './AddMitigationForm';
import AttributeEditForm from './AttributeEditForm';
import AttributesExplorer from './AttributesExplorer';
import Checklist from './CheckList/Checklist';
import ComponentForm from './ComponentForm';
import styles from './index.module.css';
import MitigationExplorer from './MitigationExplorer';
import RepresentationForm from './RepresentationForm';
import ThreatsDetailForm from './ThreatsDetailForm';
import ThreatsExplorer from './ThreatsExplorer';
import TransferForm from './TransferForm/TransferForm';
import UserThreatsForm from './UserThreatsForm';

type DiagramDrawnProps = {
  emitUpdate?: EmitUpdate;
};

let isResizing: any = null;

const DiagramDrawn: FC<DiagramDrawnProps> = ({ emitUpdate }) => {
  const { visible, drawnWidth } = useTypedSelector((state) => state.drawn);
  const dispatch = useDispatch<Dispatch>();
  const sidebarPanel = useRef<any>('sidebarPanel');
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const cbHandleMouseMove = useCallback(handleMousemove, []);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const cbHandleMouseUp = useCallback(handleMouseup, []);

  useEffect(() => {
    if (sidebarPanel.current) {
      sidebarPanel.current.style.width = `${drawnWidth}px`;
      sidebarPanel.current.style.transform = visible ? 'unset' : `translateX(${drawnWidth}px)`;
    }
  }, [visible]);

  function handleMousedown(e: any) {
    e.stopPropagation();
    e.preventDefault();
    sidebarPanel.current.style.transition = 'unset';
    // we will only add listeners when needed, and remove them afterward
    document.addEventListener('mousemove', cbHandleMouseMove);
    document.addEventListener('mouseup', cbHandleMouseUp);
    isResizing = true;
  }

  function handleMousemove(e: any) {
    if (!isResizing) return;

    const offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);

    if (DRAWN_WIDTH.MAX > offsetRight && offsetRight > DRAWN_WIDTH.MIN) {
      sidebarPanel.current.style.width = `${offsetRight}px`;
      dispatch.drawn.setDrawnWidth(offsetRight);
    }
  }

  function handleMouseup() {
    if (!isResizing) return;

    sidebarPanel.current.style.transition = 'all .3s';
    isResizing = false;
    document.removeEventListener('mousemove', cbHandleMouseMove);
    document.removeEventListener('mouseup', cbHandleMouseUp);
  }

  return (
    <div
      className={classNames(styles.sidebarContainer, 'position-fixed bg-white noScale')}
      ref={sidebarPanel}
      id="drawn-element"
    >
      <div>
        <div className={classNames(styles.sidebarDrawer, 'position-absolute ')} onMouseDown={handleMousedown} />

        <div className={styles.contentWrapper}>
          <Routes>
            <Route path="/" element={<RepresentationForm emitUpdate={emitUpdate as EmitUpdate} />} />
            <Route path={DRAWER_COMPONENTS_ROUTE} element={<ComponentForm emitUpdate={emitUpdate} />} />
            <Route path={DRAWER_ATTRIBUTE_DETAIL_ROUTE} element={<AttributeEditForm />} />
            <Route path={DRAWER_ATTRIBUTES_ROUTE} element={<AttributesExplorer emitUpdate={emitUpdate} />} />
            <Route path={DRAWER_MITIGATIONS_ROUTE} element={<MitigationExplorer emitUpdate={emitUpdate} />} />
            <Route path={DRAWER_THREATS_DETAIL_CHECKLIST_ROUTE} element={<Checklist emitUpdate={emitUpdate} />} />
            <Route path={DRAWER_THREATS_DETAIL_ROUTE} element={<ThreatsDetailForm emitUpdate={emitUpdate} />} />
            <Route
              path={DRAWER_THREATS_DETAIL_MITIGATION_ROUTE}
              element={<MitigationExplorer emitUpdate={emitUpdate} />}
            />
            <Route path={DRAWER_THREATS_ROUTE} element={<ThreatsExplorer emitUpdate={emitUpdate} />} />
            <Route path={DRAWER_USER_THREATS_ROUTE} element={<UserThreatsForm emitUpdate={emitUpdate} />} />
            <Route path={DRAWER_ADD_MITIGATION_ROUTE} element={<AddMitigationForm emitUpdate={emitUpdate} />} />
            <Route path={DRAWER_THREAT_MODEL_TRANSFER} element={<TransferForm />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default DiagramDrawn;
