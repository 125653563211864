import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import ProjectCreateOrUpdate from '../../components/Project/ProjectCreateOrUpdate/ProjectCreateOrUpdate';
import type { Dispatch } from '../../store/store';

const ProjectCreateOrUpdatePage = () => {
  const dispatch = useDispatch<Dispatch>();
  const location = useLocation();

  const mode = location.pathname.includes('/create') ? 'CREATE' : 'UPDATE';
  const params = useParams();
  const { projectId } = params;

  useEffect(() => {
    if (mode === 'UPDATE') {
      dispatch.project.getProjectById(projectId!);
    } else {
      dispatch.project.setCurrent({});
    }

    return () => {
      dispatch.project.setCurrent({});
    };
  }, []);

  return <ProjectCreateOrUpdate mode={mode} />;
};

export default ProjectCreateOrUpdatePage;
