import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { XSquareFill } from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useReactFlow } from 'reactflow';
import { Modal, Spinner } from 'reactstrap';

import { SKELETON_COLORS } from '../../../global/constants';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import classes from './TemplatesModal.module.css';

interface ITemplate {
  id: string;
  title: string;
  thumbnailImg: string;
  size: { width: number; height: number };
}

type TemplatesModalProps = {
  isCanvasEmpty: boolean;
  coords: { x: number; y: number };
};

export const TemplatesModal: FC<TemplatesModalProps> = ({ isCanvasEmpty, coords }) => {
  const params = useParams();
  const dispatch = useDispatch<Dispatch>();
  const { currentThreatModel } = useTypedSelector((state) => state.threatModel);
  const { viewport } = useTypedSelector((state) => state.app);
  const representation = useTypedSelector((state) => state.representation);
  const { fitView, screenToFlowPosition } = useReactFlow();
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [loading, setLoading] = useState('');
  const [size, setSize] = useState({ width: 0, height: 0 });

  const getTemplates = async () => {
    setLoading('all');
    const result = await dispatch.representation.getTemplates();
    setLoading('');
    setTemplates(result);
  };

  useEffect(() => {
    if (representation.templatesModalVisibility) {
      getTemplates();
    }

    if (!representation.templatesModalVisibility) {
      return () => {
        setTemplates([]);
      };
    }
  }, [representation.templatesModalVisibility]);

  const handleClose = () => {
    if (loading.length && loading !== 'all') return;

    dispatch.representation.setTemplatesModalVisibility(!representation.templatesModalVisibility);
  };

  const handleSelect = async (template: ITemplate) => {
    if (!currentThreatModel?.id || !params?.diagramId) return;

    if (template?.size) setSize(template.size);

    dispatch.representation.setTemplatesModalVisibility(false);
    const panElement = document.querySelector('.react-flow__pane');

    if (!isCanvasEmpty) {
      dispatch.representation.setActiveTemplateId(template.id);
      panElement?.classList?.add('templateCursor');

      return;
    }

    panElement?.classList?.add('waitTemplateCursor');
    await dispatch.representation.createFromTemplate({
      templateId: template.id,
      representationId: params?.diagramId,
      position: screenToFlowPosition({ x: window.innerWidth / 2, y: window.innerHeight / 2 }),
    });
    panElement?.classList?.remove('waitTemplateCursor');
    const timeout = setTimeout(() => {
      fitView();
      clearTimeout(timeout);
    }, 200);
  };

  return (
    <>
      {representation.activeTemplateId && (
        <div
          className="border position-absolute"
          style={{
            left: coords.x,
            transition: 'all .3s',
            width: size.width * viewport.zoom,
            height: size.height * viewport.zoom,
            top: coords.y - size.height * viewport.zoom,
          }}
        />
      )}
      <Modal
        size="lg"
        backdrop
        centered
        zIndex={10000}
        toggle={handleClose}
        isOpen={representation.templatesModalVisibility}
        contentClassName={classes.modalContent}
      >
        <div className={classes.modalHeader}>
          <div className={classes.modalTitle}>Templates</div>
          <div
            onClick={handleClose}
            className={classNames(classes.closeButton, {
              [classes.disabled]: loading.length,
            })}
          >
            <XSquareFill size={30} color="#0F0F10" />
          </div>
        </div>
        <div className={classes.modalBody}>
          {!templates?.length && !loading?.length && <div style={{ height: 420 }}>No templates</div>}
          {templates?.map((template) => (
            <div
              key={template.id}
              onClick={() => handleSelect(template)}
              className={classNames(classes.templateItem, {
                [classes.disabled]: loading.length,
              })}
            >
              <img src={template.thumbnailImg} alt={template.id} className={classes.otmImg} />
              <div>{template.title}</div>
              {loading === template.id && (
                <div className={classes.spinner}>
                  <Spinner />
                </div>
              )}
            </div>
          ))}
        </div>
        {loading === 'all' && (
          <Skeleton
            inline
            count={4}
            width={370}
            height={200}
            borderRadius={10}
            baseColor={SKELETON_COLORS.BASE}
            containerClassName={classes.modalBody}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
          />
        )}
      </Modal>
    </>
  );
};
