import classNames from 'classnames';
import type { FC } from 'react';
import React, { useCallback, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';

import { SKELETON_COLORS } from '../../global/constants';
import { THREATS_REGISTER_DETAIL_SIZE } from '../../store/constants/threat-register-constants';
import type { Dispatch } from '../../store/store';
import styles from './ThreatRegister.module.css';

let isResizing: any = null;
type Props = {
  children: React.ReactNode;
  loading: boolean;
};

export const DetailResize: FC<Props> = ({ children, loading }) => {
  const dispatch = useDispatch<Dispatch>();
  const resizer = useRef<any>('threatsRegisterDetailResizer');
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const cbHandleMouseMove = useCallback(handleMousemove, []);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const cbHandleMouseUp = useCallback(handleMouseup, []);

  function handleMousedown(e: any) {
    e.stopPropagation();
    e.preventDefault();
    resizer.current.style.transition = 'unset';
    document.addEventListener('mousemove', cbHandleMouseMove);
    document.addEventListener('mouseup', cbHandleMouseUp);
    isResizing = true;
  }

  function handleMousemove(e: any) {
    if (!isResizing) return;

    const offsetHeight = document.body.offsetHeight - e.clientY - 60;

    if (offsetHeight > THREATS_REGISTER_DETAIL_SIZE.MIN && offsetHeight < THREATS_REGISTER_DETAIL_SIZE.MAX) {
      dispatch.diagram.setThreatRegisterDetailHeight(offsetHeight);
      resizer.current.style.height = `${offsetHeight + 10}px`;
    }
  }

  function handleMouseup() {
    if (!isResizing) return;

    resizer.current.style.transition = 'all .3s';
    isResizing = false;
    document.removeEventListener('mousemove', cbHandleMouseMove);
    document.removeEventListener('mouseup', cbHandleMouseUp);
  }

  return (
    <div ref={resizer} className={styles.wrapPadding} id="threat-register-detail">
      <div className={classNames(styles.resizer, 'position-absolute ')} onMouseDown={handleMousedown} />
      {loading ? (
        <div className="px-3">
          <Skeleton
            style={{
              lineHeight: '25px',
              marginBottom: '4px',
              marginTop: '8px',
            }}
            width="200px"
            height={18}
            borderRadius={10}
            baseColor={SKELETON_COLORS.BASE}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
          />
          <Skeleton
            style={{ lineHeight: '25px', marginBottom: '3px' }}
            width="100%"
            height={32}
            borderRadius={10}
            baseColor={SKELETON_COLORS.BASE}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
          />
          <Skeleton
            style={{
              lineHeight: '25px',
              marginBottom: '4px',
              marginTop: '16px',
            }}
            width="200px"
            height={18}
            borderRadius={10}
            baseColor={SKELETON_COLORS.BASE}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
          />
          <Skeleton
            style={{ lineHeight: '25px', marginBottom: '3px' }}
            width="100%"
            height={32}
            borderRadius={10}
            baseColor={SKELETON_COLORS.BASE}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
          />
          <Skeleton
            style={{
              lineHeight: '25px',
              marginBottom: '4px',
              marginTop: '16px',
            }}
            width="200px"
            height={18}
            borderRadius={10}
            baseColor={SKELETON_COLORS.BASE}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
          />
          <Skeleton
            style={{
              lineHeight: '25px',
              marginBottom: '3px',
              marginRight: '5px',
            }}
            width="150px"
            height={32}
            borderRadius={10}
            baseColor={SKELETON_COLORS.BASE}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
            inline
            count={3}
          />
        </div>
      ) : (
        children
      )}
    </div>
  );
};
