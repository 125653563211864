import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { SKELETON_COLORS } from '../../../global/constants';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from '../styles.module.css';

const CustomCodexPage = () => {
  const companyName = useTypedSelector((state) => state.customer.companyName);
  const isLoadingCustomCodexStats = useTypedSelector((state) => state.customCodex.isLoadingCustomCodexStats);
  const { codex, customCodex } = useTypedSelector((state) => state.customCodex.stats);

  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch.customCodex.getCodexStats();
  }, [dispatch.customCodex, dispatch.customCodex.getCodexStats]);

  return (
    <div style={{ padding: 10 }}>
      <h3 className={styles.header}>Codex</h3>

      <div className="row mt-3">
        <div className="col-6 border-end">
          <h5 className="mb-3 text-center">{`${companyName} Codex`}</h5>

          <div className="ms-3">
            {isLoadingCustomCodexStats ? (
              <Skeleton
                style={{ lineHeight: '25px', marginBottom: '5px' }}
                width="100%"
                height={30}
                count={3}
                borderRadius={10}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
            ) : (
              <>
                <p>Custom Attributes: {customCodex.attributesAmount}</p>
                <p>Custom Threats: {customCodex.threatsAmount}</p>
                <p>Custom Mitigations: {customCodex.mitigationsAmount}</p>
              </>
            )}
          </div>
        </div>
        <div className="col-6">
          <h5 className="mb-3 text-center">Devici Codex</h5>

          <div className="ms-3">
            {isLoadingCustomCodexStats ? (
              <Skeleton
                style={{ lineHeight: '25px', marginBottom: '5px' }}
                width="100%"
                height={30}
                count={3}
                borderRadius={10}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
            ) : (
              <>
                <p>Attributes: {codex.attributesAmount}</p>
                <p>Threats: {codex.threatsAmount}</p>
                <p>Mitigations: {codex.mitigationsAmount}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomCodexPage;
