import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import Editor from 'react-simple-code-editor';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import { getContent, highlightWithLineNumbers, LAMBDA_URL } from '../../helpers/editor';
import './styles.css';

type SourceCodeEditorProps = {
  setReactFlowCode: (value: string) => void;
  setJson: (value: any) => void;
};

const SourceCodeEditor: FC<SourceCodeEditorProps> = ({ setReactFlowCode, setJson }) => {
  const [activeTab, setActiveTab] = useState<'code' | 'github'>('github');
  const [loading, setLoading] = useState(false);
  const [rubySourceCode, setRubySourceCode] = useState('');
  const [gitCreds, setGitCreds] = useState({
    account: '',
    repo: '',
    token: '',
  });
  const [routes, setRoutes] = useState<any>({
    routes: [],
    controllers: [],
    models: [],
  });
  const getRubyCodeFromLambda = async (code: string, fnName: string, models: any[] = []) => {
    try {
      setLoading(true);
      const response = await fetch(LAMBDA_URL, {
        body: JSON.stringify({ code, execute_function_name: fnName, models }),
        method: 'POST',
        mode: 'cors',
      });
      const result = await response.json();
      setReactFlowCode(JSON.stringify(result, null, 4));

      if (result?.edges?.length && result?.nodes?.length) setJson(result);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.warning(error.message);
      throw error;
    }
  };

  const getCodeFromRepo = async () => {
    try {
      setLoading(true);
      const result = await getContent({
        userName: gitCreds.account,
        repo: gitCreds.repo,
        token: gitCreds.token,
      });

      if (result) setRoutes(result);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.warning(error.message);
      throw error;
    }
  };

  const onChangeInput = (e: any) => {
    const { id, value } = e.target;
    setGitCreds({ ...gitCreds, [id]: value });
  };

  const onGetRoute = async (route: any) => {
    const controller = routes.controllers?.find((c: any) => c?.name?.includes(route.className));

    if (controller?.codeBase64)
      await getRubyCodeFromLambda(window.atob(controller?.codeBase64), route.methodName, routes.models);
  };

  const clearCreds = () => {
    setRoutes({
      routes: [],
      controllers: [],
      models: [],
    });
    setGitCreds({ account: '', repo: '', token: '' });
  };

  return (
    <div>
      <div className="d-flex align-middle justify-content-between p-2 pb-0">
        <ul className="nav nav-tabs code-editor-tabs">
          <li className="nav-item" onClick={() => setActiveTab('github')}>
            <a className={classNames('nav-link', { active: activeTab === 'github' })} aria-current="page" href="#">
              GitHub
            </a>
          </li>
          <li className="nav-item" onClick={() => setActiveTab('code')}>
            <a className={classNames('nav-link', { active: activeTab === 'code' })} aria-current="page" href="#">
              Ruby code
            </a>
          </li>
        </ul>
        {loading ? (
          <Spinner color="primary" />
        ) : (
          activeTab === 'code' && (
            <button className="btn btn-primary btn-sm" onClick={() => getRubyCodeFromLambda(rubySourceCode, '')}>
              Send
            </button>
          )
        )}
      </div>
      <div style={{ height: '40vh', overflow: 'auto' }}>
        {activeTab === 'code' && (
          <Editor
            value={rubySourceCode}
            highlight={(code) => highlightWithLineNumbers(code)}
            onValueChange={(code) => setRubySourceCode(code)}
            padding={10}
            style={{ fontFamily: '"Fira code", "Fira Mono", monospace', fontSize: 14, outline: 0 }}
            textareaId="codeArea"
            className="code-editor"
            placeholder="Code..."
            name="hello"
          />
        )}
        {activeTab === 'github' && (
          <div className="p-3 border h-100">
            <input
              id="account"
              value={gitCreds.account}
              onChange={onChangeInput}
              type="text"
              className="form-control mb-2 w-50 "
              placeholder="Git account"
            />
            <input
              id="repo"
              value={gitCreds.repo}
              onChange={onChangeInput}
              type="text"
              className="form-control mb-2 w-50 "
              placeholder="Repository name"
            />
            <input
              id="token"
              value={gitCreds.token}
              onChange={onChangeInput}
              type="password"
              className="form-control mb-2 w-50 "
              placeholder="Repository token"
            />
            {routes.length ? (
              <button onClick={clearCreds} className="btn btn-primary btn-sm">
                Clear
              </button>
            ) : (
              <button onClick={getCodeFromRepo} className="btn btn-primary btn-sm">
                Get Routes
              </button>
            )}
            <hr />
            <ul className="list-group routes-list-group">
              {routes?.routes?.length
                ? routes.routes.map((route: any, idx: number) => (
                    <li className="list-group-item cursor-pointer" key={idx} onClick={() => onGetRoute(route)}>
                      {route.path}
                    </li>
                  ))
                : null}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SourceCodeEditor;
