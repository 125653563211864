import type { RematchDispatch, RematchRootState } from '@rematch/core';
import { init } from '@rematch/core';
import { ENVIRONMENT } from '../global/constants';

import type { RootModel } from './models';
import { models } from './models';

const isProd = process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.PRODUCTION;

export const store = init({
  models,
  redux: {
    devtoolOptions: {
      trace: !isProd,
      disabled: isProd,
      actionSanitizer: (action) => action,
    },
  },
});

export type Store = typeof store;

export type Dispatch = RematchDispatch<RootModel>;

export type RootState = RematchRootState<RootModel>;
