import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { ArrowClockwise, CheckCircleFill, DashCircleFill, Trash3 } from 'react-bootstrap-icons';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

import type { User } from '../../../global/types';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from './UserSettingsModal.module.css';

type UserSettingsModalProps = {
  user: User;
  setVisible: any;
  onDelete: any;
};

const UserSettingsModal: FC<UserSettingsModalProps> = ({ user, setVisible, onDelete }) => {
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState('');
  const [isSeatsLimitExceeded, setIsSeatsLimitExceeded] = useState(false);
  const users = useTypedSelector((state) => state.user.users);
  const customerLimits = useTypedSelector((state) => state.tiers.customerLimits);

  useEffect(() => {
    if (users?.length && customerLimits?.seats) {
      const enabledCount = users.filter((i) => i.isEnabled).length;
      setIsSeatsLimitExceeded(enabledCount >= customerLimits.seats.limit);
    }
  }, [users]);

  const handleResend = async () => {
    setLoading('resend');
    const status = await dispatch.user.reInviteUser({
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
    });

    if (status === 201) {
      setVisible(null);
    }

    setLoading('');
  };

  const updateUsersList = async (isOk: boolean, isEnabled: boolean) => {
    setVisible(null);

    if (isOk) {
      if (users) {
        dispatch.user.setUsers(
          users.map((i) => {
            if (i.id === user.id) user.isEnabled = isEnabled;

            return i;
          }),
        );
      }

      dispatch.tiers.getCustomerLimits();
      dispatch.user.getUsers({});
    }

    setLoading('');
  };

  const handleEnableUser = async () => {
    if (isSeatsLimitExceeded) {
      toast.warning('The limit for active users is reached.');
      setVisible(null);

      return;
    }

    setLoading('disable-enable');
    const isOk = await dispatch.user.enableUser(user.id);
    updateUsersList(!!isOk, false);
  };

  const handleDisableUser = async () => {
    setLoading('disable-enable');
    const isOk = await dispatch.user.disableUser(user.id);
    updateUsersList(!!isOk, false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(null)}>
      <div className={styles.modal}>
        <span className={styles.triangle} />
        <div className={styles.listPart}>
          {user.status !== 'confirmed' && (
            <div
              onClick={() => {
                if (loading) return;

                handleResend();
              }}
              className={styles.listItem}
            >
              <div className={styles.listItemIcon}>
                <ArrowClockwise size={16} />
              </div>
              Resend invitation
            </div>
          )}
          {/* <div className={styles.listItem}> */}
          {/*  <div className={styles.listItemIcon}> */}
          {/*    <Pencil size={16} /> */}
          {/*  </div> */}
          {/*  Edit */}
          {/* </div> */}
          <div onClick={onDelete} className={classNames([styles.listItem, styles.red])}>
            <div>
              <Trash3 size={16} color="#EA4335" />
            </div>
            Remove user
          </div>

          <div
            onClick={user.isEnabled ? handleDisableUser : handleEnableUser}
            className={classNames(styles.listItem, {
              [styles.green]: !user.isEnabled,
              [styles.red]: user.isEnabled,
            })}
          >
            <div>
              {loading === 'disable-enable' ? (
                <Spinner size="sm" />
              ) : user.isEnabled ? (
                <DashCircleFill size={16} color="#eb6f0a" />
              ) : (
                <CheckCircleFill size={16} color="#36a159" />
              )}
            </div>
            {user.isEnabled ? 'Disable user' : 'Enable user'}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default UserSettingsModal;
