import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import { CheckLg, PlusLg } from 'react-bootstrap-icons';

import { useDispatch } from 'react-redux';
import type { NodeData } from '../../../global/types';
import { getColorDependsOnBackground, replaceColorFromLocalStorage } from '../../../helpers/colors';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from './NodeFontColorPicker.module.css';

let timer: any = null;

type NodeFontColorPickerProps = {
  itemsVisible: boolean;
  setItemsVisible: (value: boolean) => void;
  node: NodeData;
  colors: string[];
  isEdge?: boolean;
};

export const NodeFontColorPicker: FC<NodeFontColorPickerProps> = ({
  node,
  itemsVisible,
  setItemsVisible,
  colors,
  isEdge = false,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { nodesSharedState, edgesSharedState, defaultEdgeStyle } = useTypedSelector((state) => state.diagram);

  const handleSelect = (color: string) => {
    if (isEdge) {
      const inEdge = edgesSharedState?.get(node.id);

      if (inEdge) {
        edgesSharedState?.set(inEdge.id, {
          ...inEdge,
          data: {
            ...inEdge.data,
            fontColor: color,
          },
        });
        dispatch.diagram.setDefaultEdgeStyle({
          ...defaultEdgeStyle,
          data: {
            ...defaultEdgeStyle.data,
            fontColor: color,
          },
        });
      }
    } else {
      const inNode = nodesSharedState?.get(node.id);

      if (inNode) {
        nodesSharedState?.set(inNode.id, {
          ...inNode,
          data: {
            ...inNode.data,
            fontColor: color,
          },
        });
      }
    }
  };

  const changeLastColor = (newColor: string) => {
    const lastColor = colors.at(-1);

    if (lastColor && !colors.map((color) => color.substring(0, 7)).includes(newColor.substring(0, 7)))
      replaceColorFromLocalStorage(lastColor, newColor);

    if (isEdge) {
      const inEdge = edgesSharedState?.get(node.id);

      if (inEdge?.id) {
        inEdge.data = {
          ...inEdge.data,
          fontColor: newColor,
        };
        dispatch.diagram.setDefaultEdgeStyle({
          ...defaultEdgeStyle,
          data: {
            ...defaultEdgeStyle.data,
            fontColor: newColor,
          },
        });
        edgesSharedState && edgesSharedState.set(inEdge.id, structuredClone(inEdge));
      }
    } else {
      const inNode = nodesSharedState?.get(node.id);

      if (inNode?.id) {
        inNode.data = {
          ...inNode.data,
          fontColor: newColor,
        };
        nodesSharedState && nodesSharedState.set(inNode.id, structuredClone(inNode));
      }
    }
  };

  const handleColorChange = (e: any) => {
    e.persist();
    e.stopPropagation();
    e.preventDefault();
    clearTimeout(timer);
    timer = setTimeout(() => {
      changeLastColor(e.target.value);
    }, 500);
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleShowColorPicker = () => {
    document.getElementById(`node-font-color-input-${node.id}`)?.click();
    handleSelect('#ffffff');
  };

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.active]: itemsVisible,
      })}
      onClick={() => setItemsVisible(!itemsVisible)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showTooltip && !itemsVisible && (
        <div className="main-tooltip" style={{ left: '-5px' }}>
          Font Color
        </div>
      )}
      <svg
        className={styles.fontIcon}
        viewBox="0 0 14 14"
        aria-hidden="true"
        role="presentation"
        focusable="false"
        style={{
          width: 16,
          height: 16,
        }}
        color="black"
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M6 0 .5 14h2.25l1.12-3h6.25l1.12 3h2.25L8 0H6ZM4.62 9 7 2.67 9.38 9H4.62Z"
          fillRule="evenodd"
          fill="currentColor"
        />
      </svg>
      <div
        className={styles.colorIndicator}
        style={{
          backgroundColor: node?.fontColor || '#000',
        }}
      />
      {itemsVisible && (
        <div className={classNames(styles.dropdownItems)}>
          <div className={styles.dropdownItemWrapper}>
            {colors?.map((c) => (
              <div
                id={c}
                key={c}
                onClick={(ev: any) => {
                  if (c !== node?.fontColor) {
                    handleSelect(c);
                  } else {
                    ev.preventDefault();
                    ev.stopPropagation();
                  }
                }}
                className={classNames(styles.dropdownItem, 'rounded-circle')}
                style={{
                  background: c,
                  cursor: c === node?.fontColor ? 'default' : 'pointer',
                }}
              >
                {c === node?.fontColor && (
                  <div className={styles.selectedMark}>
                    <CheckLg size={16} color={getColorDependsOnBackground(c.substring(0, 7))} />
                  </div>
                )}
              </div>
            ))}
            {colors.length <= 15 && (
              <div className={styles.plusIcon}>
                <PlusLg className="cursor-pointer" size={16} color="black" onClick={handleShowColorPicker} />
              </div>
            )}
          </div>
        </div>
      )}
      <input
        id={`node-font-color-input-${node.id}`}
        className="position-absolute opacity-0"
        type="color"
        style={{ pointerEvents: 'none' }}
        onChange={handleColorChange}
        defaultValue="#ffffff"
      />
    </div>
  );
};
