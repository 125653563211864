import classNames from 'classnames';
import { useState } from 'react';
import { ChevronDown, ChevronUp, ThreeDotsVertical } from 'react-bootstrap-icons';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import type { Project } from '../../global/types';
import { PROJECTS_ROUTE } from '../../store/constants/route-constants';
import type { Dispatch } from '../../store/store';
import ProjectAvatar from '../common/ProjectAvatar/ProjectAvatar';
import styles from './ProjectLine.module.css';
import projectListTableStyles from './ProjectListTable.module.css';
import ProjectSettingsModal from './ProjectSettingsModal';

type ProjectLineProps = {
  project: Project;
  setSelectedProject: (projectId: string) => void;
  setShowArchiveModal: (value: boolean) => void;
  isNestedProjectsOpen: boolean;
  onOpenNestedProjects: () => void;
  onCloseNestedProjects: () => void;
  indentation: number;
};

export const ProjectLine = ({
  project,
  indentation,
  setSelectedProject,
  setShowArchiveModal,
  isNestedProjectsOpen,
  onOpenNestedProjects,
  onCloseNestedProjects,
}: ProjectLineProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const [showSettings, setShowSettings] = useState<{
    visible: boolean;
    id: string;
  } | null>(null);

  const [showRestoreArchiveModal, setShowRestoreArchiveModal] = useState<{
    visible: boolean;
    id: string;
  } | null>(null);

  const [canClick, setCanClick] = useState(true);

  const handleSettingsArchive = (projectId: string) => {
    setShowArchiveModal(true);
    setSelectedProject(projectId);
  };

  const handleSettingsClick = (project: Project) => {
    setShowSettings({ id: project.id, visible: true });
  };

  const handleRestoreClick = async (projectId: string) => {
    if (canClick) {
      setCanClick(false);
      await dispatch.project.unArchiveProject(projectId);
      setShowRestoreArchiveModal(null);
      setSelectedProject('');
    }
  };

  const handleTitleClick = (project: Project) => {
    navigate(`${PROJECTS_ROUTE}/${project.id}`);
  };

  return (
    <div className={styles.row} data-testid={`collection-item-${project.title}`}>
      <div
        className={classNames(`${styles.settings}`, projectListTableStyles.menuColumn)}
        style={{ marginLeft: indentation }}
      >
        <div className="d-flex gap-2">
          {project.sub_projects_amount ? (
            isNestedProjectsOpen ? (
              <ChevronUp data-testid="close-sub-collection-button" size={16} onClick={onCloseNestedProjects} />
            ) : (
              <ChevronDown data-testid="open-sub-collection-button" size={16} onClick={onOpenNestedProjects} />
            )
          ) : (
            <div style={{ width: 16, height: 16 }} />
          )}
          <ThreeDotsVertical
            data-testid="collection-settings-button"
            onClick={() => handleSettingsClick(project)}
            size={16}
          />
        </div>

        {showSettings != null && showSettings.visible && showSettings.id === project.id && (
          <div className={styles.settingsModal}>
            <ProjectSettingsModal
              project={project}
              setVisible={setShowSettings}
              onArchiveClick={() => handleSettingsArchive(project.id)}
              isListItem
            />
          </div>
        )}

        {showRestoreArchiveModal?.visible && showRestoreArchiveModal.id === project.id && (
          <OutsideClickHandler onOutsideClick={() => setShowRestoreArchiveModal(null)}>
            <div onClick={() => handleRestoreClick(project.id)} className={styles.restoreModalWrapper}>
              <div className={styles.restoreModal}>Restore collection</div>
            </div>
          </OutsideClickHandler>
        )}
      </div>
      <div
        onClick={() => handleTitleClick(project)}
        className={classNames(`${styles.title}`, projectListTableStyles.collectionNameColumn)}
      >
        <ProjectAvatar title={project.title} color={project.color || '#C4C4C4'} noHover />
        {project.title}
      </div>
      <div className={classNames(`${styles.centered}`, projectListTableStyles.modelsAmountColumn)}>
        {project?.threat_models_amount}
      </div>
      <div className={projectListTableStyles.ownerColumn}>
        {project.created_by ? `${project.created_by.first_name} ${project.created_by.last_name}` : '-'}
      </div>
    </div>
  );
};
