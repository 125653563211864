import { createModel } from '@rematch/core';
import { toast } from 'react-toastify';
import { MITIGATIONS } from '../constants/api-constants';
import http from '../http/http-common';
import type { RootModel } from './index';

type MitigationsStateType = {
  categoryList: any[] | null;
  mitigationList: any[];
  selectedThreat: any;
};

export const mitigations = createModel<RootModel>()({
  state: {
    categoryList: null,
    mitigationList: [],
    selectedThreat: null,
  } as MitigationsStateType,
  reducers: {
    setCategoryList(state, categoryList) {
      if (!categoryList) {
        categoryList = [];
      }

      return {
        ...state,
        categoryList,
      };
    },
    setMitigationList(state, mitigationList) {
      if (!mitigationList) {
        mitigationList = [];
      }

      return {
        ...state,
        mitigationList,
      };
    },
    clearMitigationList(state) {
      return {
        ...state,
        mitigationList: [],
      };
    },
    setSelectedCategory(state, category) {
      return {
        ...state,
        selectedCategoryData: category,
      };
    },
    clearSelectedCategory(state) {
      return {
        ...state,
        selectedCategoryData: null,
      };
    },
    setSelectedThreat(state, threat) {
      return {
        ...state,
        selectedThreat: threat,
      };
    },
    clearSelectedThreat(state) {
      return {
        ...state,
        selectedThreat: null,
      };
    },
  },
  effects: (dispatch) => {
    return {
      async getCategoryList() {
        const response = await http(`${MITIGATIONS}/explorer`);
        await dispatch.mitigations.setCategoryList(response.data);

        return response.data?.items || [];
      },
      async getMitigationsByCategoryTitle(category: string) {
        const response = await http(`${MITIGATIONS}/explorer?category=${category}`);
        await dispatch.mitigations.setMitigationList(response.data[0].mitigations);
      },
      async getMitigationInfo(mitigationId: string) {
        const response = await http(`${MITIGATIONS}/explorer/${mitigationId}`);

        if (response.status === 200) {
          return response.data;
        }
      },
      async addMitigationToThreat({ threatId, mitigationId, componentId }) {
        const response = await http.post(`${MITIGATIONS}/add-to-threat`, {
          threatId,
          mitigationId,
        });

        if (response.status === 201) {
          toast.success('Mitigation has added successfully!');
        }
      },
      async getMitigationById(mitigationId: string) {
        try {
          const response = await http(`${MITIGATIONS}/${mitigationId}`);

          if (response.status === 200) {
            return response.data;
          }
        } catch (e: any) {
          console.error(e.message);
        }
      },
      async searchMitigationsByTitle(text: string) {
        try {
          const response = await http(`${MITIGATIONS}?text=${text}`);

          if (response.status === 200) {
            dispatch.diagram.setMitigations(response.data);
          }
        } catch (e: any) {
          console.error(e.message);
        }
      },
      async getMitigationsForThreat(threatId, state) {
        try {
          const response = await http(`${MITIGATIONS}/for-threat/${threatId}`);

          if (response.status === 200) {
            dispatch.drawn.setThreat({
              ...state.drawn.threat,
              mitigations: response?.data || [],
            });
          }
        } catch (e: any) {
          console.error(e.message);
        }
      },
    };
  },
});
