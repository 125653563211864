import classNames from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Spinner } from 'reactstrap';
import * as Yup from 'yup';
import UiButton from '../../components/common/UIButton/UiButton';
import UiInput from '../../components/common/UiInput/UiInput';
import ThreatDropdown from '../../components/Diagram/ThreatDropdown';
import User from '../../components/User/User';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';
import styles from './index.module.css';

const CliThreatModelPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { id } = useParams();
  const { current } = useTypedSelector((state) => state.user);
  const projects = useTypedSelector((state) => state.project?.projects) || [];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) dispatch.project.getProjects(false);

    return () => {
      dispatch.project.setCurrent({});
      dispatch.threatModel.setInitialCurrentThreatModel();
    };
  }, []);

  const longPolling = (resolve: (value: unknown) => void, collectionId: string) => {
    if (!id || !collectionId) return;

    let counter = 12;
    const interval = setInterval(async () => {
      if (counter <= 0) {
        clearInterval(interval);
        setLoading(false);
        resolve(true);
      } else {
        try {
          const response = await dispatch.cliThreatModel.getRepresentation(id);

          if (response && collectionId) {
            clearInterval(interval);
            navigate(`/collections/${collectionId}/d/${response.id}`);
            setLoading(false);
            resolve(true);
          }
        } finally {
          counter -= 1;
        }
      }
    }, 5000);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      project: 'Create New collection',
      title: '',
      description: '',
      projectTitle: '',
      projectDescription: '',
    },
    validationSchema: Yup.object({
      project: Yup.string().required('Required'),
      title: Yup.string()
        .min(1, 'Minimum 1 characters required')
        .max(140, 'Maximum 140 characters are allowed')
        .required(),
      description: Yup.string().max(500, 'Maximum 500 characters are allowed'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const preparedData = {
        ...values,
        representationId: id,
        project: projects.find((p) => p.title === values.project)?.id,
      };
      const result = await dispatch.cliThreatModel.create(preparedData);

      if (result?.collectionId && result?.representationId) {
        navigate(`/collections/${result.collectionId}/d/${result.representationId}`);
        formik.values.title = '';
        formik.values.description = '';
        formik.values.projectTitle = '';
        formik.values.projectDescription = '';
        setLoading(false);
      } else if (result?.status === 502 && id) {
        // eslint-disable-next-line no-promise-executor-return
        await new Promise((resolve) => longPolling(resolve, id));
      } else {
        setLoading(false);
      }
    },
  });

  const handleCreate = async () => {
    await formik.submitForm();
  };

  return (
    <div className="p-2 h-100 position-relative">
      <div className="d-flex align-items-center justify-content-between">
        <h6 className="fw-bold">Adding modulation</h6>
        <UiButton htmlType="submit" onClick={handleCreate}>
          {loading ? <Spinner size="sm" /> : 'Create'}
        </UiButton>
      </div>

      <form className={classNames(styles.selectCollectionForm, 'w-50 m-auto')}>
        <h4 className="mb-4">Select collection</h4>

        <div className="mb-4 fs-12">
          <label className="mb-1">*Collection Name</label>
          <ThreatDropdown
            value={formik.values.project}
            options={['Create New collection', ...projects.map((i) => i.title)]}
            onChange={(newValue: any) => formik.setFieldValue('project', newValue)}
          />
        </div>

        {formik.values.project === 'Create New collection' && (
          <>
            <div className="mb-4 fs-12">
              <UiInput
                name="projectTitle"
                label="*Collection Name"
                value={formik.values.projectTitle}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorText={formik.errors.projectTitle}
                showError={!!formik.errors.projectTitle}
                placeholder="Add Collection name"
              />
            </div>

            <div className="mb-4 fs-12">
              <UiInput
                type="textarea"
                name="projectDescription"
                label="Collection Description"
                value={formik.values.projectDescription}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorText={formik.errors.projectDescription}
                showError={!!formik.errors.projectDescription}
                placeholder="Add description"
              />
            </div>
          </>
        )}

        <div className="mb-4 fs-12">
          <UiInput
            name="title"
            label="Threat Model Title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.title}
            showError={!!formik.errors.title}
            placeholder="Add title"
          />
        </div>

        <div className="fs-12">
          <UiInput
            type="textarea"
            name="description"
            label="Threat Model Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.description}
            showError={!!formik.errors.description}
            placeholder="Add description"
          />
        </div>
      </form>

      <div className="w-50 m-auto d-flex align-items-center justify-content-between position-absolute bottom-0 start-0 end-0 mb-2">
        <div>{current?.first_name && <User user={current} />}</div>
        <div className="fs-12 fw-bold text-secondary">Owner</div>
      </div>
    </div>
  );
};

export default CliThreatModelPage;
