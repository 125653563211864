import type { FC } from 'react';
import { useState } from 'react';
import { Spinner } from 'reactstrap';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import Space from '../common/Space/Space';
import UiButton from '../common/UIButton/UiButton';
import UiIconButton from '../common/UIIconButton/UiIconButton';
import UpgradePlan from '../UpgradePlan/UpgradePlan';
import styles from './ProjectSubHeader.module.css';

type ProjectSubHeaderProps = {
  onNavItemClick: () => void;
  isListMode: boolean;
  onThreatModelCreate: () => Promise<void>;
  isMyModel?: boolean;
  isRepositoryMode?: boolean;
};

const ProjectSubHeader: FC<ProjectSubHeaderProps> = ({
  onNavItemClick,
  isListMode,
  onThreatModelCreate,
  isMyModel = false,
  isRepositoryMode = false,
}) => {
  const customerLimits = useTypedSelector((state) => state.tiers.customerLimits);

  const [loading, setLoading] = useState(false);
  const onThreatModalAdd = async () => {
    if (!isMyModel) {
      setLoading(true);
      await onThreatModelCreate();
      setLoading(false);
    } else {
      await onThreatModelCreate();
    }
  };

  return (
    <div className={styles.nav}>
      <div>
        {!isRepositoryMode && (
          <UiButton fullWidth className={styles.createBtn} onClick={onThreatModalAdd}>
            {loading ? <Spinner size="sm" color="light" /> : 'Create new'}
          </UiButton>
        )}
        {/* <UiFilter /> */}
      </div>
      <Space>
        {!customerLimits?.isBetaUser && (
          <div className="d-flex align-items-center">
            {customerLimits?.tier === 'free' && (
              <span className="me-3">
                Number of available Intelligent Threat Models {customerLimits.threatModels?.available}/
                {customerLimits.threatModels?.limit}
              </span>
            )}
            <UpgradePlan />
          </div>
        )}
        {!isListMode && <UiIconButton onClick={() => onNavItemClick()} type="list" />}
        {isListMode && <UiIconButton onClick={() => onNavItemClick()} type="grid" />}
      </Space>
    </div>
  );
};

export default ProjectSubHeader;
