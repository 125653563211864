import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import type { Dispatch } from '../store/store';
import { useTypedSelector } from './useTypeSelector';

export function useHubspot(interval: number) {
  const { isAuthenticated } = useTypedSelector((state) => state.auth);
  const dispatch = useDispatch<Dispatch>();
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  const sendUserDataToHubSpot = async () => {
    if (!isAuthenticated) return;

    try {
      dispatch.auth.hubspot();
    } catch (err) {
      console.error('Error creating/updating contact:', err);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      sendUserDataToHubSpot();
      intervalId.current = setInterval(sendUserDataToHubSpot, interval);
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [isAuthenticated]);
}
