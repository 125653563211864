import classNames from 'classnames';
import type { FC } from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Progress } from 'reactstrap';
import { SKELETON_COLORS } from '../../../global/constants';
import { getTableHeight } from '../../../helpers/app';
import { capitalizeFirstLetter } from '../../../helpers/user';
import type { EmitUpdate } from '../../../hooks/useSocket';
import { useSocket } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { THREAT_PRIORITY, THREAT_STATUS } from '../../../store/constants/drawn-constants';
import { PROJECTS_ROUTE, THREATS_REGISTER_ROUTE } from '../../../store/constants/route-constants';

import { isReadMode } from '../../../helpers/isReadMode';
import {
  DEFAULT_THREATS_REGISTER_PAGINATION_PARAMS,
  THREATS_REGISTER_SELECTED_COMPONENT_ID,
} from '../../../store/constants/threat-register-constants';
import type { Dispatch } from '../../../store/store';
import UiModal from '../../common/Modal/UiModal';
import UiButton from '../../common/UIButton/UiButton';
import UiPriority from '../../common/UiPriority/UiPriority';
import ThreatDropdown from '../../Diagram/ThreatDropdown';
import { ThreatRegisterTableMitigation } from '../ThreatRegisterTable/ThreatRegisterTableMitigation';
import { ThreatRegisterTablePriorityDropdown } from '../ThreatRegisterTable/ThreatRegisterTablePriorityDropdown';
import { ORDER_TYPE } from '../ThreatRegisterTable/ThreatRegisterTableSortColumn';
import { ThreatRegisterTableStatusDropdown } from '../ThreatRegisterTable/ThreatRegisterTableStatusDropdown';
import { ElementRegisterSortColumn } from './ElementRegisterSortColumn';
import { ElementRegisterSortSubColumn } from './ElementRegisterSortSubColumn';
import styles from './ElementRegisterTable.module.css';

interface IApiAccessModal {
  visible: boolean;
  isStatus?: boolean;
  isPriority?: boolean;
  selectedComponent?: {
    id: string;
    title: string;
  };
}

type ElementRegisterTableProps = {
  emitUpdate: EmitUpdate;
};

const ElementRegisterTable: FC<ElementRegisterTableProps> = ({ emitUpdate }) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { emitGlobalUpdate } = useSocket();

  const tableRef = useRef<any>('threatRegisterTableRef');
  const [loading, setLoading] = useState(false);
  const { elementRegisterList, elementRegisterListQuery } = useTypedSelector((state) => state.threats);
  const { threatRegisterDetailHeight } = useTypedSelector((state) => state.diagram);
  const threatModelId = useTypedSelector((state) => state.threatModel?.currentThreatModel?.id);
  const canFetchMore = elementRegisterListQuery.page * elementRegisterListQuery.limit < elementRegisterListQuery.count;
  const [selectedComponentId, setSelectedComponentId] = useState<null | string>(null);
  const [isPriority, setIsPriority] = useState<boolean | string>(false);
  const [isStatus, setIsStatus] = useState<boolean | string>(false);
  const { currentThreatModel, liveUpdateData, elementRegisterLiveUpdateData, registerLiveUpdateData } =
    useTypedSelector((state) => state.threatModel);
  const { forceUpdateThreatId } = useTypedSelector((state) => state.drawn);
  const { subThreatRegisterList, subThreatRegisterListQuery } = useTypedSelector((state) => state.threats);
  const interactionMode = useTypedSelector((state) => state.threatModel.interactionMode);
  const [modal, setModal] = useState<IApiAccessModal>({
    visible: false,
  });
  const [modalStatus, setModalStatus] = useState<THREAT_STATUS | undefined>(undefined);
  const [modalPriority, setModalPriority] = useState<THREAT_PRIORITY | undefined>(undefined);

  useEffect(() => {
    return () => {
      dispatch.threats.setElementRegisterList(null);
      dispatch.threats.setElementRegisterQuery(DEFAULT_THREATS_REGISTER_PAGINATION_PARAMS);
      dispatch.threats.setSubThreatRegisterList(null);
      dispatch.threats.setSubThreatRegisterListQuery(DEFAULT_THREATS_REGISTER_PAGINATION_PARAMS);
    };
  }, []);

  useEffect(() => {
    if (elementRegisterLiveUpdateData && threatModelId && threatModelId === elementRegisterLiveUpdateData) {
      dispatch.threats.getElementRegisterList({
        ...elementRegisterListQuery,
        threatModelId,
      });
      dispatch.threats.getThreatsByComponentId({
        ...subThreatRegisterListQuery,
      });
    }

    dispatch.threatModel.setElementRegisterLiveUpdateData(null);
  }, [elementRegisterLiveUpdateData]);

  useEffect(() => {
    if (
      selectedComponentId &&
      registerLiveUpdateData &&
      currentThreatModel &&
      subThreatRegisterList?.map((threat) => threat?.id).includes(registerLiveUpdateData)
    ) {
      dispatch.threats.getThreatsByComponentId({
        ...subThreatRegisterListQuery,
      });
    }
  }, [registerLiveUpdateData]);

  useEffect(() => {
    if (
      selectedComponentId &&
      forceUpdateThreatId &&
      subThreatRegisterList?.map((threat) => threat.id).includes(forceUpdateThreatId)
    ) {
      dispatch.threats.getThreatsByComponentId({
        ...subThreatRegisterListQuery,
      });
    }
  }, [forceUpdateThreatId]);

  useEffect(() => {
    if (!threatModelId) return;

    dispatch.threats.getElementRegisterList({
      ...elementRegisterListQuery,
      threatModelId,
    });
    const localStorageSelectedElementId = localStorage.getItem(THREATS_REGISTER_SELECTED_COMPONENT_ID);

    if (localStorageSelectedElementId && !selectedComponentId) {
      setSelectedComponentId(localStorageSelectedElementId);
      dispatch.threats.getThreatsByComponentId({
        ...subThreatRegisterListQuery,
        page: 0,
        componentId: localStorageSelectedElementId,
        sort: 'created_at',
        order: subThreatRegisterListQuery.order === ORDER_TYPE.ASC ? ORDER_TYPE.DESC : ORDER_TYPE.ASC,
      });
    }
  }, [threatModelId]);

  useEffect(() => {
    if (tableRef?.current?.style && threatRegisterDetailHeight) {
      tableRef.current.style.height = getTableHeight(params, threatRegisterDetailHeight);
    }
  }, []);

  useEffect(() => {
    if (tableRef?.current?.style) {
      tableRef.current.style.height = getTableHeight(params, threatRegisterDetailHeight);
    }
  }, [threatRegisterDetailHeight]);

  useEffect(() => {
    if (liveUpdateData && currentThreatModel && liveUpdateData.includes(currentThreatModel.id)) {
      dispatch.threats.getElementRegisterList({
        ...elementRegisterListQuery,
        threatModelId,
      });

      if (selectedComponentId) {
        dispatch.threats.getThreatsByComponentId({
          ...subThreatRegisterListQuery,
          componentId: selectedComponentId,
        });
      }
    }
  }, [liveUpdateData]);

  const handleEmitAfterModelUpdate = () => {
    if (isReadMode(interactionMode)) return;

    if (currentThreatModel) {
      emitGlobalUpdate({ threatModels: [currentThreatModel.id] });
    }
  };

  const handleCloseModal = () => {
    setModal({
      visible: false,
      isPriority: false,
      isStatus: false,
      selectedComponent: {
        id: '',
        title: '',
      },
    });
  };

  const handleChangeModalStatus = (status: THREAT_STATUS) => {
    if (isReadMode(interactionMode)) return;

    setModalStatus(status);
  };

  const handleChangeModalPriority = (priority: THREAT_PRIORITY) => {
    setModalPriority(priority);
  };

  const handleUpdateMany = async () => {
    let status;

    if (modal.visible) {
      if (modal.isPriority) {
        status = await dispatch.threats.updateManyThreatsByTitleOrComponentId({
          titleOrId: {
            id: modal?.selectedComponent?.id,
          },
          threatModelId: currentThreatModel?.id,
          updateFields: {
            priority: modalPriority,
          },
        });
      }

      if (modal.isStatus) {
        status = await dispatch.threats.updateManyThreatsByTitleOrComponentId({
          titleOrId: {
            id: modal?.selectedComponent?.id,
          },
          threatModelId: currentThreatModel?.id,
          updateFields: {
            status: modalStatus,
          },
        });
      }
    }

    if (status && status === 200) {
      setModal({
        visible: false,
        isPriority: false,
        isStatus: false,
        selectedComponent: {
          id: '',
          title: '',
        },
      });
      await dispatch.threats.getElementRegisterList({
        ...elementRegisterListQuery,
        threatModelId,
      });
      await dispatch.threats.getThreatsByComponentId({
        ...subThreatRegisterListQuery,
        componentId: modal?.selectedComponent?.id,
      });
      handleEmitAfterModelUpdate();
      toast.success('Threats update successfully!');
    }
  };

  const handleOpenRowClick = async (componentId: string) => {
    dispatch.threats.setSubThreatRegisterList(null);

    if (componentId === selectedComponentId) {
      setSelectedComponentId(null);
      localStorage.removeItem(THREATS_REGISTER_SELECTED_COMPONENT_ID);
      navigate(`${PROJECTS_ROUTE}/${params.id}/d/${params.diagramId}${THREATS_REGISTER_ROUTE}/element`);
    } else {
      localStorage.setItem(THREATS_REGISTER_SELECTED_COMPONENT_ID, componentId);
      setSelectedComponentId(componentId);

      await dispatch.threats.getThreatsByComponentId({
        ...subThreatRegisterListQuery,
        page: 0,
        componentId,
        sort: 'created_at',
        order: subThreatRegisterListQuery.order === ORDER_TYPE.ASC ? ORDER_TYPE.DESC : ORDER_TYPE.ASC,
      });
    }
  };
  const handleTableSort = (column: string) => {
    dispatch.threats.getElementRegisterList({
      ...elementRegisterListQuery,
      page: 0,
      threatModelId,
      sort: column,
      order: elementRegisterListQuery.order === ORDER_TYPE.ASC ? ORDER_TYPE.DESC : ORDER_TYPE.ASC,
    });
  };

  const handleClickRow = (threat: any) => {
    if (isReadMode(interactionMode)) return;

    navigate(`${PROJECTS_ROUTE}/${params.id}/d/${params.diagramId}${THREATS_REGISTER_ROUTE}/element/${threat.id}`);
  };

  const fetchMoreData = async () => {
    const nextPage = elementRegisterListQuery.page + 1;

    if (nextPage * elementRegisterListQuery.limit < elementRegisterListQuery.count) {
      setLoading(true);
      await dispatch.threats.getElementRegisterList({
        ...elementRegisterListQuery,
        page: nextPage,
      });
      setLoading(false);
    }
  };

  const onHoverDescription = (event: any) => {
    const rect = event.target.getBoundingClientRect();
    const bottomMargin = params?.['*']?.length ? threatRegisterDetailHeight || 335 : 0;
    const top = rect.top + 150 + bottomMargin;

    if (top > document.body.clientHeight) {
      event.target.classList.add(styles.bottomTooltip);
    }
  };

  const isThreatAmountNonZero = (amount?: string) => Number(amount || null) !== 0;

  if (elementRegisterList === null) {
    return (
      <div className={styles.tableContent}>
        <Skeleton
          style={{ lineHeight: '25px', marginBottom: '5px' }}
          width="100%"
          height={30}
          count={15}
          borderRadius={10}
          baseColor={SKELETON_COLORS.BASE}
          highlightColor={SKELETON_COLORS.HIGHLIGHT}
        />
      </div>
    );
  }

  if (Array.isArray(elementRegisterList) && !elementRegisterList.length)
    return <div className="w-100 fs-12 text-center">No Elements</div>;

  return (
    <div className={styles.tableContent}>
      <InfiniteScroll
        scrollableTarget="scrollableTargetTable"
        next={fetchMoreData}
        hasMore={canFetchMore}
        loader={
          loading ? (
            <div className="w-100 m-auto text-center">
              <Progress
                animated
                color="secondary"
                value="100"
                style={{
                  height: '7px',
                  width: '100px',
                  margin: 'auto',
                }}
              />
            </div>
          ) : null
        }
        dataLength={elementRegisterList?.length || 0}
      >
        <div
          id="scrollableTargetTable"
          ref={tableRef}
          style={{
            height: getTableHeight(params, 335),
          }}
          className={styles.tableWrap}
        >
          <table className={styles.table}>
            <thead className="position-sticky top-0 z-2">
              <tr>
                <th style={{ width: '5%' }} className={styles.tableHead}>
                  Element Title
                  <ElementRegisterSortColumn column="title" onSort={handleTableSort} />
                </th>
                <th style={{ width: '10%' }} className={styles.tableHead}>
                  Description
                  <ElementRegisterSortColumn column="description" onSort={handleTableSort} />
                </th>
                <th style={{ width: '10%' }} className={styles.tableHead}>
                  Status
                </th>
                <th style={{ width: '10%', minWidth: '90px' }} className={styles.tableHead}>
                  Priority
                </th>
                <th style={{ width: '5%', paddingRight: '30px' }} className={styles.tableHead}>
                  Threats
                  <ElementRegisterSortColumn column="threat_amount" onSort={handleTableSort} />
                </th>
              </tr>
            </thead>
            <tbody id="scrollableTargetTable">
              {elementRegisterList?.map((component: any, index) => (
                <Fragment key={`${component.id}+${index}`}>
                  <tr
                    onClick={() => {
                      if (!isThreatAmountNonZero(component?.threat_amount)) return;

                      handleOpenRowClick(component.id);
                    }}
                    className={classNames({
                      [styles.pointerEventsNone]: !isThreatAmountNonZero(component?.threat_amount),
                    })}
                  >
                    <td
                      className={styles.titleTd}
                      rowSpan={selectedComponentId === component.id ? component?.threat_amount + 1 : 0}
                    >
                      <div className={styles.title}>{component.title}</div>
                      {isThreatAmountNonZero(component?.threat_amount) ? (
                        <>
                          {selectedComponentId === component.id && <CaretUpFill className={styles.tableIconUp} />}
                          {selectedComponentId !== component.id && <CaretDownFill className={styles.tableIconDown} />}
                        </>
                      ) : null}
                    </td>
                    <td className={styles.descriptionColumn}>{component?.description || '-'}</td>
                    <td
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (isReadMode(interactionMode)) return;

                        setModal({
                          visible: true,
                          selectedComponent: { id: component.id, title: component.title },
                          isStatus: true,
                        });
                      }}
                    >
                      <div className={styles.statusCel}>
                        {!component.statuses ? (
                          '-'
                        ) : (
                          <>
                            {component.statuses
                              .split(',')
                              .map((status: THREAT_STATUS) => capitalizeFirstLetter(status.trim()))
                              .join(', ')}
                          </>
                        )}
                      </div>
                    </td>
                    <td
                      onClick={(event: any) => {
                        event.preventDefault();
                        event.stopPropagation();

                        if (isReadMode(interactionMode)) return;

                        setModal({
                          visible: true,
                          selectedComponent: { id: component.id, title: component.title },
                          isPriority: true,
                        });
                      }}
                    >
                      {component?.priorities && (
                        <div className={styles.priorityCel}>
                          {component?.priorities
                            ?.replaceAll(' ', '')
                            ?.split(',')
                            ?.map((p: THREAT_PRIORITY) => (
                              <UiPriority type={p} size="smallItemNoAbsolute" key={p} />
                            ))}
                        </div>
                      )}
                      {!component?.priorities && <div>-</div>}
                    </td>
                    <td className={styles.amountTd}>{component?.threat_amount}</td>
                  </tr>
                  {selectedComponentId === component.id && component.threat_amount > 0 && (
                    <tr>
                      <td style={{ padding: 0 }} colSpan={5}>
                        <table className={styles.subTable}>
                          <thead className="position-sticky top-0 z-1">
                            <tr>
                              <th style={{ width: '15%' }} className={styles.tableHead}>
                                Title
                                <ElementRegisterSortSubColumn componentId={component.id} column="title" />
                              </th>
                              <th style={{ width: '10%' }} className={styles.tableHead}>
                                Status
                                <ElementRegisterSortSubColumn componentId={component.id} column="status" />
                              </th>
                              <th style={{ width: '8%', minWidth: '90px' }} className={styles.tableHead}>
                                Priority
                                <ElementRegisterSortSubColumn componentId={component.id} column="priority" />
                              </th>
                              <th style={{ width: '29%' }} className={styles.tableHead}>
                                Description
                                <ElementRegisterSortSubColumn componentId={component.id} column="description" />
                              </th>
                              <th style={{ width: '40%' }} className={styles.tableHead}>
                                Mitigation
                              </th>
                            </tr>
                          </thead>
                          <tbody id="scrollableTargetTable">
                            {subThreatRegisterList === null && (
                              <tr>
                                <td colSpan={6} style={{ padding: '10px 2px' }}>
                                  <Skeleton
                                    style={{ lineHeight: '25px', marginBottom: '5px' }}
                                    width="100%"
                                    height={30}
                                    count={3}
                                    borderRadius={10}
                                    baseColor={SKELETON_COLORS.BASE}
                                    highlightColor={SKELETON_COLORS.HIGHLIGHT}
                                  />
                                </td>
                              </tr>
                            )}
                            {subThreatRegisterList?.map((threat: any, index) => (
                              <tr
                                key={`${threat.id}+${index}`}
                                onClick={() => handleClickRow(threat)}
                                className={classNames({
                                  [styles.activeRow]: (params?.['*'] || '').includes(threat?.id),
                                })}
                              >
                                <td>{threat.title}</td>
                                <td
                                  onClick={(e) => {
                                    e.preventDefault();

                                    e.stopPropagation();

                                    if (isReadMode(interactionMode)) return;

                                    setIsStatus(threat.id);
                                  }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    {capitalizeFirstLetter(threat.status)}
                                    {isStatus === threat.id && (
                                      <ThreatRegisterTableStatusDropdown
                                        threat={threat}
                                        setVisible={(value) => {
                                          if (isReadMode(interactionMode)) return;

                                          setIsStatus(value);
                                        }}
                                        handleEmitAction={handleEmitAfterModelUpdate}
                                        type="element"
                                        styles={{
                                          position: 'absolute',
                                          top: '80%',
                                          zIndex: 1,
                                        }}
                                        isElementRegister
                                        emitUpdate={emitUpdate}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td
                                  onClick={(event: any) => {
                                    event.stopPropagation();

                                    if (isReadMode(interactionMode)) return;

                                    setIsPriority(threat.id);
                                  }}
                                >
                                  <div style={{ top: '4px' }} className={styles.priorityCel}>
                                    <UiPriority type={threat.priority} size="smallItem" />
                                    {isPriority === threat.id && (
                                      <ThreatRegisterTablePriorityDropdown
                                        threat={threat}
                                        setVisible={setIsPriority}
                                        handleEmitAction={handleEmitAfterModelUpdate}
                                        type="element"
                                        isElementRegister
                                        emitUpdate={emitUpdate}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td
                                  onMouseEnter={onHoverDescription}
                                  onMouseLeave={(e: any) => {
                                    e.target.classList.remove(styles.bottomTooltip);
                                  }}
                                  className={classNames(
                                    styles.hasTooltip,
                                    styles.descriptionColumn,
                                    'position-relative',
                                  )}
                                  data-tooltip={threat.description}
                                >
                                  {threat.description}
                                </td>
                                <td
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }}
                                >
                                  <ThreatRegisterTableMitigation
                                    isReadMode={isReadMode(interactionMode)}
                                    threat={threat}
                                    handleEmitAction={handleEmitAfterModelUpdate}
                                    isElementRegister
                                    emitUpdate={emitUpdate}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
      {modal.visible && (
        <UiModal
          title={modal.isStatus ? 'Update status' : modal.isPriority ? 'Update priority' : ''}
          onClose={handleCloseModal}
        >
          {modal.isStatus && (
            <div className="d-flex flex-column gap-4">
              <div>
                Are you sure you want to update status for all the
                <span className="fw-bold">&quot;{modal?.selectedComponent?.title}&quot;</span> threats
              </div>
              <div>
                <ThreatDropdown
                  label="Status"
                  defaultValue="Choose status"
                  value={modalStatus}
                  options={Object.values(THREAT_STATUS)}
                  onChange={(status: THREAT_STATUS) => {
                    handleChangeModalStatus(status);
                    // handleEmitAfterUpdate();
                  }}
                />
              </div>
              <div className="d-flex justify-content-end gap-2">
                <UiButton type="transparent" onClick={handleCloseModal}>
                  Cancel
                </UiButton>
                <UiButton disabled={modalStatus === undefined} onClick={handleUpdateMany}>
                  Update
                </UiButton>
              </div>
            </div>
          )}
          {modal.isPriority && (
            <div className="d-flex flex-column gap-4">
              <div>
                Are you sure you want to update priority for all the
                <span className="fw-bold">&quot;{modal?.selectedComponent?.title}&quot;</span> threats
              </div>
              <div>
                <ThreatDropdown
                  label="Priority"
                  defaultValue="Choose priority"
                  value={modalPriority}
                  options={Object.values(THREAT_PRIORITY)}
                  onChange={(priority: THREAT_PRIORITY) => {
                    if (isReadMode(interactionMode)) return;

                    handleChangeModalPriority(priority);
                    // handleEmitAfterUpdate();
                  }}
                />
              </div>
              <div className="d-flex justify-content-end gap-2">
                <UiButton type="transparent" onClick={handleCloseModal}>
                  Cancel
                </UiButton>
                <UiButton disabled={modalPriority === undefined} onClick={handleUpdateMany}>
                  Update
                </UiButton>
              </div>
            </div>
          )}
        </UiModal>
      )}
    </div>
  );
};

export default ElementRegisterTable;
