import classNames from 'classnames';
import { useFormik } from 'formik';
import type { ChangeEvent, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { createPortal } from 'react-dom';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { SKELETON_COLORS } from '../../../global/constants';
import { ThreatModelPriorityTypes } from '../../../global/types';
import { isReadMode } from '../../../helpers/isReadMode';
import type { EmitUpdate } from '../../../hooks/useSocket';
import { useSocket } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import DateInput from '../../common/DateInput/DateInput';
import UiInput from '../../common/UiInput/UiInput';
import CardSettings from '../../ThreatModels/CardSettings';
import UsersDropdown from '../../ThreatModels/UsersDropdown';
import User from '../../User/User';
import RepresentationRange from '../RepresentationRange';
import ThreatDropdown from '../ThreatDropdown';
import ThreatModelHistory from '../ThreatModelHistory';
import styles from './index.module.css';

let timer: any = null;

const ListEl = document.getElementById('root') || document.body;

type RepresentationFormType = {
  emitUpdate: EmitUpdate;
};

const RepresentationForm = ({ emitUpdate }: RepresentationFormType) => {
  const dispatch = useDispatch<Dispatch>();
  const params = useParams();
  const navigate = useNavigate();

  const { id: projectId, diagramId } = params;
  const { emitGlobalUpdate } = useSocket();

  const currentThreatModel = useTypedSelector((state) => state.threatModel.currentThreatModel);

  const liveUpdateData = useTypedSelector((state) => state.threatModel.liveUpdateData);
  const interactionMode = useTypedSelector((state) => state.threatModel.interactionMode);

  const projectsLiveUpdateData = useTypedSelector((state) => state.project.liveUpdateData);
  const [usersPopupVisible, setUsersPopupVisible] = useState(false);
  const { drawnWidth } = useTypedSelector((state) => state.drawn);
  const [isSettings, setIsSettings] = useState({
    visible: false,
    pageX: 0,
    pageY: 0,
  });

  useEffect(() => {
    if (liveUpdateData && currentThreatModel && liveUpdateData.includes(currentThreatModel.id)) {
      dispatch.threatModel.getThreatModel(currentThreatModel.id);
      dispatch.threatModel.setLiveUpdateData(null);
    }
  }, [liveUpdateData]);

  useEffect(() => {
    if (projectsLiveUpdateData && projectId && currentThreatModel) {
      if (projectsLiveUpdateData.includes(projectId)) {
        dispatch.threatModel.getThreatModel(currentThreatModel.id);
      }
    }
  }, [projectsLiveUpdateData]);

  const handleEmitAfterModelUpdate = () => {
    if (currentThreatModel) {
      emitGlobalUpdate({ threatModels: [currentThreatModel.id] });
    }
  };
  const handleEmitAfterCollectionUpdate = (isArchiving = false) => {
    if (projectId) {
      const archivingData = isArchiving ? { archives: [projectId] } : {};
      emitGlobalUpdate({ projects: [projectId], ...archivingData });
    }
  };
  const handleCommonEmitAfterUpdate = () => {
    if (projectId && currentThreatModel) {
      emitGlobalUpdate({
        projects: [projectId],
        threatModels: [currentThreatModel.id],
      });
    }
  };
  const handleTransferClick = () => {
    navigate(`/collections/${projectId}/d/${diagramId}/transfer`);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: currentThreatModel?.title || '',
      description: currentThreatModel?.description || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().max(100, 'Max length is 100 characters').required('Required'),
      description: Yup.string().max(140, 'Max length is 140 characters'),
    }),
    onSubmit: (values) => {
      // setEmail(values.email);
      // handleSubmit({ email: values.email });
    },
  });

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: string) => {
    event.persist();
    const { value } = event.target;
    dispatch.threatModel.setCurrentThreatModel({
      ...currentThreatModel,
      [field]: value,
    });

    if (formik.isValid) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (currentThreatModel) {
          const { value } = event.target;
          dispatch.threatModel.updateThreatModel({
            ...currentThreatModel,
            [field]: value,
          });
          handleCommonEmitAfterUpdate();
        }
      }, 500);
    }

    formik.handleChange(event);
  };

  const onChangeStatus = (status: string) => {
    if (currentThreatModel) {
      const updatedThreatModel = { ...currentThreatModel, status };
      dispatch.threatModel.updateThreatModel(updatedThreatModel);
      dispatch.threatModel.setCurrentThreatModel(updatedThreatModel);
      handleCommonEmitAfterUpdate();
    }
  };

  const handleDateChange = (newValue: any) => {
    if (currentThreatModel) {
      const updatedThreatModel = {
        ...currentThreatModel,
        due_to_date: newValue,
      };
      dispatch.threatModel.updateThreatModel(updatedThreatModel);
      dispatch.threatModel.setCurrentThreatModel(updatedThreatModel);
      handleCommonEmitAfterUpdate();
    }
  };

  const closeUserDropdown = (e: SyntheticEvent, value: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    setUsersPopupVisible(value);
  };

  const handleHideUserDropdown = () => {
    setUsersPopupVisible(false);
    handleCommonEmitAfterUpdate();
  };

  const handleSettingsButtonClick = (event: any) => {
    event.stopPropagation();
    setIsSettings({ visible: true, pageX: event.pageX, pageY: event.pageY });
  };

  const handleSettingsButtonLeave = (event: any) => {
    event.stopPropagation();
    setIsSettings({ visible: false, pageX: 0, pageY: 0 });
  };

  const handleArchive = async () => {
    navigate(`/collections/${projectId}`);
  };

  const handlePriorityChange = (newPriority: any) => {
    if (currentThreatModel) {
      currentThreatModel.priority = newPriority;
      dispatch.threatModel.updateThreatModel(currentThreatModel);
      dispatch.threatModel.setCurrentThreatModel(currentThreatModel);
      handleCommonEmitAfterUpdate();
    }
  };

  return (
    <div style={{ margin: '10px' }}>
      <div
        className={classNames({
          'non-interactive': isReadMode(interactionMode),
        })}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <UiInput
              name="title"
              value={formik.values.title}
              onChange={(e: any) => {
                formik.handleChange(e);
                onChange(e, 'title');
              }}
              onFocus={() => dispatch.diagram.setCanCopy(false)}
              onBlur={(e: any) => {
                dispatch.diagram.setCanCopy(true);
                formik.handleBlur(e);
              }}
              placeholder="Draft threat model"
              showError={(formik.touched.title && formik.errors.title) || formik.values.description.length > 100}
              errorText={formik.errors.title}
            />
            <div
              className={styles.settingsButton}
              onMouseLeave={handleSettingsButtonLeave}
              onClick={handleSettingsButtonClick}
            >
              {isSettings.visible &&
                currentThreatModel?.id &&
                createPortal(
                  // <OutsideClickHandler
                  //   onOutsideClick={() =>
                  //     setIsSettings({
                  //       visible: false,
                  //       pageX: 0,
                  //       pageY: 0,
                  //     })
                  //   }
                  // >
                  <CardSettings
                    currentItem={currentThreatModel}
                    position={isSettings}
                    handleEmitAfterUpdate={handleEmitAfterCollectionUpdate}
                    onTransferClick={handleTransferClick}
                    setIsSettings={setIsSettings}
                    zIndex={90000000000}
                    onArchiveClick={handleArchive}
                  />,
                  // </OutsideClickHandler>,
                  ListEl,
                )}
              <ThreeDotsVertical size={16} />
            </div>
          </div>
          <UiInput
            label="Description"
            placeholder="Add description"
            type="textarea"
            textAreaRows={5}
            name="description"
            value={formik.values.description}
            onChange={(e: any) => {
              formik.handleChange(e);
              onChange(e, 'description');
            }}
            onFocus={() => dispatch.diagram.setCanCopy(false)}
            onBlur={(e: any) => {
              dispatch.diagram.setCanCopy(true);
              formik.handleBlur(e);
            }}
            errorText={formik.errors.description}
            showError={
              (formik.touched.description && formik.errors.description) || formik.values.description.length > 140
            }
          />
        </form>

        <div
          className="d-flex align-items-center justify-content-between position-relative cursor-pointer"
          onClick={(e) => closeUserDropdown(e, true)}
          onMouseLeave={(e) => closeUserDropdown(e, false)}
        >
          {currentThreatModel?.owner && (
            <>
              <User noHover user={currentThreatModel?.owner} showPopUpOnHover={false} />
              <span style={{ fontSize: '10px' }}>Owner</span>
            </>
          )}
          {!currentThreatModel?.owner && (
            <>
              <Skeleton
                style={{ lineHeight: '22px' }}
                width={35}
                height={35}
                borderRadius={50}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />

              <Skeleton
                style={{ lineHeight: '11px', marginLeft: '5px' }}
                width={250}
                height={35}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
            </>
          )}
          {usersPopupVisible && <UsersDropdown hide={handleHideUserDropdown} threatModelId={currentThreatModel?.id} />}
        </div>

        <div className="mb-2">
          <DateInput
            placeholder="Due date"
            onChange={handleDateChange}
            value={currentThreatModel?.due_to_date ? new Date(currentThreatModel.due_to_date) : ''}
          />{' '}
        </div>

        <div
          className={classNames('w-100 d-flex gap-2', {
            [styles.representationStatusAndPriority]: drawnWidth < 370,
          })}
        >
          <div className="w-50">
            <span style={{ fontSize: '12px', fontWeight: 600 }}>Priority</span>
            <ThreatDropdown
              value={currentThreatModel?.id ? currentThreatModel?.priority : '-'}
              options={Object.values(ThreatModelPriorityTypes)}
              onChange={handlePriorityChange}
              isPriority
            />
          </div>
          <div className={classNames('w-50', styles.representationStatus)}>
            <span style={{ fontSize: '12px', fontWeight: 600 }}>Status</span>
            <ThreatDropdown
              value={currentThreatModel?.status || '-'}
              options={['Representation', 'Threats & Mitigations', 'Retrospective', 'Done']}
              onChange={onChangeStatus}
            />
          </div>
        </div>

        <RepresentationRange handleEmitAfterUpdate={handleEmitAfterModelUpdate} />
      </div>

      <ThreatModelHistory emitUpdate={emitUpdate} />
    </div>
  );
};

export default RepresentationForm;
