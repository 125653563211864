import { CustomCodexDrawer } from '../../../components/AdminPage/CustomCodexTable/CustomCodexDrawer';
import { CustomCodexThreatsTable } from '../../../components/AdminPage/CustomCodexTable/CustomCodexThreatsTable';

const CustomCodexThreatsPage = () => {
  return (
    <div style={{ padding: 10 }} className="d-flex w-100">
      <CustomCodexThreatsTable />
      <CustomCodexDrawer type="threats" />
    </div>
  );
};

export default CustomCodexThreatsPage;
