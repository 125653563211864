import type { FC, PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { Spinner } from 'reactstrap';
import { DEFAULT_THREAT_MODEL_URL_QUERY } from '../../global/constants';
import { MY_THREAT_MODELS_ROUTE } from '../../store/constants/route-constants';
import type { Dispatch } from '../../store/store';
import styles from './KanbanColumn.module.css';

type KanbanColumnProps = PropsWithChildren & {
  title: string;
  data?: any;
  runningId?: string;
};

const KanbanColumn: FC<KanbanColumnProps> = ({ children, data, title, runningId }) => {
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const { pathname } = useLocation();
  const canFetchMore = data.page * data.limit < data.count;
  const columnHeightValue = MY_THREAT_MODELS_ROUTE.includes(pathname) ? 110 : 160;

  const getThreats = () => {
    dispatch.threatModel.getThreatModelsByQuery({
      ...DEFAULT_THREAT_MODEL_URL_QUERY,
      status: title,
      projectId: params?.id,
      runningId,
    });
  };

  useEffect(() => {
    return () => {
      dispatch.threatModel.setDefaultFields();
    };
  }, []);

  useEffect(() => {
    if (params?.id) getThreats();
  }, [params?.id]);

  useEffect(() => {
    if (runningId) {
      getThreats();
    }
  }, [runningId]);

  useEffect(() => {
    return () => {
      if (pathname.includes('my-models')) {
        dispatch.threatModel.setDefaultFields();
      }
    };
  }, [pathname]);

  const fetchMoreData = async () => {
    const nextPage = data.page + 1;

    if (nextPage * data.limit < data.count) {
      setLoading(true);
      await dispatch.threatModel.getThreatModelsByQuery({
        ...data,
        page: nextPage,
        status: title,
      });
      setLoading(false);
    }
  };

  return (
    <Droppable droppableId={title}>
      {(provided, snapshot) => {
        return (
          <div className={styles.column}>
            <div className={styles.columnTitle}>{title}</div>
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <div
                className={styles.columnBody}
                style={{
                  height: `calc(100vh - ${columnHeightValue}px)`,
                  transition: 'all .2s',
                  backgroundColor: snapshot.isUsingPlaceholder ? '#f3f2f2' : '#F9F9F9',
                }}
                id={`columnBody${title}`}
              >
                <InfiniteScroll
                  scrollableTarget={`columnBody${title}`}
                  next={fetchMoreData}
                  hasMore={canFetchMore}
                  loader={
                    loading ? (
                      <div className="w-100 m-auto text-center">
                        <Spinner color="dark" />
                      </div>
                    ) : null
                  }
                  dataLength={data?.data?.length || 0}
                >
                  {children}
                </InfiniteScroll>
                {provided.placeholder}
              </div>
            </div>
          </div>
        );
      }}
    </Droppable>
  );
};

export default KanbanColumn;
