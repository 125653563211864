import moment from 'moment';
import type { FC } from 'react';
import type { IThreat } from '../../../store/models/drawn';

import styles from './ThreatHistory.module.css';

type ThreatHistoryProps = {
  threat: IThreat;
};

export const ThreatHistory: FC<ThreatHistoryProps> = ({ threat }) => {
  if (threat.history_records)
    return (
      <div className={styles.threatHistoryList}>
        {threat.history_records.map((record: any) => {
          return (
            <div key={record.id} className={styles.threatHistoryItem}>
              <div>
                <span className={styles.threatHistoryUser}>
                  {record.user && (
                    <>
                      {record.user?.first_name} {record.user?.last_name}
                    </>
                  )}
                  {!record.user && <>Deleted Account</>}
                </span>{' '}
                {record.description}
              </div>
              <div>{moment(record.created_at).fromNow(true)}</div>
            </div>
          );
        })}
      </div>
    );

  return null;
};
