import type { FC } from 'react';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import type { Project, Running } from '../../global/types';
import RightBar from '../RightBar/RightBar';
import ThreatModelsKanbanMode from '../ThreatModels/ThreatModelsKanbanMode';
import ThreatModelsListMode from '../ThreatModels/ThreatModelsListMode';

type ProjectDetailProps = {
  projectId?: string;
  project: Project;
  currentRunning?: Running;
};

const ProjectDetail: FC<ProjectDetailProps> = ({ projectId, project, currentRunning }) => {
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const [isTransferring, setIsTransferring] = useState(false);
  const [selectedProject, setSelectedProject] = useState<any>();
  const [selectedModelProject, setSelectedModelProject] = useState<any>();
  const [selectedForTransferModel, setSelectedForTransferModel] = useState<any>();

  const handlerTransferClick = async (selectedProjectForTransfer: any, selectedForTransferModel: any) => {
    setIsTransferring(true);
    setShowRightSidebar(true);
    setSelectedForTransferModel(selectedForTransferModel);
    setSelectedModelProject(selectedProjectForTransfer);
    setSelectedProject(undefined);
  };

  if (!projectId || !project) {
    return (
      <div className="container text-center">
        <h2 className="text-warning">Collection NOT FOUND</h2>
      </div>
    );
  }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <ThreatModelsKanbanMode
              projectId={projectId}
              runningId={currentRunning?.id || project?.runnings?.[0]?.id}
              onTransferClick={handlerTransferClick}
            />
          }
        />
        <Route
          path="/list"
          element={<ThreatModelsListMode projectId={projectId} onTransferClick={handlerTransferClick} />}
        />
      </Routes>
      <RightBar
        visible={showRightSidebar}
        setVisible={setShowRightSidebar}
        isTransferring={isTransferring}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        selectedForTransferModel={selectedForTransferModel}
        selectedModelProject={selectedModelProject}
      />
    </>
  );
};

export default ProjectDetail;
