import classNames from 'classnames';
import type { FC } from 'react';
import styles from './UiRangeInput.module.css';

type UiRangeInputProps = {
  options: number[];
  value: number;
  onOptionClick: any;
  label?: string;
  valueSign?: string;
  isThickness?: boolean;
};

const UiRangeInput: FC<UiRangeInputProps> = ({
  options,
  value,
  onOptionClick,
  label = 'Opacity',
  valueSign = '%',
  isThickness = false,
}) => {
  return (
    <div className={styles.opacityControl}>
      <div className={styles.optionListWrapper}>
        {options.map((option) => (
          <div key={option} onClick={(e: any) => onOptionClick(e, option)} className={styles.optionWrapper}>
            <div
              style={{ width: `${234 / options.length}px` }}
              className={classNames(styles.optionLine, {
                [styles.notSelectedOptionLine]: option > value,
              })}
            />
            <div
              style={{
                left: `${isThickness ? 41 : 17}px`,
              }}
              className={classNames(styles.optionCircle, {
                [styles.selectedOptionCircle]: option == value,
                [styles.notSelectedOptionCircle]: option > value,
              })}
            />
            {/* {option.value} */}
          </div>
        ))}
      </div>
      <div className={styles.opacityControl__title}>
        <div>{label}</div>
        <div>
          {value}
          {valueSign}
        </div>
      </div>
    </div>
  );
};

export default UiRangeInput;
