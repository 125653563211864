import classNames from 'classnames';
import type { FC } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner, UncontrolledTooltip } from 'reactstrap';
import { useSelectedEntityType } from '../../../hooks/useSelectedEntityType';

import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { IMitigatingAttribute } from '../../../store/models/drawn';
import type { Dispatch } from '../../../store/store';
import styles from './index.module.css';

let timer: any = null;

type MitigationAttributesFormProps = {
  isPopup: boolean;
  componentId: string;
  onAttributeAdd: () => void;
  mitigatingAttributes: Record<string, IMitigatingAttribute[]> | null;
};

export const MitigationAttributesForm: FC<MitigationAttributesFormProps> = ({
  isPopup,
  componentId,
  onAttributeAdd,
  mitigatingAttributes,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { isSearchingAttributes } = useTypedSelector((state) => state.diagram);
  const { component, visible } = useTypedSelector((state) => state.drawn);
  const selectedEntityType = useSelectedEntityType();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    return () => {
      dispatch.diagram.setHighlightedThreats([]);
    };
  }, []);

  useEffect(() => {
    if (componentId && selectedEntityType) {
      dispatch.diagram.getMitigatingAttributes({ componentId, selectedEntityType, isPopup });
    }
  }, [componentId, selectedEntityType]);

  const handleAddAttribute = (attr: any) => {
    setDisabled(true);

    if (!disabled) {
      dispatch.drawn
        .addAttribute({
          componentId: component.id,
          title: attr.title,
        })
        .then(() => setDisabled(false));
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (!componentId) return;

        dispatch.drawn.syncThreats(componentId).then(() => onAttributeAdd());
      }, 2000);
    }
  };

  const onMouseEnter = (threats: string[]) => {
    if (mitigatingAttributes && Object.keys(mitigatingAttributes).length && visible)
      dispatch.diagram.setHighlightedThreats(threats);
  };

  const onMouseOut = () => {
    if (mitigatingAttributes && Object.keys(mitigatingAttributes).length && visible)
      dispatch.diagram.setHighlightedThreats([]);
  };

  const filterByTitle = (mitigatingAttribute: IMitigatingAttribute) =>
    !component?.attributes?.map((componentAttribute) => componentAttribute?.title)?.includes(mitigatingAttribute.title);

  const renderFoundAttributes = () => {
    const categories = mitigatingAttributes ? Object.keys(mitigatingAttributes) : [];
    const filteredCategories = categories?.filter((c) => mitigatingAttributes?.[c]?.filter(filterByTitle)?.length);

    if (!filteredCategories?.length) return <div className="fs-12 text-center">No attributes</div>;

    return (
      <div className={styles.mitigatingAttributes}>
        {filteredCategories?.map((category) => (
          <div key={category} className="mb-2">
            <div>{category}</div>
            <div className={styles.mitigatingAttributesWrap}>
              {mitigatingAttributes?.[category]?.filter(filterByTitle)?.map((attr) => (
                <Fragment key={category + attr.id}>
                  {!!attr.shouldNeutralize?.length && (
                    <UncontrolledTooltip
                      target={category + attr.id}
                      innerClassName={styles.innerClassName}
                      popperClassName={styles.popperClassName}
                    >
                      {attr.shouldNeutralize?.join(', ')}
                    </UncontrolledTooltip>
                  )}
                  <div
                    onClick={() => {
                      handleAddAttribute(attr);
                      dispatch.diagram.setHighlightedThreats([]);
                    }}
                    className={classNames('fs-12', styles.attributeItem)}
                    onMouseEnter={() => onMouseEnter(attr.shouldNeutralize)}
                    onMouseOut={onMouseOut}
                    onBlur={onMouseOut}
                    id={category + attr.id}
                  >
                    {attr.title}
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.inputWrap}>
      {isSearchingAttributes && (
        <div className="text-center">
          <Spinner color="dark" size="sm" />
        </div>
      )}
      {renderFoundAttributes()}
    </div>
  );
};
