import { useFormik } from 'formik';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';
import * as Yup from 'yup';

import { Spinner } from 'reactstrap';
import styles from '../form.module.css';

import UiButton from '../../common/UIButton/UiButton';
import UiInput from '../../common/UiInput/UiInput';

interface IProps {
  email?: string;
  isLoading?: boolean;
  setEmail: Dispatch<SetStateAction<string>>;
  handleSubmit: <T>(data: T) => Promise<void>;
  isSignUpForm?: boolean;
}

const EmailForm = ({ email, isLoading, setEmail, handleSubmit, isSignUpForm }: IProps) => {
  const formik = useFormik({
    initialValues: {
      email: email || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email format').min(6, 'Minimum 6 characters required').required('Required'),
    }),
    onSubmit: (values) => {
      setEmail(values.email);
      handleSubmit({ email: values.email });
    },
  });

  useEffect(() => {
    // if initial email is provided, submit the form
    if (email) {
      formik.submitForm();
    }
  }, []);

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <div className={styles.formGroup}>
        <UiInput
          label="Email"
          type="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.errors.email}
          showError={formik.touched.email && formik.errors.email}
          placeholder="Enter your email address"
        />
      </div>
      <div>
        <UiButton disabled={!formik.isValid} htmlType="submit" fullWidth className="fw-bold">
          {!isLoading && isSignUpForm ? 'Activate My Free Account' : 'Continue'}
          {isLoading && <Spinner color="light" size="sm" />}
        </UiButton>
      </div>
    </form>
  );
};

export default EmailForm;
