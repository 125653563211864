import { useCallback, useEffect, useState } from 'react';
import type { Edge, EdgeAddChange, EdgeChange, EdgeRemoveChange, EdgeResetChange, OnEdgesChange } from 'reactflow';
import { applyEdgeChanges } from 'reactflow';
import type { Doc } from 'yjs';

const isEdgeAddChange = (change: EdgeChange): change is EdgeAddChange => change.type === 'add';
const isEdgeResetChange = (change: EdgeChange): change is EdgeResetChange => change.type === 'reset';
const isEdgeRemoveChange = (change: EdgeChange): change is EdgeRemoveChange => change.type === 'remove';

function useEdgesStateSynced(doc: Doc) {
  const [edges, setEdges] = useState<Edge[]>([]);
  const edgesSharedState = doc.getMap<Edge>('edges');

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => {
      const currentEdges = Array.from(edgesSharedState.values()).filter((e) => e);
      const nextEdges = applyEdgeChanges(changes, currentEdges);
      changes.forEach((change: EdgeChange) => {
        if (isEdgeRemoveChange(change)) {
          edgesSharedState.delete(change.id);
        } else if (!isEdgeAddChange(change) && !isEdgeResetChange(change)) {
          edgesSharedState.set(change.id, nextEdges.find((n) => n.id === change.id) as Edge);
        }
      });
    },
    [edgesSharedState],
  );

  const onConnect = useCallback(
    (params: any) => {
      edgesSharedState.set(params.id, {
        ...params,
      } as Edge);
    },
    [edgesSharedState],
  );

  useEffect(() => {
    const observer = () => {
      setEdges(Array.from(edgesSharedState.values()));
    };

    setEdges(Array.from(edgesSharedState.values()));
    edgesSharedState.observe(observer);

    return () => edgesSharedState.unobserve(observer);
  }, [setEdges]);

  return { edges, edgesSharedState, onEdgesChange, onConnect };
}

export default useEdgesStateSynced;
