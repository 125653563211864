import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import {
  EDGE_DEFAULT_COLOR,
  EDGE_STYLES,
  getUserColorsFromLocalStorage,
  MAIN_COLORS,
} from '../../../../helpers/colors';
import { getSelectedComponentsAmount } from '../../../../helpers/diagram';
import { useTypedSelector } from '../../../../hooks/useTypeSelector';
import { NodeFontColorPicker } from '../../NodeFontColorPicker/NodeFontColorPicker';
import NodeFontSizePicker from '../../NodeFontSizePicker/NodeFontSizePicker';
import NodeStylePicker from '../../NodeStylePicker/NodeStylePicker';
import EdgeColorPicker from './EdgeColorPicker/EdgeColorPicker';
import EdgeConnectionPicker from './EdgeConnectionPicker/EdgeConnectionPicker';
import EdgeContextMenu from './EdgeContextMenu/EdgeContextMenu';
import styles from './EdgePopUpTools.module.css';
import { EdgeStylePicker } from './EdgeStylePicker/EdgeStylePicker';

type EdgePopUpToolsProps = {
  edge: any;
  width?: number;
  height?: number;
  setAttributesPopup?: () => void;
};

const EdgePopUpTools: FC<EdgePopUpToolsProps> = ({ edge, setAttributesPopup }) => {
  const [nodeTypeDropdownVisible, setNodeTypeDropdownVisible] = useState(false);
  const [nodeBorderPickerVisible, setNodeBorderPickerVisible] = useState(false);
  const [stylePickerVisible, setStylePickerVisible] = useState(false);
  const { nodesSharedState, edgesSharedState } = useTypedSelector((state) => state.diagram);
  const currentUserId = useTypedSelector((state) => state.user?.current?.id) || '';
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [nodeFontSizeVisible, setNodeFontSizeVisible] = useState(false);
  const [fontColorPicker, setFontColorPicker] = useState(false);

  const selectedAmount = getSelectedComponentsAmount({
    nodesSharedState,
    edgesSharedState,
    currentUserId,
  });

  if (selectedAmount > 1) return null;

  const closeAllPopups = () => {
    setNodeTypeDropdownVisible(false);
    setNodeBorderPickerVisible(false);
    setStylePickerVisible(false);
    setNodeFontSizeVisible(false);
    setFontColorPicker(false);
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className={classNames(
        'd-flex justify-content-between align-items-center react-flow__node-toolbar',
        styles.edgePopUpTools,
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <EdgeConnectionPicker
        setItemsVisible={(value) => {
          closeAllPopups();
          setNodeTypeDropdownVisible(value);
        }}
        itemsVisible={nodeTypeDropdownVisible}
        edge={edge}
      />

      <EdgeColorPicker
        itemsVisible={nodeBorderPickerVisible}
        setItemsVisible={(value) => {
          closeAllPopups();
          setNodeBorderPickerVisible(value);
        }}
        edge={edge}
        colors={MAIN_COLORS.concat(getUserColorsFromLocalStorage()).map((c) => {
          if (c === '#FFFFFF') return EDGE_DEFAULT_COLOR;

          return c;
        })}
      />
      <div className={styles.breakLine} />

      {!edge?.hiddenLabel && (
        <>
          <NodeFontSizePicker
            node={edge}
            isEdge
            nodeFontSizeVisible={nodeFontSizeVisible}
            setNodeFontSizeVisible={(value) => {
              closeAllPopups();
              setNodeFontSizeVisible(value);
            }}
          />
          <div className={styles.breakLine} />
          <NodeStylePicker node={edge} isEdge />
          <NodeFontColorPicker
            node={edge}
            itemsVisible={fontColorPicker}
            setItemsVisible={(value) => {
              closeAllPopups();
              setFontColorPicker(value);
            }}
            colors={MAIN_COLORS.concat(getUserColorsFromLocalStorage())}
            isEdge
          />
          <div className={styles.breakLine} />
        </>
      )}

      <EdgeStylePicker
        visible={stylePickerVisible}
        setVisible={(value) => {
          closeAllPopups();
          setStylePickerVisible(value);
        }}
        edge={edge}
        items={Object.values(EDGE_STYLES)}
      />

      <div className={styles.breakLine} />
      <div
        onClick={() => {
          closeAllPopups();
          setAttributesPopup && setAttributesPopup();
        }}
        className={classNames(
          'd-flex align-items-center rounded p-1 cursor-pointer position-relative',
          styles.attrPopupToggleButton,
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {showTooltip && (
          <div className="main-tooltip" style={{ top: -31 }}>
            Add attribute
          </div>
        )}
        <button className="p-1 fs-12 btn btn-secondary bg-transparent border-0" type="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
              d="M16.3755 21.9568C16.2435 22.1304 15.9959 22.1641 15.8223 22.0321L5.69658 14.3311C5.59963 14.2574 5.54214 14.1431 5.5408 14.0213L5.46301 6.95281L4.46307 6.96382L5.46301 6.95281C5.46102 6.77217 5.5819 6.61323 5.75651 6.5669L5.50003 5.60035L5.75651 6.5669L12.5889 4.75388C12.7067 4.72264 12.8322 4.74749 12.9292 4.82123L23.0549 12.5222C23.2284 12.6541 23.2621 12.9018 23.1302 13.0754L16.3755 21.9568Z"
              stroke="black"
              strokeWidth="2"
            />
            <path
              d="M10.4144 8.40579C11.1169 8.94011 11.2533 9.94279 10.719 10.6453C10.1847 11.3479 9.182 11.4843 8.47945 10.95C7.77689 10.4156 7.64051 9.41297 8.17482 8.71042C8.70913 8.00786 9.71181 7.87148 10.4144 8.40579Z"
              stroke="black"
              strokeWidth="0.987966"
            />
          </svg>
        </button>
      </div>

      <ThreeDotsVertical
        fontSize={16}
        color="#6F767E"
        className="cursor-pointer"
        onClick={() => {
          closeAllPopups();
          setShowContextMenu(!showContextMenu);
        }}
      />
      {showContextMenu && <EdgeContextMenu setVisible={setShowContextMenu} edge={edge} />}
    </div>
  );
};

export default EdgePopUpTools;
