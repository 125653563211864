import { useEffect } from 'react';
import { useLocation } from 'react-router';

export const ScrollToTop = () => {
  const location = useLocation();

  const sidebarContent = document.getElementById('content-element');
  useEffect(() => {
    sidebarContent?.scrollTo(0, 0);
  }, [location]);

  return null;
};
