import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CaretDownFill, CaretRightFill, GripVertical, PencilFill, ThreeDotsVertical } from 'react-bootstrap-icons';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createPortal } from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Spinner } from 'reactstrap';
import { COLUMN_NAMES, COLUMN_TITLES, DEFAULT_THREAT_MODEL_URL_QUERY } from '../../global/constants';
import type { ThreatModel } from '../../global/types';
import { getRankForOneColumn, getRankForTwoColumn, sortByLexoRank } from '../../helpers/diagram';
import { useSocket } from '../../hooks/useSocket';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';
import DateInput from '../common/DateInput/DateInput';
import UiButton from '../common/UIButton/UiButton';
import UiPriority from '../common/UiPriority/UiPriority';
import User from '../User/User';
import CardSettings from './CardSettings';
import PriorityModal from './PriorityModal';
import styles from './ThreatModelsListMode.module.css';
import UsersDropdown from './UsersDropdown';

type ThreatModelsListModeProps = {
  projectId?: string;
  isGroupedByProject?: boolean;
  onTransferClick?: (project: any, selectedForTransferModel: any) => void;
};

const ListEl = document.getElementById('root') || document.body;

const ThreatModelsListMode: FC<ThreatModelsListModeProps> = ({
  projectId,
  isGroupedByProject = false,
  onTransferClick,
}) => {
  const initialHeaderItems = [
    { value: COLUMN_NAMES.REPRESENTATION, isOpened: true },
    { value: COLUMN_NAMES.THREATS_AND_MITIGATIONS, isOpened: true },
    { value: COLUMN_NAMES.RETROSPECTIVE, isOpened: true },
    { value: COLUMN_NAMES.DONE, isOpened: true },
  ];
  const dispatch = useDispatch<Dispatch>();
  const { emitGlobalUpdate } = useSocket();
  const [headerItems, setHeaderItems] = useState(initialHeaderItems);
  const navigate = useNavigate();
  const param = useParams();
  const [isPriority, setIsPriority] = useState<boolean | string>(false);

  const [usersPopupId, setUsersPopupId] = useState<string | null>(null);
  const [statusLoading, setStatusLoading] = useState<string>('');

  const { REPRESENTATION, THREATS_AND_MITIGATIONS, RETROSPECTIVE, DONE, liveUpdateData } = useTypedSelector(
    (state) => state.threatModel,
  );
  const projectsLiveUpdateData = useTypedSelector((state) => state.project?.liveUpdateData);

  const [isSettings, setIsSettings] = useState({
    id: null,
    pageX: 0,
    pageY: 0,
  });

  useEffect(() => {
    dispatch.threatModel.getThreatModelsByQuery({
      ...DEFAULT_THREAT_MODEL_URL_QUERY,
      status: COLUMN_NAMES.REPRESENTATION,
      projectId: param.id,
    });
    dispatch.threatModel.getThreatModelsByQuery({
      ...DEFAULT_THREAT_MODEL_URL_QUERY,
      status: COLUMN_NAMES.THREATS_AND_MITIGATIONS,
      projectId: param.id,
    });
    dispatch.threatModel.getThreatModelsByQuery({
      ...DEFAULT_THREAT_MODEL_URL_QUERY,
      status: COLUMN_NAMES.RETROSPECTIVE,
      projectId: param.id,
    });
    dispatch.threatModel.getThreatModelsByQuery({
      ...DEFAULT_THREAT_MODEL_URL_QUERY,
      status: COLUMN_NAMES.DONE,
      projectId: param.id,
    });
  }, []);

  useEffect(() => {
    if (liveUpdateData) {
      if (
        [...REPRESENTATION.data, ...THREATS_AND_MITIGATIONS.data, ...RETROSPECTIVE.data, ...DONE.data].some(
          (i: ThreatModel) => liveUpdateData.includes(i.id),
        )
      ) {
        dispatch.threatModel.getThreatModelsByQuery({
          ...DEFAULT_THREAT_MODEL_URL_QUERY,
          status: COLUMN_NAMES.REPRESENTATION,
          projectId: param.id,
        });
        dispatch.threatModel.getThreatModelsByQuery({
          ...DEFAULT_THREAT_MODEL_URL_QUERY,
          status: COLUMN_NAMES.THREATS_AND_MITIGATIONS,
          projectId: param.id,
        });
        dispatch.threatModel.getThreatModelsByQuery({
          ...DEFAULT_THREAT_MODEL_URL_QUERY,
          status: COLUMN_NAMES.RETROSPECTIVE,
          projectId: param.id,
        });
        dispatch.threatModel.getThreatModelsByQuery({
          ...DEFAULT_THREAT_MODEL_URL_QUERY,
          status: COLUMN_NAMES.DONE,
          projectId: param.id,
        });
      }

      dispatch.threatModel.setLiveUpdateData(null);
    }
  }, [liveUpdateData]);

  useEffect(() => {
    if (projectsLiveUpdateData && projectId) {
      if (projectsLiveUpdateData.includes(projectId)) {
        dispatch.threatModel.getThreatModelsByQuery(REPRESENTATION);
        dispatch.threatModel.getThreatModelsByQuery(THREATS_AND_MITIGATIONS);
        dispatch.threatModel.getThreatModelsByQuery(RETROSPECTIVE);
        dispatch.threatModel.getThreatModelsByQuery(DONE);
      }
    }
  }, [projectsLiveUpdateData]);

  const handleEmitAfterProjectUpdate = (isArchive = false) => {
    if (projectId && typeof projectId === 'string') {
      const archiveData = isArchive ? { archives: [projectId] } : {};
      emitGlobalUpdate({
        projects: [projectId],
        ...archiveData,
      });
    }
  };

  const getColumnByStatus = (status: string) => {
    switch (status) {
      case REPRESENTATION.status:
        return REPRESENTATION;
      case RETROSPECTIVE.status:
        return RETROSPECTIVE;
      case THREATS_AND_MITIGATIONS.status:
        return THREATS_AND_MITIGATIONS;
      case DONE.status:
        return DONE;
    }
  };

  const groupBy = (x: any, f: any) => x.reduce((a: any, b: any, i: any) => ((a[f(b, i, x)] ||= []).push(b), a), {});

  const getFilteredData = (status: string) => {
    const column = getColumnByStatus(status);
    const result: any[] = column?.data || [];

    if (isGroupedByProject) {
      const finalResult = groupBy(result, (r: any) => r.project?.title);

      return [Object.keys(finalResult), finalResult];
    }

    if (result) {
      return result;
    }

    return [];
  };

  const handleUpdateThreatModelColumn = (status: string) => {
    const column = getColumnByStatus(status);

    if (column) {
      dispatch.threatModel.getThreatModelsByQuery({
        ...column,
        page: 0,
        limit: column.data.length || column.limit,
        projectId: param.id,
      });
    }

    handleEmitAfterProjectUpdate();
  };

  const handleStatusClick = (status: string) => {
    const updatedHeaderItems = headerItems.map((item) => {
      if (item.value === status) {
        item.isOpened = !item.isOpened;
      }

      return item;
    });
    setHeaderItems(updatedHeaderItems);
  };

  const handleItemClick = (item: any) => {
    const projectId = item?.project?.id || param?.id;
    const diagram = item.representations.find((i: any) => i.format === 'diagram');
    dispatch.threatModel.setCurrentThreatModel(item);

    if (diagram?.id && projectId) navigate(`/collections/${projectId}/d/${diagram.id}`);
  };

  const handleCollectionNameClick = (item: any) => {
    if (item?.project?.id) navigate(`/collections/${item.project.id}`);
  };

  const handleDateChange = async (item: any) => {
    await dispatch.threatModel.updateThreatModel(item);
    handleUpdateThreatModelColumn(item.status);
    handleEmitAfterProjectUpdate();
  };

  const handlePriorityChange = async (updatedThreatModel: any) => {
    setIsPriority(false);
    await dispatch.threatModel.updateThreatModel(updatedThreatModel);
    handleUpdateThreatModelColumn(updatedThreatModel?.status);
    handleEmitAfterProjectUpdate();
  };

  const renderLoadMore = (status: string) => {
    const column = getColumnByStatus(status);

    if (!column) return null;

    if (column.data.length !== column.count) {
      const fetchMoreData = async () => {
        const nextPage = column.page + 1;

        if (nextPage * column.limit < column.count) {
          setStatusLoading(status);
          await dispatch.threatModel.getThreatModelsByQuery({
            ...column,
            page: nextPage,
            status,
          });
          setStatusLoading('');
        }
      };

      return (
        <div className={classNames('w-100 text-center', styles.loadMoreButtonWrap)}>
          {statusLoading === status ? (
            <Spinner className="mt-3 mb-2" color="dark" size="sm" />
          ) : (
            <UiButton onClick={fetchMoreData} type="transparent">
              Load more
            </UiButton>
          )}
        </div>
      );
    }

    return null;
  };

  const handleSettingsButtonClick = (event: any, threatId: string) => {
    event.stopPropagation();
    // @ts-ignore
    setIsSettings({ id: threatId, pageX: event.pageX, pageY: event.pageY });
  };

  const getColumnByTitle = (title: string): string => {
    switch (title) {
      case REPRESENTATION.status:
        return COLUMN_TITLES.REPRESENTATION;
      case RETROSPECTIVE.status:
        return COLUMN_TITLES.RETROSPECTIVE;
      case THREATS_AND_MITIGATIONS.status:
        return COLUMN_TITLES.THREATS_AND_MITIGATIONS;
      case DONE.status:
        return COLUMN_TITLES.DONE;
      default:
        return '';
    }
  };

  const handleThreatModelColumnMouseLeave = (event: any) => {
    event.stopPropagation();
    setIsSettings({ id: null, pageX: 0, pageY: 0 });
  };

  const onDragEnd = async ({ source, destination, draggableId }: DropResult) => {
    if (destination === undefined || destination === null) return null;

    if (source.droppableId === destination.droppableId && destination.index === source.index) return null;

    const start = getColumnByStatus(source.droppableId);
    const end = getColumnByStatus(destination.droppableId);

    if (start.status === end.status) {
      const newRank = getRankForOneColumn(start.data, source.index, destination.index, !!projectId);

      start.data.forEach((tm: any) => {
        if (tm.id === draggableId) {
          if (projectId) tm.rank = newRank;
          else tm.my_models_rank = newRank;

          tm.status = destination.droppableId;
        }
      });
      dispatch.threatModel.setField(start);

      await dispatch.threatModel.updateThreatModelOrder({
        id: draggableId,
        newStatus: destination.droppableId,
        newRank,
        projectId,
      });

      dispatch.threatModel.getThreatModelsByQuery({
        ...start,
        page: 0,
      });

      return null;
    }

    const newRank = getRankForTwoColumn(start.data, end.data, source.index, destination.index, !!projectId);

    const currentItem = start.data[source.index];

    if (projectId) currentItem.rank = newRank;
    else currentItem.my_models_rank = newRank;

    currentItem.status = destination.droppableId;

    dispatch.threatModel.setColumns(
      structuredClone({
        [getColumnByTitle(start.status)]: {
          ...getColumnByStatus(start.status),
          data: start.data.filter((_: any, idx: number) => idx !== source.index),
        },
        [getColumnByTitle(end.status)]: {
          ...getColumnByStatus(end.status),
          data: [...end.data, currentItem],
        },
      }),
    );

    await dispatch.threatModel.updateThreatModelOrder({
      id: draggableId,
      newStatus: destination.droppableId,
      newRank,
      projectId,
    });

    dispatch.threatModel.getThreatModelsByQuery({
      ...start,
      page: 0,
    });
    dispatch.threatModel.getThreatModelsByQuery({
      ...end,
      page: 0,
    });

    return null;
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <DndProvider backend={HTML5Backend}>
          <DragDropContext onDragEnd={onDragEnd}>
            {headerItems.map((item, index) => {
              return (
                <div key={item.value + index}>
                  <Droppable droppableId={item.value}>
                    {(provided, snapshot) => {
                      return (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          <div className={styles.headerItem} onClick={() => handleStatusClick(item.value)}>
                            <div className={styles.headerItemIcon}>
                              {item.isOpened && <CaretDownFill size={16} />}
                              {!item.isOpened && <CaretRightFill size={16} />}
                            </div>
                            <div className={styles.headerItemTitle}>{item.value}</div>
                          </div>
                          {item.isOpened && (
                            <div>
                              <table
                                className={styles.table}
                                style={{
                                  backgroundColor: snapshot.isUsingPlaceholder ? '#f3f2f2' : '#F9F9F9',
                                }}
                              >
                                <thead className={styles.thead} data-status={item.value}>
                                  <tr>
                                    <th className={styles.indexField}>#</th>
                                    <th style={{ width: '40%' }}>Threat Model Name</th>
                                    {isGroupedByProject && <th>Collection Name</th>}
                                    <th>Priority</th>
                                    <th>Responsible</th>
                                    <th className={styles.dateField}>Due Date</th>
                                    <th className={styles.pencilColumn}>
                                      <PencilFill size={16} />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {!isGroupedByProject &&
                                    getFilteredData(item.value)
                                      .sort((a, b) => sortByLexoRank(a, b, !!projectId))
                                      .map((item2, index) => (
                                        <Draggable draggableId={item2.id} index={index} key={item2.id}>
                                          {(provided) => {
                                            return (
                                              <tr
                                                key={item2.id}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <td
                                                  className={classNames(
                                                    'text-center position-relative',
                                                    styles.indexField,
                                                  )}
                                                >
                                                  <span className={styles.dragIcon}>
                                                    <GripVertical size={14} color="#6F767E" />
                                                  </span>
                                                  {index + 1}
                                                </td>
                                                <td
                                                  className="cursor-pointer"
                                                  onClick={() => handleItemClick(item2)}
                                                  style={{ width: '40%' }}
                                                >
                                                  {item2.title}
                                                </td>
                                                <td className={classNames('position-relative', styles.priorityField)}>
                                                  <UiPriority
                                                    type={item2.priority}
                                                    onClick={(event: any) => {
                                                      event.stopPropagation();
                                                      setIsPriority(item2.id);
                                                    }}
                                                  />
                                                  {isPriority === item2.id && (
                                                    <OutsideClickHandler onOutsideClick={() => setIsPriority(false)}>
                                                      <div className={styles.priorityModel}>
                                                        <PriorityModal
                                                          onClick={(newPriority) => {
                                                            handlePriorityChange({
                                                              ...item2,
                                                              priority: newPriority,
                                                            });
                                                          }}
                                                          currentPriority={item2.priority}
                                                        />
                                                      </div>
                                                    </OutsideClickHandler>
                                                  )}
                                                </td>
                                                <td className={styles.userField}>
                                                  <div
                                                    className="position-relative"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      setUsersPopupId(item2.id);
                                                    }}
                                                  >
                                                    <User user={item2.owner} small active showPopUpOnHover noHover />
                                                    {usersPopupId === item2.id && (
                                                      <OutsideClickHandler
                                                        onOutsideClick={() => {
                                                          setUsersPopupId(null);
                                                        }}
                                                      >
                                                        <UsersDropdown
                                                          hide={() => {
                                                            setUsersPopupId(null);
                                                            handleUpdateThreatModelColumn(item2.status);
                                                          }}
                                                          threatModelId={item2.id}
                                                        />
                                                      </OutsideClickHandler>
                                                    )}
                                                  </div>
                                                </td>
                                                <td className={classNames('position-relative', styles.dateField)}>
                                                  <DateInput
                                                    value={item2.due_to_date ? new Date(item2.due_to_date) : ''}
                                                    placeholder="Due date"
                                                    onChange={(newValue) =>
                                                      handleDateChange({
                                                        ...item2,
                                                        due_to_date: newValue,
                                                      })
                                                    }
                                                  />
                                                </td>
                                                <td onMouseLeave={handleThreatModelColumnMouseLeave} width={40}>
                                                  <div
                                                    onClick={(e) => handleSettingsButtonClick(e, item2.id)}
                                                    className={styles.settingsColumn}
                                                  >
                                                    <ThreeDotsVertical size={16} />
                                                    {isSettings.id === item2.id &&
                                                      createPortal(
                                                        <CardSettings
                                                          currentItem={item2}
                                                          position={isSettings}
                                                          handleEmitAfterUpdate={handleEmitAfterProjectUpdate}
                                                          onTransferClick={onTransferClick}
                                                          setIsSettings={setIsSettings}
                                                        />,
                                                        ListEl,
                                                      )}
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }}
                                        </Draggable>
                                      ))}
                                  {isGroupedByProject &&
                                    getColumnByStatus(item.value)
                                      ?.data?.sort((a: any, b: any) => sortByLexoRank(a, b, !!projectId))
                                      ?.map((k: ThreatModel, kIndex: number) => {
                                        return (
                                          <Draggable draggableId={k.id} index={kIndex} key={k.id}>
                                            {(provided) => {
                                              return (
                                                <tr
                                                  key={k.id}
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                >
                                                  <td
                                                    className={classNames(
                                                      'text-center position-relative',
                                                      styles.indexField,
                                                    )}
                                                  >
                                                    <div className={styles.dragIcon}>
                                                      <GripVertical size={14} color="#6F767E" />
                                                    </div>
                                                    {kIndex + 1}
                                                  </td>
                                                  <td
                                                    className="cursor-pointer"
                                                    onClick={() => handleItemClick(k)}
                                                    style={{ width: '40%' }}
                                                  >
                                                    {k.title}
                                                  </td>
                                                  <td
                                                    className="cursor-pointer"
                                                    onClick={() => handleCollectionNameClick(k)}
                                                  >
                                                    {k?.project?.title || ''}
                                                  </td>
                                                  <td className={classNames('position-relative', styles.priorityField)}>
                                                    <UiPriority
                                                      type={k.priority}
                                                      onClick={(event: any) => {
                                                        event.stopPropagation();
                                                        setIsPriority(k.id);
                                                      }}
                                                    />
                                                    {isPriority === k.id && (
                                                      <OutsideClickHandler onOutsideClick={() => setIsPriority(false)}>
                                                        <div className={styles.priorityModel}>
                                                          <PriorityModal
                                                            onClick={(newPriority) => {
                                                              handlePriorityChange({
                                                                ...k,
                                                                priority: newPriority,
                                                              });
                                                            }}
                                                            currentPriority={k.priority}
                                                          />
                                                        </div>
                                                      </OutsideClickHandler>
                                                    )}
                                                  </td>
                                                  <td className={styles.userField}>
                                                    <div
                                                      className="position-relative"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setUsersPopupId(k.id);
                                                      }}
                                                    >
                                                      <User user={k.owner} small active showPopUpOnHover noHover />
                                                      {usersPopupId === k.id && (
                                                        <OutsideClickHandler
                                                          onOutsideClick={() => {
                                                            setUsersPopupId(null);
                                                          }}
                                                        >
                                                          <UsersDropdown
                                                            hide={() => {
                                                              setUsersPopupId(null);
                                                              handleUpdateThreatModelColumn(k.status);
                                                            }}
                                                            threatModelId={k.id}
                                                          />
                                                        </OutsideClickHandler>
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td className={classNames('position-relative', styles.dateField)}>
                                                    <DateInput
                                                      value={k.due_to_date ? new Date(k.due_to_date) : ''}
                                                      placeholder="Due date"
                                                      onChange={(newValue) =>
                                                        handleDateChange({
                                                          ...k,
                                                          due_to_date: newValue,
                                                        })
                                                      }
                                                    />
                                                  </td>
                                                </tr>
                                              );
                                            }}
                                          </Draggable>
                                        );
                                      })}
                                </tbody>
                                <tfoot>{provided.placeholder}</tfoot>
                              </table>
                              {renderLoadMore(item.value)}
                            </div>
                          )}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              );
            })}
          </DragDropContext>
        </DndProvider>
      </div>
    </div>
  );
};

export default ThreatModelsListMode;
