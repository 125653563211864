import type { Models } from '@rematch/core';

import { app } from './app';
import { attributes } from './attributes';
import { auth } from './auth';
import { cliThreatModel } from './cli-threat-models';
import { customCodex } from './custom-codex';
import { customer } from './customer';
import { diagram } from './diagram';
import { drawn } from './drawn';
import { mitigations } from './mitigations';
import { plan } from './plan';
import { project } from './project';
import { representation } from './representation';
import { running } from './running';
import { team } from './team';
import { threatModel } from './threat-model';
import { threats } from './threats';
import { tiers } from './tiers';
import { user } from './user';

export interface RootModel extends Models<RootModel> {
  auth: typeof auth;
  app: typeof app;
  attributes: typeof attributes;
  user: typeof user;
  team: typeof team;
  plan: typeof plan;
  project: typeof project;
  threatModel: typeof threatModel;
  representation: typeof representation;
  diagram: typeof diagram;
  drawn: typeof drawn;
  cliThreatModel: typeof cliThreatModel;
  threats: typeof threats;
  mitigations: typeof mitigations;
  tiers: typeof tiers;
  running: typeof running;
  customer: typeof customer;
  customCodex: typeof customCodex;
}

export const models: RootModel = {
  app,
  auth,
  attributes,
  user,
  team,
  plan,
  project,
  threatModel,
  representation,
  diagram,
  drawn,
  cliThreatModel,
  threats,
  mitigations,
  tiers,
  running,
  customer,
  customCodex,
};
