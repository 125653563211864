import type { FC } from 'react';

import styles from './index.module.css';

import UiButton from '../UIButton/UiButton';

interface IProps {
  title: string;
  message: string;
  btnTitle: string;
  onClick: () => void;
}

const Placeholder: FC<IProps> = ({ title, message, btnTitle, onClick }) => {
  return (
    <div className={styles.container}>
      <p className="fw-bold">{title}</p>
      <p>{message}</p>
      <UiButton onClick={onClick} htmlType="submit" fullWidth className="fw-bold">
        {btnTitle}
      </UiButton>
    </div>
  );
};

export default Placeholder;
