import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';
import type { NodeProps } from 'reactflow';
import { NodeResizer, NodeToolbar, Position } from 'reactflow';
import { getColorDependsOnBackground } from '../../../helpers/colors';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import styles from '../diagram.module.css';
import DiagramPopupTools from '../DiagramPopupTools';
import NodeInput from './NodeInput/NodeInput';

const TrustBoundaryNode = ({ data, selected, id, type }: NodeProps) => {
  const { selectedNode, collaborators, editedComponentId = '' } = useTypedSelector((state) => state.diagram);
  const [attributesPopup, setAttributesPopup] = useState<any>({
    visible: false,
    componentId: id,
  });
  const [isInputActive, setIsInputActive] = useState(false);
  const hasCollaborators =
    collaborators?.length &&
    !!collaborators?.filter((id) => {
      return data?.selectedBy?.includes(id);
    })?.length;

  useEffect(() => {
    if (!selected) setIsInputActive(false);

    return () => {
      setAttributesPopup({ visible: false });
    };
  }, [selected]);

  useEffect(() => {
    if (editedComponentId?.length && id === editedComponentId) setIsInputActive(true);
  }, [editedComponentId]);

  const handleLabelDoubleClick = () => {
    setIsInputActive(true);
  };

  return (
    <>
      <NodeResizer nodeId={data.id} minHeight={50} minWidth={70} isVisible={selected} lineClassName="pe-auto" />
      <div className={classNames('nodeBorder', styles.nodeBorderTop)} onDoubleClick={handleLabelDoubleClick} />
      <div className={classNames('nodeBorder', styles.nodeBorderLeft)} />
      <div className={classNames('nodeBorder', styles.nodeBorderRight)} />
      <div className={classNames('nodeBorder', styles.nodeBorderBottom)} />
      <NodeToolbar isVisible={selected && !attributesPopup.visible} position={Position.Top}>
        <DiagramPopupTools
          setAttributesPopup={() => {
            setAttributesPopup({
              componentId: id,
              visible: true,
            });
          }}
          node={{
            id,
            type,
            color: data.color,
            borderColor: data?.borderColor,
            borderWidth: data?.borderWidth,
            fontSize: data?.fontSize,
            autoFontSize: data?.autoFontSize,
            textStyle: data?.textStyle,
            fontColor: data?.fontColor,
          }}
        />
      </NodeToolbar>
      <div
        className={classNames(styles.diagramNodeDataStore, styles.trustBoundaryNode, {
          [styles.hasCollaborators]: hasCollaborators,
        })}
        data-isnode={1}
        style={{
          cursor: selectedNode?.length ? 'crosshair' : 'unset',
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='${
            data?.color?.replace('#', '%23') || 'none'
          }' rx='4' ry='4' stroke='${data?.borderColor?.replace('#', '%23') || '%23333'}' stroke-width='${
            data?.borderWidth ? Number(data.borderWidth) + 6 : '6'
          }' stroke-dasharray='8%2c 24' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
        }}
      >
        <div data-isnode={1} className="position-absolute left-0 right-0 top-0 bottom-0 text-center w-100">
          <div data-isnode={1} onDoubleClick={handleLabelDoubleClick} className={styles.trustBoundaryNodeLabel}>
            {isInputActive && (
              <NodeInput
                id={id}
                value={data.label}
                onBlur={() => setIsInputActive(false)}
                inputColor={data?.fontColor || getColorDependsOnBackground(data?.color || '#FFFFFF')}
              />
            )}
            {!isInputActive && (
              <span
                data-isnode={1}
                style={{
                  color: data?.fontColor || getColorDependsOnBackground(data?.color || '#FFFFFF'),
                  fontSize: data?.autoFontSize ? '16cqw' : `${data?.fontSize || 12}px`,
                  fontWeight: `${data?.textStyle?.isBold ? '600' : '400'}`,
                  textDecoration: `${
                    data?.textStyle?.isUnderline
                      ? 'underline'
                      : data?.textStyle?.isLineThrough
                      ? 'line-through'
                      : 'none'
                  }`,
                  fontStyle: `${data?.isItalic ? 'italic' : 'normal'}`,
                }}
              >
                {data.label}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(TrustBoundaryNode);
