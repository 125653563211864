import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { COLUMN_NAMES, COLUMN_TITLES, DEFAULT_THREAT_MODEL_URL_QUERY, SKELETON_COLORS } from '../../global/constants';
import type { ThreatModel } from '../../global/types';
import { getRankForOneColumn, getRankForTwoColumn, sortByLexoRank } from '../../helpers/diagram';
import { useSocket } from '../../hooks/useSocket';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';
import KanbanColumn from './KanbanColumn';
import RepresentationCard from './RepresentationCard';
import styles from './ThreatModelsKanbanMode.module.css';

type ThreatModelsKanbanModeProps = {
  projectId?: string;
  runningId?: string;
  onTransferClick?: (project: any, selectedForTransferModel: any) => void;
};

const ThreatModelsKanbanMode: FC<ThreatModelsKanbanModeProps> = ({ projectId, runningId, onTransferClick }) => {
  const dispatch = useDispatch<Dispatch>();
  const location = useLocation();
  const { REPRESENTATION, THREATS_AND_MITIGATIONS, RETROSPECTIVE, DONE, liveUpdateData } = useTypedSelector(
    (state) => state.threatModel,
  );
  const projectsLiveUpdateData = useTypedSelector((state) => state.project.liveUpdateData);
  const { emitGlobalUpdate } = useSocket();

  useEffect(() => {
    if (location.pathname.includes('my-models')) {
      dispatch.threatModel.getThreatModelsByQuery({
        ...DEFAULT_THREAT_MODEL_URL_QUERY,
        status: COLUMN_NAMES.REPRESENTATION,
      });
      dispatch.threatModel.getThreatModelsByQuery({
        ...DEFAULT_THREAT_MODEL_URL_QUERY,
        status: COLUMN_NAMES.THREATS_AND_MITIGATIONS,
      });
      dispatch.threatModel.getThreatModelsByQuery({
        ...DEFAULT_THREAT_MODEL_URL_QUERY,
        status: COLUMN_NAMES.RETROSPECTIVE,
      });
      dispatch.threatModel.getThreatModelsByQuery({
        ...DEFAULT_THREAT_MODEL_URL_QUERY,
        status: COLUMN_NAMES.DONE,
      });
    }
  }, []);

  useEffect(() => {
    if (liveUpdateData) {
      // TODO: Add counter in future
      if (
        [...REPRESENTATION.data, ...THREATS_AND_MITIGATIONS.data, ...RETROSPECTIVE.data, ...DONE.data].some(
          (i: ThreatModel) => liveUpdateData.includes(i.id) || location.pathname.includes('my-models'),
        )
      ) {
        dispatch.threatModel.getThreatModelsByQuery(REPRESENTATION);
        dispatch.threatModel.getThreatModelsByQuery(THREATS_AND_MITIGATIONS);
        dispatch.threatModel.getThreatModelsByQuery(RETROSPECTIVE);
        dispatch.threatModel.getThreatModelsByQuery(DONE);
      }

      dispatch.threatModel.setLiveUpdateData(null);
    }
  }, [liveUpdateData]);

  useEffect(() => {
    if (projectsLiveUpdateData && projectId) {
      if (projectsLiveUpdateData.includes(projectId)) {
        dispatch.threatModel.getThreatModelsByQuery(REPRESENTATION);
        dispatch.threatModel.getThreatModelsByQuery(THREATS_AND_MITIGATIONS);
        dispatch.threatModel.getThreatModelsByQuery(RETROSPECTIVE);
        dispatch.threatModel.getThreatModelsByQuery(DONE);
      }
    }
  }, [projectsLiveUpdateData]);

  const handleEmitAfterProjectUpdate = (isArchive = false, threatModels?: string[]) => {
    if (projectId && typeof projectId === 'string') {
      const archiveData = isArchive ? [projectId] : undefined;

      emitGlobalUpdate({
        projects: [projectId],
        archives: archiveData,
        ...(threatModels?.length && { threatModels }),
      });
    }
  };

  const handleEmitAfterThreatModelUpdate = (threatModelId: string) => {
    if (threatModelId && location.pathname.includes('my-models')) {
      emitGlobalUpdate({
        threatModels: [threatModelId],
      });
    }
  };

  const getColumnByStatus = (status: string) => {
    switch (status) {
      case REPRESENTATION.status:
        return structuredClone(REPRESENTATION);
      case RETROSPECTIVE.status:
        return structuredClone(RETROSPECTIVE);
      case THREATS_AND_MITIGATIONS.status:
        return structuredClone(THREATS_AND_MITIGATIONS);
      case DONE.status:
        return structuredClone(DONE);
    }
  };

  const getColumnByTitle = (title: string): string => {
    switch (title) {
      case REPRESENTATION.status:
        return COLUMN_TITLES.REPRESENTATION;
      case RETROSPECTIVE.status:
        return COLUMN_TITLES.RETROSPECTIVE;
      case THREATS_AND_MITIGATIONS.status:
        return COLUMN_TITLES.THREATS_AND_MITIGATIONS;
      case DONE.status:
        return COLUMN_TITLES.DONE;
      default:
        return '';
    }
  };

  const onDragEnd = async ({ source, destination, draggableId }: DropResult) => {
    if (destination === undefined || destination === null) return null;

    if (source.droppableId === destination.droppableId && destination.index === source.index) return null;

    const start = getColumnByStatus(source.droppableId);
    const end = getColumnByStatus(destination.droppableId);

    if (start.status === end.status) {
      const newRank = getRankForOneColumn(start.data, source.index, destination.index, !!projectId);

      start.data.forEach((tm: any) => {
        if (tm.id === draggableId) {
          if (projectId) tm.rank = newRank;
          else tm.my_models_rank = newRank;

          tm.status = destination.droppableId;
        }
      });
      dispatch.threatModel.setField(start);

      await dispatch.threatModel.updateThreatModelOrder({
        id: draggableId,
        newStatus: destination.droppableId,
        newRank,
        projectId,
      });
      handleEmitAfterProjectUpdate(false, [draggableId]);

      dispatch.threatModel.getThreatModelsByQuery({
        ...start,
        page: 0,
      });

      return null;
    }

    const newRank = getRankForTwoColumn(start.data, end.data, source.index, destination.index, !!projectId);

    const currentItem = start.data[source.index];

    if (projectId) currentItem.rank = newRank;
    else currentItem.my_models_rank = newRank;

    currentItem.status = destination.droppableId;

    dispatch.threatModel.setColumns(
      structuredClone({
        [getColumnByTitle(start.status)]: {
          ...getColumnByStatus(start.status),
          data: start.data.filter((_: any, idx: number) => idx !== source.index),
        },
        [getColumnByTitle(end.status)]: {
          ...getColumnByStatus(end.status),
          data: [...end.data, currentItem],
        },
      }),
    );

    await dispatch.threatModel.updateThreatModelOrder({
      id: draggableId,
      newStatus: destination.droppableId,
      newRank,
      projectId,
    });
    handleEmitAfterProjectUpdate(false, [currentItem.id, end.data[destination.index]]);

    dispatch.threatModel.getThreatModelsByQuery({
      ...start,
      page: 0,
    });
    dispatch.threatModel.getThreatModelsByQuery({
      ...end,
      page: 0,
    });

    return null;
  };

  const returnItemsForColumn = (data: any[], fetched: boolean) => {
    if (!data?.length && !fetched) {
      return (
        <Skeleton
          width="100%"
          height={140}
          borderRadius={8}
          baseColor={SKELETON_COLORS.BASE}
          highlightColor={SKELETON_COLORS.HIGHLIGHT}
        />
      );
    }

    if (data?.length) {
      return data
        .sort((a, b) => sortByLexoRank(a, b, !!projectId))
        .map((item, index) => (
          <RepresentationCard
            threatModel={item}
            projectId={projectId || undefined}
            key={item.id}
            title={item.title}
            index={index}
            handleEmitAfterUpdate={(val, threatModelId?: string) => {
              handleEmitAfterProjectUpdate(val, [item.id]);
              handleEmitAfterThreatModelUpdate(item.id);
            }}
            onTransferClick={onTransferClick}
            query={getColumnByStatus(item.status)}
          />
        ));
    }

    return null;
  };

  return (
    <div
      id="kanban_component"
      className={classNames(styles.dashboard, {
        [styles.dashboardMyModelHeight]: !projectId,
        [styles.dashboardHeight]: projectId,
      })}
    >
      <DndProvider backend={HTML5Backend}>
        <DragDropContext onDragEnd={onDragEnd}>
          <KanbanColumn title={COLUMN_NAMES.REPRESENTATION} data={REPRESENTATION} runningId={runningId}>
            {returnItemsForColumn(REPRESENTATION.data, REPRESENTATION.fetched)}
          </KanbanColumn>

          <KanbanColumn
            title={COLUMN_NAMES.THREATS_AND_MITIGATIONS}
            data={THREATS_AND_MITIGATIONS}
            runningId={runningId}
          >
            {returnItemsForColumn(THREATS_AND_MITIGATIONS.data, THREATS_AND_MITIGATIONS.fetched)}
          </KanbanColumn>

          <KanbanColumn title={COLUMN_NAMES.RETROSPECTIVE} data={RETROSPECTIVE} runningId={runningId}>
            {returnItemsForColumn(RETROSPECTIVE.data, RETROSPECTIVE.fetched)}
          </KanbanColumn>

          <KanbanColumn title={COLUMN_NAMES.DONE} data={DONE} runningId={runningId}>
            {returnItemsForColumn(DONE.data, DONE.fetched)}
          </KanbanColumn>
        </DragDropContext>
      </DndProvider>
    </div>
  );
};

export default ThreatModelsKanbanMode;
