import classNames from 'classnames';
import type { FC, SyntheticEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { CheckLg, Pencil, PlusLg, Trash3Fill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Spinner } from 'reactstrap';
import { RepresentationFormat, TIER } from '../../../global/constants';
import type { Representation, ThreatModel } from '../../../global/types';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import UpgradePlan from '../../UpgradePlan/UpgradePlan';
import styles from './index.module.css';

type IRepresentationDropdownProps = {
  deleteRepresentationId: string;
  currentRepresentation: Representation | null;
  currentThreatModel?: ThreatModel | null;
  setDeleteRepresentationId: (val: string) => void;
  setShowRepresentationDropdown: (val: boolean) => void;
  isReadMode: boolean;
};

export const RepresentationDropdown: FC<IRepresentationDropdownProps> = ({
  isReadMode,
  currentThreatModel,
  currentRepresentation,
  deleteRepresentationId,
  setDeleteRepresentationId,
  setShowRepresentationDropdown,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [removeIcon, setRemoveIcon] = useState('');
  const [loading, setLoading] = useState('');
  const [editTitle, setEditTitle] = useState('');
  const [isSmall, setIsSmall] = useState(false);
  const customerLimits = useTypedSelector((state) => state.tiers.customerLimits);
  const currentUserEmail = useTypedSelector((state) => state.user?.current?.email);
  const titleRef = useRef<HTMLInputElement | null>(null);
  const dropdownRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const cond = ((dropdownRef.current && dropdownRef.current?.offsetWidth) || 0) < 160;
    setIsSmall(cond);
  }, [dropdownRef]);

  const handleCreateNewRepresentation = async (event: SyntheticEvent) => {
    if (loading) return;

    event.preventDefault();
    event.stopPropagation();

    if (currentThreatModel?.id) {
      setLoading('add');
      const newRepresentation = await dispatch.representation.createRepresentation({
        threatModelId: currentThreatModel.id,
        format: RepresentationFormat.DIAGRAM,
      });
      dispatch.representation.setData(null);
      navigate(`/collections/${params.id}/d/${newRepresentation.id}`);
      dispatch.threatModel.getThreatModel(currentThreatModel.id);
      setLoading('');
    }
  };

  const checkIsDisallowToCreateRepresentation = () => {
    const representationsPerModel = customerLimits?.threatModels?.representationsPerModel || 0;
    const isBetaUser = customerLimits?.isBetaUser;
    const representationsCount = currentThreatModel?.representations?.length || 0;
    const isLimitReached = representationsCount >= representationsPerModel;
    const isFree = customerLimits?.tier === TIER.FREE;

    return isLimitReached && currentUserEmail && !isBetaUser && isFree;
  };

  const handleChangeRepresentationTitle = async (event: SyntheticEvent, repId: string) => {
    if (loading) return;

    event.preventDefault();
    event.stopPropagation();
    const value = titleRef?.current?.value;

    if (currentThreatModel && titleRef.current && value?.length && currentRepresentation) {
      setLoading('title');
      dispatch.threatModel.setCurrentThreatModel({
        ...currentThreatModel,
        representations:
          currentThreatModel?.representations?.map((i) => {
            if (i.id === repId) i.title = value;

            return i;
          }) || [],
      });

      if (currentRepresentation.id === repId) {
        dispatch.representation.setData({
          ...currentRepresentation,
          title: value,
        });
      }

      await dispatch.representation.updateRepresentation({
        id: repId,
        title: value,
      });
      setLoading('');
      dispatch.threatModel.getThreatModel(currentThreatModel?.id);
    }

    setEditTitle('');
  };

  const needToUpgradePlan = checkIsDisallowToCreateRepresentation();

  return (
    <div
      ref={dropdownRef}
      className={classNames(styles.representationDropdown, 'shadow', {
        [styles.dropdownSmallWidth]: isSmall,
      })}
    >
      {currentThreatModel?.representations?.map((r, idx) => {
        const active = r.id === params.diagramId;

        return (
          <div
            className={classNames(styles.representationDropdownItem, {
              [styles.representationDropdownItemActive]: active,
              [styles.representationDropdownDeletedItemActive]: false,
            })}
            onClick={() => {
              if (!active) navigate(`/collections/${params.id}/d/${r.id}`);

              setShowRepresentationDropdown(false);
            }}
            onMouseEnter={() => setRemoveIcon(r.id)}
            onMouseLeave={() => setRemoveIcon('')}
            key={r.id}
          >
            {editTitle !== r.id && (
              <div
                id="representationDropdown"
                className={styles.representationTitle}
                title={r.title || `Canvas ${idx + 1}`}
              >
                {r.title || `Canvas ${idx + 1}`}
              </div>
            )}

            {editTitle === r.id && (
              <>
                <input
                  className={styles.representationTitleInput}
                  defaultValue={r.title || `Canvas ${idx + 1}`}
                  ref={titleRef}
                  id="representationDropdown"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  style={{
                    width:
                      dropdownRef?.current && dropdownRef?.current?.offsetWidth
                        ? (dropdownRef.current.offsetWidth || 100) - 50
                        : 100,
                  }}
                />
                <div
                  id="representationDropdown"
                  className={styles.inputSubmitIcon}
                  onClick={(e) => handleChangeRepresentationTitle(e, r.id)}
                >
                  {loading === 'title' ? <Spinner size="sm" /> : <CheckLg size={18} className="pe-none" />}
                </div>
              </>
            )}
            {(currentThreatModel?.representations?.length || 0) > 1 && (
              <div
                hidden={(removeIcon !== r.id && deleteRepresentationId !== r.id) || editTitle === r.id}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  if (editTitle) setEditTitle('');

                  setDeleteRepresentationId(removeIcon);
                }}
                className={styles.deleteRepresentationIcon}
              >
                {!isReadMode && <Trash3Fill size={16} className="pe-none" />}
              </div>
            )}
            <div
              hidden={(removeIcon !== r.id && deleteRepresentationId !== r.id) || editTitle === r.id}
              className={styles.editRepresentationIcon}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEditTitle(r.id);
              }}
            >
              {!isReadMode && <Pencil size={16} className="pe-none" />}
            </div>
          </div>
        );
      })}
      {needToUpgradePlan ? (
        <UpgradePlan
          trigger={
            <div
              className={classNames(
                styles.representationDropdownItem,
                'd-flex align-items-center justify-content-center gap-2',
              )}
            >
              Create
              <PlusLg size={16} />
            </div>
          }
        />
      ) : (
        !isReadMode && (
          <div
            className={classNames(
              styles.representationDropdownItem,
              'd-flex align-items-center justify-content-center gap-2',
            )}
            onClick={handleCreateNewRepresentation}
          >
            Create {loading === 'add' ? <Spinner size="sm" /> : <PlusLg size={16} />}
          </div>
        )
      )}
    </div>
  );
};
