import type { FC } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from '../../ThreatRegister/ThreatRegisterTable/ThreatRegisterTable.module.css';

type RunningTableSortColumnProps = {
  column: string;
};

enum ORDER_TYPE {
  ASC = 'ASC',
  DESC = 'DESC',
}

const RunningTableSortColumn: FC<RunningTableSortColumnProps> = ({ column }) => {
  const dispatch = useDispatch<Dispatch>();
  const { runningListQuery } = useTypedSelector((state) => state.running);
  const params = useParams();
  const { id: projectId } = params;

  const handleSort = () => {
    dispatch.running.getRunningListByProjectId({
      ...runningListQuery,
      projectId,
      page: 0,
      sort: column,
      order: runningListQuery.order === ORDER_TYPE.ASC ? ORDER_TYPE.DESC : ORDER_TYPE.ASC,
    });
  };

  return (
    <div onClick={handleSort}>
      <CaretUpFill className={styles.tableSortIconUp} />
      <CaretDownFill className={styles.tableSortIconDown} />
    </div>
  );
};

export default RunningTableSortColumn;
