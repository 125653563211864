import { useEffect, useState } from 'react';
import { Trash } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import { isFreePlan } from '../../../helpers/isFreePlan';

import { TIER } from '../../../global/constants';
import type { SAML_CONFIG } from '../../../global/types';
import { getEncryptionCertificateFile, getFileFromMetadataXml } from '../../../helpers/app';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import UiModal from '../Modal/UiModal';
import UiButton from '../UIButton/UiButton';
import { ImportUsersForm } from './ImportUsersForm';
import styles from './ManageSamlForm.module.css';
import { SamlForm } from './SamlForm';

export const ManageSamlForm = () => {
  const dispatch = useDispatch<Dispatch>();
  const customerLimits = useTypedSelector((state) => state.tiers.customerLimits);
  const { isSamlConfigured } = useTypedSelector((state) => state.customer);

  const [loading, setLoading] = useState('');
  const [samlForm, setSamlForm] = useState(false);
  const [csvFileForm, setCsvFileForm] = useState(false);
  const [deleteSamlForm, setDeleteSamlForm] = useState(false);
  const [samlConfig, setSamlConfig] = useState<SAML_CONFIG | null>(null);

  const disallowed = customerLimits?.tier === TIER.FREE && !customerLimits?.isBetaUser;

  const getSamlConfig = async () => {
    const result = await dispatch.user.checkSamlConfigured();

    if (result) {
      dispatch.customer.setIsSamlConfigured(true);
    }
  };

  useEffect(() => {
    if (!customerLimits || disallowed) return;

    if (!samlConfig) getSamlConfig();
  }, [samlConfig, customerLimits, disallowed]);

  const handleClickButton = async () => {
    setLoading('configured');
    const config = await dispatch.user.getSamlConfig();

    setSamlConfig({
      acsUrl: config.acsUrl,
      entityId: config.entityId,
      logoutUrl: config.logoutUrl,
      identifiers: config?.identifiers || [],
      metadataUrl: config?.metadataUrl,
      idpSignOut: config?.idpSignOut,
      encryptedResponses: config?.encryptedResponses,
      attributeMapping: config?.attributeMapping,
      metadataFile: getFileFromMetadataXml(config?.metadataFile),
      activeEncryptionCertificate: getEncryptionCertificateFile(config?.activeEncryptionCertificate),
    });
    setLoading('');

    setSamlForm(true);
  };

  const handleDeleteIDP = async () => {
    setLoading('delete');
    const isOk = await dispatch.user.deleteSamlIdp();

    if (isOk) {
      setDeleteSamlForm(false);
      dispatch.customer.setIsSamlConfigured(false);
      setSamlConfig(null);
    }

    setLoading('');
  };

  return (
    <>
      <div className="d-flex w-100 justify-content-between align-items-center">
        <div className={styles.title}>SAML authentication</div>
        <UiButton
          disabled={!customerLimits || isFreePlan(customerLimits)}
          className={styles.mainButton}
          onClick={handleClickButton}
        >
          {loading === 'configured' ? <Spinner color="light" size="sm" /> : <div>Manage SAML</div>}
        </UiButton>
      </div>

      {isSamlConfigured && (
        <div className="d-flex w-100 justify-content-between align-items-center">
          <div className={styles.secondTitle}>You have SAML configured</div>
          <div className="d-flex align-items-center gap-2">
            <UiButton type="transparent" onClick={() => setCsvFileForm(true)}>
              Import Users
            </UiButton>
            <UiButton type="transparent" onClick={() => setDeleteSamlForm(true)}>
              <Trash color="red" size={24} />
            </UiButton>
          </div>
        </div>
      )}

      {samlForm && samlConfig && (
        <SamlForm
          setSamlConfig={setSamlConfig}
          isConfigured={isSamlConfigured}
          samlConfig={samlConfig}
          onClose={(needUpdateConfig) => {
            setSamlForm(false);

            if (needUpdateConfig) {
              setSamlConfig(null);
            }
          }}
        />
      )}
      {csvFileForm && <ImportUsersForm onClose={() => setCsvFileForm(false)} />}
      {deleteSamlForm && (
        <UiModal title="Remove SAML configured" onClose={() => setDeleteSamlForm(false)}>
          <div className={styles.deleteModalText}>Are you sure you want to remove SAML configured?</div>
          <div className="d-flex justify-content-end">
            <UiButton className="mt-3 me-3" type="transparent" onClick={() => setDeleteSamlForm(false)}>
              No
            </UiButton>
            <UiButton className="mt-3 me-3" onClick={handleDeleteIDP} style={{ width: '100px' }}>
              {loading === 'delete' ? <Spinner color="light" size="sm" /> : 'Save'}
            </UiButton>
          </div>
        </UiModal>
      )}
    </>
  );
};
