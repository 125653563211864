import classNames from 'classnames';

import styles from './style.module.css';

const CodeGeniusPage = () => {
  return (
    <div className={classNames('content')}>
      <div className={styles.header}>Code Genius</div>
      <div className="row mt-3">
        <div className="col">
          <h5>Releases</h5>
          <ul className="list-group">
            <li className="list-group-item">
              Release v0.7.2 (latest) -{' '}
              <a
                href="https://devici-code-genius-source.s3.us-east-2.amazonaws.com/v0.7.2/code-genius-v0.7.2.zip"
                target="_blank"
                rel="noreferrer"
              >
                Link to the binary file
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h5>Instructions on How to Install</h5>
        </div>
      </div>
      <hr />
      <h4>Step 1. Choose a binary depending on your operating system and architecture on your machine:</h4>
      <div className="">
        <h5>How do I do it?</h5>
        <p>
          For <strong>Linux</strong> or <strong>MacOS</strong>, open a terminal and run the following command to gather
          architecture information:
        </p>
        <pre>
          <code>dpkg --print-architecture</code>
        </pre>
        <p>You can also use the following command to gather information about your operating system:</p>
        <pre>
          <code>uname -a</code>
        </pre>
        <p>Example output:</p>
        <pre>
          <code>
            Linux &lt;machine name&gt; &lt;additional info&gt; Thu Oct 5 21:02:42 UTC 2023 x86_64 x86_64 x86_64
            GNU/Linux
          </code>
        </pre>
        <p>
          Where <code>x86_64</code> is the architecture (also referred as <code>amd64</code>) and <code>Linux</code> is
          the operating system.
        </p>
        <p>
          For <strong>Windows</strong>, open a command prompt and run the following command to gather architecture
          information:
        </p>
        <pre>
          <code>wmic os get osarchitecture</code>
        </pre>
      </div>
      <hr />
      <h4>Step 2. Download the binary</h4>
      <div className="">
        <h5>MacOS</h5>
        <ul>
          <li>
            <code>code_genius_darwin-10.12-amd64</code> (amd64)
          </li>
          <li>
            <code>code_genius_darwin-10.12-arm64</code> (arm)
          </li>
        </ul>
        <h5>Windows</h5>
        <ul>
          <li>
            <code>code_genius_windows-4.0-amd64.exe</code>
          </li>
        </ul>
        <h5>Linux</h5>
        <ul>
          <li>
            <code>code_genius_linux_amd64</code>
          </li>
        </ul>
      </div>
      <hr />
      <h4>Step 3. Run the binary</h4>
      <div className="">
        <h5>MacOS</h5>
        <p>Run the binary.</p>
        <p>
          <em>Note.</em> You may need to give the binary permission to run by double-clicking on it and selecting
          &quot;Open&quot; in the dialog box.
        </p>
        <pre>
          <code>./code_genius --help</code>
        </pre>
        <h5>Windows</h5>
        <p>Run the binary:</p>
        <pre>
          <code>./code_genius.exe --help</code>
        </pre>
        <h5>Linux</h5>
        <p>Run this command to make the binary executable if necessary:</p>
        <pre>
          <code>chmod +x code_genius_linux_amd64</code>
        </pre>
        <p>Then, run the binary:</p>
        <pre>
          <code>./code_genius --help</code>
        </pre>
      </div>
      <hr />
      <h4>Step 4. Analyze a file</h4>
      <div className="">
        <p>First, log in to the Devici platform by running the following command:</p>
        <pre>
          <code>./code_genius login</code>
        </pre>
        <p>
          This command will open a browser window and ask you to log in to the Devici platform. Note that you need to be
          logged in to the platform first before acquiring the token for your local machine.
        </p>
        <p>
          After logging in, you can run the analyze command again and the results will be sent to the platform if the
          token is still valid.
        </p>
        <pre>
          <code>./code_genius analyze [file]</code>
        </pre>
      </div>
      <hr />
      <h4>Features</h4>
      <div className="">
        <h5>Walk mode</h5>
        <p>
          You can analyze all files in a directory by using the <code>walk</code> command:
        </p>
        <pre>
          <code>./code_genius walk [directory] [flags]</code>
        </pre>
        <p>
          Where <code>[directory]</code> is the path to the directory you want to analyze. Note that some of the payment
          plans may not have walk mode available.
        </p>
        <h5>Function scanning</h5>
        <p>
          During Analyze mode you can choose a specific function within the file to create the DFD for by using the{' '}
          <code>--function</code> flag:
        </p>
        <pre>
          <code>./code_genius analyze [file] --function [function_name]</code>
        </pre>
        <p>
          If you don&apos;t know the name of the function, you can list all detected functions by using the{' '}
          <code>--list</code> flag:
        </p>
        <pre>
          <code>./code_genius analyze [file] --list</code>
        </pre>
        <p>You can combine both flags to list all functions and then choose the one you want to analyze.</p>
        <pre>
          <code>./code_genius analyze [file] -lf</code>
        </pre>
      </div>
      <hr />
      <h4>Additional flags</h4>
      <div className="">
        <p>
          You can use the <code>--print-tree</code> flag to print the CLI text representation of the resulting tree
          without threats:
        </p>
        <pre>
          <code>./code_genius analyze [file] --print-tree</code>
        </pre>
        <p>
          If you don&apos;t want to send the results to the platform, you can use the <code>--silent</code> flag:
        </p>
        <pre>
          <code>./code_genius analyze [file] --silent</code>
        </pre>
        <p>
          You can also specify the verbosity level of the output using the <code>--verbose</code> flag (default is 1):
        </p>
        <pre>
          <code>./code_genius analyze [file] --verbose 0/1/2</code>
        </pre>
        <ul>
          <li>Level 0: minimal output (only paths to external entities/data stores)</li>
          <li>Level 1: moderate output (excludes non-affecting variable declarations)</li>
          <li>Level 2: detailed output (show everything analyzer can get)</li>
        </ul>
      </div>
    </div>
  );
};

export default CodeGeniusPage;
