import classNames from 'classnames';
import type { FC } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { ArrowLeft, ChevronRight } from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { SKELETON_COLORS } from '../../../global/constants';
import type { EmitUpdate } from '../../../hooks/useSocket';
import { UpdateTypes } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import { THREATS_REGISTER_ROUTE } from '../../../store/constants/route-constants';
import type { IMitigation } from '../../../store/models/drawn';
import type { Dispatch } from '../../../store/store';
import UiButton from '../../common/UIButton/UiButton';
import UiSearch from '../../common/UiSearch/UiSearch';
import styles from './index.module.css';

type MitigationExplorerProps = {
  emitUpdate?: EmitUpdate;
  autoHeight?: boolean;
};

const MitigationExplorer: FC<MitigationExplorerProps> = ({ emitUpdate, autoHeight = false }) => {
  const params = useParams();

  const { threatsId, componentId } = params;
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const component = useTypedSelector(({ drawn }) => drawn?.component);
  const threat = useTypedSelector((state) => state.drawn?.threat);
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [selectedMitigation, setSelectedMitigation] = useState<any>({});
  const isAlreadyExistMitigation = useTypedSelector((state) => {
    return !!state.drawn?.threat?.mitigations?.find((m: any) => m.title === selectedMitigation?.title);
  });
  const [canAdd, setCanAdd] = useState(true);

  useEffect(() => {
    dispatch.mitigations.getCategoryList();
  }, []);

  useEffect(() => {
    if (!threat?.id && threatsId) {
      dispatch.drawn.getThreat(threatsId);
    }
  }, [threat, threatsId]);

  const { categoryList } = useTypedSelector((state) => state.mitigations);

  const handleEmitAfterUpdate = () => {
    if (threatsId && emitUpdate) {
      emitUpdate(UpdateTypes.THREAT, threatsId, true);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleCategoryClick = (categoryTitle: string) => {
    setSelectedMitigation(null);
    setSelectedCategory(categoryTitle);
  };

  const scrollToBottom = () => {
    if (!pathname.includes(`${THREATS_REGISTER_ROUTE}/`)) return;

    const container = document.querySelector('#threat-register-rightside');

    if (container) {
      setTimeout(() => {
        container.scroll({ top: container.scrollHeight, behavior: 'smooth' });
      }, 200);
    }
  };

  const handleMitigationClick = async (mitigation: IMitigation) => {
    const mitigationDetail = await dispatch.mitigations.getMitigationInfo(mitigation.id!);
    setSelectedMitigation(mitigationDetail);
    scrollToBottom();
  };

  const handleAddMitigation = async () => {
    if (canAdd) {
      setCanAdd(false);
      await dispatch.mitigations.addMitigationToThreat({
        threatId: threatsId,
        mitigationId: selectedMitigation.id,
        componentId,
      });

      if (threatsId) await dispatch.drawn.getThreat(threatsId);

      setSelectedMitigation(null);
      setCanAdd(true);
      handleEmitAfterUpdate();
      await dispatch.threatModel.updateCurrentThreatModel();
    }
  };

  const handleMitigationSelect = (mitigation: any) => {
    setSelectedMitigation(mitigation);
    scrollToBottom();
  };

  const renderTitle = () => {
    if (pathname.includes(`${THREATS_REGISTER_ROUTE}/`)) return 'Back';

    return `Back to ${component?.title || ''}`;
  };

  return (
    <div style={{ margin: '10px' }}>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="cursor-pointer" onClick={handleBack}>
          <ArrowLeft fontSize={16} className="me-2 text-secondary" />
          <span className="fs-12 fw-bold text-secondary">{renderTitle()}</span>
        </div>
      </div>

      <div
        style={{
          position: 'relative',
          height: '30px',
          marginBottom: '6px',
        }}
      >
        <UiSearch placeholder="Search Mitigation" onOptionSelect={handleMitigationSelect} isMitigation />
      </div>

      <div className={styles.content} style={{ height: autoHeight ? 'auto' : '85vh' }}>
        {categoryList && (
          <ul className={classNames('border rounded list-unstyled', styles.threatsList)}>
            {categoryList.map((category) => (
              <Fragment key={category.title}>
                <li
                  className={classNames(styles.threatsListItem, {
                    [styles.threatsListItemActive]: selectedCategory === category.title,
                  })}
                  onClick={() => handleCategoryClick(category.title)}
                >
                  {category.title}
                  <ChevronRight fontSize={16} />
                </li>
                {selectedCategory === category.title
                  ? category?.mitigations?.map((mitigation: any) => (
                      <li
                        className={classNames(styles.threat__item, {
                          [styles.threatsListSubItemActive]: selectedMitigation?.id === mitigation.id,
                        })}
                        key={mitigation.id}
                        onClick={() => handleMitigationClick(mitigation)}
                      >
                        {mitigation.title}
                      </li>
                    ))
                  : null}
              </Fragment>
            ))}
          </ul>
        )}

        {!categoryList && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <div style={{ width: '100%' }}>
              <Skeleton
                style={{ lineHeight: '25px' }}
                width="100%"
                borderRadius={4}
                height={30}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
            </div>
            <div style={{ width: '100%' }}>
              <Skeleton
                style={{ lineHeight: '25px' }}
                width="100%"
                borderRadius={4}
                height={30}
                baseColor={SKELETON_COLORS.BASE}
                highlightColor={SKELETON_COLORS.HIGHLIGHT}
              />
            </div>
          </div>
        )}

        {selectedMitigation?.id && (
          <div className={styles.threatsDetail}>
            <h6>{selectedMitigation.title}</h6>
            <p>{selectedMitigation.definition}</p>
            <div className={styles.threatsDetailLink}>
              {selectedMitigation?.info?.map((link: any) => (
                <a key={link.url} href={link.url} target="_blank" rel="noreferrer">
                  {link.title}
                </a>
              ))}
            </div>
            <div className="w-100 text-center mb-2">
              {isAlreadyExistMitigation ? (
                <div className="rounded border border-1 border-success text-success fs-12 p-1 w-75 m-auto">
                  This mitigation already exists in element
                </div>
              ) : (
                <UiButton onClick={handleAddMitigation} disabled={!canAdd}>
                  Add mitigation
                </UiButton>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MitigationExplorer;
