import type { FC, PropsWithChildren } from 'react';
import React from 'react';

import classNames from 'classnames';
import styles from './UiButton.module.css';

type UIButtonProps = PropsWithChildren & {
  type?: 'primary' | 'default' | 'transparent' | 'dark' | 'accent' | 'danger';
  disabled?: boolean;
  className?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
} & React.HTMLProps<HTMLButtonElement>;

const UiButton: FC<UIButtonProps> = ({
  children,
  type = 'dark',
  disabled = false,
  onClick,
  className,
  htmlType = 'button',
  fullWidth = false,
  ...props
}) => {
  const buttonClasses = classNames({
    [`${styles.uiBtnDark}`]: type === 'dark',
    [`${styles.uiBtnTransparent}`]: type === 'transparent',
    [`${styles.uiBtnGray}`]: type === 'default',
    [`${styles.uiBtn}`]: type === 'primary',
    [`${styles.uiBtnAccent}`]: type === 'accent',
    [`${styles.uiBtnDanger}`]: type === 'danger',
  });

  return (
    <button
      style={{ width: `${fullWidth ? '100%' : 'auto'}` }}
      disabled={disabled}
      className={`${className} ${styles.common} ${buttonClasses}`}
      onClick={onClick}
      type={htmlType}
      {...props}
    >
      {children}
    </button>
  );
};

export default UiButton;
