import { debounce } from '@zxcvbn-ts/core';
import { ArrowBarRight } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { Offcanvas } from 'reactstrap';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';
import AutocompleteDropdown from '../common/AutoCompletedDropDown';

type RightBarNestedProjectProps = {
  visible: boolean;
  setVisible: (isVisible: boolean) => void;
  setSelectedProject: (id: string) => void;
  button: JSX.Element;
};

const RightBarNestedProject = ({
  visible = false,
  setVisible,
  setSelectedProject,
  button,
}: RightBarNestedProjectProps): JSX.Element => {
  const dispatch = useDispatch<Dispatch>();

  const isLoading = useTypedSelector((state) => state.project.isLoading);
  const searchProjects = useTypedSelector((state) => state.project.searchProjects);

  const handleSearchChange = debounce((title: string) => {
    dispatch.project.searchProjectByTitle(title);
  }, 300);

  return (
    <Offcanvas direction="end" isOpen={visible} toggle={() => setVisible(false)}>
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          padding: '10px',
        }}
      >
        <form action="">
          <div className="mb-2">
            <div className="d-flex justify-content-between mb-2">
              <span style={{ fontSize: '12px', fontWeight: 500 }}>Choose a collection</span>
              <ArrowBarRight
                fontSize={16}
                color="#4285F4"
                className="cursor-pointer"
                onClick={() => setVisible(false)}
              />
            </div>
            <AutocompleteDropdown
              onInputChange={handleSearchChange}
              onItemClick={setSelectedProject}
              isLoading={isLoading === 'search'}
              options={searchProjects}
            />
          </div>

          <div className="d-flex justify-content-end">{button}</div>
        </form>
      </div>
    </Offcanvas>
  );
};

export default RightBarNestedProject;
