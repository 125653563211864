import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Spinner } from 'reactstrap';

import type { ThreatModel } from '../../../global/types';
import type { Dispatch } from '../../../store/store';
import styles from './index.module.css';

type IDeleteRepresentationModalProps = {
  currentThreatModel?: ThreatModel | null;
  deleteRepresentationId: string;
  currentRepresentationId?: string | null;
  setDeleteRepresentationId: (value: string) => void;
};

export const DeleteRepresentationModal: FC<IDeleteRepresentationModalProps> = ({
  currentThreatModel,
  deleteRepresentationId,
  currentRepresentationId,
  setDeleteRepresentationId,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleDeleteRepresentation = async () => {
    if (deleteRepresentationId) {
      setLoading(true);
      const isOk = await dispatch.representation.deleteRepresentation(deleteRepresentationId);

      if (deleteRepresentationId === currentRepresentationId) {
        const nextId = currentThreatModel?.representations.filter((i) => i.id !== deleteRepresentationId)?.at(0)?.id;

        if (isOk && nextId) {
          navigate(`/collections/${params.id}/d/${nextId}`);
        }
      } else if (currentThreatModel) dispatch.threatModel.getThreatModel(currentThreatModel.id);

      setLoading(false);
    }

    setDeleteRepresentationId('');
  };

  const getDeleteRepresentationTitle = () => {
    let index = 0;
    const representation = currentThreatModel?.representations?.find((i, idx) => {
      if (i.id === deleteRepresentationId) {
        index = idx;

        return true;
      }
    });

    return representation?.title || `Canvas ${index + 1}`;
  };

  const topValue = `calc(100% + 65px + ${(currentThreatModel?.representations?.length || 0) * 34}px)`;

  return (
    <div
      className={classNames(styles.deleteRepresentationModal, 'shadow rounded-1')}
      style={{ top: topValue }}
      id="representationDropdown"
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          setDeleteRepresentationId('');
        }}
      >
        <>
          <p>Do you want to delete {getDeleteRepresentationTitle()}?</p>
          <button
            id="representationDropdown"
            className="btn btn-sm btn-danger me-2"
            onClick={handleDeleteRepresentation}
          >
            {loading ? <Spinner size="sm" /> : 'Delete'}
          </button>
          <button
            id="representationDropdown"
            className="fs-12 btn btn-sm btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDeleteRepresentationId('');
            }}
          >
            Cancel
          </button>
        </>
      </OutsideClickHandler>
    </div>
  );
};
