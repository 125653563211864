import { createModel } from '@rematch/core';
import type { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import type { PaginationParams, User } from '../../global/types';
import { parseJwt } from '../../helpers/user';
import { CLI_TOKENS, USER_INVITE_URL, USER_ONBOARDING, USER_RE_INVITE_URL, USER_URL } from '../constants/api-constants';
import { DEFAULT_USER_PAGINATION_PARAMS } from '../constants/user-constants';
import http from '../http/http-common';
import type { RootModel } from './index';

export enum ROLE {
  USER = 'user',
  ADMIN = 'admin',
  SUPER_ADMIN = 'super_admin',
}

export type IUser = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  role: ROLE;
  userHash?: string;
  isOnboardingCompleted?: boolean;
  avatar?: string;
  productFruitsHash?: string;
};

interface IRemovedUser extends IUser {
  projectsCount: number;
  threatModelsCount: number;
}

type UserState = {
  current: IUser | null;
  users: User[] | null;
  query: PaginationParams;
  removedUserInfo: IRemovedUser;
  dropdownUsers: User[];
  apiAccess: boolean | null;
  isBetaUser: boolean;
  pricingPageModalVisible: boolean;
};

export const user = createModel<RootModel>()({
  state: {
    current: null,
    users: null,
    query: DEFAULT_USER_PAGINATION_PARAMS,
    removedUserInfo: {} as any,
    dropdownUsers: [],
    apiAccess: null,
    isBetaUser: false,
    pricingPageModalVisible: false,
  } as UserState,
  reducers: {
    setUsers(state, users) {
      return {
        ...state,
        users,
      };
    },
    setCurrent(state, user) {
      return {
        ...state,
        current: user,
      };
    },
    insertUser(state, user) {
      return {
        ...state,
        users: [...(state.users || []), user],
      };
    },
    updateUserInList(state, user) {
      return {
        ...state,
        users:
          state.users?.map((userFromList) => {
            return user.id === userFromList.id ? user : userFromList;
          }) || null,
      };
    },
    setQuery(state, query) {
      return { ...state, query };
    },
    setRemovedUserInfo(state, removedUserInfo) {
      return { ...state, removedUserInfo };
    },
    setDropdownUsers(state, dropdownUsers) {
      return { ...state, dropdownUsers };
    },
    setApiAccess(state, apiAccess) {
      return { ...state, apiAccess };
    },
    setPricingPageModalVisible(state, pricingPageModalVisible) {
      return { ...state, pricingPageModalVisible };
    },
    resetUserState(state) {
      return {
        ...state,
        current: null,
        users: null,
        query: DEFAULT_USER_PAGINATION_PARAMS,
        removedUserInfo: {} as any,
        dropdownUsers: [],
        apiAccess: null,
        isBetaUser: false,
      };
    },
  },
  effects: (dispatch) => {
    return {
      async getUsers({ forDropdown, needAvatar = true }, state) {
        try {
          const { limit, page, sort, order } = state.user.query;
          const result = await http(
            `${USER_URL}?limit=${limit}&page=${page}&sort=${sort}&order=${order}${
              needAvatar ? '&needAvatar=true' : ''
            }`,
          );
          const responseText = result.request?.responseText;
          const response = responseText?.length ? JSON.parse(responseText) : null;

          if (result.status === 200) {
            if (forDropdown) dispatch.user.setDropdownUsers(result.data.items);
            else dispatch.user.setUsers(result.data.items);
          } else {
            toast.error(response?.message?.toString());
          }

          dispatch.user.setQuery({
            ...state.user.query,
            count: result.data?.count || 0,
          });
        } catch (e: any) {
          console.error(e.message);
        }
      },
      async getUsersByQuery(query, state) {
        try {
          const { limit, page, sort, order, forDropdown, needAvatar } = query;
          const nextPage = limit * page;
          const result = await http(
            `${USER_URL}?limit=${limit}&page=${nextPage}&sort=${sort}&order=${order}${
              needAvatar ? '&needAvatar=true' : ''
            }`,
          );

          if (result.status === 200) {
            if (result.data?.items) {
              const data = [...(state.user?.users || []), ...result.data.items];

              if (forDropdown) dispatch.user.setDropdownUsers(data);
              else dispatch.user.setUsers(data);
            }
          }

          dispatch.user.setQuery({
            ...query,
            count: result?.data?.count || 0,
          });
        } catch (error: any) {
          console.error(error.message);
        }
      },
      async searchUsers(query) {
        try {
          const { field, value, forDropdown } = query;
          const result = await http(`${USER_URL}/search?field=${field}&value=${value}&needAvatar=true`);

          if (result.status === 200 && result.data) {
            if (forDropdown) dispatch.user.setDropdownUsers(result.data || []);
            else dispatch.user.setUsers(result.data || []);
          }
        } catch (error: any) {
          console.error(error.message);
        }
      },
      async getUserById(id) {
        const response = await http(`${USER_URL}/${id}`);
        dispatch.user.setCurrent(response.data);
      },

      async getCurrentUser() {
        try {
          const response = await http(`${USER_URL}/me`);
          await dispatch.tiers.getCustomerLimits();

          if (response.status === 200) {
            dispatch.user.setCurrent({
              ...response.data,
            });

            return response.data;
          }
        } catch (error: any) {
          toast.warning(error?.message || '');
        }
      },

      async createUser(data: { email: string; firstName: string; lastName: string; role?: string }) {
        try {
          const result = await http.post(`${USER_URL}/`, data);

          if (result.status === 201) {
            toast.success('User created successfully!');
            dispatch.user.insertUser(result.data);
          }

          return result.data;
        } catch (error: any) {
          toast.warning(error);
        }
      },

      async inviteUser(data: { email: string; firstName: string; lastName: string; role?: string }) {
        try {
          const result = await http.post(`${USER_INVITE_URL}`, data);

          if (result.status === 201) {
            toast.success('User invited successfully!');
          } else {
            const responseText = result.request?.responseText;
            const response = responseText?.length ? JSON.parse(responseText) : null;
            toast.error(response?.message);
          }

          return result.status;
        } catch (error: any) {
          toast.error(error);
        }
      },

      async reInviteUser(data: { email: string; firstName: string; lastName: string; role?: string }) {
        try {
          const result = await http.post(`${USER_RE_INVITE_URL}`, data);

          if (result.status === 201) {
            dispatch.user.updateUserInList(result.data);
            toast.success('User re-invited successfully!');
          }

          return result.status;
        } catch (error: any) {
          toast.error(error);
        }
      },

      async removeUser({ deletingUser, newUserId }) {
        try {
          const result: any = await http.delete(`${USER_URL}/${deletingUser}?new-user-id=${newUserId}`);

          if (result.status === 200) {
            toast.success('User deleted successfully!');
          }

          if (result?.response?.status === 400) {
            toast.error(result?.response?.data?.message.toString() || 'Error deleting user!');
          }

          return result.status;
        } catch (error: any) {
          toast.error(error);
        }
      },

      async registerCLIToken(token: string, state) {
        try {
          const { accessToken } = state.auth;

          if (accessToken) {
            const user = parseJwt(accessToken);
            const data = {
              token,
              userEmail: user.email,
            };
            const result: any = await http.post(CLI_TOKENS, data);

            if (result?.response?.status === 400) {
              toast.error(result?.response?.data?.message.toString() || 'Error sending token!');
            }

            if (result.status === 201) {
              toast.success('CLI Threat Model token created successfully!');

              return result.data;
            }

            return result.status === 201;
          }
        } catch (error: any) {
          toast.warning(error);
        }
      },

      async updateUserInfo(user, state) {
        try {
          const result = await http.patch(`${USER_URL}`, {
            firstName: user.firstName,
            lastName: user.lastName,
          });

          if (result.status === 200) {
            toast.success('User data successfully updated!');
            dispatch.user.setCurrent({
              ...state.user.current,
              ...result.data,
            });
            dispatch.user.getUsers({});
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async changePassword({ currentPassword, newPassword }) {
        try {
          const result: any = await http.post(`${USER_URL}/change-password`, {
            currentPassword,
            newPassword,
          });

          if (result.status === 201) {
            toast.success('Password successfully changed!');

            return true;
          }

          if (result?.response?.status === 400) {
            toast.error(result?.response?.data?.message.toString() || 'Password not changed!');
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async changeEmail(email) {
        try {
          const result: any = await http.post(`${USER_URL}/change-email`, {
            email,
          });

          if (result.status === 201) {
            return result.status === 201;
          }

          if (result?.response?.status === 400) {
            toast.error(result?.response?.data?.message.toString() || 'Email not changed!');
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async verifyEmail({ code, email }, state) {
        try {
          const result: any = await http.post(`${USER_URL}/verify-email`, {
            code,
            email,
          });

          if (result.status === 201) {
            toast.success('Email successfully changed!');
            dispatch.user.setCurrent({
              ...state.user.current,
              email,
            });

            return result.status === 201;
          }

          if (result?.response?.status === 400) {
            toast.error(result?.response?.data?.message.toString() || 'Email not changed!');
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async changeRole({ id, role }) {
        try {
          const result: any = await http.post(`${USER_URL}/change-role`, {
            id,
            role,
          });

          if (result.status === 201) {
            toast.success('Role successfully changed!');
            await dispatch.user.getUsers({});

            return result.status === 201;
          }

          if (result?.response?.status === 400) {
            toast.error(result?.response?.data?.message.toString() || 'Role not changed!');
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async sendOnboardingData(data) {
        try {
          await http.post(USER_ONBOARDING, data);
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async getRemovedUserInfo(id) {
        try {
          const result: any = await http(`${USER_URL}/workspaces/${id}`);

          if (result.status === 200) dispatch.user.setRemovedUserInfo(result.data);

          if (result?.response?.status === 400) {
            toast.error(result?.response?.data?.message.toString());
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async uploadUserAvatar(file) {
        try {
          const formData = new FormData();
          formData.append('file', file);
          const response: any = await http.post(`${USER_URL}/upload-avatar`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (response.status === 201) {
            toast.success('User avatar updated!');
            await dispatch.user.getCurrentUser();
          }

          if (response?.response?.status === 400) {
            toast.error(response?.response?.data?.message.toString() || 'Avatar not uploaded!');
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async checkApiAccess() {
        try {
          dispatch.user.setApiAccess(true);
          const response = await http('api-access');

          if (response.status === 200) {
            dispatch.user.setApiAccess(response.data?.isAccessEnabled);
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async enableApiAccess() {
        try {
          const response = await http.post('api-access');

          if (response.status === 201) {
            dispatch.user.setApiAccess(true);
            toast.success('API Access successfully enabled!');

            return response.data;
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async regenerateApiAccess() {
        try {
          const response = await http.post('api-access/renew');

          if (response.status === 201) {
            dispatch.user.setApiAccess(true);
            toast.success('API Access successfully regenerated!');

            return response.data;
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async deleteApiAccess() {
        try {
          const response = await http.delete('api-access');

          if (response.status === 200) {
            dispatch.user.setApiAccess(false);
            toast.success('API Access successfully deleted!');
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async checkSamlConfigured() {
        try {
          const response = await http('/saml/status');

          if (response.status === 200) {
            return !!response.data?.isEnabled;
          }

          const responseText = response.request?.responseText;
          const result = responseText?.length ? JSON.parse(responseText) : null;
          toast.warning(result?.message?.toString());
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async getSamlConfig() {
        try {
          const response = await http('/saml');

          if (response.status === 200) {
            return response.data;
          }

          const responseText = response.request?.responseText;
          const result = responseText?.length ? JSON.parse(responseText) : null;
          toast.warning(result?.message?.toString());
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async saveSamlConfig({ data, isConfigured }) {
        try {
          const formData = new FormData();
          formData.append('idpSignOut', data.idpSignOut);
          formData.append('encryptedResponses', data.encryptedResponses);
          formData.append('identifiers', JSON.stringify(data.identifiers));
          formData.append('attributeMapping', JSON.stringify(data.attributeMapping));

          if (data.metadataUrl) {
            formData.append('metadataUrl', data.metadataUrl);
          } else {
            formData.append('file', data.metadataFile);
          }

          let response: AxiosResponse;

          if (isConfigured) {
            response = await http.put(`/saml`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });

            if (response.status === 200) {
              toast.success('Config updated!');

              return response.data;
            }
          } else {
            response = await http.post(`/saml`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });

            if (response.status === 201) {
              toast.success('Config saved!');

              return response.data;
            }
          }

          const responseText = response.request?.responseText;
          const result = responseText?.length ? JSON.parse(responseText) : null;
          toast.warning(result?.message?.toString());
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async deleteSamlIdp() {
        try {
          const response = await http.delete('/saml');

          if (response.status === 200) {
            toast.success('SAML IDP deleted!');

            return response.status === 200;
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async uploadUsersCsv(file) {
        try {
          const formData = new FormData();
          formData.append('file', file);
          const response: any = await http.post('/saml/import-users', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (response.status === 201) {
            toast.success('Users uploaded!');
            await dispatch.user.getCurrentUser();
          } else {
            const responseText = response.request?.responseText;
            const result = responseText?.length ? JSON.parse(responseText) : null;
            toast.warning(result?.message?.toString());
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async downloadSampleFileToImportUsers() {
        try {
          const response = await http('/saml/users-import-sample');

          if (response.status === 200) {
            return response.data;
          }

          const responseText = response.request?.responseText;
          const result = responseText?.length ? JSON.parse(responseText) : null;
          toast.warning(result?.message?.toString());
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async enableUser(id) {
        try {
          const result: any = await http.post(`${USER_URL}/enable/${id}`);

          if (result.status === 201) {
            toast.success('User enabled!');

            return result.status === 201;
          }

          if (result?.response?.status === 400) {
            toast.error(result?.response?.data?.message.toString());
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },

      async disableUser(id) {
        try {
          const result: any = await http.post(`${USER_URL}/disable/${id}`);

          if (result.status === 201) {
            toast.success('User disabled!');

            return result.status === 201;
          }

          if (result?.response?.status === 400) {
            toast.error(result?.response?.data?.message.toString());
          }
        } catch (error: any) {
          toast.warning(error.message);
        }
      },
    };
  },
});
