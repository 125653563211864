import classNames from 'classnames';
import type { FC } from 'react';
import styles from './SeatsCounter.module.css';

type Props = {
  value: number;
  onChange: (value: number) => void;
  disableReduction?: boolean;
};

const SeatsCounter: FC<Props> = ({ value, onChange, disableReduction }) => {
  return (
    <div className={styles.Container}>
      <span
        className={classNames(styles.ActionButton, {
          [styles.ActionButtonDisabled]: disableReduction,
        })}
        onClick={() => onChange(value === 1 ? 1 : value - 1)}
      >
        <button>-</button>
      </span>
      <input
        className={styles.Input}
        value={value}
        onChange={(e) => {
          onChange(Number(e.target.value.replace(/\D/g, '')) || 1);
        }}
      />
      <span className={styles.ActionButton} style={{ right: 0, left: 'auto' }} onClick={() => onChange(value + 1)}>
        <button>+</button>
      </span>
    </div>
  );
};

export default SeatsCounter;
