import type { FC } from 'react';
import { useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { CHARGEBEE_PLAN_IDS, SING_UP_PAGE_PAYMENT_URL_PARAMS } from '../../../global/constants';
import { useInitChargebee } from '../../../hooks/useInitChargebee';
import type { Dispatch } from '../../../store/store';

const ChargebeeCheckout: FC = () => {
  const { chargebeeLoaded, reregisterChargebee } = useInitChargebee();
  const dispatch = useDispatch<Dispatch>();
  const targetButtonRef = useRef<HTMLAnchorElement | null>(null);
  const data = JSON.parse(localStorage.getItem(SING_UP_PAGE_PAYMENT_URL_PARAMS) || 'false');

  useEffect(() => {
    reregisterChargebee();

    if (chargebeeLoaded && targetButtonRef.current) {
      targetButtonRef?.current?.click();
    }
  }, [chargebeeLoaded, targetButtonRef.current]);

  let plan = CHARGEBEE_PLAN_IDS.ANNUALLY;

  if (data.plan === 'yearly') plan = CHARGEBEE_PLAN_IDS.ANNUALLY;

  if (data.plan === 'monthly') plan = CHARGEBEE_PLAN_IDS.MONTHLY;

  const quantity = +(data.users || 1);

  return (
    <a
      ref={targetButtonRef}
      onClick={(e) => e.preventDefault()}
      data-cb-type="checkout"
      data-cb-item-0={plan}
      data-cb-item-0-quantity={quantity + (plan || '')}
    />
  );
};

export default ChargebeeCheckout;
