import classNames from 'classnames';
import { MiniMap } from 'reactflow';

import { NODES } from '../../../global/constants';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import styles from './index.module.css';

export const CustomMinMap = () => {
  const { nodesSharedState } = useTypedSelector((state) => state.diagram);

  return (
    <div className={classNames('rounded-1 bg-white', styles.minMap)}>
      <MiniMap
        nodeStrokeWidth={1}
        pannable
        zoomable
        ariaLabel={null}
        offsetScale={1}
        maskStrokeColor="black"
        nodeStrokeColor="black"
        inversePan
        nodeComponent={({ x, y, id, width, height }: any) => {
          const node = nodesSharedState?.get(id);
          switch (node?.type) {
            case NODES.PROCESS_NODE:
              return (
                <circle
                  cx={x}
                  cy={y}
                  width={width}
                  height={height}
                  r="50"
                  fill={node?.data?.color || 'white'}
                  stroke={node?.data?.borderColor || 'black'}
                  strokeWidth={node?.data?.borderWidth || 1}
                />
              );
            case NODES.TRUST_BOUNDARY_NODE:
              return (
                <rect
                  x={x}
                  y={y}
                  width={width}
                  height={height}
                  fill={node?.data?.color || '#ffffff60'}
                  stroke={node?.data?.borderColor || 'black'}
                  strokeWidth={node?.data?.borderWidth || 1}
                  strokeDasharray="0 20 0"
                />
              );
            default:
              return (
                <rect
                  x={x}
                  y={y}
                  width={width}
                  height={height}
                  fill={node?.data?.color || 'white'}
                  stroke={node?.data?.borderColor || 'black'}
                  strokeWidth={node?.data?.borderWidth || 1}
                />
              );
          }
        }}
        nodeColor={(node: any) => {
          if (node?.data?.color) return node.color;

          return '#F5F9FECC';
        }}
      />
    </div>
  );
};
