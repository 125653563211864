import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Plans from '../../components/Plans';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import type { Dispatch } from '../../store/store';

const PlansPage = () => {
  const isAuthenticated = useTypedSelector((state) => state.auth.isAuthenticated);
  const { plans, chosenPlan } = useTypedSelector((state) => state.plan);
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    if (isAuthenticated) dispatch.plan.getPlans();
    // else navigate(SIGN_IN_ROUTE);
  }, []);

  return (
    <div className="mt-12 shadow-xl rounded-lg">
      <div className="flex flex-col items-center mb-12">
        <h2 className="text-6xl text-primary-white font-bold">Pricing</h2>
      </div>
      <Plans plans={plans} chosenPlan={chosenPlan} />
    </div>
  );
};

export default PlansPage;
