import { useFormik } from 'formik';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { ArrowBarRight, ArrowLeft } from 'react-bootstrap-icons';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import * as Yup from 'yup';
import { getId } from '../../helpers/user';
import { useSocket } from '../../hooks/useSocket';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import { DEFAULT_USER_PAGINATION_PARAMS } from '../../store/constants/user-constants';
import type { Dispatch } from '../../store/store';
import UiButton from '../common/UIButton/UiButton';
import UiDropDown from '../common/UiDropDown/UiDropDown';
import UiInput from '../common/UiInput/UiInput';
import User from '../User/User';

type RightBarProps = {
  visible: boolean;
  setVisible: (isVisible: boolean) => void;
  isTransferring: boolean;
  selectedProject: any;
  // @ts-ignore
  setSelectedProject: Dispatch<any>;
  selectedForTransferModel: any;
  selectedModelProject: any;
  isMyModel?: boolean;
};

const RightBar: FC<RightBarProps> = ({
  visible = false,
  setVisible,
  isTransferring = false,
  selectedProject,
  setSelectedProject,
  selectedForTransferModel,
  selectedModelProject,
  isMyModel = false,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const [options, setOptions] = useState<{ id: string; title: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const { emitGlobalUpdate } = useSocket();
  const { id: projectId } = params;

  const { projects, current: currentProject, query } = useTypedSelector((state) => state.project);
  const { current } = useTypedSelector((state) => state.user);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const canFetchMoreProjects = query.page * query.limit < query.count;

  useEffect(() => {
    if (projects) {
      setOptions([
        { id: 'create_new', title: 'Create a new collection' },
        ...projects.filter((p) => p?.id !== selectedModelProject?.id),
      ]);
    }
  }, [projects, selectedModelProject]);

  const handleEmitAfterUpdate = (targetProjectId?: string) => {
    if (projectId) {
      emitGlobalUpdate({
        projects: targetProjectId ? [projectId, targetProjectId] : [projectId],
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      project: 'Create New collection',
      title: '',
      description: '',
    },
    validationSchema: Yup.object({
      project: Yup.string().required('Required'),
      title: Yup.string()
        .min(1, 'Minimum 1 characters required')
        .max(140, 'Maximum 140 characters are allowed')
        .required('Required'),
      description: Yup.string().max(500, 'Maximum 500 characters are allowed'),
    }),
    onSubmit: async (values) => {
      const createdCollection = await dispatch.project.createProject({
        id: getId(),
        title: values.title,
        description: values.description,
      });

      if (createdCollection) {
        if (!isTransferring) {
          const createdThreatModel = await dispatch.threatModel.createThreatModel({
            projectId: createdCollection.id,
            title: 'Draft threat model',
          });

          if (createdThreatModel?.representationId) {
            dispatch.drawn.setIsFirstOpen(true);
            dispatch.app.setSidebarVisible(false);
            navigate(`/collections/${createdCollection.id}/d/${createdThreatModel.representationId}`);
            handleEmitAfterUpdate();
          }
        } else if (selectedForTransferModel) {
          selectedForTransferModel.project = createdCollection;
          await dispatch.threatModel.updateThreatModel(selectedForTransferModel);
          await dispatch.threatModel.getThreatModelsByQuery({
            ...DEFAULT_USER_PAGINATION_PARAMS,
            projectId: currentProject?.id || projectId,
            status: selectedForTransferModel.status,
            page: 0,
          });
          setVisible(false);
          toast.success('Threat Model transferred successfully!');
          handleEmitAfterUpdate();
        }
      }

      values.title = '';
      values.description = '';
    },
  });

  const handleThreatModelCreate = async () => {
    if (selectedProject?.id) {
      if (!isTransferring) {
        const createdThreatModel = await dispatch.threatModel.createThreatModel({
          projectId: selectedProject.id,
          title: 'Draft threat model',
        });

        if (createdThreatModel?.representationId) {
          dispatch.drawn.setIsFirstOpen(true);
          dispatch.app.setSidebarVisible(false);
          handleEmitAfterUpdate();
          navigate(`/collections/${selectedProject.id}/d/${createdThreatModel.representationId}`);
        }
      } else if (selectedForTransferModel) {
        selectedForTransferModel.project = selectedProject;
        await dispatch.threatModel.updateThreatModel(selectedForTransferModel);
        setVisible(false);

        if (!isMyModel) {
          if (currentProject?.id) {
            await dispatch.project.getProjectById(currentProject?.id);
          }

          dispatch.threatModel.getThreatModelsByQuery({
            ...DEFAULT_USER_PAGINATION_PARAMS,
            projectId: currentProject?.id || projectId,
            status: selectedForTransferModel.status,
            page: 0,
          });
        } else {
          dispatch.threatModel.getThreatModelsByQuery({
            ...DEFAULT_USER_PAGINATION_PARAMS,
            status: selectedForTransferModel.status,
            page: 0,
          });
        }

        toast.success('Threat Model transferred successfully!');
        handleEmitAfterUpdate(selectedProject.id);
      }
    }
  };

  const handleCreateCollection = async () => {
    if (loading) return;

    setLoading(true);
    await formik.submitForm();
    setLoading(false);
  };

  const fetchMoreProjects = async () => {
    const nextPage = query.page + 1;

    if (nextPage * query.limit < query.count) {
      setLoadingProjects(true);
      await dispatch.project.getProjectsByQuery({
        ...query,
        page: nextPage,
      });
      setLoadingProjects(false);
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        background: 'white',
        top: 0,
        right: visible ? 0 : -420,
        width: '420px',
        height: '100vh',
        zIndex: '10000',
        borderLeft: '1px solid gray',
        transition: 'all 0.5s',
      }}
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          setVisible(false);
        }}
        disabled={!visible}
      >
        <div
          style={{
            padding: '10px',
            height: '100vh',
          }}
        >
          {selectedProject?.id !== 'create_new' && (
            <form action="">
              <div className="mb-2">
                <div className="d-flex justify-content-between mb-2">
                  <span style={{ fontSize: '12px', fontWeight: 500 }}>Choose a collection</span>
                  <ArrowBarRight
                    fontSize={16}
                    color="#4285F4"
                    className="cursor-pointer"
                    onClick={() => setVisible(false)}
                  />
                </div>
                <UiDropDown
                  value={selectedProject}
                  options={options}
                  onChange={(newValue: any) => setSelectedProject(newValue)}
                  defaultValueTitle="Select Collection"
                  infinityScroll={{
                    fetchMoreData: fetchMoreProjects,
                    canFetchMore: canFetchMoreProjects,
                    loading: loadingProjects,
                  }}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <User noHover user={isTransferring ? selectedForTransferModel?.owner : current} />
                <span style={{ fontSize: '10px' }}>Owner</span>
              </div>
              <div className="d-flex justify-content-end">
                <UiButton
                  disabled={
                    (isMyModel && isTransferring && selectedProject?.id === selectedForTransferModel?.project?.id) ||
                    selectedProject?.id === projectId
                  }
                  onClick={handleThreatModelCreate}
                >
                  {isTransferring ? 'Transfer' : 'Create'}
                </UiButton>
              </div>
            </form>
          )}
          {selectedProject?.id === 'create_new' && (
            <>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setSelectedProject(null);
                    formik.values.title = '';
                    formik.values.description = '';
                  }}
                >
                  <ArrowLeft fontSize={16} className="me-2 text-secondary" />
                  <span className="fs-12 fw-bold text-secondary">Back to Choose a collection</span>
                </div>
                <ArrowBarRight
                  fontSize={16}
                  color="#4285F4"
                  className="cursor-pointer"
                  onClick={() => setVisible(false)}
                />
              </div>
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: 500,
                  marginBottom: '10px',
                }}
              >
                Create a new collection
              </div>
              <div className="mb-2 fs-12">
                <UiInput
                  name="title"
                  label="*Collection Name"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorText={formik.errors.title}
                  showError={!!formik.errors.title}
                  placeholder="Add Collection name"
                />
              </div>

              <div className="mb-2 fs-12">
                <UiInput
                  type="textarea"
                  name="description"
                  label="Collection Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorText={formik.errors.description}
                  showError={!!formik.errors.description}
                  placeholder="Add description"
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <User noHover user={current && current} />
                <span style={{ fontSize: '10px' }}>Owner</span>
              </div>
              <div className="d-flex justify-content-end">
                <div style={{ width: 100 }}>
                  <UiButton fullWidth htmlType="submit" onClick={handleCreateCollection}>
                    {loading ? <Spinner size="sm" /> : 'Transfer'}
                  </UiButton>
                </div>
              </div>
            </>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default RightBar;
