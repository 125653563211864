import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { MITIGATION_STATUSES } from '../../../global/constants';
import type { IMitigation } from '../../../store/models/drawn';
import UiCheck from './UiCheck';
import styles from './UiCheckList.module.css';

type UiCheckListProps = {
  mitigation: IMitigation;
  onClick?: any;
  isLoading: boolean;
};

const UiCheckList: FC<UiCheckListProps> = ({ mitigation, onClick, isLoading }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [clickedStatus, setClickedStatus] = useState<string | null | undefined>(null);

  const handleClick = (mitigationStatus: string | null) => {
    setIsClicked(true);
    onClick(mitigation.id, mitigationStatus);
    setClickedStatus(mitigationStatus);
    setIsClicked(false);
  };

  useEffect(() => {
    if (!isLoading) {
      setClickedStatus(undefined);
    }
  }, [isLoading]);

  return (
    <div className={styles.row}>
      <UiCheck
        onClick={() => handleClick(MITIGATION_STATUSES.DONE)}
        isActive={!isClicked && mitigation.status === MITIGATION_STATUSES.DONE}
        status="done"
        isLoading={clickedStatus === MITIGATION_STATUSES.DONE && isLoading}
      />
      <UiCheck
        onClick={() => handleClick(MITIGATION_STATUSES.IN_FUTURE)}
        isActive={!isClicked && mitigation.status === MITIGATION_STATUSES.IN_FUTURE}
        status="will"
        isLoading={clickedStatus === MITIGATION_STATUSES.IN_FUTURE && isLoading}
      />
      <UiCheck
        onClick={() => handleClick(MITIGATION_STATUSES.NEVER)}
        isActive={!isClicked && mitigation.status === MITIGATION_STATUSES.NEVER}
        status="never"
        isLoading={clickedStatus === MITIGATION_STATUSES.NEVER && isLoading}
      />
      <UiCheck
        onClick={() => handleClick(null)}
        isActive={!isClicked && mitigation.status === null}
        status={null}
        isLoading={clickedStatus === null && isLoading}
      />
    </div>
  );
};

export default UiCheckList;
