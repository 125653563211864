import classNames from 'classnames';
import type { ChangeEvent, FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import styles from './index.module.css';

type RepresentationRangeProps = { handleEmitAfterUpdate: () => void };

let timer: any = null;

const RepresentationRange: FC<RepresentationRangeProps> = ({ handleEmitAfterUpdate }) => {
  const dispatch = useDispatch<Dispatch>();
  const threatModel = useTypedSelector((state) => state.threatModel.currentThreatModel);

  const onChangeTimeInvested = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    dispatch.threatModel.setCurrentThreatModel({
      ...threatModel,
      time_invested: +e.target.value,
    });

    clearTimeout(timer);
    timer = setTimeout(() => {
      if (threatModel) {
        dispatch.threatModel.updateThreatModel({
          ...threatModel,
          time_invested: +e.target.value,
        });
        handleEmitAfterUpdate();
      }
    }, 500);
  };

  const onQualityChange = (value: string) => {
    dispatch.threatModel.setCurrentThreatModel({
      ...threatModel,
      quality_of_model: +value,
    });

    clearTimeout(timer);
    timer = setTimeout(() => {
      if (threatModel) {
        dispatch.threatModel.updateThreatModel({
          ...threatModel,
          quality_of_model: +value,
        });
        handleEmitAfterUpdate();
      }
    }, 500);
  };

  const onRevisitChange = (value: string) => {
    dispatch.threatModel.setCurrentThreatModel({
      ...threatModel,
      when_to_revisit: +value,
    });

    clearTimeout(timer);
    timer = setTimeout(() => {
      if (threatModel) {
        dispatch.threatModel.updateThreatModel({
          ...threatModel,
          when_to_revisit: +value,
        });
        handleEmitAfterUpdate();
      }
    }, 500);
  };

  const formatHours = (value: number) => {
    return `${value} hour${value === 1 ? '' : 's'}`;
  };

  return (
    <div className={classNames('fs-12', styles.representationRange)}>
      <label htmlFor="customRange1" className="form-label">
        Time invested
      </label>
      <div>{formatHours(threatModel?.time_invested || 0)}</div>
      <input
        min={0}
        max={100}
        value={threatModel?.time_invested || 0}
        onChange={onChangeTimeInvested}
        type="range"
        className="custom-range form-range"
        id="customRange1"
      />

      <label htmlFor="customRange1" className="form-label">
        Quality of Model
      </label>
      <div className="d-flex align-items-center justify-content-between">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
          <div
            className={classNames('cursor-pointer text-center', styles.numberField, {
              [styles.active]: i === +(threatModel?.quality_of_model || 1),
            })}
            onClick={() => onQualityChange(i.toString())}
            key={i}
          >
            {i}
          </div>
        ))}
      </div>
      <input
        min={1}
        max={10}
        value={threatModel?.quality_of_model || 1}
        onChange={(e) => onQualityChange(e.target.value)}
        type="range"
        className="custom-range form-range"
        id="customRange1"
      />

      <label htmlFor="customRange1" className="form-label">
        When to revisit?
      </label>
      <div className="d-flex align-items-center justify-content-between">
        {['Monthly', 'Quarterly', 'Yearly'].map((i, idx) => (
          <div
            className={classNames('cursor-pointer text-center', styles.field, {
              [styles.active]: idx + 1 === +(threatModel?.when_to_revisit || 1),
            })}
            onClick={() => onRevisitChange((idx + 1).toString())}
            key={i}
          >
            {i}
          </div>
        ))}
      </div>
      <input
        min={1}
        max={3}
        value={threatModel?.when_to_revisit || 1}
        onChange={(e) => onRevisitChange(e.target.value)}
        type="range"
        className="custom-range form-range"
        id="customRange1"
      />
    </div>
  );
};

export default RepresentationRange;
