import classNames from 'classnames';
import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { Clipboard2Check, Plus } from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NODES, SKELETON_COLORS } from '../../../global/constants';
import { isReadMode } from '../../../helpers/isReadMode';
import type { EmitUpdate } from '../../../hooks/useSocket';
import { UpdateTypes } from '../../../hooks/useSocket';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import type { Dispatch } from '../../../store/store';
import UiInput from '../../common/UiInput/UiInput';
import Switch from '../../common/UISwitch';
import DrawnAttributes from '../DrawnAttributes';
import DrawnThreatItem from '../DrawnThreatItem';
import styles from './index.module.css';

let timer: any = null;
type ComponentFormProps = {
  emitUpdate?: EmitUpdate;
};

const ComponentForm: FC<ComponentFormProps> = ({ emitUpdate }) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { componentId, diagramId } = useParams();
  const [showAttributesSearch, setShowAttributesSearch] = useState(false);
  const { component, forceUpdateComponentId, visualRemoveComponentId, visible } = useTypedSelector(
    (state) => state.drawn,
  );
  const { nodesSharedState, edgesSharedState, highlightedThreats } = useTypedSelector((state) => state.diagram);
  const currentThreatModel = useTypedSelector((state) => state.threatModel.currentThreatModel);
  const interactionMode = useTypedSelector((state) => state.threatModel.interactionMode);

  const selectedNode = componentId && nodesSharedState?.get(componentId);
  const selectedEdge = componentId && edgesSharedState?.get(componentId);

  useEffect(() => {
    if (componentId && !component?.id) dispatch.drawn.getComponent({ id: componentId });

    return () => {
      if (timer) clearTimeout(timer);

      setShowAttributesSearch(false);
    };
  }, []);

  useEffect(() => {
    if (componentId && componentId !== component?.id) {
      dispatch.drawn.getComponent({ id: componentId });
    }
  }, [componentId]);

  useEffect(() => {
    if (componentId && forceUpdateComponentId && componentId === forceUpdateComponentId) {
      dispatch.drawn.getComponent({ id: componentId, setNull: false });
      dispatch.drawn.setForceUpdateComponentId(null);
    }
  }, [forceUpdateComponentId]);

  useEffect(() => {
    if (visualRemoveComponentId?.length && component?.id === visualRemoveComponentId) {
      if (selectedNode) {
        nodesSharedState && nodesSharedState.delete(visualRemoveComponentId);
      } else if (selectedEdge) {
        edgesSharedState && edgesSharedState.delete(visualRemoveComponentId);
      }

      if (visible) toast.error('Component deleted!');

      timer = setTimeout(() => {
        clearTimeout(timer);
        const { id, diagramId } = params;
        navigate(`/collections/${id}/d/${diagramId}`);
      }, 300);
      dispatch.drawn.setVisible(false);
      dispatch.drawn.setVisualRemoveComponentId(null);
    }
  }, [visualRemoveComponentId]);

  const handleEmitAfterUpdate = () => {
    if (diagramId && emitUpdate) {
      emitUpdate(UpdateTypes.COMPONENT, component.id, true);
    }

    if (currentThreatModel?.id && emitUpdate) {
      emitUpdate(UpdateTypes.REGISTER, currentThreatModel.id, true);
      emitUpdate(UpdateTypes.ELEMENT_REGISTER, currentThreatModel.id, true);
    }
  };

  const handleAttributeAdd = async () => {
    handleEmitAfterUpdate();
    await dispatch.threatModel.updateCurrentThreatModel();
  };

  const handleEmitAfterUpdateAttributeRemove = (attributeId: string) => {
    if (diagramId && emitUpdate && componentId) {
      emitUpdate(UpdateTypes.ATTRIBUTE_REMOVE, attributeId, true);
      emitUpdate(UpdateTypes.COMPONENT, componentId, true);
    }
  };

  const handleAttributeRemove = async (attributeId: string) => {
    handleEmitAfterUpdateAttributeRemove(attributeId);
    await dispatch.threatModel.updateCurrentThreatModel();
  };

  const onDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    event.persist();
    dispatch.drawn.setComponent({
      ...component,
      description: event.target.value,
    });

    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch.drawn.updateComponent({
        id: component.id,
        description: event.target.value,
        representationId: diagramId,
        title: component.title,
      });
      handleEmitAfterUpdate();
    }, 500);
  };

  const onTitleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    event.persist();
    dispatch.drawn.setComponent({
      ...component,
      title: event.target.value,
    });

    clearTimeout(timer);
    timer = setTimeout(() => {
      if (event.target.value.length > 1 && event.target.value.length < 64) {
        dispatch.drawn.updateComponent({
          id: component.id,
          description: component?.description,
          representationId: diagramId,
          title: event.target.value,
        });

        if (selectedNode) {
          selectedNode.data = {
            ...selectedNode.data,
            label: event.target.value,
          };
          nodesSharedState?.set(component.id, selectedNode);
        }

        if (selectedEdge) {
          selectedEdge.data = {
            ...selectedEdge.data,
            label: event.target.value,
          };
          edgesSharedState?.set(component.id, selectedEdge);
        }

        handleEmitAfterUpdate();
      }
    }, 500);
  };

  const onChangeEdgeLabelVisibility = (hiddenLabel: boolean) => {
    if (!selectedEdge) return;

    edgesSharedState?.set(selectedEdge.id, {
      ...selectedEdge,
      data: { ...selectedEdge.data, hiddenLabel },
    });
  };

  const getErrorText = () => {
    if (component?.title?.length > 64) return 'Max length is 64 characters';

    if (component?.title?.length < 1) return 'Component name required';

    return '';
  };

  const showAttributes = selectedNode && selectedNode?.type !== NODES.TRUST_BOUNDARY_NODE;

  return (
    <div
      style={{ margin: '10px' }}
      className={classNames({
        'non-interactive': isReadMode(interactionMode),
      })}
    >
      <div className="d-flex align-items-center justify-content-between mb-2">
        <UiInput
          name="title"
          value={component?.title || ''}
          onChange={onTitleChange}
          onFocus={() => dispatch.diagram.setCanCopy(false)}
          onBlur={() => dispatch.diagram.setCanCopy(true)}
          showError={component?.title?.length > 64 || component?.title?.length < 1}
          errorText={getErrorText()}
        />
        {!component?.id && (
          <div style={{ width: '100%' }}>
            <Skeleton
              style={{ lineHeight: '25px', width: '100%' }}
              width="100%"
              height={30}
              baseColor={SKELETON_COLORS.BASE}
              highlightColor={SKELETON_COLORS.HIGHLIGHT}
            />
          </div>
        )}
      </div>

      <div className={classNames('mb-2', styles.description)}>
        <label htmlFor="node-description" className="fs-12 pb-2 fw-bold">
          Description
        </label>
        <textarea
          id="node-description"
          className="w-100 border-1 rounded-2"
          placeholder="Add description..."
          value={component?.description || ''}
          onChange={onDescriptionChange}
          onFocus={() => dispatch.diagram.setCanCopy(false)}
          onBlur={() => dispatch.diagram.setCanCopy(true)}
        />
        {!component?.id && (
          <Skeleton
            style={{ lineHeight: '25px' }}
            width="100%"
            height={66}
            baseColor={SKELETON_COLORS.BASE}
            highlightColor={SKELETON_COLORS.HIGHLIGHT}
          />
        )}
      </div>

      {selectedEdge && (
        <div className="d-flex">
          <label htmlFor="dataflow-visibility" className="fs-12 fw-bold user-select-none me-2">
            Hide label
          </label>
          <Switch checked={!!selectedEdge?.data?.hiddenLabel} onChange={onChangeEdgeLabelVisibility} />
        </div>
      )}
      <hr />

      {(showAttributes || selectedEdge) && (
        <>
          <div className="d-flex align-items-center flex-wrap gap-2 mb-2">
            <div className="fs-12 fw-bold me-3">Attributes</div>
            <DrawnAttributes
              showSearch={showAttributesSearch}
              setShowSearch={setShowAttributesSearch}
              onAttributeAdd={handleAttributeAdd}
              onAttributeRemove={handleAttributeRemove}
            />
          </div>

          <div>
            <Link
              to="a"
              className={classNames(
                'fs-12 d-flex p-1 align-items-center gap-1 cursor-pointer mb-2 text-decoration-none',
                styles.explorerButton,
              )}
            >
              <Clipboard2Check fontSize={16} />
              Attribute Explorer
            </Link>
          </div>

          <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 mb-2 py-2">
            <span className="fs-12 fw-bold d-flex align-items-center" style={{ height: '30px' }}>
              Codex Threats
            </span>
            <div>
              <Link
                to="t"
                className={classNames(
                  'fs-12 d-flex p-1 align-items-center gap-1 cursor-pointer text-decoration-none',
                  styles.explorerButton,
                )}
              >
                <Clipboard2Check fontSize={16} />
                Threat Explorer
              </Link>
            </div>
          </div>

          <div className="fs-12" style={{ overflow: 'auto' }}>
            {!component?.threats?.filter((i) => !i.is_custom)?.length && (
              <div className="text-secondary">No threats</div>
            )}
            <ol className={classNames('ps-4', styles.threats)}>
              {component?.threats &&
                component?.threats
                  ?.filter((i) => !i.is_custom)
                  .map((threat) => (
                    <DrawnThreatItem
                      key={threat.id}
                      threat={threat}
                      highlighted={highlightedThreats.includes(threat.title)}
                      onThreatRemove={(isOk) => {
                        handleEmitAfterUpdate();

                        if (isOk && threat.id && emitUpdate) {
                          emitUpdate(UpdateTypes.THREAT_REMOVE, threat.id, true);
                        }

                        dispatch.threatModel.updateCurrentThreatModel();
                      }}
                    />
                  ))}
            </ol>
            {!component?.threats && (
              <div style={{ margin: '10px 10px' }}>
                <Skeleton
                  style={{ lineHeight: '25px', marginBottom: '5px' }}
                  width="100%"
                  height={35}
                  baseColor={SKELETON_COLORS.BASE}
                  highlightColor={SKELETON_COLORS.HIGHLIGHT}
                />
                <Skeleton
                  style={{ lineHeight: '25px' }}
                  width="100%"
                  height={35}
                  baseColor={SKELETON_COLORS.BASE}
                  highlightColor={SKELETON_COLORS.HIGHLIGHT}
                />
              </div>
            )}
          </div>

          <div className="mb-2 py-2">
            <span className="fs-12 fw-bold d-flex align-items-center" style={{ height: '30px' }}>
              User generated threats
            </span>
            {!component?.threats?.filter((i) => i.is_custom)?.length && (
              <div className="text-secondary fs-12 mt-2">No threats</div>
            )}
            <ol className={classNames('fs-12 my-2', styles.threats)}>
              {component?.threats &&
                component?.threats
                  ?.filter((i) => i.is_custom)
                  .map((threat) => (
                    <DrawnThreatItem
                      key={threat.id}
                      threat={threat}
                      onThreatRemove={(isOk) => {
                        handleEmitAfterUpdate();

                        if (isOk && threat.id && emitUpdate) {
                          emitUpdate(UpdateTypes.THREAT_REMOVE, threat.id, true);
                          dispatch.threatModel.updateCurrentThreatModel();
                        }
                      }}
                    />
                  ))}
            </ol>
            {!component?.threats && (
              <div style={{ margin: '10px 10px' }}>
                <Skeleton
                  style={{ lineHeight: '25px', marginBottom: '5px' }}
                  width="100%"
                  height={35}
                  baseColor={SKELETON_COLORS.BASE}
                  highlightColor={SKELETON_COLORS.HIGHLIGHT}
                />
                <Skeleton
                  style={{ lineHeight: '25px' }}
                  width="100%"
                  height={35}
                  baseColor={SKELETON_COLORS.BASE}
                  highlightColor={SKELETON_COLORS.HIGHLIGHT}
                />
              </div>
            )}
            <Link
              to="ut"
              className={classNames(
                'fs-12 d-flex p-1 align-items-center gap-1 cursor-pointer mb-2 text-decoration-none',
                styles.explorerButton,
              )}
            >
              <Plus fontSize={16} className="" />
              <span className="fs-12">Add a threat</span>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default ComponentForm;
