import type { Project } from '../global/types';

export type ProjectInfoData = {
  nestedProjects: Project[];
  query?: {
    page: number;
    count: number;
    limit: number;
  };
};

export type NestedProjectsParams = {
  parentId: string;
  project: Project;
  projectInfo: ProjectInfoData;
};

export const filterExistProjects = (projects: Project[] = [], newProjects: Project[] = []) => {
  const filteredNewProjects = newProjects.filter(
    (newProject) => !projects.some((project) => project.id === newProject.id),
  );

  return [...projects, ...filteredNewProjects];
};

export const setNestedProjects = ({ parentId, project, projectInfo }: NestedProjectsParams): Project => {
  if (project.id === parentId) {
    const nestedProjects = filterExistProjects(project.nestedProjects, projectInfo.nestedProjects);

    return {
      ...project,
      nestedProjects,
      sub_projects_amount: nestedProjects.length,
      query: {
        limit: projectInfo?.query?.limit || 25,
        count: projectInfo.query?.count || 0,
        page: projectInfo.query?.page || 0,
      },
    };
  }

  if (project.nestedProjects?.length) {
    return {
      ...project,
      nestedProjects: project.nestedProjects.map((item) => setNestedProjects({ parentId, project: item, projectInfo })),
    };
  }

  return project;
};

export const removeNestedProjects = (subProjectId: string, project: Project): Project | null => {
  if (project.id === subProjectId) {
    return null;
  }

  if (project.nestedProjects?.length) {
    const nestedProjects = project.nestedProjects
      .map((item) => removeNestedProjects(subProjectId, item))
      .filter(Boolean) as Project[];

    return {
      ...project,
      nestedProjects,
      sub_projects_amount: nestedProjects.length,
      query: undefined,
    };
  }

  return project;
};

export const setNestedProjectsColor = (projectId: string, color: string, project: Project): Project => {
  if (project.id === projectId) {
    return { ...project, color };
  }

  if (project.nestedProjects?.length) {
    return {
      ...project,
      nestedProjects: project.nestedProjects.map((item) => setNestedProjectsColor(projectId, color, item)),
    };
  }

  return project;
};
