import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import { X, XLg } from 'react-bootstrap-icons';
import { stringToBackgroundColor, stringToCommonColor } from '../../helpers/colors';
import UserPopUp from '../common/UserPopUp/UserPopUp';
import styles from './User.module.css';

type UserProp = {
  user: any;
  clearIcon?: boolean;
  active?: boolean;
  small?: boolean;
  showPopUpOnHover?: boolean;
  withDropDown?: boolean;
  noHover?: boolean;
};
const User: FC<UserProp> = ({
  user,
  clearIcon,
  active,
  small,
  showPopUpOnHover = false,
  withDropDown = false,
  noHover = false,
}) => {
  const [isUserPopUp, setIsUserPopUp] = useState(false);

  const handleMouseEnter = () => {
    setIsUserPopUp(true);
  };

  const handleMouseLeave = () => {
    setIsUserPopUp(false);
  };

  return (
    <div
      className={classNames(styles.user, {
        [styles.active]: active,
        [styles.small]: small,
        [styles.noHover]: noHover,
      })}
      style={{ width: `${withDropDown ? '165px' : 'auto'}` }}
      onMouseLeave={handleMouseLeave}
    >
      {user?.avatarUrl?.length ? (
        <img
          onMouseEnter={handleMouseEnter}
          className={styles.userAvatar}
          src={user?.avatarUrl}
          alt={`${user?.first_name} ${user?.last_name}`}
        />
      ) : (
        <span
          onMouseEnter={handleMouseEnter}
          className={styles.userAvatar}
          style={{
            background: stringToBackgroundColor(`${user?.first_name} ${user?.last_name}`),
            color: stringToCommonColor(`${user?.first_name} ${user?.last_name}`),
          }}
        >
          {user?.first_name?.[0]}
          {user?.last_name?.[0]}
        </span>
      )}
      <span
        className={classNames(styles.name, { [styles.smallName]: small })}
      >{`${user?.first_name} ${user?.last_name}`}</span>
      {clearIcon && <div className={styles.clearIcon}>{small ? <X /> : <XLg />}</div>}
      {isUserPopUp && showPopUpOnHover && (
        <UserPopUp fullName={`${user?.first_name} ${user?.last_name}`} img={user?.avatarUrl ? user.avatarUrl : ''} />
      )}
    </div>
  );
};

export default User;
