import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import { Layers } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import deviciLogo from '../../assets/images/devici-logo.png';
import styles from '../../components/common/Auth/styles/index.module.css';
import PrivacyAndTerms from '../../components/common/PrivacyAndTerms';
import UiButton from '../../components/common/UIButton/UiButton';
import CodeForm from '../../components/Forms/CodeForm';
import EmailForm from '../../components/Forms/EmailForm';
import PasswordForm from '../../components/Forms/PasswordForm';
import { AUTH_FLOW } from '../../global/auth';
import { copyQrCodeToNavigator, getCodeChallenge } from '../../helpers/user';
import { useSocket } from '../../hooks/useSocket';
import {
  CLI_AUTH_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  HOME_ROUTE,
  SIGN_UP_ROUTE,
} from '../../store/constants/route-constants';
import type { Dispatch, RootState } from '../../store/store';

interface IMfaSetupData {
  code: string;
  email: string;
  session: string;
  password: string;
  secondCode: string;
}

type SignInProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const SignInPage: FC<SignInProps> = ({
  authFlow,
  isAuthenticated,
  samlRedirectUrl,
  isCLIAuth,
  isAuthLoading,
  mfaSetupData,
  mfaCheckData,
  checkAuthFlow,
  signIn,
  setPermanentPassword,
  redeemInvitationCode,
  resetAuthFlow,
  verifyMfaToken,
  checkMfaCode,
  exchangeCodeForTokens,
  isExtendedSessionDuration,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [email, setEmail] = useState('');
  const { socket, disconnect } = useSocket();
  const [password, setPassword] = useState('');
  const [secondCode, setSecondCode] = useState('');
  const [windowWidth, setWindowWidth] = useState(0);
  const [isFinishSamlFlow, setIsFinishSamlFlow] = useState(false);

  const code = new URLSearchParams(search).get('code');

  useEffect(() => {
    if (code) {
      setIsFinishSamlFlow(true);
      const codeChallenge = getCodeChallenge();

      if (!codeChallenge) return;

      exchangeCodeForTokens({
        code,
        codeChallenge,
      });
    }

    if (socket) {
      // if after unsuccessful refresh flow user was redirected
      // to the sign in page adn still connected to the ws api:
      disconnect(); // disconnect from ws live updates api
    }

    const handleResize = (e: any) => {
      setWindowWidth(e.target.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      resetAuthFlow();
      window.removeEventListener('resize', handleResize);
      setWindowWidth(0);
    };
  }, []);

  useEffect(() => {
    if (authFlow === AUTH_FLOW.MFA_SETUP_COMPLETED && mfaCheckData) {
      checkMfaCode({ code: secondCode, email, session: mfaCheckData.session });
    }
  }, [authFlow]);

  useEffect(() => {
    if (samlRedirectUrl) {
      window.location.replace(samlRedirectUrl);
    }
  }, [samlRedirectUrl]);

  const handleNavigate = (url: string) => {
    navigate(url);
  };

  if (isAuthenticated) {
    if (isCLIAuth) return <Navigate to={CLI_AUTH_ROUTE} />;

    return <Navigate to={HOME_ROUTE} />;
  }

  const getTitle = () => {
    switch (authFlow) {
      case AUTH_FLOW.MFA_SETUP:
        return 'MFA Set Up';
      case AUTH_FLOW.REJECTED:
        return 'SSO Authentication Needed';
      case AUTH_FLOW.MFA_SETUP_COMPLETED:
        return 'MFA Set Up';
      default:
        return 'Sign in';
    }
  };

  const isMfaSetupFlow = authFlow === AUTH_FLOW.MFA_SETUP || authFlow === AUTH_FLOW.MFA_SETUP_COMPLETED;
  const isSamlFlow = authFlow === AUTH_FLOW.SAML;
  const isBasicFlow = authFlow === AUTH_FLOW.BASIC;
  const isRejected = authFlow === AUTH_FLOW.REJECTED;
  const isMfaCheckFlow = authFlow === AUTH_FLOW.MFA_CHECK;
  const isUnconfirmed = authFlow === AUTH_FLOW.UNCONFIRMED;
  const isFirstSignIn = authFlow === AUTH_FLOW.FIRST_SIGN_IN;
  const isWithInvitationCode = authFlow === AUTH_FLOW.WITH_INVITATION_CODE;

  const isMfaSetupData = (data: any): data is IMfaSetupData => {
    return typeof data === 'object' && 'code' in data && 'email' in data && 'session' in data && 'password' in data;
  };

  const handleMfaSetup = async <T,>(data: T) => {
    if (isMfaSetupData(data)) {
      const { email, code, password, session, secondCode } = data;
      setSecondCode(secondCode);
      await verifyMfaToken({ session, code, email, password });
    }
  };

  const handleCopyQrCode = async (text: string) => {
    const result = await copyQrCodeToNavigator(text);

    if (result) toast.success('Copied to clipboard!');
    else toast.warning('Could not copy!');
  };

  if (isUnconfirmed) {
    navigate(SIGN_UP_ROUTE);
  }

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        {!!windowWidth && windowWidth < 600 && (
          <div className={classNames(styles.displaySizeWarning, 'text-light')}>
            For optimal performance and a seamless experience, we recommend using our platform on a desktop rather than
            a mobile device.
          </div>
        )}
        <div className={styles.header}>
          <div className={styles.logo}>
            <img src={deviciLogo} alt="Devici logo" />
          </div>
          <div
            className={styles.title}
            style={{
              width: isMfaSetupFlow ? '480px' : '300px',
              textAlign: isRejected ? 'center' : 'left',
            }}
          >
            {getTitle()}
          </div>
        </div>
        {!authFlow && !isFinishSamlFlow && !isRejected && (
          <EmailForm email={email} setEmail={setEmail} handleSubmit={checkAuthFlow} isLoading={isAuthLoading} />
        )}
        {isSamlFlow && <p>Redirecting to your SAML IDP...</p>}
        {isFinishSamlFlow && !isRejected && <p>We are almost finished...</p>}
        {isRejected && (
          <p>
            Oops! It looks like you don&apos;t have permission to access Devici. Please contact your administrator for
            assistance.
          </p>
        )}
        {isBasicFlow && email && (
          <PasswordForm
            email={email}
            title="Password"
            isFirstPassword={false}
            handleSubmit={signIn}
            isLoading={isAuthLoading}
            setPassword={setPassword}
            needCheckboxAuthSession={isExtendedSessionDuration}
          />
        )}
        {isWithInvitationCode && email && (
          <CodeForm
            email={email}
            title="Invitation code"
            isLoading={isAuthLoading}
            handleSubmit={redeemInvitationCode}
          />
        )}
        {isFirstSignIn && email && (
          <PasswordForm
            email={email}
            isLoading={isAuthLoading}
            title="Enter your new permanent password"
            isFirstPassword={isFirstSignIn}
            handleSubmit={setPermanentPassword}
            setPassword={setPassword}
            needCheckboxAuthSession={isExtendedSessionDuration}
          />
        )}
        {isMfaSetupFlow && email && (
          <>
            <span className={styles.mfaSetupText}>
              For security reasons, we require two <b>different</b> codes from an authenticator app.
            </span>
            <span className={styles.mfaSetupText}>
              First, scan the QR code with an authenticator app. Then, enter the first code in the first box below.
              Then, please <b>wait</b> for a new code to generate and enter it into the second box.
            </span>
            <div className={styles.mfaSetupWrap}>
              <div>
                <img src={mfaSetupData?.qrImage || ''} />
                <UiButton type="transparent" onClick={() => handleCopyQrCode(mfaSetupData?.code || '')}>
                  Copy key <Layers />
                </UiButton>
              </div>
              <span className={styles.verticalLine} />
              <div className="mt-3">
                <CodeForm
                  isDoubled
                  email={email}
                  title="Enter code from the app"
                  password={password}
                  isLoading={isAuthLoading}
                  secondTitle="Enter second code from app"
                  handleSubmit={handleMfaSetup}
                  session={mfaSetupData?.session}
                />
              </div>
            </div>
          </>
        )}
        {isMfaCheckFlow && (
          <CodeForm
            email={email}
            password={password}
            isLoading={isAuthLoading}
            handleSubmit={checkMfaCode}
            session={mfaCheckData?.session}
            title="Enter code from Authenticator app"
          />
        )}
        <div className={styles.footer}>
          <div className={styles.footerLink} onClick={() => handleNavigate(FORGOT_PASSWORD_ROUTE)}>
            Forgot password?
          </div>{' '}
          <span className={styles.footerLinkDivider} />
          <div className={styles.footerLink} onClick={() => handleNavigate(SIGN_UP_ROUTE)}>
            Create an account
          </div>
        </div>

        <PrivacyAndTerms />
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  isAuthenticated: state.auth?.isAuthenticated,
  authFlow: state.auth?.authFlow,
  isCLIAuth: state.auth?.isCLIAuth,
  isAuthLoading: state.auth.isAuthLoading,
  mfaSetupData: state.auth.mfaSetupData,
  mfaCheckData: state.auth.mfaCheckData,
  samlRedirectUrl: state.auth.samlRedirectUrl,
  isExtendedSessionDuration: state.auth?.sessionDuration > 1,
});

const mapDispatch = (dispatch: Dispatch) => ({
  signIn: dispatch.auth.signIn,
  checkAuthFlow: dispatch.auth.checkAuthFlow,
  resetAuthFlow: dispatch.auth.resetAuthFlow,
  redeemInvitationCode: dispatch.auth.redeemInvitationCode,
  setPermanentPassword: dispatch.auth.setPermanentPassword,
  verifyMfaToken: dispatch.auth.verifyMfaToken,
  checkMfaCode: dispatch.auth.checkMfaCode,
  exchangeCodeForTokens: dispatch.auth.exchangeCodeForTokens,
});

export default connect(mapState, mapDispatch)(SignInPage);
