import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { getUserColorsFromLocalStorage, MAIN_COLORS, STICKER_COLORS } from '../../helpers/colors';
import { getSelectedComponentsAmount } from '../../helpers/diagram';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import styles from './diagram.module.css';
import NodeColorPicker from './NodeColorPicker';
import { NodeFontColorPicker } from './NodeFontColorPicker/NodeFontColorPicker';
import NodeFontSizePicker from './NodeFontSizePicker/NodeFontSizePicker';
import NodeStylePicker from './NodeStylePicker/NodeStylePicker';
import PopupToolsContextMenu from './PopupToolsContextMenu';

type DiagramPopupToolsProps = {
  node: any;
  width?: number;
  height?: number;
  setAttributesPopup?: () => void;
};

const DiagramStickerPopupTools: FC<DiagramPopupToolsProps> = ({ setAttributesPopup, node }) => {
  const { nodesSharedState, edgesSharedState } = useTypedSelector((state) => state.diagram);
  const currentUserId = useTypedSelector((state) => state.user?.current?.id) || '';
  const [NodeColorPickerVisible, setNodeColorPickerVisible] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [nodeFontSizeVisible, setNodeFontSizeVisible] = useState(false);
  const [fontColorPicker, setFontColorPicker] = useState(false);

  const selectedAmount = getSelectedComponentsAmount({
    nodesSharedState,
    edgesSharedState,
    currentUserId,
  });

  if (selectedAmount > 1) return null;

  const closeAllPopups = () => {
    setNodeColorPickerVisible(false);
    setShowContextMenu(false);
    setFontColorPicker(false);
    setNodeFontSizeVisible(false);
  };

  return (
    <div className={classNames('z-1 d-flex justify-content-between align-items-center', styles.nodePopupTools)}>
      <NodeColorPicker
        setItemsVisible={(value) => {
          closeAllPopups();
          setNodeColorPickerVisible(value);
        }}
        itemsVisible={NodeColorPickerVisible}
        node={node}
        colors={STICKER_COLORS.concat(getUserColorsFromLocalStorage())}
      />
      <div className={styles.breakLine} />
      <NodeFontSizePicker
        node={node}
        nodeFontSizeVisible={nodeFontSizeVisible}
        setNodeFontSizeVisible={(value) => {
          closeAllPopups();
          setNodeColorPickerVisible(value);
        }}
      />
      <div className={styles.breakLine} />
      <NodeStylePicker node={node} />
      <NodeFontColorPicker
        node={node}
        itemsVisible={fontColorPicker}
        setItemsVisible={(value) => {
          closeAllPopups();
          setFontColorPicker(value);
        }}
        colors={MAIN_COLORS.concat(getUserColorsFromLocalStorage())}
      />

      <ThreeDotsVertical
        fontSize={16}
        color="#6F767E"
        className="cursor-pointer"
        onClick={() => {
          closeAllPopups();
          setShowContextMenu((prev) => !prev);
        }}
      />
      {showContextMenu && <PopupToolsContextMenu isSticker node={node} setVisible={setShowContextMenu} />}
    </div>
  );
};

export default DiagramStickerPopupTools;
